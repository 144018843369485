import {
	collection,
	doc,
	getDoc,
	onSnapshot,
	query,
	updateDoc,
	where,
} from 'firebase/firestore';
import { useCallback, useContext, useEffect, useState } from 'react';
import { db } from '../../../../firebase';
import {
	FormNode,
	FormTemplate,
	NodeType,
	UtilityFormV2,
} from '../../ProjectUtilityFormV2/utils/types';
import { TreeItems } from 'dnd-kit-sortable-tree';
import { MinimalTreeItemData } from '../components/TreeComponent/TreeComponent.types';
import { ItemChangedReason } from 'dnd-kit-sortable-tree/dist/types';
import { useParams } from 'react-router-dom';
import { TreeItem } from '../../../../screens/NodesReorder/components/DndKitSortableTreeItem/types';
import { SettingsContext } from '../../../../context/SettingsProvider';

const allowedRootNodes: NodeType[] = ['node', 'photos'];

export type ReorderableItem = {
	id: string;
	name: string;
	isForm?: boolean;
};

const useReorder = () => {
	const { formId, templateId } = useParams();
	const { maxNodeContainerNestingLevel } = useContext(SettingsContext);

	const [reorderableItem, setReorderableItem] = useState<ReorderableItem | null>(null);
	const [loading, setLoading] = useState(true);
	const [saving, setSaving] = useState(false);
	const [nodes, setNodes] = useState<TreeItems<MinimalTreeItemData> | undefined>();
	const [treeNodes, setTreeNodes] = useState<TreeItem[]>([]);
	const [nodesArray, setNodesArray] = useState<FormNode[]>([]);
	const [form, setForm] = useState<UtilityFormV2>();

	const buildTree = useCallback(
		(nodes: FormNode[], parentId: string = '', level = 0): any => {
			return nodes
				.filter(node => node.parentId === parentId)
				.sort((a, b) => (a.order || 0) - (b.order || 0))
				.map(node => {
					return {
						id: node.id,
						value: { ...node, level },
						canHaveChildren: node.type === 'node',
						children: buildTree(nodes, node.id, level + 1),
					};
				});
		},
		[]
	);

	const buildDndKitTree = useCallback(
		(nodes: FormNode[], parentId: string = ''): TreeItem[] => {
			return nodes
				.filter(node => node.parentId === parentId)
				.sort((a, b) => (a.order || 0) - (b.order || 0))
				.map(node => {
					return {
						id: node.id,
						node: node,
						children: buildDndKitTree(nodes, node.id),
					};
				});
		},
		[]
	);

	const loadForm = useCallback(async () => {
		if (formId) {
			const formRef = doc(db, 'utility_forms_v2', formId);
			const formDoc = await getDoc(formRef);

			if (formDoc.exists()) {
				const form = formDoc.data() as UtilityFormV2;
				form.id = formDoc.id;
				setReorderableItem({
					id: form.id,
					name: form.formName,
					isForm: true,
				});
				setLoading(false);
				setForm(form);
			}
		}
	}, [formId]);

	// useEffect for getting template document and filtering nodes.
	useEffect(() => {
		if (templateId) {
			const templateRef = doc(db, 'utility_forms_v2_templates', templateId);

			const unsubscribe = onSnapshot(templateRef, async templateDoc => {
				if (!templateDoc.exists()) {
					setLoading(false);
					return;
				}

				const template = templateDoc.data() as FormTemplate;
				template.id = templateDoc.id;

				// There may be invalid nodes in the database, so we filter them out
				const filteredNodes = template?.nodes
					.filter(node =>
						(!node.level || node.level === 0) && !allowedRootNodes.includes(node.type)
							? false
							: true
					)
					.filter(node => (node.level || 0) <= maxNodeContainerNestingLevel) // Only show nodes from level 0-4
					.filter(node =>
						node.type === 'node' && (node.level || 0) > maxNodeContainerNestingLevel - 1
							? false
							: true
					); // Do not show containers with level > 3

				const tree = buildTree(filteredNodes);
				setNodes(tree);
				setNodesArray(filteredNodes);

				setReorderableItem(template);
				setLoading(false);
			});

			return () => unsubscribe();
		}
	}, [buildTree, maxNodeContainerNestingLevel, templateId]);

	// useEffect for getting form and item documents.
	useEffect(() => {
		if (formId && !form) {
			// Only load form if given a 'formId' and the form hasn't been loaded yet.
			loadForm();
		}

		if (formId && form?.projectId) {
			// Only query the form items after form has been loaded.
			const nodesRef = query(
				collection(db, 'utility_forms_v2_items'),
				where('projectId', '==', form?.projectId),
				where('formId', '==', formId)
			);

			const unsubscribe = onSnapshot(nodesRef, async nodesCollection => {
				if (nodesCollection.empty) {
					setLoading(false);
					return;
				}

				const nodes = nodesCollection.docs.map(doc => {
					const data = doc.data() as FormNode;
					data.id = doc.id;
					return data;
				});

				// There may be invalid nodes in the database, so we filter them out
				const filteredNodes = nodes
					.filter(node =>
						(!node.level || node.level === 0) && !allowedRootNodes.includes(node.type)
							? false
							: true
					)
					.filter(node => (node.level || 0) <= maxNodeContainerNestingLevel)
					.filter(node =>
						node.type === 'node' && node.level === maxNodeContainerNestingLevel
							? false
							: true
					);

				const tree = buildTree(filteredNodes);
				setNodes(tree);
				setNodesArray(filteredNodes);

				const dndKitTree = buildDndKitTree(filteredNodes);
				setTreeNodes(dndKitTree);

				setLoading(false);
			});

			return () => unsubscribe();
		}
	}, [
		buildDndKitTree,
		buildTree,
		form,
		form?.projectId,
		formId,
		loadForm,
		maxNodeContainerNestingLevel,
		templateId,
	]);

	const reorderNode = async (
		result: TreeItems<MinimalTreeItemData>,
		reason: ItemChangedReason<MinimalTreeItemData>,
		showAlert: (message: string) => void
	) => {
		// Check if depth is greater than 2
		// If it is, don't update the nodes
		if (!checkDepth(result)) {
			showAlert(
				'You cannot add containers deeper than 4 levels and the root level can only have containers and photos.'
			);
			return;
		}

		setSaving(true);
		setNodes(result);

		const nodesArray: FormNode[] = [];

		const buildNodes = (nodes: TreeItems<MinimalTreeItemData>, parentNode?: FormNode) => {
			let order = 0;
			nodes.forEach(node => {
				nodesArray.push({
					...node.value,
					parentId: parentNode?.id || '',
					level: parentNode ? (parentNode.level || 0) + 1 : 0,
					order: order++,
					parentsTitle: parentNode?.parentsTitle
						? `${parentNode?.parentsTitle} > ${parentNode?.displayTitle}`
						: parentNode
						? parentNode?.displayTitle
						: '',
				});
				if (node.children) buildNodes(node.children, node.value);
			});
		};

		buildNodes(result);

		if (reason.type === 'dropped') {
			if (templateId) {
				const templateRef = doc(db, 'utility_forms_v2_templates', templateId);
				updateDoc(templateRef, { nodes: nodesArray });
			}

			if (formId) {
				await Promise.all(
					nodesArray.map(async node => {
						const nodeRef = doc(db, 'utility_forms_v2_items', node.id);
						await updateDoc(nodeRef, node);
					})
				);
			}
		}

		setSaving(false);
	};

	const checkDepth = (nodes: TreeItems<MinimalTreeItemData>, depth = 0): boolean => {
		if (depth > 4) return false;

		for (const node of nodes) {
			// Only allow root nodes to be of type node or photos
			if (depth === 0 && !['node', 'photos'].includes(node.value.type)) return false;
			// No nodes after the third level
			if (depth > 3 && node.value.type === 'node') return false;

			if (node.children && node.children.length > 0) {
				if (!checkDepth(node.children, depth + 1)) return false;
			}
		}

		return true;
	};

	return {
		loading,
		nodes,
		nodesArray,
		reorderNode,
		saving,
		reorderableItem,
		treeNodes,
	};
};

export default useReorder;
