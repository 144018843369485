import styled from 'styled-components';

export default function ClickableSpan({
	children,
	onClick,
	title,
}: {
	children: React.ReactNode;
	onClick?: () => void;
	title?: string;
}) {
	return (
		<StyledSpan
			onClick={event => [event.stopPropagation(), !!onClick && onClick()]}
			tabIndex={0}
			title={title}>
			{children}
		</StyledSpan>
	);
}

const StyledSpan = styled.span`
	transition: 0.2s;
	color: #ffb310;
	cursor: pointer;

	&:hover,
	:focus-visible {
		color: white;
		text-decoration: underline;
	}
`;
