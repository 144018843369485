import { Button, Dialog, DialogActions, DialogTitle, DialogContent } from '@mui/material';

type Props = {
	open: boolean;
	setOpen: React.Dispatch<React.SetStateAction<boolean>>;
	onClose: any;
	title: string;
	content: any;
};

/**
 * Functional component that displays a generic dialog popup
 * @param open boolean useState that controls ConfirmDialog's visibility
 * @param setOpen useState updater for open
 * @param onClose function that runs when dialog is closed. onClose(true) when Accept is clicked and onClose(false) when ConfirmDialog is closed any other way
 * @param title string value displayed as the title of the dialog
 * @param content any JSX values/elements that are displayed as the content of the dialog
 */
export default function ConfirmDialog({
	open,
	setOpen,
	onClose,
	title,
	content,
}: Props): JSX.Element {
	const handleClose = () => {
		setOpen(false);
		onClose(false);
	};

	const handleAccept = () => {
		setOpen(false);
		onClose(true);
	};

	return (
		<Dialog open={open} onClose={handleClose}>
			<DialogTitle>{title}</DialogTitle>
			<DialogContent>{content}</DialogContent>
			<DialogActions>
				<Button onClick={handleClose}>Cancel</Button>
				<Button onClick={handleAccept} autoFocus>
					Accept
				</Button>
			</DialogActions>
		</Dialog>
	);
}
