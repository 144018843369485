import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import styled from 'styled-components';

export default function ErrorScreen({
	message,
	title,
	size = 150,
	textColor = 'white',
}: {
	message?: string;
	title?: string;
	size?: number;
	textColor?: 'white' | 'black';
}) {
	return (
		<Container $color={textColor}>
			{title ? <h2>{title}</h2> : null}

			<ErrorOutlineIcon
				style={{ height: `${size}px`, width: `${size}px`, fill: '#ffb310' }}
			/>

			{message ? <h3>{message}</h3> : null}
		</Container>
	);
}

const Container = styled.div<{ $color: string }>`
	display: flex;
	flex-direction: column;
	gap: 20px;
	justify-content: center;
	align-items: center;

	color: ${({ $color }) => $color};
	height: 100%;
	> * {
		margin: 0;
	}
`;
