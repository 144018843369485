import { Cancel, CheckBoxOutlined } from '@mui/icons-material';
import { YellowIconButton } from '../../../../../../styled-components/buttons';
import { useContext, useEffect } from 'react';
import { ProjectContext } from '../../../../../../../context/ProjectProvider';

export default function CheckboxHeader() {
	const { canSelectProjects, setCanSelectProjects, setProjectsSelected } =
		useContext(ProjectContext);

	useEffect(() => {
		return () => {
			setCanSelectProjects(false);
			setProjectsSelected([]);
		};
	}, [setCanSelectProjects, setProjectsSelected]);

	return !canSelectProjects ? (
		<YellowIconButton
			title="View project selection checkboxes"
			onClick={() => setCanSelectProjects(true)}>
			<CheckBoxOutlined />
		</YellowIconButton>
	) : (
		<YellowIconButton
			title="Hide project selection checkboxes"
			onClick={() => {
				setCanSelectProjects(false);
				setProjectsSelected([]);
			}}>
			<Cancel />
		</YellowIconButton>
	);
}
