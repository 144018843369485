import {
	FullMetadata,
	StorageReference,
	getMetadata,
	ref,
	updateMetadata,
} from 'firebase/storage';
import { FirebaseFile } from '../types';
import { storage } from '../firebase';
import { isBrowserViewableFile } from '.';

/**
 * Fallback download function for when file is too big for blob
 */
export const downloadFile = async (
	downloadUrl: string,
	filename: string,
	metadata: FullMetadata,
	storageRef: StorageReference
) => {
	// Set contentDisposition to attachment so that file is downloaded from url instead of just being opened
	const contentDispo = `attachment; filename="${filename}"`;
	if (metadata.contentDisposition !== contentDispo) {
		await updateMetadata(storageRef, {
			contentDisposition: contentDispo,
		});
	}

	const anchor = document.createElement('a');
	anchor.setAttribute('href', downloadUrl);
	document.body.appendChild(anchor);
	anchor.click();
	document.body.removeChild(anchor);

	// Reset contentDisposition if file is viewable
	if (isBrowserViewableFile(metadata)) {
		updateMetadata(storageRef, {
			contentDisposition: `inline; filename="${filename}"`,
		});
	}
};

/**
 * Standard function for downloading individual files
 */
export const downloadFileWithBlob = async (
	file: FirebaseFile,
	dataFetchSnackbar: () => void,
	downloadStartedSnackbar: () => void,
	setProgress?: React.Dispatch<React.SetStateAction<string | null>>
) => {
	dataFetchSnackbar();

	const fileRef = ref(storage, file.filepath);
	const filename = file.filename;
	const url = file.downloadUrl;
	const metadata = await getMetadata(fileRef);

	downloadStartedSnackbar();

	const gb = metadata.size / (1024 * 1024 * 1024);
	if (gb >= 1) {
		await downloadFile(url, filename, metadata, fileRef);
	} else {
		const xhr = new XMLHttpRequest();
		xhr.responseType = 'blob';
		xhr.onerror = err => console.error(err);
		xhr.onload = () => {
			const blob = xhr.response;
			const blobURL = (window.URL || window.webkitURL).createObjectURL(blob);

			const element = document.createElement('a');
			element.setAttribute('href', blobURL);
			element.setAttribute('download', filename);
			element.style.display = 'none';
			element.target = '_blank';
			document.body.appendChild(element);
			element.click();

			(window.URL || window.webkitURL).revokeObjectURL(blobURL);
			document.body.removeChild(element);
		};
		xhr.onprogress = event => {
			if (setProgress) {
				const progress = (event.loaded / event.total) * 100;
				if (progress !== 100) {
					setProgress(progress.toFixed(0));
				} else {
					setProgress(null);
				}
			}
		};
		xhr.open('GET', url);
		xhr.send();
	}
};

export const downloadUploadedFiles = async (
	fileArr: FirebaseFile[],
	setDetails?: React.Dispatch<React.SetStateAction<string>>
) => {
	const urlArr: string[] = [];
	const refArr: StorageReference[] = [];
	const filenameArr: string[] = [];

	for (const file of fileArr) {
		urlArr.push(file.downloadUrl);
		refArr.push(ref(storage, file.filepath));
		filenameArr.push(file.filename);
	}
	if (urlArr.length !== refArr.length) {
		console.error('Url array not the same length as ref array');
		return;
	}

	for (const i of urlArr.keys()) {
		const downloadUrl = urlArr[i];
		const storageRef = refArr[i];
		const filename = filenameArr[i];
		const totalFiles = urlArr.length;

		if (setDetails) {
			setDetails(`
				Getting file metadata...\n
				Downloading file ${i + 1} of ${totalFiles}
			`);
		}

		const metadata = await getMetadata(storageRef);
		const blobSizeLimit = 500 * 1024 * 1024;

		if (metadata.size >= blobSizeLimit) {
			// If file size is >= 500 MB, download directly through url instead of blob.
			await downloadFile(downloadUrl, filename, metadata, storageRef);

			// Slight delay before continuing download.
			setTimeout(() => {}, 500);
		} else {
			// File size is < 500 MB, so download through blob.
			await new Promise<void>(resolve => {
				const xhr = new XMLHttpRequest();
				xhr.responseType = 'blob';
				xhr.onprogress = event => {
					if (event.lengthComputable && setDetails) {
						setDetails(`
							${((event.loaded / event.total) * 100).toFixed(2)}% downloaded...\n
							Downloading file ${i + 1} of ${totalFiles}
						`);
					}
				};
				xhr.onerror = async error => {
					// Could not download through blob, so download with url.
					console.error(`Could not download ${filename} with blob...`);
					console.error(error);
					await downloadFile(downloadUrl, filename, metadata, storageRef);
					setTimeout(() => {}, 500);
					resolve();
				};
				xhr.onload = () => {
					const blob = xhr.response;
					const blobURL = (window.URL || window.webkitURL).createObjectURL(blob);

					const element = document.createElement('a');
					element.setAttribute('href', blobURL);
					element.setAttribute('download', filename);
					element.style.display = 'none';
					element.target = '_blank';
					document.body.appendChild(element);
					element.click();

					document.body.removeChild(element);
					(window.URL || window.webkitURL).revokeObjectURL(blobURL);
					resolve();
				};

				xhr.open('GET', downloadUrl);
				xhr.send();
			});
		}
		if (setDetails) {
			setDetails('');
		}
	}
};
