import { ArrowDropDown, DoneAll } from '@mui/icons-material';
import { Box, IconButton, styled, Typography } from '@mui/material';
import React, { forwardRef, useImperativeHandle, useState } from 'react';
import logoSrc from '../../../../assets/circleLogo.png';
import theme from '../../../../styles/theme';
import { PendingUser, SharedUser } from './RightNavButtons/types';
import ProjectEmailButton from './RightNavButtons/components/ProjectEmailButton';
import Spinner from '../../../reusable-components/Spinner';

export type ProjectInfoDropdownHandles = {
	handleOpen: (status: boolean) => void;
};

type Props = {
	title: string;
	isAdmin?: boolean;
	isContractor?: boolean;
	isComplete?: boolean;
	sharedUsers?: SharedUser[];
	pendingUsers?: PendingUser[];
	children?: React.ReactNode;
	notExpandable?: boolean;
	isLoadingEmail?: boolean;
	showEmailButton?: boolean;
	customIconRight?: React.ReactNode;
	customIconLeft?: React.ReactNode;
};

const ProjectInfoDropdown = forwardRef<ProjectInfoDropdownHandles, Props>(
	(
		{
			title,
			isAdmin,
			isContractor,
			isComplete,
			sharedUsers = [],
			pendingUsers = [],
			children,
			notExpandable = false,
			isLoadingEmail,
			showEmailButton = false,
			customIconRight,
			customIconLeft,
		},
		ref
	) => {
		const [isOpen, setIsOpen] = useState<boolean>(!isAdmin || notExpandable);

		useImperativeHandle(ref, () => ({
			handleOpen: status => {
				setIsOpen(status);
			},
		}));

		return (
			<ProjectInfoContainer>
				<ProjectHeader
					onClick={() => !notExpandable && setIsOpen(!isOpen)}
					expandable={!notExpandable ? 1 : 0}>
					<StyledBoxLeft>
						<StyledBoxLeftContainer>
							<img
								src={logoSrc}
								alt="Logo"
								style={{ height: 18, marginRight: 10, userSelect: 'none' }}
							/>
							<StyledTypography variant="h6">{title}</StyledTypography>
						</StyledBoxLeftContainer>
						{!!customIconLeft && customIconLeft}
					</StyledBoxLeft>
					<StyledBox>
						{!!customIconRight && customIconRight}
						{showEmailButton ? (
							!isLoadingEmail ? (
								<ProjectEmailButton
									teamMembers={sharedUsers}
									pendingUsers={pendingUsers}
								/>
							) : (
								<Box sx={{ mt: '-18px', mb: '-25px' }}>
									<Spinner />
								</Box>
							)
						) : null}

						{isComplete && (
							<DoneAll
								sx={{ color: 'green', cursor: 'default' }}
								onClick={event => event.stopPropagation()}
							/>
						)}

						{!notExpandable && (
							<IconButton sx={{ mt: '-10px', mb: '-10px' }}>
								<ArrowDropDown
									sx={{
										color: theme.palette.common.white,
										cursor: 'pointer',
										transform: isOpen ? 'scale(-1, -1)' : 'scale(1, 1)',
									}}
								/>
							</IconButton>
						)}
					</StyledBox>
				</ProjectHeader>
				<ProjectContent open={isOpen ? 1 : 0}>{children}</ProjectContent>
			</ProjectInfoContainer>
		);
	}
);

export default ProjectInfoDropdown;

const ProjectInfoContainer = styled(Box)`
	background-color: ${theme.palette.common.black};
	border: 1px solid ${theme.palette.borderColor};
	border-radius: 5px;
	overflow: hidden;
	width: 100%;
	transition: 0.3s;
`;

const ProjectHeader = styled(Box)<{ expandable: number }>`
	display: grid;
	align-items: center;
	justify-content: space-between;
	grid-template-columns: 1fr auto;
	border-bottom: 4px solid ${theme.palette.borderColor};
	padding: 10px;
	cursor: ${({ expandable }) => (expandable ? 'pointer' : 'default')};
`;

const ProjectContent = styled(Box)<{ open: number }>`
	width: 100%;
	transition: all 0.3s;
	overflow: visible;
	height: auto;
	background-color: #0a0a0c;
	position: relative;

	& > div {
		transition: all 0.3s;
	}

	${({ open }) =>
		!open &&
		`
		overflow: hidden;
		height: 0;
		padding-bottom: 0;
	`}
`;

const StyledBox = styled(Box)`
	display: flex;
	align-items: center;
`;

const StyledTypography = styled(Typography)`
	margin-top: 4px;
	user-select: none;
	line-height: 1.25rem;
	font-weight: 600;
`;

const StyledBoxLeft = styled(Box)`
	display: flex;
	align-items: center;
	gap: 10px;
`;

const StyledBoxLeftContainer = styled(Box)`
	display: flex;
	align-items: center;
`;
