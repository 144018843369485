import { createContext, useState } from 'react';
import { ProjectData } from '../../../types';

type GroupedStatusTableContextType = {
	statusGroupCounts: {
		[groupName: string]: number;
	} | null;
	setStatusGroupCounts: React.Dispatch<
		React.SetStateAction<{
			[groupName: string]: number;
		} | null>
	>;

	statusGroupProjects: ProjectData[];
	setStatusGroupProjects: React.Dispatch<React.SetStateAction<ProjectData[]>>;
};

export const GroupedStatusTableContext = createContext(
	{} as GroupedStatusTableContextType
);

export const GroupedStatusTableProvider = ({
	children,
}: {
	children?: React.ReactNode;
}) => {
	const [statusGroupCounts, setStatusGroupCounts] = useState<{
		[groupName: string]: number;
	} | null>(null);

	const [statusGroupProjects, setStatusGroupProjects] = useState<ProjectData[]>([]);

	return (
		<GroupedStatusTableContext.Provider
			value={{
				statusGroupCounts,
				setStatusGroupCounts,

				statusGroupProjects,
				setStatusGroupProjects,
			}}>
			{children}
		</GroupedStatusTableContext.Provider>
	);
};
