import { createContext, useContext, useEffect, useRef, useState } from 'react';
import { AuthContext } from './AuthProvider';
import { SnackContext } from './SnackProvider';
import { useNavigate } from 'react-router-dom';

import { createProject, db, getCachedData, shareProjects } from '../firebase/index';
import {
	arrayUnion,
	collection,
	doc,
	getDoc,
	getDocs,
	onSnapshot,
	updateDoc,
} from 'firebase/firestore';
import {
	ProjectData,
	ProjectFlag,
	ProjectStatus,
	ProjectTravel,
	UserObj,
} from '../types';
import useProjectStatusCount from '../hooks/useProjectStatusCount';
import { ProjectStatusCountState } from '../hooks/useProjectStatusCount/reducer';
import { StatusGroupName } from '../components/reusable-components/GroupedStatusTable/utils';

type ProjectContextType = {
	project: ProjectData | null;
	setProject: React.Dispatch<React.SetStateAction<ProjectData | null>>;

	cachedClients: string[] | null;
	cachedDesignSoftware: string[];
	cachedModelers: string[];
	cachedEmails: string[];

	loadingAutofillData: boolean;

	projEmailOptions: string[];

	canSelectProjects: boolean;
	setCanSelectProjects: React.Dispatch<React.SetStateAction<boolean>>;
	projectsSelected: ProjectData[];
	setProjectsSelected: React.Dispatch<React.SetStateAction<ProjectData[]>>;

	contractors: any[];
	setContractors: React.Dispatch<React.SetStateAction<any[]>>;

	handleAddMembers: (emails: string[], inputProjects?: ProjectData[]) => Promise<void>;

	handleAddProject: (
		formValues: any,
		autoNavigate?: boolean
	) => Promise<string | undefined>;

	projectStatusCount: ProjectStatusCountState;
	setProjectStatusCount: React.Dispatch<Partial<ProjectStatusCountState>>;
	currentProjectStatus: ProjectStatus;
	setCurrentProjectStatus: React.Dispatch<React.SetStateAction<ProjectStatus>>;
	accountManagerFilter: UserObj | null;
	setAccountManagerFilter: React.Dispatch<React.SetStateAction<UserObj | null>>;

	projectsListQuery: string;
	setProjectsListQuery: React.Dispatch<React.SetStateAction<string>>;

	selectedStatusGroup: StatusGroupName;
	setSelectedStatusGroup: React.Dispatch<React.SetStateAction<StatusGroupName>>;
	showContractorDialog: boolean;
	setShowContractorDialog: React.Dispatch<React.SetStateAction<boolean>>;

	flaggedProjects: ProjectFlag[];
	traveledProjects: ProjectTravel[];
};

export const ProjectContext = createContext({} as ProjectContextType);

export const ProjectProvider = ({ children }: { children?: React.ReactNode }) => {
	const { user } = useContext(AuthContext);
	const { setSnackbarProps } = useContext(SnackContext);

	const [project, setProject] = useState<ProjectData | null>(null);

	const [cachedClients, setCachedClients] = useState<string[] | null>(null);
	const [cachedDesignSoftware, setCachedDesignSoftware] = useState<string[]>([]);
	const [cachedModelers, setCachedModelers] = useState<string[]>([]);
	const [cachedEmails, setCachedEmails] = useState<string[]>([]);

	const [loadingAutofillData, setLoadingAutofillData] = useState(false);

	const [projEmailOptions, setProjEmailOptions] = useState<string[]>([]);

	const [canSelectProjects, setCanSelectProjects] = useState(false);
	const [projectsSelected, setProjectsSelected] = useState<ProjectData[]>([]);

	const [contractors, setContractors] = useState<any[]>([]);

	const [projectsListQuery, setProjectsListQuery] = useState('');
	const [accountManagerFilter, setAccountManagerFilter] = useState<UserObj | null>(null);

	const [selectedStatusGroup, setSelectedStatusGroup] =
		useState<StatusGroupName>('In-Progress');

	const [showContractorDialog, setShowContractorDialog] = useState<boolean>(false);

	const navigate = useNavigate();

	const handleAddMembers = async (emails: string[], inputProjects?: ProjectData[]) => {
		let projectsArr: ProjectData[] = inputProjects || [];

		if (projectsSelected.length) {
			projectsArr = projectsSelected;
		} else if (project) {
			projectsArr.push(project);
		}

		try {
			const res = await shareProjects({
				emails: emails,
				projectIds: projectsArr.map(proj => proj.id),
			});
			const data = res.data as any;
			const severity =
				data.nonExistantProjectIds.length ||
				data.restrictedProjectIds.length ||
				data.undocumentedEmails.length
					? 'error'
					: data.sharingErrors.length
					? 'warning'
					: 'success';
			const createMessage = () => {
				switch (severity) {
					case 'error':
						return `Error(s) occurred while sharing projects!\n
							${
								data.nonExistantProjectIds.length
									? `${data.nonExistantProjectIds.length} projects could not be shared.`
									: ''
							}
							${
								data.restrictedProjectIds.length
									? `You do not have access to shared ${data.restrictedProjectIds.length} projects.`
									: ''
							}
							${
								data.undocumentedEmails.length
									? `Could not share projects with: ${data.undocumentedEmails.join(
											', '
									  )}.`
									: ''
							}`;
					case 'warning':
						const projectNames = data.sharingErrors.map((err: any) => {
							return {
								email: err.email,
								names: err.alreadyShared.map((id: string) => {
									const proj = projectsArr.find(proj => proj.id === id);
									return proj?.address || proj?.name || 'Unnamed Project';
								}),
							};
						});
						return `${projectNames
							.map(
								(foo: any) => `${foo.email} is already shared on: ${foo.names.join(',')}`
							)
							.join('\n')}`;
					default:
						return 'Successfully shared project(s)!';
				}
			};
			setSnackbarProps({
				open: true,
				severity: severity,
				message: createMessage(),
				hideDuration: severity === 'success' ? undefined : null,
			});

			// Cache new emails.
			const newEmails = emails.filter(x => !cachedEmails.includes(x));
			setCachedEmails(prev => [...prev, ...newEmails]);

			if (user?.isAdmin) {
				const cachedEmailsDoc = doc(db, `cached_data/globalCachedEmails`);
				updateDoc(cachedEmailsDoc, {
					emails: arrayUnion(...newEmails),
				});
			}
		} catch (err) {
			console.error(err);
		}
	};

	// Initialize cached data.
	useEffect(() => {
		if (user) {
			// Get project email options.
			const docFilepath = `cached_data/${
				user?.isAdmin ? 'adminProjectEmailOptions' : 'clientProjectEmailOptions'
			}`;
			const projEmailOptionsDoc = doc(db, docFilepath);
			getDoc(projEmailOptionsDoc)
				.then(res => {
					const resData = res.data() as Record<string, unknown>;
					setProjEmailOptions((resData?.emails as string[]) || []);
				})
				.catch(err => {
					console.error(`Error getting mailing lists doc`, err);
				});

			// Admin only.
			if (user?.isAdmin) {
				// Get autofill data.
				setLoadingAutofillData(true);
				getCachedData().then(res => {
					const cachedData = res.data as any;
					setCachedDesignSoftware(cachedData.cachedDesignSoftware || []);
					setCachedModelers(cachedData.cachedModelers || []);
					setLoadingAutofillData(false);
				});

				// Get global cached emails.
				const cachedEmailsDoc = doc(db, `cached_data/globalCachedEmails`);
				getDoc(cachedEmailsDoc)
					.then(res => {
						const resData = res.data();
						setCachedEmails(resData?.emails || []);
					})
					.catch(err => {
						console.error(`Error getting cached emails`, err);
					});
			}
		}

		return () => {
			setCachedDesignSoftware([]);
			setCachedModelers([]);
		};
	}, [user, user?.isAdmin]);

	useEffect(() => {
		setCachedEmails(prev => {
			const tmp = [...prev];
			return tmp.sort();
		});
	}, [cachedEmails.length]);

	/**
	 * Function that adds a new project to Firestore and navigates to the new project
	 */
	const handleAddProject = async (formValues: any, autoNavigate = true) => {
		const projName = formValues.address || formValues.name || 'Unnamed Project';
		setSnackbarProps({
			open: true,
			severity: 'info',
			message: `Adding ${projName}...`,
			hideDuration: null,
		});

		// Add new project doc to Firestore
		try {
			const response = await createProject({ formValues });
			const data = response.data as { project: ProjectData };

			setSnackbarProps({
				open: true,
				severity: 'success',
				message: `Success! Redirecting to project page...`,
			});

			// Navigate to newProject
			if (autoNavigate) {
				navigate(`/projects/${data.project.id}`);
			}

			return data.project.id;
		} catch (err) {
			console.error(err);
			setSnackbarProps({
				open: true,
				severity: 'error',
				message: 'Error creating project...',
			});
		}
	};

	const { projectStatusCount, setProjectStatusCount } = useProjectStatusCount();
	const [currentProjectStatus, setCurrentProjectStatus] =
		useState<ProjectStatus>('To Be Scheduled');

	// Fetch cached data for auto-complete options
	const fetchedCachedData = useRef(false);
	useEffect(() => {
		async function fetchCachedData() {
			fetchedCachedData.current = true;

			if (user?.isAdmin) {
				// Get clients
				getDocs(collection(db, 'clients'))
					.then(snap => {
						const clientDocs = snap.docs;
						const clientNames = clientDocs.map(c => c.data().clientName as string);
						setCachedClients(clientNames);
					})
					.catch(err => {
						console.error('Error fetching cached clients', err);
					});

				// Get contractors
				getDocs(collection(db, 'matterport_directory'))
					.then(snap => {
						const contractorDocs = snap.docs;
						const contractorList = contractorDocs.map(c => {
							return {
								...c.data(),
								id: c.id,
							};
						});
						setContractors(contractorList);
					})
					.catch(err => {
						console.error('Error fetching contractors', err);
					});
			}
		}

		if (user && !fetchedCachedData.current) {
			fetchCachedData();
		}
	}, [user]);

	const [flaggedProjects, setFlaggedProjects] = useState<ProjectFlag[]>([]);
	const [traveledProjects, setTraveledProjects] = useState<ProjectTravel[]>([]);

	useEffect(() => {
		let unsubProjectFlags = () => {};
		let unsubProjectTravel = () => {};

		if (user && user?.isAdmin) {
			const flagDocs = collection(db, 'project_flags');
			unsubProjectFlags = onSnapshot(flagDocs, res => {
				const projectFlags = res.docs.map(flagDoc => {
					const projectFlag: ProjectFlag = {
						...flagDoc.data(),
						timestamp: new Date(flagDoc.data()._seconds * 1000),
					} as ProjectFlag;
					return projectFlag;
				});
				setFlaggedProjects(projectFlags);
			});

			const travelDocs = collection(db, 'project_travel');
			unsubProjectTravel = onSnapshot(travelDocs, res => {
				const projectTravels = res.docs.map(travelDoc => {
					const projectTravel: ProjectTravel = {
						...travelDoc.data(),
						timestamp: new Date(travelDoc.data()._seconds * 1000),
					} as ProjectTravel;
					return projectTravel;
				});
				setTraveledProjects(projectTravels);
			});
		} else {
			unsubProjectFlags();
			unsubProjectTravel();
			setFlaggedProjects([]);
			setTraveledProjects([]);
		}
	}, [user]);

	return (
		<ProjectContext.Provider
			value={{
				project,
				setProject,

				cachedClients,
				cachedDesignSoftware,
				cachedModelers,
				cachedEmails,

				loadingAutofillData,

				projEmailOptions,

				canSelectProjects,
				setCanSelectProjects,
				projectsSelected,
				setProjectsSelected,

				contractors,
				setContractors,

				handleAddMembers,

				handleAddProject,

				projectStatusCount,
				setProjectStatusCount,
				currentProjectStatus,
				setCurrentProjectStatus,
				accountManagerFilter,
				setAccountManagerFilter,

				projectsListQuery,
				setProjectsListQuery,

				selectedStatusGroup,
				setSelectedStatusGroup,

				showContractorDialog,
				setShowContractorDialog,

				flaggedProjects,
				traveledProjects,
			}}>
			{children}
		</ProjectContext.Provider>
	);
};
