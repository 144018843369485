import { FormNode } from '../../../../../utils/types';
import NodeTitle from './NodeTitle';
import { useItemNodes } from '../../../../../hooks/useItemNodes';
import { useContext, useEffect, useState } from 'react';
import {
	ChildrenContainer,
	NodeContainer,
	SubCategoryHeaderContainer,
} from '../FormFillV2.styles';
import ItemField from '../ItemField';
import NodeAddChildButton from '../../../../buttons/NodeAddChildButton/NodeAddChildButton';
import NodeActionButton from '../../../../buttons/NodeActionButton/NodeActionButton';
import CommentsButton from '../../../../buttons/CommentsButton/CommentsButton';
import InfoButton from '../../../../buttons/InfoButton/InfoButton';
import { FormV2Context } from '../../../../../context/FormV2Context.types';
import CategoryHeader from '../../CategoryHeader';
import { SettingsContext } from '../../../../../../../../context/SettingsProvider';

interface NodeFieldProps {
	node: FormNode;
}

const NodeField: React.FC<React.PropsWithChildren<NodeFieldProps>> = ({ node }) => {
	const { currentNode } = useContext(FormV2Context);
	const { maxNodeContainerNestingLevel } = useContext(SettingsContext);

	const { itemNodes } = useItemNodes(node);
	const [childItems, setChildItems] = useState<FormNode[]>([]);

	useEffect(() => {
		setChildItems(itemNodes);
	}, [itemNodes]);

	return (
		<NodeContainer id={node.id}>
			{currentNode?.id === node.id ? (
				<CategoryHeader />
			) : (
				<SubCategoryHeaderContainer>
					<NodeTitle node={node} />
					<NodeAddChildButton
						node={node}
						excludeTypes={
							// Do not allow containers to nest further than level 3.
							typeof node.level === 'number' &&
							node.level >= maxNodeContainerNestingLevel - 1
								? ['node']
								: undefined
						}
					/>
					<NodeActionButton node={node} />
					<CommentsButton node={node} />
					<InfoButton node={node} />
				</SubCategoryHeaderContainer>
			)}

			{childItems.length !== 0 && (
				<ChildrenContainer>
					{childItems.map(item => (
						<ItemField key={item.id} node={item} />
					))}
				</ChildrenContainer>
			)}
		</NodeContainer>
	);
};

export default NodeField;
