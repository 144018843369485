import { useContext, useState } from 'react';
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	TextField,
	Typography,
} from '@mui/material';
import { SnackContext } from '../../../context/SnackProvider';
import { ProjectDataWithForms } from '../../../types';
import theme from '../../../styles/theme';
import logoSrc from '../../../assets/circleLogo.png';
import { StyledDialogLogo } from '../../form-components/Dialog/Dialog.styles';

export default function CreateReportFirstDialog({
	open,

	projects,
	setOpen,
	toggleForm,
	totalSelectedForms,
	setReportName,
	setSecondDialogOpen,
}: {
	open: boolean;
	projects: ProjectDataWithForms[] | undefined;
	setOpen: React.Dispatch<React.SetStateAction<boolean>>;
	setReportName: React.Dispatch<React.SetStateAction<string>>;
	setSecondDialogOpen: React.Dispatch<React.SetStateAction<boolean>>;
	toggleForm: (formId: string, value: boolean) => void;
	totalSelectedForms: number;
}) {
	const { setSnackbarProps } = useContext(SnackContext);
	const [reportNameInput, setReportNameInput] = useState(''); // Local state to track input

	const handleNext = () => {
		if (reportNameInput.trim() === '') {
			// Show error or set a snack message if needed
			setSnackbarProps({
				open: true,
				message: 'Report name is required!',
				severity: 'error',
			});
			return;
		}
		setReportName(reportNameInput); // Set report name
		setOpen(false);
		setSecondDialogOpen(true);
	};

	return (
		<Dialog
			open={open}
			onClose={() => setOpen(false)}
			maxWidth={false}
			PaperProps={{
				sx: {
					width: '639px', // Set the dialog width
					maxWidth: 'none',
					height: '299px',
				},
			}}>
			<DialogTitle sx={{ background: '#242424', color: '#F6F6F6' }}>
				<StyledDialogLogo>
					<img src={logoSrc} alt="" style={{ height: 24 }} />
				</StyledDialogLogo>{' '}
				Create Analytics
			</DialogTitle>
			<DialogContent
				sx={{
					background: '#121212',
					display: 'grid',
					gap: '10px',
					alignItems: 'center',
				}}>
				<Typography sx={{ color: theme.palette.common.white, textAlign: 'center' }}>
					Please enter the name of this report:
				</Typography>
				<TextField
					value={reportNameInput} // Bind input value
					onChange={e => setReportNameInput(e.target.value)}
					placeholder="Report name"
					required={true}
					sx={{
						borderRadius: '4px',
						backgroundColor: theme.palette.common.white,
						'& .MuiInputBase-input::placeholder': {
							color: '#121212',
						},
					}}
				/>
			</DialogContent>
			<DialogActions sx={{ background: '#242424' }}>
				<Button onClick={() => setOpen(false)}>Cancel</Button>
				<Button onClick={handleNext} disabled={!reportNameInput} autoFocus>
					Next
				</Button>
			</DialogActions>
		</Dialog>
	);
}
