import React, { Fragment, useState, FC, useContext, useEffect, useCallback } from 'react';
import { Dialog, StyledTextField } from '../../../../components/form-components';
import { Box, InputAdornment, Typography } from '@mui/material';
import {
	StyledButtonText,
	StyledCircularProgress,
} from '../../../../components/form-components/CoverImage/CoverImageStyles';
import { AuthContext } from '../../../../context/AuthProvider';
import { collection, doc, getDoc, getDocs, query } from 'firebase/firestore';
import { db, queryProjects } from '../../../../firebase';
import { ProjectTable } from './ProjectTable';
import { Close, Search } from '@mui/icons-material';
import { SnackContext } from '../../../../context/SnackProvider';
import { TemplateContext } from '../../../../context/TemplateProvider';
import { ProjectData } from '../../../../types';

export const AssignDialog: FC<{
	open: boolean;
	type: 'project' | 'team';
	handleClose: () => void;
}> = ({ open, type, handleClose }) => {
	const { user } = useContext(AuthContext);
	const { assignTemplate } = useContext(TemplateContext);
	const { setSnackbarProps } = useContext(SnackContext);

	const [searchQuery, setSearchQuery] = useState<string>('');
	const [items, setItems] = useState<any[]>([]);
	const [teams, setTeams] = useState<any[]>([]);
	const [selectedIds, setSelectedIds] = useState<string[]>([]);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [disableButton, setDisableButton] = useState<boolean>(false);

	const handleCancel = () => {
		setSelectedIds([]);
		setSearchQuery('');
		setDisableButton(true);
		setItems([]);
		handleClose();
	};

	const handleSelectProject = (id: string) => {
		setSelectedIds(current =>
			current.includes(id)
				? selectedIds.filter(item => item !== id)
				: [...selectedIds, id]
		);
	};

	useEffect(() => {
		if (selectedIds.length === 0) {
			setDisableButton(true);
		} else {
			setDisableButton(false);
		}
	}, [selectedIds]);

	const handleAssign = async () => {
		setDisableButton(true);
		const selectedTeamIds = type === 'team' ? selectedIds : [];
		const selectedProjectIds = type === 'project' ? selectedIds : [];
		const selectedOrganizationIds =
			type === 'team'
				? teams
						.filter(team => selectedIds.includes(team.id))
						.map(team => team.organizationId)
				: [];

		await assignTemplate(selectedProjectIds, selectedTeamIds, selectedOrganizationIds);
		setDisableButton(false);
		setSnackbarProps({
			open: true,
			message: `Success! Template assigned to the ${type}.`,
			severity: 'success',
		});
		handleCancel();
	};

	const fetchTeams = useCallback(async () => {
		if (!user?.isAdmin && !user?.teamIds) return;
		setIsLoading(true);
		if (!user.isAdmin) {
			const documentPromises =
				!!user &&
				!!user.teamIds &&
				user.teamIds.map(id => {
					const docRef = doc(db, 'teams', id);
					return getDoc(docRef);
				});
			const documentSnapshots = !!documentPromises
				? await Promise.all(documentPromises)
				: [];

			const documents = documentSnapshots.map((docSnapshot: any) => {
				if (docSnapshot.exists()) {
					const data = docSnapshot.data();
					return {
						id: docSnapshot.id,
						name: data.name,
						organizationId: data.organizationId,
					};
				}
			});
			setTeams(documents);
		} else {
			const teamsRef = query(collection(db, 'teams'));
			const snapshot = await getDocs(teamsRef);
			const teams = snapshot.docs.map(doc => {
				const data = doc.data();
				return { id: doc.id, name: data.name, organizationId: data.organizationId };
			});
			setTeams(teams);
		}
		setIsLoading(false);
	}, [user]);

	useEffect(() => {
		type === 'team' && open && fetchTeams();
	}, [fetchTeams, open, type]);

	const clearSearchHandler = () => {
		setSearchQuery('');
		setSelectedIds(['']);
		setItems([]);
	};

	const searchHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
		const query = event.target.value;
		setSearchQuery(query);
		setIsLoading(true);
		if (query.length > 2) {
			if (type === 'team') {
				setItems(
					teams.filter(team => team.name.toLowerCase().includes(query.toLowerCase())) ||
						[]
				);
				setIsLoading(false);
			}
			if (type === 'project') {
				queryProjects({
					searchMode: 'general',
					query: query,
					limit: 6000,
				}).then(res => {
					const projects = (res.data as any).projects as ProjectData[];
					setItems(projects);
					setIsLoading(false);
				});
			}
		} else {
			setItems([]);
			setIsLoading(false);
		}
	};

	return (
		<Dialog
			open={open}
			wide
			title={
				type === 'project'
					? 'Assign Template to Project(s)'
					: 'Assign Template to Team(s)'
			}
			onClose={handleCancel}
			bottomActions={
				<>
					<StyledButtonText onClick={handleCancel}>Cancel</StyledButtonText>
					<StyledButtonText
						onClick={handleAssign}
						disabled={selectedIds.length === 0 || disableButton}>
						Assign Template
					</StyledButtonText>
				</>
			}>
			<Fragment>
				<Typography color="newText.primary" variant="body2" sx={{ mb: '24px' }}>
					Select which {type}(s) you would like to share this template with.
				</Typography>
				<StyledTextField
					label="Search"
					value={searchQuery}
					size="small"
					onChange={(event: React.ChangeEvent<HTMLInputElement>) => searchHandler(event)}
					sx={{
						mb: '16px',
						width: '520px',
						maxWidth: '100%',
					}}
					InputProps={{
						startAdornment: (
							<InputAdornment position="start">
								<Search color="primary" />
							</InputAdornment>
						),
						endAdornment: !!searchQuery.length && (
							<InputAdornment position="end" onClick={() => clearSearchHandler()}>
								<Close color="primary" sx={{ cursor: 'pointer	' }} />
							</InputAdornment>
						),
					}}
				/>
				<Box
					sx={{
						position: 'relative',
						minHeight: searchQuery.length > 2 ? '285px' : 'unset',
						width: '100%',
					}}>
					{isLoading ? (
						<StyledCircularProgress size={24} variant="indeterminate" />
					) : (
						<ProjectTable
							rows={items}
							selectedProject={selectedIds}
							selectProject={handleSelectProject}
							isAssign
						/>
					)}
				</Box>
			</Fragment>
		</Dialog>
	);
};
