import { AddCircle } from '@mui/icons-material';
import { Button } from '@mui/material';
import styled from 'styled-components';

export const SidebarContainer = styled.div`
	background-color: #191919;
	width: 20vw;
	display: flex;
	flex-direction: column;
	box-shadow: 0px 2px 5px 1px rgba(0, 0, 0, 0.25);
	padding: 20px;
	height: 100%;
	box-sizing: border-box;
	overflow: hidden;
	justify-content: center;
	align-self: center;
	border-radius: 40px;

	@media (max-width: 1600px) {
		width: 30vw; /* Adjusted for tablets and down */
	}
`;
export const NewNodeContainer = styled.div`
	align-self: center;
	margin-top: auto;
	margin-bottom: 30px;
	display: flex;
	flex-direction: column;
	gap: 15px;
	padding: 10px;
	color: #eef0f5;

	&:hover {
		transform: scale(1.1);
		color: #ffb310;
	}
`;

export const PlusButton = styled(AddCircle)`
	border-radius: 100px;
	align-self: center;
	font-size: 3rem;

	@media (max-width: 768px) {
		font-size: 2.5rem;
	}

	@media (max-width: 480px) {
		font-size: 2rem;
	}
`;

export const AddNewNodeText = styled.span`
	font-family: 'Epilogue';
	font-weight: 400;
	font-size: 1.2em;
	font-size: 1.2em;
	text-align: center;
	@media (max-width: 768px) {
		font-size: 1.1em;
	}
	@media (max-width: 480px) {
		font-size: 1em;
	}
`;

export const NodeContainer = styled.div`
	display: flex;
	flex-grow: 1;
	overflow-y: auto;
	flex-direction: column;
	justify-content: flex-start;
	gap: 20px;
	padding-bottom: 10px;
	overflow-x: hidden;
`;

export const OverviewChip = styled(Button)`
	display: flex;
	height: 50px;
	border: 1px solid #eef0f5;
	background-color: transparent;
	align-self: center;
	width: 90%;
	border-radius: 30px;
	justify-content: center;
	text-transform: none;
	margin-bottom: 20px;

	&:hover {
		opacity: 0.8;
		transform: scale(1.1);
	}
`;

export const OverviewChipTitle = styled.span`
	display: flex;
	align-self: center;
	font-family: 'Epilogue';
	color: #eef0f5;
	font-weight: 400;
	font-size: 1.2em;
`;

export const ChildNodeChip = styled.div`
	display: flex;
	flex-direction: column;
	min-height: 40px;
	background-color: #eef0f5;
	box-shadow: inset 0px -2px 10px 0.5px rgba(0, 0, 0, 0.15);
	padding: 10px 20px;
	align-self: center;
	width: 80%;
	border-radius: 30px;
	justify-content: center;
	text-transform: none;
	cursor: pointer;
	pointer-events: auto;
	transition: 0.2s;

	&:hover {
		opacity: 0.8;
		transform: scale(1.05);
		z-index: 1;
	}
`;

export const ChildChipTitle = styled.span`
	display: flex;
	align-self: center;
	font-family: 'Epilogue';
	color: black;
	font-weight: 300;
	font-size: 1em;
	white-space: normal;
	overflow-wrap: break-word;
	word-break: break-all;
`;
