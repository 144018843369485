import { useState } from 'react';
import Layout from '../../components/reusable-components/Layout';
import { V2FormGalleryProvider } from './V2FormGalleryProvider';
import V2FormGalleryRenderer from './components/V2FormGalleryRenderer';

export default function CaptureFormGalleryV2() {
	const [projectAddress, setProjectAddress] = useState<string | null>(null);

	return (
		<Layout title={projectAddress || ''}>
			<V2FormGalleryProvider>
				<V2FormGalleryRenderer setProjectAddress={setProjectAddress} />
			</V2FormGalleryProvider>
		</Layout>
	);
}
