import { FieldLabel } from '../../../../../../screens/ProjectInfo';
import { AuthContext } from '../../../../../../context/AuthProvider';
import { ProjectData } from '../../../../../../types';
import { fetchEquipment } from '../../../../../../utils/fetchEquipment';
import React, { useContext, useEffect, useState } from 'react';
import EquipmentChips from './EquipmentChips';
import { ProjectContext } from '../../../../../../context/ProjectProvider';

interface EquipmentProps {
	isActive: boolean;
	partialFormState: Partial<ProjectData>;
	setPartialFormState: React.Dispatch<React.SetStateAction<Partial<ProjectData>>>;
}

export default function Equipment({
	isActive,
	partialFormState,
	setPartialFormState,
}: EquipmentProps) {
	const { user } = useContext(AuthContext);
	const { project } = useContext(ProjectContext);

	const [equipmentOptions, setEquipmentOptions] = useState<string[]>([]);
	const equipment = project?.equipment || [];

	useEffect(() => {
		const getEquipmentOptions = async () => {
			const options = await fetchEquipment();
			setEquipmentOptions(options);
		};
		getEquipmentOptions();
	}, []);

	return (
		<div className="infoRow">
			<FieldLabel label={'Equipment'} />
			{user?.isAdmin && isActive ? (
				<EquipmentChips
					defaultValue={equipment}
					form={partialFormState}
					field="equipment"
					setForm={setPartialFormState}
					options={equipmentOptions}
				/>
			) : (
				<div className="fieldInfo">{project?.equipment?.join(', ')}</div>
			)}
		</div>
	);
}
