import { Fragment, useContext, useState } from 'react';
import styled from 'styled-components';

import AddIcon from '@mui/icons-material/Add';
import CallReceivedIcon from '@mui/icons-material/CallReceived';
import CallMadeIcon from '@mui/icons-material/CallMade';
import { Fab, Switch } from '@mui/material';

import { VisibleProjectStatus, projectStatusText } from '../../../../../utils';
import { AuthContext } from '../../../../../context/AuthProvider';
import { getPinSrc } from '../utils';

type MapLegendProps = {
	statuses: VisibleProjectStatus[];
	setVisibleProjectStatuses: React.Dispatch<React.SetStateAction<VisibleProjectStatus[]>>;

	showContractors: boolean;
	setShowContractors: React.Dispatch<React.SetStateAction<boolean>>;
	showProjects: boolean;
	setShowProjects: React.Dispatch<React.SetStateAction<boolean>>;

	setShowingDialog: React.Dispatch<React.SetStateAction<boolean>>;
	setShowingCDialog: React.Dispatch<React.SetStateAction<boolean>>;
};

export default function MapLegend({
	statuses,
	setVisibleProjectStatuses,

	showContractors,
	setShowContractors,
	showProjects,
	setShowProjects,

	setShowingDialog,
	setShowingCDialog,
}: MapLegendProps) {
	const { user } = useContext(AuthContext);

	const [legendExpanded, setLegendExpanded] = useState(true);

	const updateStatus = (statusName: string) =>
		setVisibleProjectStatuses(prev =>
			prev.map(status => {
				if (status.status === statusName) {
					return { ...status, visible: !status.visible };
				}
				return status;
			})
		);

	return (
		<Legend>
			{legendExpanded ? (
				<LegendContent>
					<MinimizeLegendButton
						title="Minimize legend"
						onClick={() => setLegendExpanded(false)}>
						<CallReceivedIcon />
					</MinimizeLegendButton>

					<LegendGrid>
						{statuses.map(item => {
							return (
								<Fragment key={item.status}>
									<Switch
										color="primary"
										size="small"
										checked={item.visible}
										onChange={() => updateStatus(item.status)}
									/>
									<LegendContainer>
										<LegendIcon
											key={item.status + 'icon'}
											src={getPinSrc(item.status)}
											alt=""
										/>
										<LegendText key={item.status + 'text'}>
											{projectStatusText(item.status)}
										</LegendText>
									</LegendContainer>
								</Fragment>
							);
						})}

						<Switch
							color="primary"
							size="small"
							checked={showProjects}
							onChange={() => {
								setShowProjects(prev => !prev);
							}}
						/>
						<LegendText>Toggle Project Markers</LegendText>

						{user?.isAdmin ? (
							<>
								<Switch
									color="primary"
									size="small"
									checked={showContractors}
									onChange={() => {
										setShowContractors(prev => !prev);
									}}
								/>
								<LegendText>Toggle Contractor Markers</LegendText>
							</>
						) : null}
					</LegendGrid>
				</LegendContent>
			) : (
				<MaximizeLegendButton
					title="Expand legend"
					onClick={() => setLegendExpanded(true)}>
					<CallMadeIcon />
				</MaximizeLegendButton>
			)}

			{user?.isAdmin ? (
				<CreateButtonsContainer legendExpanded={legendExpanded}>
					<CreateButton
						title="Create a new project"
						color="primary"
						onClick={() => {
							setShowingDialog(prev => !prev);
						}}>
						<AddIcon />
					</CreateButton>

					<CreateButton
						title="Add a new contractor"
						style={{ backgroundColor: '#ea4335' }}
						onClick={() => {
							setShowingCDialog(prev => !prev);
						}}>
						<AddIcon />
					</CreateButton>
				</CreateButtonsContainer>
			) : null}
		</Legend>
	);
}

const Legend = styled.div`
	position: absolute;
	left: 5px;
	bottom: 30px;

	display: flex;
	align-items: flex-end;
	gap: 5px;
`;

const LegendContent = styled.div`
	position: relative;
	background-color: white;
	border-radius: 5px;
`;

const LegendGrid = styled.div`
	display: grid;
	grid-template-columns: auto auto;
	column-gap: 5px;
	row-gap: 5px;
	padding: 5px;
	align-items: center;
`;

const MinimizeLegendButton = styled.button`
	position: absolute;
	right: 5px;
	top: 5px;
	height: 40px;
	width: 40px;
`;

const MaximizeLegendButton = styled.button`
	height: 40px;
	width: 40px;
`;

const LegendIcon = styled.img`
	height: 25px;
`;

const LegendText = styled.span``;

const LegendContainer = styled.div`
	display: flex;
	align-items: center;
	gap: 10px;
`;

const CreateButtonsContainer = styled.div<{ legendExpanded: boolean }>`
	display: flex;
	gap: 5px;
	flex-direction: ${({ legendExpanded }) => (legendExpanded ? 'column' : 'row')};
`;

const CreateButton = styled(Fab)`
	height: 40px;
	width: 40px;
`;
