import { useContext, useState, useEffect } from 'react';
import { Row } from 'react-table';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';

import DomainAddOutlinedIcon from '@mui/icons-material/DomainAddOutlined';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import FlagIcon from '@mui/icons-material/Flag';

import AddProjectForm from '../../../../../components/reusable-components/AddProjectForm';
import { createLeadFlag, deleteLead, deleteLeadFlags } from '../../../../../firebase';
import styled from 'styled-components';
import BasicIconButton from '../../../../../components/reusable-components/BasicIconButton';
import { Lead, LeadFlag } from '../../../types';
import { LeadsContext } from '../../LeadsProvider';
import { getEmailDomain } from '../../../../../utils';
import LoadingScreen from '../../../../../components/reusable-components/LoadingScreen';

const commonEmailDomains = [
	'gmail.com',
	'yahoo.com',
	'hotmail.com',
	'aol.com',
	'msn.com',
	'outlook.com',
];

export default function LeadButtons({ row }: { row: Row<Lead> }) {
	const { setLeads, leadFlags } = useContext(LeadsContext);

	const [addProject, setAddProject] = useState(false);
	const [deletingLead, setDeletingLead] = useState(false);
	const [openFlagDialog, setOpenFlagDialog] = useState(false);
	const [loadingMessage, setLoadingMessage] = useState('');
	const [matchedFlag, setMatchedFlag] = useState<LeadFlag | null>(null);

	const leadId = row.original.id;
	const [company, setCompany] = useState(row.original.company.toLowerCase());
	const [emailDomain, setEmailDomain] = useState(
		getEmailDomain(row.original.email.toLowerCase())
	);
	const leadsAddition: boolean = true;

	useEffect(() => {
		setCompany(prev => {
			const newCompany = row.original.company.toLowerCase();
			if (prev !== newCompany) return newCompany;
			else return prev;
		});
	}, [row.original.company]);

	useEffect(() => {
		setEmailDomain(prev => {
			const newEmailDomain = getEmailDomain(row.original.email.toLowerCase());
			if (prev !== newEmailDomain) return newEmailDomain;
			else return prev;
		});
	}, [row.original.email]);

	useEffect(() => {
		if (!leadFlags.length) setMatchedFlag(null);
		else {
			const matched = leadFlags.some(flag => {
				if (
					(flag.leadId && leadId === flag.leadId) ||
					(flag.company && company === flag.company) ||
					(flag.emailDomain && emailDomain === flag.emailDomain)
				) {
					setMatchedFlag(flag);
					return true;
				} else return false;
			});
			if (!matched) setMatchedFlag(null);
		}
	}, [leadFlags, company, leadId, emailDomain]);

	const closeFlagDialog = () => {
		setOpenFlagDialog(false);
		setTimeout(() => setLoadingMessage(''), 150);
	};

	const flagLead = async () => {
		setLoadingMessage('Flagging lead...');
		await createLeadFlag({
			leadId,
		});
		closeFlagDialog();
	};

	const flagCompany = async () => {
		setLoadingMessage('Flagging company...');
		const companyFlag: any = { company };
		if (emailDomain && !commonEmailDomains.includes(emailDomain)) {
			companyFlag.emailDomain = emailDomain;
		}
		await createLeadFlag(companyFlag);
		closeFlagDialog();
	};

	const removeFlag = async () => {
		if (matchedFlag) {
			setLoadingMessage('Removing flag...');
			await deleteLeadFlags({ flagIds: [matchedFlag.id] });
			closeFlagDialog();
		}
	};

	return (
		<>
			<BasicIconButton
				title="Convert lead to project"
				onClick={() => setAddProject(true)}>
				<DomainAddOutlinedIcon />
			</BasicIconButton>

			<BasicIconButton
				title="Delete lead"
				onClick={async () => {
					setDeletingLead(true);
					await deleteLead({ id: leadId });
					setLeads(prev => {
						const idx = prev.findIndex(lead => lead.id === leadId);
						const tmp = [...prev];
						tmp.splice(idx, 1);
						return tmp;
					});
					setDeletingLead(false);
				}}>
				<DeleteOutlineIcon />
			</BasicIconButton>

			<FlagButton $isFlagged={!!matchedFlag} onClick={() => setOpenFlagDialog(true)} />

			<AddProjectForm
				showingDialog={addProject}
				setShowingDialog={setAddProject}
				clients={row.original.company ? [row.original.company] : []}
				accountManager={row.original.accountManager || {}}
				leadsAddition={leadsAddition}
				orgRequired={false}
			/>

			<Dialog open={deletingLead}>
				<DialogContent>
					<LoadingScreen message="Deleting lead..." textColor="black" />
				</DialogContent>
			</Dialog>

			<Dialog
				open={openFlagDialog}
				onClose={() => {
					if (!loadingMessage) closeFlagDialog();
				}}>
				<DialogTitle>Lead Flagging</DialogTitle>

				<DialogContent>
					{!loadingMessage ? (
						!matchedFlag ? (
							<>
								<StyledButton onClick={flagLead}>Flag Lead</StyledButton>
								<StyledButton onClick={flagCompany}>Flag Company</StyledButton>
							</>
						) : (
							<>
								<span>
									{`${
										matchedFlag.leadId ? 'This lead' : "This lead's company"
									} was flagged by ${matchedFlag.flaggerName} (${
										matchedFlag.flaggerEmail
									}) on ${(matchedFlag.timestamp as any).toDate().toLocaleString()}.`}
								</span>
								{matchedFlag.company ? (
									<strong>
										Removing this flag will also unflag all other leads with the same
										company.
									</strong>
								) : null}
								<StyledButton onClick={removeFlag}>Remove Flag</StyledButton>
							</>
						)
					) : (
						<LoadingScreen message={loadingMessage} textColor="black" />
					)}
				</DialogContent>

				<DialogActions>
					<Button onClick={closeFlagDialog} disabled={!!loadingMessage}>
						Close
					</Button>
				</DialogActions>
			</Dialog>
		</>
	);
}

const FlagButton = styled(FlagIcon)<{ $isFlagged: boolean }>`
	display: block;
	flex-shrink: 0;
	cursor: pointer;
	transition: 0.2s;
	transform-origin: 0 0;
	color: ${({ $isFlagged }) => (!$isFlagged ? 'white' : 'red')};

	&:hover,
	:focus-visible {
		color: #ffb310;
	}

	&:active {
		color: white;
		transform: scale(0.9);
	}
`;

const StyledButton = styled(Button)`
	background-color: black;
`;
