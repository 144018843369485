import { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../../../context/AuthProvider';
import styled from 'styled-components';
import ScopeCheckbox from './components/ScopeCheckbox';
import {
	ArrowContainer,
	ScopeContainer,
	ScopeTitle,
	ScopeTitleContainer,
} from './styledComponents';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { ProjectContext } from '../../../context/ProjectProvider';
import { CachedProjectScoping, ProjectScopeArea, ProjectScopingData } from './types';
import { doc, onSnapshot, setDoc } from 'firebase/firestore';
import { db } from '../../../firebase';
import ScopeSelect from './components/ScopeSelect';

interface ProjectScopingProps {
	darkMode?: boolean;
	updateCallback?: (updatedData: ProjectScopingData) => void;
}

export default function ProjectScoping({
	darkMode = true,
	updateCallback,
}: ProjectScopingProps) {
	const { user } = useContext(AuthContext);
	const { project } = useContext(ProjectContext);

	const [showTable, setShowTable] = useState(false);

	const [cachedProjectScoping, setCachedProjectScoping] = useState<
		CachedProjectScoping | undefined
	>(undefined);
	const [projectScopingData, setProjectScopingData] = useState<
		ProjectScopingData | undefined
	>(undefined);

	const projectScopeDocRef = project?.id
		? doc(db, `projects/${project?.id}/private/projectScoping`)
		: null;

	// Listen to cached global project scoping.
	useEffect(() => {
		let unsub = () => {};

		if (user) {
			unsub = onSnapshot(doc(db, `cached_data/projectScoping`), snap => {
				if (snap.exists()) {
					setCachedProjectScoping(snap.data() as CachedProjectScoping);
				} else {
					setCachedProjectScoping(undefined);
				}
			});
		} else {
			unsub();
			unsub = () => {};
			setCachedProjectScoping(undefined);
		}

		return () => {
			unsub();
		};
	}, [user]);

	// Fetch project scope data from Firestore.
	useEffect(() => {
		let unsub = () => {};
		if (project?.id) {
			unsub = onSnapshot(
				doc(db, `projects/${project?.id}/private/projectScoping`),
				snap => {
					if (snap.exists()) {
						setProjectScopingData(snap.data() as ProjectScopingData);
					} else {
						setProjectScopingData(undefined);
					}
				}
			);
		} else {
			unsub();
			unsub = () => {};
			setProjectScopingData(undefined);
		}

		return () => {
			unsub();
		};
	}, [project?.id]);

	const updateProjectScope = async (
		scopeName: string,
		scopeAreas: { name: ProjectScopeArea; value: boolean | string }[]
	) => {
		const scopes = projectScopingData?.scopes || [];
		const idx = scopes.findIndex(scope => scope.name === scopeName);
		if (idx > -1) {
			scopes.splice(idx, 1, {
				name: scopeName,
				areas: scopeAreas,
			});
		} else {
			scopes.push({
				name: scopeName,
				areas: scopeAreas,
			});
		}
		const updatedData: ProjectScopingData = {
			scopes: scopes,
		};

		if (projectScopeDocRef) {
			await setDoc(projectScopeDocRef, updatedData);
		} else {
			setProjectScopingData(updatedData);
		}

		if (updateCallback) {
			updateCallback(updatedData);
		}
	};
	return cachedProjectScoping ? (
		<ScopeContainer>
			<ScopeTitleContainer>
				<ScopeTitle onClick={() => setShowTable(prev => !prev)}>Scope</ScopeTitle>
				<ArrowContainer>
					{showTable ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
				</ArrowContainer>
			</ScopeTitleContainer>

			{showTable ? (
				<TableContainer>
					<ProjectScopingTable $darkMode={darkMode}>
						<thead>
							<tr>
								<th>Scope of Work</th>
								{cachedProjectScoping.allScopeAreas.map(scopeArea => (
									<th key={scopeArea}>
										{scopeArea === 'Above Ceiling' ? 'Abv Ceiling' : scopeArea}
									</th>
								))}
							</tr>
						</thead>

						<tbody>
							{cachedProjectScoping.scopes.map(cachedScope => {
								const projScopeIdx = projectScopingData
									? projectScopingData?.scopes?.findIndex(
											projScope => projScope.name === cachedScope.name
									  )
									: -1;
								const projScope =
									projScopeIdx > -1
										? projectScopingData?.scopes?.[projScopeIdx]
										: undefined;

								return (
									<tr key={cachedScope.name}>
										<td>{cachedScope.name}</td>
										{cachedProjectScoping.allScopeAreas.map(cachedScopeArea => {
											const areaIdx = projScope
												? projScope.areas?.findIndex(
														area => area.name === cachedScopeArea
												  )
												: -1;
											const projScopeArea =
												areaIdx > -1 ? projScope?.areas?.[areaIdx] : undefined;

											const onChange = async (newValue: boolean | string) => {
												const updatedArea = {
													name: cachedScopeArea,
													value: newValue,
												};
												const updatedAreaArr = [...(projScope?.areas || [])];
												if (projScope?.areas && areaIdx > -1) {
													updatedAreaArr.splice(areaIdx, 1, updatedArea);
												} else {
													updatedAreaArr.push(updatedArea);
												}

												await updateProjectScope(cachedScope.name, updatedAreaArr);
											};

											return (
												<ScopeInputCell key={cachedScope.name + cachedScopeArea}>
													{cachedScope.areas.includes(cachedScopeArea) ? (
														cachedScopeArea === 'BIM LOD Standards' ? (
															<ScopeSelect
																value={projScopeArea?.value as string}
																onChange={onChange}
																area={'BIM LOD Standards'}
															/>
														) : (
															<ScopeCheckbox
																checked={Boolean(projScopeArea?.value)}
																onChange={onChange}
															/>
														)
													) : (
														'-'
													)}
												</ScopeInputCell>
											);
										})}
									</tr>
								);
							})}
						</tbody>
					</ProjectScopingTable>
				</TableContainer>
			) : null}
		</ScopeContainer>
	) : null;
}

const TableContainer = styled.div`
	width: 100%;
	overflow-x: auto;
	border: 1px solid #ffb310;
`;

const ProjectScopingTable = styled.table<{ $darkMode: boolean }>`
	border-collapse: collapse;
	border-style: hidden;
	width: 100%;

	th,
	td {
		border: 1px solid #ffb310;
		padding: 5px;
	}
`;

const ScopeInputCell = styled.td`
	> * {
		margin: auto;
	}
	text-align: center;
`;
