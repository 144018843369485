import { MenuItem, Tooltip } from '@mui/material';
import { ProjectData, UserObj } from '../../../types';
import styled from 'styled-components';
import { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../../../context/AuthProvider';
import { ProjectRole } from '../../../types/project-roles';
import { getCount, getToolTip } from './utils';

type ProjectRoleOptionProps = {
	props: React.HTMLAttributes<HTMLLIElement>;
	userObj: UserObj;
	role: ProjectRole;
	project: ProjectData;
};

export default function ProjectRoleOption({
	props,
	userObj,
	role,
	project,
}: ProjectRoleOptionProps) {
	const { user } = useContext(AuthContext);

	const [count, setCount] = useState<number | undefined>();

	useEffect(() => {
		if (user?.isAdmin) {
			getCount(role, userObj, project.projectStatus).then(count => setCount(count));
		}
	}, [project.projectStatus, role, user?.isAdmin, userObj]);

	return (
		<MenuItem {...props} value={userObj as any} divider>
			<ItemWrapper>
				<UserOption>
					<span style={{ color: 'black' }}>{userObj.name}</span>
					<span style={{ color: 'grey' }}>{userObj.email}</span>
				</UserOption>

				<Tooltip title={getToolTip(userObj.name, role, count, project.projectStatus)}>
					<span>{count}</span>
				</Tooltip>
			</ItemWrapper>
		</MenuItem>
	);
}

const ItemWrapper = styled.div`
	display: flex;
	gap: 20px;
	align-items: center;
	justify-content: space-between;
	width: 100%;
`;

const UserOption = styled.div`
	display: flex;
	flex-direction: column;
`;
