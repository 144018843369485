import { useEffect, useState } from 'react';
import { YellowTextField } from '../../styled-components/styledReactTable';
import { debounce } from 'lodash';
import { ReportQuestion } from '../../../types/reports';

interface ReportQuestionFilterProps {
	allQuestions: ReportQuestion[];
	setFilteredReportQuestions: React.Dispatch<React.SetStateAction<ReportQuestion[]>>;
}

export default function ReportQuestionFilter({
	allQuestions,
	setFilteredReportQuestions,
}: ReportQuestionFilterProps) {
	const [questionFilter, setQuestionFilter] = useState('');

	const filterQuestions = debounce((filterStr: string) => {
		setFilteredReportQuestions(() => {
			if (!filterStr) return allQuestions;
			else {
				return allQuestions.filter(reportQuestion =>
					reportQuestion.question.toLowerCase().includes(filterStr.toLowerCase())
				);
			}
		});
	}, 500);

	useEffect(() => {
		filterQuestions(questionFilter);
	}, [filterQuestions, questionFilter]);

	return (
		<YellowTextField
			sx={{ flexGrow: '1' }}
			placeholder="Filter questions..."
			value={questionFilter}
			onChange={e => setQuestionFilter(e.target.value)}
		/>
	);
}
