import { useState, useEffect } from 'react';
import usePlacesAutocomplete from 'use-places-autocomplete';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import './PlacesAutocomplete.css';
import styled from 'styled-components';
import theme from '../../../styles/theme';

export interface PlacesAutocompleteProps {
	formState: { [key: string]: any };
	setFormState: React.Dispatch<React.SetStateAction<{ [key: string]: any }>>;
	addressField: string;
	required?: boolean;
	style?: React.CSSProperties;
	variant?: 'standard' | 'outlined' | 'filled';
	isStyled: boolean;
	label?: string;
	isProjectPage?: boolean;
	addressColor?: string;
}

/**
 * Functional component that returns an input field for entering an address, used in forms. Displays suggestions from Google based on what is typed into the input field
 * @param formFolderName Object useState containing all form/field values
 * @param setFormFolderName useState updater for formFolderName
 * @param addressField string denoting the field name in the form that holds the address
 * @param required boolean determining whether or not the address input field is required to submit the form
 * @param style style Object for the component container
 * @param variant string determining which variant of <TextField/> is rendered
 * @param label optional string for TextField label. Defaults to "Address"
 */
export default function PlacesAutocomplete({
	formState,
	setFormState,
	addressField,
	required = false,
	style,
	variant = 'standard',
	isStyled,
	label = 'Address',
	isProjectPage = false,
	addressColor,
}: PlacesAutocompleteProps) {
	const {
		value,
		suggestions: { data },
		setValue,
	} = usePlacesAutocomplete({
		requestOptions: {
			/* Define search scope here */
		},
		debounce: 300,
		defaultValue: formState[addressField],
	});

	const [inputValue, setInputValue] = useState(formState[addressField]);
	const [options, setOptions] = useState<
		{ description: string; main_text: string; secondary_text: string }[]
	>([]);

	useEffect(() => {
		setValue(inputValue);
		if (!inputValue) setOptions([]);
	}, [inputValue, setValue]);

	useEffect(() => {
		if (data?.length) {
			setOptions(
				data.map(place => ({
					description: place.description,
					main_text: place.structured_formatting.main_text,
					secondary_text: place.structured_formatting.secondary_text,
				}))
			);
		}
	}, [data]);

	return (
		<div style={style} onClick={event => event.stopPropagation()}>
			<Autocomplete
				freeSolo
				fullWidth
				autoComplete
				value={value}
				options={options}
				onChange={(event, newValue) => {
					const address =
						typeof newValue === 'string' ? newValue : newValue?.description ?? '';
					setValue(address, false);
					setFormState(prev => ({
						...prev,
						[addressField]: address,
					}));
				}}
				getOptionLabel={option =>
					typeof option === 'string' ? option : option.description
				}
				renderOption={(props, option) => {
					const { main_text, secondary_text } = option;
					return (
						<Option {...props}>
							<strong>{main_text}</strong>
							<small>{secondary_text}</small>
						</Option>
					);
				}}
				onInputChange={(event, newInputValue) => {
					setInputValue(newInputValue);
					setFormState(prev => ({
						...prev,
						[addressField]: newInputValue,
					}));
				}}
				renderInput={props =>
					isStyled ? (
						<StyledTextField
							{...props}
							isProjectPage={isProjectPage}
							addressColor={addressColor}
							label=""
							variant={variant}
							required={required}
							InputProps={{
								...props.InputProps,
								required: required ? value.length === 0 : undefined,
							}}
							fullWidth
							sx={{ height: '100%', borderRadius: '5px' }}
						/>
					) : (
						<TextField
							{...props}
							label={label}
							variant={variant}
							required={required}
							InputProps={{
								...props.InputProps,
								required: required ? value.length === 0 : undefined,
							}}
						/>
					)
				}
				isOptionEqualToValue={(option, val) => {
					return option.description === val.description;
				}}
				onClick={event => event.stopPropagation()}
			/>
		</div>
	);
}

const Option = styled.li`
	display: flex;
	flex-direction: column;
	align-items: start !important;
	padding: 0;
`;

const StyledTextField = styled(TextField)<{
	$hasLineBreaks?: boolean;
	isProjectPage?: boolean;
	addressColor?: string;
}>`
	width: 100%;

	${({ isProjectPage }) =>
		isProjectPage
			? `
			&:hover {
				border: 1px solid ${theme.palette.primary.main};
			}
	`
			: `
			border: 1px solid ${theme.palette.primary.main};
			`}
			
		.MuiAutocomplete-endAdornment {
			cursor: pointer;
			filter: ${({ isProjectPage }) =>
				isProjectPage
					? 'invert(1)'
					: 'invert(73%) sepia(94%) saturate(1834%) hue-rotate(349deg) brightness(104%) contrast(101%)'};
				contrast(101%);

			&:hover,
			:focus-visible {
				filter: ${({ isProjectPage }) =>
					isProjectPage
						? 'invert(73%) sepia(94%) saturate(1834%) hue-rotate(349deg) brightness(104%) contrast(101%)'
						: 'invert(1)'};
			}
		}

	> .MuiInputBase-root {
		width: 100%;
		padding: 5px;

		${({ isProjectPage, addressColor }) =>
			isProjectPage
				? `
				color: ${addressColor};
				&:hover {
					color: ${theme.palette.primary.main};
					}
					`
				: `
					color: ${theme.palette.primary.main};
			`}

		> input {
			cursor: text;
			line-height: normal;
			padding: 10px;
		}

		> textarea {
			padding: ${({ $hasLineBreaks }) => (!$hasLineBreaks ? '10px' : '5px')};
			color: #ffb310;
		}

		> fieldset {
			transition: 0.2s;
			border-color: #ffb310;
			border-width: 2px;
		}

		&:hover,
		:focus-within {
			> fieldset {
				border-color: white;
			}
		}
		&:focus-within > fieldset {
			border-color: white;
		}
	}

	.MuiInput-underline:before,
	.MuiInput-underline:after {
		display: none;
	}
`;
