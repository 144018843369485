import { FC } from 'react';
import { FormPlaceholderProps } from './FormPlaceholder.types';
import { FormHeader } from '../FormHeader';
import FormFooter from '../FormFooter/FormFooter';

const FormPlaceholder: FC<FormPlaceholderProps> = ({ node }) => {
	switch (node.id) {
		case 'header':
			return <FormHeader />;
		case 'footer':
			return <FormFooter />;
		default:
			return null;
	}
};

export default FormPlaceholder;
