import { useCallback, useContext, useState } from 'react';
import { SnackContext } from '../../../../../context/SnackProvider';
import { arrayUnion, doc, getDoc, serverTimestamp, updateDoc } from 'firebase/firestore';
import { db, storage } from '../../../../../firebase';
import {
	CoverImage,
	FormNode,
} from '../../../../screen-components/ProjectUtilityFormV2/utils/types';
import { StyledButton } from './InfoDialog.styles';
import { Dialog } from '../../../Dialog';
import { StyledTextField } from '../../../StyledTextField';
import theme from '../../../../../styles/theme';
import styled from 'styled-components';
import { closestCenter, DndContext, DragEndEvent } from '@dnd-kit/core';
import { arrayMove, SortableContext } from '@dnd-kit/sortable';
import CoverImageItem from '../../../CoverImage/CoverImageItem';
import CoverImageUpload from '../../../CoverImage/CoverImageUpload';
import UploadImageDialog, {
	UploadImageDialogData,
} from '../../../../reusable-components/UploadImageDialog';
import {
	deleteObject,
	getDownloadURL,
	ref,
	uploadBytesResumable,
} from 'firebase/storage';

type InfoDialogProps = {
	open: boolean;
	node: FormNode;
	onClose: () => void;
	templateSave?: (info?: string) => void;
};

const emptyState = {
	downloadURL: '',
	name: '',
	filepath: '',
};

const InfoDialog = ({ templateSave, open, node, onClose }: InfoDialogProps) => {
	const { setSnackbarProps } = useContext(SnackContext);
	const [info, setInfo] = useState(node.info);
	const [referencePhotos, setReferencePhotos] = useState<CoverImage[]>(
		node.referencePhotos || []
	);
	const [editData, setEditData] = useState<CoverImage>(emptyState);
	const [openDialog, setOpenDialog] = useState<boolean>(false);

	const handleInfoSubmit = async () => {
		if (templateSave) {
			templateSave(info);
			onClose();
			return;
		}

		const itemRef = doc(db, `utility_forms_v2_items/${node.id}`);
		const itemSnap = await getDoc(itemRef); // get doc snapshot

		if (!itemSnap.exists()) {
			onClose(); // Close the dialog
			setSnackbarProps({
				open: true,
				message: 'Another user deleted this item.',
				severity: 'error',
				hideDuration: 6000,
			});
			return;
		}

		try {
			onClose();

			await updateDoc(itemRef, { info: info });

			setSnackbarProps({
				open: true,
				message: 'Description saved successfully!',
				severity: 'success',
				hideDuration: 6000,
			});
		} catch (error) {
			console.error('Error saving info: ', error);
			setSnackbarProps({
				open: true,
				message: 'Error saving info. Please try again!',
				severity: 'error',
				hideDuration: 6000,
			});
		}
	};

	const handleKeyDown = async (e: React.KeyboardEvent<HTMLDivElement>) => {
		if (e.key === 'Enter' && !e.shiftKey) {
			e.preventDefault();
			handleInfoSubmit();
		}
	};

	const uploadHandler = () => {
		setEditData(emptyState);
		setOpenDialog(true);
	};

	const updateReferencePhotos = useCallback(
		async (value: any): Promise<void> => {
			if (value === undefined) return;

			const itemRef = doc(db, `utility_forms_v2_items/${node.id}`);
			await updateDoc(itemRef, {
				referencePhotos: value,
				updatedAt: serverTimestamp(),
			});
		},
		[node.id]
	);

	const handleDragEnd = useCallback(
		(event: DragEndEvent) => {
			const { active, over } = event;

			if (over && active.id !== over.id) {
				const oldIndex = referencePhotos.findIndex(
					item => item.downloadURL === active.id
				);
				const newIndex = referencePhotos.findIndex(item => item.downloadURL === over.id);

				const newPhotos = arrayMove(referencePhotos, oldIndex, newIndex);

				setReferencePhotos(newPhotos);
				updateReferencePhotos(newPhotos).catch(console.error);
			}
		},
		[referencePhotos, updateReferencePhotos]
	);

	const uploadImage = async (value: UploadImageDialogData) => {
		const filePath = `/utility_forms_v2/${node.formId}/reference/${value.name}`;
		const storageRef = ref(storage, filePath);
		const uploadTask = uploadBytesResumable(storageRef, value.newFile);

		await uploadTask.on(
			'state_changed',
			snapshot => {
				const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;

				value.onProgress(Number(progress.toFixed(0)));
			},
			error => {
				console.error('Failed to upload image:', error);
			},
			async () => {
				await getDownloadURL(uploadTask.snapshot.ref).then(async downloadURL => {
					const itemRef = doc(db, `utility_forms_v2_items/${node.id}`);

					const obj = {
						downloadURL,
						name: value.name,
						filepath: filePath,
					};

					await updateDoc(itemRef, {
						referencePhotos: arrayUnion(obj),
					});
					setReferencePhotos([...referencePhotos, obj]);
				});
			}
		);
	};

	const deleteImage = async (value: UploadImageDialogData) => {
		const imageRef = ref(storage, value.oldURL);
		const docRef = doc(db, `utility_forms_v2_items`, node.id);
		const updatedArray = node.referencePhotos?.filter(
			(item: CoverImage) => item.downloadURL !== value.oldURL
		);

		console.log(updatedArray);

		await deleteObject(imageRef);
		await updateDoc(docRef, { referencePhotos: updatedArray });
		if (updatedArray) setReferencePhotos(updatedArray);
	};

	return (
		<>
			<Dialog
				open={open}
				onClose={onClose}
				title="Node description"
				bottomActions={<StyledButton onClick={handleInfoSubmit}>Save</StyledButton>}>
				<Container>
					<StyledTextField
						multiline
						value={info}
						onChange={e => setInfo(e.target.value)}
						onKeyDown={handleKeyDown}
						minRows={8}
						maxRows={12}
						autoFocus
						fullWidth
						inputProps={{ style: { color: theme.palette.newText.primary } }}
					/>
					<DndContext onDragEnd={handleDragEnd} collisionDetection={closestCenter}>
						<SortableContext items={referencePhotos.map(photo => photo.downloadURL)}>
							<CoverImageContainer>
								{referencePhotos.map((item, index) => (
									<CoverImageItem
										key={index}
										index={index}
										image={item}
										handleClick={() => {
											setEditData(item);
											setOpenDialog(true);
										}}
									/>
								))}
								<CoverImageUpload onClick={() => uploadHandler()} />
							</CoverImageContainer>
						</SortableContext>
					</DndContext>
				</Container>
			</Dialog>
			{editData && !!Object.keys(editData).length && (
				<UploadImageDialog
					openDialog={openDialog}
					handleClose={() => setOpenDialog(false)}
					image={editData}
					dialogTitle="Add reference photo"
					uploadImageFunc={uploadImage}
					deleteImageFunc={deleteImage}
				/>
			)}
		</>
	);
};

export default InfoDialog;

const Container = styled.div``;

export const CoverImageContainer = styled.div`
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	gap: 16px;
	width: 100%;
	padding: 16px 0;
`;
