import styled from 'styled-components';

export default function BasicIconButton({
	children,
	href,
	title,
	onClick,
}: {
	children: React.ReactNode;
	href?: string;
	title?: string;
	onClick?: () => void;
}) {
	return (
		<Container
			href={href}
			title={title}
			onClick={onClick}
			target="_blank"
			rel="noopener noreferrer">
			{children}
		</Container>
	);
}

const Container = styled.a`
	display: block;
	flex-shrink: 0;
	cursor: pointer;
	transition: 0.2s;
	transform-origin: 0 0;
	color: white;

	&:hover,
	:focus-visible {
		color: #ffb310;
	}

	&:active {
		color: white;
		transform: scale(0.9);
	}

	> * {
		display: block;
	}
`;
