import React, { useContext, useState } from 'react';
import { FieldLabel } from '../../../../../screens/ProjectInfo';
import { AuthContext } from '../../../../../context/AuthProvider';
import MultiLinkField from './MultiLinkField';
import { ProjectData } from '../../../../../types';

// Props for Invoice Links
interface InvoiceLinkProps {
	isActive: boolean;
	setPartialFormState: React.Dispatch<React.SetStateAction<Partial<ProjectData>>>;
}

// Invoice Links Information
export default function InvoiceLinks({
	isActive,
	setPartialFormState,
}: InvoiceLinkProps) {
	const { user } = useContext(AuthContext);
	const [editInvoiceLinks, setEditInvoiceLinks] = useState(false);

	return !user?.isContractor ? (
		<div className="infoRow">
			<FieldLabel label={'Invoice Link(s)'} />
			<MultiLinkField
				field={'invoiceLinks'}
				title={'Invoice'}
				// State needs to be set to partialFormState – See "MultiLinkField.tsx" for more
				setState={setPartialFormState}
				isActive={Boolean(user?.isAdmin && isActive)}
				isFocused={editInvoiceLinks}
				setFocused={setEditInvoiceLinks}
			/>
		</div>
	) : null;
}
