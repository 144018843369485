import DownloadIcon from '@mui/icons-material/Download';
import { useContext, useState } from 'react';
import styled from 'styled-components';
import theme from '../../../../styles/theme';
import PhotoLibraryIcon from '@mui/icons-material/PhotoLibrary';
import { ButtonMenu, MenuOption, StyledIconButton } from './components';
import { FormV2Context } from '../../../screen-components/ProjectUtilityFormV2/context/FormV2Context.types';
import useDownloadPhotos from '../../../screen-components/ProjectUtilityFormV2/components/FormV2Header/components/ToolsDropdown/useDownloadPhotos';
import { mdiFileExcel } from '@mdi/js';
import { MdiIcon } from '../../../reusable-components/MdiIcon';
import { ProjectContext } from '../../../../context/ProjectProvider';
import useXLSX from '../../../screen-components/ProjectUtilityFormV2/hooks/useXLSX';

export default function Download() {
	const { utilityForm, fetchNodesAsTree } = useContext(FormV2Context);
	const { project } = useContext(ProjectContext);

	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const open = Boolean(anchorEl);

	const handleClick = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const { downloadPhotos, safeFileName } = useDownloadPhotos();
	const downloadAllPhotos = () => {
		if (utilityForm) {
			downloadPhotos(
				utilityForm.id,
				utilityForm.projectId,
				safeFileName(utilityForm.formName)
			);
		}
	};

	const downloadXLSX = useXLSX();
	const downloadExcel = async () => {
		if (utilityForm && project) {
			const nodes = await fetchNodesAsTree();
			if (!nodes) return;
			downloadXLSX(project.storeNumber, utilityForm.formName, project.address, nodes);
		}
	};

	return (
		<>
			<StyledIconButton onClick={handleClick} title="Download Options">
				<StyledDownloadIcon />
			</StyledIconButton>

			<ButtonMenu anchorEl={anchorEl} open={open} onClose={handleClose}>
				<MenuOption
					onClick={() => {
						downloadAllPhotos();
						handleClose();
					}}>
					<PhotoLibraryIcon />
					<span>Download All Photos</span>
				</MenuOption>
				<MenuOption
					onClick={() => {
						downloadExcel();
						handleClose();
					}}>
					<MdiIcon path={mdiFileExcel} />
					<span>Download XLSX File</span>
				</MenuOption>
			</ButtonMenu>
		</>
	);
}

const StyledDownloadIcon = styled(DownloadIcon)`
	color: ${theme.palette.primary.main};
	width: 40px;
`;
