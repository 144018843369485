import { useState, useContext } from 'react';
import { Item } from '../types';
import AddIcon from '@mui/icons-material/Add';

import {
	Alert,
	AlertTitle,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	InputLabel,
	MenuItem,
	Select,
	TextField,
} from '@mui/material';

import { FormContext } from '../FormContext';

import {
	newServicePanel,
	newDisconnect,
	newElectricMeter,
	newHvac,
	newWaterHeater,
	newWaterMeter,
	newFireAlarmControlPanel,
	newCustomItem,
} from './BaseItems';
import { CategoryButton } from '../categories/CreateCategory';

export default function AddNewItem({
	categoryItems,
	categoryIndex,
}: {
	categoryItems: Item[];
	categoryIndex: number;
}) {
	const { formState, setFormState } = useContext(FormContext);

	const [open, setOpen] = useState(false);
	const [newItem, setNewItem] = useState<Item>(newCustomItem());
	const [alert, setAlert] = useState(false);

	const existingItems = formState[categoryIndex].items.map(item => item.displayTitle);

	const selectNewItem = (selectedItem: Item) => {
		const count = formState[categoryIndex].items.filter(
			item => item.type === selectedItem.type
		).length;
		if (count > 0) {
			setNewItem({
				...selectedItem,
				displayTitle: `${selectedItem.displayTitle} ${count}`,
			});
		} else {
			setNewItem(selectedItem);
		}
	};
	const addNewItem = () => {
		if (existingItems.includes(newItem.displayTitle)) {
			setAlert(true);
		} else {
			setFormState(prev => {
				const oldCategory = prev[categoryIndex];
				const updatedCategory = {
					...oldCategory,
					items: [...oldCategory.items, newItem],
				};
				prev.splice(categoryIndex, 1, updatedCategory);
				return [...prev];
			});

			handleClose();
		}
	};
	const handleOpen = () => {
		setOpen(true);
		setNewItem(newCustomItem());
	};
	const handleClose = () => {
		setOpen(false);
		setAlert(false);
	};

	return (
		<>
			<CategoryButton type="button" title="Add New Item" onClick={handleOpen}>
				<AddIcon />
			</CategoryButton>

			<Dialog open={open} onClose={() => setOpen(false)}>
				{alert && (
					<Alert severity="warning">
						<AlertTitle>Warning! Item with the same name already exits.</AlertTitle>
						Please rename the item to continue
					</Alert>
				)}
				<DialogTitle>New Item</DialogTitle>
				<DialogContent>
					<InputLabel>Item Type</InputLabel>
					<Select
						fullWidth
						label="Item Type"
						value={newItem.type}
						onChange={e => {
							switch (e.target.value) {
								case 'SERVICE_PANEL':
									selectNewItem(newServicePanel());
									break;
								case 'DISCONNECT':
									selectNewItem(newDisconnect());
									break;
								case 'ELECTRIC_METER':
									selectNewItem(newElectricMeter());
									break;
								case 'HVAC':
									selectNewItem(newHvac());
									break;
								case 'WATER_HEATER':
									selectNewItem(newWaterHeater());
									break;
								case 'WATER_METER':
									selectNewItem(newWaterMeter());
									break;
								case 'FIRE_ALARM_CONTROL_PANEL':
									selectNewItem(newFireAlarmControlPanel());
									break;
								case 'CUSTOM':
									selectNewItem(newCustomItem());
									break;
							}
						}}>
						{categoryItems.map((item, id) => (
							<MenuItem key={id} value={item.type}>
								{item.displayTitle}
							</MenuItem>
						))}
					</Select>
					<TextField
						autoFocus
						margin="dense"
						id="field"
						label="Item Name"
						fullWidth
						variant="standard"
						value={newItem.displayTitle}
						onChange={e => {
							setNewItem(prev => ({
								...prev,
								displayTitle: e.target.value,
							}));
						}}
					/>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleClose}>Cancel</Button>
					<Button onClick={addNewItem}>Add Item</Button>
				</DialogActions>
			</Dialog>
		</>
	);
}
