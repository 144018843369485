import { MenuItem, TextField } from '@mui/material';
import React from 'react';
import styled from 'styled-components';

interface Props {
	onFilterChange: (filters: { availability?: string; status?: string }) => void;
}

const ModelersTableFilters: React.FC<React.PropsWithChildren<Props>> = ({ onFilterChange }) => {
	const handleAvailabilityChange = (
		e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
	) => {
		onFilterChange({ availability: e.target.value });
	};
	const handleStatusChange = (
		e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
	) => {
		onFilterChange({ status: e.target.value });
	};

	return (
		<FilterContainer>
			<DropDown>
				<span>Filter by Availability:</span>
				<TextField
					id="filterBoxes"
					select
					SelectProps={{ displayEmpty: true }}
					defaultValue={'All'}
					onChange={handleAvailabilityChange}
					variant="standard">
					<MenuItem value="All">All</MenuItem>
					<MenuItem value="Available">Available</MenuItem>
					<MenuItem value="Busy">Busy</MenuItem>
				</TextField>
			</DropDown>
			<DropDown>
				<span>Filter by Status:</span>
				<TextField
					id="filterBoxes"
					select
					SelectProps={{ displayEmpty: true }}
					defaultValue={'All'}
					onChange={handleStatusChange}
					variant="standard">
					<MenuItem value="All">All</MenuItem>
					<MenuItem value="Ready For Modeling">Ready For Modeling</MenuItem>
					<MenuItem value="Modeling In Progress">Modeling In Progress</MenuItem>
					<MenuItem value="QA/QC">QA/QC</MenuItem>
				</TextField>
			</DropDown>
		</FilterContainer>
	);
};

export default ModelersTableFilters;

const FilterContainer = styled.div`
	display: flex;
	flex-direction: row;
	@media (max-width: 1023px) {
		flex-direction: column;
	}
	gap: 12px;
	margin: 0 auto;
	// align-items: flex-end;
`;

const DropDown = styled.div`
	display: flex;
	width: 350px;
	min-width: 350px;
	height: 44px;
	text-align: center;
	background-color: #ffb310;
	color: black;
	border-radius: 15px;

	& > span {
		margin-top: auto;
		margin-bottom: 2px;
		padding: 10px;
	}

	& > div {
		display: flex;
		flex-direction: column;
		align-items: flex-end;
		min-width: 100px;
		padding: 5px;
		margin-top: auto; //aligns the text to the bottom of the div
	}
`;
