// import { useState } from 'react';
import { HeaderGroup } from 'react-table';
import styled from 'styled-components';
import { Lead, SortState } from '../../../../types';

const noSortArr = ['indexNumber', 'checkbox', 'buttonColumn', 'lastComment'];

export default function LeadsHeaderCell({
	column,
	sortState,
	setSortState,
}: {
	column: HeaderGroup<Lead>;
	sortState: SortState;
	setSortState: React.Dispatch<React.SetStateAction<SortState>>;
}) {
	const headerTitle = column.Header;
	const field = column.id;
	const isSorted = sortState.field === column.id;
	const canSort = !noSortArr.includes(column.id);

	const handleClick = () => {
		if (!noSortArr.includes(field)) {
			setSortState(prev => {
				if (prev.field === column.id) {
					if (prev.sortType === 'desc') {
						return {
							sortType: 'asc',
						};
					} else {
						return {
							...prev,
							sortType: 'desc',
						};
					}
				} else {
					return {
						sortType: 'asc',
						field: column.id as keyof Lead,
					};
				}
			});
		}
	};

	return (
		<HeaderCell
			// {...column.getHeaderProps(column.getSortByToggleProps())}
			// isSorted={column.isSorted}
			// canSort={column.canSort}
			// title={column.canSort ? `Sort table by ${column.Header}` : ''}
			{...column.getHeaderProps()}
			isSorted={isSorted}
			canSort={canSort}
			title={canSort ? `Sort table by ${headerTitle}` : ''}
			onClick={handleClick}>
			{typeof headerTitle === 'string' ? (
				<>
					<HeaderSpan canSort={canSort}>{`${headerTitle}`}</HeaderSpan>
					{isSorted ? (
						sortState.sortType === 'desc' ? (
							<span>↓</span>
						) : (
							<span>↑</span>
						)
					) : null}
				</>
			) : null}
		</HeaderCell>
	);
}

const HeaderCell = styled.div<{ isSorted: boolean; canSort: boolean }>`
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 5px;

	padding: 5px;
	color: ${({ isSorted }) => (isSorted ? '#ffb310' : '#f6f6f6')};
	transition: 0.2s;
	cursor: ${({ canSort }) => (canSort ? 'pointer' : 'auto')};
	line-height: normal;

	&:hover {
		color: ${({ canSort }) => (canSort ? '#ffb310' : '')};
		text-shadow: ${({ canSort }) => (canSort ? '0 0 2px #ffb310' : '')};
	}
`;

const HeaderSpan = styled.span<{ canSort: boolean }>`
	&:hover {
		text-decoration: ${({ canSort }) => (canSort ? 'underline' : '')};
	}
`;
