import { FC, useState } from 'react';
import styled from 'styled-components';
import theme from '../../../styles/theme';
import { FormNode } from '../../../components/screen-components/ProjectUtilityFormV2/utils/types';
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import { FirestoreTimeStampObj } from '../../../types/firestore';

type Props = {
	fields: FormNode[];
};

const FieldsPreview: FC<Props> = ({ fields }: Props) => {
	const [open, setOpen] = useState(false);

	const processValue = (node: FormNode) => {
		if (node.type === 'date') {
			return new Date(
				(node.value as unknown as FirestoreTimeStampObj)._seconds * 1000
			).toLocaleDateString();
		} else if (node.type === 'datetime') {
			return new Date(
				(node.value as unknown as FirestoreTimeStampObj)._seconds * 1000
			).toLocaleTimeString();
		} else if (node.type === 'switch') {
			return node.value ? 'On' : 'Off';
		} else {
			return node.value as string;
		}
	};

	return (
		<Container open={open}>
			<Header open={open}>
				<Title>Tag Information</Title>
				<ButtonAction onClick={() => setOpen(!open)}>
					{open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
				</ButtonAction>
			</Header>
			{open && (
				<Body>
					{fields?.map((item, index) => (
						<BodyContent key={index} index={index} total={fields.length}>
							<Item>
								<span>{item.displayTitle}</span>
								<span>{processValue(item)}</span>
							</Item>
						</BodyContent>
					))}
				</Body>
			)}
		</Container>
	);
};

export default FieldsPreview;

const Container = styled.div<{ open: boolean }>`
	position: absolute;
	top: 16px;
	left: 20px;
	border: 1px solid ${theme.palette.primary.main};
	border-radius: 6px;
	overflow: hidden;
	width: ${({ open }) => (open ? '400px' : '200px')};
	z-index: 2;
`;

const Header = styled.div<{ open: boolean }>`
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 10px;
	background-color: ${({ open }) => (open ? theme.palette.grey[900] : 'transparent')};
`;

const Title = styled.h1`
	font-size: 16px;
	color: ${theme.palette.primary.main};
	margin: 0;
`;

const ButtonAction = styled.button`
	color: ${theme.palette.primary.main};
	background-color: transparent;
	cursor: pointer;
	border: none;
`;

const Body = styled.div`
	background-color: #000;
	border-radius: 0 0 6px 6px;
	max-height: calc(100vh - 300px);
	overflow-y: auto;
`;

const Item = styled.div`
	display: flex;
	justify-content: space-between;
	padding: 5px 5px 5px 15px;
	border-bottom: 1px solid #ccc;
	color: #fff;

	:first-child {
		margin-top: 5px;
	}

	:last-child {
		border-bottom: none;
	}

	a {
		color: #fff;
		cursor: pointer;
	}
`;

const BodyContent = styled.div<{ index: number; total: number }>`
	border-bottom: ${({ index, total }) =>
		index !== total ? `1px solid ${theme.palette.primary.main}` : 'none'};
`;
