import { createContext, useContext, useEffect, useState, useCallback } from 'react';
import { Lead, LeadFlag } from '../types';
import { db, getLeads } from '../../../firebase';
import { SnackContext } from '../../../context/SnackProvider';
import { collection, onSnapshot } from 'firebase/firestore';

export type LeadsContextType = {
	leads: Lead[];
	setLeads: React.Dispatch<React.SetStateAction<Lead[]>>;
	fetchingLeads: boolean;
	clientList: string[];
	fetchSetLeads: () => Promise<void>;
	leadFlags: LeadFlag[];
};

export const LeadsContext = createContext<LeadsContextType>({} as LeadsContextType);

export default function LeadsProvider({ children }: { children?: React.ReactNode }) {
	const { setSnackbarProps } = useContext(SnackContext);

	const [leads, setLeads] = useState<Lead[]>([]);
	const [fetchingLeads, setFetchingLeads] = useState(false);

	const [clientList, setClientList] = useState<string[]>([]);
	const [leadFlags, setLeadFlags] = useState<LeadFlag[]>([]);

	/**
	 * Function for fetching leads from Firestore and setting them to the 'leads' useState.
	 */
	const fetchSetLeads = useCallback(async () => {
		setFetchingLeads(true);
		const res = await getLeads();
		if (res.data) {
			const rawLeads = (res.data as any).leads;
			const clientSet = new Set<string>();
			const updatedLeads = rawLeads.map((lead: any) => {
				const client = `${lead.firstName} ${lead.lastName}`.trim();
				if (client) clientSet.add(client);

				return {
					...lead,
					nextActivity: lead.nextActivity
						? new Date(lead.nextActivity._seconds * 1000)
						: null,
					lastActivity: lead.lastActivity
						? new Date(lead.lastActivity._seconds * 1000)
						: null,
				};
			});
			setLeads(updatedLeads);
			setClientList(Array.from(clientSet));
		} else {
			setSnackbarProps({
				open: true,
				message: 'Error fetching leads...',
				severity: 'error',
			});
		}
		setFetchingLeads(false);
	}, [setSnackbarProps]);

	// Fetch leads and lead flags when provider loads
	useEffect(() => {
		fetchSetLeads();

		const collectionRef = collection(db, 'lead_flags');
		const unsub = onSnapshot(collectionRef, async snapshot => {
			const flags = [];
			for (const doc of snapshot.docs) {
				flags.push({
					...doc.data(),
					id: doc.id,
				} as LeadFlag);
			}
			setLeadFlags(flags);
		});

		return () => {
			unsub();
		};
	}, [fetchSetLeads]);

	return (
		<LeadsContext.Provider
			value={{
				leads,
				setLeads,
				fetchingLeads,
				clientList,
				fetchSetLeads,
				leadFlags,
			}}>
			{children}
		</LeadsContext.Provider>
	);
}
