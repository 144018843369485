import { Typography, styled } from '@mui/material';
import { FormEditTextFieldStylesProps } from './FormTextEditField.types';
import { inputStyles } from '../InputField/InputField.styles';

export const StyledInfo = styled(Typography)`
	display: inline-block;
	margin-top: 4px;
	font-size: 12px;
`;

export const additionalStyles = ({
	isFormTitle,
	helperText,
	error,
	isEmpty,
}: FormEditTextFieldStylesProps) => {
	return {
		...inputStyles,
		...(isFormTitle && {
			marginBottom: '0px',
			'.MuiInputBase-root': {
				fontWeight: 600,
			},
		}),
		...(helperText?.length && {
			pb: '20px',
		}),
		...(error && {
			'.MuiFormHelperText-root': {
				position: 'absolute',
				bottom: '-23px',
			},
		}),
		...(isEmpty && {
			'.MuiFormLabel-root:not(.active)': {
				opacity: '1',
				fontStyle: 'italic',
				left: '-13px',
			},
		}),
	};
};
