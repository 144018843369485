import {
	collection,
	deleteDoc,
	doc,
	getDocs,
	orderBy,
	query,
	setDoc,
	updateDoc,
	where,
} from 'firebase/firestore';
import { db } from '../../../../../../firebase';
import { FormNodeWithChildren } from '../../../utils/types';
import { useCallback, useContext, useState } from 'react';
import { SnackContext } from '../../../../../../context/SnackProvider';
import { SettingsContext } from '../../../../../../context/SettingsProvider';

const useArchivedNodes = (formId: string, projectId: string) => {
	const [archivedNodes, setArchivedNodes] = useState<FormNodeWithChildren[]>([]);
	const [activeContainers, setActiveContainers] = useState<FormNodeWithChildren[]>([]);
	const [loading, setLoading] = useState(false);
	const { setSnackbarProps } = useContext(SnackContext);
	const { maxNodeContainerNestingLevel } = useContext(SettingsContext);

	const getArchivedNodes = useCallback(async () => {
		try {
			setLoading(true);
			setActiveContainers([]);

			const nodesRef = query(
				collection(db, 'utility_forms_v2_items_archive'),
				where('formId', '==', formId),
				where('projectId', '==', projectId),
				orderBy('displayTitle')
			);

			const nodeCollection = await getDocs(nodesRef);

			const nodes: FormNodeWithChildren[] = nodeCollection.docs.map(doc => {
				const data = doc.data() as FormNodeWithChildren;
				data.id = doc.id;
				return data;
			});

			const treeNodes = buildTree(nodes);

			setArchivedNodes(treeNodes);
			setLoading(false);
		} catch (error) {
			console.error(error);
			return [];
		}
	}, [formId, projectId]);

	const getActiveContainers = async () => {
		try {
			setLoading(true);

			const nodesRef = query(
				collection(db, 'utility_forms_v2_items'),
				where('formId', '==', formId),
				where('projectId', '==', projectId),
				where('type', '==', 'node'),
				orderBy('parentsTitle'),
				orderBy('displayTitle')
			);

			const nodeCollection = await getDocs(nodesRef);

			const nodes = nodeCollection.docs.map(doc => {
				const data = doc.data() as FormNodeWithChildren;
				data.id = doc.id;
				return data;
			});

			const filteredNodes = nodes.filter(
				node => !node.parentId || nodes.some(n => n.id === node.parentId)
			);

			const treeNodes = buildTree(filteredNodes);

			setActiveContainers(treeNodes);

			setLoading(false);
		} catch (error) {
			console.error(error);
			return [];
		}
	};

	const markNode = (
		nodes: FormNodeWithChildren[],
		selected: boolean,
		node?: FormNodeWithChildren
	): FormNodeWithChildren[] => {
		if (node === undefined) {
			return nodes.map(n => {
				const newNode = { ...n, selected };
				newNode.children = markNode(n.children, selected);
				return newNode;
			});
		}

		return nodes.map(n => {
			if (n.id === node.id) {
				const newNode = { ...n, selected };
				newNode.children = markNode(newNode.children, selected);
				return newNode;
			} else {
				const newNode = { ...n, children: markNode(n.children, selected, node) };
				return newNode;
			}
		});
	};

	const getSelectedNodes = (nodes?: FormNodeWithChildren[]): FormNodeWithChildren[] => {
		const selectedNodes: FormNodeWithChildren[] = [];
		(nodes || archivedNodes).forEach(node => {
			if (node.selected) {
				selectedNodes.push(node);
			}
			if (node.children) {
				selectedNodes.push(...getSelectedNodes(node.children));
			}
		});
		return selectedNodes;
	};

	const toggleNode = (node: FormNodeWithChildren, selected: boolean) =>
		setArchivedNodes(nodes => markNode(nodes, selected, node));

	const restoreNodes = async (targetNode: FormNodeWithChildren) => {
		setLoading(true);

		const treeNodes = buildTree(getSelectedNodes());

		const selectedNodes = treeNodes.map(node => ({
			...node,
			parentId: targetNode.id,
			level: targetNode.id ? (targetNode.level || 0) + 1 : 0,
			parentsTitle: targetNode.parentsTitle
				? targetNode.parentsTitle + ' > ' + targetNode.displayTitle
				: targetNode.displayTitle,
		}));

		// Update level and parent ids of selected nodes children at all levels
		selectedNodes.forEach(updateLevel);

		const flattenedNodes = flattenNodes(selectedNodes);

		let maxDepth = 0;
		selectedNodes.forEach(rootNode => {
			const depth = getMaxDepth(rootNode);
			maxDepth = Math.max(maxDepth, depth);
		});

		console.log(maxNodeContainerNestingLevel, maxDepth + (targetNode.level || 0));
		if (maxNodeContainerNestingLevel <= maxDepth + (targetNode.level || 0)) {
			setSnackbarProps({
				open: true,
				message: 'Selected nodes exceed the maximum nesting level!',
				severity: 'error',
				hideDuration: 6000,
			});
			setLoading(false);
			return;
		}

		console.log(targetNode.level, isNodeOrPhotos(selectedNodes));
		if (targetNode.level === undefined && !isNodeOrPhotos(selectedNodes)) {
			setSnackbarProps({
				open: true,
				message: 'Root node must be a container or photo!',
				severity: 'error',
				hideDuration: 6000,
			});
			setLoading(false);
			return;
		}

		try {
			await Promise.all(
				flattenedNodes.map(async node => {
					const nodeRef = doc(db, 'utility_forms_v2_items', node.id);
					const nodeWithoutSelectedProp = { ...node };
					delete nodeWithoutSelectedProp.selected;
					await setDoc(nodeRef, nodeWithoutSelectedProp);
				})
			);

			await Promise.all(
				flattenedNodes.map(async node => {
					const nodeRef = doc(db, 'utility_forms_v2_items_archive', node.id);
					await updateDoc(nodeRef, { restored: true });
				})
			);

			await Promise.all(
				selectedNodes.map(async node => {
					const nodeRef = doc(db, 'utility_forms_v2_items_archive', node.id);
					await deleteDoc(nodeRef);
				})
			);
		} catch (error) {
			console.error(error);
			setSnackbarProps({
				open: true,
				message: 'Error restoring nodes. Please try again!',
				severity: 'error',
				hideDuration: 6000,
			});
		}

		setSnackbarProps({
			open: true,
			message: 'Nodes restored successfully!',
			severity: 'success',
			hideDuration: 6000,
		});

		clearSelected();
		setLoading(false);
	};

	const buildTree = (nodes: FormNodeWithChildren[]): FormNodeWithChildren[] => {
		// Transforms this unordered list of nodes into a tree structure
		const nodeMap = new Map();
		nodes.forEach(node => {
			// Clone the node and initialize its children array
			const newNode = { ...node, children: [] };
			nodeMap.set(node.id, newNode);
		});

		nodes.forEach(node => {
			// If the node has a parent and the parent exists in the map, add it to the parent's children array
			if (node.parentId && nodeMap.has(node.parentId)) {
				const parent = nodeMap.get(node.parentId);
				parent.children.push(nodeMap.get(node.id));
			}
		});

		// Filter out the root nodes
		const rootNodes = Array.from(nodeMap.values()).filter(
			node => !node.parentId || !nodeMap.has(node.parentId)
		);

		return rootNodes;
	};

	const flattenNodes = (nodes: FormNodeWithChildren[]): FormNodeWithChildren[] => {
		const flattenedNodes: FormNodeWithChildren[] = [];
		nodes.forEach(node => {
			flattenedNodes.push(node);
			flattenedNodes.push(...flattenNodes(node.children));
		});
		return flattenedNodes;
	};

	const clearSelected = () => setArchivedNodes(nodes => markNode(nodes, false));

	const getMaxDepth = (node: FormNodeWithChildren, currentDepth: number = 0): number => {
		let maxDepth = currentDepth;
		node.children.forEach(child => {
			const childDepth = getMaxDepth(child, currentDepth + 1);
			maxDepth = Math.max(maxDepth, childDepth);
		});
		return maxDepth;
	};

	const updateLevel = (node: FormNodeWithChildren) => {
		node.children = node.children.map(child => {
			const newChild = {
				...child,
				level: (node.level || 0) + 1,
				parentsTitle:
					(node.parentsTitle ? node.parentsTitle + ' > ' : '') + node.displayTitle,
			};
			newChild.children = newChild.children.map(grandchild => {
				const newGrandchild = {
					...grandchild,
					level: newChild.level + 1,
					parentsTitle:
						(newChild.parentsTitle ? newChild.parentsTitle + ' > ' : '') +
						newChild.displayTitle,
				};
				return newGrandchild;
			});
			return newChild;
		});
	};

	const isNodeOrPhotos = (nodes: FormNodeWithChildren[]) =>
		nodes.every(node => node.type === 'node' || node.type === 'photos');

	const rootNode: FormNodeWithChildren = {
		id: '',
		displayTitle: 'Root',
		formId: formId,
		parentId: '',
		type: 'node',
		projectId: projectId,
		children: [],
	};

	return {
		activeContainers,
		archivedNodes,
		getActiveContainers,
		getArchivedNodes,
		getSelectedNodes,
		loading,
		restoreNodes,
		rootNode,
		toggleNode,
	};
};

export default useArchivedNodes;
