import { IconButton } from '@mui/material';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const YellowIconButton = styled(IconButton)<{ $hideButton?: boolean }>`
	display: ${({ $hideButton }) => ($hideButton ? 'none' : 'inline-flex')};
	color: #ffb310;
	&:hover {
		background-color: rgba(255, 179, 16, 0.2);
	}
`;

export const WhiteIconButton = styled(IconButton)`
	color: white;
	&:hover,
	:focus-visible {
		color: #ffb310;
		background-color: rgba(255, 179, 16, 0.2);
	}
`;

export const ButtonsContainer = styled.div`
	display: flex;
	justify-content: center;
	gap: 10px;
	margin-top: 10px;
`;

export const BackButton = styled(Link)`
	display: flex;
	align-items: center;
	text-decoration: none;
	padding: 20px 0;
	transition: all 0.2s;

	> span {
		padding-left: 5px;
		color: white;
		font-size: 20px;
	}

	&:hover {
		background-color: rgba(255, 179, 16, 0.04);
		> span {
			color: #ffb310;
			text-decoration: underline;
		}
	}
`;
