import {
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	Button,
	styled,
} from '@mui/material';
import LoadingScreen from '../../reusable-components/LoadingScreen';
import { useCallback, useContext, useEffect, useState } from 'react';
import { SnackContext } from '../../../context/SnackProvider';
import { OrganizationsContext } from '../../../context/OrganizationsProvider';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import theme from '../../../styles/theme';
import { PendingUserObj } from '../../../screens/TeamPage';
import { UserObj } from '../../../types';
import { removeUsersFromOrgOrTeam } from '../../../firebase';

export default function RemoveUserDialog({
	usersToBeRemoved,
	setUsersToBeRemoved,
	teamId,
	setPendingUsers,
}: {
	usersToBeRemoved: (UserObj | PendingUserObj)[];
	setUsersToBeRemoved: React.Dispatch<React.SetStateAction<(PendingUserObj | UserObj)[]>>;
	teamId: string;
	setPendingUsers: React.Dispatch<React.SetStateAction<PendingUserObj[]>>;
}) {
	const { setTeam } = useContext(OrganizationsContext);
	const { setSnackbarProps } = useContext(SnackContext);

	const [removingUsers, setRemovingUsers] = useState(false);

	const handleClose = useCallback(() => {
		setUsersToBeRemoved([]);
	}, [setUsersToBeRemoved]);

	const handleDelete = async () => {
		const userIds: string[] = [];
		const pendingUserIds: string[] = [];

		for (const user of usersToBeRemoved) {
			if ('name' in user) {
				userIds.push(user.id);
			} else {
				pendingUserIds.push(user.id);
			}
		}

		try {
			setRemovingUsers(true);
			await removeUsersFromOrgOrTeam({
				removalType: 'team',
				removalTypeId: teamId,
				userIds: userIds,
				pendingUserIds: pendingUserIds,
			});

			setTeam(prev =>
				prev
					? {
							...prev,
							userIds: prev.userIds?.filter(id => !userIds.includes(id)),
							users: prev.users?.filter(userObj => !userIds.includes(userObj.id)),
					  }
					: prev
			);
			setPendingUsers(prev => prev.filter(user => !pendingUserIds.includes(user.id)));

			setSnackbarProps({
				open: true,
				severity: 'success',
				message: `Successfully removed the user from team!`,
			});
		} catch (err) {
			console.log({ err });
			setSnackbarProps({
				open: true,
				severity: 'error',
				message: `Error removing user`,
			});
		} finally {
			setRemovingUsers(false);
			handleClose();
		}
	};

	const handleRemoveUser = (user: UserObj | PendingUserObj) => {
		setUsersToBeRemoved(usersToBeRemoved?.filter(item => item.id !== user.id));
	};

	useEffect(() => {
		if (usersToBeRemoved?.length === 0) {
			handleClose();
		}
	}, [handleClose, usersToBeRemoved]);

	const userWord = usersToBeRemoved?.length === 1 ? 'user' : 'users';

	return (
		<Dialog open={Boolean(usersToBeRemoved.length)} onClose={handleClose}>
			<DialogTitle>Remove {userWord} from team</DialogTitle>
			{removingUsers ? (
				<DialogContent>
					<LoadingScreen message="Removing users from the team..." textColor="black" />
				</DialogContent>
			) : (
				<DialogContent>
					<Text>
						Are you sure you want to remove the following {userWord} from this team?
					</Text>

					<Container>
						{usersToBeRemoved?.map((user, index) => (
							<ContainerContent key={index}>
								<IconRemove onClick={() => handleRemoveUser(user)} />
								<Address>{'name' in user ? user.name : user.email}</Address>
							</ContainerContent>
						))}
					</Container>
				</DialogContent>
			)}
			{!removingUsers && (
				<DialogActions>
					<Button autoFocus onClick={handleClose}>
						Cancel
					</Button>
					<Button onClick={handleDelete}>Remove {userWord}</Button>
				</DialogActions>
			)}
		</Dialog>
	);
}

const Text = styled('span')`
	color: #000;
	font-size: 16px;
	font-weight: 400;
`;

const Container = styled('div')`
	display: flex;
	flex-direction: column;
	gap: 5px;
	padding: 20px 0 0 10px;
`;

const ContainerContent = styled('div')`
	display: flex;
	align-items: center;
	gap: 7px;
`;

const Address = styled('span')`
	color: #595959;
	font-size: 14px;
	font-weight: 400;
`;

const IconRemove = styled(RemoveCircleOutlineIcon)`
	color: ${theme.palette.primary.main};
	width: 15px;
	height: 15px;
	cursor: pointer;
`;
