import { Button, Input, Switch, TextField } from '@mui/material';
import styled from 'styled-components';
import theme from '../../../styles/theme';

export const Page = styled.div`
	display: flex;
	flex-direction: row;
	width: 100vw;
	height: calc(100vh - 75px);
	background-color: #eef0f5;
	justify-content: center;
	padding: 20px;
`;

export const ChildBlockContainer = styled.div`
	width: 100%;
	background-color: bisque;
	/* background-color: #eef0f5; */
	box-shadow: 0px 2px 5px 1px rgba(0, 0, 0, 0.15);

	border-radius: 20px;
	height: fit-content;
	gap: 20px;
	padding: 20px;
`;

interface OverviewPageProps {
	currentNode?: boolean;
}

export const Field = styled.div`
	background-color: #fefefe;
	box-shadow: 0px 2px 5px 1px rgba(0, 0, 0, 0.15);
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: 8px;
	width: 100%;
	border-radius: 20px;
	min-height: 50px;
	break-inside: avoid;
	padding: 20px;
	position: relative;
`;

export const SubCategoryHeaderContainer = styled.div`
	position: relative;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	width: 100%;
`;

export const InputFieldContainer = styled.div`
	gap: 40px;
	flex: 1;
`;

export const PhotoDropzone = styled.div<{ small?: number; isViewMode?: boolean }>`
	width: ${({ isViewMode }) => (isViewMode ? '25px' : '100px')};
	height: ${({ isViewMode }) => (isViewMode ? '25px' : '100px')};
	display: flex;
	flex-direction: column;
	background-color: ${theme.palette.grey[900]};
	color: ${theme.palette.primary.main};
	font-size: ${({ isViewMode }) => (isViewMode ? '8px' : '16px')};
	font-weight: 700;
	text-decoration: underline;
	border-radius: 5px;
	padding: ${({ isViewMode }) => (isViewMode ? '2px' : '10px')};
	justify-content: center;
	align-items: center;
	break-inside: avoid;
	cursor: pointer;

	svg {
		font-size: ${({ isViewMode }) => (isViewMode ? '12px' : '24px')};
		margin-bottom: ${({ isViewMode }) => (isViewMode ? '0' : '4px')};
	}

	p {
		display: ${({ isViewMode }) => (isViewMode ? 'none' : 'block')};
	}

	&:hover {
		border: 1px solid ${theme.palette.primary.main};
	}
`;

export const ChildrenContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 20px;

	border-radius: 20px;
	width: 100%;
	height: fit-content;
`;

export const NodeContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 20px;
`;

export const ItemTitleInput = styled(TextField)`
	font-family: 'Epilogue';
	color: black;
	font-weight: 400;
	font-size: 1rem;
	width: 50%;
	display: flex;
`;

export const NodeCenteredHeader = styled(Input)`
	font-family: 'Epilogue';
	color: black;
	font-weight: 500;
	font-size: 1.2rem;
	text-align: center;
	input {
		text-align: center;
	}
`;

export const StaticTitle = styled.span`
	display: flex;
	font-family: 'Epilogue';
	color: black;
	font-weight: 600;
	font-size: 1rem;
	margin-right: 10px;
	margin-left: 5px;
`;

export const Value = styled.div<OverviewPageProps>`
	/* display: flex; */
	display: ${({ currentNode }) => (currentNode ? 'flex' : null)};
	flex: 1;
`;

export const FormSwitch = styled(Switch)`
	width: 28;
	height: 16;
	padding: 0;
	display: 'flex';

	&:active {
		& .MuiSwitch-thumb {
			width: 20;
		}
		& .MuiSwitch-switchBase.Mui-checked {
			transform: translateX(15px);
		}
	}
	& .MuiSwitch-switchBase {
		padding: 2;
		&.Mui-checked {
			transform: translateX(18px);
			color: #f6f6f6;
			& + .MuiSwitch-track {
				opacity: 1;
				background-color: #ffb310;
			}
		}
	}
	& .MuiSwitch-thumb {
		box-shadow: 0 2px 4px 0 rgb(0 35 11 / 20%);
		width: 12;
		height: 12;
		border-radius: 6;
		transition: width 100 transform 200ms;
	}
	& .MuiSwitch-track {
		border-radius: 20px;
		opacity: 1;
		background-color: #eef0f5;
		box-sizing: border-box;
	}
`;

export const PickerButton = styled(Button)`
	text-transform: none;
	border: 1px solid grey;
	color: black;
`;

export const GalleryContainer = styled.div<{
	isviewmode?: string;
	isExpanded?: boolean;
}>`
	display: grid;
	grid-template-columns: ${({ isviewmode, isExpanded }) =>
		isviewmode === 'true'
			? isExpanded
				? 'repeat(auto-fill, 25px)'
				: 'repeat(auto-fit, 25px)'
			: 'repeat(auto-fill, 100px)'};
	gap: 8px;
	padding: 2px;
	width: 100%;
	max-height: ${({ isviewmode, isExpanded }) =>
		isviewmode === 'true' && !isExpanded ? '35px' : 'none'};
	overflow: ${({ isviewmode, isExpanded }) =>
		isviewmode === 'true' && !isExpanded ? 'hidden' : 'visible'};

	@media (max-width: ${theme.breakpoints.values.sm}px) {
		grid-template-columns: ${({ isviewmode, isExpanded }) =>
			isviewmode === 'true'
				? isExpanded
					? 'repeat(auto-fill, 25px)'
					: 'repeat(auto-fit, minmax(25px, 1fr))'
				: 'repeat(auto-fill, minmax(80px, 1fr))'};
	}
`;
export const PhotoLoadingIcon = styled('div')<{ isviewmode?: string }>`
	position: absolute;
	align-self: center;
	z-index: 2;
	width: ${({ isviewmode }) => (isviewmode === 'true' ? '25px' : '100px')};
	height: ${({ isviewmode }) => (isviewmode === 'true' ? '25px' : '100px')};
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 5px; // Add border radius to loading state
`;

export const PhotoContainer = styled.div<{ isviewmode?: string }>`
	width: ${({ isviewmode }) => (isviewmode === 'true' ? '25px' : '100px')};
	height: ${({ isviewmode }) => (isviewmode === 'true' ? '25px' : '100px')};
	position: relative;
	overflow: hidden;
	outline: none;
	border-radius: 5px; // Ensure container has border radius

	@keyframes pulse {
		0%,
		100% {
			background-color: rgba(0, 0, 0, 0.5);
		}
		50% {
			background-color: rgba(0, 0, 0, 0.2);
		}
	}

	animation: pulse 3s infinite;
`;
export const ManageUploadsButton = styled.div<{ isViewMode?: boolean }>`
	padding: ${({ isViewMode }) => (isViewMode ? '5px 2px' : '15px 5px')};
	color: ${theme.palette.primary.main};
	text-decoration: underline;
	cursor: pointer;
	width: fit-content;
	font-family: 'Epilogue';
	font-weight: 600;
	font-size: ${({ isViewMode }) => (isViewMode ? '0.75rem' : '1rem')};
	display: flex;
	align-items: center;
	gap: ${({ isViewMode }) => (isViewMode ? '4px' : '10px')};
`;

export const CompactIconButton = styled.div`
	width: 25px;
	height: 25px;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: ${theme.palette.grey[900]};
	border-radius: 5px;
	cursor: pointer;
	color: ${theme.palette.primary.main};
	font-size: 12px;

	&:hover {
		border: 1px solid ${theme.palette.primary.main};
	}

	svg {
		font-size: 16px;
	}
`;

export const CollapseButton = styled.button`
	display: flex;
	align-items: center;
	justify-content: center;
	width: 25px;
	height: 25px;
	color: ${theme.palette.primary.main};
	background: ${theme.palette.grey[900]};
	border: none;
	border-radius: 5px;
	cursor: pointer;
	margin-top: 8px;

	&:hover {
		border: 1px solid ${theme.palette.primary.main};
	}
`;
