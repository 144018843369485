import { Category, Item, Photo } from '../ProjectUtilityForm/types';

/**
 * Filters out invalid Categories from a give Category array.
 * @param {Category[]} categories The Category array to be filtered.
 * @returns {Category[]} A Category array containing only Categories that have items.
 */
export const filterFormCategories = (categories: Category[]) => {
	const filteredCategories: Category[] = [];
	categories.forEach(category => {
		const filteredItems = filterFormItems(category.items);
		if (filteredItems.length) {
			filteredCategories.push({
				...category,
				items: filteredItems,
			});
		}
	});
	return filteredCategories;
};

/**
 * Filters out invalid Items from a given Item array.
 * @param {Item[]} items The Item array to be filtered.
 * @returns {Item[]} An Item array containing only Items that have Photos.
 */
const filterFormItems = (items: Item[]) => {
	const filteredItems: Item[] = [];
	items.forEach(item => {
		const filteredPhotos = filterFormPhotos(item.photos);
		if (filteredPhotos.length) {
			filteredItems.push({
				...item,
				photos: filteredPhotos,
			});
		}
	});
	return filteredItems;
};

/**
 * Filters out invalid Photos from a given Photo array.
 * @param {Photo[]} photos The Photo array to be filtered.
 * @returns {Photo[]} A Photo array containing only valid Photos with names that end in '.jpg', '.jpeg', or '.png'.
 */
const filterFormPhotos = (photos: Photo[]) => {
	return photos.filter(photo => {
		const name = photo.name.toLowerCase();
		return name.endsWith('.jpg') || name.endsWith('jpeg') || name.endsWith('png');
	});
};
