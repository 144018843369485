import {
	BackgroundContainer,
	BackgroundContent,
	MainContainer,
} from './FormContainer.styles';
import FormV2Header from '../FormV2Header';
import CategoryHeader from './components/CategoryHeader/CategoryHeader';
import { useContext, useEffect } from 'react';
import { FormV2Context } from '../../context/FormV2Context.types';
import ContentBuckets from './components/ContentBuckets/ContentBuckets';
import { useSearchParams } from 'react-router-dom';
import ItemField from './components/FormFill/ItemField';
import { ChildrenContainer } from './components/FormFill/FormFillV2.styles';

/**
 * Renders the right side of the Form Screen that holds the form data
 */
export default function FormContainer() {
	const { currentNode, rootNodes } = useContext(FormV2Context);

	const [searchParams] = useSearchParams();
	const itemId = searchParams.get('itemId');
	// const commentId = searchParams.get('commentId');

	useEffect(() => {
		if (itemId) {
			setTimeout(() => {
				const item = document.getElementById(`${itemId}-scroll`);
				console.log(item);
				item?.scrollIntoView();
			}, 1800);
			setTimeout(() => {
				const dialog = document.getElementById(`${itemId}-comments`);
				dialog?.click();
			}, 2500);
		}
	}, [itemId, currentNode]);

	return (
		<MainContainer>
			<FormV2Header />
			{currentNode ? (
				<BackgroundContainer>
					<BackgroundContent>
						<ItemField node={currentNode} />
					</BackgroundContent>
				</BackgroundContainer>
			) : (
				<>
					<ContentBuckets />
					<BackgroundContainer>
						<BackgroundContent>
							<CategoryHeader />
							<ChildrenContainer>
								{rootNodes.map(node => (
									<ItemField key={node.id} node={node} />
								))}
							</ChildrenContainer>
						</BackgroundContent>
					</BackgroundContainer>
				</>
			)}
		</MainContainer>
	);
}
