import { createContext, useState } from 'react';
import { ProjectData } from '../../../types';

type StatusTableContextType = {
	statusProjects: ProjectData[];
	setStatusProjects: React.Dispatch<React.SetStateAction<ProjectData[]>>;
};

export const StatusTableContext = createContext({} as StatusTableContextType);

export const StatusTableProvider = ({ children }: { children?: React.ReactNode }) => {
	const [statusProjects, setStatusProjects] = useState<ProjectData[]>([]);

	return (
		<StatusTableContext.Provider
			value={{
				statusProjects,
				setStatusProjects,
			}}>
			{children}
		</StatusTableContext.Provider>
	);
};
