import { useContext, useState } from 'react';
import { YellowButton } from '../../../styled-components/styledReactTable';
import { FormV2Context } from '../../../screen-components/ProjectUtilityFormV2/context/FormV2Context.types';
import { ButtonMenu, MenuOption } from './components';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import styled from 'styled-components';

export default function Submit() {
	const { setIsSubmitting, submitLetterForm, submitForm } = useContext(FormV2Context);

	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const open = Boolean(anchorEl);

	const handleClick = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	return (
		<>
			<YellowButton
				onClick={handleClick}
				title="Submission Options"
				style={{ textTransform: 'none', paddingTop: 8 }}>
				Submit
			</YellowButton>

			<ButtonMenu anchorEl={anchorEl} open={open} onClose={handleClose}>
				<MenuOption
					onClick={() => {
						setIsSubmitting(true);
						submitLetterForm();
						handleClose();
					}}>
					<InsertDriveFileIcon />
					<span>Generate Letter PDF</span>
				</MenuOption>
				<MenuOption
					onClick={() => {
						setIsSubmitting(true);
						submitForm();
						handleClose();
					}}>
					<LandscapeIcon />
					<span>Generate Landscape PDF</span>
				</MenuOption>
			</ButtonMenu>
		</>
	);
}

const LandscapeIcon = styled(InsertDriveFileIcon)`
	transition: 0ms;
	transform: rotate(90deg);
`;
