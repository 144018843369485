import { ReactNode } from 'react';
import styled from 'styled-components';
import { ArrowBack } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import { MobileDropdown } from './MobileDropdown';

type SidebarProps = {
	backButtonTo?: string;
	backButtonTitle?: string;
	children?: ReactNode;
	topContent?: ReactNode;
	bottomContent?: ReactNode;
};

export const Sidebar = ({
	backButtonTo,
	backButtonTitle,
	children,
	topContent,
	bottomContent,
}: SidebarProps) => {
	return (
		<>
			<SidebarContainer>
				<TopContentContainer>
					{backButtonTo && backButtonTitle ? (
						<BackButton to={backButtonTo} style={{ flexShrink: '0' }}>
							<ArrowBack style={{ color: '#ffb310' }} />
							<span>{backButtonTitle}</span>
						</BackButton>
					) : null}
					{topContent}
				</TopContentContainer>

				<ChildrenContainer>{children}</ChildrenContainer>

				<BottomContentContainer>{bottomContent}</BottomContentContainer>
			</SidebarContainer>

			<MobileDropdown title="Organization Info">
				{children}
				{bottomContent}
			</MobileDropdown>
		</>
	);
};

const SidebarContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 10px;
	min-width: 250px;
	padding: 20px;
	background-color: black;
	border-right: 1px solid rgba(89, 89, 89, 0.4);
	height: 100%;

	@media (max-width: 768px) {
		display: none;
	}
`;

const TopContentContainer = styled.div`
	display: flex;
	flex-direction: column;
	flex-shrink: 0;
	gap: 10px;
`;

const BackButton = styled(Link)`
	display: flex;
	gap: 10px;
	align-items: center;
	text-decoration: none;
	transition: all 0.2s;

	> span {
		color: white;
		font-size: 1.1rem;

		@media (max-width: 768px) {
			font-size: 0.9rem;
		}
	}

	&:hover {
		background-color: rgba(255, 179, 16, 0.04);
		> span {
			color: #ffb310;
			text-decoration: underline;
		}
	}
`;

const ChildrenContainer = styled.div`
	display: flex;
	flex-direction: column;
	flex-shrink: 1;
	overflow-y: auto;
`;

const BottomContentContainer = styled.div`
	display: flex;
	flex-direction: column;
	margin-top: auto;
	flex-shrink: 0;
`;
