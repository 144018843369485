import { useEffect, useRef, useState } from 'react';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../../../../../../../firebase';
import { Team } from '../../../../../../../types';

interface TeamsById {
	[teamId: string]: Team;
}

export default function useOrgTeams(
	teamIds: string[]
): [Team[], (teamId: string) => void, (teamId: string) => void, boolean] {
	const [teams, setTeams] = useState<Team[]>([]);
	const cachedTeams = useRef<TeamsById>({});

	const [loading, setLoading] = useState(true);
	const fetching = useRef<{ [teamId: string]: boolean }>({});

	const fetchTeam = async (id: string) => {
		try {
			if (fetching.current[id]) return;
			fetching.current[id] = true;

			if (!cachedTeams.current[id]) {
				const teamDocRef = doc(db, `teams/${id}`);
				const snap = await getDoc(teamDocRef);
				const teamData: Team = snap.exists()
					? ({ ...snap.data(), id: snap.id } as Team)
					: {
							id: snap.id,
							name: 'Unknown Team',
							organizationId: '',
							projectIds: [],
							userIds: [],
					  };

				cachedTeams.current = {
					...cachedTeams.current,
					[id]: teamData,
				};
				setTeams(prev => {
					if (!prev.some(team => team.id === id)) {
						return [...prev, teamData];
					}
					return prev;
				});
			} else {
				setTeams(prev => {
					if (!prev.some(team => team.id === id)) {
						return [...prev, cachedTeams.current[id]];
					}
					return prev;
				});
			}

			fetching.current[id] = false;
		} catch (error) {
			fetching.current[id] = false;
		}
	};

	useEffect(() => {
		if (teamIds.length) {
			const fetchAllTeams = async () => {
				setLoading(true);
				await Promise.all(teamIds.map(id => fetchTeam(id)));
				setLoading(false);
			};

			fetchAllTeams();
		}
	}, [teamIds]);

	const addTeam = (teamId: string) => {
		if (!teams.some(team => team.id === teamId)) {
			const fetchAndAddTeam = async () => {
				const teamDocRef = doc(db, `teams/${teamId}`);
				const snap = await getDoc(teamDocRef);
				const teamData: Team = snap.exists()
					? ({ ...snap.data(), id: snap.id } as Team)
					: {
							id: snap.id,
							name: 'Unknown Team',
							organizationId: '',
							projectIds: [],
							userIds: [],
					  };

				cachedTeams.current = {
					...cachedTeams.current,
					[teamId]: teamData,
				};
				setTeams(prev => [...prev, teamData]);
			};
			fetchAndAddTeam();
		}
	};

	const removeTeam = (teamId: string) => {
		setTeams(prev => prev.filter(team => team.id !== teamId));
	};

	return [teams, addTeam, removeTeam, loading];
}
