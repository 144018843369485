import { Checkbox, FormControlLabel } from '@mui/material';
import styled from 'styled-components';

export default function ItemCheckBoxField({
	field,
	setState,
	label,
	type,
	value,
}: {
	field: string;
	setState: React.Dispatch<React.SetStateAction<any>>;
	label: string;
	type?: string;
	value: boolean;
}) {
	return (
		<CheckBoxContainer>
			<FormControlLabel
				control={
					<Checkbox
						checked={value}
						onChange={() => {
							setState((prev: any) => ({
								...prev,
								metadata: {
									...prev.metadata,
									[field]: !value,
								},
							}));
						}}
					/>
				}
				label={<span style={{ padding: '9px' }}>{label || ''}</span>}
				labelPlacement="start"
				sx={{
					width: '100%',
					margin: 0,
					justifyContent: 'space-between',
				}}
			/>
		</CheckBoxContainer>
	);
}

const CheckBoxContainer = styled.div`
	min-width: 219px;
	height: 56px;
	@media screen and (max-width: 768px) {
		width: 100%;
	}
	border: 1px lightgray solid;
	border-radius: 5px;
	&:hover {
		border-color: black;
	}
	display: flex;
	align-items: center;
`;
