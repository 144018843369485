import React, { FC, useEffect, useState } from 'react';
import { IconButton, Menu, MenuItem, ListItemIcon, ListItemText } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { Flag, PhotoV2 } from '../../../utils/types';
import {
	GalleryImage,
	StyledContent,
	StyledDialog,
	StyledDialogTitle,
} from './PhotoGalleryDialog.styles';
import Carousel from '../../../../../reusable-components/Carousel';
import SettingsIcon from '@mui/icons-material/Settings';
import DeleteIcon from '@mui/icons-material/Delete';
import { getFlagStyle } from '../../../utils/utils';

interface PhotoGalleryDialogProps {
	open: boolean;
	photos: PhotoV2[];
	initialIndex: number;
	onClose: () => void;
	onDelete: (index: number) => void;
	setFlag: (index: number, flag: Flag) => void;
}

const PhotoGalleryDialog: FC<React.PropsWithChildren<PhotoGalleryDialogProps>> = ({
	open,
	photos,
	initialIndex,
	onClose,
	onDelete,
	setFlag,
}) => {
	const [currentIndex, setCurrentIndex] = useState(initialIndex);
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const openMenu = Boolean(anchorEl);

	useEffect(() => {
		setCurrentIndex(initialIndex);
	}, [initialIndex]);

	const handleClick = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const handleDelete = () => {
		onDelete(currentIndex);

		if (photos.length <= 1) {
			onClose();
		} else if (currentIndex === photos.length - 1) {
			setCurrentIndex(currentIndex - 1);
		}
		handleClose();
	};

	const handleChangeIndex = (newIndex: number) => {
		setCurrentIndex(newIndex);
	};

	const handleSetFlag = (flag: Flag) => {
		setFlag(currentIndex, flag);
		handleClose();
	};

	return (
		<StyledDialog open={open} onClose={onClose} fullWidth>
			<StyledDialogTitle>
				<IconButton
					aria-label="settings"
					aria-controls={openMenu ? 'settings-menu' : undefined}
					aria-expanded={openMenu ? 'true' : undefined}
					onClick={handleClick}
					style={{ color: 'white' }}>
					<SettingsIcon />
				</IconButton>
				<Menu
					id="settings-menu"
					anchorEl={anchorEl}
					open={openMenu}
					onClose={handleClose}
					MenuListProps={{
						'aria-labelledby': 'settings-button',
					}}>
					<MenuItem onClick={handleDelete}>
						<ListItemIcon>
							<DeleteIcon fontSize="small" />
						</ListItemIcon>
						<ListItemText>Delete Photo</ListItemText>
					</MenuItem>
					{photos[currentIndex]?.flag && (
						<MenuItem onClick={() => handleSetFlag('')}>Remove flag</MenuItem>
					)}
					{photos[currentIndex]?.flag !== 'review' && (
						<MenuItem onClick={() => handleSetFlag('review')}>
							Mark as needs review
						</MenuItem>
					)}
					{photos[currentIndex]?.flag !== 'completed' && (
						<MenuItem onClick={() => handleSetFlag('completed')}>
							Mark as completed
						</MenuItem>
					)}
				</Menu>
				<IconButton onClick={onClose} style={{ color: 'white' }}>
					<CloseIcon fontSize="medium" />
				</IconButton>
			</StyledDialogTitle>
			<StyledContent>
				<Carousel
					captureWindowKeypresses
					controlledIndex={currentIndex}
					setControlledIndex={handleChangeIndex}>
					{photos.map((photo, index) => (
						<GalleryImage
							key={index}
							src={photo.url || photo.thumbnail}
							alt="Gallery View"
							style={getFlagStyle(photo.flag)}
						/>
					))}
				</Carousel>
			</StyledContent>
		</StyledDialog>
	);
};

export default PhotoGalleryDialog;
