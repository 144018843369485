import { useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { ProjectContext } from '../context/ProjectProvider';
import { formatProjectTimestamps, useNav } from '../utils';
import { getProject } from '../firebase';
import FormV2Provider from '../components/screen-components/ProjectUtilityFormV2/context/FormV2Context';
import UtilityFormV2Renderer from '../components/screen-components/ProjectUtilityFormV2/screens/UtilityFormV2Renderer';
import LoadingLayer from '../components/reusable-components/LoadingLayer';
import { LoadingContext } from '../context/LoadingProvider';

export default function UtilityFormV2() {
	const { projectId }: any = useParams();

	const { setProject } = useContext(ProjectContext);
	const { loadingStatus } = useContext(LoadingContext);

	const navigate = useNav();

	useEffect(() => {
		document.title = 'Report | Robotic Imaging';
	}, []);

	// Fetch, format, and set project
	useEffect(() => {
		const getSetProject = async () => {
			try {
				const res = await getProject({ projectId: projectId });
				const resData = res.data as any;
				if (resData.project) {
					setProject(formatProjectTimestamps([resData.project])[0]);
				} else {
					console.error(`No data found for project '${projectId}'`);
					navigate('/404', { replace: true });
				}
			} catch (err) {
				console.log(err);
				navigate('/404', { replace: true });
			}
		};
		getSetProject();
		// Cleanup on unmount
		return () => {
			setProject(null);
		};
	}, [projectId, setProject, navigate]);

	return (
		<FormV2Provider>
			<LoadingLayer showOverlay={!!loadingStatus?.title} />
			<UtilityFormV2Renderer />
		</FormV2Provider>
	);
}
