import { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import { Link } from 'react-router-dom';
import LogoContainer from '../components/reusable-components/LogoContainer';
import { useContext } from 'react';
import { LoadingContext } from '../context/LoadingProvider';

import EmailIcon from '@mui/icons-material/Email';
import CreateIcon from '@mui/icons-material/Create';
import BackgroundVideo from '../components/reusable-components/BackgroundVideo';

import styled from 'styled-components';
import { AuthContext } from '../context/AuthProvider';
import LoadingScreen from '../components/reusable-components/LoadingScreen';

// Hook
export function useWindowSize() {
	// Initialize state with undefined width/height so server and client renders match
	// Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
	const [windowSize, setWindowSize] = useState({
		width: 0,
		height: 0,
	});
	useEffect(() => {
		// Handler to call on window resize
		function handleResize() {
			// Set window width/height to state
			setWindowSize({
				width: window.innerWidth,
				height: window.innerHeight,
			});
		}
		// Add event listener
		window.addEventListener('resize', handleResize);
		// Call handler right away so state gets updated with initial window size
		handleResize();
		// Remove event listener on cleanup
		return () => window.removeEventListener('resize', handleResize);
	}, []); // Empty array ensures that effect is only run on mount
	return windowSize;
}

export default function Welcome() {
	const { loading } = useContext(LoadingContext);
	const { loadingUser } = useContext(AuthContext);

	// const [src, setSrc] = useState('');

	return (
		<>
			<BackgroundVideo />
			<Container>
				{/* <button
					type="button"
					onClick={async () => {
						const res = await fetch(
							'https://image-resizer-dot-robotic-imaging---test.uc.r.appspot.com/',
							{
								method: 'POST',
								headers: {
									'Content-Type': 'application/json',
								},
								body: JSON.stringify({
									imageUrl:
										'https://firebasestorage.googleapis.com/v0/b/disco-outpost-268223.appspot.com/o/projects%2F00ndwJjDfLNlxolZcHSf%2Fphotos%2FIMG_0781.JPG?alt=media&token=dc2c56f7-742e-43a2-9469-22529ed95883',
								}),
							}
						);
						const blob = await res.blob();
						setSrc(URL.createObjectURL(blob));
					}}>
					test
				</button>

				{src ? <img src={src} /> : null} */}

				{!loading && !loadingUser ? (
					<LogoContainer>
						<LinkContainer to={'/login'}>
							<Button
								variant="outlined"
								color="primary"
								size="large"
								startIcon={<EmailIcon />}>
								Login
							</Button>
						</LinkContainer>
						<LinkContainer to={'/register'}>
							<Button
								variant="outlined"
								color="primary"
								size="large"
								startIcon={<CreateIcon />}>
								Sign up
							</Button>
						</LinkContainer>
					</LogoContainer>
				) : (
					<LoadingScreen message="Loading user data..." />
				)}

				<Footer>
					<div>
						<ExternalLink href="https://www.roboticimaging.com/">
							roboticimaging.com
						</ExternalLink>
						<span>{'|'} </span>
						<ExternalLink href="https://www.roboticimaging.com/request-a-scan">
							Request a Scan
						</ExternalLink>
					</div>
					<span>{`© ${new Date().getFullYear()} Robotic Imaging`}</span>
				</Footer>
			</Container>
		</>
	);
}

const Container = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	width: 100%;
	height: 100%;
`;

const LinkContainer = styled(Link)`
	text-decoration: unset;
`;

const Footer = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;

	position: absolute;
	bottom: 10px;

	width: 100%;
	padding: 0px 10px;

	color: white;
	font-size: 12px;

	& > div {
		display: flex;
		align-items: center;
		gap: 5px;
	}

	@media screen and (max-width: 400px) {
		flex-direction: column;
	}
`;

const ExternalLink = styled.a`
	color: white;
	transition: 0.2s;
	&:hover {
		color: #ffb310;
	}
`;
