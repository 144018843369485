import { useContext, useEffect, useState } from 'react';
import { FormV2Context } from '../../../../context/FormV2Context.types';
import { FormTitle, InputUnderline, StyledInputField } from './FormName.styles';

export default function FormName() {
	const { utilityForm, updateFormName } = useContext(FormV2Context);
	const [editTitle, setEditTitle] = useState(false);
	const [title, setTitle] = useState('');

	useEffect(() => {
		if (!editTitle) {
			setTitle(utilityForm?.formName as string);
		}
	}, [editTitle, utilityForm?.formName]);

	const handleTitleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setTitle(event.target.value);
	};

	const handleBlur = async () => {
		setEditTitle(false);
		if (utilityForm?.id && title !== utilityForm.formName) {
			try {
				await updateFormName(title as string);
			} catch (error) {
				console.error('Error updating form name', error);
			}
		}
	};

	return editTitle ? (
		<>
			<StyledInputField
				fullWidth
				value={title}
				onChange={handleTitleChange}
				onBlur={handleBlur}
				autoFocus
			/>
			<InputUnderline
				style={{
					width: `${title.length + 18}ch`,
					backgroundColor: '#ffb310',
					height: '2px',
				}}
			/>
		</>
	) : (
		<FormTitle
			onClick={() => {
				setEditTitle(true);
				setTitle(utilityForm?.formName || '');
			}}>
			{utilityForm?.formName}
		</FormTitle>
	);
}
