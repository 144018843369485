import React, { FC } from 'react';
import styled from 'styled-components';

import GoogleLogo from '../../assets/google-logo.svg';
import MicrosoftLogo from '../../assets/microsoft-logo.svg';
import AppleLogo from '../../assets/apple-logo.svg';
import useGoogle from './useGoogle';
import useMicrosoft from './useMicrosoft';
import useApple from './useApple';

type Props = {
	isRegister?: boolean;
};

const LoginSocial: FC<Props> = ({ isRegister }: Props) => {
	const { login: googleLogin } = useGoogle();
	const { login: microsoftLogin } = useMicrosoft();
	const { login: appleLogin } = useApple();

	const handleGoogle = () => {
		googleLogin();
	};

	const handleApple = () => {
		appleLogin();
	};

	const handleMicrosoft = () => {
		microsoftLogin();
	};

	const typeOfAuth = isRegister ? 'Sign up' : 'Sign in';

	return (
		<Container>
			<SocialButton onClick={handleGoogle}>
				<img src={GoogleLogo} alt="google logo" />
				{typeOfAuth} with Google
			</SocialButton>

			<SocialButton onClick={handleApple}>
				<img src={AppleLogo} alt="apple logo" />
				{typeOfAuth} with Apple
			</SocialButton>

			<SocialButton onClick={handleMicrosoft}>
				<img src={MicrosoftLogo} alt="microsoft logo" />
				{typeOfAuth} with Microsoft
			</SocialButton>
		</Container>
	);
};

export default LoginSocial;

const Container = styled.div`
	display: flex;
	flex-direction: column;
	gap: 15px;
`;

const SocialButton = styled('button')`
	display: flex;
	gap: 8px;
	background: #f6f6f6;
	border: 1px rgba(30, 36, 50, 0.23);
	border-radius: 8px;
	width: 100%;
	padding: 8px 22px;
	align-items: center;
	cursor: pointer;
	height: 42px;

	img {
		width: 20px;
		height: 20px;
	}
`;
