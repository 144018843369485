import React, { FC, useContext } from 'react';
import { Notifications } from '@mui/icons-material';
import styled from 'styled-components';
import { SnackContext } from '../../../context/SnackProvider';
import { PendingUserObj } from '../../../screens/TeamPage';
import { useParams } from 'react-router-dom';
import { OrganizationsContext } from '../../../context/OrganizationsProvider';

type Props = {
	pendingUser: PendingUserObj | undefined;
};

const ButtonReminder: FC<Props> = ({ pendingUser }: Props) => {
	const { setSnackbarProps } = useContext(SnackContext);
	const { organizationId, teamId } = useParams();
	const { addUserToTeam } = useContext(OrganizationsContext);
	const { selectedTeam } = useContext(OrganizationsContext);

	const handleReminder = async () => {
		if (!pendingUser || !organizationId) return;

		setSnackbarProps({
			open: true,
			severity: 'warning',
			message: `Sending reminder email to ${pendingUser.email}...`,
			hideDuration: null,
		});

		try {
			const team = selectedTeam || teamId;
			if (team) {
				await addUserToTeam(pendingUser.email, team, organizationId);
				setSnackbarProps({
					open: true,
					severity: 'success',
					message: 'Successfully sent reminder email!',
				});
			}
		} catch (err) {
			setSnackbarProps({
				open: true,
				severity: 'error',
				message: `Error sending reminder email to ${pendingUser.email}...`,
			});
		}
	};

	const exisPendingUser = () => pendingUser && 'name' in pendingUser;

	return (
		<Button disabled={exisPendingUser()} onClick={() => handleReminder()}>
			{exisPendingUser() ? null : <Notifications />}
		</Button>
	);
};

export default ButtonReminder;

const Button = styled.button`
	background: none;
	border: none;
	color: #fff;
	cursor: pointer;

	&:disabled {
		cursor: not-allowed;
	}
`;
