import styled from 'styled-components';
import { ProjectData } from '../../../../../../types';
import FileDnd from '../../../../FileDnd';
import GenerateProposalButton from '../../../../../screen-components/ProjectInfo/components/GenerateProposalButton';

interface ProposalCellProps {
	project: ProjectData;
	setProjects: React.Dispatch<React.SetStateAction<ProjectData[]>>;
}

export default function ProposalCell({ project, setProjects }: ProposalCellProps) {
	const handleChange = (newProjectData: ProjectData) => {
		setProjects(prev => {
			const idx = prev.findIndex(proj => proj.id === project.id);
			if (idx > -1) {
				const tmp = [...prev];
				tmp.splice(idx, 1, newProjectData);
				return tmp;
			} else return prev;
		});
	};

	return (
		<Container onClick={e => e.stopPropagation()}>
			<GenerateProposalButton project={project} handleChange={handleChange} />

			<FileDnd projectProp={project} field="proposal" setProjects={setProjects} />
		</Container>
	);
}

const Container = styled.div`
	display: flex;
	align-items: center;
`;
