import styled from 'styled-components';
import { MenuItem } from '@mui/material';
import { YellowTextField } from '../../../components/styled-components/styledReactTable';
import { useCallback, useContext, useEffect } from 'react';
import { V2FormGalleryContext } from '../V2FormGalleryProvider';
import { GalleryNode, GalleryPhoto } from '../types';
import { FormNode } from '../../../components/screen-components/ProjectUtilityFormV2/utils/types';

type SelectorProps = {
	title: string;
	options: GalleryNode[] | GalleryPhoto[];
	value: number;
};

export default function Selector({ title, options, value }: SelectorProps) {
	const { setGalleryIndex, setCategoryId } = useContext(V2FormGalleryContext);

	const handleChange = useCallback(
		(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
			for (let i = 0; i < options.length; i++) {
				if (options[i].galleryIndex === Number(e.target.value)) {
					const item = options[i] as FormNode;
					if (item.type === 'node') setCategoryId(item.id);
				}
			}

			setGalleryIndex(Number(e.target.value));
		},
		[options, setCategoryId, setGalleryIndex]
	);

	useEffect(() => {
		handleChange({ target: { value: value.toString() } } as any);
	}, [handleChange, value]);

	return (
		<Container
			select
			fullWidth
			label={title}
			onChange={handleChange}
			value={value}
			InputLabelProps={{ shrink: true }}>
			{options.map(option => {
				if ('type' in option) {
					// 'option' is a GalleryNode.
					return (
						<MenuItem value={option.galleryIndex} key={option.id}>
							{option.displayTitle}
						</MenuItem>
					);
				} else {
					// 'option' is a GalleryPhoto.
					return (
						<MenuItem value={option.galleryIndex} key={option.name}>
							{option.originalName}
						</MenuItem>
					);
				}
			})}
		</Container>
	);
}

const Container = styled(YellowTextField)`
	label {
		color: #ffb310;
	}
`;
