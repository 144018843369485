// import {
// 	useEffect,
// 	useState,
// 	useRef
// } from 'react';
import styled from 'styled-components';
import { Photo } from '../../ProjectUtilityForm/types';
// import LoadingScreen from '../../../reusable-components/LoadingScreen';
// import { CarouselImg } from '../../../reusable-components/Carousel/Styled';
import PanAndZoom from '../../../reusable-components/PanAndZoom';

export default function GalleryImg({
	photo,
	photoIndex,
	currentIndex,
}: {
	photo: Photo;
	photoIndex: number;
	currentIndex: number;
}) {
	// const [progress, setProgress] = useState(0);
	// const [src, setSrc] = useState<string | undefined>(undefined);
	// const [loaded, setLoaded] = useState(false);

	// const isMounted = useRef<boolean | null>(null);
	// const imageRequest = useRef<XMLHttpRequest | null>(null);
	// useEffect(() => {
	// 	isMounted.current = true;
	// 	return () => {
	// 		isMounted.current = false;
	// 		if (imageRequest.current) imageRequest.current.abort();
	// 	};
	// }, []);

	// const renderImage = (url: string) => {
	// 	const req = new XMLHttpRequest();
	// 	req.responseType = 'arraybuffer';
	// 	req.open('GET', url, true);
	// 	req.onprogress = ev => {
	// 		if (isMounted.current) setProgress((ev.loaded / ev.total) * 100);
	// 	};
	// 	req.onloadend = e => {
	// 		const blob = new Blob([req.response]);
	// 		if (isMounted.current) setSrc(window.URL.createObjectURL(blob));
	// 		if (isMounted.current) setLoaded(true);
	// 	};
	// 	req.send();
	// 	imageRequest.current = req;
	// };

	// useEffect(() => {
	// 	renderImage(photo.url || '');
	// }, [photo.url]);

	return (
		<Container>
			<PanAndZoom
				src={photo.url}
				// imgProps={{
				// 	onLoad: () => {
				// 		setLoaded(true);
				// 	},
				// }}
				reset={currentIndex !== photoIndex}
				// style={{
				// 	display: loaded ? 'block' : 'none',
				// }}
			/>
			{/* {!loaded && currentIndex === photoIndex ? (
				<LoadingScreen message={`Loading ${photo.name}...`} progress={progress} />
			) : null} */}
		</Container>
	);
}

const Container = styled.div`
	height: 100%;
	width: 100%;
	background-color: #161616;
`;
