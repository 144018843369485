import { useContext, useEffect, useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import ShareIcon from '@mui/icons-material/Share';
import styled from 'styled-components';
import { SnackContext } from '../../../../context/SnackProvider';
import { ProjectContext } from '../../../../context/ProjectProvider';
import { YellowIconButton } from '../../../../components/styled-components/buttons';
import { StatusIcon } from '../../../../components/styled-components/styledReactTable';
import EmailSharingBox from '../../../../components/reusable-components/EmailSharingBox';
import ProjectIconButton from './ProjectIconButton';

export default function MultipleShareButton() {
	const { setSnackbarProps } = useContext(SnackContext);
	const { projectsSelected } = useContext(ProjectContext);

	const [showingShareModal, setShowingShareModal] = useState(false);

	const handleClose = () => {
		setShowingShareModal(false);
	};

	useEffect(() => {
		if (!projectsSelected.length) {
			handleClose();
		}
	}, [projectsSelected]);

	return (
		<>
			{/* Share Button */}
			<YellowIconButton
				title="Share multiple projects"
				size="large"
				onClick={() => {
					if (projectsSelected?.length > 0) {
						setShowingShareModal(true);
					} else {
						setSnackbarProps({
							open: true,
							message: 'No Projects Selected to Share!',
							severity: 'warning',
						});
					}
				}}>
				<ShareIcon />
			</YellowIconButton>

			{/* Share Dialog */}
			<Dialog open={showingShareModal} onClose={handleClose}>
				<DialogTitle>
					<ShareIcon />
					<span>Share Project(s)</span>
				</DialogTitle>
				<StyledDialogContent>
					<ProjectList>
						{projectsSelected?.map((proj, i) => {
							return (
								<ProjectRow key={i}>
									<ProjectIconButton projectId={proj.id || ''}>
										<StatusIcon status={proj.projectStatus} />
									</ProjectIconButton>

									{proj.address.length > 0 ? (
										<span>{proj.address}</span>
									) : proj.name.length > 0 ? (
										<span>{proj.name}</span>
									) : (
										<span>Unnamed Project</span>
									)}
								</ProjectRow>
							);
						})}
					</ProjectList>

					<EmailSharingBox />
				</StyledDialogContent>

				<DialogActions>
					<Button onClick={handleClose}>close</Button>
				</DialogActions>
			</Dialog>
		</>
	);
}

const StyledDialogContent = styled(DialogContent)`
	display: flex;
	flex-direction: column;
	gap: 20px;

	background-color: dimgrey;

	> div {
		background-color: white;
		border-radius: 8px;
		padding: 5px;
	}
`;

const ProjectList = styled.div`
	display: flex;
	flex-direction: column;
	gap: 5px;

	max-height: 300px;
	overflow: auto;
`;

const ProjectRow = styled.div`
	display: flex;
	align-items: center;
	gap: 5px;

	line-height: normal;
`;
