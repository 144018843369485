import { UploadTask } from 'firebase/storage';
import { ProjectData } from '../../types';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { ProjectContext } from '../../context/ProjectProvider';
import { SnackContext } from '../../context/SnackProvider';
import { AuthContext } from '../../context/AuthProvider';
import Dropzone, { DropEvent, FileRejection } from 'react-dropzone';
import { uploadFile } from '../../utils/project-file-upload';
import Spinner from './Spinner';
import ProjectFilesDialog from './DeliverableFiles/components/ProjectFilesDialog.tsx';
import ConfirmDialog from './ConfirmDialog';
import styled from 'styled-components';
import { FileType } from './DeliverableFiles/types';

export default function FileDnd({
	projectProp,
	darkMode = true,
	field,
	setProjects,
}: {
	projectProp?: ProjectData;
	darkMode?: boolean;
	field: FileType;
	setProjects?: React.Dispatch<React.SetStateAction<ProjectData[]>>;
}) {
	const [showingFilesDialog, setShowingFilesDialog] = useState(false);
	const [showingConfirmDialog, setShowingConfirmDialog] = useState(false);

	const [filesUploaded, setFilesUploaded] = useState(0);

	const [filesToUpload, setFilesToUpload] = useState<File[]>([]);
	const [fileUploadQueue, setFileUploadQueue] = useState<File[]>([]);
	const [currentFileUpload, setCurrentFileUpload] = useState<UploadTask | null>(null);
	const [uploadDetails, setUploadDetails] = useState('');

	const { project, setProject } = useContext(ProjectContext);
	const { setSnackbarProps } = useContext(SnackContext);
	const { user } = useContext(AuthContext);

	const projectData: ProjectData | undefined = useMemo(() => {
		return project || projectProp;
	}, [project, projectProp]);

	const onDrop = (
		acceptedFiles: File[],
		fileRejections: FileRejection[],
		event: DropEvent
	) => {
		event.stopPropagation();
		const rejects = fileRejections.map(x => x.file.name);
		if (fileRejections.length) {
			setSnackbarProps({
				open: true,
				message: `${rejects.join(', ')} rejected`,
				severity: 'error',
			});
		} else {
			let totalFiles: File[] = [];
			setFilesToUpload(prev => {
				totalFiles = [...prev, ...acceptedFiles];
				return totalFiles;
			});
			setShowingConfirmDialog(true);
		}
	};

	const uploadFiles = useCallback(
		async (files: File[]) => {
			for (const file of files) {
				await uploadFile(
					file,
					projectData?.id as string,
					field,
					setCurrentFileUpload,
					setUploadDetails,
					setProject,
					setProjects
				);
			}
			setCurrentFileUpload(null);
		},
		[field, projectData?.id, setProject, setProjects]
	);

	const confirmDialogClose = async (result: boolean) => {
		if (result) {
			setFileUploadQueue(prev => [...prev, ...filesToUpload]);
		}
		setFilesToUpload([]);
	};

	useEffect(() => {
		if (!currentFileUpload && fileUploadQueue.length) {
			uploadFiles(fileUploadQueue);
			setFileUploadQueue([]);
		}
	}, [currentFileUpload, fileUploadQueue, uploadFiles]);

	useEffect(() => {
		if (projectData) {
			let count = projectData[field]?.length || 0;
			if (user?.isAdmin) {
				// @ts-ignore
				count += projectData[field + 'inProgress']?.length || 0;
				// @ts-ignore
				count += projectData[field + 'Archive']?.length || 0;
			}
			setFilesUploaded(count);
		}
	}, [field, projectData, user?.isAdmin]);

	return (
		<>
			<Dropzone noClick onDrop={onDrop}>
				{({ getRootProps, getInputProps, isDragActive }) => (
					<DnDRoot
						{...getRootProps()}
						className="fieldInfo"
						isDragActive={isDragActive}
						darkMode={darkMode}>
						{uploadDetails ? (
							<LoadingContainer>
								<Spinner size={40} />
								<LoadingDetails>{uploadDetails}</LoadingDetails>
							</LoadingContainer>
						) : (
							<FieldInfoSpan
								onClick={() => setShowingFilesDialog(true)}
								title="View uploaded files">
								{`${filesUploaded} File(s) uploaded`}
							</FieldInfoSpan>
						)}

						<input {...getInputProps()} />
					</DnDRoot>
				)}
			</Dropzone>

			<ProjectFilesDialog
				deliverableKey={field}
				showingDialog={showingFilesDialog}
				setShowingDialog={setShowingFilesDialog}
				uploadFunction={files => setFileUploadQueue(prev => [...prev, ...files])}
				projectProp={projectProp}
			/>

			<ConfirmDialog
				open={showingConfirmDialog}
				setOpen={setShowingConfirmDialog}
				onClose={confirmDialogClose}
				title={fileTitleMap[field]}
				content={
					<Dropzone noClick onDrop={onDrop}>
						{({ getRootProps, getInputProps, isDragActive }) => (
							<ConfirmDialogRoot {...getRootProps()} isDragActive={isDragActive}>
								<p>Upload following files?</p>
								<ul>
									{filesToUpload.map((file, i) => {
										return <li key={file.name + i}>{file.name}</li>;
									})}
								</ul>
								<input {...getInputProps()} />
							</ConfirmDialogRoot>
						)}
					</Dropzone>
				}
			/>
		</>
	);
}

const fileTitleMap = {
	proposal: 'Proposal File(s) Upload',
	pointCloud: 'Point Cloud File(s) Upload',
	photos: 'Photo(s) Upload',
	pdf: 'PDF File(s) Upload',
	existingDocs: 'Existing Documentation Upload',
	autodesk: '3D / 2D File(s) Upload',
};

const DnDRoot = styled.div<{ isDragActive: boolean; darkMode: boolean }>`
	width: fit-content;
	transition: 0.2s;
	border: ${({ isDragActive }) => {
		return isDragActive ? 'dashed 1px green' : 'none';
	}};
	color: ${({ darkMode }) => (darkMode ? 'white' : 'black')};

	&:hover,
	:focus-visible {
		color: #ffb310;
		outline: none;
	}
`;

const FieldInfoSpan = styled.span`
	cursor: pointer;
`;

const LoadingContainer = styled.div`
	display: flex;
	align-items: center;
	gap: 10px;
`;

const LoadingDetails = styled.h4`
	max-width: 100%;
	margin: 0px;
	line-height: normal;
`;

const ConfirmDialogRoot = styled.div<{ isDragActive: boolean }>`
	transition: 0.3s;
	border: ${({ isDragActive }) => {
		return isDragActive ? 'dashed 3px green' : 'solid 3px white';
	}};
	background-color: ${({ isDragActive }) => {
		return isDragActive ? 'lightgrey' : 'white';
	}};
	padding: 5px;
`;
