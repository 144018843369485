import { Info } from '@mui/icons-material';
import { IconButton, Popover } from '@mui/material';
import { useState } from 'react';
import styled, { CSSProperties } from 'styled-components';

export default function InfoIcon({
	title,
	style,
	message,
}: {
	title?: string;
	message: string;
	style?: CSSProperties;
}) {
	const [infoAnchor, setInfoAnchor] = useState<null | HTMLElement>(null);

	const handleCloseInfo = () => {
		setInfoAnchor(null);
	};

	const handleClickInfo = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
		setInfoAnchor(e.currentTarget);
	};

	return (
		<>
			<IconButton onClick={handleClickInfo} title={title}>
				<StyledInfoIcon />
			</IconButton>
			<InfoPopover
				open={Boolean(infoAnchor)}
				anchorEl={infoAnchor}
				onClose={handleCloseInfo}
				anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}>
				<span style={{ whiteSpace: 'pre-wrap' }}>{message}</span>
			</InfoPopover>
		</>
	);
}

const InfoPopover = styled(Popover)`
	.MuiPaper-root {
		display: flex;
		flex-direction: column;
		gap: 10px;

		padding: 20px;
		max-width: 500px;

		span {
			word-wrap: break-word;
		}
	}
`;

const StyledInfoIcon = styled(Info)`
	color: #ffb310;
`;
