import styled from 'styled-components';

const DownloadMessage = ({ message }: { message: string }) => {
	return (
		<>
			{message && (
				<>
					{message.includes('Saving') ? (
						<Saving>{message}</Saving>
					) : (
						<Downloading>
							{message.split(' - ').slice(0, -1).join(' - ')}
							<Size>
								<Current>{message.split(' - ').pop()?.split(' / ')[0]}</Current>
								<Total>
									{message.split(' - ').length > 0 ? '/' : ''}
									{message.split(' - ').pop()?.split(' / ')[1]}
								</Total>
							</Size>
						</Downloading>
					)}
				</>
			)}
		</>
	);
};

export default DownloadMessage;

const Saving = styled.div`
	padding: 8px;
	width: 100%;
	text-align: center;
`;

const Downloading = styled.div`
	padding: 8px;
	width: 100%;
	display: flex;
	flex-direction: row;
`;

const Size = styled.div`
	margin-left: auto;
	display: flex;
	flex-direction: row;
	justify-content: center;
`;

const Current = styled.div`
	width: 100px;
	text-align: end;
`;

const Total = styled.div`
	width: 120px;
	text-align: start;
`;
