import { Row } from 'react-table';
import { UserWithData } from '../../../../types';
import { MenuItem } from '@mui/material';
import { useContext } from 'react';
import { AuthContext } from '../../../../context/AuthProvider';
import { db } from '../../../../firebase';
import { doc, updateDoc } from 'firebase/firestore';
import { SnackContext } from '../../../../context/SnackProvider';
import { YellowTextField } from '../../../styled-components/styledReactTable';
import styled from 'styled-components';

type UserTypeCellProps = {
	row: Row<UserWithData>;
};

export default function UserTypeCell({ row }: UserTypeCellProps) {
	const typeList = ['Admin', 'Contractor', 'Client'];
	const type = getUserType(row.original);
	const { setSnackbarProps } = useContext(SnackContext);
	const { setUsersWithData } = useContext(AuthContext);

	const userId = row.original.id;
	const userDocRef = doc(db, `users/${userId}`);

	const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
		const type = e.target.value;
		const newValue = setUserType(type);

		updateDoc(userDocRef, {
			...newValue,
		})
			.then(() => {
				setUsersWithData(prev => {
					const idx = prev.findIndex(userWithData => userWithData.id === userId);
					if (idx >= 0) {
						prev.splice(idx, 1, {
							...row.original,
							...newValue,
						});
					}
					return prev;
				});
				setSnackbarProps({
					open: true,
					severity: 'success',
					message: `${row.original.fullName}'s account type has been changed to ${type}`,
				});
			})
			.catch(err => {
				console.error('Could not update user doc', err);
				setSnackbarProps({
					open: true,
					severity: 'error',
					message: `Could not change user type`,
				});
			});
	};

	return (
		<StyledSelect select value={type} onChange={handleChange}>
			{typeList.map(type => (
				<MenuItem key={type} value={type}>
					{type}
				</MenuItem>
			))}
		</StyledSelect>
	);
}

const StyledSelect = styled(YellowTextField)`
	.MuiSelect-select {
		padding-top: 10px;
		padding-bottom: 10px;
	}
`;

const getUserType = (user: any) => {
	let type = '';
	if (user.isAdmin) type = 'Admin';
	if (user.isContractor) type = 'Contractor';
	if (!user.isAdmin && !user.isContractor) type = 'Client';
	return type;
};

const setUserType = (type: string) => {
	let isAdmin = false;
	let isContractor = false;
	if (type === 'Admin') isAdmin = true;
	if (type === 'Contractor') isContractor = true;
	return { isAdmin, isContractor };
};
