import { FieldLabel } from '../../../../../screens/ProjectInfo';
import React, { useContext, useState } from 'react';
import { AuthContext } from '../../../../../context/AuthProvider';
import MultiLinkField from './MultiLinkField';
import { ProjectData } from '../../../../../types';

// Props for VR (Matterport) Link
interface VRLinkProps {
	isActive: boolean;
	setPartialFormState: React.Dispatch<React.SetStateAction<Partial<ProjectData>>>;
}

// VR (Matterport) Link Information
export default function VRLink({ isActive, setPartialFormState }: VRLinkProps) {
	const { user } = useContext(AuthContext);
	const [editVRLink, setEditVRLink] = useState(false);

	return (
		<div className="infoRow">
			<FieldLabel label={'VR Link(s)'} />
			<MultiLinkField
				field={'matterport'}
				title={'VR'}
				setState={setPartialFormState}
				isActive={Boolean(user?.isAdmin && isActive)}
				isFocused={editVRLink}
				setFocused={setEditVRLink}
			/>
		</div>
	);
}
