import { Dialog, DialogContent, DialogTitle } from '@mui/material';
import styled from 'styled-components';

export const StyledDialog = styled(Dialog)`
	& .MuiDialogContent-root {
		justify-content: center;
		align-items: center;
	}
	& .MuiDialog-paper {
		max-width: 90vw;
		height: 800px;
	}
`;

export const StyledDialogTitle = styled(DialogTitle)`
	display: flex;
	align-items: center;
	justify-content: flex-end;
	width: 100%;
	padding-right: 20px;
`;

export const StyledContent = styled(DialogContent)`
	background-color: #131313;
	justify-content: center;
	align-items: center;
	width: 100%;
`;

export const GalleryImage = styled.img`
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	max-width: 99%;
	max-height: 99%;
	object-fit: contain;
`;
