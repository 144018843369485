import { Row } from 'react-table';
import { UserWithData } from '../../../../types';
import { MenuItem } from '@mui/material';
import { useContext } from 'react';
import { AuthContext } from '../../../../context/AuthProvider';
import { db } from '../../../../firebase';
import { doc, updateDoc } from 'firebase/firestore';
import { SnackContext } from '../../../../context/SnackProvider';
import { YellowTextField } from '../../../styled-components/styledReactTable';
import styled from 'styled-components';

type UserStatusCellProps = {
	row: Row<UserWithData>;
};

export default function UserStatusCell({ row }: UserStatusCellProps) {
	const { setSnackbarProps } = useContext(SnackContext);
	const { setUsersWithData } = useContext(AuthContext);

	const userId = row.original.id;
	const userDocRef = doc(db, `users/${userId}`);

	const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
		const newValue = Boolean(e.target.value);

		updateDoc(userDocRef, {
			disabled: newValue,
		})
			.then(() => {
				setUsersWithData(prev => {
					const idx = prev.findIndex(userWithData => userWithData.id === userId);
					if (idx >= 0) {
						prev.splice(idx, 1, {
							...row.original,
							disabled: newValue,
						});
					}
					return prev;
				});
				setSnackbarProps({
					open: true,
					severity: 'success',
					message: `${row.original.fullName} has been ${
						newValue ? 'disabled' : 'activated'
					}`,
				});
			})
			.catch(err => {
				console.error('Could not update user doc', err);
				setSnackbarProps({
					open: true,
					severity: 'error',
					message: `Could not change user status`,
				});
			});
	};

	return (
		<StyledSelect select value={Boolean(row.original.disabled)} onChange={handleChange}>
			<MenuItem value={false as any}>Active</MenuItem>
			<MenuItem value={true as any}>Disabled</MenuItem>
		</StyledSelect>
	);
}

const StyledSelect = styled(YellowTextField)`
	.MuiSelect-select {
		padding-top: 10px;
		padding-bottom: 10px;
	}
`;
