import { FormNode } from '../../../../utils/types';
import { useContext, useEffect, useState } from 'react';
import { FormV2Context } from '../../../../context/FormV2Context.types';
import { useItemNodes } from '../../../../hooks/useItemNodes';
import ChildChip from '../ChildChip/ChildChip';
import { ChipTitle, RootNodeChip, TitleContainer } from './NodeChip.styles';
import { getFlagStyle, scrollToAndHighlight } from '../../../../utils/utils';

const NodeChip = ({
	rootNode,
	isExpanded,
}: {
	rootNode: FormNode;
	isExpanded: boolean;
}) => {
	const { setCurrentNode } = useContext(FormV2Context);
	const { itemNodes } = useItemNodes(rootNode);
	const [activeItemId, setActiveItemId] = useState('');

	const handleClick = (node: FormNode) => {
		setCurrentNode(node);
	};

	const handleScroll = (node: FormNode) => {
		setActiveItemId(node.id);
	};

	useEffect(() => {
		if (activeItemId) {
			scrollToAndHighlight(activeItemId);
		}
	}, [activeItemId]);

	return (
		<RootNodeChip
			onClick={() => handleClick(rootNode)}
			key={rootNode.id}
			isExpanded={isExpanded}
			style={getFlagStyle(rootNode.flag)}>
			<TitleContainer>
				<ChipTitle>{rootNode.displayTitle}</ChipTitle>
			</TitleContainer>
			{isExpanded &&
				itemNodes.map(child => (
					<ChildChip
						key={child.id}
						rootNode={child}
						onClick={() => handleScroll(child)}
					/>
				))}
		</RootNodeChip>
	);
};
export default NodeChip;
