import { useState } from 'react';

import Slide from '@mui/material/Slide';

import LogoButton from '../LogoButton';
import styled from 'styled-components';
import theme from '../../../styles/theme';
import LayoutDrawer from './components/LayoutDrawer';
import { LogoTeam } from '../LogoTeam';

const drawerWidth = '240px';
export const appBarHeight = '75px';

export default function Layout({
	title = '',
	children,
	LeftNavComponent,
	RightNavComponent,
	orgLogo = '',
	isTeamPage = false,
}: {
	title?: string;
	children?: React.ReactNode;
	LeftNavComponent?: React.ReactNode;
	RightNavComponent?: React.ReactNode;
	orgLogo?: string;
	isTeamPage?: boolean;
}) {
	const [drawerOpen, setDrawerOpen] = useState(false);

	return (
		<Slide in={true} direction="down">
			<LayoutContainer>
				<NavBarContainer>
					<NavBar $drawerOpen={drawerOpen}>
						{/* Left-aligned elements */}
						{!!orgLogo ? (
							<LogoTeam src={orgLogo} alt={title} />
						) : (
							<LogoButton disabled={isTeamPage} />
						)}

						{title ? <LayoutTitle>{title}</LayoutTitle> : null}

						{/* Displays any components passed into Layout as left-aligned */}
						{LeftNavComponent}

						{/* Right-aligned elements */}
						<RightNavComponentContainer>
							{/* Displays any components passed into Layout as right-aligned */}
							{RightNavComponent}

							<LayoutDrawer
								drawerOpen={drawerOpen}
								setDrawerOpen={setDrawerOpen}
								width={drawerWidth}
								appBarHeight={appBarHeight}
							/>
						</RightNavComponentContainer>
					</NavBar>
				</NavBarContainer>

				<LayoutChildrenContainer>{children}</LayoutChildrenContainer>
			</LayoutContainer>
		</Slide>
	);
}

const LayoutContainer = styled.div`
	display: flex;
`;

const NavBar = styled.header<{ $drawerOpen: boolean }>`
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 5px;
	color: white;
	background-color: black;
	border-bottom: solid 2px ${theme.palette.primary.main};

	height: ${appBarHeight};
	width: ${({ $drawerOpen }) => (!$drawerOpen ? '100vw' : `calc(100% - ${drawerWidth})`)};
	margin-right: ${({ $drawerOpen }) => (!$drawerOpen ? 'none' : drawerWidth)};
	overflow-x: auto;

	transition: ${({ $drawerOpen }) =>
		!$drawerOpen
			? theme.transitions.create(['margin', 'width'], {
					easing: theme.transitions.easing.sharp,
					duration: theme.transitions.duration.leavingScreen,
			  })
			: theme.transitions.create(['margin', 'width'], {
					easing: theme.transitions.easing.easeOut,
					duration: theme.transitions.duration.enteringScreen,
			  })};

	> h1 {
		margin: 0;
	}
`;

const LayoutTitle = styled.h1`
	line-height: normal;
	margin: 0;
	flex-shrink: 1;
	min-width: 80px;

	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
`;

const NavBarContainer = styled.div`
	position: fixed;
	z-index: 2;
	width: 100%;
	gap: 5px;
`;

const RightNavComponentContainer = styled.div`
	display: flex;
	gap: 5px;
	align-items: center;
	margin-left: auto;
`;

const LayoutChildrenContainer = styled.main`
	z-index: 1;
	flex-grow: 1;
	transition: ${theme.transitions.create('margin', {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.leavingScreen,
	})};

	height: calc(100vh - ${appBarHeight});
	width: 100%;
	margin-top: ${appBarHeight};
	overflow-y: auto;
`;
