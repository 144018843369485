import { ContainerBorder } from './ProjectsList/components/ClientProjects/styles';
import {
	ProjectCategory,
	categoryFields,
	validateCategory,
} from '../components/screen-components/ProjectInfo/utils';
import {
	getCompletedForms,
	getProject,
	getProjectPassword,
	getSharedUsers,
} from '../firebase';
import { useParams, useSearchParams } from 'react-router-dom';
import { Button, Fab, Tab, Tabs } from '@mui/material';
import { AuthContext } from '../context/AuthProvider';
import { ProjectContext } from '../context/ProjectProvider';
import { LoadingContext } from '../context/LoadingProvider';
import { formatProjectTimestamps, useNav } from '../utils';
import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import ProjectInfoCategory, {
	ProjectInfoCategoryHandles,
} from '../components/screen-components/ProjectInfo/components/ProjectInfoCategory';
import DeliverableFiles from '../components/reusable-components/DeliverableFiles';
import RightNavButtons from '../components/screen-components/ProjectInfo/components/RightNavButtons';
import SectionHeader from './ProjectsList/components/ClientProjects/components/SectionHeader';
import LoadingLayer from '../components/reusable-components/LoadingLayer';
import V2FormsList from './V2FormsList';
import NewTracker from '../components/reusable-components/ProjectTracker/NewTracker';
import styled from 'styled-components';
import Layout from '../components/reusable-components/Layout';
import Paper from '@mui/material/Paper';
import '../styles/ProjectInfo.css';
import {
	PendingUser,
	SharedUser,
} from '../components/screen-components/ProjectInfo/components/RightNavButtons/types';
import ProjectInfoDropdown, {
	ProjectInfoDropdownHandles,
} from '../components/screen-components/ProjectInfo/components/ProjectInfoDropdown';
import ClientUploadBoxContent from '../components/reusable-components/DeliverableFiles/components/ClientUploadBox/ClientUploadBoxContent';
import ProjectInfoMap from '../components/screen-components/ProjectInfo/components/ProjectInfoMap';
import { Add } from '@mui/icons-material';
import theme from '../styles/theme';
import ClientDeliverableFiles from '../components/reusable-components/DeliverableFiles/ClientIndex';
import DeliveryCountdown from '../components/reusable-components/ProjectTracker/DeliveryCountdown';
import ClientTracker from '../components/reusable-components/ProjectTracker/ClientTracker';
import DocusignButton from '../components/screen-components/ProjectInfo/components/DocusignButton';
import GenerateProposalButton from '../components/screen-components/ProjectInfo/components/GenerateProposalButton';
import GoogleMapsButton from '../components/screen-components/ProjectInfo/components/GoogleMapsButton';

export const FieldLabel = ({ label }: { label: string }) => {
	return <div className="fieldLabel">{`${label}:`}</div>;
};

// Functional component that displays the details of the project selected by the project useState
const ProjectInfo: React.FC = () => {
	const [searchParams] = useSearchParams();
	const { loadingStatus, setLoadingStatus } = useContext(LoadingContext);
	const { user } = useContext(AuthContext);
	const isContractor = !!user?.isContractor;
	const [sharedUsers, setSharedUsers] = useState<SharedUser[]>([]);
	const [pendingUsers, setPendingUsers] = useState<PendingUser[]>([]);
	const [loadingUsers, setLoadingUsers] = useState<boolean>(false);
	const { project, setProject, setShowContractorDialog } = useContext(ProjectContext);

	const [utilityFormComplete, setUtilityFormComplete] = useState(false);
	const _isMounted = useRef(true);
	const { projectId } = useParams();
	const navigate = useNav();
	const [currentTab, setCurrentTab] = useState(Number(searchParams.get('option')) || 0);
	const [isActive, setIsActive] = useState(false);

	const projectInfoCategorySubmitRef = useRef<ProjectInfoCategoryHandles>(null);
	const projectInfoDropdownRef = useRef<ProjectInfoDropdownHandles>(null);

	const address = project?.address.replace(/\s+/g, '+');

	const hasFiles = [
		project?.existingDocs,
		project?.photos,
		project?.matterport,
		project?.pointCloud,
		project?.pdf,
		project?.autodesk,
	].some(array => array && array.length > 0);

	const [categoryCompletions, setCategoryCompletions] = useState<
		Record<ProjectCategory, boolean>
	>({
		projectScope: false,
		scanning: false,
		bim: false,
		projectDetails: false,
	});

	const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
		setCurrentTab(newValue);
	};

	const getSetMembers = useCallback(async () => {
		if (!project) return;

		setLoadingUsers(true);
		const res = await getSharedUsers({ projectId: project.id });
		const data = res.data as any;
		setSharedUsers(data.sharedUsers ?? []);
		setPendingUsers(data.pendingUsers ?? []);
		setLoadingUsers(false);
	}, [project]);

	useEffect(() => {
		if (!!project) {
			for (const category in categoryFields) {
				const projCat = category as ProjectCategory;
				let complete = true;

				switch (projCat) {
					case 'projectScope':
						if (
							!validateCategory(
								project,
								projCat
								// [docusignComplete]
							)
						) {
							complete = false;
						}
						break;
					case 'scanning':
						if (!validateCategory(project, projCat)) {
							complete = false;
						}
						break;
					default:
						if (!validateCategory(project, projCat)) {
							complete = false;
						}
				}

				setCategoryCompletions(prev => {
					return {
						...prev,
						[projCat]: complete,
					};
				});
			}
		}
	}, [project, utilityFormComplete]);

	const fetchCompletionStatus = useCallback(async () => {
		if (!projectId) return;
		try {
			const utilityFormStatus = (await getCompletedForms({ projectId })) as any;
			setUtilityFormComplete(utilityFormStatus.data);
		} catch (error) {
			console.error('Error fetching completion status:', error);
		}
	}, [projectId]);

	useEffect(() => {
		let isMounted = true;
		if (projectId) {
			fetchCompletionStatus().then(() => {
				if (!isMounted) return;
			});
		}
		return () => {
			isMounted = false;
		};
	}, [projectId, fetchCompletionStatus]);

	// Fetch, format, and set project
	useEffect(() => {
		const getSetProject = async () => {
			try {
				setLoadingStatus({ title: 'Loading project...' });
				const res = await getProject({ projectId: projectId });
				const resData = res.data as any;
				setProject(formatProjectTimestamps([resData.project])[0]);
				setLoadingStatus(null);
			} catch (err) {
				console.log(err);
				navigate('/404', { replace: true });
			}
		};
		getSetProject();

		return () => {
			setProject(null);
		};
	}, [projectId, user, setProject, navigate, setLoadingStatus]);

	const [anonUploadPassword, setAnonUploadPassword] = useState<string>('');

	// Fetches password for anonymous upload page
	useEffect(() => {
		if (project) {
			document.title = document.title = `${
				project.address || project.name || 'Unnamed Project'
			} | Project Page`;

			getProjectPassword({ projectId: project.id })
				.then((res: any) => {
					if (_isMounted.current) {
						const password = res.data.result;
						setAnonUploadPassword(password);
					}
				})
				.catch(e => {
					console.error(e);
				});
		}
	}, [project]);

	useEffect(() => {
		_isMounted.current = true;
		return () => {
			_isMounted.current = false;
		};
	}, []);

	useEffect(() => {
		if (project && !isContractor) {
			getSetMembers();
		}
	}, [project, isContractor, getSetMembers]);

	const handleProjectInfoCategorySubmitRef = () => {
		projectInfoCategorySubmitRef.current?.handleSubmit();
	};

	const handleProjectInfoCategoryActiveRef = () => {
		projectInfoCategorySubmitRef.current?.handleActive();
	};

	const handleProjectInfoDropdownOpenRef = (status: boolean) => {
		projectInfoDropdownRef.current?.handleOpen(status);
	};

	const renderData = [
		{
			isAdmin: false,
			render: true,
			node: (
				<ProjectInfoDropdown
					title={'Project Details'}
					sharedUsers={sharedUsers}
					pendingUsers={pendingUsers}
					isAdmin={user?.isAdmin}
					isLoadingEmail={loadingUsers}
					isContractor={isContractor}
					showEmailButton>
					<ProjectInfoCategory
						category="projectDetails"
						fields={categoryFields.projectDetails}
						isVisible={false}
					/>
				</ProjectInfoDropdown>
			),
		},
		{
			isAdmin: false,
			render: true,
			node: (
				<ProjectInfoDropdown title={'Upload New Files'}>
					<ClientUploadBoxContent />
				</ProjectInfoDropdown>
			),
		},
		{
			isAdmin: true,
			render: true,
			node: (
				<ProjectInfoDropdown
					title={'Project Details'}
					sharedUsers={sharedUsers}
					pendingUsers={pendingUsers}
					isAdmin={!!user?.isAdmin}
					isLoadingEmail={loadingUsers}
					isContractor={isContractor}
					isComplete={categoryCompletions.projectScope}
					showEmailButton
					ref={projectInfoDropdownRef}
					customIconLeft={
						user?.isAdmin && (
							<ButtonContainer onClick={e => e.stopPropagation()}>
								<CustomButton
									type="submit"
									variant="contained"
									$isComplete={categoryCompletions.projectScope}
									onClick={() => {
										if (!isActive) handleProjectInfoDropdownOpenRef(true);
										handleProjectInfoCategoryActiveRef();
									}}>
									{!isActive ? 'Edit' : 'Cancel'}
								</CustomButton>

								{isActive ? (
									<CustomButton
										type="submit"
										variant="contained"
										$isComplete={categoryCompletions.projectScope}
										onClick={() => handleProjectInfoCategorySubmitRef()}>
										Submit
									</CustomButton>
								) : null}
							</ButtonContainer>
						)
					}
					customIconRight={
						<CustomIconList onClick={e => e.stopPropagation()}>
							<DocusignButton />
							{project && (
								<GenerateProposalButton
									project={project}
									handleChange={newProjectData => {
										setProject(newProjectData);
									}}
								/>
							)}
							<GoogleMapsButton concatAddress={address} />
						</CustomIconList>
					}>
					<ProjectInfoCategory
						category="projectScope"
						fields={[...categoryFields.projectScope, ...categoryFields.bim]}
						anonUploadPassword={anonUploadPassword}
						setAnonUploadPassword={setAnonUploadPassword}
						isVisible={false}
						isComplete={categoryCompletions.projectScope && categoryCompletions.bim}
						ref={projectInfoCategorySubmitRef}
						handleSetActive={setIsActive}
						showBIMTitle={true}
					/>
				</ProjectInfoDropdown>
			),
		},
		{
			isAdmin: false,
			isContractor: true,
			render: true,
			node: (
				<ProjectInfoDropdown
					title={'BIM'}
					isAdmin={!!user?.isAdmin}
					isComplete={categoryCompletions.bim}>
					<ProjectInfoCategory
						category="bim"
						fields={categoryFields.bim}
						anonUploadPassword={anonUploadPassword}
						setAnonUploadPassword={setAnonUploadPassword}
						isVisible={false}
						isComplete={categoryCompletions.bim}
					/>
				</ProjectInfoDropdown>
			),
		},
		{
			isAll: false,
			IsAdmin: false,
			isContractor: true,
			render: true,
			node: (
				<ProjectInfoDropdown
					title={'Map'}
					isAdmin={!!user?.isAdmin}
					customIconRight={
						user?.isAdmin && (
							<StyledFab
								title="Add a new contractor"
								onClick={event => [
									event.stopPropagation(),
									setShowContractorDialog(true),
								]}>
								<Add fontSize="small" sx={{ fill: theme.palette.common.black }} />
							</StyledFab>
						)
					}>
					{project && <ProjectInfoMap lat={project.lat ?? 0} lng={project.lng ?? 0} />}
				</ProjectInfoDropdown>
			),
		},
	];

	return (
		<Layout
			title={
				project
					? project.address || project.name || 'Unnamed Project'
					: 'Loading Project...'
			}
			LeftNavComponent={
				<>
					{user?.isAdmin ? (
						<NewTracker projectStatus={project?.projectStatus} nav={true} />
					) : (
						<ClientTracker projectStatus={project?.projectStatus} nav={true} />
					)}
					<div style={{ paddingLeft: 10 }}>
						<DeliveryCountdown />
					</div>
				</>
			}
			RightNavComponent={
				<>
					<RightNavButtons />
				</>
			}>
			<ProjectInfoWrapper isAdmin={user?.isAdmin}>
				<LoadingLayer showOverlay={!!loadingStatus} />

				<ProjectInfoDetails>
					{renderData.map(
						({ isAdmin, isContractor, node, render, isAll }, index) =>
							(isAdmin === !!user?.isAdmin ||
								isAll ||
								isContractor === !!user?.isContractor) &&
							render && <div key={index}>{node}</div>
					)}
				</ProjectInfoDetails>

				{/* Deliverable Files Container on Right side of page */}
				<Paper
					elevation={0}
					style={{
						backgroundColor: 'transparent',
						width: '100%',
						height: '100%',
						color: 'white',
						paddingRight: '2px',
						display: 'flex',
						flexDirection: 'column',
					}}>
					<ContainerBorder
						style={{
							height: '100%',
							display: 'flex',
							flexDirection: 'column',
						}}>
						<SectionHeader showLogo={false}>
							<Tabs value={currentTab} onChange={handleTabChange} centered>
								<StyledTab label="Files" />
								<StyledTab label="Reports" />
							</Tabs>
						</SectionHeader>
						<div style={{ height: '100%', width: '100%' }}>
							{currentTab === 0 &&
								(isContractor || user?.isAdmin ? (
									<DeliverableFiles />
								) : (
									<ClientDeliverableFiles hasFiles={hasFiles} />
								))}
							{currentTab === 1 && <V2FormsList hideNav={true} />}
						</div>
					</ContainerBorder>
				</Paper>
			</ProjectInfoWrapper>
		</Layout>
	);
};

const ProjectInfoWrapper = styled.div<{ isAdmin?: boolean }>`
	display: grid;

	width: 100%;
	height: 100%;

	@media screen and (min-width: 1024px) {
		grid-template-columns: ${({ isAdmin }) => (isAdmin ? '650px auto' : '600px auto')};
	}
	@media screen and (max-width: 1023px) {
		grid-template-columns: 100%;
		padding: 0 10px;
		height: fit-content;
	}
`;

const ProjectInfoDetails = styled.div`
	display: flex;
	flex-direction: column;
	gap: 15px;

	width: 100%;
	height: 100%;
	overflow: auto;
	padding: 14px 25px;

	color: white;
`;

const StyledFab = styled(Fab)`
	margin-top: -10px;
	margin-bottom: -10px;
	width: 24px;
	height: 24px;
	background-color: ${theme.palette.error.main};
	transition: 0.3s;
	&:hover {
		opacity: 0.7;
		background-color: ${theme.palette.error.main};
	}
	min-height: unset;
`;

export const StyledTab = styled(Tab)`
	color: ${theme.palette.common.white};
	text-transform: none;
`;
export default ProjectInfo;

const CustomIconList = styled.div`
	display: flex;
	align-items: center;
`;

const ButtonContainer = styled.div`
	display: flex;
	justify-content: center;
	gap: 10px;
`;

type ProjectInfoCategoryProps = {
	$isComplete?: boolean;
	$isVisible?: boolean;
};

const CustomButton = styled(Button)<ProjectInfoCategoryProps>`
	transition: 0.2s;
	line-height: normal;
	border-radius: 5px;
	text-transform: capitalize;
	padding: 8px 14px;

	background-color: transparent;
	border: 1px solid #ffb310;
	border-color: ${({ $isComplete }) => ($isComplete ? '#5BC236' : '#ffb310')};
	color: ${({ $isComplete }) => ($isComplete ? '#5BC236' : '#ffb310')};

	&:hover {
		border-color: ${({ $isComplete }) => ($isComplete ? '#5BC236' : '#ffb310')};
		background-color: ${({ $isComplete }) => ($isComplete ? '#6f94619e' : '#c78400')};
	}
`;
