import { useRef, useReducer, useCallback } from 'react';
import { initialState, reducer } from './reducer';
import { pan, startPan, zoom, reset } from './actions';

export default function usePanAndZoom() {
	const [state, dispatch] = useReducer(reducer, initialState);
	const containerRef = useRef<HTMLDivElement | null>(null);
	const imgContainerRef = useRef<HTMLDivElement | null>(null);

	const onMouseMoveInWindow = (event: any) => {
		event.preventDefault();
		dispatch(pan(event));
	};
	const onMouseUpInWindow = () => {
		window.removeEventListener('mouseup', onMouseUpInWindow);
		window.removeEventListener('mousemove', onMouseMoveInWindow);
	};
	const onMouseDown = (event: any) => {
		dispatch(startPan(event));
		window.addEventListener('mouseup', onMouseUpInWindow);
		window.addEventListener('mousemove', onMouseMoveInWindow);
	};
	const onWheel = (event: any) => {
		if (event.deltaY !== 0 && containerRef.current) {
			const containerRect = containerRef.current.getBoundingClientRect();
			dispatch(zoom(event, containerRect));
		}
	};
	const resetPanZoom = useCallback(() => {
		dispatch(reset());
	}, []);

	return {
		...state,
		containerRef,
		imgContainerRef,
		onMouseDown,
		onWheel,
		resetPanZoom,
	};
}
