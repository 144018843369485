import { Avatar } from '@mui/material';
import styled from 'styled-components';
import PersonIcon from '@mui/icons-material/Person';
import { modelerProjectStatuses } from '../../../screens/ModelerPage';
import { Doughnut } from 'react-chartjs-2';
import {
	ArcElement,
	BarElement,
	CategoryScale,
	Chart,
	Legend,
	LinearScale,
	Plugin,
	Title,
	Tooltip,
} from 'chart.js';
import { ProjectData } from '../../../types';
import { AnyObject } from 'chart.js/dist/types/basic';

export default function ModelerInfoContainer({
	modelerId,
	filteredProjects,
}: {
	modelerId: string;
	filteredProjects: ProjectData[] | null;
}) {
	Chart.register(
		CategoryScale,
		LinearScale,
		BarElement,
		Title,
		Tooltip,
		Legend,
		ArcElement
	);
	const totalProjectStatusCounts = modelerProjectStatuses.map(
		status =>
			filteredProjects?.filter(project => project.projectStatus === status).length || 0
	);

	const doughnutOptions = (arr: number[], title: string) => ({
		responsive: true,
		plugins: {
			legend: {
				position: 'right' as const,
			},
			title: {
				display: true,
				text: title,
				color: 'transparent',
			},
		},
		total: arr.reduce((acc, curr) => acc + curr, 0),
	});

	const colorsList = [
		'#f58cff', //Ready for Modeling
		'#4BCCC1', //Modeling in Progress
		'#7c26ff', //QA/QC
		'#EFE751', //On-Site Capture Complete
		'#4c90ef', //Registration
		'#ddff00', //Pending Payment/Delivered
		'#AFEF7E', //Delivered
		'#7c25ad', //Pending Payment
	];

	const totalDoughnutData = {
		labels: [...modelerProjectStatuses],
		datasets: [
			{
				label: 'Projects',
				data: totalProjectStatusCounts,
				backgroundColor: colorsList,
				borderColor: ['black'],
			},
		],
	};

	const centerDoughnutPlugin: Plugin<'doughnut', AnyObject> = {
		id: 'doughnutCenterText',
		beforeDraw: (
			chart: Chart<'doughnut', number[], unknown>,
			args: { cancelable: true },
			options: AnyObject
		) => {
			const width = chart.width;
			const height = chart.height;
			const ctx = chart.ctx;
			const legendWidth = chart.legend?.width || 0;
			const doughnutWidth = width - legendWidth;
			chart.ctx.restore();
			let fontSize = height / 114;
			ctx.font = fontSize.toFixed(2) + 'em Epilogue';

			let text = '-';
			const chartOptions = chart.config.options as any;
			if (chartOptions && chartOptions.total) {
				const total = chartOptions.total as number;
				text = total.toString();
			}

			let textMetrics = ctx.measureText(text);
			// Makes fontSize smaller until it fits within doughnut center
			// (the diameter of the center is 50% of the doughnut's width by default)
			while (textMetrics.width >= doughnutWidth / 2 - 10) {
				fontSize -= 0.3;
				ctx.font = fontSize.toFixed(2) + 'em Epilogue';
				textMetrics = ctx.measureText(text);
			}

			const textX = doughnutWidth / 2 - textMetrics.width / 2;
			const textY =
				height / 1.87 +
				(textMetrics.actualBoundingBoxAscent + textMetrics.actualBoundingBoxDescent) / 2;

			ctx.fillText(text, textX, textY);
			ctx.fillStyle = '#ffb310';
			ctx.save();
		},
	};

	return (
		<Container>
			<div>
				<ModelerAvatar>
					<ModelerIcon />
				</ModelerAvatar>
				<span>{modelerId}</span>
			</div>
			<div id="graph">
				<ModelerGraph>
					<Doughnut
						options={doughnutOptions(
							totalProjectStatusCounts,
							'Total Projects per Status'
						)}
						data={totalDoughnutData}
						plugins={[centerDoughnutPlugin]}
					/>
				</ModelerGraph>
			</div>
		</Container>
	);
}

const ModelerIcon = styled(PersonIcon)`
	font-size: 5rem;

	@media (max-width: 768px) {
		font-size: 3rem;
	}

	@media (min-width: 2200px) {
		font-size: 8rem;
	}
`;

const Container = styled.div`
	display: flex;
	flex-direction: column;
	background-color: #17181b;
	width: 80%;
	height: 100%;
	margin: 1rem auto;
	border-radius: 20px;
	text-align: center;

	@media (max-width: 768px) {
		width: 95%;
		height: 90%;
	}

	> div {
		padding-top: 1rem 0;
	}

	> div > span {
		color: white;
		font-size: 2.5em;
		font-weight: 300;
		text-transform: capitalize;

		@media (max-width: 768px) {
			font-size: 2rem;
		}
		@media (min-width: 2200px) {
			font-size: 4em;
		}
	}
`;

const ModelerAvatar = styled(Avatar)`
	width: 8rem;
	height: 8rem;
	margin: 0 auto;
	margin-top: 3rem;
	margin-bottom: 1rem;

	@media (max-width: 768px) {
		width: 5rem;
		height: 5rem;
	}

	@media (min-width: 2200px) {
		width: 15rem;
		height: 15rem;
	}
`;

const ModelerGraph = styled.div`
	display: flex;
	justify-content: center;
	width: 450px;
	margin: 0 auto;
	margin-top: -4rem;

	@media (max-width: 768px) {
		flex-direction: column;
		align-items: center;
		width: 300px;
	}

	@media (min-width: 2200px) {
		width: 600px;
	}
`;
