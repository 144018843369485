import { FC, useState } from 'react';
import {
	Dialog,
	DialogContent,
	DialogTitle,
	styled,
	IconButton,
	Box,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import theme from '../../../styles/theme';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';
import { SearchOff, ZoomIn, ZoomOut } from '@mui/icons-material';
import Loading from '../../screen-components/ProjectUtilityFormV2/components/Loading/Loading';

type Props = {
	imageUrl: string;
	imageName?: string;
	imageHeight?: number;
	imageWidth?: number;
};

const PreviewImage: FC<Props> = ({
	imageUrl,
	imageName,
	imageHeight = 32,
	imageWidth = 32,
}: Props) => {
	const [open, setOpen] = useState(false);
	const [loading, setLoading] = useState(true);

	return (
		<>
			<Thumbnail
				width={`${imageWidth}px`}
				height={`${imageHeight}px`}
				onClick={() => setOpen(true)}
				src={`${process.env.REACT_APP_image_resizer_url}?imageUrl=${imageUrl}&width=${imageWidth}&height=${imageHeight}`}
			/>
			<Dialog open={open} onClose={() => setOpen(false)}>
				<DialogTitleGrid>
					{imageName}
					<IconButton onClick={() => setOpen(!open)}>
						<CloseIconWhite />
					</IconButton>
				</DialogTitleGrid>
				<DialogContent style={{ padding: 0, minWidth: '250px', minHeight: '250px' }}>
					{loading && (
						<LoadingContainer>
							<Loading size={100} />
						</LoadingContainer>
					)}
					<TransformWrapper maxScale={40}>
						{({ zoomIn, zoomOut, resetTransform }) => (
							<>
								{!loading && (
									<Controls>
										<ZoomControl onClick={() => zoomIn()}>
											<ZoomIn fontSize="large" />
										</ZoomControl>
										<ZoomControl onClick={() => zoomOut()}>
											<ZoomOut fontSize="large" />
										</ZoomControl>
										<ZoomControl onClick={() => resetTransform()}>
											<SearchOff fontSize="large" />
										</ZoomControl>
									</Controls>
								)}
								<TransformComponent contentStyle={{ cursor: 'grab' }}>
									<BigImage
										src={imageUrl}
										alt={imageName}
										onLoad={() => setLoading(false)}
									/>
								</TransformComponent>
							</>
						)}
					</TransformWrapper>
				</DialogContent>
			</Dialog>
		</>
	);
};

const Thumbnail = styled('img')<{ width: string; height: string }>`
	object-fit: cover;
	width: ${({ width }) => width};
	height: ${({ height }) => height};
	border-radius: 5px;
	cursor: pointer;
	border: 1px solid transparent;

	&:hover {
		border: 1px solid ${theme.palette.primary.main};
	}
`;

const DialogTitleGrid = styled(DialogTitle)`
	display: grid;
	grid-template-columns: 1fr max-content;
	height: 65px;
`;

const CloseIconWhite = styled(CloseIcon)`
	color: #fff;
`;

const BigImage = styled('img')`
	width: 100%;
	height: 100%;
`;

const Controls = styled('div')`
	position: absolute;
	top: 80px;
	right: 10px;
	z-index: 2;
	display: flex;
	flex-direction: column;
	gap: 10px;
	border-radius: 5px;
`;

const ZoomControl = styled('button')`
	width: 50px;
	height: 50px;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: rgba(255, 255, 255, 0.5);
	cursor: pointer;
	border-radius: 5px;

	&:hover {
		background-color: rgba(255, 255, 255, 0.7);
	}
`;

const LoadingContainer = styled(Box)`
	position: absolute;
	top: 60%;
	left: 50%;
	transform: translate(-50%, -50%);
`;

export default PreviewImage;
