import { FieldLabel } from '../../../../screens/ProjectInfo';
import React, { useContext } from 'react';
import { AuthContext } from '../../../../context/AuthProvider';
import TextInputField from './TextInputField';
import styled from 'styled-components';

// Props for Project ID
interface NameProps {
	isActive: boolean;
	handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

// Project ID Information
export default function Name({ isActive, handleChange }: NameProps) {
	const { user } = useContext(AuthContext);
	return (
		<Row>
			<FieldLabel label={'Name'} />
			<TextInputField
				field={'name'}
				onBlur={handleChange}
				isActive={Boolean(user?.isAdmin && isActive)}
			/>
		</Row>
	);
}

const Row = styled.div`
	display: flex;
	align-items: center;
	gap: 5px;
	width: 100%;
	padding: 5px;
	font-size: 1.1rem;
	margin-bottom: 10px;

	&:last-child {
		min-width: 300px;
	}
	@media (max-width: 768px) {
		flex-wrap: wrap;
		font-size: 0.9rem;
	}
`;
