import { useCallback, useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import Carousel from '../../../components/reusable-components/Carousel';
import GalleryImg from './GalleryImg';
import { V2FormGalleryContext } from '../V2FormGalleryProvider';
import GalleryControls from './GalleryControls';
import { FormNode } from '../../../components/screen-components/ProjectUtilityFormV2/utils/types';

export default function V2FormGallery() {
	const { formRes, photos, galleryIndex, setGalleryIndex, showPdf, categoryId } =
		useContext(V2FormGalleryContext);
	const [fields, setFields] = useState<FormNode[]>([]);

	const formData = formRes?.form;

	const filterItems = useCallback(() => {
		if (
			formRes?.items &&
			Object.keys(formRes.items).length > 0 &&
			categoryId &&
			formRes?.items[categoryId]
		) {
			const items = formRes?.items[categoryId].filter(
				item =>
					item.type !== 'photos' && item.type !== 'node' && item.type !== 'modelNumber'
			);

			setFields(items);
		}
	}, [categoryId, formRes?.items]);

	useEffect(() => {
		filterItems();
	}, [filterItems, categoryId]);

	return (
		<Container>
			<GalleryControls />

			<CarouselContainer>
				<Carousel
					lazy
					lazyPreload={1}
					controlledIndex={galleryIndex}
					setControlledIndex={idx => setGalleryIndex(idx)}
					swipe={false}
					captureWindowKeypresses>
					{photos.map((photo, idx) => {
						return (
							<GalleryImg
								key={idx}
								photoIndex={idx}
								currentIndex={galleryIndex}
								photo={photo}
								nodeId={photo.node.id}
								fields={fields}
							/>
						);
					})}
				</Carousel>

				{showPdf ? <PDFOverlay src={formData?.downloadUrlVirtual} /> : null}
			</CarouselContainer>
		</Container>
	);
}

const Container = styled.div`
	display: flex;
	flex-direction: column;
	height: 100%;
`;

const CarouselContainer = styled.div`
	flex-grow: 1;
	position: relative;
`;

const PDFOverlay = styled.iframe`
	position: absolute;
	top: 0px;
	left: 0px;
	width: 100%;
	height: 100%;
	z-index: 100;
`;
