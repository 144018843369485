import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@mui/material';
import React, { FC } from 'react';

type ConfirmDialogProps = {
	open: boolean;
	handleClose: () => void;
	handleConfirm: () => void;
	title: string;
	label?: string;
};

const ConfirmDialog: FC<React.PropsWithChildren<ConfirmDialogProps>> = ({
	handleClose,
	handleConfirm,
	label,
	open,
	title,
}) => {
	return (
		<Dialog open={open} onClose={handleClose}>
			<DialogTitle>{title}</DialogTitle>
			<DialogContent>{label}</DialogContent>
			<DialogActions>
				<Button onClick={handleClose}>Cancel</Button>
				<Button onClick={handleConfirm}>Ok</Button>
			</DialogActions>
		</Dialog>
	);
};

export default ConfirmDialog;
