import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Table,
	TableCell,
	TableRow,
	styled,
} from '@mui/material';
import {
	ReportData,
	ReportQuestion,
	ReportQuestionForm,
	ReportQuestionFormDoc,
	ReportQuestionOption,
} from '../../../types/reports';
import { useNavigate } from 'react-router-dom';
import { useCallback, useEffect, useState } from 'react';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../../../firebase';
import Loading from '../ProjectUtilityFormV2/components/Loading/Loading';

export default function FormListDialog({
	open,
	option,
	question,
	report,
	setOpen,
}: {
	open: boolean;
	option?: ReportQuestionOption;
	report?: ReportData;
	question?: ReportQuestion;
	setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) {
	const navigate = useNavigate();
	const [forms, setForms] = useState<ReportQuestionForm[]>();
	const [loading, setLoading] = useState(false);

	const close = () => {
		setOpen(false);
		setForms(undefined);
	};
	const goToForm = (formId: string, projectId: string) =>
		navigate(`/projects/${projectId}/capture-form-v3/${formId}`);

	const loadForms = useCallback(async () => {
		if (!report || !question || !option) return;
		setLoading(true);

		const formDoc = (
			await getDoc(doc(db, `reportQuestions/${report.id}/forms/${question.id}`))
		).data() as ReportQuestionFormDoc;

		setLoading(false);

		if (!formDoc) return;

		if (option.value === 'Missing' && option.count === -1) {
			setForms(formDoc.missing);
			return;
		}

		if (option.value === 'Available' && option.count === -1) {
			setForms(formDoc.available);
			return;
		}

		if (option.value === '' && option.count === -1) {
			setForms(formDoc.options?.find(opt => opt.value === option.value)?.forms);
			return;
		}

		setForms(formDoc.options?.find(opt => opt.value === option.value)?.forms);
	}, [option, question, report]);

	useEffect(() => {
		loadForms();
	}, [loadForms, open]);

	return (
		<Dialog open={open} onClose={close}>
			<DialogTitle>
				{question?.question} - {option?.value}
			</DialogTitle>
			<DialogContent>
				{loading ? (
					<Loading size={50} />
				) : (
					<Table>
						<tbody>
							{forms &&
								forms.map((form, index) => (
									<HoverTableRow
										onClick={() => goToForm(form.formId, form.projectId)}
										key={Math.random()}>
										<TableCell key={form.formId} style={{ marginRight: 16 }}>
											{index + 1}. {form.projectAddress?.slice(0, 50)}
											{form.projectAddress?.length > 50 && '...'}
										</TableCell>
										<TableCell>{form.formName}</TableCell>
									</HoverTableRow>
								))}
						</tbody>
					</Table>
				)}
			</DialogContent>
			<DialogActions>
				<Button onClick={close}>Close</Button>
			</DialogActions>
		</Dialog>
	);
}

const HoverTableRow = styled(TableRow)`
	cursor: pointer;
	&:hover {
		background-color: #dddddd;
	}
`;
