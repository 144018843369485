import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(timezone);

export const getCaptureTimestampString = (date: Date) => {
	const ms = date.getTime();
	if (ms === 0) {
		return 'Scheduling Needed';
	} else {
		return dayjs(date).tz(dayjs.tz.guess()).format('MM/DD/YYYY');
	}
};

export const setDateToTwelvePM = (date: Date) => {
	const ms = date.getTime();
	if (ms === 0) {
		return date;
	} else {
		return dayjs(date).hour(12).minute(0).second(0).toDate();
	}
};
