import firebaseConfig from './firebaseConfig';
import { initializeApp } from 'firebase/app';
import { getAuth, setPersistence, browserLocalPersistence } from 'firebase/auth';
import { getStorage } from 'firebase/storage';
import { getFirestore } from 'firebase/firestore';
import {
	// connectFunctionsEmulator,
	getFunctions,
	httpsCallable,
} from 'firebase/functions';

const firebaseApp = initializeApp(firebaseConfig);
const functions = getFunctions(firebaseApp);
// if (process.env.NODE_ENV === 'development') {
// 	connectFunctionsEmulator(functions, '127.0.0.1', 5001);
// }

export const auth = getAuth();
export const db = getFirestore();
export const storage = getStorage(firebaseApp);

setPersistence(auth, browserLocalPersistence).catch(err =>
	console.log('Persistence error:', err)
);

// Authentication functions
export const createUser = httpsCallable(functions, 'authentication-createUser');

// User functions
export const getOwnUserDocument = httpsCallable(functions, 'users-getOwnUserDocument');
export const getUsers = httpsCallable(functions, 'users-getUsers');
export const updateUserPerms = httpsCallable(functions, 'users-updateUserPerms');
export const updateUserTags = httpsCallable(functions, 'users-updateUserTags');
export const updateUserTeamAdmin = httpsCallable(functions, 'users-updateUserTeamAdmin');

// Project functions
export const getProjects = httpsCallable(functions, 'projects-getProjects');
export const getProject = httpsCallable(functions, 'projects-getProject');
export const getSharedUsers = httpsCallable(functions, 'projects-getSharedUsers');
export const anonymousProjectSignIn = httpsCallable(
	functions,
	'projects-anonymousProjectSignIn'
);
export const getProjectPassword = httpsCallable(functions, 'projects-getProjectPassword');
export const updateProjectPassword = httpsCallable(
	functions,
	'projects-updateProjectPassword'
);
export const createProject = httpsCallable(functions, 'projects-createProject');
export const updateProjects = httpsCallable(functions, 'projects-updateProjects');
export const shareProjects = httpsCallable(functions, 'projects-shareProjects');
export const getPendingProjectShare = httpsCallable(
	functions,
	'projects-getPendingProjectShare'
);
export const pendingProjectShareReminder = httpsCallable(
	functions,
	'projects-pendingProjectShareReminder'
);
export const renameProjectFile = httpsCallable(functions, 'projects-renameProjectFile');
export const moveProjectFile = httpsCallable(functions, 'projects-moveProjectFile');
export const deleteProjectFile = httpsCallable(functions, 'projects-deleteProjectFile');
export const uploadProjectFilePostProcess = httpsCallable(
	functions,
	'projects-uploadProjectFilePostProcess'
);
export const getProjectFileHistory = httpsCallable(
	functions,
	'projects-getProjectFileHistory'
);
export const getProjectAddress = httpsCallable(functions, 'projects-getProjectAddress');
export const getProjectStatusCounts = httpsCallable(
	functions,
	'projects-getProjectStatusCounts'
);
export const queryProjects = httpsCallable(functions, 'projects-queryProjects');
export const getStatusTotal = httpsCallable(functions, 'projects-getStatusTotal');
export const getCachedData = httpsCallable(functions, 'projects-getCachedData');
export const getStatusGroupProjectCounts = httpsCallable(
	functions,
	'projects-getStatusGroupProjectCounts'
);
export const queryStatusGroupProjects = httpsCallable(
	functions,
	'projects-queryStatusGroupProjects'
);
export const sendProjectCaptureEmail = httpsCallable(
	functions,
	'projects-sendProjectCaptureEmail'
);

// Leads functions
export const getLeads = httpsCallable(functions, 'leads-getLeads');
export const deleteLead = httpsCallable(functions, 'leads-deleteLead');
export const updateLead = httpsCallable(functions, 'leads-updateLead');
export const updateLeads = httpsCallable(functions, 'leads-updateLeads');
export const uploadLeads = httpsCallable(functions, 'leads-uploadLeads');
export const addLeadComment = httpsCallable(functions, 'leads-addLeadComment');
export const addLeadComments = httpsCallable(functions, 'leads-addLeadComments');
export const getLeadComments = httpsCallable(functions, 'leads-getLeadComments');
export const getMultipleLeadComments = httpsCallable(
	functions,
	'leads-getMultipleLeadComments'
);
export const createLeadFlag = httpsCallable(functions, 'leads-createLeadFlag');
export const deleteLeadFlags = httpsCallable(functions, 'leads-deleteLeadFlags');

// Docusign functions
export const getJWT = httpsCallable(functions, 'docusign-getJWT');
export const getEnvelopesByProjectId = httpsCallable(
	functions,
	'docusign-getEnvelopesByProjectId'
);
export const getEnvelopeFromDocusign = httpsCallable(
	functions,
	'docusign-getEnvelopeFromDocusign'
);
export const createSenderView = httpsCallable(functions, 'docusign-createSenderView');
export const createRecipientView = httpsCallable(
	functions,
	'docusign-createRecipientView'
);
export const getEnvelopesRecipientView = httpsCallable(
	functions,
	'docusign-getEnvelopesRecipientView'
);
export const getCompletedDocusign = httpsCallable(
	functions,
	'docusign-getCompletedDocusign'
);

// Modelers functions
export const getModelers = httpsCallable(functions, 'modelers-getModelers');
export const getModelerProjects = httpsCallable(functions, 'modelers-getModelerProjects');

// Form functions
export const getUtilityForms = httpsCallable(functions, 'utilityForm-getUtilityForms');
export const getUtilityForm = httpsCallable(functions, 'utilityForm-getUtilityForm');
export const createUtilityForm = httpsCallable(
	functions,
	'utilityForm-createUtilityForm'
);
export const updateUtilityForm = httpsCallable(
	functions,
	'utilityForm-updateUtilityForm'
);
export const getUtilityFormById = httpsCallable(
	functions,
	'utilityForm-getUtilityFormById'
);
export const resetUtilityForm = httpsCallable(functions, 'utilityForm-resetUtilityForm');
export const updateFormName = httpsCallable(functions, 'utilityForm-updateFormName');

export const sendProjectEmail = httpsCallable(functions, 'smtpMailers-sendProjectEmail');
export const getCompletedForms = httpsCallable(
	functions,
	'utilityForm-getCompletedForms'
);

// Organization functions
export const getOrganization = httpsCallable(functions, 'organizations-getOrganization');
export const getOrganizations = httpsCallable(
	functions,
	'organizations-getOrganizations'
);
export const createOrganization = httpsCallable(
	functions,
	'organizations-createOrganization'
);
export const deleteOrganization = httpsCallable(
	functions,
	'organizations-deleteOrganization'
);
export const updateOrganization = httpsCallable(
	functions,
	'organizations-updateOrganization'
);
export const getOrgOwners = httpsCallable(functions, 'organizations-getOrgOwners');
export const addOrgOwner = httpsCallable(functions, 'organizations-addOrgOwner');

// Team functions
export const getTeam = httpsCallable(functions, 'organizations-getTeam');
export const getTeams = httpsCallable(functions, 'organizations-getTeams');
export const createTeam = httpsCallable(functions, 'organizations-createTeam');
export const deleteTeam = httpsCallable(functions, 'organizations-deleteTeam');
export const deleteTeams = httpsCallable(functions, 'organizations-deleteTeams');
export const addUserToTeam = httpsCallable(functions, 'organizations-addUserToTeam');
export const updateTeam = httpsCallable(functions, 'organizations-updateTeam');
export const removeUserFromTeam = httpsCallable(
	functions,
	'organizations-removeUserFromTeam'
);
export const removeOwnerFromOrganization = httpsCallable(
	functions,
	'organizations-removeOwnerFromOrganization'
);
export const addProjectToTeam = httpsCallable(
	functions,
	'organizations-addProjectToTeam'
);
export const addProjectsToTeam = httpsCallable(
	functions,
	'organizations-addProjectsToTeam'
);
export const removeProjectFromTeam = httpsCallable(
	functions,
	'organizations-removeProjectFromTeam'
);
export const getOrgAdminStatus = httpsCallable(
	functions,
	'organizations-getOrgAdminStatus'
);
export const getSharedTeamMembers = httpsCallable(
	functions,
	'organizations-getSharedTeamMembers'
);
export const addTemplatesToTeam = httpsCallable(
	functions,
	'organizations-addTemplatesToTeam'
);
export const removeTemplateFromTeam = httpsCallable(
	functions,
	'organizations-removeTemplateFromTeam'
);
export const removeUsersFromOrgOrTeam = httpsCallable(
	functions,
	'organizations-removeUsersFromOrgOrTeam'
);

// V2 Form Functions
export const getV2Form = httpsCallable(functions, 'utilityFormV2-getV2Form');
export const duplicateTemplate = httpsCallable(
	functions,
	'utilityFormV2-duplicateTemplate'
);
export const duplicateItemAndChildren = httpsCallable(
	functions,
	'utilityFormV2-duplicateItemAndChildren'
);
export const processModelNumberOCR = httpsCallable(
	functions,
	'utilityFormV2-processModelNumberOCR'
);
export const searchModelNumber = httpsCallable(
	functions,
	'utilityFormV2-searchModelNumber'
);
export const recalculateRequiredFields = httpsCallable(
	functions,
	'utilityFormV2-recalculateRequiredFields'
);

export const duplicateTemplateItemAndChildren = httpsCallable(
	functions,
	'utilityFormV2-duplicateTemplateItemAndChildren'
);
export const createForm = httpsCallable(functions, 'utilityFormV2-createForm');
export const createTemplate = httpsCallable(functions, 'utilityFormV2-createTemplate');
export const addTemplate = httpsCallable(functions, 'utilityFormV2-addTemplate');
export const copyAndPasteNode = httpsCallable(
	functions,
	'utilityFormV2-copyAndPasteNode'
);
export const movePhotosToAnotherItem = httpsCallable(
	functions,
	'utilityFormV2-movePhotosToAnotherItem'
);
export const createTemplateFromNode = httpsCallable(
	functions,
	'utilityFormV2-createTemplateFromNode'
);
// Portfolio functions
export const createPickerReport = httpsCallable(
	functions,
	'portfolios-createPickerReport'
);

// Quickbooks functions
export const getQBAuthUri = httpsCallable(functions, 'quickbooks-getAuthUri');
export const getQBInvoicePdf = httpsCallable(functions, 'quickbooks-getInvoicePdf');
export const refreshQBAccessToken = httpsCallable(
	functions,
	'quickbooks-refreshAccessToken'
);

// Proposal Generation functions
export const createProposal = httpsCallable(functions, 'proposalGen-createProposal');

// Procore integration functions
export const getProcoreOrganizations = httpsCallable(
	functions,
	'procore-getProcoreOrganizations'
);
export const getProcoreProjects = httpsCallable(functions, 'procore-getProcoreProjects');
export const uploadProcoreFiles = httpsCallable(functions, 'procore-uploadProcoreFiles');
