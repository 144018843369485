import { useRef } from 'react';
import { isEqual } from 'lodash';

export default function usePrevious(value: any[]) {
	const currentRef = useRef<any[]>(value);
	const previousRef = useRef<any[]>([]);
	if (!isEqual(currentRef.current, value)) {
		previousRef.current = currentRef.current;
		currentRef.current = value;
	}
	return previousRef.current;
}
