import { FC, useContext, useEffect, useState } from 'react';
import {
	ConditionalType,
	FormNode,
	FormTemplate,
} from '../../../../screen-components/ProjectUtilityFormV2/utils/types';
import { Dialog } from '../../../Dialog';
import {
	Button,
	FormControlLabel,
	IconButton,
	MenuItem,
	Select,
	SelectChangeEvent,
	Switch,
} from '@mui/material';
import styled from 'styled-components';
import theme from '../../../../../styles/theme';
import { Clear, Search } from '@mui/icons-material';
import PickTemplateDialog from '../../../../screen-components/FormsList/PickTemplateDialog';
import { db } from '../../../../../firebase';
import { deleteField, doc, getDoc, serverTimestamp, updateDoc } from 'firebase/firestore';
import { AuthContext } from '../../../../../context/AuthProvider';
import { SettingsContext } from '../../../../../context/SettingsProvider';
import { SnackContext } from '../../../../../context/SnackProvider';

type Props = {
	open: boolean;
	node: FormNode;
	onClose: () => void;
	isTemplate: boolean;
	templateId: string;
};

type ConditionalTemplatesList = {
	id: string;
	name: string;
};

const ConditionalTemplatesDialog: FC<Props> = ({
	open,
	node,
	onClose,
	isTemplate,
	templateId,
}) => {
	const { user } = useContext(AuthContext);
	const { maxNodeContainerNestingLevel } = useContext(SettingsContext);
	const { setSnackbarProps } = useContext(SnackContext);
	const [openPickTemplate, setOpenPickTemplate] = useState(false);
	const [conditionalType, setConditionalType] = useState<ConditionalType>(
		node.conditionalType || 'individual'
	);
	const [selectedIndex, setSelectedIndex] = useState<number | null>(null);
	const [conditionalTemplatesList, setConditionalTemplatesList] = useState<
		ConditionalTemplatesList[] | null
	>(Array.from({ length: node?.options?.length || 0 }, () => ({ id: '', name: '' })));
	const [shouldAddConditionalSection, setShouldAddConditionalSection] = useState(false);

	const handleClose = () => {
		setSelectedIndex(null);
		setConditionalTemplatesList(null);
		onClose();
	};

	const handleSaveClick = async () => {
		const list = conditionalTemplatesList || [];

		if (isTemplate) {
			const itemRef = doc(db, `utility_forms_v2_templates`, templateId);
			const item = (await getDoc(itemRef)).data() as FormTemplate;

			const newNodes = item?.nodes.map(n => {
				if (n.id === node.id) {
					return {
						...n,
						conditionalTemplates: valuesIsEmpty(list) ? deleteField() : list,
						conditionalType: valuesIsEmpty(list) ? deleteField() : conditionalType,
						shouldAddConditionalSection: shouldAddConditionalSection,
					};
				}
				return n;
			});

			await updateDoc(itemRef, {
				nodes: newNodes,
			});
		} else {
			const itemRef = doc(db, `utility_forms_v2_items`, node.id);
			await updateDoc(itemRef, {
				conditionalTemplates: valuesIsEmpty(list) ? deleteField() : list,
				conditionalType: valuesIsEmpty(list) ? deleteField() : conditionalType,
				shouldAddConditionalSection: shouldAddConditionalSection,
				updatedAt: serverTimestamp(),
				updatedBy: {
					id: user?.id,
					name: user?.fullName,
				},
			});
		}

		handleClose();
	};

	const valuesIsEmpty = (values: ConditionalTemplatesList[]) => {
		return values.every(value => value.id === '');
	};

	const handleSelectedIndex = (index: number) => {
		setSelectedIndex(index);
		setOpenPickTemplate(true);
	};

	const handlePickTemplate = (
		templatedId: string,
		templateName: string,
		maxLevelNode: number
	) => {
		const shouldAddConditionalSectionIncrement = shouldAddConditionalSection ? 1 : 0;
		if (
			maxLevelNode + (node.level || 0) + shouldAddConditionalSectionIncrement >
			maxNodeContainerNestingLevel
		) {
			setSnackbarProps({
				open: true,
				message: `This template has too many subsections for this section of the form. Please try inserting it ${maxLevelNode} level(s) above the current section.`,
				severity: 'error',
			});
			return;
		}

		setConditionalTemplatesList(prevState => {
			const newState = [...(prevState || [])];
			newState[selectedIndex!] = { id: templatedId, name: templateName };
			return newState;
		});
		setOpenPickTemplate(false);
	};

	const handleConditionalType = (e: SelectChangeEvent<'individual' | 'sequential'>) => {
		setConditionalType(e.target.value as ConditionalType);
	};

	const handleRemoveTemplate = (index: number) => {
		setConditionalTemplatesList(prevState => {
			const newState = [...(prevState || [])];
			newState[index] = { id: '', name: '' };
			return newState;
		});
	};

	useEffect(() => {
		if (node.conditionalTemplates) {
			const conditionalTemplates = node.conditionalTemplates;
			if (conditionalTemplates.length < (node.options?.length || 0)) {
				const newTemplates = Array.from({ length: node?.options?.length || 0 }, () => ({
					id: '',
					name: '',
				}));
				conditionalTemplates.forEach((template, index) => {
					newTemplates[index] = template;
				});
				setConditionalTemplatesList(newTemplates);
			} else {
				setConditionalTemplatesList(conditionalTemplates);
			}
		} else {
			setConditionalTemplatesList(
				Array.from({ length: node?.options?.length || 0 }, () => ({ id: '', name: '' }))
			);
		}
	}, [open, node.conditionalTemplates, node.options?.length]);

	useEffect(() => {
		if (node.conditionalType) {
			setConditionalType(node.conditionalType);
		}
	}, [open, node.conditionalType]);

	useEffect(() => {
		if (node.shouldAddConditionalSection !== undefined) {
			setShouldAddConditionalSection(node.shouldAddConditionalSection);
		}
	}, [open, node.shouldAddConditionalSection]);

	if (conditionalTemplatesList === null) return null;

	return (
		<>
			<Dialog
				open={open}
				onClose={onClose}
				title="Conditional Templates"
				fullWidth={true}
				wide={true}
				bottomActions={
					<>
						<Button onClick={handleClose}>Cancel</Button>
						<Button onClick={handleSaveClick}>Save</Button>
					</>
				}>
				<Container>
					<ContainerHeader>
						<SelectContainer>
							<label htmlFor="conditionalType">Conditional type</label>
							<Select
								id="select-type"
								value={conditionalType}
								onChange={handleConditionalType}
								sx={{
									padding: 0,
									color: '#fff',
									fontWeight: 400,
									minWidth: '200px',
									'.MuiSelect-select': {
										padding: '5px 16px 2px 10px',
										border: `1px solid ${theme.palette.primary.main}`,
									},
									'.MuiSvgIcon-root ': {
										fill: theme.palette.primary.main,
									},
								}}>
								<MenuItem value={'individual'}>Individual</MenuItem>
								<MenuItem value={'sequential'}>Sequential</MenuItem>
							</Select>
						</SelectContainer>
						<FormControlLabelContainer>
							<FormControlLabelStyled
								disabled={!valuesIsEmpty(conditionalTemplatesList)}
								onChange={() =>
									setShouldAddConditionalSection(!shouldAddConditionalSection)
								}
								control={<Switch checked={shouldAddConditionalSection} color="primary" />}
								label="Place templates inside a new section"
								labelPlacement="start"
							/>
							{!valuesIsEmpty(conditionalTemplatesList) && (
								<span>To change this, remove all templates from the options.</span>
							)}
						</FormControlLabelContainer>
					</ContainerHeader>
					<Content>
						{node.options &&
							node.options.map((option, index) => {
								return (
									<ContentGrid
										key={index}
										index={index + 1}
										total={node.options?.length || 0}>
										<ContentGridOption>
											<label htmlFor="option">Option:</label>
											<span id="option">{option}</span>
										</ContentGridOption>
										<StyledButton onClick={() => handleSelectedIndex(index)}>
											<Search />
										</StyledButton>
										<TemplateContainer>
											{index < conditionalTemplatesList.length && (
												<>
													<span>{conditionalTemplatesList[index].name}</span>
													{conditionalTemplatesList[index].id && (
														<StyledIconButton
															color="error"
															onClick={() => handleRemoveTemplate(index)}>
															<Clear />
														</StyledIconButton>
													)}
												</>
											)}
										</TemplateContainer>
									</ContentGrid>
								);
							})}
					</Content>
				</Container>
			</Dialog>
			<PickTemplateDialog
				open={openPickTemplate}
				handleClose={() => setOpenPickTemplate(false)}
				handlePickTemplate={handlePickTemplate}
				isPartial={true}
			/>
		</>
	);
};

export default ConditionalTemplatesDialog;

const Container = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: flex-start;
	width: 100%;
	overflow: hidden;
`;

const SelectContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 5px;
`;

const Content = styled.div`
	display: flex;
	flex-direction: column;
	padding: 16px;
	border: 1px solid ${theme.palette.primary.main};
	border-radius: 6px;
	width: 100%;
	margin-top: 20px;
	overflow-y: auto;
	overflow-x: hidden;
`;

const ContentGrid = styled.div<{ index: number; total: number }>`
	display: flex;
	gap: 5px;
	align-items: center;
	padding: 5px 0;
	border-bottom: ${({ index, total }) =>
		index !== total ? `1px solid ${theme.palette.primary.main}` : ''};
`;

const ContentGridOption = styled.div`
	display: flex;
	flex-direction: row;
	gap: 5px;
	align-items: center;

	label {
		font-size: 12px;
	}

	span {
		font-weight: 700;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		width: 100px;
	}
`;

const TemplateContainer = styled.div`
	display: flex;
	flex-direction: row;
	gap: 5px;
	align-items: center;
	justify-content: space-between;
	width: 100%;

	span {
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		width: 300px;
	}
`;

const StyledButton = styled(Button)`
	padding: 0;
`;

const StyledIconButton = styled(IconButton)`
	padding: 0;
`;

const ContainerHeader = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	width: 100%;
	align-items: flex-end;
`;

const FormControlLabelStyled = styled(FormControlLabel)`
	margin: 0;
`;

const FormControlLabelContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
`;
