import { useEffect, useState } from 'react';
import { Autocomplete, TextField } from '@mui/material';
import { FieldLabel } from '../../../../screens/ProjectInfo';
import styled from 'styled-components';
import { getProcoreOrganizations } from '../../../../firebase';
import type { ProcoreOrganizations } from '../../../../types/procore';

type Props = {
	isActive: boolean;
	value?: string;
	handleChange: (value: string) => void;
};

const SelectProcoreOrganizations = ({ isActive, value, handleChange }: Props) => {
	const [organizations, setOrganizations] = useState<ProcoreOrganizations>([]);

	const getProcoreOrganizationsList = async () => {
		const response = await getProcoreOrganizations();
		const data: ProcoreOrganizations = response.data as ProcoreOrganizations;

		data.sort((a, b) => {
			if (a.name < b.name) {
				return -1;
			}
			if (a.name > b.name) {
				return 1;
			}
			return 0;
		});

		setOrganizations(data);
	};

	useEffect(() => {
		getProcoreOrganizationsList();
	}, []);

	return (
		<div className="infoRow">
			<FieldLabel label="Procore ID" />
			{isActive ? (
				<AutoCompleteStyled
					id="size-small-outlined"
					value={value || ''}
					options={organizations.map(organization => organization.name)}
					onChange={(e, value) => {
						const selected = organizations.find(
							organization => organization.name === value
						);

						if (selected) {
							handleChange(selected.id.toString());
						}
					}}
					renderInput={params => (
						<TextField
							{...params}
							variant="outlined"
							size="small"
							focused
							sx={{
								input: { color: 'white' },
							}}
						/>
					)}
				/>
			) : (
				<span className="fieldInfo">{value}</span>
			)}
		</div>
	);
};

export default SelectProcoreOrganizations;

const AutoCompleteStyled = styled(Autocomplete)`
	width: 100%;
	height: 43px;
`;
