import { useContext, useEffect } from 'react';

import { ProjectData, ProjectStatus, UserWithData } from '../../../types';

import { SnackContext } from '../../../context/SnackProvider';
import { AuthContext } from '../../../context/AuthProvider';

import { doc, getDoc } from 'firebase/firestore';
import { db } from '../../../firebase';
import Layout from '../../../components/reusable-components/Layout';
import Searchbar from '../../../components/reusable-components/Searchbar';
import { contractorStatuses, projectStatuses } from '../../../utils';
import CreateProjectButton from '../../../components/reusable-components/CreateProjectButton';
import MapButton from './MapButton';
// import ExportProjectsButton from '../screen-components/ProjectsList/ExportProjectsButton';
import MultipleShareButton from './MultipleShareButton';
import ProjectStatusTables from '../../../components/reusable-components/ProjectStatusTables';
import { ProjectContext } from '../../../context/ProjectProvider';
import { LoadingContext } from '../../../context/LoadingProvider';
import AccountManagerFilterButton from './AccountManagerFilterButton';

export default function ProjectsListStatusTables() {
	const { user, setUser } = useContext(AuthContext);
	const { setSnackbarProps } = useContext(SnackContext);
	const { loadingProjects, loadingProjectStatuses, setLoadingProjectStatuses } =
		useContext(LoadingContext);
	const {
		projectsListQuery,
		setProjectsListQuery,
		accountManagerFilter,
		currentProjectStatus,
		setCurrentProjectStatus,
	} = useContext(ProjectContext);

	// Display snackbar if user has newProjectIds.
	useEffect(() => {
		if (user?.newProjectIds?.length) {
			const numNewProjects = user.newProjectIds.length;

			// Fetch project data for first new project.
			const projDoc = doc(db, `projects/${user.newProjectIds[0]}`);
			getDoc(projDoc).then(res => {
				const projData = res.data() as ProjectData;
				let message = projData?.address || projData?.name || 'Unnamed Project';
				const otherProjects = numNewProjects - 1;
				switch (otherProjects) {
					case 0:
						message += ' has been shared with you!';
						break;
					case 1:
						message += ' and 1 other project has been shared with you!';
						break;
					default:
						message += ` and ${otherProjects} other projects have been shared with you!`;
						break;
				}
				setSnackbarProps({
					open: true,
					severity: 'success',
					message: message,
				});
			});

			// Remove newProjectIds from user state.
			setUser(prev => {
				if (prev) {
					const updatedUser: UserWithData = {
						...prev,
						newProjectIds: [],
					};
					return updatedUser;
				} else return prev;
			});
		}
	}, [setSnackbarProps, setUser, user?.newProjectIds]);

	const statuses: ProjectStatus[] = user?.isAdmin
		? projectStatuses
		: [
				'To Be Scheduled',
				'On-Site Capture Complete',
				'Registration',
				...contractorStatuses,
				'Pending Payment / Delivered',
				'Delivered',
				'Template',
				'Archive',
		  ];
	const layoutTitle = 'Projects';
	return (
		<Layout
			title={layoutTitle}
			LeftNavComponent={
				<div style={{ paddingLeft: 10, display: 'flex' }}>
					<Searchbar
						query={projectsListQuery}
						setQuery={setProjectsListQuery}
						showSpinner={loadingProjects || loadingProjectStatuses}
						onDebounceStart={() => {
							setLoadingProjectStatuses(true);
						}}
						placeholder="Search projects..."
					/>
					<CreateProjectButton />
				</div>
			}
			RightNavComponent={
				user?.isAdmin ? (
					<>
						<AccountManagerFilterButton />
						<MapButton />
						{/* <ExportProjectsButton /> */}
						<MultipleShareButton />
					</>
				) : null
			}>
			<ProjectStatusTables
				statuses={statuses}
				query={projectsListQuery}
				accountManagerFilter={accountManagerFilter}
				globalProjStatus={currentProjectStatus}
				setGlobalProjStatus={setCurrentProjectStatus}
			/>
		</Layout>
	);
}
