import { collection, getDocs, orderBy, query } from 'firebase/firestore';
import { db } from '../../../../firebase';
import { useEffect, useState } from 'react';
import { Equipment } from './types';
import styled from 'styled-components';
import SkeletonTable from '../../../reusable-components/SkeletonTable';
import TableSearchbar from '../../../reusable-components/DataTable/TableSearchbar';
import AddEquipmentDialog from './AddEquipmentDialog';
import { Button } from '@mui/material';
import EditEquipmentDialog from './EditEquipmentDialog';
import theme from '../../../../styles/theme';

export default function EquipmentTab() {
	const [equipment, setEquipment] = useState<Equipment[]>([]);
	const [loadingEquipment, setLoadingEquipment] = useState(false);

	const [searchQuery, setSearchQuery] = useState('');
	const [filteredEquipment, setFilteredEquipment] = useState<Equipment[]>([]);
	const [selectedEquipment, setSelectedEquipment] = useState<Equipment>();

	const [showAddDialog, setShowAddDialog] = useState(false);

	// Loads equipment.
	useEffect(() => {
		setLoadingEquipment(true);
		const equipmentRef = collection(db, 'equipment');
		const q = query(equipmentRef, orderBy('createdAt'));
		getDocs(q)
			.then(res => {
				const equipmentData = res.docs.map(resDoc => {
					const date = new Date(resDoc.data().createdAt._seconds * 1000);
					return {
						...resDoc.data(),
						createdAt: date,
					} as Equipment;
				});
				setEquipment(equipmentData);
			})
			.finally(() => {
				setLoadingEquipment(false);
			});
	}, []);

	// Filters equipment.
	useEffect(() => {
		const lowercase = searchQuery.toLowerCase();
		if (!searchQuery) {
			setFilteredEquipment(equipment);
		} else {
			setFilteredEquipment(
				equipment.filter(
					equip =>
						equip.name.toLowerCase().includes(lowercase) ||
						(equip.serialNumber || '').toLowerCase().includes(lowercase)
				)
			);
		}
	}, [equipment, searchQuery]);

	return loadingEquipment ? (
		<SkeletonContainer>
			<SkeletonTable rows={10} />
		</SkeletonContainer>
	) : (
		<Container>
			<ActionBar>
				<TableSearchbar
					query={searchQuery}
					handleInputChange={e => setSearchQuery(e.target.value)}
					clearQuery={() => setSearchQuery('')}
					name="Equipment"
					showButton
					onAdd={() => setShowAddDialog(true)}
				/>
			</ActionBar>

			{filteredEquipment.length ? (
				<EquipmentContainer>
					{filteredEquipment.map(equip => (
						<EquipmentItem onClick={() => setSelectedEquipment(equip)} key={equip.id}>
							<span>{equip.name}</span>
							<span>{`Serial Number: ${equip.serialNumber}`}</span>
							<p>{`Description: ${equip.description}`}</p>
							<span>{`# of files: ${equip.files.length}`}</span>
						</EquipmentItem>
					))}
				</EquipmentContainer>
			) : (
				<div>No equipment to display...</div>
			)}

			<AddEquipmentDialog
				open={showAddDialog}
				setOpen={setShowAddDialog}
				setEquipment={setEquipment}
			/>
			<EditEquipmentDialog
				selectedEquipment={selectedEquipment}
				setSelectedEquipment={setSelectedEquipment}
				setEquipment={setEquipment}
			/>
		</Container>
	);
}

const SkeletonContainer = styled.div`
	max-width: 800px;
	width: 100%;
	margin: auto;
	margin-top: 80px;
`;

const Container = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	color: white;
`;

const ActionBar = styled.div`
	width: 100%;
	height: 50px;
	padding: 20px;
	display: flex;
	flex-direction: row;
	gap: 12px;
	margin: 0 auto;
	justify-content: center;
	margin: 20px 0 10px 0;

	@media (max-width: 768px) {
		-webkit-overflow-scrolling: touch; // Improves scrolling on iOS devices
		width: 90%;
		padding: 20px;
	}
`;

const EquipmentContainer = styled.div`
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	gap: 5px;
`;

const EquipmentItem = styled(Button)`
	display: flex;
	flex-direction: column;
	gap: 0px;
	align-items: flex-start;

	text-transform: none;
	width: 300px;

	color: ${theme.palette.primary.main};
	background-color: ${theme.palette.secondary.main};
	border: 1px solid white;
	&:hover {
		background-color: gray;
	}

	> p {
		margin: 0;
	}
`;
