import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	TextField,
} from '@mui/material';
import { ProjectData } from '../../../types';
import { useEffect, useState, useContext } from 'react';
import MultiAutoCompleteInput from '../../reusable-components/MultiAutoCompleteInput';
import { ProjectContext } from '../../../context/ProjectProvider';
import { SnackContext } from '../../../context/SnackProvider';
import { db } from '../../../firebase';
import LoadingScreen from '../../reusable-components/LoadingScreen';
import { isEqual } from 'lodash';
import { deleteField, doc, updateDoc } from 'firebase/firestore';
import { dateToDateString } from '../../../utils';
import ModelerMultiDateTime from './components/ModelerMultiDateTime';

export default function EditProjectForm({
	project,
	setSelectedProject,
	setModelerProjects,
}: {
	project: ProjectData | null;
	setSelectedProject: React.Dispatch<React.SetStateAction<ProjectData | null>>;
	setModelerProjects: React.Dispatch<React.SetStateAction<ProjectData[] | null>>;
}) {
	const { cachedModelers, loadingAutofillData } = useContext(ProjectContext);
	const { setSnackbarProps } = useContext(SnackContext);
	const [submitting, setSubmitting] = useState(false);
	const [projectName, setProjectName] = useState('Unnamed Project');
	const [editedProject, setEditedProject] = useState(project);

	useEffect(() => {
		setEditedProject(project);
		if (project) {
			setProjectName(project.address || project.name || 'Unnamed Project');
		}
	}, [project]);

	const handleClose = () => {
		if (!submitting) {
			setSelectedProject(null);
		} else {
			setSnackbarProps({
				open: true,
				severity: 'info',
				message: 'Cannot close form while updating project!',
			});
		}
	};
	const handleSubmit = async () => {
		setSubmitting(true);

		// Submit changed project fields to updateProjects Firebase Function.
		if (editedProject && project) {
			const submitObj: Partial<ProjectData> = {};

			// Create the submitObj by finding differences in the old project object vs the projectForm.
			for (const key in editedProject) {
				const field = key as keyof ProjectData;
				if (!isEqual(editedProject[field], project[field])) {
					// @ts-ignore
					submitObj[field] = editedProject[field];

					// Delete field if the updated value is undefined (for the timestamp fields).
					if (editedProject[field] === undefined) {
						// @ts-ignore
						submitObj[field] = deleteField();
					}
				}
			}

			// Update firestore project document.
			const docRef = doc(db, `projects/${project.id}`);
			await updateDoc(docRef, submitObj);

			// Update project state.
			setModelerProjects(prev => {
				if (prev) {
					return prev.map(proj => {
						if (proj.id === editedProject.id) return editedProject;
						else return proj;
					});
				} else return prev;
			});

			setSnackbarProps({
				open: true,
				severity: 'success',
				message: `Successfully updated ${projectName}!`,
			});
		}

		setSelectedProject(null);
		setSubmitting(false);
	};
	const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
		setEditedProject(prev => {
			if (prev) {
				return {
					...prev,
					[e.target.name]: e.target.value,
				};
			} else return prev;
		});
	};

	const handleTimestampChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const dateStr = e.target.value;
		const dateObj = new Date(dateStr);
		let updatedDate: Date | undefined = undefined;

		if (dateStr && dateObj.toString() !== 'Invalid Date') {
			const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
			updatedDate = dateObj;

			const utcDate = new Date(updatedDate.toLocaleString('en-US', { timeZone: 'UTC' }));
			const tzDate = new Date(updatedDate.toLocaleString('en-US', { timeZone: tz }));
			const offset = utcDate.getTime() - tzDate.getTime();

			updatedDate.setTime(updatedDate.getTime() + offset);
		}

		setEditedProject(prev => {
			if (prev) {
				return {
					...prev,
					[e.target.name]: updatedDate,
				};
			} else return prev;
		});
	};

	return (
		<Dialog open={!!project} onClose={handleClose} maxWidth="xs" fullWidth>
			<DialogTitle>{`Edit ${projectName}`}</DialogTitle>
			{!submitting ? (
				<DialogContent>
					<TextField
						name="subTotal"
						label="Sub-Total"
						value={editedProject?.subTotal || ''}
						onChange={handleChange}
						variant="standard"
						fullWidth
					/>

					<ModelerMultiDateTime
						field="captureTimestamps"
						editProject={editedProject}
						setEditProject={setEditedProject}
					/>

					<TextField
						name="modelingStartTimestamp"
						label="Modeling Start Date"
						value={
							editedProject?.modelingStartTimestamp
								? dateToDateString(editedProject.modelingStartTimestamp)
								: ''
						}
						onChange={handleTimestampChange}
						variant="standard"
						fullWidth
						type="date"
						InputLabelProps={{ shrink: true }}
					/>
					<TextField
						name="modelingDueTimestamp"
						label="Modeling Due Date"
						value={
							editedProject?.modelingDueTimestamp
								? dateToDateString(editedProject.modelingDueTimestamp)
								: ''
						}
						onChange={handleTimestampChange}
						variant="standard"
						fullWidth
						type="date"
						InputLabelProps={{ shrink: true }}
					/>
					<TextField
						name="deliveryTimestamp"
						label="Delivery Date"
						value={
							editedProject?.deliveryTimestamp
								? dateToDateString(editedProject.deliveryTimestamp)
								: ''
						}
						onChange={handleTimestampChange}
						variant="standard"
						fullWidth
						type="date"
						InputLabelProps={{ shrink: true }}
					/>
					<MultiAutoCompleteInput
						label="Modeler(s)"
						value={editedProject?.modelers || []}
						onChange={(e, newValue) => {
							setEditedProject(prev => {
								if (prev) {
									return {
										...prev,
										modelers: Array.from(newValue),
									};
								} else return prev;
							});
						}}
						onBlur={inputValue => {
							setEditedProject(prev => {
								if (prev) {
									return {
										...prev,
										modelers: [...prev.modelers, inputValue],
									};
								} else return prev;
							});
						}}
						options={cachedModelers}
						autoFocus={false}
						variant="standard"
						loading={loadingAutofillData}
						loadingText="Loading modeler options..."
					/>
				</DialogContent>
			) : (
				<DialogContent>
					<LoadingScreen message="Updating project..." textColor="black" />
				</DialogContent>
			)}

			<DialogActions>
				<Button onClick={handleClose} color="primary">
					Cancel
				</Button>

				{!submitting && !isEqual(project, editedProject) ? (
					<Button onClick={handleSubmit} color="primary">
						Submit Changes
					</Button>
				) : null}
			</DialogActions>
		</Dialog>
	);
}
