import { useContext, useState } from 'react';
import { createForm, db } from '../../firebase';
import { collection, doc, serverTimestamp, setDoc } from 'firebase/firestore';
import { UtilityFormV2 } from '../../components/screen-components/ProjectUtilityFormV2/utils/types';
import { AuthContext } from '../../context/AuthProvider';
import { useNavigate } from 'react-router-dom';

const useFormName = () => {
	const [nameDialogOpen, setNameDialogOpen] = useState(false);
	const [isAdding, setIsAdding] = useState(false);
	const { user, firebaseAuthData } = useContext(AuthContext);
	const navigate = useNavigate();

	const openAddNameDialog = () => setNameDialogOpen(true);

	const closeNameDialog = () => setNameDialogOpen(false);

	const handleAddName = async (
		formName: string,
		formType: 'scratch' | 'template' | undefined,
		projectId: string,
		templateId?: string,
		withReload?: boolean
	) => {
		closeNameDialog();
		if (!formType) return;

		setIsAdding(true);

		if (formType === 'scratch') {
			const col = collection(db, 'utility_forms_v2');
			const formId = doc(col).id;

			const newForm: UtilityFormV2 = {
				id: formId,
				formName: formName,
				projectId: projectId,
				status: 'draft',
				v2: true,
				createdBy: {
					id: firebaseAuthData?.uid || '',
					name: user?.fullName || '',
				},
			};

			const ref = doc(collection(db, 'utility_forms_v2'), formId);

			const result = setDoc(ref, {
				...newForm,
				createdDate: serverTimestamp(),
				lastUpdated: serverTimestamp(),
			});

			if (!result) {
				setIsAdding(false);
				return;
			}

			navigate(`/projects/${projectId}/capture-form-v3/${formId}`);
		} else if (formType === 'template') {
			const result = await createForm({
				name: formName,
				projectId: projectId,
				templateId: templateId,
			});

			if (!result) {
				setIsAdding(false);
				return;
			}

			const data = result.data as { formId: string };
			navigate(`/projects/${projectId}/capture-form-v3/${data.formId}`);
			withReload && window.location.reload();
		}
	};

	return {
		openAddNameDialog,
		closeNameDialog,
		handleAddName,
		isAdding,
		nameDialogOpen,
	};
};

export default useFormName;
