import { Typography, TypographyVariant } from '@mui/material';

export default function AppText({
	variant = 'h5',
	text,
	error,
}: {
	variant?: TypographyVariant;
	text: string;
	error?: boolean;
}) {
	return (
		<Typography color={error ? 'red' : 'primary'} variant={variant}>
			{text}
		</Typography>
	);
}
