import { ProjectStatus } from '../../../types';
import { ClientBlock, ClientFrame } from './styles';
import { useEffect, useState } from 'react';
import { statusLabels } from './utils/utils';
import { Tooltip } from '@mui/material';
import { CloudDone, PendingActions, ShareLocation } from '@mui/icons-material';

import {
	statusGroups,
	StatusGroupName,
} from '../../reusable-components/GroupedStatusTable/utils';

type ProjectTrackerProps = {
	projectStatus?: ProjectStatus;
	nav?: boolean;
	style?: React.CSSProperties;
};

const ClientTracker: React.FC<React.PropsWithChildren<ProjectTrackerProps>> = ({
	projectStatus,
	style,
}) => {
	const [activeStep, setActiveStep] = useState(0);

	useEffect(() => {
		let step = 0;

		if (projectStatus) {
			const statusGroup = Object.keys(statusGroups).find(groupName =>
				statusGroups[groupName as StatusGroupName].includes(projectStatus)
			) as StatusGroupName;

			switch (statusGroup) {
				case 'Pending':
					step = 1;
					break;
				case 'In-Progress':
					step = 2;
					break;
				case 'Delivered':
					step = 3;
					break;
				default:
					step = 0;
					break;
			}
		}

		setActiveStep(step);
	}, [projectStatus]);
	const tooltipTitle = projectStatus
		? statusLabels[projectStatus] || 'Unknown status'
		: 'Status unavailable';

	const getBlockColor = (blockIndex: number) => {
		if (activeStep === 1 && blockIndex === 1) {
			return '#F0A3A3';
		} else if (activeStep === 2 && blockIndex <= 2) {
			return '#ffb310';
		} else if (activeStep === 3) {
			return '#228b22';
		}
		return 'black';
	};

	return (
		<Tooltip title={tooltipTitle} placement="top" arrow>
			<div>
				<ClientFrame step={activeStep} style={style}>
					<ClientBlock step={activeStep} style={{ backgroundColor: getBlockColor(1) }}>
						<PendingActions
							sx={{
								color: `${activeStep >= 1 ? 'black' : 'white'}`,
							}}
						/>
					</ClientBlock>
					<ClientBlock step={activeStep} style={{ backgroundColor: getBlockColor(2) }}>
						<ShareLocation
							sx={{
								color: `${activeStep >= 2 ? 'black' : 'white'}`,
							}}
						/>
					</ClientBlock>
					<ClientBlock step={activeStep} style={{ backgroundColor: getBlockColor(3) }}>
						<CloudDone
							sx={{
								color: `${activeStep >= 3 ? 'black' : 'white'}`,
							}}
						/>
					</ClientBlock>
				</ClientFrame>
			</div>
		</Tooltip>
	);
};

export default ClientTracker;
