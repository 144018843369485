import { FC, memo, useContext, useEffect, useMemo, useState } from 'react';
import { InputFieldContainer, ManageUploadsButton } from './FormFillV2.styles';
import DraggableGallery from './PhotosField/DraggableGallery';
import styled from 'styled-components';
import { FormV2Context } from '../../screen-components/ProjectUtilityFormV2/context/FormV2Context.types';
import { SnackContext } from '../../../context/SnackProvider';
import {
	Flag,
	FormNode,
	LocalPhoto,
	PhotoV2,
	OCRModelNumberType,
} from '../../screen-components/ProjectUtilityFormV2/utils/types';
import { FilePresent } from '@mui/icons-material';
import PhotoGalleryDialog from './PhotoGalleryDialog/PhotoGalleryDialog';
import PhotoUploadManager from './PhotoUploadManager/PhotoUploadManager';
import { Badge, Tooltip, Typography } from '@mui/material';
import { TemplateContext } from '../../../context/TemplateProvider';
import { FlattenedItem } from '../../screen-components/Form/components/FormWrapper/types';
import { getDownloadURL, ref, uploadBytesResumable } from 'firebase/storage';
import { db, storage } from '../../../firebase';
import { doc, updateDoc } from 'firebase/firestore';
import theme from '../../../styles/theme';

const imageJs = require('image-js');

type PhotosFieldProps = {
	itemNode: FormNode;
	orderedItems: FlattenedItem[];
};

const PhotosField: FC<PhotosFieldProps> = memo(({ itemNode, orderedItems }) => {
	const {
		addNewNode,
		addPhotosToDownloadQueue,
		deleteNodePhoto,
		photoUploadQueue,
		updatePhotos,
		isViewMode,
	} = useContext(FormV2Context);
	const { isTemplate } = useContext(TemplateContext);
	const { setSnackbarProps } = useContext(SnackContext);

	const [openUploadManager, setOpenUploadManager] = useState(false);
	const [isGalleryOpen, setIsGalleryOpen] = useState(false);
	const [initialImageIndex, setInitialImageIndex] = useState(0);
	const [isUploading, setIsUploading] = useState(false);
	const [rotateImagePercentage, setRotateImagePercentage] = useState(0);

	const uploadedPhotos = useMemo<PhotoV2[]>(
		() => (itemNode.value && Array.isArray(itemNode.value) ? itemNode.value : []),
		[itemNode.value]
	);

	const queuedPhotos = useMemo<PhotoV2[]>(
		() =>
			photoUploadQueue?.filter(p => p?.nodeId === itemNode.id).map(p => p.photo) || [],
		[itemNode.id, photoUploadQueue]
	);

	const combinedPhotos = useMemo(
		() => [...uploadedPhotos, ...queuedPhotos],
		[uploadedPhotos, queuedPhotos]
	);

	const draggingDisabled = useMemo(
		() => combinedPhotos.some(photo => !photo.url),
		[combinedPhotos]
	);

	const openGallery = (index: number) => {
		setInitialImageIndex(index);
		setIsGalleryOpen(true);
	};

	const closeGallery = () => setIsGalleryOpen(false);

	const deleteImage = async (index: number) => {
		const photoToDelete = uploadedPhotos[index];
		if (!photoToDelete) {
			console.error("Can't find photo.");
			return;
		}
		try {
			await deleteNodePhoto(itemNode.id, photoToDelete);
			setSnackbarProps({
				open: true,
				message: 'Photo deleted successfully.',
				severity: 'success',
			});
		} catch (error) {
			console.error('Failed to delete photo:', error);
			setSnackbarProps({
				open: true,
				message: 'Failed to delete photo. Please try again later.',
				severity: 'error',
			});
		}
	};

	const setFlag = (index: number, flag: Flag) => {
		const photos = uploadedPhotos.map((photo, i) =>
			i === index ? { ...photo, flag } : photo
		);
		try {
			updatePhotos(itemNode.id, photos);
		} catch (error) {
			console.error('Failed to update flag:', error);
			setSnackbarProps({
				open: true,
				message: 'Failed to update flag. Please try again later.',
				severity: 'error',
			});
		}
	};

	const handlePhotoUpload = async (newPhotos: File[]) => {
		setIsUploading(true);
		try {
			const localPhotos: LocalPhoto[] = newPhotos.map(file => ({
				originalName: file.name,
				uri: URL.createObjectURL(file),
			}));
			await addPhotosToDownloadQueue(itemNode.id, localPhotos);
		} catch (error) {
			console.log(error);
		} finally {
			setIsUploading(false);
		}
	};

	const handleCreateOCR = async (index: number) => {
		if (!uploadedPhotos[index].url) {
			console.error("Can't find photo.");
			return;
		}

		const value: OCRModelNumberType = {
			serialNumber: '',
			modelNumber: '',
			manufactureDate: '',
			manufacturer: '',
			photo: uploadedPhotos[index],
			photoNodeId: itemNode.id,
		};
		await addNewNode('OCR', 'modelNumber', [], itemNode.parentId, value);
	};

	const handleRotateImage = async (index: number, degrees: number) => {
		try {
			const image = await imageJs.Image.load(uploadedPhotos[index].url);
			const rotateImage = await image.rotate(degrees);
			const imageBlob = await rotateImage.toBlob('image/png', 1);

			const filePath = `utility_forms_v2/${itemNode.formId}/photos/${uploadedPhotos[index].name}`;

			const storageRef = ref(storage, filePath);
			const uploadTask = uploadBytesResumable(storageRef, imageBlob);

			uploadTask.on(
				'state_changed',
				snapshot => {
					const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;

					setRotateImagePercentage(Number(progress.toFixed(0)));
				},
				error => {
					console.error('Failed to rotate image:', error);
					setSnackbarProps({
						open: true,
						message: 'Failed to rotate image. Please try again later.',
						severity: 'error',
					});
				},
				async () => {
					await getDownloadURL(uploadTask.snapshot.ref).then(async downloadURL => {
						const updateObject = {
							photos: uploadedPhotos.map((photo, i) =>
								i === index ? { ...photo, url: downloadURL } : photo
							),
						};

						const itemRef = doc(db, 'utility_forms_v2_items', itemNode.id);
						await updateDoc(itemRef, {
							value: updateObject.photos,
						});
					});
				}
			);
		} catch (error) {
			console.error('Failed to rotate image:', error);
			setSnackbarProps({
				open: true,
				message: 'Failed to rotate image. Please try again later.',
				severity: 'error',
			});
		}
	};

	const generateListOfPendingUsersName = () => {
		console.log(itemNode);
		if (itemNode.pendingUploadsUsers && itemNode.pendingUploadsUsers.length > 0) {
			const pendingUsers = itemNode.pendingUploadsUsers.map(user => {
				return user.name;
			});
			return pendingUsers.join(', ');
		}
		return '';
	};

	return (
		<Container>
			<InputFieldContainer>
				{!isViewMode && (
					<ManageUploadsContainer>
						<ManageUploadsButton
							onClick={() => !isTemplate && setOpenUploadManager(true)}>
							<FilePresent />
							<Typography fontWeight={700}>Manage uploads</Typography>
						</ManageUploadsButton>
						{itemNode.pendingUploads && (
							<PendingUploadContainer>
								<Tooltip title={generateListOfPendingUsersName()} placement="top">
									<span>
										Pending uploads: <b>{itemNode.pendingUploads}</b>
									</span>
								</Tooltip>
							</PendingUploadContainer>
						)}
					</ManageUploadsContainer>
				)}

				<PhotoUploadManager
					open={openUploadManager}
					onClose={() => setOpenUploadManager(false)}
					itemNode={itemNode}
					uploadedPhotos={uploadedPhotos}
					orderedItems={orderedItems}
				/>

				<DraggableGallery
					key={itemNode.id}
					photos={combinedPhotos}
					nodeId={itemNode.id}
					openGallery={openGallery}
					disabled={draggingDisabled}
					onUpload={handlePhotoUpload}
					isUploading={isUploading}
					setOpenUploadManager={setOpenUploadManager}
				/>

				<PhotoGalleryDialog
					open={isGalleryOpen}
					photos={uploadedPhotos}
					initialIndex={initialImageIndex}
					onClose={closeGallery}
					onDelete={deleteImage}
					setFlag={setFlag}
					createOCR={handleCreateOCR}
					rotateImage={handleRotateImage}
					rotateImagePercentage={rotateImagePercentage}
					setRotateImagePercentage={setRotateImagePercentage}
				/>
			</InputFieldContainer>
		</Container>
	);
});

export default PhotosField;

const Container = styled.div`
	display: flex;
	flex-direction: column;
	gap: 40px;
`;

const ManageUploadsContainer = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
`;

const PendingUploadContainer = styled.div`
	span {
		color: #fff;
		cursor: pointer;

		b {
			color: ${theme.palette.primary.main};
		}
	}
`;
