import { useContext } from 'react';
import { useLocation, Navigate, Outlet } from 'react-router-dom';
import { AuthContext } from '../context/AuthProvider';
import { RouteProps } from '../types';

export default function PrivateRoute({ children, redirectPath = '/login' }: RouteProps) {
	const { user } = useContext(AuthContext);
	const { pathname, search } = useLocation();

	if (!user) {
		return (
			<Navigate
				to={redirectPath}
				state={{ redirectPath: pathname, searchParams: search }}
				replace
			/>
		);
	} else {
		if (children) return <>{children}</>;
		else return <Outlet />;
	}
}
