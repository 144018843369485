import {
	Announcements,
	DragEndEvent,
	DragMoveEvent,
	DragOverEvent,
	DragStartEvent,
	KeyboardSensor,
	PointerSensor,
	UniqueIdentifier,
	useSensor,
	useSensors,
} from '@dnd-kit/core';
import { useContext, useEffect, useRef, useState } from 'react';
import { FlattenedItem, Projected, SensorContext, TreeItem } from './types';
import { sortableTreeKeyboardCoordinates } from './keyboardCoordinates';
import {
	buildTree,
	flattenTree,
	formNodesToTreeItems,
	getProjection,
	removeChildrenOf,
	removeItem,
	setProperty,
} from './utils';
import { arrayMove } from '@dnd-kit/sortable';
import { FormNode } from '../../../ProjectUtilityFormV2/utils/types';
import { CellMeasurerCache } from 'react-virtualized';
import { SettingsContext } from '../../../../../context/SettingsProvider';

type useDraggingProps = {
	allFormNodes: FormNode[];
	indentationWidth: number;
	indicator: boolean;
	flattenedFooterPlaceholder: FlattenedItem;
	flattenedHeaderPlaceholder: FlattenedItem;
	rowHeights: CellMeasurerCache;
	saveNodes: (nodes: FormNode[]) => Promise<void>;
};

const useDragging = ({
	allFormNodes,
	flattenedFooterPlaceholder,
	flattenedHeaderPlaceholder,
	indentationWidth,
	indicator,
	rowHeights,
	saveNodes,
}: useDraggingProps) => {
	const { maxNodeContainerNestingLevel } = useContext(SettingsContext);

	const [activeId, setActiveId] = useState<UniqueIdentifier | null>(null);
	const [overId, setOverId] = useState<UniqueIdentifier | null>(null);
	const [offsetLeft, setOffsetLeft] = useState(0);
	const [currentPosition, setCurrentPosition] = useState<{
		parentId: UniqueIdentifier | null;
		overId: UniqueIdentifier;
	} | null>(null);
	const [flattenedItems, setFlattenedItems] = useState<FlattenedItem[]>([]);
	const [treeItems, setTreeItems] = useState<TreeItem[]>([]);
	const [parentIdToBeSaved, setParentIdToBeSaved] = useState<string>();

	useEffect(() => {
		if (!!allFormNodes && allFormNodes.length)
			setTreeItems(formNodesToTreeItems(allFormNodes));
	}, [allFormNodes]);

	const [projected, setProjected] = useState<Projected | null>(null);

	useEffect(() => {
		setProjected(
			activeId && overId
				? getProjection(flattenedItems, activeId, overId, offsetLeft, indentationWidth)
				: null
		);
	}, [activeId, overId, flattenedItems, offsetLeft, indentationWidth]);

	const sensorContext: SensorContext = useRef({
		items: flattenedItems,
		offset: offsetLeft,
	});

	const [coordinateGetter] = useState(() =>
		sortableTreeKeyboardCoordinates(sensorContext, indicator, indentationWidth)
	);

	const sensors = useSensors(
		useSensor(PointerSensor),
		useSensor(KeyboardSensor, {
			coordinateGetter,
		})
	);

	const [sortedIds, setSortedIds] = useState<UniqueIdentifier[]>([]);
	const [activeItem, setActiveItem] = useState<FlattenedItem>();

	useEffect(() => {
		setSortedIds(flattenedItems.map(({ id }) => id));
		setActiveItem(
			activeId ? flattenedItems.find(({ id }) => id === activeId) : undefined
		);
	}, [flattenedItems, activeId]);

	useEffect(() => {
		sensorContext.current = {
			items: flattenedItems,
			offset: offsetLeft,
		};
	}, [flattenedItems, offsetLeft]);

	useEffect(() => {
		const flattenedTree = flattenTree(treeItems);
		flattenedTree.unshift(flattenedHeaderPlaceholder);
		const collapsedItems = flattenedTree.reduce<string[]>(
			(acc, { children, collapsed, id }) =>
				collapsed && children.length ? [...acc, `${id}`] : acc,
			[]
		);
		flattenedTree.push(flattenedFooterPlaceholder);

		const result = removeChildrenOf(
			flattenedTree,
			activeId ? [activeId, ...collapsedItems] : collapsedItems
		);

		setFlattenedItems(result);
	}, [activeId, flattenedHeaderPlaceholder, flattenedFooterPlaceholder, treeItems]);

	function handleDragStart({ active: { id: activeId } }: DragStartEvent) {
		setActiveId(activeId);
		setOverId(activeId);

		const activeItem = flattenedItems.find(({ id }) => id === activeId);

		if (activeItem) {
			setCurrentPosition({
				parentId: activeItem.parentId,
				overId: activeId,
			});
		}

		document.body.style.setProperty('cursor', 'grabbing');
	}

	function handleDragMove({ delta }: DragMoveEvent) {
		setOffsetLeft(delta.x);
	}

	function handleDragOver({ over }: DragOverEvent) {
		setOverId(over?.id ?? null);
	}

	function handleDragEnd({ active, over }: DragEndEvent) {
		resetState();

		if (projected && over) {
			const { depth, parentId } = projected;
			const clonedItems: FlattenedItem[] = JSON.parse(
				JSON.stringify(flattenTree(treeItems))
			);
			const overIndex = clonedItems.findIndex(({ id }) => id === over.id);
			const activeIndex = clonedItems.findIndex(({ id }) => id === active.id);
			const activeTreeItem = clonedItems[activeIndex];
			const overTreeItem = clonedItems[overIndex];

			const activeNode = activeTreeItem.node;

			// Can't move a node before header or after footer
			if (over.id === 'header' || over.id === 'footer') return;

			// Only allow nodes and photos to be moved to the root level
			if (overTreeItem.depth === 0 && !['node', 'photos'].includes(activeNode.type))
				return;

			// Don't allow containers after the max nesting level
			if (
				overTreeItem.depth === maxNodeContainerNestingLevel &&
				!['node'].includes(activeNode.type)
			)
				return;

			const activeNodeDepth = getDepth(activeTreeItem) - 1;
			console.log(activeNodeDepth, overTreeItem.depth, maxNodeContainerNestingLevel);

			// Don't allow containers deeper than the max nesting level
			if (activeNodeDepth + overTreeItem.depth > maxNodeContainerNestingLevel) return;

			console.log('reorder');

			activeTreeItem.node.parentId = overTreeItem.node.parentId;
			setParentIdToBeSaved(overTreeItem.node.parentId);

			clonedItems[activeIndex] = { ...activeTreeItem, depth, parentId };

			const sortedItems = arrayMove(clonedItems, activeIndex, overIndex);
			const newItems = buildTree(sortedItems);

			setTreeItems(newItems);
		}
	}

	// Function that returns how many levels of depth their children have
	const getDepth = (node: TreeItem): number => {
		let depth = 0;
		if (node.children) {
			node.children.forEach(child => {
				const childDepth = getDepth(child);
				if (childDepth > depth) depth = childDepth;
			});
		}
		return depth + 1;
	};

	useEffect(() => {
		rowHeights.clearAll();
	}, [rowHeights, treeItems]);

	useEffect(() => {
		if (parentIdToBeSaved === undefined) return;

		const clonedItems: FlattenedItem[] = JSON.parse(
			JSON.stringify(flattenTree(treeItems))
		);

		saveNodes(
			clonedItems
				.filter(({ node }) => node.parentId === parentIdToBeSaved)
				.map(({ node, index }) => ({ ...node, order: index }))
		);

		setParentIdToBeSaved(undefined);
	}, [parentIdToBeSaved, saveNodes, treeItems]);

	function handleDragCancel() {
		resetState();
	}

	function resetState() {
		setOverId(null);
		setActiveId(null);
		setOffsetLeft(0);
		setCurrentPosition(null);

		document.body.style.setProperty('cursor', '');
	}

	function handleRemove(id: UniqueIdentifier) {
		setTreeItems(items => removeItem(items, id));
	}

	function handleCollapse(id: UniqueIdentifier) {
		setTreeItems(items =>
			setProperty(items, id, 'collapsed', value => {
				return !value;
			})
		);
	}

	function getMovementAnnouncement(
		eventName: string,
		activeId: UniqueIdentifier,
		overId?: UniqueIdentifier
	) {
		if (overId && projected) {
			if (eventName !== 'onDragEnd') {
				if (
					currentPosition &&
					projected.parentId === currentPosition.parentId &&
					overId === currentPosition.overId
				) {
					return;
				} else {
					setCurrentPosition({
						parentId: projected.parentId,
						overId,
					});
				}
			}

			const clonedItems: FlattenedItem[] = JSON.parse(
				JSON.stringify(flattenTree(treeItems))
			);
			const overIndex = clonedItems.findIndex(({ id }) => id === overId);
			const activeIndex = clonedItems.findIndex(({ id }) => id === activeId);
			const sortedItems = arrayMove(clonedItems, activeIndex, overIndex);

			const previousItem = sortedItems[overIndex - 1];

			let announcement;
			const movedVerb = eventName === 'onDragEnd' ? 'dropped' : 'moved';
			const nestedVerb = eventName === 'onDragEnd' ? 'dropped' : 'nested';

			if (!previousItem) {
				const nextItem = sortedItems[overIndex + 1];
				announcement = `${activeId} was ${movedVerb} before ${nextItem.id}.`;
			} else {
				if (projected.depth > previousItem.depth) {
					announcement = `${activeId} was ${nestedVerb} under ${previousItem.id}.`;
				} else {
					let previousSibling: FlattenedItem | undefined = previousItem;
					while (previousSibling && projected.depth < previousSibling.depth) {
						const parentId: UniqueIdentifier | null = previousSibling.parentId;
						previousSibling = sortedItems.find(({ id }) => id === parentId);
					}

					if (previousSibling) {
						announcement = `${activeId} was ${movedVerb} after ${previousSibling.id}.`;
					}
				}
			}

			return announcement;
		}

		return;
	}

	const announcements: Announcements = {
		onDragStart({ active }) {
			return `Picked up ${active.id}.`;
		},
		onDragMove({ active, over }) {
			return getMovementAnnouncement('onDragMove', active.id, over?.id);
		},
		onDragOver({ active, over }) {
			return getMovementAnnouncement('onDragOver', active.id, over?.id);
		},
		onDragEnd({ active, over }) {
			return getMovementAnnouncement('onDragEnd', active.id, over?.id);
		},
		onDragCancel({ active }) {
			return `Moving was cancelled. ${active.id} was dropped in its original position.`;
		},
	};

	return {
		announcements,
		handleDragStart,
		handleDragMove,
		handleDragOver,
		handleDragEnd,
		handleDragCancel,
		handleRemove,
		handleCollapse,
		sensors,
		sortedIds,
		activeItem,
		flattenedItems,
		activeId,
		projected,
	};
};

export default useDragging;
