import { styled } from '@mui/system';
import { FC, useCallback, useContext, useEffect, useState } from 'react';
import theme from '../../../styles/theme';
import { BlockMenuButtonOptions, BlockMenuButtonProps } from './BlockMenuButton.types';
import { Menu, MenuItem } from '@mui/material';
import MenuButton from '../MenuButton/MenuButton';
import MenuItemContent from '../MenuItem/MenuItem';
import { ChevronRight, ContentCut, ListAlt } from '@mui/icons-material';
import { TemplateContext } from '../../../context/TemplateProvider';
import { AuthContext } from '../../../context/AuthProvider';

const BlockMenuButton: FC<BlockMenuButtonProps> = ({
	disable,
	onChange,
	small = false,
	isSection = false,
}) => {
	const { user } = useContext(AuthContext);
	const { isTemplate, template } = useContext(TemplateContext);
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const [disableButton, setDisableButton] = useState(false);
	const open = Boolean(anchorEl);

	const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const handleItemClick = (item: BlockMenuButtonOptions) => {
		if (!disable?.includes(item)) {
			onChange?.(item);
			handleClose();
		}
	};

	const handleDisableButton = useCallback(() => {
		if (isTemplate && user?.id !== template?.userId && !user?.isAdmin) {
			setDisableButton(true);
		}
	}, [isTemplate, template?.userId, user?.id, user?.isAdmin]);

	useEffect(() => {
		handleDisableButton();
	}, [handleDisableButton]);

	return (
		<>
			<MenuButton
				disabled={disableButton}
				onClick={handleClick}
				open={open}
				small={small}>
				Add block
			</MenuButton>

			<CustomMenu anchorEl={anchorEl} open={open} onClose={handleClose}>
				<CustomMenuItem
					onClick={() => handleItemClick('section')}
					disabled={disable?.includes('section')}>
					<MenuItemContent
						title="Section"
						description="Add sections to organize report fields. Sections appear as navigation items in the report outline."
						startIcon={<ListAlt />}
						endIcon={<ChevronRight />}
					/>
				</CustomMenuItem>
				{!isSection && (
					<CustomMenuItem
						onClick={() => handleItemClick('input')}
						disabled={disable?.includes('input')}>
						<MenuItemContent
							title="Input"
							description="Add report inputs to collect information. Inputs can only be added inside of a report section."
							startIcon={<ContentCut />}
							endIcon={<ChevronRight />}
						/>
					</CustomMenuItem>
				)}
			</CustomMenu>
		</>
	);
};

const CustomMenu = styled(Menu)`
	top: 8px;

	.MuiPaper-root {
		background-color: ${theme.palette.secondary.main};
	}
`;

const CustomMenuItem = styled(MenuItem)`
	padding: 0;
	white-space: normal;
`;

export default BlockMenuButton;
