import { useContext } from 'react';
import { newCustomCategory } from './BaseCategories';
import { FormContext } from '../FormContext';
import { Button } from '@mui/material';
import styled from 'styled-components';

export default function AddCustomCategory() {
	const { setFormState } = useContext(FormContext);

	const addCustomCategory = () => {
		setFormState(prev => [...prev, newCustomCategory()]);
	};

	return (
		<Container>
			{/* <h2>Add Custom Category </h2> */}
			<Button onClick={addCustomCategory}>
				{/* <AddIcon /> */}
				Add Custom Category
			</Button>
		</Container>
	);
}

const Container = styled.div`
	display: flex;
	background-color: black;
	color: #ffb310;
	justify-content: center;
`;
