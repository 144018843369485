const ZOOM_FACTOR = 0.1;
const ZOOM_FACTOR_IN = 1 + ZOOM_FACTOR;
const ZOOM_FACTOR_OUT = 1 - ZOOM_FACTOR;

export interface Action {
	type: 'PAN' | 'PAN_START' | 'ZOOM' | 'RESET';
	clientX: number;
	clientY: number;
}
export interface ZoomAction extends Action {
	zoomFactor: number;
	containerRect: DOMRect;
}

export const startPan = (event: any): Action => ({
	type: 'PAN_START',
	clientX: event.clientX,
	clientY: event.clientY,
});

export const pan = (event: any): Action => ({
	type: 'PAN',
	clientX: event.clientX,
	clientY: event.clientY,
});

export const zoom = (event: any, containerRect: DOMRect): ZoomAction => ({
	type: 'ZOOM',
	clientX: event.clientX,
	clientY: event.clientY,
	zoomFactor: event.deltaY < 0 ? ZOOM_FACTOR_IN : ZOOM_FACTOR_OUT,
	containerRect: containerRect,
});

export const reset = (): Action => ({
	type: 'RESET',
	clientX: 0,
	clientY: 0,
});
