import { TextField } from '@mui/material';
import { FormNode } from '../../../utils/types';
import { useContext, useState } from 'react';
import { SnackContext } from '../../../../../../context/SnackProvider';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { db } from '../../../../../../firebase';
import {
	Info,
	InfoContent,
	InfoText,
	InfoTitle,
	ItemText,
	StyledActions,
	StyledButton,
} from './InfoDialog.styles';

interface InfoDialogProps {
	open: boolean;
	node: FormNode;
	onClose: () => void;
	templateSave?: (info?: string) => void;
}

export default function InfoDialog({
	templateSave,
	open,
	node,
	onClose,
}: InfoDialogProps) {
	const { setSnackbarProps } = useContext(SnackContext);
	const [info, setInfo] = useState(node.info);
	const handleInfoSubmit = async () => {
		if (templateSave) {
			templateSave(info);
			onClose();
			return;
		}

		const itemRef = doc(db, `utility_forms_v2_items/${node.id}`);
		const itemSnap = await getDoc(itemRef); // get doc snapshot

		// make sure item exists before submitting comment
		if (!itemSnap.exists()) {
			onClose(); // Close the dialog
			setSnackbarProps({
				open: true,
				message: 'Another user deleted this item.',
				severity: 'error',
				hideDuration: 6000,
			});
			return;
		}

		try {
			onClose(); // Close the dialog

			await updateDoc(itemRef, { info: info });

			setSnackbarProps({
				open: true,
				message: 'Info saved successfully!',
				severity: 'success',
				hideDuration: 6000,
			});
		} catch (error) {
			console.error('Error saving info: ', error);
			setSnackbarProps({
				open: true,
				message: 'Error saving info. Please try again!',
				severity: 'error',
				hideDuration: 6000,
			});
		}
	};

	const handleKeyDown = async (e: React.KeyboardEvent<HTMLDivElement>) => {
		if (e.key === 'Enter' && !e.shiftKey) {
			e.preventDefault();
			handleInfoSubmit();
		}
	};

	return (
		<>
			<Info open={open} onClose={onClose} aria-labelledby="">
				<InfoTitle>
					<InfoText>Node info</InfoText> <ItemText>{node.displayTitle}</ItemText>
				</InfoTitle>

				<InfoContent>
					<TextField
						multiline
						value={info}
						onChange={e => setInfo(e.target.value)}
						variant="standard"
						onKeyDown={handleKeyDown}
						autoFocus
						inputProps={{ style: { fontSize: '0.875rem' } }}
						fullWidth
					/>
				</InfoContent>

				<StyledActions>
					<StyledButton onClick={handleInfoSubmit}>Save</StyledButton>
					<StyledButton onClick={onClose}>Close</StyledButton>
				</StyledActions>
			</Info>
		</>
	);
}
