import { useContext, useState } from 'react';
import { FormTemplate } from '../ProjectUtilityFormV2/utils/types';
import { duplicateTemplate as callableDuplicateTemplate } from '../../../firebase';
import { TemplatesContext } from '../../../context/TemplatesProvider';

const useDuplicateTemplate = (template: FormTemplate) => {
	const { refreshData } = useContext(TemplatesContext);

	const [duplicateDialogOpen, setDuplicateDialogOpen] = useState(false);
	const [isDuplicating, setIsDuplicating] = useState(false);

	const duplicateTemplate = (e: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
		e.stopPropagation();
		setDuplicateDialogOpen(true);
	};

	const closeDuplicateDialog = () => setDuplicateDialogOpen(false);

	const handleDuplicateTemplate = async () => {
		setIsDuplicating(true);
		closeDuplicateDialog();
		await callableDuplicateTemplate({ templateId: template.id });
		setIsDuplicating(false);
		refreshData();
	};

	return {
		closeDuplicateDialog,
		duplicateDialogOpen,
		handleDuplicateTemplate,
		isDuplicating,
		duplicateTemplate,
	};
};

export default useDuplicateTemplate;
