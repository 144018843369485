import { FieldLabel } from '../../../../../screens/ProjectInfo';
import React, { useContext } from 'react';
import StringInput from './StringInput';
import { AuthContext } from '../../../../../context/AuthProvider';

// Props for Internal Notes
interface InternalNotesProps {
	isActive: boolean;
	handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

// Internal Notes Information
export default function InternalNotes({ isActive, handleChange }: InternalNotesProps) {
	const { user } = useContext(AuthContext);
	return user?.isAdmin ? (
		<div className="infoRow multilineRow">
			<FieldLabel label={'Internal Notes'} />

			<StringInput
				field={'internalNotes'}
				onBlur={handleChange}
				isActive={Boolean(user?.isAdmin && isActive)}
				multiline
			/>
		</div>
	) : null;
}
