import { db } from '../firebase';
import { doc, getDoc } from 'firebase/firestore';

// fetches equipment stored in cachedData collection in firestore
export async function fetchEquipment(): Promise<string[]> {
	const docRef = doc(db, 'cached_data', 'equipment');
	const docSnap = await getDoc(docRef);

	if (docSnap.exists()) {
		const data = docSnap.data();
		return data.realityKits || [];
	} else {
		console.log('No such document');
		return [];
	}
}
