import styled from 'styled-components';

export const CommentsIndicator = styled.div`
	position: absolute;
	top: 1px;
	right: 1px;
	background-color: #5bc236;
	width: 15px;
	height: 15px;
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
	color: white;
	font-size: 0.5rem;
`;
