import { useState } from 'react';
import { doc, serverTimestamp, updateDoc } from 'firebase/firestore';
import { UtilityFormV2 } from '../ProjectUtilityFormV2/utils/types';
import { db } from '../../../firebase';

const useRenameForm = (form: UtilityFormV2) => {
	const [renameDialogOpen, setRenameDialogOpen] = useState(false);
	const [isSaving, setIsSaving] = useState(false);

	const renameForm = (e: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
		e.stopPropagation();
		setRenameDialogOpen(true);
	};

	const closeRenameDialog = () => setRenameDialogOpen(false);

	const handleRenameForm = async (value: string) => {
		setIsSaving(true);
		closeRenameDialog();
		const formRef = doc(db, 'utility_forms_v2', form.id);
		await updateDoc(formRef, {
			formName: value,
			lastUpdated: serverTimestamp(),
		});
		setIsSaving(false);
	};

	return {
		closeRenameDialog,
		handleRenameForm,
		isSaving,
		renameDialogOpen,
		renameForm,
	};
};

export default useRenameForm;
