import {
	query,
	collection,
	getFirestore,
	orderBy,
	onSnapshot,
	where,
	Timestamp,
} from 'firebase/firestore';
import { useState, useEffect, createContext, useCallback, useContext } from 'react';
import {
	UtilityFormDetailsForV2,
	UtilityFormV2,
} from '../components/screen-components/ProjectUtilityFormV2/utils/types';
import { getProject, getUtilityForms } from '../firebase';
import { formatProjectTimestamps } from '../utils';
import { AuthContext } from './AuthProvider';

const ITEMS_PER_PAGE = 5;

export type V2FormsContextType = {
	clearQuery: () => void;
	existingFormNames: string[] | undefined;
	loadingForms: boolean;
	loadingV1Forms: boolean;
	nextPage: () => void;
	onChangeQuery: (event: React.ChangeEvent<HTMLInputElement>) => void;
	page: number;
	prevPage: () => void;
	searchQuery: string;
	totalPages: number | undefined;
	forms: (UtilityFormV2 | UtilityFormDetailsForV2)[] | undefined;
	project: any | null;
	projectId: string | undefined;
	setProjectId: React.Dispatch<React.SetStateAction<string | undefined>>;
};

export const V2FormsContext = createContext({} as V2FormsContextType);

export const V2FormsProvider = ({ children }: { children?: React.ReactNode }) => {
	const { user } = useContext(AuthContext);

	const [forms, setForms] = useState<UtilityFormV2[] | undefined>();
	const [filteredForms, setFilteredForms] = useState<
		(UtilityFormV2 | UtilityFormDetailsForV2)[] | undefined
	>();
	const [pagedForms, setPagedForms] = useState<
		(UtilityFormV2 | UtilityFormDetailsForV2)[] | undefined
	>();
	const [loadingForms, setLoadingForms] = useState(true);
	const [loadingV1Forms, setLoadingV1Forms] = useState(true);
	const [page, setPage] = useState(1);
	const [searchQuery, setSearchQuery] = useState('');
	const [projectId, setProjectId] = useState<string | undefined>();
	const [project, setProject] = useState<any | null>(null);
	const [totalPages, setTotalPages] = useState<number | undefined>();
	const [v1Forms, setV1Forms] = useState<UtilityFormDetailsForV2[] | undefined>();
	const [existingFormNames, setExistingFormNames] = useState<string[] | undefined>();

	useEffect(() => {
		const getSetProject = async () => {
			try {
				const res = await getProject({ projectId: projectId });
				const resData = res.data as any;
				if (resData.project) setProject(formatProjectTimestamps([resData.project])[0]);
			} catch (err) {
				console.error(err);
			}
		};
		if (projectId) getSetProject();
	}, [projectId, setProject]);

	const loadV1Forms = useCallback(async () => {
		if (!projectId) return;
		if (!user?.isAdmin) {
			setV1Forms([]);
			setLoadingV1Forms(false);
			return;
		}

		const v1res = await getUtilityForms(projectId);
		const formsList: UtilityFormDetailsForV2[] = (v1res.data as any)?.utilityForms;
		const listenerFormsArr: UtilityFormDetailsForV2[] = [];
		const formsListParsed = formsList.map(form => {
			form = {
				...form,
				createdDate: form.createdDate
					? Timestamp.fromDate(new Date((form.createdDate as any)._seconds * 1000))
					: null,
				lastUpdated: Timestamp.fromDate(
					new Date((form.lastUpdated as any)._seconds * 1000)
				),
				uploadedDate: form.uploadedDate
					? Timestamp.fromDate(new Date((form.uploadedDate as any)._seconds * 1000))
					: null,
			};
			if (form.status === 'processing' || form.status === 'uploaded') {
				listenerFormsArr.push(form);
			}
			return form;
		});
		setV1Forms(formsListParsed);
		setLoadingV1Forms(false);
	}, [projectId, user?.isAdmin]);

	useEffect(() => {
		if (!projectId) return;

		const formQuery = query(
			collection(getFirestore(), 'utility_forms_v2'),
			where('projectId', '==', projectId),
			orderBy('lastUpdated', 'desc')
		);

		const unsubscribe = onSnapshot(formQuery, async collection => {
			const forms = collection.docs.map(doc => ({
				...(doc.data() as UtilityFormV2),
				id: doc.id,
			}));

			setForms(forms);

			if (loadingForms) setLoadingForms(false);
		});

		loadV1Forms();

		return () => unsubscribe();
	}, [loadV1Forms, loadingForms, projectId]);

	const nextPage = () => {
		if (totalPages && page < totalPages) setPage(prev => prev + 1);
	};
	const prevPage = () => {
		if (page > 1) setPage(prev => prev - 1);
	};

	const onChangeQuery = (event: React.ChangeEvent<HTMLInputElement>) =>
		setSearchQuery(event.target.value);
	const clearQuery = () => setSearchQuery('');

	useEffect(() => {
		if (searchQuery) {
			const filtered = forms?.filter(form =>
				form.formName.toLowerCase().includes(searchQuery.toLowerCase())
			);
			const filteredV1 = v1Forms?.filter(form =>
				form.formName.toLowerCase().includes(searchQuery.toLowerCase())
			);
			const allForms = [...(filtered || []), ...(filteredV1 || [])];
			setFilteredForms(allForms);
			setTotalPages(Math.ceil((allForms?.length || 0) / ITEMS_PER_PAGE));
		} else {
			const allForms = [...(forms || []), ...(v1Forms || [])];
			setFilteredForms(allForms);
			setTotalPages(Math.ceil((allForms?.length || 0) / ITEMS_PER_PAGE));
		}
	}, [forms, v1Forms, searchQuery]);

	useEffect(() => {
		if (filteredForms) {
			const start = (page - 1) * ITEMS_PER_PAGE;
			const end = start + ITEMS_PER_PAGE;
			setPagedForms(filteredForms.slice(start, end));
		}
	}, [filteredForms, page]);

	useEffect(() => {
		setExistingFormNames(
			[...(forms || []), ...(v1Forms || [])].map(form => form.formName)
		);
	}, [forms, v1Forms]);

	return (
		<V2FormsContext.Provider
			value={{
				clearQuery,
				existingFormNames,
				loadingForms,
				loadingV1Forms,
				nextPage,
				onChangeQuery,
				page,
				prevPage,
				searchQuery,
				totalPages,
				forms: pagedForms,
				project,
				projectId,
				setProjectId,
			}}>
			{children}
		</V2FormsContext.Provider>
	);
};
