import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	IconButton,
} from '@mui/material';
import { collection, deleteDoc, doc, getDoc, getDocs, setDoc } from 'firebase/firestore';
import { useContext, useEffect, useState } from 'react';
import { db } from '../../../firebase';
import Spinner from '../../../components/reusable-components/Spinner';
import UnarchiveIcon from '@mui/icons-material/Unarchive';
import { ProjectContext } from '../../../context/ProjectProvider';
import { SnackContext } from '../../../context/SnackProvider';

const flexRow = {
	display: 'flex',
	flexWrap: 'wrap',
	columnGap: '10px',
};

export default function ContractorArchive({ showingDialog, setShowingDialog }) {
	const { setContractors } = useContext(ProjectContext);
	const { setSnackbarProps } = useContext(SnackContext);

	const handleClose = () => {
		setShowingDialog(false);
	};

	const [archivedContractors, setArchivedContractors] = useState([]);
	const [loadingArchive, setLoadingArchive] = useState(false);

	const unArchive = async contractor => {
		const archiveRef = doc(collection(db, 'contractor_archive'), contractor.id);
		const conDoc = await getDoc(archiveRef);
		if (conDoc.exists) {
			const ref = doc(collection(db, 'matterport_directory'), contractor.id);
			setDoc(ref, conDoc.data());
			deleteDoc(archiveRef);

			const idx = archivedContractors.findIndex(con => con.id === contractor.id);
			const temp = [...archivedContractors];
			temp.splice(idx, 1);
			setArchivedContractors(temp);
			setContractors(prev => [...prev, contractor]);

			setSnackbarProps({
				open: true,
				message: `Unarchived ${contractor.Name}`,
				severity: 'success',
			});
		} else {
			setSnackbarProps({
				open: true,
				message: `Something went wrong. Please refresh the page and try again.`,
				severity: 'warning',
			});
		}
	};

	useEffect(() => {
		if (showingDialog === true) {
			const archiveRef = collection(db, 'contractor_archive');
			setArchivedContractors(async () => {
				setLoadingArchive(true);
				const docSnaps = await getDocs(archiveRef);
				setLoadingArchive(false);

				const tmp = [];
				docSnaps.forEach(doc => {
					tmp.push({
						...doc.data(),
						id: doc.id,
					});
				});
				setArchivedContractors(tmp);
			});
		}
	}, [showingDialog]);

	return (
		<Dialog open={showingDialog} onClose={handleClose}>
			<DialogTitle>Archived Contractors</DialogTitle>
			<DialogContent>
				{loadingArchive ? (
					<Spinner />
				) : archivedContractors.length ? (
					archivedContractors.map(con => (
						<div
							key={con.id}
							style={{
								display: 'flex',
								border: 'solid lightgrey 1px',
								borderRadius: '5px',
								padding: '5px',
								alignItems: 'center',
								justifyContent: 'space-between',
							}}>
							<div style={{ display: 'flex', flexDirection: 'column' }}>
								<h2 style={{ margin: 0 }}>{con.Name}</h2>
								<div style={flexRow}>
									<span>
										<strong>Email: </strong>
										{con.Email ? con.Email : 'N/A'}
									</span>
									<span>
										<strong>Address: </strong>
										{con.Address ?? 'N/A'}
									</span>
								</div>
							</div>
							<IconButton
								style={{ height: 'fit-content' }}
								title={`Unarchive ${con.Name}`}
								onClick={() => {
									if (window.confirm(`Are you sure you want to unarchive ${con.Name}?`)) {
										unArchive(con);
									}
								}}>
								<UnarchiveIcon />
							</IconButton>
						</div>
					))
				) : (
					'No archived contractors to display'
				)}
			</DialogContent>
			<DialogActions>
				<Button onClick={handleClose} color="primary">
					Close
				</Button>
			</DialogActions>
		</Dialog>
	);
}
