import { useContext } from 'react';
import { SnackContext } from '../../context/SnackProvider';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { copyToClipboard } from '../../utils';
import styled from 'styled-components';

export default function LinkWithCopyButton({
	link,
	linkText,
	title,
}: {
	link: string;
	linkText?: string;
	title: string;
}) {
	const { setSnackbarProps } = useContext(SnackContext);

	return (
		<LinkWithCopy>
			<a className="clickable-link" href={link} target="_blank" rel="noreferrer">
				{linkText || link}
			</a>
			{link ? (
				<ContentCopyIcon
					onClick={() => {
						copyToClipboard(link);
						setSnackbarProps({
							open: true,
							message: `${title} link copied!`,
							severity: 'success',
						});
					}}
					className="CopyLinkButton"
					titleAccess={`Copy ${title} link`}
					tabIndex={0}
				/>
			) : null}
		</LinkWithCopy>
	);
}

const LinkWithCopy = styled.div`
	display: flex;
	align-items: center;
	gap: 2px;
	min-width: 0px;
	max-width: 100%;
`;
