import { useContext, useState } from 'react';
import { doc, serverTimestamp, updateDoc } from 'firebase/firestore';
import { FormTemplate } from '../ProjectUtilityFormV2/utils/types';
import { db } from '../../../firebase';
import { AuthContext } from '../../../context/AuthProvider';
import { TemplatesContext } from '../../../context/TemplatesProvider';

const useRenameTemplate = (template: FormTemplate) => {
	const { user, firebaseAuthData } = useContext(AuthContext);
	const { refreshData } = useContext(TemplatesContext);

	const [renameDialogOpen, setRenameDialogOpen] = useState(false);
	const [isSaving, setIsSaving] = useState(false);

	const renameTemplate = (e: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
		e.stopPropagation();
		setRenameDialogOpen(true);
	};

	const closeRenameDialog = () => setRenameDialogOpen(false);

	const handleRenameTemplate = async (value: string) => {
		setIsSaving(true);
		closeRenameDialog();
		const templateRef = doc(db, 'utility_forms_v2_templates', template.id);
		await updateDoc(templateRef, {
			name: value,
			updatedAt: serverTimestamp(),
			updatedBy: {
				id: firebaseAuthData?.uid || '',
				name: user?.fullName || '',
			},
		});
		setIsSaving(false);
		refreshData();
	};

	return {
		closeRenameDialog,
		handleRenameTemplate,
		isSaving,
		renameDialogOpen,
		renameTemplate,
	};
};

export default useRenameTemplate;
