import { useEffect, useState } from 'react';
import { FormNode } from '../utils/types';
import { doc, onSnapshot } from 'firebase/firestore';
import { db } from '../../../../firebase';

const useNodeListener = (nodeId?: string) => {
	const [nodeData, setNodeData] = useState<FormNode | undefined>();

	useEffect(() => {
		if (nodeId) {
			const nodeRef = doc(db, 'utility_forms_v2_items', nodeId);
			const unsub = onSnapshot(nodeRef, async snapshot => {
				if (snapshot.exists()) {
					const data = snapshot.data() as FormNode;
					data.id = snapshot.id;
					setNodeData(data);
					console.log('Node Data:', data);
				}
			});
			return () => {
				unsub();
			};
		}
	}, [nodeId]);

	return nodeData;
};

export default useNodeListener;
