import {
	Custom,
	Disconnect,
	ElectricMeter,
	FireAlarmControlPanel,
	Hvac,
	MetaData,
	ServicePanel,
	WaterHeater,
	WaterMeter,
} from '../types';

import { v4 as uuidv4 } from 'uuid';

/**
 *
 * Base Item and metada map for each item type
 */

export const newServicePanel = () => {
	const baseServicePanel: ServicePanel = {
		itemId: uuidv4(),
		type: 'SERVICE_PANEL',
		displayTitle: 'Service Panel',
		location: '',
		notes: '',
		photos: [],
		customFields: {},

		metadata: {
			panelNumber: '',
			amps: '',
			serviceType: '',
			panelType: '',
			panelUlNumber: '',
			manufacturer: '',
		},
	};
	return baseServicePanel;
};

export const servicePanelMetadataMap: MetaData = {
	panelNumber: 'Panel Number',
	amps: 'Amps',
	serviceType: 'Service Type',
	panelType: 'Panel Type',
	panelUlNumber: 'Panel UL Number',
	manufacturer: 'Manufacturer',
};

export const newDisconnect = () => {
	const baseDisconnect: Disconnect = {
		itemId: uuidv4(),
		type: 'DISCONNECT',
		displayTitle: 'Disconnect',
		location: '',
		notes: '',
		photos: [],
		customFields: {},

		metadata: {
			panelNumber: '',
			amps: '',
			manufacturer: '',
			isHandle: false,
			isBreaker: false,
		},
	};
	return baseDisconnect;
};

export const disconnectMetadataMap: MetaData = {
	panelNumber: 'Panel Number',
	amps: 'Amps',
	manufacturer: 'Manufacturer',
	isHandle: 'Handle',
	isBreaker: 'Breaker',
};

export const newElectricMeter = () => {
	const baseElectricMeter: ElectricMeter = {
		itemId: uuidv4(),
		type: 'ELECTRIC_METER',
		displayTitle: 'Electric Meter',
		location: '',
		notes: '',
		photos: [],
		customFields: {},

		metadata: {
			meterNumber: '',
			meterReading: '',
			read: 'OFF',
			voltage: '',
		},
	};
	return baseElectricMeter;
};

export const electricMeterMetadataMap: MetaData = {
	meterNumber: 'Meter Number',
	meterReading: 'Meter Reading',
	read: 'Read',
	voltage: 'Voltage',
};

export const newHvac = (displayTitle?: string) => {
	const baseServicePanel: Hvac = {
		itemId: uuidv4(),
		type: 'HVAC',
		displayTitle: displayTitle ?? 'Hvac',
		location: '',
		notes: '',
		photos: [],
		customFields: {},

		metadata: {
			modelNumber: '',
			serialNumber: '',
			tonnage: '',
			year: '',
		},
	};
	return baseServicePanel;
};

export const hvacMetadataMap: MetaData = {
	modelNumber: 'Model Number',
	serialNumber: 'Serial Number',
	tonnage: 'Tonnage',
	year: 'Year',
};

export const newWaterHeater = () => {
	const baseWaterHeater: WaterHeater = {
		itemId: uuidv4(),
		type: 'WATER_HEATER',
		displayTitle: 'Water Heater',
		location: '',
		notes: '',
		photos: [],
		customFields: {},

		metadata: {
			modelNumber: '',
			serialNumber: '',
			gallons: '',
			tankless: false,
		},
	};
	return baseWaterHeater;
};

export const waterHeaterMetadataMap: MetaData = {
	modelNumber: 'Model Number',
	serialNumber: 'Serial Number',
	gallons: 'Gallons',
	tankless: 'Tankless',
};

export const newWaterMeter = () => {
	const baseWaterMeter: WaterMeter = {
		itemId: uuidv4(),
		type: 'WATER_METER',
		displayTitle: 'Water Meter',
		location: '',
		notes: '',
		photos: [],
		customFields: {},
		metadata: {
			meterNumber: '',
		},
	};
	return baseWaterMeter;
};

export const waterMeterMetadataMap: MetaData = {
	meterNumber: 'Meter Number',
};

export const newFireAlarmControlPanel = () => {
	const baseFireAlarmControlPanel: FireAlarmControlPanel = {
		itemId: uuidv4(),
		type: 'FIRE_ALARM_CONTROL_PANEL',
		displayTitle: 'Fire Alarm Control Panel',
		location: '',
		notes: '',
		photos: [],
		customFields: {},
		metadata: {
			modelNumber: '',
			numberOfLines: '',
			phoneNumbersListed: [],
		},
	};
	return baseFireAlarmControlPanel;
};
export const fireAlarmControlPanelMetadataMap: MetaData = {
	modelNumber: 'Model Number',
	numberOfLines: 'Number of Lines',
	phoneNumbersListed: 'Phone numbers Listed',
};

export const newCustomItem = () => {
	const baseCustomItem: Custom = {
		itemId: uuidv4(),
		type: 'CUSTOM',
		displayTitle: 'Custom Item',
		location: '',
		notes: '',
		photos: [],
		customFields: {},
	};
	return baseCustomItem;
};
