import React, { useRef } from 'react';
import { useClickOutside } from '../../../../../../screens/Map/components/CameraChips';
import styled from 'styled-components';

interface EquipmentChipsProps {
	defaultValue: string[];
	form: any;
	field: string;
	setForm: React.Dispatch<React.SetStateAction<any>>;
	style?: React.CSSProperties;
	onBlur?: () => void;
	options: string[];
}

export default function EquipmentChips({
	defaultValue,
	form,
	field,
	setForm,
	style = {},
	onBlur = () => {},
	options,
}: EquipmentChipsProps) {
	const ref = useRef(null);
	useClickOutside(ref, onBlur);

	const equipmentList = form[field] ?? defaultValue;

	return (
		<EquipmentContainer style={style} ref={ref}>
			<EquipmentRow>
				<SelectedChipContainer>
					{equipmentList.length ? (
						equipmentList.map((equipment: string) => (
							<Chip
								type="button"
								key={equipment}
								onClick={() => {
									setForm({
										...form,
										[field]: equipmentList.filter((item: string) => item !== equipment),
									});
								}}
								tabIndex={0}>
								{equipment}
							</Chip>
						))
					) : (
						<span>None</span>
					)}
				</SelectedChipContainer>
			</EquipmentRow>
			<ChipContainer>
				{options.map((option: string) => {
					if (!equipmentList.includes(option)) {
						return (
							<Chip
								type="button"
								key={option}
								onClick={() => {
									setForm({
										...form,
										[field]: [...equipmentList, option],
									});
								}}
								tabIndex={0}>
								{option}
							</Chip>
						);
					}
					return '';
				})}
			</ChipContainer>
		</EquipmentContainer>
	);
}

const EquipmentContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 10px;
`;

const EquipmentRow = styled.div`
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	align-items: center;
	gap: 10px;
	border-bottom: dotted 2px #ffb310;
	padding-bottom: 5px;
`;

const ChipContainer = styled.div`
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	align-items: center;
	gap: 5px;
`;

const SelectedChipContainer = styled(ChipContainer)`
	background-color: darkgray;
	padding: 5px;
	border-radius: 5px;
`;

const Chip = styled.button`
	border: solid 1px grey;
	background-color: white;
	padding: 3px 5px;
	cursor: pointer;
	transition: 0.1s;
	font-family: 'Epilogue';
	border-radius: 5px;

	&:hover {
		box-shadow: 0px 0px 5px grey;
	}
	&:active {
		background-color: rgb(245, 245, 245);
	}
`;
