import { useEffect, useState } from 'react';
import { collection, query, where, onSnapshot, updateDoc, doc } from 'firebase/firestore';
import { db } from '../../../../firebase';
import { FormNode } from '../utils/types';

export const useRootNodes = (formId: string, projectId: string) => {
	const [rootPhotos, setRootPhotos] = useState<FormNode[]>([]);
	const [rootContainers, setRootContainers] = useState<FormNode[]>([]);

	const [rootNodes, setRootNodes] = useState<FormNode[]>([]);
	const [isLoading, setIsLoading] = useState(true);

	useEffect(() => {
		if (projectId) {
			const fetchNodes = () => {
				// separate queries for 'photos' and 'node' types
				const photoQuery = query(
					collection(db, 'utility_forms_v2_items'),
					where('formId', '==', formId),
					where('projectId', '==', projectId),
					where('parentId', '==', ''),
					where('type', '==', 'photos')
				);
				const nodeQuery = query(
					collection(db, 'utility_forms_v2_items'),
					where('formId', '==', formId),
					where('projectId', '==', projectId),
					where('parentId', '==', ''),
					where('type', '==', 'node')
				);

				// Listen to root node queries
				const unsubPhotos = onSnapshot(photoQuery, snapshot => {
					const rootPhotos = snapshot.docs;
					setRootPhotos(rootPhotos.map(photoDoc => photoDoc.data() as FormNode));
				});

				// Listen to root node queries
				const unsubContainers = onSnapshot(nodeQuery, snapshot => {
					const rootContainers = snapshot.docs;
					setRootContainers(
						rootContainers.map(containerDoc => containerDoc.data() as FormNode)
					);
				});

				return () => {
					unsubPhotos();
					unsubContainers();
				};
			};

			const unsub = fetchNodes();

			return () => {
				unsub();
			};
		}
	}, [formId, projectId]);

	useEffect(() => {
		const combined = [...rootPhotos, ...rootContainers];
		combined.sort((a, b) => (a.order || 0) - (b.order || 0));
		setRootNodes(combined);
		setIsLoading(false);
	}, [rootPhotos, rootContainers]);

	// This useEffect makes sure that the 'level' property of all root node is always 0.
	useEffect(() => {
		if (rootNodes.length) {
			for (const node of rootNodes) {
				if (node.level !== 0) {
					console.warn(`Root node '${node.id}' has incorrect level! Setting level to 0.`);
					const nodeDoc = doc(db, `utility_forms_v2_items/${node.id}`);
					updateDoc(nodeDoc, {
						level: 0,
					});
				}
			}
		}
	}, [rootNodes]);

	return {
		rootNodes,
		isLoading,
	};
};
