import {
	// FieldValue,
	arrayUnion,
	collection,
	deleteDoc,
	doc,
	getDocs,
	orderBy,
	query,
	// setDoc,
	updateDoc,
	where,
} from 'firebase/firestore';
import { db } from '../../../../../../firebase';
import {
	ArchivedPhotoV2,
	FormNode,
	FormNodeWithChildren,
	PhotoV2,
} from '../../../utils/types';
import { useCallback, useContext, useState } from 'react';
import { SnackContext } from '../../../../../../context/SnackProvider';

const useArchivedPhotos = (formId: string, projectId: string) => {
	const [archivedPhotos, setArchivedPhotos] = useState<ArchivedPhotoV2[]>([]);
	const [activePhotoNodes, setActivePhotoNodes] = useState<FormNode[]>([]);
	const [loading, setLoading] = useState(false);
	const { setSnackbarProps } = useContext(SnackContext);

	const getArchivedPhotos = useCallback(async () => {
		try {
			setLoading(true);
			setActivePhotoNodes([]);

			const photosRef = query(
				collection(db, 'utility_forms_v2_photos_archive'),
				where('formId', '==', formId),
				where('projectId', '==', projectId),
				orderBy('originalName')
			);

			const photosCollection = await getDocs(photosRef);

			const photos: ArchivedPhotoV2[] = photosCollection.docs.map(doc => {
				const data = doc.data() as ArchivedPhotoV2;
				data.id = doc.id;
				return data;
			});

			setArchivedPhotos(photos);
			setLoading(false);
		} catch (error) {
			console.error(error);
			return [];
		}
	}, [formId, projectId]);

	const getActivePhotoNodes = async () => {
		try {
			setLoading(true);

			const nodesRef = query(
				collection(db, 'utility_forms_v2_items'),
				where('formId', '==', formId),
				where('projectId', '==', projectId),
				where('type', '==', 'photos'),
				orderBy('parentsTitle'),
				orderBy('displayTitle')
			);

			const nodeCollection = await getDocs(nodesRef);

			const nodes = nodeCollection.docs.map(doc => {
				const data = doc.data() as FormNodeWithChildren;
				data.id = doc.id;
				return data;
			});

			setActivePhotoNodes(nodes);
			setLoading(false);
		} catch (error) {
			console.error(error);
			return [];
		}
	};

	const getSelectedPhotos = () => archivedPhotos.filter(photo => photo.selected);

	const togglePhoto = (photo: ArchivedPhotoV2, selected: boolean) =>
		setArchivedPhotos(photos =>
			photos.map(p => (p.id === photo.id ? { ...p, selected } : p))
		);

	const restorePhotos = async (targetNode: FormNode) => {
		setLoading(true);

		const selectedPhotos = getSelectedPhotos();

		try {
			const nodeRef = doc(db, 'utility_forms_v2_items', targetNode.id);
			await updateDoc(nodeRef, {
				value: arrayUnion(...selectedPhotos.map(photo => photo as PhotoV2)),
			});

			await Promise.all(
				selectedPhotos.map(async photo => {
					const photoRef = doc(db, 'utility_forms_v2_photos_archive', photo.id);
					await updateDoc(photoRef, { restored: true });
				})
			);

			await Promise.all(
				selectedPhotos.map(async node => {
					const nodeRef = doc(db, 'utility_forms_v2_photos_archive', node.id);
					await deleteDoc(nodeRef);
				})
			);
		} catch (error) {
			console.error(error);
			setSnackbarProps({
				open: true,
				message: 'Error restoring nodes. Please try again!',
				severity: 'error',
				hideDuration: 6000,
			});
		}

		setSnackbarProps({
			open: true,
			message: 'Nodes restored successfully!',
			severity: 'success',
			hideDuration: 6000,
		});

		clearSelected();
		setLoading(false);
	};

	const clearSelected = () =>
		setArchivedPhotos(photos => photos.map(photo => ({ ...photo, selected: false })));

	return {
		activePhotoNodes,
		archivedPhotos,
		getActivePhotoNodes,
		getArchivedPhotos,
		getSelectedPhotos,
		loading,
		restorePhotos,
		togglePhoto,
	};
};

export default useArchivedPhotos;
