import { InputBase, Select, MenuItem } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import ClearIcon from '@mui/icons-material/Clear';
import Spinner from '../Spinner';
import styled from 'styled-components';
import { YellowIconButton } from '../../styled-components/buttons';

type SearchbarProps = {
	query: string;
	setQuery: React.Dispatch<React.SetStateAction<string>>;

	filterOptions?: { value: string; name: string }[];
	filter?: string;
	setFilter?: React.Dispatch<React.SetStateAction<string>>;

	showSpinner?: boolean;
	onDebounceStart?: () => void;
	onDebounceEnd?: () => void;
	placeholder?: string;

	className?: string;
};

export default function Searchbar({
	query,
	setQuery,

	filterOptions,
	filter,
	setFilter,

	showSpinner,
	onDebounceStart = () => {},
	onDebounceEnd = () => {},
	placeholder = 'Search...',

	className,
}: SearchbarProps) {
	const hasFilter =
		Array.isArray(filterOptions) &&
		filterOptions.length &&
		typeof filter === 'string' &&
		setFilter;

	const [localQuery, setLocalQuery] = useState<string>(query);
	const [active, setActive] = useState(false);

	const inputRef = useRef<HTMLInputElement | null>(null);

	const debounce = useRef<number | null>(null);
	const [debouncing, setDebouncing] = useState(false);

	useEffect(() => {
		if (typeof debounce.current === 'number') window.clearTimeout(debounce.current);
		debounce.current = window.setTimeout(() => {
			debounce.current = null;
			setQuery(localQuery);
			setDebouncing(false);
		}, 500);
	}, [localQuery, setQuery]);

	const handleInputChange = (
		e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
	) => {
		setDebouncing(true);
		onDebounceStart();

		const value = e.currentTarget.value;
		setLocalQuery(value);

		if (value === query) {
			setDebouncing(false);
			onDebounceEnd();
		}
	};

	const clearQuery = () => {
		onDebounceStart();
		setLocalQuery('');
		inputRef.current?.focus();
	};

	return (
		<Container $active={active} className={className}>
			<SearchbarInput
				placeholder={placeholder}
				onChange={handleInputChange}
				value={localQuery}
				inputRef={inputRef}
				onFocus={() => setActive(true)}
				onBlur={() => setActive(false)}
			/>
			{localQuery || showSpinner ? (
				showSpinner || debouncing ? (
					<Spinner size={50} />
				) : (
					<YellowIconButton onClick={() => clearQuery()}>
						<ClearIcon />
					</YellowIconButton>
				)
			) : null}

			{hasFilter ? (
				<SearchbarSelect
					value={filter}
					onChange={e => {
						const filterValue = e.target.value;
						if (typeof filterValue === 'string') {
							setFilter(filterValue);
						}
					}}
					variant="standard">
					{filterOptions.map(option => {
						return (
							<MenuItem value={option.value} key={option.value}>
								{option.name}
							</MenuItem>
						);
					})}
				</SearchbarSelect>
			) : null}
		</Container>
	);
}

const Container = styled.div<{ $active: boolean }>`
	display: flex;
	gap: 5px;
	align-items: center;

	width: 500px;
	min-width: 230px;
	max-width: 100%;

	border-radius: 15px;
	border: 1px solid #ffb310;
	padding: 10px;
	height: 44px;

	outline: ${({ $active }) => ($active ? '1px solid #ffb310' : undefined)};

	&:hover {
		outline: 1px solid white;
		border-color: white;
	}
`;

const SearchbarInput = styled(InputBase)`
	color: #ffb310;
	flex-grow: 1;
`;

const SearchbarSelect = styled(Select)`
	color: #ffb310;

	> svg {
		fill: #ffb310;
	}
`;
