import { useContext, useEffect, useRef, useState } from 'react';
import { CategoryHeaderText } from '../../FormContainer.styles';
import { FormV2Context } from '../../../../context/FormV2Context.types';

/**
 * Returns the display title of the currently selected root-level node (category)
 * Handles updating the node's display title in firestore
 */
export default function NodeHeaderText() {
	const { currentNode, updateDisplayTitle } = useContext(FormV2Context);
	const [inputValue, setInputValue] = useState(currentNode?.displayTitle);
	const inputRef = useRef<HTMLInputElement>(null);

	useEffect(() => {
		setInputValue(currentNode?.displayTitle);
	}, [currentNode]);

	const handleInputChange = async (
		e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
	) => {
		const newValue = e.target.value;
		setInputValue(newValue);
		if (!currentNode) {
			return;
		}
		console.log(`updating value for node id: ${currentNode?.id}`);
		await updateDisplayTitle(currentNode.id, newValue);
	};

	const handleClick = () => {
		if (inputRef.current) {
			inputRef.current.focus();
		}
	};

	return currentNode ? (
		<CategoryHeaderText
			value={inputValue}
			onChange={handleInputChange}
			onClick={handleClick}
			ref={inputRef}
			sx={{ input: { textAlign: 'center' } }}
			fullWidth
		/>
	) : null;
}
