import { useState, createContext } from 'react';
import { ContainerProps } from '../types';
import { AlertColor } from '@mui/material';

interface SnackbarPropsType {
	open: boolean;
	message?: string;
	severity?: AlertColor;
	hideDuration?: number | null;
	onClick?: () => void;
	onButtonClose?: () => void;
}

type SnackContextType = {
	snackbarProps: SnackbarPropsType;
	setSnackbarProps: React.Dispatch<React.SetStateAction<SnackbarPropsType>>;
};

export const SnackContext = createContext<SnackContextType>({} as SnackContextType);

export const SnackProvider = ({ children }: ContainerProps) => {
	const [snackbarProps, setSnackbarProps] = useState<SnackbarPropsType>({
		open: false,
		message: '',
		severity: undefined,
		hideDuration: undefined,
		onClick: undefined,
	});

	return (
		<SnackContext.Provider
			value={{
				snackbarProps,
				setSnackbarProps,
			}}>
			{children}
		</SnackContext.Provider>
	);
};
