import React, { useContext, useEffect, useState } from 'react';
import { FormNode } from '../../../utils/types';
import { IconButton } from '@mui/material';
import ChatBubbleIcon from '@mui/icons-material/ChatBubble';
import CommentsDialog from '../../Dialogs/CommentsDialog/CommentsDialog';
import { SnackContext } from '../../../../../../context/SnackProvider';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../../../../../../firebase';
import styled from 'styled-components';

type CommentsButtonProps = {
	node: FormNode;
};

export default function CommentsButton({ node }: CommentsButtonProps) {
	const [dialogOpen, setDialogOpen] = useState(false);
	const { setSnackbarProps } = useContext(SnackContext);
	const [commentsCount, setCommentsCount] = useState(0);

	const updateCommentsCount = (newCount: number) => {
		setCommentsCount(newCount);
	};

	useEffect(() => {
		// Fetch the current number of comments when the component mounts or node.id changes
		const fetchCommentsCount = async () => {
			const itemRef = doc(db, `utility_forms_v2_items/${node.id}`);
			const docSnap = await getDoc(itemRef);

			if (docSnap.exists() && docSnap.data().comments) {
				setCommentsCount(docSnap.data().comments.length);
			}
		};

		fetchCommentsCount();
	}, [node.id]);

	const handleClick = async (_event: React.MouseEvent<HTMLButtonElement>) => {
		const itemRef = doc(db, `utility_forms_v2_items/${node.id}`);
		const docSnap = await getDoc(itemRef);

		if (!docSnap.exists()) {
			setSnackbarProps({
				open: true,
				message: 'Error, another user deleted this item',
				severity: 'error',
				hideDuration: 6000,
			});
		} else {
			setDialogOpen(true);
		}
	};

	const handleClose = async () => {
		setDialogOpen(false);
	};

	return (
		<>
			<IconButton onClick={handleClick} id={`${node.id}-comments`}>
				<ChatBubbleIcon />
				{commentsCount > 0 && <CommentsIndicator>{commentsCount}</CommentsIndicator>}
			</IconButton>
			{dialogOpen && (
				<CommentsDialog
					open={dialogOpen}
					onClose={handleClose}
					node={node}
					updateCommentsCount={updateCommentsCount}
				/>
			)}
		</>
	);
}

const CommentsIndicator = styled.div`
	position: absolute;
	top: 1px;
	right: 1px;
	background-color: #5bc236;
	width: 15px;
	height: 15px;
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
	color: white;
	font-size: 0.5rem;
`;
