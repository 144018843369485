import styled from 'styled-components';
import theme from '../../../styles/theme';
import { Flag, NodeType } from '../../screen-components/ProjectUtilityFormV2/utils/types';
import { getFlagColor } from './buttons/FlagButton';

export const WrapperContainer = styled.div<{
	nodeType: NodeType;
	level?: number;
	flag: Flag;
	isSelected: boolean;
}>`
	display: flex;
	flex-direction: column;
	gap: 4px;
	padding: ${({ nodeType, level }) =>
		nodeType === 'node' || (level && level > 0) ? '0  8px' : '8px'};
	width: 100%;
	border-radius: 10px;

	background-color: ${({ nodeType }) =>
		nodeType !== 'placeholder' ? theme.palette.common.black : 'transparent'};

	border: ${({ nodeType, level, flag }) =>
		`1px solid ${getFlagColor(
			flag,
			nodeType === 'node' || level === 0 ? 'grey' : 'transparent'
		)}`};

	box-shadow: ${({ isSelected }) =>
		isSelected ? `${theme.palette.primary.main} 0px 0px 5px 2.5px` : ''};
`;

export const WrapperHeader = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: calc(100% + 8px);
`;

export const WrapperTitleContainer = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	flex: 1;
	gap: 8px;
`;

export const WrapperTitle = styled.div`
	font-weight: bold;
	color: ${theme.palette.common.white};
	font-size: 14px;
	line-height: 20px;
`;

export const WrapperInfo = styled.div`
	color: ${theme.palette.grey[300]};
	font-size: 12px;
	line-height: 16px;
`;

export const WrapperNodeContainer = styled.div`
	display: flex;
	width: 100%;
`;

export const WrapperNode = styled.div`
	width: 100%;
`;

export const WrapperIconButton = styled.div`
	width: 40px;
`;
