import { MenuItem, TextField } from '@mui/material';
import styled from 'styled-components';
import { ProjectStatus } from '../../../../types';
import { SelectedStatus } from '..';
import { projectStatusText } from '../../../../utils';

export default function Dropdown({
	selectedStatus,
	setSelectedStatus,
	options,
	statuses,
}: {
	selectedStatus: SelectedStatus;
	setSelectedStatus: React.Dispatch<React.SetStateAction<SelectedStatus>>;

	options: { icon: JSX.Element; text: string; status: string }[];
	statuses: ProjectStatus[];
}) {
	return (
		<div className="status-dropdown">
			<Container>
				<span>View:</span>
				<TextField
					value={selectedStatus}
					onChange={e => {
						setSelectedStatus(e.target.value);
					}}
					select
					variant="standard"
					fullWidth>
					{options.map(option => (
						<MenuItem value={option.status} key={option.status}>
							{option.status}
						</MenuItem>
					))}
					{statuses.map(status => (
						<MenuItem value={status} key={status}>
							{projectStatusText(status)}
						</MenuItem>
					))}
				</TextField>
			</Container>
		</div>
	);
}

const Container = styled.div`
	display: flex;
	gap: 10px;
	align-items: center;

	min-width: 0px;
	width: 100%;
	padding: 10px 15px;

	background-color: #ffb310;
	color: black;
	border-radius: 30px;

	@media (min-width: 1024px) {
		display: none;
		padding: 10px 0px;
	}
`;
