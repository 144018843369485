import { useState } from 'react';
import { createUtilityForm } from '../../firebase';
import {
	newElectrical,
	newFireProtection,
	newMechanical,
	newPlumbing,
} from '../../components/screen-components/ProjectUtilityForm/utils/BaseCategories';

const useAddTemplateForm = () => {
	const [pickTemplateDialogOpen, setPickTemplateDialogOpen] = useState(false);
	const [creatingForm, setCreatingForm] = useState(false);

	const closePickTemplateDialog = () => setPickTemplateDialogOpen(false);

	const openPickTemplateDialog = () => setPickTemplateDialogOpen(true);

	const pickTemplate = () => closePickTemplateDialog();

	const callCreateLegacyForm = async (projectId: string, existingFormNames: string[]) => {
		setCreatingForm(true);

		let newFormName = 'Form';
		let count = 1;

		while (existingFormNames?.includes(newFormName)) {
			count += 1;
			newFormName = `Form-${count}`;
		}

		try {
			const response = await createUtilityForm({
				projectId: projectId,
				formName: newFormName,
				formData: [newElectrical(), newMechanical(), newPlumbing(), newFireProtection()],
			});
			return response.data;
		} catch (err) {
			console.log(err);
		}
	};

	return {
		callCreateLegacyForm,
		closePickTemplateDialog,
		creatingForm,
		openPickTemplateDialog,
		pickTemplateDialogOpen,
		pickTemplate,
	};
};

export default useAddTemplateForm;
