import { useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';

import { ProjectContext } from '../context/ProjectProvider';

import Layout from '../components/reusable-components/Layout';
import LoadingScreen from '../components/reusable-components/LoadingScreen';

export default function Clients() {
	const { cachedClients } = useContext(ProjectContext);

	useEffect(() => {
		document.title = 'Clients | Robotic Imaging';
	}, []);

	return (
		<Layout title="Clients">
			{cachedClients ? (
				<div
					style={{
						color: '#ffb310',
						width: '100%',
						padding: '20px 10px',
						overflowY: 'auto',

						display: 'flex',
						gap: '10px',
						flexWrap: 'wrap',
					}}>
					{cachedClients.length ? (
						cachedClients.map((client, i) => (
							<div
								key={i}
								style={{ width: 'calc((100% - 40px) / 5)', height: 'fit-content' }}>
								<Link to={`/clients/${client}`} className="clickable-link">
									{client}
								</Link>
							</div>
						))
					) : (
						<span>No clients to display...</span>
					)}
				</div>
			) : (
				<LoadingScreen message="Loading clients..." />
			)}
		</Layout>
	);
}
