import { useContext, useState } from 'react';
import { IconButton } from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import styled from 'styled-components';
import { ProjectContext } from '../../../../context/ProjectProvider';

export default function ProjectIconButton({
	projectId,
	children,
}: {
	projectId: string;
	children?: React.ReactNode;
}) {
	const { setProjectsSelected } = useContext(ProjectContext);

	const [hover, setHover] = useState(false);

	return (
		<StyledIconButton
			$hover={hover}
			onMouseEnter={() => setHover(true)}
			onMouseLeave={() => setHover(false)}
			onClick={() => {
				setProjectsSelected(prev => prev.filter(proj => proj.id !== projectId));
			}}>
			{!hover ? children : <CancelIcon />}
		</StyledIconButton>
	);
}

const StyledIconButton = styled(IconButton)<{ $hover: boolean }>`
	padding: 2px;
	color: ${({ $hover }) => (!$hover ? 'black' : '#ffb310')};
	transition: 0.2s;
`;
