import {
	UploadTask,
	deleteObject,
	getDownloadURL,
	getStorage,
	ref,
	uploadBytesResumable,
} from 'firebase/storage';
import {
	ReactNode,
	createContext,
	useCallback,
	useContext,
	useEffect,
	useRef,
	useState,
} from 'react';
import { Category, Item, Photo, UtilityFormStatus } from './types';
import { storage } from '../../../firebase';
import { cloneDeep } from 'lodash';
import { SnackContext } from '../../../context/SnackProvider';

export type UploadObject = {
	[key: string]: UploadQueueItem[];
};

export type formSubmitObject = {
	[key: string]: boolean;
};

export type UploadQueueItem = {
	formId: string;
	photo: Photo;
	category: string;
	item: string;
	categoryId: string;
	itemId: string;
	categoryIndex: number;
	itemIndex: number;

	cachedFormState: Category[];
	setItemState: React.Dispatch<React.SetStateAction<Item>>;
	wrappedDebounce: (status?: UtilityFormStatus, cachedFormState?: Category[]) => void;
};

export type UploadContextType = {
	uploadQueueState: UploadObject;
	setUploadQueueState: React.Dispatch<React.SetStateAction<UploadObject>>;
	deleteTask: (formId: string, categoryId: string, itemId?: string) => void;
	deleteFromStorage: (filePath: string) => void;
	currentUploadTask: { task: UploadTask; hash: string } | null;
	currentUploadProgress: number;
	queueStateCleanup: (removedItemId: string, formId: string) => void;
	mountedFormId: React.MutableRefObject<string | null>;
	globalCachedForm: Category[] | null;
	setGlobalCachedForm: React.Dispatch<React.SetStateAction<Category[] | null>>;
	formSubmitState: formSubmitObject;
	setFormSubmitState: React.Dispatch<React.SetStateAction<formSubmitObject>>;
};

export const UploadContext = createContext<UploadContextType>({} as UploadContextType);

export const UploadProvider = ({ children }: { children: ReactNode }) => {
	const { setSnackbarProps } = useContext(SnackContext);

	const [uploadQueueState, setUploadQueueState] = useState<UploadObject>({});
	const [currentUploadTask, setCurrentUploadTask] = useState<{
		task: UploadTask;
		hash: string;
	} | null>(null);
	const [currentUploadProgress, setCurrentUploadProgress] = useState(0);
	const [globalCachedForm, setGlobalCachedForm] = useState<Category[] | null>(null);
	const [formSubmitState, setFormSubmitState] = useState<formSubmitObject>({});

	/**
	 * Cleanup function that should be called after items in the uploadQueueState finish uploading or get canceled
	 */
	const queueStateCleanup = useCallback((queueItemHash: string, formId: string) => {
		setUploadQueueState(prev => {
			if (prev[formId]) {
				const clone = cloneDeep(prev);

				// If the upload queue for the given form id has items in it, remove the first item b/c
				// it has just finished uploading or has been cancelled
				if (clone[formId].length) {
					clone[formId].shift();
				}

				// If the upload queue for the given form id has no items, remove the empty array from the
				// uploadQueueState and remove the form from the formSubmitState
				if (!clone[formId].length) {
					delete clone[formId];
					setFormSubmitState(prev => {
						delete prev[formId];
						return { ...prev };
					});
				}
				return clone;
			} else return prev;
		});

		// If the item that has finished uploading or has been cancelled is the currentUploadTask,
		// set the currentUploadTask to null
		setCurrentUploadTask(prev => {
			if (queueItemHash === prev?.hash) {
				setCurrentUploadProgress(0);
				return null;
			} else return prev;
		});
	}, []);

	/**
	 * Function that uploads a file
	 */
	const uploadFile = useCallback(
		(formId: string, queueItem: UploadQueueItem) => {
			const photo = queueItem.photo;
			const categoryId = queueItem.categoryId;
			const itemId = queueItem.itemId;

			const filePath = `utility_forms/${formId}/${categoryId}/${itemId}/photos/`;
			const storageRef = ref(storage, filePath + photo.name);
			const uploadTask = uploadBytesResumable(storageRef, photo.file as File);

			setCurrentUploadTask({ task: uploadTask, hash: queueItem.photo.hash });

			uploadTask.on(
				'state_changed',
				snapshot => {
					const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
					setCurrentUploadProgress(progress);

					switch (snapshot.state) {
						case 'paused':
							console.log('Upload is paused');
							break;
						case 'running':
							break;
					}
				},
				error => {
					// Handle unsuccessful uploads
					console.error(error);
					setSnackbarProps({
						open: true,
						severity: 'warning',
						message: 'Canceled upload!',
					});
				},
				async () => {
					const downloadUrl = await getDownloadURL(uploadTask.snapshot.ref);
					setCurrentUploadProgress(0);
					console.log('File available at', downloadUrl);

					if (mountedFormId.current === queueItem.formId) {
						// queueItem's form is currently mounted
						queueItem.setItemState(prev => {
							const updatedPhotos: Photo[] = [];

							prev.photos.forEach(photo => {
								if (photo.hash === queueItem.photo.hash) {
									updatedPhotos.push({
										hash: queueItem.photo.hash,
										name: photo.name,
										url: downloadUrl,
									});
								} else {
									updatedPhotos.push(photo);
								}
							});
							const updatedItem = {
								...prev,
								photos: updatedPhotos,
							};
							return { ...updatedItem };
						});
					} else {
						// queueItem's form is not mounted
						const categoryIndex = queueItem.categoryIndex;
						const itemIndex = queueItem.itemIndex;
						const photo: Photo = {
							hash: queueItem.photo.hash,
							name: queueItem.photo.name,
							url: downloadUrl,
						};

						const photoIdx = queueItem.cachedFormState[categoryIndex].items[
							itemIndex
						].photos.findIndex(oldPhoto => oldPhoto.hash === photo.hash);
						queueItem.cachedFormState[categoryIndex].items[itemIndex].photos.splice(
							photoIdx,
							1,
							photo
						);

						setGlobalCachedForm(queueItem.cachedFormState);

						// TODO - Think of better way to access the formSubmitState and
						// uploadQueueState within this useCallback
						setFormSubmitState(prev => {
							setUploadQueueState(uploadQueue => {
								if (prev[queueItem.formId] && uploadQueue[formId]?.length === 1)
									queueItem.wrappedDebounce('uploaded', queueItem.cachedFormState);
								else queueItem.wrappedDebounce(undefined, queueItem.cachedFormState);

								return uploadQueue;
							});
							return prev;
						});

						console.log(`${queueItem.photo.name} uploaded...`);
					}

					queueStateCleanup(photo.hash, formId);
				}
			);
		},
		[queueStateCleanup, setSnackbarProps]
	);

	// useEffect that starts the upload process for selected files
	useEffect(() => {
		if (Object.keys(uploadQueueState).length) {
			if (!currentUploadTask) {
				const firstFormId = Object.keys(uploadQueueState)[0];
				uploadFile(firstFormId, uploadQueueState[firstFormId][0]);
			}
		}
	}, [uploadQueueState, currentUploadTask, uploadFile]);

	//Function to delete the files from firebase storage
	const deleteFromStorage = async (filePath: string) => {
		const storage = getStorage();
		const fileRef = ref(storage, filePath);

		try {
			await deleteObject(fileRef);
		} catch (err) {
			console.log(err);
		}
	};

	const deleteTask = (formId: string, categoryId: string, itemId?: string) => {
		// Cancelled task flag to keep track of whether an upload task was cancelled.
		let cancelledCurrentTask = false;
		if (uploadQueueState[formId]) {
			// Delete Item
			if (itemId) {
				// Checks if the current upload task belongs to the item that is to be deleted
				if (
					uploadQueueState[formId].some(
						uplodQueueItem =>
							uplodQueueItem.photo.hash === currentUploadTask?.hash &&
							uplodQueueItem.categoryId === categoryId &&
							uplodQueueItem.itemId === itemId
					)
				) {
					currentUploadTask?.task.cancel();
					cancelledCurrentTask = true;
				}
				// Removes all upload queue tasks for the item that is to be deleted
				setUploadQueueState((prev: UploadObject) => {
					prev[formId] = prev[formId].filter(
						(uploadObj: UploadQueueItem) =>
							!(uploadObj.categoryId === categoryId && uploadObj.itemId === itemId)
					);
					if (!prev[formId].length) {
						delete prev[formId];
					}
					return { ...prev };
				});
			}
			//Delete Category
			else {
				// Checks if the current upload task belongs to the category that is to be deleted
				if (
					uploadQueueState[formId].some(
						uplodQueueItem =>
							uplodQueueItem.photo.hash === currentUploadTask?.hash &&
							uplodQueueItem.categoryId === categoryId
					)
				) {
					currentUploadTask?.task.cancel();
					cancelledCurrentTask = true;
				}
				// Removes all upload queue tasks for the category that is to be deleted
				setUploadQueueState((prev: UploadObject) => {
					prev[formId] = prev[formId].filter(
						(uploadObj: UploadQueueItem) => !(uploadObj.categoryId === categoryId)
					);
					if (!prev[formId].length) {
						delete prev[formId];
					}
					return { ...prev };
				});
			}
			//Clean up if upload task was cancelled.
			if (cancelledCurrentTask) {
				setCurrentUploadTask(null);
				setCurrentUploadProgress(0);
			}
		}
	};

	// This useRef is used to control the completion callback of successful
	// photo uploads. We use a useRef instead of a useState becase the callback
	// snapshots the useState hook and doesn't get the most current one.
	const mountedFormId = useRef<string | null>(null);

	return (
		<UploadContext.Provider
			value={{
				uploadQueueState,
				setUploadQueueState,
				deleteTask,
				deleteFromStorage,
				currentUploadTask,
				currentUploadProgress,
				queueStateCleanup,
				mountedFormId,
				globalCachedForm,
				setGlobalCachedForm,
				formSubmitState,
				setFormSubmitState,
			}}>
			{children}
		</UploadContext.Provider>
	);
};
