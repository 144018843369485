import { Row } from 'react-table';
import { ProjectData } from '../../../../../../../types';
import { reorderDate } from '../../../../../../../utils';
import StringTextField from '../StringTextField';

export default function LastContactedCell({
	row,
	rowEditing,
}: {
	row: Row<ProjectData>;
	rowEditing: boolean;
}) {
	const dateStr = row.original.lastContacted;

	return !rowEditing ? (
		<span>{reorderDate(dateStr)}</span>
	) : (
		<StringTextField
			type="date"
			projectId={row.original.id as string}
			defaultValue={dateStr || ''}
			field="lastContacted"
			isProjectPage={true}
		/>
	);
}
