import { useEffect, useState } from 'react';

const useSelecting = () => {
	const [selectedIds, setSelectedIds] = useState<string[]>([]);
	const [isSelecting, setIsSelecting] = useState<boolean>(false);

	const addSelectedId = (id: string) => {
		setSelectedIds(prev => {
			if (prev.includes(id)) {
				return prev;
			}
			return [...prev, id];
		});
	};

	const removeSelectedId = (id: string) => {
		setSelectedIds(prev => prev.filter(selectedId => selectedId !== id));
	};

	const clearSelectedIds = () => setSelectedIds([]);

	useEffect(() => {
		if (!isSelecting) setSelectedIds([]);
	}, [isSelecting]);

	return {
		addSelectedId,
		clearSelectedIds,
		isSelecting,
		removeSelectedId,
		selectedIds,
		setIsSelecting,
	};
};

export default useSelecting;
