import { useContext } from 'react';
import styled from 'styled-components';
import Spinner from '../../reusable-components/Spinner';
import { LoadingContext } from '../../../context/LoadingProvider';

const FormsLoadingLayer = ({ showOverlay }: { showOverlay: boolean }) => {
	const { loadingStatus } = useContext(LoadingContext);

	return showOverlay ? (
		<LoadingPositionClass>
			<h2>{loadingStatus?.title}</h2>
			<Spinner size={140} />
			<h3>{loadingStatus?.detail}</h3>
		</LoadingPositionClass>
	) : null;
};

const LoadingPositionClass = styled.div`
	left: 0;
	right: 0;
	height: 100%;
	width: 100%;
	background-color: black;
	opacity: 75%;
	position: fixed;
	width: 100%;
	z-index: 11;
	text-align: center;
	color: white;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
`;

export default FormsLoadingLayer;
