import { TextField } from '@mui/material';
import styled from 'styled-components';
import { baseFontSize } from '../../../styles/theme';

export const ProjectInfoTextField = styled(TextField)`
	.MuiOutlinedInput-root {
		font-size: ${baseFontSize};
		padding: 0;

		fieldset,
		.MuiSelect-select,
		.MuiOutlinedInput-notchedOutline {
			border-color: #ffb310;
			color: #ffb310;
		}

		&:hover fieldset,
		.MuiSelect-select {
			border-width: 2px;
			border-color: white;
		}

		input,
		textarea,
		.MuiSelect-select {
			box-sizing: border-box;
			color: #ffb310;
			padding: 3px 5px;
			min-height: 26px;
			height: fit-content;
		}

		.MuiAutocomplete-endAdornment .MuiButtonBase-root .MuiSvgIcon-root {
			color: #ffb310;
		}
	}

	#lastContacted {
		cursor: text;
		&::-webkit-calendar-picker-indicator {
			cursor: pointer;
			filter: invert(73%) sepia(94%) saturate(1834%) hue-rotate(349deg) brightness(104%)
				contrast(101%);
			&:hover {
				filter: invert(1);
			}
		}
	}

	.MuiSelect-icon {
		color: #ffb310;
	}
`;
