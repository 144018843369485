import { useState, useContext, useEffect } from 'react';
import DeliverableDropzone from '../components/DeliverableDropzone';
import { ProjectContext } from '../../../../context/ProjectProvider';
import styled from 'styled-components';
import Carousel from '../../Carousel';
import DeliverableButtonsOverlay from '../components/DeliverableButtonsOverlay';
import { CarouselImg, CarouselPdf, CarouselPdfPlaceholder } from '../../Carousel/Styled';
import { useWindowSize } from '../../../../screens/Welcome';

interface Link {
	link: string;
	type: 'photo' | 'pdf';
}

export default function PhotosBox({
	downloadOnly,
	anonUpload,
}: {
	downloadOnly: boolean;
	anonUpload: boolean;
}) {
	const { project } = useContext(ProjectContext);

	const [validLinks, setValidLinks] = useState<Link[]>([]);
	const [showPhotoFilesDialog, setShowPhotoFilesDialog] = useState(false);
	const { width } = useWindowSize();

	const locked = downloadOnly && project?.projectStatus === 'Pending Payment';

	const isAnon = downloadOnly || anonUpload;
	const showCarousel = isAnon ? width >= 1024 : true;

	const fileIsPhoto = (filename: string) => {
		return /\.(jpg|JPG|jpeg|JPEG|png|PNG|webp|avif|gif|GIF|svg|SVG)$/.test(filename);
	};
	const fileIsPdf = (filename: string) => {
		return filename.toLowerCase().endsWith('.pdf');
	};

	useEffect(() => {
		function getValidLinks() {
			if (project?.photos?.length) {
				const urlArr: Link[] = [];
				for (const photo of project.photos) {
					if (fileIsPhoto(photo.filename)) {
						urlArr.push({ link: photo.downloadUrl, type: 'photo' });
					} else if (fileIsPdf(photo.filename) && width >= 1024) {
						urlArr.push({ link: photo.downloadUrl, type: 'pdf' });
					}
				}
				setValidLinks(urlArr);
			} else {
				setValidLinks([]);
			}
		}
		getValidLinks();
	}, [project?.photos, width]);

	return downloadOnly && !validLinks.length && !project?.photos?.length ? null : (
		<DeliverableDropzone
			deliverableKey="photos"
			showingDialog={showPhotoFilesDialog}
			setShowingDialog={setShowPhotoFilesDialog}
			downloadOnly={downloadOnly}
			anonUpload={anonUpload}>
			{validLinks.length && !locked && showCarousel ? (
				<PhotosContainer id="deliverable-photos">
					<Carousel lazy lazyPreload={3}>
						{validLinks.map((link, i) => {
							if (link.type === 'photo') {
								return <CarouselImg src={link.link} key={i} alt={link.link} />;
							} else {
								return (
									<CarouselPdf data={link.link} type="application/pdf" key={i}>
										<CarouselPdfPlaceholder>
											<span>Could not load PDF Viewer:</span>
											<a href={link.link}>Click to view PDF</a>
										</CarouselPdfPlaceholder>
									</CarouselPdf>
								);
							}
						})}
					</Carousel>
					<DeliverableButtonsOverlay
						deliverableKey="photos"
						downloadOnly={downloadOnly}
						setShowingDialog={setShowPhotoFilesDialog}
					/>
				</PhotosContainer>
			) : null}
		</DeliverableDropzone>
	);
}

const PhotosContainer = styled.div`
	width: 100%;
	height: 100%;
`;
