import { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { UtilityForm } from '../ProjectUtilityForm/types';
import { useSearchParams } from 'react-router-dom';
import { filterFormCategories } from './utils';
import { FormControlLabel, MenuItem, Switch, TextField } from '@mui/material';
import Carousel from '../../reusable-components/Carousel';
import GalleryImg from './components/GalleryImg';
import useFormIndexes from './hooks/useFormIndexes';

export default function FormGallery({ form }: { form: UtilityForm | null }) {
	const [searchParams, setSerachParams] = useSearchParams();
	const filteredCategories = useMemo(
		() => (form?.formData ? filterFormCategories(form.formData) : []),
		[form?.formData]
	);

	const categoryId = searchParams.get('categoryId');
	const foundCatIdx = filteredCategories.findIndex(cat => cat.categoryId === categoryId);
	const initCatIdx = foundCatIdx !== -1 ? foundCatIdx : 0;

	const itemId = searchParams.get('itemId');
	const initialItems = filteredCategories[initCatIdx].items;
	const foundItemIdx = initialItems.findIndex(item => item.itemId === itemId);
	const initItemIdx = foundItemIdx !== -1 ? foundItemIdx : 0;

	const photoHash = searchParams.get('photoHash');
	const initialPhotos = initialItems[initItemIdx].photos;
	const foundPhotoIdx = initialPhotos.findIndex(photo => photo.hash === photoHash);
	const initPhotoIdx = foundPhotoIdx !== -1 ? foundPhotoIdx : 0;

	const { formIndexes, setFormIndexes } = useFormIndexes({
		categoryIdx: initCatIdx,
		itemIdx: initItemIdx,
		photoIdx: initPhotoIdx,
	});

	const canGoNext =
		formIndexes.itemIdx + 1 < filteredCategories[formIndexes.categoryIdx].items.length ||
		formIndexes.categoryIdx + 1 < filteredCategories.length;
	const canGoPrev = formIndexes.itemIdx > 0 || formIndexes.categoryIdx > 0;

	const overflow = () => {
		if (canGoNext) {
			if (
				formIndexes.itemIdx ===
				filteredCategories[formIndexes.categoryIdx].items.length - 1
			) {
				setFormIndexes({
					categoryIdx: formIndexes.categoryIdx + 1,
					itemIdx: 0,
					photoIdx: 0,
				});
			} else {
				setFormIndexes({
					itemIdx: formIndexes.itemIdx + 1,
					photoIdx: 0,
				});
			}
		}
	};

	const underflow = () => {
		if (canGoPrev) {
			if (formIndexes.itemIdx === 0) {
				const newCatIdx = formIndexes.categoryIdx - 1;
				const newItemIdx = filteredCategories[newCatIdx].items.length - 1;
				const newPhotoIdx =
					filteredCategories[newCatIdx].items[newItemIdx].photos.length - 1;
				setFormIndexes({
					categoryIdx: newCatIdx,
					itemIdx: newItemIdx,
					photoIdx: newPhotoIdx,
				});
			} else {
				const newItemIdx = formIndexes.itemIdx - 1;
				const newPhotoIdx =
					filteredCategories[formIndexes.categoryIdx].items[newItemIdx].photos.length - 1;
				setFormIndexes({
					itemIdx: newItemIdx,
					photoIdx: newPhotoIdx,
				});
			}
		}
	};

	useEffect(() => {
		setSerachParams(prev => {
			prev.set('categoryId', filteredCategories[formIndexes.categoryIdx].categoryId);
			prev.set(
				'itemId',
				filteredCategories[formIndexes.categoryIdx].items[formIndexes.itemIdx].itemId
			);
			prev.set(
				'photoHash',
				filteredCategories[formIndexes.categoryIdx].items[formIndexes.itemIdx].photos[
					formIndexes.photoIdx
				].hash
			);
			return prev;
		});
	}, [filteredCategories, formIndexes, setSerachParams]);

	const [viewPDF, setViewPDF] = useState(false);

	return !form ? (
		<ErrorContainer>
			<ErrorIcon />
			<h3>Form not found...</h3>
		</ErrorContainer>
	) : (
		<Container>
			<Controls>
				<Selector>
					<h3>{`Category ${formIndexes.categoryIdx + 1}/${
						filteredCategories.length
					}`}</h3>
					<TextField
						select
						value={formIndexes.categoryIdx}
						onChange={e =>
							setFormIndexes({
								categoryIdx: Number(e.target.value),
								itemIdx: 0,
								photoIdx: 0,
							})
						}
						fullWidth>
						{filteredCategories.map((category, idx) => (
							<MenuItem value={idx} key={category.categoryId}>
								{category.displayTitle || 'Unnamed Category'}
							</MenuItem>
						))}
					</TextField>
				</Selector>

				<Selector>
					<h3>{`Item ${formIndexes.itemIdx + 1}/${
						filteredCategories[formIndexes.categoryIdx].items.length
					}`}</h3>
					<TextField
						select
						value={formIndexes.itemIdx}
						onChange={e =>
							setFormIndexes({
								itemIdx: Number(e.target.value),
								photoIdx: 0,
							})
						}
						fullWidth>
						{filteredCategories[formIndexes.categoryIdx].items.map((item, idx) => (
							<MenuItem value={idx} key={item.itemId}>
								{item.displayTitle || 'Unnamed Item'}
							</MenuItem>
						))}
					</TextField>
				</Selector>

				<Selector>
					<h3>{`Photo ${formIndexes.photoIdx + 1}/${
						filteredCategories[formIndexes.categoryIdx].items[formIndexes.itemIdx].photos
							.length
					}`}</h3>
					<TextField
						select
						value={formIndexes.photoIdx}
						onChange={e =>
							setFormIndexes({
								photoIdx: Number(e.target.value),
							})
						}
						fullWidth>
						{filteredCategories[formIndexes.categoryIdx].items[
							formIndexes.itemIdx
						].photos.map((photo, idx) => (
							<MenuItem value={idx} key={photo.hash}>
								{photo.name}
							</MenuItem>
						))}
					</TextField>
				</Selector>

				{form.downloadUrl ? (
					<SwitchContiner
						control={
							<Switch
								value={viewPDF}
								onChange={e => setViewPDF(e.currentTarget.checked)}
							/>
						}
						label="Toggle PDF Overlay"
					/>
				) : null}
			</Controls>

			<CarouselContainer>
				<Carousel
					lazy
					lazyPreload={1}
					controlledIndex={formIndexes.photoIdx}
					setControlledIndex={newIndex => setFormIndexes({ photoIdx: newIndex })}
					showNextButton={canGoNext}
					showPrevButton={canGoPrev}
					overflow={overflow}
					underflow={underflow}
					swipe={false}
					captureWindowKeypresses>
					{filteredCategories[formIndexes.categoryIdx].items[
						formIndexes.itemIdx
					].photos.map((photo, idx) => (
						<GalleryImg
							photo={photo}
							key={photo.hash}
							photoIndex={idx}
							currentIndex={formIndexes.photoIdx}
						/>
					))}
				</Carousel>

				{viewPDF ? <PDFOverlay src={form.downloadUrl} /> : null}
			</CarouselContainer>
		</Container>
	);
}

const ErrorContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 10px;
	align-items: center;
	justify-content: center;

	color: #ffb310;
	height: 100%;
`;

const ErrorIcon = styled(ErrorOutlineIcon)`
	height: 150px;
	width: 150px;
`;

const Container = styled.div`
	display: flex;
	flex-direction: column;
	height: 100%;
`;

const Controls = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 10px;
	padding: 10px;

	> * {
		flex-grow: 1;
		max-width: 350px;
	}

	@media screen and (max-width: 767px) {
		flex-direction: column;

		> div {
			max-width: 100%;
			width: 100%;

			.MuiSelect-select {
				padding: 5px;
			}
		}
	}
`;

const Selector = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	color: #ffb310;

	> h3 {
		margin: 0;
	}

	> div.MuiFormControl-root {
		background-color: white;
	}
`;

const SwitchContiner = styled(FormControlLabel)`
	color: #ffb310;
	flex-grow: 0;
	padding-left: 12px;

	.MuiSwitch-track {
		background-color: rgba(255, 255, 255, 0.5);
	}
`;

const CarouselContainer = styled.div`
	flex-grow: 1;
	position: relative;
`;

const PDFOverlay = styled.iframe`
	position: absolute;
	top: 0px;
	left: 0px;
	width: 100%;
	height: 100%;
	z-index: 100;
`;
