import {
	deleteObject,
	getDownloadURL,
	ref,
	uploadBytesResumable,
} from 'firebase/storage';
import { storage } from '../../../../firebase';
import { PhotoV2, QueuedPhotoV2 } from '../utils/types';

export const formsPath = 'utility_forms_v2';

export const useV2Storage = () => {
	// Starts uploading photos in the photo upload queue
	const uploadFromQueue = async (
		queuedPhoto: QueuedPhotoV2,
		onError: (queuedPhoto: QueuedPhotoV2) => void,
		onProgress: (name: string, progress: number) => void
	) => {
		if (!queuedPhoto || !queuedPhoto.photo.file || !queuedPhoto.photo.name) return;

		try {
			const filePath = `${formsPath}/${queuedPhoto.formId}/photos/${queuedPhoto.photo.name}`;
			const fileRef = ref(storage, filePath);
			const response = await fetch(queuedPhoto.photo.file);
			const blob = await response.blob();

			const uploadTask = uploadBytesResumable(fileRef, blob, {
				contentDisposition: `inline; filename="${queuedPhoto.photo.name}"`,
				customMetadata: {
					originalFilename: queuedPhoto.photo.originalName,
					projectId: queuedPhoto.projectId,
				},
			});

			uploadTask.on(
				'state_changed',
				snapshot => {
					const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
					onProgress(queuedPhoto.photo.name, progress);
				},
				error => {
					console.error(error);
					onError(queuedPhoto);
				},
				() => {
					getDownloadURL(uploadTask.snapshot.ref).then(downloadURL => {
						updatePhotoQueue(queuedPhoto, downloadURL);
					});
				}
			);
		} catch (e) {
			console.error(e);
			onError(queuedPhoto);
		}
	};

	const updatePhotoQueue = async (photoToUpdate: QueuedPhotoV2, url: string) => {
		photoToUpdate.onFinishUpload({
			...photoToUpdate,
			status: 'UPLOADED',
			photo: { ...photoToUpdate.photo, url: url },
		});
	};

	// Deletes a form photo from firebase storage
	const deleteFormPhoto = async (formId: string, photo: PhotoV2): Promise<void> => {
		if (!photo.name) return;

		const filePath = `${formsPath}/${formId}/photos/${photo.name}`;
		const fileRef = ref(storage, filePath);
		try {
			await deleteObject(fileRef);
		} catch (e) {
			console.error(e);
		}
	};

	return {
		deleteFormPhoto,
		uploadFromQueue,
	};
};
