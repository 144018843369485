import { TextField } from '@mui/material';
import styled from 'styled-components';

export const StyledInputField = styled(TextField)`
	display: flex;
	align-self: center;
	max-height: 45px;
	input {
		color: black;
		font-size: 1.5em;
		// max-height: 35px;
		padding: 0px;
		overflow: visible;
		text-align: center;
	}
	fieldset {
		border: none;
	}
`;

export const InputUnderline = styled.div`
	height: 2px;
	background-color: #ffb310;
	margin-top: -2px;
`;

export const FormTitle = styled.span`
	font-family: 'Epilogue';
	color: black;
	font-weight: 400;
	font-size: 1.5em;
	display: flex;
	align-self: center;
`;
