import styled from 'styled-components';
import { FormNode } from '../../../utils/types';
import { getFlagStyle } from '../../../utils/utils';
import LazyLoad from 'react-lazy-load';
import { useState } from 'react';

type ItemWrapperProps = {
	itemNode: FormNode;
	children?: React.ReactNode;
};

export default function ItemWrapper({ itemNode, children }: ItemWrapperProps) {
	const [loaded, setLoaded] = useState(false);

	return (
		<div id={`${itemNode.id}-scroll`}>
			{/* Only lazy load non-containers because if containers are lazy loaded,
            the child element placeholder elements will not be rendered. */}
			{itemNode.type !== 'node' ? (
				<LazyFormItem
					$node={itemNode}
					$contentVisible={loaded}
					onContentVisible={() => setLoaded(true)}>
					<Container
						style={getFlagStyle(itemNode.flag)}
						id={itemNode.id}
						$level={itemNode.level || 0}>
						{children}
					</Container>
				</LazyFormItem>
			) : (
				<Container
					style={getFlagStyle(itemNode.flag)}
					id={itemNode.id}
					$level={itemNode.level || 0}>
					{children}
				</Container>
			)}
		</div>
	);
}

export const LazyFormItem = styled(LazyLoad)<{
	$node: FormNode;
	$contentVisible: boolean;
}>`
	height: ${({ $node, $contentVisible }) => {
		if ($contentVisible) return 'fit-content';
		else {
			switch ($node.type) {
				case 'node':
					return 'fit-content';
				case 'photos':
					return '300px';
				default:
					return '100px';
			}
		}
	}};
`;

const Container = styled.div<{ $level: number }>`
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: 8px;

	background-color: ${({ $level }) => ($level === 0 ? '#eef0f5' : '#fefefe')};
	box-shadow: 0px 2px 5px 1px rgba(0, 0, 0, 0.15);

	min-height: 50px;
	width: 100%;
	padding: 20px;
	border-radius: 20px;

	& > div {
		width: 100%;
	}
`;
