import { useContext, useState } from 'react';
import { AuthContext } from '../../../../../../../context/AuthProvider';
import { SharedUser } from '../../types';
import { Avatar, IconButton } from '@mui/material';
import ListItemText from '@mui/material/ListItemText';
import PersonIcon from '@mui/icons-material/Person';
import DeleteIcon from '@mui/icons-material/Delete';
import { SnackContext } from '../../../../../../../context/SnackProvider';
import { ProjectContext } from '../../../../../../../context/ProjectProvider';
import { memberDelete } from '../../../../../../../firebase/firestore/projects';
import { UserRowContainer, UserRow } from './styled-components';

interface SharedUsersProps {
	sharedUsers: SharedUser[];
	getSetMembers: () => Promise<void>;
}

export default function SharedUsers({ sharedUsers, getSetMembers }: SharedUsersProps) {
	const { setSnackbarProps } = useContext(SnackContext);
	const { user } = useContext(AuthContext);
	const { project } = useContext(ProjectContext);

	const [disableActions, setDisableActions] = useState(false);

	const handleDelete = async (sharedUser: SharedUser) => {
		if (project) {
			setDisableActions(true);
			setSnackbarProps({
				open: true,
				severity: 'warning',
				message: `Removing ${sharedUser.email} from this project...`,
				hideDuration: null,
			});

			try {
				await memberDelete(sharedUser.email, project.id as string);
				await getSetMembers();
				setSnackbarProps({
					open: true,
					severity: 'success',
					message: `${sharedUser.email} has been removed from this project!`,
				});
			} catch (err) {
				console.error(err);
				setSnackbarProps({
					open: true,
					severity: 'error',
					message: `Error removing ${sharedUser.email} from this project...`,
				});
			}
			setDisableActions(false);
		}
	};

	return (
		<UserRowContainer>
			{sharedUsers.length < 1 ? (
				<h2>No shared users yet...</h2>
			) : (
				sharedUsers.map(sharedUser => (
					<UserRow key={sharedUser.email}>
						<Avatar>
							<PersonIcon />
						</Avatar>

						<ListItemText primary={sharedUser.email} secondary={sharedUser.userType} />

						{user?.isAdmin ? (
							<IconButton
								title={`Remove ${sharedUser.email} from this project`}
								onClick={() => handleDelete(sharedUser)}
								disabled={disableActions}
								size="large">
								<DeleteIcon />
							</IconButton>
						) : null}
					</UserRow>
				))
			)}
		</UserRowContainer>
	);
}
