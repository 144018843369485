import { debounce } from 'lodash';
import { useState, useMemo, useContext } from 'react';
import styled from 'styled-components';
import { TextField } from '@mui/material';
import { Row } from 'react-table';
import { SnackContext } from '../../../../../../context/SnackProvider';
import { updateLead } from '../../../../../../firebase';
import { formatDate, reorderDate } from '../../../../../../utils';
import { EditableSpan } from '../../../../../../components/styled-components/styledReactTable';
import { Lead } from '../../../../types';
import { LeadsContext } from '../../../LeadsProvider';

const yyyMmDd = (date: string) => {
	if (!date) return '';
	else {
		const arr = date.split('-');
		if (arr.length !== 3) return '-';
		else return [arr[2], arr[0], arr[1]].join('-');
	}
};

export default function LeadDateCell({
	row,
	field,
}: {
	row: Row<Lead>;
	field: 'nextActivity';
}) {
	const { setSnackbarProps } = useContext(SnackContext);
	const { setLeads } = useContext(LeadsContext);

	const leadId = row.original.id as string;
	const defaultDate = row.original[field];

	const [displayDate, setDisplayDate] = useState(formatDate(defaultDate));

	const [focused, setFocused] = useState(false);

	const updateDate = useMemo(
		() =>
			debounce(async (newDate: Date | null) => {
				if (newDate !== defaultDate) {
					setSnackbarProps({
						open: true,
						message: 'Submitting changes...',
						severity: 'warning',
						hideDuration: null,
					});

					setLeads(prev =>
						prev.map(lead => {
							if (lead.id !== leadId) return lead;
							else
								return {
									...lead,
									[field]: newDate,
									lastActivity: new Date(),
								};
						})
					);
					await updateLead({
						id: leadId,
						updatedFields: {
							[field]: newDate,
						},
					});

					setSnackbarProps({
						open: true,
						message: 'Changes submitted!',
						severity: 'success',
					});
				}
			}, 3000),
		[defaultDate, field, leadId, setLeads, setSnackbarProps]
	);

	const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
		const muiDate = e.target.value; // yyyy-mm-dd
		const normalDate = reorderDate(muiDate); /// mm-dd-yyyy

		if (muiDate) {
			updateDate(new Date(Date.parse(normalDate)));
		} else {
			updateDate(null);
		}

		setDisplayDate(normalDate);
	};

	return focused ? (
		<StyledTextField
			variant="standard"
			type="date"
			autoFocus
			defaultValue={displayDate !== '-' ? yyyMmDd(displayDate) : ''}
			onChange={handleChange}
			onBlur={() => setFocused(false)}
		/>
	) : (
		<EditableSpan
			onClick={() => setFocused(true)}
			onKeyUp={e => {
				if (e.key === 'Enter') setFocused(true);
			}}
			tabIndex={0}>
			{displayDate}
		</EditableSpan>
	);
}

const StyledTextField = styled(TextField)`
	.MuiInput-root {
		color: white;

		&:before {
			border-bottom: 1px solid white;
		}

		&:hover {
			&:after {
				transform: scaleX(1) translateX(0);
			}
		}

		> input {
			cursor: text;
			&::-webkit-calendar-picker-indicator {
				cursor: pointer;
				filter: invert(1);

				&:hover,
				:focus-visible {
					filter: invert(73%) sepia(94%) saturate(1834%) hue-rotate(349deg)
						brightness(104%) contrast(101%);
				}
			}
		}
	}
`;
