import { useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { SnackContext } from '../../../../context/SnackProvider';
import { AuthContext } from '../../../../context/AuthProvider';
import { ProjectContext } from '../../../../context/ProjectProvider';
import { FormV2Context } from '../context/FormV2Context.types';
import Layout from '../../../reusable-components/Layout';
import { LayoutLink } from '../../../styled-components/links';
import { YellowIconButton } from '../../../styled-components/buttons';
// import { ReactComponent as Forms } from '../../../../assets/projectInfoIcons/Forms.svg';
import EditIcon from '@mui/icons-material/Edit';
import LoadingScreen from '../../../reusable-components/LoadingScreen';
import ProcessingForm from '../../ProjectUtilityForm/screens/ProcessingForm';
import styled from 'styled-components';
import { db } from '../../../../firebase';
import { doc, updateDoc } from 'firebase/firestore';
import useArchivedListener from '../hooks/useArchivedListener';
import Form from '../../Form';
import FormFillV2 from '../components/FormContainer/components/FormFill/FormFillV2';
import Download from '../../../form-components/FormHeader/buttons/Download';
import { PDF } from '../../../form-components/FormHeader/buttons/PDF';
import Submit from '../../../form-components/FormHeader/buttons/Submit';
import { ViewModeToggle } from '../../../form-components/FormHeader/FormHeader.styles';
import { Edit, Visibility } from '@mui/icons-material';

export default function UtilityFormV2Renderer() {
	const { formId, projectId }: any = useParams();
	const { setSnackbarProps } = useContext(SnackContext);
	const { user } = useContext(AuthContext);
	const { project } = useContext(ProjectContext);
	const isV3 = window.location.href.includes('capture-form-v3');

	useArchivedListener();

	const {
		utilityForm,
		isSubmitting,
		setIsSubmitting,
		isLoading,
		isViewMode,
		setIsViewMode,
	} = useContext(FormV2Context);
	const formDocRef = doc(db, `utility_forms_v2/${formId}`);

	useEffect(() => {
		if (utilityForm?.status === 'completed') {
			setIsSubmitting(false);
		}
	}, [utilityForm?.status, setIsSubmitting]);

	const projectTitle = project
		? project.address || project.name || 'Unnamed Project'
		: 'Loading Project...';

	const editForm = async () => {
		setSnackbarProps({
			open: true,
			message: `Setting ${utilityForm?.formName} back to edit mode...`,
			severity: 'warning',
			hideDuration: null,
		});
		if (utilityForm) {
			await updateDoc(formDocRef, { status: 'draft' });
		}
		setSnackbarProps({
			open: true,
			message: `You can now edit ${utilityForm?.formName}!`,
			severity: 'success',
		});
	};

	const toggleViewMode = () => {
		setIsViewMode(!isViewMode);
	};

	return (
		<Layout
			LeftNavComponent={
				<NavComponentContainer>
					{project ? (
						<>
							<LayoutLink
								to={`/projects/${project.id}`}
								title="Navigate to project details page">
								<h1>{projectTitle}</h1>
							</LayoutLink>
						</>
					) : null}
					<Download />
					{!!utilityForm && !!utilityForm.downloadUrlVirtual && (
						<PDF link={utilityForm.downloadUrlVirtual} />
					)}
					<Submit />
				</NavComponentContainer>
			}
			RightNavComponent={
				<>
					{utilityForm?.downloadUrlVirtual &&
					utilityForm.status === 'completed' &&
					user?.isAdmin ? (
						<YellowIconButton title="Edit form" size="large" onClick={() => editForm()}>
							<EditIcon />
						</YellowIconButton>
					) : undefined}
					<ViewModeToggle
						variant="contained"
						color="primary"
						onClick={toggleViewMode}
						startIcon={isViewMode ? <Edit /> : <Visibility />}>
						{isViewMode ? 'Full view' : 'Condensed'}
					</ViewModeToggle>
				</>
			}>
			{isLoading ? (
				<LoadingScreen message="Loading Report..." />
			) : utilityForm?.status === 'uploaded' || utilityForm?.status === 'processing' ? (
				<ProcessingForm />
			) : isSubmitting ? (
				<LoadingScreen message="Submitting Report..." />
			) : utilityForm?.status === 'processing failed' ? (
				<UtilityFormLoadingContainer>
					<h3>Failed to generate PDF!</h3>
					<h4>Please contact a member of the Robotic Imaging team for assistance.</h4>
				</UtilityFormLoadingContainer>
			) : isV3 ? (
				<Form formId={formId} projectId={projectId} />
			) : (
				<FormFillV2 />
			)}
		</Layout>
	);
}

const NavComponentContainer = styled.div`
	display: flex;
	gap: 15px;
	width: 100%;
`;

export const UtilityFormLoadingContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	gap: 10px;

	color: #ffb310;
	padding: 50px;
	height: 100%;
	width: 100%;

	> * {
		margin: 0;
	}
`;
