import { useContext, useState } from 'react';
import {
	SidebarContainer,
	PlusButton,
	NewNodeContainer,
	AddNewNodeText,
	OverviewChip,
	OverviewChipTitle,
} from './Sidebar.styles';
import { FormV2Context } from '../../context/FormV2Context.types';
import Loading from '../Loading/Loading';
import RootNodeList from './components/RootNodeList/RootNodeList';
import { NewItemDialog } from '../Dialogs/NewItemDialog/NewItemDialog';
import { NodeType } from '../../utils/types';
import { copyAndPasteNode } from '../../../../../firebase';
import { SnackContext } from '../../../../../context/SnackProvider';

export default function Sidebar() {
	const { isLoading, isSubmitting } = useContext(FormV2Context);
	const { setCurrentNode, addNewNode, copiedNodeData, formId } =
		useContext(FormV2Context);
	const [isDialogOpen, setIsDialogOpen] = useState(false);
	const { setSnackbarProps } = useContext(SnackContext);

	const handleDialogOpen = () => {
		setIsDialogOpen(true);
	};

	const handleDialogClose = () => {
		setIsDialogOpen(false);
	};

	const handleAddItem = (displayTitle: string, type: NodeType) => {
		addNewNode(displayTitle, type);
		handleDialogClose();
	};

	const handlePasteItem = async () => {
		if (!copiedNodeData) {
			console.error('No node to paste!');
			return;
		}
		try {
			const result = await copyAndPasteNode({
				operation: 'paste',
				itemId: copiedNodeData.id,
				targetParentId: '',
				formId: formId,
				copiedNodeData: copiedNodeData,
			});
			setSnackbarProps({
				open: true,
				message: `Success! Item pasted.`,
				severity: 'success',
			});
			console.log('Node pasted successfully', result);
			handleDialogClose();
		} catch (error) {
			console.error('error pasting node', error);
			setSnackbarProps({
				open: true,
				message: `Error, Item could not be pasted. Please try again.`,
				severity: 'error',
			});
		}
	};

	return (
		<SidebarContainer>
			{isLoading && <Loading loadingText={'Loading...'} />}
			<OverviewChip onClick={() => setCurrentNode(undefined)}>
				<OverviewChipTitle>Overview</OverviewChipTitle>
			</OverviewChip>
			{!isLoading && !isSubmitting && <RootNodeList />}
			<NewNodeContainer onClick={handleDialogOpen}>
				<PlusButton />
				<AddNewNodeText>Add New Category</AddNewNodeText>
			</NewNodeContainer>

			<NewItemDialog
				isOpen={isDialogOpen}
				onClose={handleDialogClose}
				onAddItem={handleAddItem}
				enableTypeSelection={true}
				excludeTypes={['input', 'area', 'select', 'switch', 'date', 'datetime']}
				dialogTitle="Add New Category"
				inputLabel="Enter Category Name"
				showPasteOption={
					!!copiedNodeData &&
					(copiedNodeData.type === 'node' || copiedNodeData.type === 'photos')
				}
				onPasteItem={() => handlePasteItem()}
			/>
		</SidebarContainer>
	);
}
