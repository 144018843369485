import { useCallback, useEffect, useState } from 'react';

import { FirebaseFile } from '../../../../../../types';
import { getMetadata, getStorage, ref } from 'firebase/storage';
import styled from 'styled-components';
import Loading from '../../../../../screen-components/ProjectUtilityFormV2/components/Loading/Loading';

type Props = {
	file: FirebaseFile;
	fileSizeInMegabytes?: (size: number) => number;
};

export default function FileSize({ file, fileSizeInMegabytes }: Props) {
	const [fileSize, setFileSize] = useState<string>();

	const getFileSize = useCallback(async (file: FirebaseFile) => {
		try {
			const storage = getStorage();
			const fileRef = ref(storage, file.filepath);
			const metadata = await getMetadata(fileRef);
			const fileSize = metadata.size;
			return setFileSize(getReadableSize(fileSize));
		} catch (error) {
			console.error('Error getting file size:', error);
		}
	}, []);

	const getReadableSize = (size: number) => {
		const fileSizeInKB = size / 1024;
		const fileSizeInMB = fileSizeInKB / 1024;
		const fileSizeInGB = fileSizeInMB / 1024;

		if (fileSizeInMegabytes) {
			fileSizeInMegabytes(fileSizeInMB);
		}

		if (fileSizeInGB >= 1) {
			return fileSizeInGB.toFixed(2) + ' GB';
		} else if (fileSizeInMB >= 1) {
			return fileSizeInMB.toFixed(2) + ' MB';
		} else if (fileSizeInKB >= 1) {
			return fileSizeInKB.toFixed(2) + ' KB';
		} else {
			return size + ' bytes';
		}
	};

	useEffect(() => {
		getFileSize(file);
	}, [file, getFileSize]);

	return (
		<Size>
			{fileSize}
			{!fileSize && <Loading size={20} />}
		</Size>
	);
}

const Size = styled.span`
	font-size: 0.7rem;
	color: #666;
	padding-left: 0.5rem;
	white-space: nowrap;
	width: 4rem;
	text-align: right;
`;
