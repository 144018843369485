import { useContext } from 'react';
import { IconButton } from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';

import { utils, writeFile } from 'xlsx';
import { getMultipleLeadComments } from '../../../../../firebase';
import { SnackContext } from '../../../../../context/SnackProvider';
import { Row } from 'react-table';
import { Lead } from '../../../types';

export default function LeadsExportButton({
	selectedRows,
}: {
	selectedRows: Row<Lead>[];
}) {
	const { setSnackbarProps } = useContext(SnackContext);

	const getCommentsArr = async (data: any) => {
		const response = await getMultipleLeadComments(data);

		if (response?.data) {
			const commentsArr = (response.data as any).comments;
			const leadComments = commentsArr.map((comments: any) => {
				if (comments.length) {
					const concatenatedComment = comments.reduce(
						(acc: string, curr: any, idx: number) => {
							if (idx !== comments.length - 1) return acc + curr.comment + '\n';
							else return acc + curr.comment;
						},
						''
					);
					return {
						id: comments[0].leadId,
						comment: concatenatedComment,
					};
				} else {
					return {};
				}
			});
			return leadComments;
		} else {
			setSnackbarProps({
				open: true,
				message: 'Error fetching leads...',
				severity: 'error',
			});
		}
	};

	const dataToExcel = async () => {
		const selectedLead = selectedRows.map(row => row.original);
		const selectedLeadIds: string[] = [];
		for (const lead of selectedLead) {
			if (lead.id) selectedLeadIds.push(lead.id);
		}
		const data = { ids: selectedLeadIds };
		setSnackbarProps({
			open: true,
			message: 'Exporting leads...',
			severity: 'warning',
			hideDuration: null,
		});

		const commentsArr = await getCommentsArr(data);
		const leadsArr = selectedLead;

		const combinedArr = leadsArr.map(lead => {
			return {
				...lead,
				...commentsArr.find((comment: any) => comment.id === lead.id),
			};
		});

		const excelData = combinedArr.map((lead: any) => {
			return {
				Company: lead.company,
				'First Name': lead.firstName,
				'Last Name': lead.lastName,
				Title: lead.contactTitle,
				'Mobile Number': lead.mobileNumber,
				'Direct Number': lead.hqNumber,
				Email: lead.email,
				'Lead Status': lead.status,
				'Account Manager': lead.accountManager.name,
				'Last Activity': lead.lastActivity,
				State: lead.state,
				Comments: lead.comment,
			};
		});
		const worksheet = utils.json_to_sheet(excelData);
		const workbook = utils.book_new();
		utils.book_append_sheet(workbook, worksheet, 'Leads');
		writeFile(workbook, 'Leads.xlsx');
		setSnackbarProps({
			open: true,
			message: 'Leads Exported!',
			severity: 'success',
		});
	};

	return (
		<>
			<IconButton size="large" onClick={() => dataToExcel()}>
				<DownloadIcon />
			</IconButton>
		</>
	);
}
