import { useContext, useState } from 'react';
import { OrganizationsContext } from '../../../../context/OrganizationsProvider';
import { Organization } from '../../../../types';
import { AuthContext } from '../../../../context/AuthProvider';
import { useOrganizationsColumns } from '../../Organizations/OrganizationsColumns';
import SkeletonTable from '../../../reusable-components/SkeletonTable';
import DataTable from '../../../reusable-components/DataTable';
import DeleteOrganizationDialog from '../../Organizations/DeleteOrganizationDialog';
import AddOrganizationDialog from '../../Organizations/AddOrganizationDialog';
import styled from 'styled-components';

export default function Organizations() {
	const { user } = useContext(AuthContext);
	const { loadingOrganizations, organizations } = useContext(OrganizationsContext);

	const [organizationToBeDeleted, setOrganizationToBeDeleted] = useState<
		Organization | undefined
	>();
	const [openOrganizationDialog, setOpenOrganizationDialog] = useState(false);

	const isAdmin = Boolean(user?.isAdmin);

	const { organizationsColumns } = useOrganizationsColumns({
		onDeleteOrganization: organization => setOrganizationToBeDeleted(organization),
		isAdmin,
	});

	return (
		<>
			{loadingOrganizations ? (
				<SkeletonContainer>
					<SkeletonTable rows={10} />
				</SkeletonContainer>
			) : (
				<TableWrapper>
					<DataTable
						columns={organizationsColumns}
						data={organizations}
						name="organization"
						onAdd={() => setOpenOrganizationDialog(true)}
						searchbar
						showButton={isAdmin}
						queryFields={['name']}
					/>
					<DeleteOrganizationDialog
						organizationToBeDeleted={organizationToBeDeleted}
						setOrganizationToBeDeleted={setOrganizationToBeDeleted}
					/>
				</TableWrapper>
			)}
			<AddOrganizationDialog
				open={openOrganizationDialog}
				setOpen={setOpenOrganizationDialog}
			/>
		</>
	);
}

const SkeletonContainer = styled.div`
	max-width: 800px;
	width: 100%;
	margin: auto;
	margin-top: 80px;
`;

const TableWrapper = styled.div`
	display: block;
	max-width: 100%;
	min-width: 600px;
	width: 70%;
	overflow: hidden;
	border-radius: 20px;
	margin: 0 auto;
`;
