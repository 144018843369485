import { useContext, useEffect, useState } from 'react';
import { Sidebar } from '../../../reusable-components/Sidebar';
import { AuthContext } from '../../../../context/AuthProvider';
import { useParams } from 'react-router-dom';
import { OrganizationsContext } from '../../../../context/OrganizationsProvider';
import styled from 'styled-components';
import { OrganizationContent } from '../../../../screens/OrganizationPage';
import SkeletonTable from '../../../reusable-components/SkeletonTable';
import DataTable from '../../../reusable-components/DataTable';
import { useTeamPageColumns } from '../../TeamPage/TeamPageColumns';
import LogoContainer from '../../../reusable-components/LogoContainer/LogoContainer';
import theme from '../../../../styles/theme';
import { Button } from '@mui/material';
import AddUserDialog from '../../TeamPage/AddUserDialog';
import RemoveUserDialog from '../../TeamPage/RemoveUserDialog';
import { Team, UserObj } from '../../../../types';
import { PendingUserObj } from '../../../../screens/TeamPage';
import useTeamUsers from '../../TeamPage/useTeamUsers';
import OwnerTab from '../OwnerTab';
import AddIcon from '@mui/icons-material/Add';
import AddTeamDialog from '../TeamTab/AddTeamDialog';

export default function NonAdminOrgPage() {
	const { organizationId } = useParams();

	const { user } = useContext(AuthContext);
	const { teams, loadTeam, team } = useContext(OrganizationsContext);
	const [teamUsers, setTeamUsers] = useState<UserObj[]>();
	const [openTeamDialog, setOpenTeamDialog] = useState(false);

	useEffect(() => {
		setTeamUsers(team?.users || []);
	}, [team]);

	const isAdmin = Boolean(user?.isAdmin);
	const isOrgOwner = Boolean(user?.orgOwnerIds?.includes(organizationId || ''));
	const isTeamAdmin = team && Boolean((user?.teamAdminIds || []).includes(team.id));
	const [teamArr, setTeamArr] = useState<Team[]>([]);

	useEffect(() => {
		setTeamArr(
			isOrgOwner
				? teams || []
				: (teams || []).filter(team => team.userIds?.includes(user?.id || ''))
		);
	}, [isOrgOwner, teams, user?.id]);

	const [selectedTeamId, setSelectedTeamId] = useState('');

	const [loadingTeam, setLoadingTeam] = useState(false);

	const { usersColumns } = useTeamPageColumns();

	const [openUserDialog, setOpenUserDialog] = useState(false);
	const [usersToBeRemoved, setUsersToBeRemoved] = useState<(UserObj | PendingUserObj)[]>(
		[]
	);
	const { loadTeamUsers, pendingUsers, setPendingUsers } = useTeamUsers(
		selectedTeamId,
		organizationId || ''
	);
	const { setSelectedTeam } = useContext(OrganizationsContext);
	const handleOnDeleteUsers = (ids: string[]) => {
		const usersToDelete: UserObj[] = [];
		const pendingUsersToDelete: PendingUserObj[] = [];

		for (const id of ids) {
			const userToDelete = (teamUsers || []).find(userObj => userObj.id === id);
			if (userToDelete) {
				usersToDelete.push(userToDelete);
				continue;
			}
			const pendingUserToDelete = pendingUsers.find(
				pendingUserObj => pendingUserObj.id === id
			);
			if (pendingUserToDelete) {
				pendingUsersToDelete.push(pendingUserToDelete);
			}
		}

		setUsersToBeRemoved([...usersToDelete, ...pendingUsersToDelete]);
	};

	useEffect(() => {
		if (selectedTeamId) {
			setLoadingTeam(true);
			loadTeam(selectedTeamId).then(() => {
				setLoadingTeam(false);
			});
		}
	}, [loadTeam, selectedTeamId]);

	const [viewOwners, setViewOwners] = useState(true);

	return (
		<>
			<Sidebar
				backButtonTo="/projects"
				backButtonTitle="Back to Homepage"
				topContent={
					isOrgOwner ? (
						<OwnerButton
							$viewOwners={viewOwners}
							onClick={() => {
								setViewOwners(true);
								setSelectedTeamId('');
							}}>
							Owners
						</OwnerButton>
					) : null
				}
				bottomContent={<LogoContainer />}>
				{teamArr.length ? (
					<TeamListWrapper>
						<TeamListHeader>
							<TeamListHeaderTitle>Teams</TeamListHeaderTitle>
							{isOrgOwner && (
								<TeamListHeaderAction onClick={() => setOpenTeamDialog(true)}>
									<AddIcon />
								</TeamListHeaderAction>
							)}
						</TeamListHeader>
						<TeamList>
							{teamArr.map(team => {
								const numProjects = (team.projectIds || []).length;
								const numUsers = (team.userIds || []).length;

								return (
									<TeamListItem
										key={team.id}
										$isSelected={selectedTeamId === team.id}
										onClick={() => {
											setSelectedTeamId(team.id);
											setSelectedTeam?.(team.id);
											setViewOwners(false);
										}}
										title={`View ${team.name} members`}>
										<span>{team.name}</span>
										<Subtext>
											<span>{`${numProjects} project${
												numProjects !== 1 ? 's' : ''
											},`}</span>
											<span>{`${numUsers} user${numUsers !== 1 ? 's' : ''}`}</span>
										</Subtext>
									</TeamListItem>
								);
							})}
						</TeamList>
					</TeamListWrapper>
				) : (
					<div>No teams</div>
				)}
			</Sidebar>

			<OrganizationContent>
				{!viewOwners ? (
					loadingTeam ? (
						<SkeletonTable rows={10} />
					) : (
						<>
							<ViewTitle>{`${team?.name} Members`}</ViewTitle>
							<DataTable
								data={[...pendingUsers, ...(teamUsers || [])]}
								columns={usersColumns}
								name="user"
								onAdd={() => setOpenUserDialog(true)}
								onDelete={handleOnDeleteUsers}
								textEmptyResult="Add a member to this team to get started!"
								textNoSearchQueryResult="No Displayable Members"
								showButton={isAdmin || isOrgOwner || isTeamAdmin}
								queryFields={['fullName', 'email']}
							/>

							<AddUserDialog
								open={openUserDialog}
								setOpen={setOpenUserDialog}
								teamId={team?.id || ''}
								organizationId={organizationId || ''}
								onSave={loadTeamUsers}
							/>
							<RemoveUserDialog
								setUsersToBeRemoved={setUsersToBeRemoved}
								usersToBeRemoved={usersToBeRemoved}
								teamId={team?.id || ''}
								setPendingUsers={setPendingUsers}
							/>
						</>
					)
				) : (
					<>
						<ViewTitle>Organization Owners</ViewTitle>
						<OwnerTab />
					</>
				)}
			</OrganizationContent>
			<AddTeamDialog
				open={openTeamDialog}
				setOpen={setOpenTeamDialog}
				organizationId={organizationId}
			/>
		</>
	);
}

const OwnerButton = styled(Button)<{ $viewOwners: boolean }>`
	color: ${({ $viewOwners }) => (!$viewOwners ? 'white' : 'black')};
	background-color: ${({ $viewOwners }) =>
		!$viewOwners ? theme.palette.secondary.main : theme.palette.primary.main};

	&:hover {
		background-color: ${({ $viewOwners }) =>
			$viewOwners ? theme.palette.primary.main : 'rgba(255, 179, 16, 0.04)'};
	}
`;

const TeamListWrapper = styled.div`
	display: flex;
	flex-direction: column;
	border: 1px solid ${theme.palette.divider};
	overflow-y: auto;
`;
const TeamListHeader = styled.div`
	display: grid;
	grid-template-columns: 1fr auto;
	align-items: center;
	width: 100%;
	padding: 10px;

	color: white;
	text-align: center;
	background-color: ${theme.palette.secondary.dark};
	z-index: 1;
	border-bottom: 1px solid ${theme.palette.divider};

	position: sticky;
	top: 0px;
`;

const TeamListHeaderTitle = styled.h3`
	margin: 0;
	text-align: center;
`;

const TeamListHeaderAction = styled.button`
	color: ${theme.palette.primary.main};
	cursor: pointer;
	background-color: transparent;
	border: none;
`;

const TeamListItem = styled(Button)<{ $isSelected: boolean }>`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: 0px;

	text-transform: none;
	padding: 5px;

	background-color: ${({ $isSelected }) =>
		$isSelected ? theme.palette.primary.main : 'transparent'};
	color: ${({ $isSelected }) => ($isSelected ? 'black' : 'white')};

	&:hover {
		background-color: ${({ $isSelected }) =>
			$isSelected ? theme.palette.primary.main : 'rgba(255, 179, 16, 0.04)'};
	}

	> span:first-child {
		text-align: start;
		width: 100%;
		white-space: nowrap;
		text-overflow: ellipsis;
		overflow: hidden;
	}
`;

const Subtext = styled.div`
	display: flex;
	align-items: center;
	gap: 5px;
	font-size: 10px;
`;

const TeamList = styled.div`
	display: flex;
	flex-direction: column;
	color: white;
	background-color: ${theme.palette.secondary.main};

	> ${TeamListItem} {
		border-bottom: 1px solid ${theme.palette.divider};
		&:last-child {
			border-bottom: none;
		}
	}
`;

const ViewTitle = styled.h3`
	color: white;
	margin-bottom: 0px;
`;
