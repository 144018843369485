import { DialogContent } from '@mui/material';
import styled from 'styled-components';

export const FullWidthDialogContent = styled(DialogContent)`
	display: flex;
	flex-direction: column;
	gap: 10px;

	width: 600px;
	max-width: calc(100vw - 64px);
`;

export const StyledDialogContent = styled(DialogContent).attrs(() => ({
	$minWidth: 300,
}))<{ $minWidth?: number }>`
	@media screen and (min-width: 364px) {
		min-width: ${({ $minWidth }) => `${$minWidth}px`};
	}
	max-width: calc(100vw - 64px);
`;
