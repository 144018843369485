import { useState, useEffect, useContext, useCallback } from 'react';
import { useParams, Link } from 'react-router-dom';
import styled from 'styled-components';

import { onAuthStateChanged, signInAnonymously } from 'firebase/auth';
import { anonymousProjectSignIn, auth, getProject } from '../firebase';

import DeliverableFiles from '../components/reusable-components/DeliverableFiles';
import DeliverableFilesHeader, {
	AnonNavBar,
	AnonNavBarButton,
	AnonNavBarDrawer,
	AnonNavBarDrawerButton,
	AnonNavBarDrawerHeader,
} from '../components/reusable-components/DeliverableFiles/DeliverableFilesHeader';
import { ProjectContext } from '../context/ProjectProvider';
import { SnackContext } from '../context/SnackProvider';

import { List, ListItem, ListItemText } from '@mui/material';

import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';

import LogoButton from '../components/reusable-components/LogoButton';
import Spinner from '../components/reusable-components/Spinner';
import LoadingScreen from '../components/reusable-components/LoadingScreen';
import { formatProjectTimestamps } from '../utils';
import { StyledFormikTextField } from '../components/reusable-components/FormikTextField';

export default function UploadDeliverables() {
	const { project, setProject } = useContext(ProjectContext);
	const { setSnackbarProps } = useContext(SnackContext);
	const { projectId } = useParams();

	const [loading, setLoading] = useState(true);
	const [loadingStatus, setLoadingStatus] = useState('');
	const [loggedIn, setLoggedIn] = useState(false);
	const [loggingIn, setLoggingIn] = useState(false);
	const [openMenu, setOpenMenu] = useState(false);

	const disableLoading = useCallback(() => {
		setLoadingStatus('');
	}, [setLoadingStatus]);

	const handleError = useCallback((e: any, status: string) => {
		console.log(e);
		setLoading(false);
		setLoadingStatus(status);
	}, []);

	useEffect(() => {
		let unsub = () => {};
		if (loggedIn) {
			unsub = onAuthStateChanged(auth, async user => {
				setLoadingStatus('Connecting to backend...');
				if (user) {
					try {
						const res = await getProject({
							projectId: projectId,
							anonymous: { upload: true },
						});
						const resData = res.data as any;
						setProject(formatProjectTimestamps([resData.project])[0]);
						disableLoading();
					} catch (err) {
						handleError(err, "Error: Can't access project data...");
					}
				} else {
					signInAnonymously(auth).catch(e =>
						handleError(e, "Error: Can't connect to backend...")
					);
				}
			});
		}

		return () => {
			setProject(null);
			unsub();
		};
	}, [disableLoading, handleError, loggedIn, projectId, setProject]);

	useEffect(() => {
		if (project) {
			document.title = `${
				project.address || project.name || 'Unnamed Project'
			} | Upload Page`;
		} else {
			document.title = 'Pending Upload Page';
		}
	}, [project]);

	return (
		<AnonymousBackground>
			{loggedIn ? (
				project ? (
					<>
						<DeliverableFilesHeader project={project} upload />
						<DeliverableFiles anonUpload />
					</>
				) : (
					<AnonymousLoadingContainer>
						{loading ? (
							<Spinner size={200} />
						) : (
							<ErrorOutlineIcon color="primary" fontSize="large" />
						)}
						<span>{loadingStatus}</span>
					</AnonymousLoadingContainer>
				)
			) : (
				<>
					<AnonNavBar $drawerOpen={openMenu}>
						<LogoButton path={`/projects/${project?.id ?? ''}`} />

						<div style={{ marginLeft: 'auto' }}>
							<AnonNavBarDrawerButton
								$drawerOpen={openMenu}
								title="Open menu"
								size="large"
								onClick={() => setOpenMenu(true)}>
								<MenuIcon />
							</AnonNavBarDrawerButton>

							<AnonNavBarDrawer variant="persistent" anchor="right" open={openMenu}>
								<AnonNavBarDrawerHeader>
									<AnonNavBarButton
										title="Close menu"
										size="large"
										onClick={() => setOpenMenu(false)}
										style={{ marginLeft: 'auto' }}>
										<CloseIcon />
									</AnonNavBarButton>
								</AnonNavBarDrawerHeader>

								<List>
									<ListItem
										button
										component={Link}
										to="/login"
										state={{ redirectPath: `/projects/${projectId}` }}
										title="Navigate to Login screen">
										<ListItemText primary="Login" />
									</ListItem>
									<ListItem
										button
										component="a"
										href="https://www.roboticimaging.com/request-a-scan"
										title="Navigate to Request Quote page on website">
										<ListItemText primary="Request Quote" />
									</ListItem>
									<ListItem
										button
										component="a"
										href="https://www.roboticimaging.com/scheduling"
										title="Navigate to Scheduling page on website">
										<ListItemText primary="Schedule" />
									</ListItem>
									<ListItem
										button
										component="a"
										href="https://roboticimaging.ai/deliverables/e6QCykLflAJu0JEGIkEC/upload"
										title="Navigate to Transfer screen">
										<ListItemText primary="Transfer" />
									</ListItem>
								</List>
							</AnonNavBarDrawer>
						</div>
					</AnonNavBar>
					{!loggingIn ? (
						<StyledFormikTextField
							label="Enter project password..."
							name="Password"
							type="password"
							style={{ margin: 'auto' }}
							autoFocus
							onKeyUp={async e => {
								if (e.key === 'Enter') {
									const password = (e.target as HTMLTextAreaElement).value;
									setLoggingIn(true);
									anonymousProjectSignIn({ projectId, password })
										.then(signIn => {
											setLoggingIn(false);
											const res = (signIn.data as any).result ?? false;
											if (res) setLoggedIn(true);
											else
												setSnackbarProps({
													open: true,
													severity: 'warning',
													message: 'Wrong password!',
												});
										})
										.catch(e => {
											console.log(e);
											setLoggingIn(false);
											setSnackbarProps({
												open: true,
												severity: 'error',
												message:
													'Backend error! Please contact a member of the Robotic Imaging team.',
											});
										});
								}
							}}
						/>
					) : (
						<LoadingScreen message="Logging in..." />
					)}
				</>
			)}
		</AnonymousBackground>
	);
}

export const AnonymousBackground = styled.div`
	width: 100%;
	height: 100%;
	color: white;
	overflow: auto;
	display: flex;
	flex-direction: column;
`;

export const AnonymousLoadingContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 20px;
	font-size: 20px;
	height: 100%;
	align-items: center;
	justify-content: center;
`;
