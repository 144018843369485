import { Outlet } from 'react-router-dom';
import { ModelerProvider } from '../context/ModelerProvider';

export default function ModelersRoute() {
	// Add modeler specific logic

	// render routes
	return (
		<ModelerProvider>
			<Outlet />
		</ModelerProvider>
	);
}
