import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	ListItem,
} from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { FormV2Context } from '../../../context/FormV2Context.types';
import { CheckCircle, HourglassTop } from '@mui/icons-material';
import { FormNode, PhotoV2 } from '../../../utils/types';
import {
	EmptyContent,
	ListItemContainer,
	RemoveButton,
	StyledChip,
} from './PhotoUploadManager.styles';
import useDownloadPhotos from './useDownloadPhotos';

interface PhotoUploadManagerProps {
	open: boolean;
	onClose: () => void;
	itemNode: FormNode;
	uploadedPhotos: PhotoV2[];
}

const PhotoUploadManager: React.FC<React.PropsWithChildren<PhotoUploadManagerProps>> = ({
	open,
	onClose,
	itemNode,
	uploadedPhotos,
}) => {
	const { photoUploadQueue, deleteNodePhoto } = useContext(FormV2Context);
	const { downloadPhotos, downloadZip } = useDownloadPhotos();

	const [combinedPhotos, setCombinedPhotos] = useState<PhotoV2[]>([]);
	const [downloadMessage, setDownloadMessage] = useState<string>('');

	useEffect(() => {
		setCombinedPhotos([
			...uploadedPhotos,
			...photoUploadQueue.filter(p => p.nodeId === itemNode.id).map(p => p.photo),
		]);
	}, [itemNode.id, photoUploadQueue, uploadedPhotos]);

	const handleDownloadAll = async () => {
		await downloadPhotos(combinedPhotos, itemNode, setDownloadMessage);
		setDownloadMessage('');
		onClose();
	};

	const handleZip = async () => {
		await downloadZip(combinedPhotos, itemNode, setDownloadMessage);
		setDownloadMessage('');
		onClose();
	};

	return (
		<Dialog open={open} onClose={onClose} fullWidth>
			<DialogTitle>Manage Photos</DialogTitle>
			<DialogContent>
				{combinedPhotos.length > 0 ? (
					combinedPhotos.map((photo, index) => (
						<ListItemContainer key={index}>
							<ListItem>{photo.originalName}</ListItem>
							{photo.url ? (
								<StyledChip
									label="Uploaded"
									icon={<CheckCircle style={{ color: 'green' }} />}
									color="primary"
									variant="outlined"
								/>
							) : (
								<>
									<StyledChip
										label="Queued for upload"
										icon={<HourglassTop style={{ color: 'black' }} />}
										color="default"
										variant="outlined"
									/>
								</>
							)}
							<RemoveButton onClick={() => deleteNodePhoto(itemNode.id, photo)}>
								Remove
							</RemoveButton>
						</ListItemContainer>
					))
				) : (
					<EmptyContent>No photos uploaded or queued.</EmptyContent>
				)}
			</DialogContent>
			<DialogActions>
				<span style={{ padding: 11 }}>{downloadMessage.split('...')[1]}</span>
				{downloadMessage === '' && (
					<>
						<Button color="primary" onClick={handleZip} sx={{ textTransform: 'none' }}>
							Download Zip
						</Button>{' '}
						<Button
							color="primary"
							onClick={handleDownloadAll}
							sx={{ textTransform: 'none' }}>
							Download All Files
						</Button>{' '}
						<Button onClick={onClose} sx={{ textTransform: 'none' }}>
							Close
						</Button>
					</>
				)}
			</DialogActions>
		</Dialog>
	);
};

export default PhotoUploadManager;
