import React, {
	useCallback,
	useContext,
	useEffect,
	useMemo,
	useRef,
	useState,
} from 'react';
import { Organization, ProjectData, ProjectStatus } from '../../../types';
import {
	confirmProjectStatusChange,
	updateSingleProjectField,
} from '../../../firebase/firestore/projects';
import { SnackContext } from '../../../context/SnackProvider';
import {
	AddressLink,
	BodyCell,
	BodyRow,
	BottomRow,
	HeaderCell,
	HeaderRow,
	Pagination,
	PaginationButton,
	StatusWrapper,
	Table,
	TableWrapper,
} from '../../styled-components/styledReactTable';
import { MenuItem, Select } from '@mui/material';
import {
	copyToClipboard,
	getAnonDownloadLink,
	projectStatusText,
	projectStatuses,
} from '../../../utils';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import EditIcon from '@mui/icons-material/Edit';
import {
	useFilters,
	useGlobalFilter,
	usePagination,
	useSortBy,
	useTable,
	useRowSelect,
	useFlexLayout,
} from 'react-table';
import {
	ExpandLessIcon,
	ExpandMoreIcon,
} from '../../../screens/ProjectsList/components/ClientProjects/styles';
import { ChevronLeft, ChevronRight } from '@mui/icons-material';
import EditProjectForm from '../ModelerPage/EditProjectForm';
import styled from 'styled-components';

export default function UserTable({
	status,
	filteredProjects,
	setUserProjects,
}: {
	status: ProjectStatus;
	filteredProjects: ProjectData[] | null;
	setUserProjects: React.Dispatch<React.SetStateAction<ProjectData[] | null>>;
}) {
	const { setSnackbarProps } = useContext(SnackContext);

	const [controlledPageIndex, setControlledPageIndex] = useState(0);
	const [selectedProject, setSelectedProject] = useState<ProjectData | null>(null);
	const [data, setData] = useState<ProjectData[]>([]);
	const statusColWidth = 270;

	const updateFirebase = useCallback(
		(project: ProjectData, value: any, field: string, rerender: boolean = true) => {
			updateSingleProjectField(project.id as string, value, field).catch(e =>
				console.error(e)
			);
			if (rerender) {
				setUserProjects(prev => {
					if (prev) {
						const idx = prev.findIndex((obj: ProjectData) => obj.id === project.id);
						if (idx !== -1) {
							const updatedProj = {
								...prev[idx],
								[field]: value,
							};
							if (field === 'projectStatus')
								updatedProj.lastStatusChange = new Date(Date.now());
							let tmp = [...prev];
							tmp.splice(idx, 1, updatedProj);
							tmp.sort(
								(x, y) => y.lastStatusChange.getTime() - x.lastStatusChange.getTime()
							);
							return [...tmp];
						} else return prev;
					} else return prev;
				});
			}
		},
		[setUserProjects]
	);

	const columns = useMemo(
		() => [
			{
				Header: 'Status',
				id: 'projectStatus',
				Cell: ({ row: { original: project } }: any) => {
					return (
						<StatusWrapper $projectStatus={status}>
							<Select
								onChange={e => {
									e.preventDefault();
									const newStatus = e.target.value as ProjectStatus;
									if (confirmProjectStatusChange(newStatus)) {
										updateFirebase(project, newStatus, 'projectStatus');
									}
								}}
								value={project.projectStatus}
								displayEmpty>
								{projectStatuses.map(status => (
									<MenuItem value={status} key={status}>
										{projectStatusText(status)}
									</MenuItem>
								))}
							</Select>
						</StatusWrapper>
					);
				},
				accessor: (row: any) => projectStatusText(row.projectStatus),
				disableSortBy: true,
				width: statusColWidth,
			},
			{
				Header: 'Organization(s)',
				id: 'orgs',
				Cell: ({ row: { original: project } }: any) => {
					const projectOrgs = project?.orgs?.length
						? project.orgs.map((org: Organization) => org.name).join(', ')
						: '–';
					return <span>{projectOrgs}</span>;
				},
				accessor: (row: any) => row.orgs,
				sortType: 'string',
				disableSortBy: true,
				width: 200,
			},
			{
				Header: 'Address',
				id: 'address',
				_Cell: ({ row: { original: project } }: { row: { original: ProjectData } }) => {
					const shareableLink = getAnonDownloadLink(project);
					const projectName = project.address || project.name || 'Unnamed Project';
					return (
						<>
							<AddressLink
								to={`/projects/${project.id}`}
								title={`Navigate to ${projectName}`}
								$color="white">
								{projectName}
							</AddressLink>
							<div style={{ display: 'flex' }}>
								<ContentCopyIcon
									onClick={() => {
										copyToClipboard(shareableLink);
										setSnackbarProps({
											open: true,
											message: `${projectName} public shareable link copied!`,
											severity: 'success',
										});
									}}
									className="CopyLinkButton CopyLinkButton-black"
									titleAccess="Copy public shareable link"
									tabIndex={0}
								/>
								<EditIcon
									onClick={() => {
										setSelectedProject(project);
									}}
									className="CopyLinkButton CopyLinkButton-black"
									titleAccess={`Edit ${projectName}`}
									tabIndex={0}
								/>
							</div>
						</>
					);
				},
				// @ts-ignore
				get Cell() {
					return this._Cell;
				},
				set Cell(value) {
					this._Cell = value;
				},
				accessor: (row: any) => row.address || row.name || 'Unnamed Project',
				sortType: 'string',
				disableSortBy: true,
				width: 400,
			},
			{
				Header: 'Delivery Date',
				id: 'deliveryTimestamp',
				Cell: ({ row: { original: project } }: any) => {
					const ts = project?.deliveryTimestamp
						? new Date(project.deliveryTimestamp.seconds * 1000).toLocaleDateString()
						: '-';
					return <span>{ts}</span>;
				},
				accessor: (row: any) =>
					row?.deliveryTimestamp
						? new Date(row.deliveryTimestamp.seconds * 1000).getTime()
						: 0,

				sortType: 'number',
				width: 200,
			},
		],
		[status, updateFirebase, setSnackbarProps]
	);

	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		prepareRow,
		page,
		canPreviousPage,
		canNextPage,
		pageCount,
		nextPage,
		previousPage,
		setPageSize,
		state: { pageIndex, pageSize },
	} = useTable(
		{
			data,
			columns,
			initialState: {
				pageSize: 5,
				pageIndex: controlledPageIndex,
			},
		},
		useFilters,
		useGlobalFilter,
		useSortBy,
		usePagination,
		useRowSelect,
		useFlexLayout
	);

	const pageMin = () => pageIndex * pageSize + 1;
	const pageMax = () =>
		pageIndex + 1 === pageCount ? data.length : (pageIndex + 1) * pageSize;
	const pageRows = () => data.length;

	const configurePageSize = (size: number) => {
		// Calculates new page index based on top row after resizing page size
		const newPageIndex = Math.floor((pageIndex * pageSize) / size);
		setControlledPageIndex(newPageIndex);
		setPageSize(size);
	};

	// Keeps table data up to date with any changes made to projects useState
	useEffect(() => {
		setData(filteredProjects || []);
	}, [setData, filteredProjects]);

	// Keeps table from displaying a blank page when there are no more projects on that page
	// e.g. when projectStatus is changed
	useEffect(() => {
		// Calculates the index of the top row of the current page + 1
		const topRowIndex = pageIndex * pageSize + 1;
		// Goes to previous page if there is no project to display on the top row and the pageIndex !== 0
		if (topRowIndex > data.length && pageIndex) {
			setControlledPageIndex(prev => prev - 1);
			previousPage();
		}
	}, [data.length, pageCount, pageIndex, pageSize, previousPage]);

	const headerRef = useRef<HTMLDivElement>(null);
	const bodyRef = useRef<HTMLDivElement>(null);

	const handleScroll = () => {
		if (bodyRef.current && headerRef.current) {
			const { scrollLeft } = bodyRef.current;
			headerRef.current.scrollLeft = scrollLeft;
		}
	};

	return data.length > 0 ? (
		<TableWrapper {...getTableProps()}>
			{headerGroups.map((headerGroup: any) => (
				<HeaderRow
					{...headerGroup.getHeaderGroupProps({
						style: {
							minWidth: '100%',
							borderTopRightRadius: '20px',
							borderTopLeftRadius: '20px',
						},
					})}
					ref={headerRef}>
					{headerGroup.headers.map((column: any, idx: number) => (
						<HeaderCell key={idx} style={{ width: column.width }}>
							<HeaderCellInner
								isSorted={column.isSorted}
								canSort={column.canSort}
								{...column.getHeaderProps(column.getSortByToggleProps())}
								title={column.canSort ? `Sort table by ${column.Header}` : ''}>
								{column.render('Header')}
								{column.isSorted ? (
									column.isSortedDesc ? (
										<ExpandMoreIcon />
									) : (
										<ExpandLessIcon />
									)
								) : null}
							</HeaderCellInner>
						</HeaderCell>
					))}
				</HeaderRow>
			))}

			<Table style={{ borderRadius: '30px' }} ref={bodyRef} onScroll={handleScroll}>
				<div {...getTableBodyProps()}>
					{page.map((row: any) => {
						prepareRow(row);
						return (
							<BodyRow {...row.getRowProps()} key={row.original.id}>
								{row.cells.map((cell: any) => {
									return (
										<StyledBodyCell
											status={row.original.projectStatus}
											{...cell.getCellProps()}
											style={{ width: cell.column.width }}>
											{cell.render('Cell')}
										</StyledBodyCell>
									);
								})}
							</BodyRow>
						);
					})}
				</div>
			</Table>

			<BottomRow
				style={{ borderBottomLeftRadius: '30px', borderBottomRightRadius: '30px' }}>
				<Pagination>
					<div>Rows per page:</div>
					<select onChange={e => configurePageSize(Number(e.target.value))}>
						{[5, 10, 25, 50, 100].map(pageSize => (
							<option key={pageSize} value={pageSize}>
								{pageSize}
							</option>
						))}
					</select>
					<div>{page.length > 0 && `${pageMin()}–${pageMax()} of ${pageRows()}`}</div>
					<PaginationButton
						disabled={!canPreviousPage}
						onClick={() => {
							if (canPreviousPage) setControlledPageIndex(prev => prev - 1);
							previousPage();
						}}>
						<ChevronLeft />
					</PaginationButton>
					<PaginationButton
						disabled={!canNextPage}
						onClick={() => {
							if (canNextPage) setControlledPageIndex(prev => prev + 1);
							nextPage();
						}}>
						<ChevronRight />
					</PaginationButton>
				</Pagination>
			</BottomRow>

			<EditProjectForm
				project={selectedProject}
				setSelectedProject={setSelectedProject}
				setModelerProjects={setUserProjects}
			/>
		</TableWrapper>
	) : (
		<div
			style={{
				alignItems: 'center',
				padding: '80px',
				textAlign: 'center',
				fontSize: '1.5rem',
				color: 'white',
			}}>
			<span>Modeler has no projects currently associated with this status.</span>
		</div>
	);
}

const HeaderCellInner = styled.div<{
	isSorted: boolean;
	canSort: boolean;
}>`
	color: ${({ isSorted }) => (isSorted ? '#ffb310' : '#f6f6f6')};
	font-weight: bold;
	display: flex;
	align-items: center;
	height: 60px;
	transition: 0.2s;
	padding: 20px;

	&:hover {
		color: ${({ canSort }) => (canSort ? '#ffb310' : '')};
		text-shadow: ${({ canSort }) => (canSort ? '0 0 2px #ffb310' : '')};
		text-decoration: ${({ canSort }) => (canSort ? 'underline' : '')};
	}
`;

const StyledBodyCell = styled(BodyCell)`
	padding-left: 20px;
`;
