import CompletionIconButton from '../CompletionIconButton';
import { ReactComponent as DocusignSvg } from '../../../../../assets/projectInfoIcons/Docusign.svg';
import styled from 'styled-components';
import { useState } from 'react';
import DocusignDialog from './components/DocusignDialog';

// type DocusignButtonProps = {
// 	isComplete: boolean;
// };

export default function DocusignButton() {
	const [showDialog, setShowDialog] = useState(false);

	const handleClick = () => {
		setShowDialog(prev => !prev);
	};

	return (
		<>
			<CompletionIconButton
				isComplete={false}
				title={'View Docusign envelopes'}
				icon={<DocusignIcon />}
				onClick={handleClick}
			/>

			<DocusignDialog showDialog={showDialog} setShowDialog={setShowDialog} />
		</>
	);
}

const DocusignIcon = styled(DocusignSvg)`
	width: 26px;
`;
