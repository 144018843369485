import { Typography } from '@mui/material';
import { CropOriginal } from '@mui/icons-material';
import { StyledBoxUpload } from './CoverImageStyles';

const CoverImageUpload: React.FC<{ onClick: any }> = ({ onClick }) => {
	return (
		<StyledBoxUpload onClick={onClick}>
			<CropOriginal color="primary" fontSize="small" />
			<Typography
				variant="body2"
				fontWeight={700}
				color="primary.main"
				sx={{ textDecoration: 'underline' }}>
				Add image
			</Typography>
		</StyledBoxUpload>
	);
};

export default CoverImageUpload;
