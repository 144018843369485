import { writeFileXLSX, utils } from 'xlsx';
import { ReportData, ReportQuestions } from '../../../types/reports';
import { db } from '../../../firebase';
import { collection, getDocs, orderBy, query } from 'firebase/firestore';

const useXLSX = () => {
	const downloadXLSX = (
		report?: ReportData,
		reportQuestions?: ReportQuestions,
		hiddenQuestions: string[] = []
	) => {
		if (!report || !reportQuestions) return;

		const workbook = utils.book_new();
		const rows = reportQuestions.questions
			.filter(question => !hiddenQuestions.includes(question.question))
			.map(question => {
				const row: any = {
					Parent: question.parent,
					Question: question.question.split(' > ').pop(),
				};

				question.options.forEach(option => {
					row[option.value] = option.count;
				});

				row['No Value'] = question.noValue;
				row['Missing Question'] = question.missing;
				row['Total'] = report.forms?.length || 0;
				return row;
			});

		const worksheet = utils.json_to_sheet(rows);

		worksheet['!cols'] = [{ wch: 30 }, { wch: 30 }];
		worksheet['!rows'] = [{ hpx: 30 }];

		utils.book_append_sheet(workbook, worksheet, 'Form');
		writeFileXLSX(workbook, `Report ${new Date().toISOString()}.xlsx`);
	};

	const downloadFullXLSX = async (
		report?: ReportData,
		hiddenQuestions: string[] = []
	) => {
		if (!report) return;
		console.log(hiddenQuestions);

		const excelQuery = query(
			collection(db, `reportQuestions/${report.id}/excel`),
			orderBy('order')
		);

		const excelCollection = await getDocs(excelQuery);
		if (excelCollection.empty) return console.log('Excel data not found');
		const rows = excelCollection.docs.map(doc => JSON.parse(doc.data().row));

		const cleanedRows = rows.map((row: any) => {
			const cleanedRow: any = {};

			Object.keys(row).forEach(key => {
				if (!hiddenQuestions.includes(key)) {
					cleanedRow[key] = row[key];
				}
			});

			return cleanedRow;
		});

		const workbook = utils.book_new();
		const worksheet = utils.json_to_sheet(cleanedRows);

		worksheet['!cols'] = [{ wch: 30 }, { wch: 30 }];
		worksheet['!rows'] = [{ hpx: 30 }];

		utils.book_append_sheet(workbook, worksheet, 'Form');
		writeFileXLSX(workbook, `FullData ${new Date().toISOString()}.xlsx`);
	};

	const downloadPDFs = async (report?: ReportData) => {
		if (!report) return;

		if (report.pdfsDownloadUrl) {
			window.open(report.pdfsDownloadUrl, '_self');
		}
	};

	return { downloadXLSX, downloadPDFs, downloadFullXLSX };
};

export default useXLSX;
