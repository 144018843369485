import { useContext, useState } from 'react';
import { deleteDoc, doc } from 'firebase/firestore';
import { FormTemplate } from '../ProjectUtilityFormV2/utils/types';
import { db } from '../../../firebase';
import { TemplatesContext } from '../../../context/TemplatesProvider';

const useDeleteTemplate = (template: FormTemplate) => {
	const { refreshData } = useContext(TemplatesContext);

	const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
	const [isDeleting, setIsDeleting] = useState(false);

	const deleteTemplate = (e: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
		e.stopPropagation();
		setDeleteDialogOpen(true);
	};

	const closeDeleteDialog = () => setDeleteDialogOpen(false);

	const handleDeleteTemplate = async () => {
		setIsDeleting(true);
		closeDeleteDialog();
		const templateRef = doc(db, 'utility_forms_v2_templates', template.id);
		deleteDoc(templateRef);
		setIsDeleting(false);
		refreshData();
	};

	return {
		closeDeleteDialog,
		deleteDialogOpen,
		handleDeleteTemplate,
		isDeleting,
		deleteTemplate,
	};
};

export default useDeleteTemplate;
