import { Avatar, IconButton, ListItemText } from '@mui/material';
import { PendingUser } from '../../types';
import { UserRow, UserRowContainer } from './styled-components';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import { useContext, useState } from 'react';
import { SnackContext } from '../../../../../../../context/SnackProvider';
import { pendingProjectShareReminder } from '../../../../../../../firebase';

interface PendingUsersProps {
	pendingUsers: PendingUser[];
	getSetMembers: () => Promise<void>;
}

export default function PendingUsers({ pendingUsers, getSetMembers }: PendingUsersProps) {
	const { setSnackbarProps } = useContext(SnackContext);

	const [disableActions, setDisableActions] = useState(false);

	const handleReminder = async (pendingUser: PendingUser) => {
		setDisableActions(true);
		setSnackbarProps({
			open: true,
			severity: 'warning',
			message: `Sending reminder email to ${pendingUser.email}...`,
			hideDuration: null,
		});

		try {
			await pendingProjectShareReminder({ email: pendingUser.email });
			await getSetMembers();
			setSnackbarProps({
				open: true,
				severity: 'success',
				message: 'Successfully sent reminder email!',
			});
		} catch (err) {
			console.error(err);
			setSnackbarProps({
				open: true,
				severity: 'error',
				message: `Error sending reminder email to ${pendingUser.email}...`,
			});
		}
		setDisableActions(false);
	};

	return (
		<UserRowContainer>
			{pendingUsers.length < 1 ? (
				<h2>No pending users...</h2>
			) : (
				pendingUsers.map(pendingUser => {
					const lastSharedDate = pendingUser.lastShared
						? new Date(pendingUser.lastShared._seconds * 1000)
						: null;
					return (
						<UserRow key={pendingUser.email}>
							<Avatar>
								<AutorenewIcon />
							</Avatar>

							<ListItemText
								primary={pendingUser.email}
								secondary={`Pending${
									lastSharedDate
										? ` - Last shared on ${lastSharedDate.toLocaleDateString()}`
										: null
								}`}
							/>

							<IconButton
								title={`Send reminder email to ${pendingUser.email}`}
								onClick={() => handleReminder(pendingUser)}
								disabled={disableActions}
								size="large">
								<NotificationsActiveIcon />
							</IconButton>
						</UserRow>
					);
				})
			)}
		</UserRowContainer>
	);
}
