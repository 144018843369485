import {
	StorageError,
	deleteObject,
	getDownloadURL,
	getMetadata,
	ref,
	uploadBytesResumable,
} from 'firebase/storage';
import { v4 as uuidv4 } from 'uuid';
import { db, storage } from '../firebase';
import { FirebaseFile, Organization } from '../types';
import { splitFilename } from '.';
import { arrayUnion, doc, getDoc, updateDoc } from 'firebase/firestore';

/**
 * Handles uploading or updating a logo file to firebase storage and firestore database.
 * If an old logo exists, it will be removed before uploading the new one.
 * Returns the downloadUrl of the logo.
 *
 * @param file File to be uploaded
 * @param organizationId Id of the Organization whose logo is being uploaded
 * @param setOrganization Sets the Organization
 * @param oldLogo Optional. The existing logo file to be replaced
 */
export const uploadLogo = async (
	file: File,
	organizationId: string,
	setOrganization?: React.Dispatch<React.SetStateAction<Organization | null>>,
	oldLogo?: FirebaseFile
): Promise<string> => {
	return new Promise<string>(async (resolve, reject) => {
		const uuid = uuidv4();
		const [, extension] = splitFilename(file.name);
		const uniqueFilename = `${uuid}.${extension}`;
		const filepath = `organization_files/${uniqueFilename}`;
		const storageRef = ref(storage, filepath);

		const metadata = {
			contentDisposition: `inline; filename="${file.name}"`,
			customMetadata: {
				originalFilename: file.name,
				organizationId,
			},
		};

		const uploadTask = uploadBytesResumable(storageRef, file, metadata);

		uploadTask.on(
			'state_changed',
			snapshot => {
				// const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 10;
			},
			(error: StorageError) => {
				console.error(error);
				reject(`Failed to upload file ${file.name}...`);
			},
			async () => {
				try {
					const organizationRef = doc(db, `organizations/${organizationId}`);

					if (oldLogo) {
						const oldStorageRef = ref(storage, oldLogo.filepath);
						await deleteObject(oldStorageRef).catch(error =>
							console.error('Error deleting old logo:', error)
						);
					}

					if (oldLogo) {
						const orgDoc = await getDoc(organizationRef);
						const currentLogos = orgDoc.data()?.logo || [];
						const updatedLogos = currentLogos.filter(
							(logo: any) => logo.filepath !== oldLogo.filepath
						);

						await updateDoc(organizationRef, {
							logo: updatedLogos,
						});
					}

					// Fetch new logo's download URL and metadata
					const downloadUrl = await getDownloadURL(storageRef);
					const fetchedMetadata = await getMetadata(storageRef);
					const uploadDate = new Date(fetchedMetadata.timeCreated);
					const newFileObject: FirebaseFile = {
						filename: file.name,
						downloadUrl,
						filepath,
						uploadDate: uploadDate,
						lastUpdated: uploadDate,
					};
					// Update Firestore with the new logo
					await updateDoc(organizationRef, {
						logo: arrayUnion(newFileObject),
					});

					if (setOrganization) {
						setOrganization(prev => {
							if (prev) {
								return {
									...prev,
									logo: [newFileObject],
								};
							}
							return prev;
						});
					}

					resolve(downloadUrl);
				} catch (error) {
					console.error('Error during logo upload:', error);
					reject('Failed to upload or update logo.');
				}
			}
		);
	});
};
