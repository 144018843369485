import { useContext, useState } from 'react';
import { Row } from 'react-table';
import { ProjectData } from '../../../../../../../types';
import { SnackContext } from '../../../../../../../context/SnackProvider';
import { updateSingleProjectField } from '../../../../../../../firebase/firestore/projects';
import ClickableSpan from '../../../../../ClickableSpan';
import { Button, Dialog, DialogActions, DialogTitle, IconButton } from '@mui/material';
import { FullWidthDialogContent } from '../../../../../../styled-components/dialog';
import Spinner from '../../../../../Spinner';
import { StatusTableContext } from '../../../../StatusTableProvider';
import { MultiUserProjectRole } from '../../../../../../../types/project-roles';
import ProjectRoleAutocomplete from '../../../../../ProjectRoleAutocomplete';
import styled from 'styled-components';
import theme from '../../../../../../../styles/theme';
import { Remove } from '@mui/icons-material';

export default function MultiUserCell({
	row,
	rowEditing,
	field,
	title,
}: {
	row: Row<ProjectData>;
	rowEditing: boolean;
	field: MultiUserProjectRole;
	title: string;
}) {
	const { setSnackbarProps } = useContext(SnackContext);
	const { setStatusProjects } = useContext(StatusTableContext);

	const projectId = row.original.id as string;
	const displayValue = row.original[field]?.length
		? row.original[field]?.map(user => user.name).join(', ')
		: '-';

	const [value, setValue] = useState(row.original[field] || []);
	const [editing, setEditing] = useState(false);
	const [disabled, setDisabled] = useState(false);

	const handleOpen = (e: React.MouseEvent<HTMLSpanElement> | undefined) => {
		e?.stopPropagation();
		setValue(row.original[field] || []);
		setEditing(true);
	};

	const handleClose = () => {
		setEditing(false);
	};

	const handleSubmit = async () => {
		setDisabled(true);
		setSnackbarProps({
			open: true,
			severity: 'warning',
			message: 'Saving changes...',
			hideDuration: null,
		});

		await updateSingleProjectField(projectId, value, field);
		setStatusProjects(prev => {
			if (prev) {
				return prev.map(proj => {
					if (proj.id === projectId) {
						return {
							...proj,
							[field]: value,
						};
					} else return proj;
				});
			} else return prev;
		});

		setDisabled(false);
		setSnackbarProps({
			open: true,
			severity: 'success',
			message: 'Changes saved!',
		});
	};

	return !rowEditing ? (
		<span>{displayValue}</span>
	) : (
		<>
			<StyledSpan onClick={handleOpen}>
				{displayValue === '-' ? (
					<IconButton color="inherit">
						<Remove />
					</IconButton>
				) : (
					displayValue
				)}
			</StyledSpan>

			<Dialog
				open={editing}
				onClose={handleClose}
				onClick={event => event.stopPropagation()}>
				<DialogTitle>{title}</DialogTitle>

				<FullWidthDialogContent>
					<ProjectRoleAutocomplete
						role={field}
						inputValue={value}
						handleChange={newValue => {
							if (newValue) {
								if (Array.isArray(newValue)) setValue(newValue);
							} else {
								setValue([]);
							}
						}}
						project={row.original}
						darkMode={false}
					/>
				</FullWidthDialogContent>

				<DialogActions>
					<Button onClick={handleClose}>Close</Button>
					{!disabled ? (
						<Button onClick={handleSubmit}>Submit</Button>
					) : (
						<Spinner size={50} />
					)}
				</DialogActions>
			</Dialog>
		</>
	);
}

const StyledSpan = styled.span`
	transition: 0.2s;
	color: #fff;
	cursor: pointer;

	&:hover,
	:focus-visible {
		color: ${theme.palette.primary.main};
		text-decoration: underline;
	}
`;
