import { Stack, Typography } from '@mui/material';
import { FormNode } from '../../../../../utils/types';
import { useContext, useEffect, useState } from 'react';
import { FormV2Context } from '../../../../../context/FormV2Context.types';
import { FormSwitch } from '../FormFillV2.styles';

export default function SwitchField({ itemNode }: { itemNode: FormNode }) {
	const [nodeValue, setNodeValue] = useState<boolean>(!!itemNode.value);
	const { updateValue } = useContext(FormV2Context);

	const handleToggle = async (e: React.ChangeEvent<HTMLInputElement>) => {
		const newValue = e.target.checked;
		setNodeValue(newValue);

		await updateValue(itemNode.id, newValue);
	};

	useEffect(() => {
		setNodeValue(!!itemNode.value);
	}, [itemNode.value]);

	return (
		<Stack
			direction="row"
			spacing={1}
			alignItems="center"
			style={{ width: '50%' }}
			id={itemNode.id}>
			<Typography>Off</Typography>
			<FormSwitch
				checked={nodeValue}
				onChange={handleToggle}
				inputProps={{ 'aria-label': 'switch' }}
			/>
			<Typography>On</Typography>
		</Stack>
	);
}
