import { ExpandLess, ExpandMore } from '@mui/icons-material';
import styled from 'styled-components';

export const TeamsWrapper = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	overflow-y: hidden;
	transition: flex 0.3s slide;
`;

export const TeamsContainer = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	align-items: center;
	height: 100%;
	gap: 20px;

	@media (max-width: 1020px) {
		width: 100%;
	}

	.grid-container {
		display: flex;
		flex-wrap: wrap;
		gap: 20px;
		width: 100%;
	}

	@media (max-width: 400px) {
		padding: 10px;
	}
`;

export const ContentContainer = styled.div`
	flex: 1;
	display: flex;
	width: 100%;
	flex-direction: row;
	// height: calc(100% - 280px);
	gap: 20px;
	align-items: stretch;
`;

export const ContainerBorder = styled('div')`
	border: 1px solid rgba(89, 89, 89, 0.4);
	border-radius: 10px;
	background-color: #0a0a0c;
	width: 100%;
	overflow: hidden;
`;

export const ProjectsContainer = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	flex: 1;
	overflow: hidden;
	align-items: center;
	@media (max-width: 1020px) {
		width: 100%;
	}

	@media (max-width: 400px) {
		padding: 10px;
	}
`;

export const CardsWrapper = styled.div`
	flex: 1;
	width: 100%;
	height: 100%;
	align-items: center;
	display: flex;
	flex-direction: column;
	overflow: hidden;
	transition: flex 0.3s ease-out;
`;

export const ActionBar = styled.div`
	width: 100%;
	height: 60px;
	display: flex;
	flex-direction: row;
	gap: 5px;
	justify-content: center;
`;

export const ExpandLessIcon = styled(ExpandLess)`
	color: white;
	:hover {
		color: #ffb310;
	}
`;

export const ExpandMoreIcon = styled(ExpandMore)`
	color: black;
	:hover {
		color: #ffb310;
	}
`;

export const SearchContainer = styled.div`
	display: flex;
	flex-direction: row;
	width: 100%;
	padding: 20px 40px 5px 40px;
	gap: 10px;
	justify-content: center;
`;

export const Toggle = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	color: #ffb310;
	gap: 3px;

	.MuiSwitch-track {
		background-color: rgba(255, 255, 255, 0.5);
	}
`;

export const ScrollableContent = styled.div`
	overflow: hidden;
	overflow-y: auto;
	width: 100%;
	padding-bottom: 40px;
`;

export const LayoutContainer = styled.div`
	display: flex;
	flex-direction: column;
	height: 100%;
	overflow: hidden;
	background-color: #111114;
	gap: 20px;
	align-items: stretch;
	padding: 20px;
`;

export const RightColumn = styled.div`
	display: flex;
	flex-direction: column;
	width: 40%;
	gap: 20px;
`;

export const Details = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	border-radius: 20px;
	height: fit-content;
	width: 100%;
	background-color: transparent;
	padding: 10px;
	transition: max-height 0.2 ease-out;

	.category-top-row {
		display: flex;
		align-items: start;
	}

	.category-content {
		flex: 1;
		overflow-x: hidden;
	}

	.category-icon {
		display: flex;
		flex-direction: column;
		align-items: center;
		padding: 10px;
	}
`;
