import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { ProjectContext } from '../../../../../context/ProjectProvider';
import LinkWithCopyButton from '../../../../reusable-components/LinkWithCopyButton';
import { ProjectData } from '../../../../../types';
import { ClickAwayListener, TextField } from '@mui/material';
import styled from 'styled-components';
import { baseFontSize } from '../../../../../styles/theme';
import { isEqual } from 'lodash';

interface MultiLinkFieldProps {
	field: 'matterport' | 'twoDLinks' | 'threeDLinks' | 'pointCloudLinks' | 'invoiceLinks';
	title: string;
	setState: React.Dispatch<React.SetStateAction<Partial<ProjectData>>>;
	isActive: boolean;
	isFocused: boolean;
	setFocused: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function MultiLinkField({
	field,
	title,
	setState,
	isActive,
	isFocused,
	setFocused,
}: MultiLinkFieldProps) {
	const { project } = useContext(ProjectContext);

	const [inputValue, setInputValue] = useState('');
	const inputRef = useRef<HTMLInputElement>();

	const projLinkArr = useMemo(() => project?.[field] || [], [field, project]);
	const [linkArr, setLinkArr] = useState(projLinkArr);

	useEffect(() => {
		if (!isEqual(projLinkArr, linkArr)) {
			setState(prev => ({
				...prev,
				[field]: linkArr,
			}));
		}
	}, [field, linkArr, projLinkArr, setState]);

	useEffect(() => {
		if (isActive) setLinkArr(projLinkArr);
	}, [isActive, projLinkArr]);

	const isValidLink = (val: string, showError = false) => {
		const res = linkPrefixes.some(prefix => val.startsWith(prefix));

		if (!res && inputRef.current && showError) {
			inputRef.current.setCustomValidity('Not a valid link');
			inputRef.current.reportValidity();
		}

		return res;
	};

	const addValue = () => {
		const val = inputValue.trim();
		if (val && isValidLink(val)) {
			setLinkArr(prev => [...prev, val]);
			setInputValue('');
		}
	};

	const removeLink = (linkIdx: number) => {
		const updatedArr = [...linkArr];
		updatedArr.splice(linkIdx, 1);
		setLinkArr(updatedArr);
	};

	const handleClickAway = () => {
		const val = inputValue.trim();
		if (isValidLink(val) || val === '') {
			addValue();
			setFocused(false);
		}
	};

	const handleEnterKey = (e: React.KeyboardEvent<HTMLDivElement>) => {
		if (e.key === 'Enter' && isValidLink(inputValue, true)) {
			addValue();
		}
	};

	const handleInputChange = (
		e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
	) => {
		setInputValue(e.target.value);
		inputRef.current?.setCustomValidity('');
	};

	return !isActive ? (
		<div className="fieldInfo link-field">
			{projLinkArr.map((link: string, i: number) => (
				<LinkWithCopyButton link={link} title={title} key={i} />
			))}
		</div>
	) : !isFocused ? (
		<div
			className="fieldInfo link-field fieldInfo-edit"
			onClick={() => setFocused(true)}
			title={`Click to edit ${title} links`}>
			{linkArr.length
				? linkArr.map((link: string, i: number) => <span key={i}>{link}</span>)
				: 'N/A'}
		</div>
	) : (
		<ClickAwayListener onClickAway={handleClickAway}>
			<Container>
				{linkArr.length ? (
					<ChipContainer>
						{linkArr.map((item, idx) => {
							return (
								<Chip key={idx}>
									<ChipLink href={item} target="_blank" rel="noreferrer">
										{item}
									</ChipLink>

									<button onClick={() => removeLink(idx)}>X</button>
								</Chip>
							);
						})}
					</ChipContainer>
				) : null}

				<LinkTextField
					variant="outlined"
					value={inputValue}
					onChange={handleInputChange}
					onKeyUp={handleEnterKey}
					inputRef={inputRef}
					autoFocus
				/>
			</Container>
		</ClickAwayListener>
	);
}

const linkPrefixes = ['https://', 'http://'];

const Container = styled.div`
	display: flex;
	flex-direction: column;
	flex-grow: 1;
	gap: 10px;
	min-width: 0px;
`;

const ChipContainer = styled.div`
	display: flex;
	flex-wrap: wrap;
	gap: 5px;
	padding: 5px;
	background-color: grey;
	border-radius: 5px;
`;
const Chip = styled.div`
	background-color: rgb(41, 41, 41);
	display: flex;
	align-items: center;
	max-width: 100%;
`;

const ChipLink = styled.a`
	display: inline-block;
	margin: 0 5px;
	max-width: calc(100% - 34px);
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;

	color: #ffb310;

	&:hover,
	:focus {
		color: white;
	}
`;

const LinkTextField = styled(TextField)`
	.MuiInputBase-input {
		font-size: ${baseFontSize};
		box-sizing: border-box;
		color: #ffb310;
		padding: 3px 5px;
		height: 26px;
	}

	.MuiOutlinedInput-notchedOutline {
		border-color: #ffb310;
	}

	&:hover {
		.MuiOutlinedInput-notchedOutline {
			border-color: white;
			border-width: 2px;
		}
	}
`;
