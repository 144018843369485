import {
	UploadTask,
	getDownloadURL,
	getMetadata,
	ref,
	uploadBytesResumable,
} from 'firebase/storage';
import { v4 as uuidv4 } from 'uuid';
import { storage, uploadProjectFilePostProcess } from '../firebase';
import { FirebaseFile, ProjectData } from '../types';
import { FileType } from '../components/reusable-components/DeliverableFiles/types';
import { splitFilename } from '.';

export const uploadFile = async (
	file: File,
	projectId: string,
	deliverableKey: FileType,
	setCurrentUpload: (value: React.SetStateAction<UploadTask | null>) => void,
	setUploadDetails: React.Dispatch<React.SetStateAction<string>>,
	setProject?: React.Dispatch<React.SetStateAction<ProjectData | null>>,
	setProjects?: React.Dispatch<React.SetStateAction<ProjectData[]>>,
	deliverableSuffix: '' | 'InProgress' | 'Archive' = ''
) => {
	return new Promise<void>(async (resolve, reject) => {
		const uuid = uuidv4();
		const [, extension] = splitFilename(file.name);
		const uniqueFilename = `${uuid}.${extension}`;
		const filepath = `project_files/${uniqueFilename}`;
		const storageRef = ref(storage, filepath);

		const metadata = {
			contentDisposition: `inline; filename="${file.name}"`,
			customMetadata: {
				originalFilename: file.name,
				projectId,
			},
		};

		const uploadTask = uploadBytesResumable(storageRef, file, metadata);
		setCurrentUpload(uploadTask);

		uploadTask.on(
			'state_changed',
			snapshot => {
				const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
				setUploadDetails(`${progress.toFixed(2)}% uploaded...`);
			},
			error => {
				console.error(error);
				reject(`Failed to upload file ${file.name}...`);
			},
			async () => {
				setUploadDetails(`Updating backend...`);

				const downloadUrl = await getDownloadURL(storageRef);
				const fetchedMetadata = await getMetadata(storageRef);
				const uploadDate = new Date(fetchedMetadata.timeCreated);
				const fileObject: FirebaseFile = {
					filename: file.name,
					downloadUrl,
					filepath,
					uploadDate: uploadDate,
					lastUpdated: uploadDate,
				};

				const fileField = (deliverableKey + deliverableSuffix) as keyof ProjectData;

				await uploadProjectFilePostProcess({
					projectId,
					deliverableField: fileField,
					filename: file.name,
					downloadUrl,
					filepath,
				});

				if (setProject) {
					setProject(prev => {
						if (prev) {
							const oldArr = (prev[fileField] as FirebaseFile[]) || [];
							return {
								...prev,
								[fileField]: [...oldArr, fileObject],
							};
						} else return prev;
					});
				}

				if (setProjects) {
					setProjects(prev => {
						return prev.map(proj => {
							if (proj.id !== projectId) return proj;
							else
								return {
									...proj,
									[fileField]: [
										...((proj[fileField] as FirebaseFile[]) || []),
										fileObject,
									],
								};
						});
					});
				}

				setUploadDetails('');
				resolve();
			}
		);
	});
};
