import {
	query,
	collection,
	getFirestore,
	limit,
	orderBy,
	getDocs,
	startAfter,
	where,
	DocumentData,
	startAt,
	or,
	QueryConstraint,
	and,
} from 'firebase/firestore';
import {
	useRef,
	useState,
	useEffect,
	useCallback,
	createContext,
	useContext,
} from 'react';
import { debounce } from 'lodash';
import { FormTemplate } from '../components/screen-components/ProjectUtilityFormV2/utils/types';
import { AuthContext } from './AuthProvider';
import { useLocation } from 'react-router-dom';

const ITEMS_PER_PAGE = 5;

export type TemplatesContextType = {
	clearQuery: () => void;
	loadingForms: boolean;
	nextPage: () => void;
	onChangeQuery: (event: React.ChangeEvent<HTMLInputElement>) => void;
	page: number;
	prevPage: () => void;
	refreshData: () => void;
	searchQuery: string;
	totalPages: number;
	templates: FormTemplate[] | undefined;
	isPartial: boolean;
};

export const TemplatesContext = createContext({} as TemplatesContextType);

export const TemplatesProvider = ({ children }: { children?: React.ReactNode }) => {
	const location = useLocation();
	const { pathname } = location;
	const isPartial = pathname.includes('/partial-templates');
	const [templates, setTemplates] = useState<FormTemplate[] | undefined>();
	const [loadingForms, setLoadingForms] = useState(false);
	const [page, setPage] = useState(1);
	const [totalPages, setTotalPages] = useState<number>(0);
	const [searchQuery, setSearchQuery] = useState('');
	const [debouncedSearchQuery, setDebouncedSearchQuery] = useState('');
	const { user } = useContext(AuthContext);
	const cursorStack = useRef<{ firstVisible: DocumentData; lastVisible: DocumentData }[]>(
		[]
	);

	useEffect(() => {
		document.title = 'Templates List';
	}, []);

	const getTemplatesCollection = useCallback(
		async (pageLimit: number, queryConstraint?: QueryConstraint[]) => {
			let baseQuery = query(
				collection(getFirestore(), 'utility_forms_v2_templates'),
				orderBy('updatedAt', 'desc')
			);

			if (queryConstraint) baseQuery = query(baseQuery, ...queryConstraint);

			if (pageLimit > 0) {
				baseQuery = query(baseQuery, limit(pageLimit));
			}

			if (user?.isAdmin) {
				if (debouncedSearchQuery.length > 2) {
					baseQuery = query(
						baseQuery,
						where('searchableName', 'array-contains', debouncedSearchQuery.toLowerCase())
					);
				}

				if (isPartial) {
					baseQuery = query(baseQuery, where('isPartial', '==', isPartial));
				} else {
					baseQuery = query(
						baseQuery,
						and(where('userId', '==', ''), where('isPartial', '==', false))
					);
				}
			} else {
				if (isPartial) {
					baseQuery = query(baseQuery, where('isPartial', '==', true));
				} else {
					baseQuery = query(baseQuery, where('isPartial', '==', false));
					if (
						user?.orgOwnerIds &&
						user?.orgOwnerIds.length > 0 &&
						user?.teamIds &&
						user?.teamIds.length > 0
					) {
						baseQuery = query(
							baseQuery,
							or(
								where('teamIds', 'array-contains-any', user.teamIds),
								where('organizationIds', 'array-contains-any', user.orgOwnerIds),
								where('userId', '==', user?.id)
							)
						);
					} else if (
						user?.orgOwnerIds &&
						user?.orgOwnerIds.length > 0 &&
						(!user?.teamIds || user?.teamIds.length === 0)
					) {
						baseQuery = query(
							baseQuery,
							or(
								where('organizationIds', 'array-contains-any', user.orgOwnerIds),
								where('userId', '==', user?.id)
							)
						);
					} else if (
						(!user?.orgOwnerIds || user?.orgOwnerIds.length === 0) &&
						user?.teamIds &&
						user?.teamIds.length > 0
					) {
						baseQuery = query(
							baseQuery,
							or(
								where('teamIds', 'array-contains-any', user.teamIds),
								where('userId', '==', user?.id)
							)
						);
					} else {
						baseQuery = query(baseQuery, where('userId', '==', user?.id));
					}
				}
			}

			return await getDocs(baseQuery);
		},
		[
			debouncedSearchQuery,
			isPartial,
			user?.id,
			user?.isAdmin,
			user?.orgOwnerIds,
			user?.teamIds,
		]
	);

	const loadTotalPages = useCallback(async () => {
		let templatesCollection = await getTemplatesCollection(0);
		let templatesData = templatesCollection.docs.map(doc => ({
			...(doc.data() as FormTemplate),
			id: doc.id,
		}));

		if (debouncedSearchQuery.length > 2) {
			templatesData = templatesData.filter(template =>
				template.searchableName.includes(debouncedSearchQuery.toLowerCase())
			);
		}

		const total = Math.ceil(templatesData.length / ITEMS_PER_PAGE);

		setTotalPages(total);
		setPage(total === 0 ? 0 : 1);
	}, [getTemplatesCollection, debouncedSearchQuery]);

	const loadData = useCallback(
		async (direction: number) => {
			if (loadingForms) return;
			setLoadingForms(true);

			let queryConstraint: QueryConstraint[] = [];

			if (direction === 1 && cursorStack.current.length > 0) {
				queryConstraint.push(
					startAfter(cursorStack.current[cursorStack.current.length - 1].lastVisible)
				);
			} else if (direction === -1 && cursorStack.current.length > 1) {
				queryConstraint.push(
					startAt(cursorStack.current[cursorStack.current.length - 2].firstVisible)
				);
			}

			const templatesCollection = await getTemplatesCollection(
				ITEMS_PER_PAGE,
				queryConstraint
			);
			const templatesData = templatesCollection.docs.map(doc => ({
				...(doc.data() as FormTemplate),
				id: doc.id,
			}));

			setTemplates(templatesData);

			if (!user?.isAdmin && debouncedSearchQuery.length > 2) {
				const filteredTemplates = templatesData.filter(template =>
					template.searchableName.includes(debouncedSearchQuery.toLowerCase())
				);
				setTemplates(filteredTemplates);
			}

			if (direction === 1) {
				cursorStack.current.push({
					firstVisible: templatesCollection.docs[0],
					lastVisible: templatesCollection.docs[templatesCollection.docs.length - 1],
				});
			} else if (direction === -1) {
				cursorStack.current.pop();
			}

			setLoadingForms(false);
		},
		[
			debouncedSearchQuery,
			getTemplatesCollection,
			loadingForms,
			user?.isAdmin,
			cursorStack,
		]
	);

	const firstLoad = useCallback(async () => {
		setLoadingForms(true);

		await loadTotalPages();

		const templatesCollection = await getTemplatesCollection(ITEMS_PER_PAGE);
		const templatesData = templatesCollection.docs.map(doc => ({
			...(doc.data() as FormTemplate),
			id: doc.id,
		}));

		if (templatesCollection.docs.length > 0) {
			cursorStack.current = [
				{
					firstVisible: templatesCollection.docs[0],
					lastVisible: templatesCollection.docs[templatesCollection.docs.length - 1],
				},
			];
		}
		setTemplates(templatesData);

		if (!user?.isAdmin && debouncedSearchQuery.length > 2) {
			const filteredTemplates = templatesData.filter(template =>
				template.searchableName.includes(debouncedSearchQuery.toLowerCase())
			);
			setTemplates(filteredTemplates);
		}

		setLoadingForms(false);
	}, [loadTotalPages, getTemplatesCollection, user?.isAdmin, debouncedSearchQuery]);

	useEffect(() => {
		firstLoad();
	}, [firstLoad]);

	const nextPage = () => {
		if (totalPages && page < totalPages) {
			setPage(prev => prev + 1);
			loadData(1);
		}
	};

	const prevPage = () => {
		if (page > 1) {
			setPage(prev => prev - 1);
			loadData(-1);
		}
	};

	const onChangeQuery = (event: React.ChangeEvent<HTMLInputElement>) =>
		setSearchQuery(event.target.value);
	const clearQuery = () => setSearchQuery('');

	const debouncedSearch = useRef(
		debounce((query: string) => setDebouncedSearchQuery(query), 300)
	);

	useEffect(() => {
		setLoadingForms(true);
		debouncedSearch.current(searchQuery);
	}, [searchQuery]);

	const refreshData = () => {
		setLoadingForms(true);
		firstLoad();
	};

	return (
		<TemplatesContext.Provider
			value={{
				clearQuery,
				loadingForms,
				nextPage,
				onChangeQuery,
				page,
				prevPage,
				refreshData,
				searchQuery,
				totalPages,
				templates,
				isPartial,
			}}>
			{children}
		</TemplatesContext.Provider>
	);
};
