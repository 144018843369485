import styled, { keyframes } from 'styled-components';
import GlowLoaderIcon from './GlowLoaderIcon.png';

export default function GlowLoader({ size = 50 }: { size?: number }) {
	return <SpinningImage src={GlowLoaderIcon} alt="Loading" size={size} />;
}

const spin = keyframes`
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
`;

// Step 2: Apply the animation to an Image component
const SpinningImage = styled.img<{ size: number }>`
	width: ${props => props.size}px;
	height: ${props => props.size}px;
	animation: ${spin} 2s linear infinite;
`;
