import { MenuItem } from '@mui/material';
import { FieldLabel } from '../../../../../screens/ProjectInfo';
import {
	contractorStatuses,
	projectStatusText,
	projectStatuses,
} from '../../../../../utils';
import { useContext } from 'react';
import { AuthContext } from '../../../../../context/AuthProvider';
import { ProjectInfoTextField } from '../../styled-components';
import { ProjectContext } from '../../../../../context/ProjectProvider';

// Props for Project Status
interface ProjectStatusProps {
	isActive: boolean;
	handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

// Project Status Information
export default function ProjectStatusField({
	isActive,
	handleChange,
}: ProjectStatusProps) {
	const { user } = useContext(AuthContext);
	const { project } = useContext(ProjectContext);

	const statuses = user?.isContractor ? contractorStatuses : projectStatuses;
	const projectStatus = project?.projectStatus;
	const displayText = projectStatus ? projectStatusText(projectStatus) : '';

	return (
		<div className="infoRow">
			<FieldLabel label={'Status'} />

			{isActive ? (
				<ProjectInfoTextField
					select
					name="projectStatus"
					defaultValue={projectStatus}
					onChange={handleChange}
					fullWidth>
					{statuses.map(status => (
						<MenuItem value={status} key={status}>
							{projectStatusText(status)}
						</MenuItem>
					))}
				</ProjectInfoTextField>
			) : (
				<div className="fieldInfo">{displayText}</div>
			)}
		</div>
	);
}
