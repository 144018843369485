import { CoverImageMainProps, CoverImageProps } from './CoverImage.types';
import CoverImageItem from './CoverImageItem';
import { FormV2Context } from '../../screen-components/ProjectUtilityFormV2/context/FormV2Context.types';
import { useCallback, useContext, useEffect, useState } from 'react';
import CoverImageUpload from './CoverImageUpload';
import CoverImageDialog from './CoverImageDialog';
import { Box, Typography } from '@mui/material';
import {
	CoverContainer,
	CoverImageContainer,
	CoverLogoContainer,
} from './CoverImageStyles';
import { closestCenter, DndContext, DragEndEvent } from '@dnd-kit/core';
import { arrayMove, SortableContext } from '@dnd-kit/sortable';
import { db } from '../../../firebase';
import { doc, serverTimestamp, updateDoc } from 'firebase/firestore';

const CoverImage: React.FC<CoverImageMainProps> = ({ logoOnly, hasLogo, formId }) => {
	const emptyState = {
		downloadURL: '',
		name: '',
		filepath: '',
	};
	const imageMaxCount = 3;
	const { utilityForm } = useContext(FormV2Context);
	const images = utilityForm?.coverImage || [];
	const logo = utilityForm?.clientLogo
		? [
				{
					downloadURL: utilityForm?.clientLogo || '',
					name: 'logo',
					filepath: '',
				},
		  ]
		: [];
	const [openDialog, setOpenDialog] = useState<boolean>(false);
	const [editData, setEditData] = useState<CoverImageProps>(emptyState);
	const [editLogo, setEditLogo] = useState<boolean>(false);
	const [localPhotos, setLocalPhotos] = useState<CoverImageProps[]>(images);

	const uploadHandler = () => {
		setEditData(emptyState);
		setOpenDialog(true);
	};

	const updateCoverImage = useCallback(
		async (value: any): Promise<void> => {
			if (value === undefined) return;

			const itemRef = doc(db, `utility_forms_v2/${formId}`);
			await updateDoc(itemRef, {
				coverImage: value,
				updatedAt: serverTimestamp(),
			});
		},
		[formId]
	);

	const handleDragEnd = useCallback(
		(event: DragEndEvent) => {
			const { active, over } = event;

			if (over && active.id !== over.id) {
				const oldIndex = localPhotos.findIndex(item => item.downloadURL === active.id);
				const newIndex = localPhotos.findIndex(item => item.downloadURL === over.id);

				const newPhotos = arrayMove(localPhotos, oldIndex, newIndex);

				setLocalPhotos(newPhotos);
				updateCoverImage(newPhotos).catch(console.error);
			}
		},
		[localPhotos, updateCoverImage]
	);

	useEffect(() => {
		if (utilityForm?.coverImage) setLocalPhotos(utilityForm?.coverImage);
	}, [utilityForm?.coverImage]);

	return (
		<>
			<CoverContainer haslogo={hasLogo ? 1 : 0} logoonly={logoOnly ? 1 : 0}>
				{(hasLogo || logoOnly) && (
					<Box position={'relative'}>
						<Typography
							variant="label"
							color="newText.primary"
							sx={{ display: 'inline-block', mb: '8px' }}>
							Company logo
						</Typography>
						<CoverLogoContainer hasborder={hasLogo ? 1 : 0}>
							{logo.map((item, index) => (
								<CoverImageItem
									key={index}
									index={index}
									image={item}
									hasLogo={hasLogo || !logoOnly}
									handleClick={() => {
										setEditData(item);
										setOpenDialog(true);
										setEditLogo(true);
									}}
								/>
							))}
							{!logo.length && (
								<CoverImageUpload onClick={() => [uploadHandler(), setEditLogo(true)]} />
							)}
						</CoverLogoContainer>
					</Box>
				)}
				{!logoOnly && (
					<Box>
						<Typography
							variant="label"
							color="newText.primary"
							sx={{ display: 'inline-block', mb: '8px' }}>
							Cover images (up to 3)
						</Typography>
						<DndContext onDragEnd={handleDragEnd} collisionDetection={closestCenter}>
							<SortableContext items={localPhotos.map(photo => photo.downloadURL)}>
								<CoverImageContainer>
									{localPhotos.map((item, index) => (
										<CoverImageItem
											key={index}
											index={index}
											image={item}
											handleClick={() => {
												setEditData(item);
												setOpenDialog(true);
												setEditLogo(false);
											}}
										/>
									))}
									{images.length < imageMaxCount && (
										<CoverImageUpload
											onClick={() => [uploadHandler(), setEditLogo(false)]}
										/>
									)}
								</CoverImageContainer>
							</SortableContext>
						</DndContext>
					</Box>
				)}
			</CoverContainer>
			{editData && !!Object.keys(editData).length && (
				<CoverImageDialog
					openDialog={openDialog}
					handleClose={() => setOpenDialog(false)}
					image={editData}
					hasLogo={editLogo}
				/>
			)}
		</>
	);
};

export default CoverImage;
