import { FC, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Autocomplete, TextField } from '@mui/material';
import styled from 'styled-components';
import { ProcoreProjects } from '../../../../../../types/procore';
import { getOrganization, getProcoreProjects } from '../../../../../../firebase';
import { FieldLabel } from '../../../../../../screens/ProjectInfo';
import { Organization, ProjectDataProcore } from '../../../../../../types';
import { ProjectContext } from '../../../../../../context/ProjectProvider';
import Loading from '../../../../ProjectUtilityFormV2/components/Loading/Loading';

type ProcoreOption = {
	label: string;
	id: string;
};

type ProcoreInputProps = {
	orgId: string;
	orgName: string;
	onChange: (data: ProjectDataProcore) => void;
	isActive: boolean;
};

const emptyProcoreOption: ProcoreOption = {
	label: 'None',
	id: '',
};

const ProcoreInput: FC<ProcoreInputProps> = ({ orgId, orgName, isActive, onChange }) => {
	const { project } = useContext(ProjectContext);
	const [projectOrg, setProjectOrg] = useState<Organization>();
	const [procoreProjects, setProcoreProjects] = useState<ProcoreProjects>();
	const [procoreOption, setProcoreOption] = useState<ProcoreOption>(emptyProcoreOption);

	const getDefaultInputValue = useCallback(() => {
		const defaultValue = project?.procore?.find(
			procore => procore.organizationId === orgId
		);

		if (defaultValue) {
			setProcoreOption({
				label: defaultValue.procoreProjectId,
				id: defaultValue.procoreProjectId,
			});
		}
	}, [orgId, project?.procore]);

	useEffect(() => {
		getDefaultInputValue();
	}, [getDefaultInputValue]);

	const getOrganizationData = useCallback(async () => {
		const organizationDoc = await getOrganization({ id: orgId });
		const organizationData = organizationDoc.data as Organization;
		setProjectOrg(organizationData);
	}, [orgId]);

	const getListCompanies = async (companyId: string) => {
		const response = await getProcoreProjects({
			companyId: companyId,
		});
		const data: ProcoreProjects = response.data as ProcoreProjects;

		data.sort((a, b) => {
			if (a.name < b.name) {
				return -1;
			}
			if (a.name > b.name) {
				return 1;
			}
			return 0;
		});

		setProcoreProjects(data);
	};

	useEffect(() => {
		getOrganizationData();
	}, [getOrganizationData]);

	useEffect(() => {
		if (projectOrg?.procoreId) {
			getListCompanies(projectOrg?.procoreId);
		} else {
			setProcoreProjects([]);
		}
	}, [projectOrg?.procoreId]);

	const getProjectProcoreObj = () => {
		if (project?.procore?.length) {
			return project.procore.find(procore => procore.organizationId === orgId);
		}
	};

	const handleChange = (value: ProcoreOption) => {
		const emptyData: ProjectDataProcore = {
			procoreProjectId: '',
			procoreOrganizationId: '',
			organizationId: orgId,
		};

		if (value) {
			const selected = procoreProjects?.find(
				project => project.id.toString() === value.id
			);

			if (selected && projectOrg && projectOrg?.procoreId) {
				const data: ProjectDataProcore = {
					procoreProjectId: selected?.id.toString(),
					procoreOrganizationId: projectOrg?.procoreId,
					organizationId: orgId,
				};
				setProcoreOption({
					label: selected.display_name,
					id: selected.id.toString(),
				});
				onChange(data);
			} else {
				setProcoreOption(emptyProcoreOption);
				onChange(emptyData);
			}
		} else {
			setProcoreOption(emptyProcoreOption);
			onChange(emptyData);
		}
	};

	const getOptions = useMemo(
		() => [
			emptyProcoreOption,
			...(procoreProjects?.map(
				project =>
					({
						label: project.display_name,
						id: project.id.toString(),
					} as ProcoreOption)
			) || []),
		],
		[procoreProjects]
	);

	return (
		<div className="infoRow">
			<FieldLabel label={`Org ${orgName} Procore Id`} />
			{isActive ? (
				<>
					{procoreProjects === undefined ? (
						<Loading />
					) : (
						<AutoCompleteStyled
							options={getOptions || []}
							value={procoreOption}
							onChange={(e, value) => handleChange(value as ProcoreOption)}
							isOptionEqualToValue={(option, value) =>
								(option as ProcoreOption).id === (value as ProcoreOption).id
							}
							renderInput={params => (
								<TextField
									{...params}
									variant="outlined"
									size="small"
									focused
									sx={{
										input: { color: 'white' },
									}}
								/>
							)}
						/>
					)}
				</>
			) : (
				<span className="fieldInfo">{getProjectProcoreObj()?.procoreProjectId}</span>
			)}
		</div>
	);
};

const AutoCompleteStyled = styled(Autocomplete)`
	width: 100%;
	height: 43px;
`;

export default ProcoreInput;
