import {
	Button,
	Checkbox,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	FormControlLabel,
	ListItem,
	styled,
	Dialog as MuiDialog,
} from '@mui/material';
import { useCallback, useContext, useEffect, useState } from 'react';
import {
	EmptyContent,
	IconContainer,
	IconsContainer,
	ListItemContainer,
} from './PhotoUploadManager.styles';
import useDownloadPhotos from './useDownloadPhotos';
import {
	FormNode,
	PhotoV2,
} from '../../../screen-components/ProjectUtilityFormV2/utils/types';
import { FormV2Context } from '../../../screen-components/ProjectUtilityFormV2/context/FormV2Context.types';
import { Dialog } from '../../Dialog';
import Loading from '../../../screen-components/ProjectUtilityFormV2/components/Loading/Loading';
import theme from '../../../../styles/theme';
import PreviewImage from '../../../reusable-components/PreviewImage';
import { movePhotosToAnotherItem } from '../../../../firebase';
import { SnackContext } from '../../../../context/SnackProvider';
import { FlattenedItem } from '../../../screen-components/Form/components/FormWrapper/types';

interface PhotoUploadManagerProps {
	open: boolean;
	onClose: () => void;
	itemNode: FormNode;
	uploadedPhotos: PhotoV2[];
	orderedItems: FlattenedItem[];
}

const PhotoUploadManager: React.FC<React.PropsWithChildren<PhotoUploadManagerProps>> = ({
	open,
	onClose,
	itemNode,
	uploadedPhotos,
	orderedItems,
}) => {
	const { setSnackbarProps } = useContext(SnackContext);
	const { photoUploadQueue, deleteNodePhoto } = useContext(FormV2Context);
	const { downloadPhotos, downloadZip } = useDownloadPhotos();

	const [combinedPhotos, setCombinedPhotos] = useState<PhotoV2[]>([]);
	const [downloadMessage, setDownloadMessage] = useState<string>('');
	const [selectedPhotos, setSelectedPhotos] = useState<PhotoV2[]>([]);
	const [showMoveToDialog, setShowMoveToDialog] = useState<boolean>(false);
	const [allPhotoNodes, setAllPhotoNodes] = useState<FormNode[]>([]);
	const [targetPhotoNodeId, setTargetPhotoNodeId] = useState<string>('');
	const [moveToLoading, setMoveToLoading] = useState<boolean>(false);
	const [openConfirmDialog, setOpenConfirmDialog] = useState(false);

	useEffect(() => {
		setCombinedPhotos([
			...uploadedPhotos,
			...(!!photoUploadQueue
				? photoUploadQueue?.filter(p => p?.nodeId === itemNode.id).map(p => p.photo)
				: []),
		]);
	}, [itemNode.id, photoUploadQueue, uploadedPhotos]);

	const handleDownloadAll = async () => {
		await downloadPhotos(combinedPhotos, itemNode, setDownloadMessage);
		setDownloadMessage('');
		handleClose();
	};

	const handleZip = async () => {
		await downloadZip(combinedPhotos, itemNode, setDownloadMessage);
		setDownloadMessage('');
		handleClose();
	};

	const handleDelete = () => {
		selectedPhotos.forEach(photo => {
			deleteNodePhoto(itemNode.id, photo);
		});
		setSelectedPhotos([]);
		onClose();
		setOpenConfirmDialog(false);
	};

	const handleCheckbox = (photo: PhotoV2) => {
		if (selectedPhotos.includes(photo))
			setSelectedPhotos(selectedPhotos.filter(p => p.name !== photo.name));
		else setSelectedPhotos([...selectedPhotos, photo]);
	};

	const handleSelectAll = () => {
		if (selectedPhotos.length === combinedPhotos.length) setSelectedPhotos([]);
		else setSelectedPhotos(combinedPhotos);
	};

	const handleClose = () => {
		setSelectedPhotos([]);
		onClose();
	};

	const getTargetPhotoNodeTitle = () => {
		return allPhotoNodes.find(item => item.id === targetPhotoNodeId)?.displayTitle || '';
	};

	const handleCloseMoveToDialog = () => {
		setShowMoveToDialog(!showMoveToDialog);
	};

	const getAllFormPhotos = useCallback(() => {
		const nodes = orderedItems
			.filter(item => item.node.type === 'photos')
			.map(item => item.node);
		setAllPhotoNodes(nodes);
	}, [orderedItems]);

	useEffect(() => {
		getAllFormPhotos();
	}, [orderedItems, getAllFormPhotos]);

	const handleMovePhotosTo = async () => {
		setMoveToLoading(true);
		const data = {
			from: itemNode.id,
			to: targetPhotoNodeId,
			photosUrl: selectedPhotos.map(item => item.url) as string[],
		};

		try {
			await movePhotosToAnotherItem(data);

			handleCloseMoveToDialog();
			handleClose();
			setSnackbarProps({
				open: true,
				message: `Success! Photos moved to ${getTargetPhotoNodeTitle()}`,
				severity: 'success',
			});
		} catch (error) {
			setSnackbarProps({
				open: true,
				message: `Error moving photos`,
				severity: 'error',
			});
		} finally {
			setMoveToLoading(false);
		}
	};

	const getParentTitle = (node: FormNode): string => {
		let hasParent = false;

		const generateParentTitle = (node: FormNode): string => {
			if (!node.parentId) return '';
			const parent = orderedItems.find(
				item => item.node.id === node.parentId
			) as FlattenedItem;

			if (!parent) return '';

			hasParent = true;
			return `${generateParentTitle(parent.node)} - ${parent.node.displayTitle}`;
		};

		const parentTitle = generateParentTitle(node);

		return hasParent
			? parentTitle.slice(2) + ' - ' + node.displayTitle
			: node.displayTitle;
	};

	return (
		<>
			<Dialog
				open={open}
				onClose={handleClose}
				title="Manage Photos"
				fullWidth={true}
				bottomActions={
					<DialogBottomActions>
						<span style={{ padding: 8 }}>{downloadMessage.split('...')[1]}</span>
						{downloadMessage === '' && (
							<>
								{!!selectedPhotos.length && (
									<>
										<Button
											color="primary"
											onClick={() => setShowMoveToDialog(true)}
											sx={{ textTransform: 'none' }}>
											Move To ({selectedPhotos.length})
										</Button>
										<Button
											color="primary"
											onClick={() => setOpenConfirmDialog(true)}
											sx={{ textTransform: 'none' }}>
											Delete Selected Photos ({selectedPhotos.length})
										</Button>
									</>
								)}

								<Button
									color="primary"
									onClick={handleZip}
									sx={{ textTransform: 'none' }}>
									Download Zip
								</Button>
								<Button
									color="primary"
									onClick={handleDownloadAll}
									sx={{ textTransform: 'none' }}>
									Download All Files
								</Button>
							</>
						)}
					</DialogBottomActions>
				}>
				<StyledFormControlLabel
					onChange={handleSelectAll}
					control={
						<StyledCheckbox checked={selectedPhotos.length === combinedPhotos.length} />
					}
					label={`${
						selectedPhotos.length === combinedPhotos.length ? 'Unselect' : 'Select'
					} all
				photos`}
				/>
				{combinedPhotos.length > 0 ? (
					combinedPhotos.map((photo, index) => (
						<ListItemContainer key={index}>
							<IconContainer>
								<StyledCheckbox
									onClick={() => handleCheckbox(photo)}
									inputProps={{ 'aria-label': 'controlled' }}
									checked={selectedPhotos.includes(photo)}
								/>
							</IconContainer>
							{photo.url && <PreviewImage imageUrl={photo.url} imageName={photo.name} />}
							<ListItem style={{ paddingLeft: '0' }}>{photo.originalName}</ListItem>
							<IconsContainer>
								{!photo.url && (
									<IconContainer>
										<Loading size={36} />
									</IconContainer>
								)}
							</IconsContainer>
						</ListItemContainer>
					))
				) : (
					<EmptyContent>No photos uploaded or queued.</EmptyContent>
				)}
			</Dialog>

			<MuiDialog open={showMoveToDialog}>
				<DialogTitle>Move To</DialogTitle>
				<DialogContent>
					<DialogContentText>Select the target photo field</DialogContentText>
					<SelectStyles
						onChange={e => {
							e.preventDefault();
							setTargetPhotoNodeId(e.target.value);
						}}>
						<option value="">Select option</option>
						{allPhotoNodes.map(item => {
							if (item.id === itemNode.id) return null;
							return (
								<option value={item.id} key={item.id}>
									{getParentTitle(item)}
								</option>
							);
						})}
					</SelectStyles>
				</DialogContent>
				<DialogActions>
					{moveToLoading ? (
						<Loading size={26} />
					) : (
						<>
							<Button onClick={handleCloseMoveToDialog}>Cancel</Button>
							<Button onClick={handleMovePhotosTo}>Move</Button>
						</>
					)}
				</DialogActions>
			</MuiDialog>
			<Dialog
				open={openConfirmDialog}
				onClose={() => setOpenConfirmDialog(false)}
				title={`${selectedPhotos.length} photos selected`}
				bottomActions={
					<>
						<Button onClick={() => setOpenConfirmDialog(false)}>Cancel</Button>
						<Button onClick={handleDelete}>Confirm</Button>
					</>
				}>
				<span>Are you sure you want to delete the selected photos?</span>
			</Dialog>
		</>
	);
};

const StyledCheckbox = styled(Checkbox)`
	color: ${theme.palette.primary.main};

	&.Mui-checked {
		color: ${theme.palette.primary.main};
	}
`;

const StyledFormControlLabel = styled(FormControlLabel)`
	align-self: flex-start;
	margin-left: -5px;
	gap: 30px;
	.MuiTypography-root {
		font-size: 0.875rem;
	}
`;

const SelectStyles = styled('select')`
	border-radius: 6px;
	height: 40px;
	border: 1px solid ${theme.palette.primary.main};
`;

const DialogBottomActions = styled('div')`
	display: flex;
	align-items: center;
`;

export default PhotoUploadManager;
