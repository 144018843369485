import {
	Button,
	Checkbox,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	FormControlLabel,
} from '@mui/material';
import { UserWithData } from '../../../../types';
import { UserTag, UserTagTitleMap } from '../../../../types/project-roles';
import { useContext } from 'react';
import { SnackContext } from '../../../../context/SnackProvider';
import { AuthContext } from '../../../../context/AuthProvider';
import { updateUserTags } from '../../../../firebase';
import { getEntries } from '../../../../utils/typescript-utils';

export default function UserTagDialog({
	open,
	onClose,
	userTagsEdit,
	setUserTagsEdit,
}: {
	open: boolean;
	onClose: () => void;
	userTagsEdit: UserWithData | null;
	setUserTagsEdit: React.Dispatch<React.SetStateAction<UserWithData | null>>;
}) {
	const { setSnackbarProps } = useContext(SnackContext);
	const { setUsersWithData, firebaseAuthData, setUser } = useContext(AuthContext);

	const handleTagsChange = async (checked: boolean, tag: UserTag) => {
		setSnackbarProps({
			open: true,
			message: 'Saving changes...',
			severity: 'warning',
			hideDuration: null,
		});

		// Update states
		const updatedUser = {
			...userTagsEdit,
			userTags: {
				...(userTagsEdit?.userTags || {}),
				[tag as UserTag]: checked,
			},
		} as UserWithData;

		setUserTagsEdit(updatedUser);
		setUsersWithData(prev => {
			// Splice prev instead of map so that the whole table doesn't get re-rendered.
			const idx = prev.findIndex(u => u.id === updatedUser.id);
			prev.splice(idx, 1, updatedUser);
			return prev;
		});

		// update firestore
		await updateUserTags({
			uid: updatedUser.id,
			[tag]: checked,
		});

		// update user object if current user is the one being changed
		if (userTagsEdit?.id === firebaseAuthData?.uid) {
			setUser((prev: any) => {
				return {
					...prev,
					userTags: {
						...(prev?.userTags || {}),
						[tag]: checked,
					},
				};
			});
		}

		setSnackbarProps({
			open: true,
			message: 'Changes saved!',
			severity: 'success',
		});
	};

	return (
		<Dialog open={open} onClose={onClose}>
			<DialogTitle>Editing User Tags</DialogTitle>

			<DialogContent>
				<strong>{`${userTagsEdit?.fullName || 'Unnamed User'}`}</strong>
				<span>{`${userTagsEdit?.email || 'Unknown Email'}`}</span>
				{getEntries(UserTagTitleMap).map(([key, value]) => {
					const tag = key as UserTag;
					const tagTitle = value;
					const hasTag = !!userTagsEdit?.userTags?.[tag];

					return (
						<FormControlLabel
							control={
								<Checkbox
									checked={hasTag}
									onChange={(e, checked) => handleTagsChange(checked, tag)}
								/>
							}
							label={tagTitle}
							key={tag}
						/>
					);
				})}
			</DialogContent>

			<DialogActions>
				<Button onClick={onClose}>Close</Button>
			</DialogActions>
		</Dialog>
	);
}
