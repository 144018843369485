import { useState, useEffect, createContext, useContext } from 'react';
import {
	FormTemplate,
	NodeType,
} from '../components/screen-components/ProjectUtilityFormV2/utils/types';
import useReorder from '../components/screen-components/NodesReorder/hooks/useReorder';
import { AuthContext } from './AuthProvider';
import { arrayUnion, doc, getDoc, serverTimestamp, updateDoc } from 'firebase/firestore';
import { db } from '../firebase';
import { v4 as uuidv4 } from 'uuid';
import { usePreviousUrl } from '../utils/usePreviousURL';
import { useLocation } from 'react-router-dom';
type OrderInfo = {
	id: string;
	order: number;
	level: number;
	parentId: string;
};
export type TemplatesContextType = {
	templateName: string;
	isTemplate: boolean;
	tempId: string;
	setTempId: React.Dispatch<React.SetStateAction<string>>;
	setIsTemplate: React.Dispatch<React.SetStateAction<boolean>>;
	updateTemplateDisplayTitle: (nodeId: string, displayTitle: any) => Promise<void>;
	updateTemplateOrder: (info: OrderInfo[]) => Promise<void>;
	assignTemplate: (
		projectIds: string[],
		teamIds: string[],
		organizationIds: string[]
	) => Promise<void>;
	addTemplateNode: (
		displayTitle: string,
		type: NodeType,
		options?: string[],
		parentId?: string,
		formId?: string,
		nodeStack?: any
	) => Promise<void>;
	deleteTemplateNode: (nodeId: string) => Promise<void>;
	duplicateTemplateNode: (nodeId: string) => Promise<void>;
	prevURL: any;
	template: FormTemplate | undefined;
	isPartial: boolean;
};

export const TemplateContext = createContext({} as TemplatesContextType);

export const TemplateProvider = ({ children }: { children?: React.ReactNode }) => {
	const { reorderableItem } = useReorder();
	const prevURL = usePreviousUrl();
	const { user, firebaseAuthData } = useContext(AuthContext);
	const location = useLocation();
	const { pathname } = location;
	const isPartial = pathname.includes('/partial-templates');

	const [isTemplate, setIsTemplate] = useState<boolean>(false);
	const [tempId, setTempId] = useState<string>('');
	const [template, setTemplate] = useState<FormTemplate | undefined>(undefined);
	const templateName = reorderableItem?.name || '';

	useEffect(() => {
		setIsTemplate(!!tempId);
		if (tempId) {
			getTemplate(tempId);
		}
	}, [tempId]);

	const updateTemplateOrder = async (info: OrderInfo[]) => {
		const itemRef = doc(db, `utility_forms_v2_templates`, tempId);
		const templateSnapshot = (await getDoc(itemRef)).data();
		const newData = templateSnapshot?.nodes?.map((node: any) => {
			const i = info.find(i => i.id === node.id);
			if (i) {
				node.order = i.order;
				node.level = i.level;
				node.parentId = i.parentId;
			}
			return node;
		});
		await updateDoc(itemRef, {
			nodes: newData,
			updatedAt: serverTimestamp(),
			updatedBy: {
				id: firebaseAuthData?.uid || '',
				name: user?.fullName || '',
			},
		});
	};

	const updateTemplateDisplayTitle = async (
		nodeId: string,
		displayTitle: any
	): Promise<void> => {
		if (!displayTitle) return;
		const itemRef = doc(db, `utility_forms_v2_templates`, tempId);
		const templateSnapshot = (await getDoc(itemRef)).data();
		const newData = templateSnapshot?.nodes?.map((node: any) => {
			if (node.id === nodeId) {
				node.displayTitle = displayTitle;
			}
			return node;
		});
		await updateDoc(itemRef, {
			nodes: newData,
			updatedAt: serverTimestamp(),
			updatedBy: {
				id: firebaseAuthData?.uid || '',
				name: user?.fullName || '',
			},
		});
	};

	const addTemplateNode = async (
		displayTitle: string,
		type: NodeType,
		options?: string[],
		parentId?: string,
		id?: string
	): Promise<void> => {
		const itemRef = doc(db, `utility_forms_v2_templates`, tempId);
		const templateSnapshot = (await getDoc(itemRef)).data()?.nodes;
		const parentElem = templateSnapshot.find((node: any) => node.id === parentId);
		const parentsTitle = parentElem?.displayTitle || '';

		const newNode = {
			id: id ?? uuidv4(),
			displayTitle,
			type,
			formId: parentElem?.formId || tempId,
			options: options || [],
			parentId: parentId || '',
			parentsTitle,
			order: templateSnapshot.length,
			level: !!parentElem ? parentElem.level + 1 : 0,
			projectId: parentElem?.projectId || '',
			required: type !== 'node',
		};

		if (parentsTitle.length) {
			const findChildren = templateSnapshot.filter(
				(node: any) => node.parentId === parentId
			);
			const lastChild = findChildren[findChildren.length - 1];
			const childIndex = !!lastChild
				? templateSnapshot.findIndex((node: any) => node.id === lastChild.id)
				: 0;
			templateSnapshot.splice(childIndex + 1, 0, newNode);

			templateSnapshot.forEach((node: any, index: number) => {
				node.order = index;
			});
		} else {
			templateSnapshot.push(newNode);
		}

		await updateDoc(itemRef, {
			nodes: templateSnapshot,
			updatedAt: serverTimestamp(),
			updatedBy: {
				id: firebaseAuthData?.uid || '',
				name: user?.fullName || '',
			},
		});
	};

	const deleteTemplateNode = async (nodeId: string) => {
		const itemRef = doc(db, `utility_forms_v2_templates`, tempId);
		const templateSnapshot = (await getDoc(itemRef)).data()?.nodes;
		const newData = templateSnapshot.filter(
			(node: any) => node.id !== nodeId && node.parentId !== nodeId
		);
		newData.forEach((node: any, index: number) => {
			node.order = index;
		});
		await updateDoc(itemRef, {
			nodes: newData,
			updatedAt: serverTimestamp(),
			updatedBy: {
				id: firebaseAuthData?.uid || '',
				name: user?.fullName || '',
			},
		});
	};

	const duplicateTemplateNode = async (nodeId: string) => {
		const itemRef = doc(db, `utility_forms_v2_templates`, tempId);
		const templateSnapshot = (await getDoc(itemRef)).data()?.nodes;
		const node = templateSnapshot.find((node: any) => node.id === nodeId);
		const nodeIndex = templateSnapshot.findIndex((node: any) => node.id === nodeId);
		const children = templateSnapshot.filter((node: any) => node.parentId === nodeId);

		const newNode = {
			...node,
			id: uuidv4(),
			displayTitle: `${node.displayTitle} (Copy)`,
			order: nodeIndex + 1,
		};
		if (children.length) {
			const newChildren = children.map((child: any, index: number) => ({
				...child,
				id: uuidv4(),
				parentId: newNode.id,
			}));
			templateSnapshot.splice(nodeIndex + 1, 0, newNode, ...newChildren);
		} else {
			templateSnapshot.splice(nodeIndex + 1, 0, newNode);
		}

		templateSnapshot.forEach((node: any, index: number) => {
			node.order = index;
		});
		await updateDoc(itemRef, {
			nodes: templateSnapshot,
			updatedAt: serverTimestamp(),
			updatedBy: {
				id: firebaseAuthData?.uid || '',
				name: user?.fullName || '',
			},
		});
	};

	const assignTemplate = async (
		projectIds: string[],
		teamIds: string[],
		organizationIds: string[]
	) => {
		const itemRef = doc(db, `utility_forms_v2_templates`, tempId);
		if (projectIds.length > 0) {
			await updateDoc(itemRef, {
				projectIds: arrayUnion(...projectIds),
				updatedAt: serverTimestamp(),
				updatedBy: {
					id: firebaseAuthData?.uid || '',
					name: user?.fullName || '',
				},
			});
		}

		if (teamIds.length > 0 && organizationIds.length > 0) {
			await updateDoc(itemRef, {
				teamIds: arrayUnion(...teamIds),
				organizationIds: arrayUnion(...organizationIds),
				updatedAt: serverTimestamp(),
				updatedBy: {
					id: firebaseAuthData?.uid || '',
					name: user?.fullName || '',
				},
			});
		}
	};

	const getTemplate = async (templateId: string) => {
		const itemRef = doc(db, `utility_forms_v2_templates`, templateId);
		const templateSnapshot = (await getDoc(itemRef)).data() as FormTemplate;
		setTemplate(templateSnapshot);
	};

	return (
		<TemplateContext.Provider
			value={{
				templateName,
				tempId,
				setTempId,
				isTemplate,
				setIsTemplate,
				updateTemplateDisplayTitle,
				updateTemplateOrder,
				assignTemplate,
				addTemplateNode,
				deleteTemplateNode,
				duplicateTemplateNode,
				prevURL,
				template,
				isPartial,
			}}>
			{children}
		</TemplateContext.Provider>
	);
};
