import styled from 'styled-components';
import { LinearProgress } from '@mui/material';
import GlowLoader from './GlowLoader/GlowLoader';

export default function LoadingScreen({
	message,
	title,
	size = 150,
	textColor = 'white',
	progress,
	style,
	className,
}: {
	message?: string;
	title?: string;
	size?: number;
	textColor?: 'white' | 'black';
	progress?: number;
	style?: React.CSSProperties;
	className?: string;
}) {
	return (
		<Container $color={textColor} style={style} className={className}>
			{title ? <h2>{title}</h2> : null}
			<GlowLoader size={size} />
			{message ? <h3>{message}</h3> : null}
			{progress !== undefined ? (
				<LinearProgContainer>
					<LinearProgress
						variant="determinate"
						value={progress}
						color="primary"
						style={{ flexGrow: 1 }}
					/>
					<LinearProgNumber>{`${Math.floor(progress)}%`}</LinearProgNumber>
				</LinearProgContainer>
			) : null}
		</Container>
	);
}

const Container = styled.div<{ $color: string }>`
	display: flex;
	flex-direction: column;
	gap: 20px;
	justify-content: center;
	align-items: center;

	color: ${({ $color }) => $color};
	height: 100%;
	> * {
		margin: 0;
	}
`;

const LinearProgContainer = styled.div`
	display: flex;
	align-items: center;
	gap: 10px;
	width: 50%;
`;

const LinearProgNumber = styled.h3`
	width: 40px;
	margin: 0;
`;
