import logo from '../../assets/circleLogo.png';
import styled from 'styled-components';

export default function LogoContainer({ children }: { children: React.ReactNode }) {
	return (
		<Container>
			<Logo src={`${logo}`} alt="logoimg" />
			{children}
		</Container>
	);
}

const Container = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 10px;

	background-color: black;
	padding: 20px;
	border-radius: 15px;
`;

const Logo = styled.img`
	width: 65px;
	height: 65px;
	margin-bottom: 20px;
`;
