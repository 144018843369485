import { IconButton } from '@mui/material';
import Layout from '../../components/reusable-components/Layout';
import { ChevronLeft, ChevronRight } from '@mui/icons-material';
import {
	ListButtonsContainer,
	ListContainer,
	ListContent,
	ListTitle,
	ListTitleContainer,
} from '../../components/reusable-components/List';
import TemplateListItem from '../../components/screen-components/TemplateList/TemplateListItem';
import { FormTemplate } from '../../components/screen-components/ProjectUtilityFormV2/utils/types';
import styled from 'styled-components';
import { useContext, useState } from 'react';
import { TemplatesContext } from '../../context/TemplatesProvider';
import Searchbar from '../../components/screen-components/TemplateList/Searchbar';
import { addTemplate } from '../../firebase';
import InputDialog from '../../components/reusable-components/InputDialog';
import { useNavigate } from 'react-router-dom';

const TemplatesList = () => {
	const {
		clearQuery,
		loadingForms,
		nextPage,
		onChangeQuery,
		page,
		prevPage,
		refreshData,
		searchQuery,
		totalPages,
		templates,
		isPartial,
	} = useContext(TemplatesContext);
	const navigate = useNavigate();

	const title = isPartial ? 'Partial Templates' : 'Templates';
	const titleAction = isPartial ? 'Partial Template' : 'Template';

	const [addTemplateDialog, setAddTemplateDialog] = useState(false);
	const [addingTemplate, setAddingTemplate] = useState(false);

	const handleAddTemplate = () => setAddTemplateDialog(true);

	const onAddTemplate = async (name: string) => {
		setAddTemplateDialog(false);
		setAddingTemplate(true);
		const result = await addTemplate({ name, isPartial });
		const { templateId } = result.data as any;
		setAddingTemplate(false);
		refreshData();
		navigate(isPartial ? `/partial-templates/${templateId}` : `/templates/${templateId}`);
	};

	return (
		<Layout LeftNavComponent={<h1>{title}</h1>}>
			<Container>
				<Searchbar
					query={searchQuery}
					handleInputChange={onChangeQuery}
					clearQuery={clearQuery}
					onAdd={handleAddTemplate}
					name={title}
					showButton={true}
					style={{ width: '100%', marginBottom: 20 }}
				/>
				<ListButtonsContainer>
					<IconButton
						onClick={prevPage}
						disabled={loadingForms || addingTemplate || page === 1}>
						<ChevronLeft style={{ fontSize: 28 }} color="primary" />
					</IconButton>
					<TextContainer>
						{page} / {totalPages}
					</TextContainer>
					<IconButton
						onClick={nextPage}
						disabled={loadingForms || addingTemplate || page === totalPages}>
						<ChevronRight style={{ fontSize: 28 }} color="primary" />
					</IconButton>
				</ListButtonsContainer>
				<ListContainer>
					<ListTitleContainer>
						<ListTitle>{title}</ListTitle>
					</ListTitleContainer>
					<ListContent
						list={templates}
						loading={loadingForms || addingTemplate}
						renderItem={(template: FormTemplate) => (
							<TemplateListItem
								key={template.id}
								template={template}
								isPartial={isPartial}
							/>
						)}
						emptyMessage="No templates to display..."
						loadingMessage="Loading templates..."
					/>
				</ListContainer>
			</Container>
			<InputDialog
				handleClose={() => setAddTemplateDialog(false)}
				handleSave={onAddTemplate}
				open={addTemplateDialog}
				label={`New ${titleAction} name`}
				title={`Add ${titleAction.toLocaleLowerCase()}`}
			/>
		</Layout>
	);
};

const TextContainer = styled.div`
	color: #ffb310;
	font-size: 16px;
	font-weight: 600;
`;

const Container = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	padding-top: 2rem;
`;

export default TemplatesList;
