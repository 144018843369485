import { useEffect } from 'react';
import LeadsProvider from './components/LeadsProvider';
import LeadsScreen from './components/LeadsScreen';
import { StatusTableProvider } from '../../components/reusable-components/ProjectStatusTables/StatusTableProvider';

export default function Leads() {
	useEffect(() => {
		document.title = 'Sales | Robotic Imaging';
	}, []);

	return (
		<LeadsProvider>
			<StatusTableProvider>
				<LeadsScreen />
			</StatusTableProvider>
		</LeadsProvider>
	);
}
