import { ListItemMenuPropsItem } from '../../reusable-components/List/ListItemMenu/ListItemMenu';
import Edit from '@mui/icons-material/Edit';
import Delete from '@mui/icons-material/Delete';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import { UtilityFormV2 } from '../ProjectUtilityFormV2/utils/types';

type TemplateListItemProps = {
	form: UtilityFormV2;
	renameForm: (e: React.MouseEvent<HTMLLIElement, MouseEvent>) => void;
	archiveForm: (e: React.MouseEvent<HTMLLIElement, MouseEvent>) => void;
	editForm?: (e: React.MouseEvent<HTMLLIElement, MouseEvent>) => void;
};

const useMenuItems = (props: TemplateListItemProps) => {
	const { renameForm, archiveForm, editForm, form } = props;

	const menuItems: ListItemMenuPropsItem[] = [];
	if (editForm)
		menuItems.push({
			title: 'Edit',
			onClick: editForm,
			icon: <FormatListBulletedIcon color="primary" fontSize="small" />,
		});

	menuItems.push({
		title: 'Rename',
		onClick: renameForm,
		icon: <Edit color="primary" fontSize="small" />,
	});

	menuItems.push({
		title: 'Archive',
		onClick: archiveForm,
		icon: <Delete color="primary" fontSize="small" />,
	});

	const created = `${form.createdDate?.toDate().toLocaleDateString()},
						${form.createdDate?.toDate().toLocaleTimeString()}`;
	const updated = `${form.lastUpdated?.toDate().toLocaleDateString()},
						${form.lastUpdated?.toDate().toLocaleTimeString()}`;

	return { menuItems, created, updated };
};

export default useMenuItems;
