import { IconButton } from '@mui/material';
import styled from 'styled-components';

type CompletionIconButtonProps = {
	isComplete: boolean;
	icon: React.ReactNode;
	title?: string;
	onClick?: () => void;
};

export default function CompletionIconButton({
	isComplete,
	icon,
	title,
	onClick = () => {},
}: CompletionIconButtonProps) {
	return (
		<StyledButton $isComplete={isComplete} title={title} onClick={onClick}>
			{icon}
		</StyledButton>
	);
}

const StyledButton = styled(IconButton)<{ $isComplete: boolean }>`
	color: ${({ $isComplete }) => ($isComplete ? '#5BC236' : '#ffb310')};
	&:hover {
		background-color: ${({ $isComplete }) =>
			$isComplete ? 'rgba(91, 194, 54, 0.2)' : 'rgba(255, 179, 16, 0.2)'};
	}
`;
