import { styled } from '@mui/system';
import { FC } from 'react';
import { MenuItemProps } from './MenuItem.types';
import theme from '../../../styles/theme';

const MenuItem: FC<MenuItemProps> = ({
	title,
	description,
	endIcon,
	onClick,
	startIcon,
}) => {
	return (
		<Item onClick={onClick}>
			<Icon>{startIcon}</Icon>
			<TextColumn>
				<Title>{title}</Title>
				{description && <Description>{description}</Description>}
			</TextColumn>
			<Icon>{endIcon}</Icon>
		</Item>
	);
};

const Item = styled('div')`
	padding: 8px 0;
	font-size: 16px;
	transition: color 0.3s;

	display: flex;
	align-items: center;
	flex-direction: row;

	color: ${theme.palette.grey[300]};

	&:hover {
		color: ${theme.palette.primary.main};
	}
`;

const TextColumn = styled('div')`
	display: flex;
	flex-direction: column;
	width: 180px;
`;

const Title = styled('div')`
	color: ${theme.palette.common.white};
`;

const Description = styled('div')`
	font-size: 14px;
`;

const Icon = styled('div')`
	width: 50px;
	display: flex;
	justify-content: center;
	align-items: center;
`;

export default MenuItem;
