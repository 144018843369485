import { FieldLabel } from '../../../../../screens/ProjectInfo';
import React, { useContext, useState } from 'react';
import { AuthContext } from '../../../../../context/AuthProvider';
import MultiLinkField from './MultiLinkField';
import { ProjectData } from '../../../../../types';

// Props for 3D Links
interface ThreeDLinksProps {
	isActive: boolean;
	setPartialFormState: React.Dispatch<React.SetStateAction<Partial<ProjectData>>>;
}

// 3D Links Information
export default function ThreeDLinks({ isActive, setPartialFormState }: ThreeDLinksProps) {
	const { user } = useContext(AuthContext);
	const [edit3DLink, setEdit3DLink] = useState(false);

	return (
		<div className="infoRow">
			<FieldLabel label={'3D Link(s)'} />
			<MultiLinkField
				field={'threeDLinks'}
				title={'3D'}
				setState={setPartialFormState}
				isActive={Boolean(user?.isAdmin && isActive)}
				isFocused={edit3DLink}
				setFocused={setEdit3DLink}
			/>
		</div>
	);
}
