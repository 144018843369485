import React from 'react';
import styled from 'styled-components';
import { Skeleton } from '@mui/material';

const SkeletonGrid = () => {
	const skeletonCards = Array(6).fill(0);
	return (
		<GridContainer>
			{skeletonCards.map((_, index) => (
				<SkeletonCard key={index}>
					<SkeletonHeader>
						<Skeleton variant="text" animation="wave" />
					</SkeletonHeader>
					<SkeletonBody>
						<Skeleton variant="text" animation="wave" />
						<Skeleton variant="text" animation="wave" />
					</SkeletonBody>
					<SkeletonAdminChip>
						<Skeleton variant="circular" animation="wave" width={50} height={20} />
					</SkeletonAdminChip>
				</SkeletonCard>
			))}
		</GridContainer>
	);
};

export default SkeletonGrid;

const GridContainer = styled.div`
	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
	width: 100%;
	grid-gap: 20px;
	padding: 20px;
	place-items: center center;
`;

const SkeletonCard = styled.div`
	border-radius: 30px;
	background: #2b2b2b;
	padding: 30px 20px;
	min-height: 200px;
	width: 300px;
	position: relative;
`;

const SkeletonHeader = styled.div`
	height: 20px;
	background: #404040;
	margin-bottom: 10px;
	width: 70%;
	border-radius: 30px;
`;

const SkeletonBody = styled.div`
	height: 15px;
	background: #404040;
	margin-bottom: 5px;
	width: 90%;
	border-radius: 30px;
`;

const SkeletonAdminChip = styled.div`
	height: 20px;
	width: 50px;
	background: #404040;
	position: absolute;
	bottom: 10px;
	right: 20px;
	border-radius: 50px;
`;
