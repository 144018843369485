import { FieldLabel } from '../../../../screens/ProjectInfo';
import styled from 'styled-components';
import { YellowTextField } from '../../../styled-components/styledReactTable';
import { TimeValue } from '../../../../types';

interface HostingTermsProps {
	isActive: boolean;
	handleChange: (timeValue: TimeValue) => void;
	timeValue: TimeValue | undefined;
}

export default function HostingTerms({
	isActive,
	handleChange,
	timeValue,
}: HostingTermsProps) {
	const onChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
		const timeProp = e.target.name as keyof TimeValue;
		const stringVal = e.target.value;
		const numVal = Number(stringVal);

		const isValidNum = !Number.isNaN(numVal);
		const isUndefined = !e.target.value;

		if (isValidNum || isUndefined) {
			const updatedHostingTerms = {
				...timeValue,
				[timeProp]: isUndefined ? undefined : numVal,
			};
			handleChange(updatedHostingTerms);
		}
	};

	return (
		<div className="infoRow">
			<FieldLabel label="Hosting Terms" />
			<Container>
				{timeValueProperties['hostingTerms'].map(timeProp => {
					const value = timeValue?.[timeProp as keyof TimeValue];
					const hasTimeProp = typeof value === 'number';
					const singularTimeProp = timeProp.substring(0, timeProp.length - 1);

					return isActive ? (
						<TimeInput
							key={timeProp}
							name={timeProp}
							type="number"
							defaultValue={value}
							onChange={onChange}
						/>
					) : hasTimeProp ? (
						<p className="fieldInfo" style={{ color: 'white' }} key={timeProp}>
							{`${value} ${value === 1 ? singularTimeProp : timeProp}`}
						</p>
					) : null;
				})}
			</Container>
		</div>
	);
}

const timeValueProperties = {
	hostingTerms: ['years'],
};

const Container = styled.div`
	display: flex;
	gap: 5px;
	width: 100%;
`;

const TimeInput = styled(YellowTextField)`
	flex-grow: 1;
	flex-shrink: 1;

	label {
		color: #ffb310;
	}
`;
