import { Dialog, styled } from '@mui/material';
import theme from '../../../styles/theme';

export const StyledDialog = styled(Dialog)`
	& .MuiDialog-paper {
		width: 80%;
		max-width: 600px;
	}
`;

export const textFieldStyles = {
	width: '100%',
	minWidth: '400px',
};

export const selectStyles = {
	'.MuiInputBase-input': {
		color: theme.palette.newText.primary,
	},
	'.MuiSvgIcon-root': {
		color: theme.palette.newText.primary,
	},
};
