import styled from 'styled-components';
import { FieldLabel } from '../../../../../screens/ProjectInfo';
import { ProjectData } from '../../../../../types';
import { YellowTextField } from '../../../../styled-components/styledReactTable';
import React, { useContext } from 'react';
import { ProjectContext } from '../../../../../context/ProjectProvider';

interface TimeValueFieldProps {
	field: 'timeOnSite' | 'totalDuration';
	isActive: boolean;
	setPartialFormState: React.Dispatch<React.SetStateAction<Partial<ProjectData>>>;
}

export default function TimeValueField({
	field,
	isActive,
	setPartialFormState,
}: TimeValueFieldProps) {
	const { project } = useContext(ProjectContext);

	const timeValue = project?.[field];

	const handleChange = async (
		e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
	) => {
		const timeProp = e.target.name;
		const stringVal = e.target.value;
		const numVal = Number(stringVal);

		const isValidNum = !Number.isNaN(numVal);
		const isUndefined = !e.target.value;

		if (isValidNum || isUndefined) {
			setPartialFormState(prev => {
				const prevVal = prev[field] || {};
				return {
					...prev,
					[field]: {
						...prevVal,
						[timeProp]: isUndefined ? undefined : numVal,
					},
				};
			});
		}
	};

	return (
		<div className="infoRow">
			<FieldLabel label={timeValueDisplays[field]} />

			<Container>
				{timeValueProperties[field].map(timeProp => {
					// @ts-ignore
					const value: number | undefined = timeValue?.[timeProp];
					const hasTimeProp = typeof value === 'number';
					const singularTimeProp = timeProp.substring(0, timeProp.length - 1);

					return isActive ? (
						<TimeInput
							key={timeProp}
							label={timeProp}
							name={timeProp}
							type="number"
							InputLabelProps={{ shrink: true }}
							// @ts-ignore
							defaultValue={timeValue?.[timeProp]}
							onChange={handleChange}
						/>
					) : hasTimeProp ? (
						<span key={timeProp}>{`${value} ${
							value === 1 ? singularTimeProp : timeProp
						}`}</span>
					) : null;
				})}
			</Container>
		</div>
	);
}

const timeValueDisplays = {
	timeOnSite: 'Time On-Site',
	hostingTerms: 'Hosting Terms',
	totalDuration: 'Total Project Duration',
};

const timeValueProperties = {
	timeOnSite: ['hours', 'minutes'],
	hostingTerms: ['years'],
	totalDuration: ['days', 'hours', 'minutes'],
};

const Container = styled.div`
	display: flex;
	gap: 5px;
	width: 100%;
`;

const TimeInput = styled(YellowTextField)`
	flex-grow: 1;
	flex-shrink: 1;

	label {
		color: #ffb310;
	}
`;
