import { useContext, useEffect, useState } from 'react';
import { MenuItem, TextField } from '@mui/material';
import { Row } from 'react-table';
import { SnackContext } from '../../../../../../context/SnackProvider';
import { updateLead } from '../../../../../../firebase';
import { Lead, LeadStatus } from '../../../../types';
import styled from 'styled-components';
import { leadStatusColor, leadStatusTextColor, leadStatuses } from '../../../../utils';
import { LeadsContext } from '../../../LeadsProvider';
import { AuthContext } from '../../../../../../context/AuthProvider';
import { UserObj } from '../../../../../../types';

export default function LeadSelectCell({
	row,
	field,
}: {
	row: Row<Lead>;
	field: 'status' | 'accountManager';
}) {
	const { setSnackbarProps } = useContext(SnackContext);
	const { projectRoleOptions } = useContext(AuthContext);
	const { setLeads } = useContext(LeadsContext);

	const leadId = row.original.id as string;
	const defaultValue = row.original[field];
	const [value, setValue] = useState<string | UserObj>(defaultValue);
	const status = row.values.status;

	useEffect(() => {
		setValue(defaultValue);
	}, [defaultValue]);

	const handleAccountManagerChange = async (selectedId: string) => {
		const newManager: UserObj = {
			email: '',
			name: '',
			id: '',
		};

		const selectedManager = projectRoleOptions['accountManager'].find(
			userWithData => userWithData.id === selectedId
		);
		if (selectedId && selectedManager) {
			newManager.email = selectedManager.email;
			newManager.name = selectedManager.fullName;
			newManager.id = selectedManager.id;
		}

		setValue(newManager);
		setLeads(prev =>
			prev.map(lead => {
				if (lead.id !== leadId) return lead;
				else return { ...lead, accountManager: newManager, lastActivity: new Date() };
			})
		);
		await updateLead({
			id: leadId,
			updatedFields: {
				accountManager: newManager,
			},
		});
		setSnackbarProps({
			open: true,
			message: 'Changes submitted!',
			severity: 'success',
		});
	};

	const handleChange = async (
		e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
	) => {
		const newValue = e.target.value;

		if (field === 'accountManager') {
			await handleAccountManagerChange(e.target.value);
		} else if (newValue !== value) {
			const newValue = e.target.value as LeadStatus;
			setValue(newValue);

			setSnackbarProps({
				open: true,
				message: 'Submitting changes...',
				severity: 'warning',
				hideDuration: null,
			});

			setLeads(prev =>
				prev.map(lead => {
					if (lead.id !== leadId) return lead;
					return { ...lead, [field]: newValue, lastActivity: new Date() };
				})
			);
			await updateLead({
				id: leadId,
				updatedFields: {
					[field]: newValue,
				},
			});

			setSnackbarProps({
				open: true,
				message: 'Changes submitted!',
				severity: 'success',
			});
		}
	};

	return (
		<Wrapper $status={status}>
			<SelectField
				select
				value={typeof value === 'string' ? value : value?.id || ''}
				onChange={handleChange}
				fullWidth
				variant="standard"
				$status={status}
				SelectProps={{
					displayEmpty: true,
					native: false,
					renderValue: () => {
						if (typeof value === 'string') return value || 'None';
						else return value?.name || 'None';
					},
				}}>
				{field === 'status'
					? leadStatuses.map(status => (
							<MenuItem value={status} key={status}>
								{status}
							</MenuItem>
					  ))
					: field === 'accountManager'
					? [
							<MenuItem value="" key="null-user">
								None
							</MenuItem>,
							...projectRoleOptions['accountManager'].map(userWithData => (
								<MenuItem value={userWithData.id} key={userWithData.id}>
									{userWithData.fullName}
								</MenuItem>
							)),
					  ]
					: null}
			</SelectField>
		</Wrapper>
	);
}

export const Wrapper = styled.div<{ $status: LeadStatus }>`
	background-color: ${({ $status }) => ($status ? leadStatusColor($status) : undefined)};
	width: 100%;
	padding: 5px;
	border-radius: 5px;
`;

export const SelectField = styled(TextField)<{ $status: LeadStatus }>`
	.MuiInput-root {
		color: ${({ $status }) => ($status ? leadStatusTextColor($status) : 'inherit')};

		&:before {
			border-bottom: 1px solid
				${({ $status }) => ($status ? leadStatusTextColor($status) : 'inherit')};
		}

		&:hover {
			&:after {
				transform: scaleX(1) translateX(0);
			}
		}
	}

	.MuiSelect-icon {
		color: ${({ $status }) => ($status ? leadStatusTextColor($status) : 'inherit')};
	}
`;
