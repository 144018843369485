import { Dialog, DialogActions, Stack } from '@mui/material';
import styled from 'styled-components';

export const StyledDialog = styled(Dialog)`
	& .MuiDialog-paper {
		width: 80%;
		max-width: 600px;
	}
`;

export const StyledDialogActions = styled(DialogActions)`
	& .MuiButton-text {
		text-transform: none;
	}
`;

export const StyledStack = styled(Stack)`
	display: flex;
	flex-direction: row;
	align-items: center;
	width: 100%;
`;
