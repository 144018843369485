import { TextField } from '@mui/material';
import { FormNode } from '../../../../../utils/types';
import { useCallback, useContext, useEffect, useState } from 'react';
import { FormV2Context } from '../../../../../context/FormV2Context.types';
import { InputFieldContainer } from '../FormFillV2.styles';
import { debounce } from 'lodash';
import { AuthContext } from '../../../../../../../../context/AuthProvider';

const debouncedSave = debounce(async (save: () => void) => save(), 200);

interface MultilineFieldProps {
	node: FormNode;
}

const MultilineField: React.FC<React.PropsWithChildren<MultilineFieldProps>> = ({
	node,
}) => {
	const nodeValue = node.value as string;
	const { firebaseAuthData } = useContext(AuthContext);
	const [isFocused, setIsFocused] = useState(false);
	const [value, setValue] = useState<string>(nodeValue);
	const { updateValue } = useContext(FormV2Context);

	const onFocus = () => {
		setIsFocused(true);
	};

	const onBlur = () => {
		setIsFocused(false);
	};

	const handleInputChange = (
		event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
	) => {
		setValue(event.target.value);
	};

	const debounceSave = useCallback(
		(value: string) => {
			debouncedSave.cancel();
			debouncedSave(() => updateValue(node.id, value));
		},
		[node.id, updateValue]
	);

	useEffect(() => {
		if (!isFocused && node.value) setValue(node.value as string);
	}, [node.value, isFocused]);

	useEffect(() => {
		if (isFocused) debounceSave(value);
	}, [value, isFocused, debounceSave]);

	const editable = !node.focusedBy || node.focusedBy.id === firebaseAuthData?.uid;

	return (
		<InputFieldContainer id={node.id}>
			<TextField
				placeholder={value || ''}
				fullWidth
				label="Value"
				multiline
				rows={3}
				value={value}
				onChange={handleInputChange}
				onBlur={onBlur}
				onFocus={onFocus}
				disabled={!editable}
			/>
			{node.focusedBy && node.focusedBy.id !== firebaseAuthData?.uid ? (
				<span>{node.focusedBy.name} is typing...</span>
			) : (
				<span />
			)}
		</InputFieldContainer>
	);
};

export default MultilineField;
