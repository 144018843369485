import { CheckCircle, ExpandMore } from '@mui/icons-material';
import styled from 'styled-components';

export const BucketsWrapper = styled.div`
	display: flex;
	flex-direction: row;
	width: 95%;
	margin: 0 auto;
	align-items: center;
	position: relative;
	align-content: center;
	justify-content: center;
	margin: 20px auto;
	min-height: 150px;
`;

interface BucketProps {
	disableHover: boolean;
}

export const Bucket = styled.div<BucketProps>`
	display: flex;
	position: relative;
	background-color: #fefefe;
	box-shadow: 0px 2px 5px 1px rgba(0, 0, 0, 0.15);
	border-radius: 40px;
	flex-grow: 1;
	height: 120px;
	width: calc(25% - 20px);
	margin: 0 10px;
	justify-content: center;
	align-items: center;
	border: 2px dashed #ffb310;
	padding: 5px;
	${({ disableHover }) =>
		!disableHover &&
		`&:hover {
      transform: scale(1.1);
      height: auto;
      z-index: 10;
    }
  `}
`;

export const ImageOverlay = styled.div`
	display: none;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	border-radius: 40px;
	background-color: rgba(255, 255, 255, 0.75);
	z-index: 20;
	transition: all 0.3s ease;
	justify-content: center;
	align-items: center;
	padding: 10px;
	box-sizing: border-box;

	${Bucket}:hover & {
		display: flex;
		height: auto;
	}
`;

export const BucketText = styled.span`
	font-family: 'Epilogue';
	font-size: 1em;
	color: black;
`;

export const UploadedContainer = styled.div`
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100%;
	width: 100%;
	overflow: hidden;
	border-radius: 30px;

	max-height: 100px;
	${Bucket}:hover & {
		min-height: 150px;
	}
`;

export const UploadedCheck = styled(CheckCircle)`
	color: green;
	size: 40px;
`;

export const UploadedImage = styled.img`
	max-width: 100%;
	height: auto;
`;

export const ToggleBar = styled.div`
	// display: flex;
	flex-drectioin: column;
	width: 95%;
	border-radius: 30px;
	margin: 20px auto 0px;
  	justify-content: center;
  	cursor: pointer;
  	background-color: #fefefe;
	align-items: center;
	position: relative;
	box-shadow: 0px 2px 5px 1px rgba(0, 0, 0, 0.15);
`;

interface CaretProps {
	isOpen: boolean;
}

export const Caret = styled(ExpandMore)<CaretProps>`
	transform: ${({ isOpen }) => (isOpen ? 'rotate(180deg)' : 'rotate(0deg)')};
	transition: transform 0.3s ease;
`;

export const DropdownHeader = styled.div`
display: flex;
 width: 100%;
 gap: 5px;
 justify-content: center;
 align-self: center;
 align-items: center;
flex-direction: row;
padding: 20px;
`;

export const DropdownText = styled.p`
	font-family: 'Epilogue';
	color: black;
	font-size: 1.2em;
	display: flex;
	align-self: center;
	justify-content: center;
	margin: 0 auto;`
	;
