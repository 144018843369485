import { SectionItemProps } from './Section.types';
import { useContext, useState } from 'react';
import { Box } from '@mui/material';
import { FormV2Context } from '../../screen-components/ProjectUtilityFormV2/context/FormV2Context.types';
import {
	StyledButtonContained,
	StyledButtonText,
	StyledCircularProgress,
} from '../CoverImage/CoverImageStyles';
import { Section } from './Section.styles';
import { StyledTextField } from '../StyledTextField';
import { inputStyles } from '../InputField/InputField.styles';

const SectionCreate: React.FC<SectionItemProps> = ({ node }) => {
	const { displayTitle, value } = node;
	const [name, setName] = useState<string>(displayTitle);
	const [description, setDescription] = useState<string>(value as string);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const { updateValue, updateDisplayTitle } = useContext(FormV2Context);

	const handleSave = async () => {
		setIsLoading(true);
		await updateDisplayTitle(node.id, name);
		await updateValue(node.id, description).then(res => {
			setIsLoading(false);
		});
	};

	const handleCancel = () => {
		setIsLoading(false);
		setName(displayTitle || '');
		setDescription((value as string) || '');
	};

	return (
		<Section>
			<StyledTextField
				placeholder="Enter section name"
				label="Name"
				sx={{ ...inputStyles }}
				className={'active'}
				value={name}
				onChange={e => setName(e.target.value)}
				fullWidth
			/>
			<StyledTextField
				placeholder="Enter section description"
				label="Description (optional)"
				fullWidth
				sx={{
					...inputStyles,
				}}
				className={'active'}
				value={description}
				onChange={e => setDescription(e.target.value)}
				helperText="Leave this field blank if you don’t want to display a description for this section."
			/>
			<Box display={'flex'} justifyContent={'flex-end'} gap={1} width={'100%'}>
				<StyledButtonText onClick={handleCancel} disabled={isLoading}>
					Cancel
				</StyledButtonText>
				<Box sx={{ position: 'relative' }}>
					<StyledButtonContained
						onClick={handleSave}
						variant="contained"
						size="medium"
						colortype="primary"
						disabled={isLoading || !name}>
						Save and create section
					</StyledButtonContained>
					{isLoading && <StyledCircularProgress size={24} variant="indeterminate" />}
				</Box>
			</Box>
		</Section>
	);
};
export default SectionCreate;
