import { FC, useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import theme from '../../../styles/theme';
import {
	FormNode,
	OCRModelNumberType,
} from '../../../components/screen-components/ProjectUtilityFormV2/utils/types';
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import { collection, getDocs, query, where } from 'firebase/firestore';
import { db } from '../../../firebase';

type Props = {
	nodeId: string;
};

const ModelNumberPreview: FC<Props> = ({ nodeId }: Props) => {
	const [open, setOpen] = useState(false);
	const [modelsNumber, setModelsNumber] = useState<OCRModelNumberType[] | null>(null);

	const searchNodes = useCallback(async () => {
		const itemsRef = collection(db, 'utility_forms_v2_items');

		const q = query(itemsRef, where('value.photoNodeId', '==', nodeId));

		const querySnapshot = await getDocs(q);

		const modelsValue = querySnapshot.docs.map(doc => {
			const data = doc.data() as FormNode;

			return data.value as OCRModelNumberType;
		});

		setModelsNumber(modelsValue);
	}, [nodeId]);

	useEffect(() => {
		searchNodes();
	}, [searchNodes]);

	if (!modelsNumber || modelsNumber.length === 0) return null;

	return (
		<Container open={open}>
			<Header open={open}>
				<Title>Model Number</Title>
				<ButtonAction onClick={() => setOpen(!open)}>
					{open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
				</ButtonAction>
			</Header>
			{open && (
				<Body>
					{modelsNumber?.map((model, index) => (
						<BodyContent key={index} index={index} total={modelsNumber.length}>
							<BodyTitle>Item {index + 1}</BodyTitle>
							<Item>
								<span>Model Number:</span>
								<span>{model.modelNumber}</span>
							</Item>
							<Item>
								<span>Serial Number:</span>
								<span>{model.serialNumber}</span>
							</Item>
							<Item>
								<span>Manufacturer:</span>
								<span>{model.manufacturer}</span>
							</Item>
							<Item>
								<span>Manufacture Date:</span>
								<span>{model.manufactureDate}</span>
							</Item>
							<Item>
								<span>PDF Link:</span>
								{model.data?.pdfUrl && (
									<a
										target="_blank"
										href={model.data?.pdfUrl}
										aria-label="PDF Link"
										rel="noreferrer">
										View PDF
									</a>
								)}
							</Item>
						</BodyContent>
					))}
				</Body>
			)}
		</Container>
	);
};

export default ModelNumberPreview;

const Container = styled.div<{ open: boolean }>`
	position: absolute;
	top: 16px;
	right: 20px;
	border: 1px solid ${theme.palette.primary.main};
	border-radius: 6px;
	overflow: hidden;
	width: ${({ open }) => (open ? '500px' : '200px')};
`;

const Header = styled.div<{ open: boolean }>`
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 10px;
	background-color: ${({ open }) => (open ? theme.palette.grey[900] : 'transparent')};
`;

const Title = styled.h1`
	font-size: 16px;
	color: ${theme.palette.primary.main};
	margin: 0;
`;

const ButtonAction = styled.button`
	color: ${theme.palette.primary.main};
	background-color: transparent;
	cursor: pointer;
	border: none;
`;

const Body = styled.div`
	background-color: #000;
	border-radius: 0 0 6px 6px;
	max-height: calc(100vh - 300px);
	overflow-y: auto;
`;

const Item = styled.div`
	display: flex;
	justify-content: space-between;
	padding: 10px 10px 10px 15px;
	border-bottom: 1px solid #ccc;
	color: #fff;

	:first-child {
		margin-top: 5px;
	}

	:last-child {
		border-bottom: none;
	}

	a {
		color: #fff;
		cursor: pointer;
	}
`;

const BodyTitle = styled.h1`
	color: #fff;
	font-size: 16px;
	margin: 0;
	padding-left: 10px;
`;

const BodyContent = styled.div<{ index: number; total: number }>`
	padding-top: 10px;
	border-bottom: ${({ index, total }) =>
		index !== total ? `1px solid ${theme.palette.primary.main}` : 'none'};
`;
