import { useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';

import { FormContext } from '../FormContext';

import { Category } from '../types';

import EditableHeaderField from '../utils/EditableHeaderField';
import CreateCategory from '../categories/CreateCategory';
import {
	newCustomCategory,
	newElectrical,
	newFireProtection,
	newMechanical,
	newPlumbing,
} from '../utils/BaseCategories';
import AddCustomCategory from '../utils/AddCustomCategory';
import { Button } from '@mui/material';
import { UploadContext } from '../UploadContext';

export default function FormFill() {
	const { formId }: any = useParams();

	const { uploadQueueState, mountedFormId, setFormSubmitState } =
		useContext(UploadContext);
	const {
		formState,

		setSubmittingUtilityForm,
		wrappedDebounce,

		formTitle,
		setFormTitle,
	} = useContext(FormContext);

	useEffect(() => {
		mountedFormId.current = formId;
		return () => {
			mountedFormId.current = null;
		};
	}, [formId, mountedFormId]);

	// Returns array of items included in each category.
	const categoryItems = (category: Category) => {
		switch (category.type) {
			case 'ELECTRICAL':
				return [...newElectrical().items, ...newCustomCategory().items];
			case 'MECHANICAL':
				return [...newMechanical().items, ...newCustomCategory().items];
			case 'PLUMBING':
				return [...newPlumbing().items, ...newCustomCategory().items];
			case 'FIRE_PROTECTION':
				return [...newFireProtection().items, ...newCustomCategory().items];
			default:
				return newCustomCategory().items;
		}
	};

	const submitForm = () => {
		setSubmittingUtilityForm(true);
		if (uploadQueueState[formId]) {
			wrappedDebounce();
			setFormSubmitState(prev => {
				prev[formId] = true;
				return prev;
			});
		} else {
			wrappedDebounce('uploaded');
		}
	};

	return (
		<Container>
			<HeaderContainer>
				<h1>
					<EditableHeaderField
						field="formName"
						value={formTitle?.formName || ''}
						setState={setFormTitle}
						styles={{
							colorTheme: 'primary',
							style: { fontSize: '0.975rem', color: '#ffb310' },
						}}
					/>
				</h1>
			</HeaderContainer>

			{formState.map((category, idx) => {
				return (
					<CreateCategory
						key={category.categoryId}
						category={category}
						categoryItems={categoryItems(category)}
						categoryIndex={idx}
					/>
				);
			})}
			<AddCustomCategory />
			<SubmitButtonContainer>
				<Button variant="outlined" onClick={submitForm}>
					Submit Form
				</Button>
			</SubmitButtonContainer>
		</Container>
	);
}

const Container = styled.form`
	display: flex;
	flex-direction: column;
	gap: 10px;

	@media screen and (max-width: 768px) {
		width: 100%;
	}

	background-color: ivory;
	padding: 5px;
`;

const HeaderContainer = styled.div`
	background-color: black;
	color: #ffb310;
	margin: 0;
	justify-content: center;

	display: flex;
	flex-direction: row;
	gap: 10px;
`;

const SubmitButtonContainer = styled.div`
	padding-top: 20px;
	display: flex;
	justify-content: center;
`;
