import { db, storage } from '../index';
import {
	getDocs,
	collection,
	doc,
	updateDoc,
	arrayRemove,
	serverTimestamp,
	query,
	where,
	deleteField,
	// deleteField,
} from 'firebase/firestore';
import { deleteObject, ref } from 'firebase/storage';
import { ProjectData, ProjectStatus } from '../../types';
import { isPlainObject } from 'lodash';
import { projectStatusText } from '../../utils';

/**
 * Deletes member with given email from project with given project id in Firestore.
 * Does not handle state/UI updates.
 */
export function memberDelete(email: string, pId: string) {
	return new Promise((resolve, reject) => {
		const q = query(collection(db, 'users'), where('email', '==', email));
		getDocs(q)
			.then(querySnapshot => {
				if (!querySnapshot.empty) {
					// Remove uId from sharedUsers array of project doc
					updateDoc(doc(db, 'projects', pId), {
						sharedUsers: arrayRemove(querySnapshot.docs[0].id),
					})
						.then(res => {
							resolve(res);
						})
						.catch(e => {
							console.log(e);
							reject(e);
						});
				}
			})
			.catch(e => {
				console.log(e);
				reject(e);
			});
	});
}

export function deleteData(projectData: ProjectData) {
	return new Promise<void>((resolve, reject) => {
		const folderRef = ref(storage, `projects/${projectData.id}/pdfDeliverable.pdf`);
		deleteObject(folderRef)
			.then(() => {
				console.log('Storage Folder Deleted');
				resolve();
			})
			.catch(e => {
				console.log('Error deleting storage folder: ', e);
				reject(e);
			});
	});
}

// TODO: Refactor uses of this function to use the 'updateProjectFields' function instead.
export async function updateSingleProjectField(pID: string, value: any, field: string) {
	let newData = { [field]: value };

	if (value === undefined) {
		newData = {
			[field]: deleteField(),
		};
	}

	if (field === 'projectStatus') {
		newData = { ...newData, lastStatusChange: serverTimestamp() };
	}
	await updateDoc(doc(db, 'projects', pID), newData);
}

export const updateProjectFields = async (
	project: ProjectData,
	updatedFields: Partial<ProjectData>,
	setProject?: React.Dispatch<React.SetStateAction<ProjectData | null>>
) => {
	const newData = updatedFields;

	// Update 'lastStatusChange' field if 'projectStatus' field has changed.
	if (
		'projectStatus' in updatedFields &&
		updatedFields.projectStatus !== project.projectStatus
	) {
		newData.lastStatusChange = new Date();
	}

	// Submit newData with the deleteField() sentinel for any properties that are undefined.
	const submitData = { ...newData };
	for (const property in newData) {
		const newValue = submitData[property as keyof ProjectData];

		if (newValue === undefined) {
			// 'newValue' is undefined, so delete it from project document.
			// @ts-ignore
			submitData[property] = deleteField();
		} else if (isPlainObject(newValue)) {
			// 'newValue' is a plain object, so check for undefined values.

			for (const innerProp in newValue as any) {
				// @ts-ignore
				const innerValue = newValue[innerProp];
				if (innerValue === undefined) {
					// @ts-ignore
					delete submitData[property][innerProp];
				}
			}
		}
	}

	// Update project document with 'submitData'.
	try {
		await updateDoc(doc(db, 'projects', project.id as string), submitData);
	} catch (err) {
		console.error(`Error updating project document!`, err);
	}

	// Update project state if state setter is provided.
	if (setProject) {
		setProject(prev => {
			if (prev && prev.id === project.id) {
				return {
					...project,
					...newData,
				};
			} else return prev;
		});
	}
};

/**
 * If the newStatus is 'Pending Payment / Delivered', display a confirmation window.
 * @param {ProjectStatus} newStatus
 * @returns {boolean}
 */
export const confirmProjectStatusChange = (newStatus: ProjectStatus) => {
	if (newStatus === 'Pending Payment / Delivered') {
		return window.confirm(
			`Are you sure you want to change the project status to ${projectStatusText(
				newStatus
			)}?`
		);
	}
	return true;
};
