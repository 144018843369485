import { FieldLabel } from '../../../../../screens/ProjectInfo';
import React, { useContext, useState } from 'react';
import { AuthContext } from '../../../../../context/AuthProvider';
import MultiLinkField from './MultiLinkField';
import { ProjectData } from '../../../../../types';

// Props for 2D Links
interface TwoDLinksProps {
	isActive: boolean;
	setPartialFormState: React.Dispatch<React.SetStateAction<Partial<ProjectData>>>;
}

// 2D Links Information
export default function TwoDLinks({ isActive, setPartialFormState }: TwoDLinksProps) {
	const { user } = useContext(AuthContext);
	const [edit2DLink, setEdit2DLink] = useState(false);

	return (
		<div className="infoRow">
			<FieldLabel label={'2D Link(s)'} />
			<MultiLinkField
				field={'twoDLinks'}
				title={'2D'}
				setState={setPartialFormState}
				isActive={Boolean(user?.isAdmin && isActive)}
				isFocused={edit2DLink}
				setFocused={setEdit2DLink}
			/>
		</div>
	);
}
