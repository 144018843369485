import { useContext, useState, useEffect, useMemo } from 'react';
import {
	useFilters,
	useGlobalFilter,
	usePagination,
	useSortBy,
	useTable,
	useRowSelect,
	useFlexLayout,
} from 'react-table';
import { AuthContext } from '../../../context/AuthProvider';
import { ProjectStatus } from '../../../types';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { Modeler } from '../../../types';
import {
	TableWrapper,
	Table,
	HeaderCell,
	BodyRow,
	BodyCell,
	BottomRow,
	Pagination,
	PaginationButton,
	AvailableButton,
	BusyButton,
	TableCellLink,
} from '../../styled-components/styledReactTable';
import styled from 'styled-components';

export default function ModelerTable({ modelers }: { modelers: Modeler[] }) {
	const { user } = useContext(AuthContext);
	const [data, setData] = useState<Modeler[]>([]);
	const [controlledPageIndex, setControlledPageIndex] = useState(0);

	const busyStatuses: ProjectStatus[] = useMemo(
		() => [
			'Ready For Modeling',
			'Modeling In Progress',
			// 'QA/QC',
		],
		[]
	);

	const columns = useMemo(
		() => [
			{
				Header: 'Modeler',
				id: 'modelers',
				Cell: ({ row: { original: modelers } }: any) => {
					return (
						<TableCellLink to={`/modelers/${modelers.name}`}>
							<span style={{ justifyContent: 'left' }}>{modelers.name}</span>
						</TableCellLink>
					);
				},
				accessor: (row: any) => row.modelers,
				width: 300,
			},
			{
				Header: 'Availability',
				Cell: ({ row: { original: modelers } }: any) => {
					const isBusy = Object.keys(modelers.projects).some(status =>
						busyStatuses.includes(status as ProjectStatus)
					);
					return (
						<div>
							{isBusy ? (
								<BusyButton>Busy</BusyButton>
							) : (
								<AvailableButton>Available</AvailableButton>
							)}
						</div>
					);
				},
				sortType: 'string',
				width: 200,
			},
			{
				Header: 'Ready for Modeling',
				Cell: ({ row: { original: modelers } }: any) => {
					return (
						<div>
							{modelers.projects['Ready For Modeling']
								? modelers.projects['Ready For Modeling'].length
								: 0}
						</div>
					);
				},
				sortType: 'string',
				width: 200,
			},
			{
				Header: 'Modeling In Progress',
				Cell: ({ row: { original: modelers } }: any) => {
					return (
						<div>
							{modelers.projects['Modeling In Progress']
								? modelers.projects['Modeling In Progress'].length
								: 0}
						</div>
					);
				},
				sortType: 'string',
				width: 200,
			},
			{
				Header: 'QA/QC',
				Cell: ({ row: { original: modelers } }: any) => {
					return (
						<div>
							{modelers.projects['QA/QC'] ? modelers.projects['QA/QC'].length : 0}
						</div>
					);
				},
				sortType: 'string',
				width: 200,
			},
		],
		[busyStatuses]
	);
	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		prepareRow,
		page,
		canPreviousPage,
		canNextPage,
		pageCount,
		nextPage,
		previousPage,
		setPageSize,
		state: { pageIndex, pageSize },
	} = useTable(
		{
			data,
			columns,
			initialState: {
				pageSize: 10,
				pageIndex: controlledPageIndex,
				hiddenColumns: user?.isContractor
					? ['Sub-Total', 'Internal Notes', 'Invoice Link', 'Signed Proposal']
					: [],
			},
		},
		useFilters,
		useGlobalFilter,
		useSortBy,
		usePagination,
		useRowSelect,
		useFlexLayout
	);

	const pageMin = () => pageIndex * pageSize + 1;
	const pageMax = () =>
		pageIndex + 1 === pageCount ? data.length : (pageIndex + 1) * pageSize;
	const pageRows = () => data.length;

	const configurePageSize = (size: number) => {
		// Calculates new page index based on top row after resizing page size
		const newPageIndex = Math.floor((pageIndex * pageSize) / size);
		setControlledPageIndex(newPageIndex);
		setPageSize(size);
	};

	useEffect(() => {
		setData(modelers);
	}, [modelers]);

	// Makes header row scroll at same rate as body
	useEffect(() => {
		const scrollFunc = (e: Event) => {
			const target = e.target as HTMLElement;
			if (target.id === 'table-body') {
				const headerRow = document.getElementById('header-row');
				if (headerRow) headerRow.scrollLeft = target.scrollLeft;
			}
		};
		document.addEventListener('scroll', scrollFunc, true);
		return () => document.removeEventListener('scroll', scrollFunc);
	}, []);

	// Keeps table from displaying a blank page when there are no more projects on that page
	// e.g. when projectStatus is changed
	useEffect(() => {
		// Calculates the index of the top row of the current page + 1
		const topRowIndex = pageIndex * pageSize + 1;
		// Goes to previous page if there is no project to display on the top row and the pageIndex !== 0
		if (topRowIndex > data.length && pageIndex) {
			setControlledPageIndex(prev => prev - 1);
			previousPage();
		}
	}, [data.length, pageCount, pageIndex, pageSize, previousPage]);

	return (
		<ModelerContent>
			{data.length > 0 && (
				<TableWrapper>
					{headerGroups.map(headerGroup => (
						<ModelerTableHeaderRow
							{...headerGroup.getHeaderGroupProps({
								style: { minWidth: '100%' },
							})}
							id="header-row">
							{headerGroup.headers.map((column, idx) => (
								<HeaderCell key={idx} style={{ width: column.width }}>
									<ModelerHeaderCellInner
										isSorted={column.isSorted}
										canSort={column.canSort}
										{...column.getHeaderProps(column.getSortByToggleProps())}
										title={column.canSort ? `Sort table by ${column.Header}` : ''}>
										{column.render('Header')}
										{column.isSorted ? (
											column.isSortedDesc ? (
												<ExpandMoreIcon />
											) : (
												<ExpandLessIcon />
											)
										) : null}
									</ModelerHeaderCellInner>
								</HeaderCell>
							))}
						</ModelerTableHeaderRow>
					))}
					<ModelerBodyTable {...getTableProps()} id="table-body">
						<div {...getTableBodyProps()}>
							{page.map((row, idx) => {
								prepareRow(row);
								return (
									<BodyRow {...row.getRowProps({ key: row.original.name + idx })}>
										{row.cells.map((cell: any) => {
											return (
												<ModelerBodyCell
													{...cell.getCellProps()}
													style={{ width: cell.column.width }}>
													{cell.render('Cell')}
												</ModelerBodyCell>
											);
										})}
									</BodyRow>
								);
							})}
						</div>
					</ModelerBodyTable>

					<ModelerBottomRow>
						<Pagination>
							<div>Rows per page:</div>
							<select onChange={e => configurePageSize(Number(e.target.value))}>
								{[10, 25, 50, 100].map(pageSize => (
									<option key={pageSize} value={pageSize}>
										{pageSize}
									</option>
								))}
							</select>
							<div>{page.length > 0 && `${pageMin()}–${pageMax()} of ${pageRows()}`}</div>
							<PaginationButton
								disabled={!canPreviousPage}
								onClick={() => {
									if (canPreviousPage) setControlledPageIndex(prev => prev - 1);
									previousPage();
								}}>
								<ChevronLeftIcon />
							</PaginationButton>
							<PaginationButton
								disabled={!canNextPage}
								onClick={() => {
									if (canNextPage) setControlledPageIndex(prev => prev + 1);
									nextPage();
								}}>
								<ChevronRightIcon />
							</PaginationButton>
						</Pagination>
					</ModelerBottomRow>
				</TableWrapper>
			)}
		</ModelerContent>
	);
}
export const ModelerContent = styled.div`
	width: fit-content;
	padding: 0px;
	max-width: 100%;
	margin: 0 auto;

	@media (max-width: 1023px) {
		padding: 20px;
	}
`;
export const ModelerHeaderCellInner = styled.div<{ isSorted: boolean; canSort: boolean }>`
	color: ${({ isSorted }) => (isSorted ? '#ffb310' : '#f6f6f6')};
	font-weight: bold;
	display: flex;
	align-items: center;
	height: 60px;
	transition: 0.2s;
	justify-content: center;
	align-items: center;

	&:hover {
		color: ${({ canSort }) => (canSort ? '#ffb310' : '')};
		text-shadow: ${({ canSort }) => (canSort ? '0 0 2px #ffb310' : '')};
		text-decoration: ${({ canSort }) => (canSort ? 'underline' : '')};
	}
`;

export const ModelerBodyTable = styled(Table)`
	max-height: 60vh;
	overflow-x: auto;
`;

export const ModelerTableHeaderRow = styled.div`
	// display: flex;
	background-color: #202124;
	gap: 10px;
	position: sticky;
	top: 0px;
	z-index: 1;
	overflow: hidden;
`;

export const ModelerBottomRow = styled(BottomRow)`
	position: sticky;
	bottom: 0px;
	z-index: 5;
	overflow: hidden;
`;

export const ModelerBodyCell = styled(BodyCell)`
	justify-content: center;
`;
