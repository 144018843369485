import { useState, useContext, useEffect } from 'react';
import DeliverableDropzone from '../components/DeliverableDropzone';
import { ProjectContext } from '../../../../context/ProjectProvider';
import styled from 'styled-components';
import Carousel from '../../Carousel';
import DeliverableButtonsOverlay from '../components/DeliverableButtonsOverlay';
import {
	CarouselIframe,
	CarouselPdf,
	CarouselPdfPlaceholder,
} from '../../Carousel/Styled';
import { useWindowSize } from '../../../../screens/Welcome';

export default function PointCloudBox({
	downloadOnly,
	anonUpload,
}: {
	downloadOnly: boolean;
	anonUpload: boolean;
}) {
	const { project } = useContext(ProjectContext);

	const [validPointCloudLinks, setValidPointCloudLinks] = useState<string[]>([]);
	const [validPointCloudPdfs, setValidPointCloudPdfs] = useState<string[]>([]);
	const [showPointCloudFilesDialog, setShowPointCloudFilesDialog] = useState(false);

	const locked = downloadOnly && project?.projectStatus === 'Pending Payment';

	const isAnon = downloadOnly || anonUpload;
	const { width } = useWindowSize();
	const showCarousel = isAnon ? width >= 1024 : true;

	// Set valid point cloud links
	useEffect(() => {
		async function getValidPointCloudLinks(): Promise<void> {
			if (project?.pointCloudLinks?.length) {
				let arr: string[] = [];
				for (const link of project.pointCloudLinks as string[]) {
					if (
						link.startsWith('https://app.voxxlr.com') ||
						link.startsWith('https://aec.cintoo.com') ||
						link.startsWith('https://youriguide.com')
					) {
						arr.push(link);
					}
				}
				setValidPointCloudLinks(arr);
			} else {
				setValidPointCloudLinks([]);
			}
		}
		getValidPointCloudLinks();
	}, [project?.pointCloudLinks]);

	// Set valid point cloud pdfs
	useEffect(() => {
		function getValidPdfLinks() {
			if (project?.pointCloud?.length) {
				const pdfArr: string[] = [];
				for (const file of project.pointCloud) {
					if (file.filepath.endsWith('pdf')) {
						pdfArr.push(file.downloadUrl);
					}
				}
				setValidPointCloudPdfs(pdfArr);
			}
		}

		getValidPdfLinks();
	}, [project?.pointCloud]);

	return downloadOnly &&
		!validPointCloudLinks.length &&
		!project?.pointCloud?.length ? null : (
		<DeliverableDropzone
			deliverableKey="pointCloud"
			showingDialog={showPointCloudFilesDialog}
			setShowingDialog={setShowPointCloudFilesDialog}
			downloadOnly={downloadOnly}
			anonUpload={anonUpload}>
			{(validPointCloudLinks.length || validPointCloudPdfs.length) &&
			!locked &&
			showCarousel ? (
				<PointCloudContainer id="deliverable-pointCloud">
					<Carousel lazy>
						{[
							...validPointCloudLinks.map((link: string, i: number) => (
								<CarouselIframe
									title="2d-iframe"
									src={link}
									key={i}
									allow="xr-spatial-tracking"
								/>
							)),
							...validPointCloudPdfs.map(url => (
								<CarouselPdf data={url} type="application/pdf" key={url}>
									<CarouselPdfPlaceholder>
										<span>Could not load PDF Viewer:</span>
										<a href={url}>Click to view PDF</a>
									</CarouselPdfPlaceholder>
								</CarouselPdf>
							)),
						]}
					</Carousel>
					<DeliverableButtonsOverlay
						deliverableKey="pointCloud"
						downloadOnly={downloadOnly}
						setShowingDialog={setShowPointCloudFilesDialog}
					/>
				</PointCloudContainer>
			) : null}
		</DeliverableDropzone>
	);
}

const PointCloudContainer = styled.div`
	width: 100%;
	height: 100%;
`;
