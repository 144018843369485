import { arrayRemove, arrayUnion, doc, onSnapshot, updateDoc } from 'firebase/firestore';
import { Button } from '@mui/material';
import { db } from '../../../firebase';
import {
	Container,
	QuestionParent,
	QuestionsScroller,
	ReportContainer,
	ReportScrollerWrapper,
	Scroller,
	XLSXContainer,
} from './StyledComponents';
import { FC, useEffect, useState } from 'react';
import FormListDialog from './FormListDialog';
import Loading from '../ProjectUtilityFormV2/components/Loading/Loading';
import QuestionsItem from './QuestionsItem';
import ReportListItem from './ReportListItem';
import useQuestions from './useQuestions';
import useReports from './useReports';
import useXLSX from './useXLSX';
import { Download } from '@mui/icons-material';
import ReportQuestionFilter from './ReportQuestionFilter';
import InfoIcon from '../../reusable-components/InfoIcon/InfoIcon';
import CreateReportSecondDialog from './CreateReportSecondDialog';
import CreateReportFirstDialog from './CreateReportFirstDialog';

type ReportsProps = {
	teamId: string;
	organizationId: string;
};

const Reports: FC<ReportsProps> = ({ organizationId, teamId }) => {
	const [createReportDialogOpen, setCreateReportDialogOpen] = useState(false);
	const [createReportSecondDialogOpen, setCreateReportSecondDialogOpen] = useState(false);
	const [hiddenQuestions, setHiddenQuestions] = useState<string[]>([]);

	const [reportName, setReportName] = useState('');
	const {
		createReport,
		loadForms,
		loading,
		projectsWithForms,
		reports,
		reportQuestions,
		selectedReportId,
		selectReport,
		toggleForm,
		totalSelectedForms,
	} = useReports({ organizationId, teamId, reportName });
	const [filteredReportQuestions, setFilteredReportQuestions] = useState(
		reportQuestions?.questions || []
	);
	// Set hidden questions whenever 'reportQuestions' changes
	useEffect(() => {
		setHiddenQuestions(reportQuestions?.hiddenQuestions || []);
		setFilteredReportQuestions(reportQuestions?.questions || []);
	}, [reportQuestions]);

	// Listen for real-time updates to the 'selectedReport's questions
	useEffect(() => {
		if (!selectedReportId) return;

		if (selectedReportId) {
			const docRef = doc(db, 'reportQuestions', selectedReportId);
			const unsubscribe = onSnapshot(docRef, snapshot => {
				if (snapshot.exists()) {
					const data = snapshot.data();
					setHiddenQuestions(data.hiddenQuestions || []);
				}
			});
			return () => unsubscribe();
		}
	}, [selectedReportId]);

	const {
		formListDialogOpen,
		onClickAvailable,
		onClickMissing,
		onClickNoValue,
		onClickOption,
		option,
		question,
		setFormListDialogOpen,
	} = useQuestions();

	const { downloadFullXLSX, downloadPDFs, downloadXLSX } = useXLSX();

	// Toggle visibility of a question
	const toggleVisibility = async (question: string) => {
		if (!selectedReportId) return;

		const docRef = doc(db, 'reportQuestions', selectedReportId);
		const newHiddenQuestions = hiddenQuestions.includes(question)
			? hiddenQuestions.filter(id => id !== question)
			: [...hiddenQuestions, question];

		setHiddenQuestions(newHiddenQuestions);

		await updateDoc(docRef, {
			hiddenQuestions: hiddenQuestions.includes(question)
				? arrayRemove(question)
				: arrayUnion(question),
		});
	};

	const selectedReport = reports?.find(report => report.id === selectedReportId);

	return (
		<ReportContainer>
			<ReportScrollerWrapper>
				<div style={{ display: 'flex', gap: '5px', alignItems: 'center' }}>
					<Button
						type="submit"
						variant="contained"
						onClick={() => {
							loadForms();
							setCreateReportDialogOpen(true);
						}}>
						Create new analytics
					</Button>
					<InfoIcon
						title="Learn more about analytics"
						message={`Analytics provide a way to compare and contrast multiple choice picker questions from forms across multiple projects. Here's how you create analytics:
							\n1. Click the "CREATE NEW ANALYTICS" button on the left side of the screen. You should now see a pop-up window with a list of all the projects shared to the current team, as well as all the forms created for each project. Select all the forms that you want to be included in the analytics.
							\n2. After you're done selecting all the forms, press the "ACCEPT" button in the lower right hand corner of the pop-up window. Your analytics will now start generating and this could take anywhere from 2-10 minutes.
							\n3. When your analytics is done generating, you should see a new grey row under the "create new analytics" button with a timestamp. This is your analytics and clicking on the row will display a comparison of the picker questions and answers. Clicking on the pencil icon in the row will let you rename the analytics.`}
					/>
				</div>

				<Scroller style={{ marginTop: 45, marginBottom: 30 }}>
					{loading && <Loading />}
					{!loading &&
						reports?.map(report => (
							<ReportListItem
								key={report.id}
								onClick={() => selectReport(report)}
								report={report}
								selectedReport={selectedReport}
							/>
						))}
				</Scroller>
			</ReportScrollerWrapper>
			<Container
				style={{
					flex: 3,
					padding: '20px 10px 0',
					borderRadius: '20px',
				}}>
				{!selectedReport && (
					<QuestionParent>Please select analytics from the list.</QuestionParent>
				)}
				{selectedReport && !reportQuestions && <Loading />}
				{reportQuestions && (
					<>
						<XLSXContainer>
							<ReportQuestionFilter
								allQuestions={reportQuestions.questions}
								setFilteredReportQuestions={setFilteredReportQuestions}
							/>
							<Button
								onClick={() =>
									downloadXLSX(selectedReport, reportQuestions, hiddenQuestions)
								}
								style={{ textTransform: 'capitalize' }}
								variant="contained">
								<Download /> Analytics
							</Button>
							<Button
								onClick={() => downloadFullXLSX(selectedReport, hiddenQuestions)}
								variant="contained"
								style={{ textTransform: 'none' }}>
								<Download /> Full data
							</Button>
							<Button
								onClick={() => downloadPDFs(selectedReport)}
								variant="contained"
								style={{ textTransform: 'capitalize' }}>
								<Download /> PDFs
							</Button>
						</XLSXContainer>

						<QuestionsScroller>
							{
								<h1 style={{ textAlign: 'center', color: 'white' }}>
									{selectedReport?.name}
								</h1>
							}
							{filteredReportQuestions.map(question => (
								<QuestionsItem
									key={question.id}
									onClickOption={option => onClickOption(option, question)}
									onClickAvailable={() => onClickAvailable(question)}
									onClickMissing={() => onClickMissing(question)}
									onClickNoValue={() => onClickNoValue(question)}
									totalForms={selectedReport?.forms?.length || 0}
									question={question}
									hiddenQuestions={hiddenQuestions}
									toggleVisibility={toggleVisibility}
								/>
							))}
						</QuestionsScroller>
					</>
				)}
			</Container>
			<CreateReportFirstDialog
				open={createReportDialogOpen}
				projects={projectsWithForms}
				setOpen={setCreateReportDialogOpen}
				toggleForm={toggleForm}
				setSecondDialogOpen={setCreateReportSecondDialogOpen}
				totalSelectedForms={totalSelectedForms}
				setReportName={setReportName}
			/>

			<CreateReportSecondDialog
				open={createReportSecondDialogOpen}
				reportName={reportName}
				createReport={createReport}
				projects={projectsWithForms}
				setOpen={setCreateReportSecondDialogOpen}
				toggleForm={toggleForm}
				totalSelectedForms={totalSelectedForms}
			/>

			<FormListDialog
				open={formListDialogOpen}
				option={option}
				question={question}
				report={selectedReport}
				setOpen={setFormListDialogOpen}
			/>
		</ReportContainer>
	);
};

export default Reports;
