import FiberManualRecord from '@mui/icons-material/FiberManualRecord';
import { IconButton } from '@mui/material';
import styled from 'styled-components';

export const StyledRoot = styled.div`
	position: relative;
	overflow: hidden;
`;

export const StyledItem = styled.div`
	position: absolute;
	height: 100%;
	width: 100%;
`;

export const StyledItemWrapper = styled.div`
	position: relative;
	width: 100%;
	height: 100%;
`;

export const StyledIndicators = styled.div`
	width: 100%;
	margin-top: 10px;
	text-align: center;
`;

export const StyledFiberManualRecordIcon = styled(FiberManualRecord)`
	font-size: 15px;
`;

export const StyledIndicatorIconButton = styled(IconButton)<{ $active: boolean }>`
	cursor: pointer;
	transition: 200ms;
	padding: 0;
	color: ${({ $active }) => ($active ? '#494949' : '#afafaf')};

	&:hover,
	:active {
		color: ${({ $active }) => ($active ? '#494949' : '#1f1f1f')};
	}
`;

export const StyledIconButton = styled(IconButton)<{
	$alwaysVisible: boolean;
	$fullHeightHover: boolean;
}>`
	color: #ffb310;
	background-color: black;
	box-shadow: 0 0 5px gray;

	margin: 0 10px;
	position: relative;
	top: calc(50% - 20px) !important;
	font-size: 30px;
	transition: 200ms;
	cursor: pointer;
	opacity: ${({ $alwaysVisible }) => ($alwaysVisible ? '1' : '0')};

	&:hover {
		opacity: 0.8;
	}
`;

export const StyledButtonWrapper = styled.div<{
	$next: boolean;
	$prev: boolean;
	$fullHeightHover: boolean;
}>`
	height: ${({ $fullHeightHover }) => ($fullHeightHover ? '100%' : '100px')};
	background-color: transparent;
	z-index: 1;

	position: absolute;
	top: 50%;
	right: ${({ $next }) => ($next ? '0' : '')};
	left: ${({ $prev }) => ($prev ? '0' : '')};
	transform: translate(0, -50%);
`;

export const CarouselIframe = styled.iframe`
	border: 0;
	width: 100%;
	height: 100%;
`;

export const CarouselImg = styled.img`
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	max-width: 99%;
	max-height: 99%;
	object-fit: contain;
`;

export const CarouselPdf = styled.object`
	width: 100%;
	height: 100%;
`;
export const CarouselPdfPlaceholder = styled.div`
	display: flex;
	flex-direction: column;
	gap: 5px;

	color: #ffb310;
	margin: auto;
	width: fit-content;
`;
