import { createContext, useEffect, useState } from 'react';
import { ContainerProps } from '../types';

export type LoadingStatus = {
	title?: string;
	detail?: string;
};

export type LoadingContextType = {
	loading: boolean;
	setLoading: React.Dispatch<React.SetStateAction<boolean>>;

	loadingProjects: boolean;
	setLoadingProjects: React.Dispatch<React.SetStateAction<boolean>>;
	loadingProjectStatuses: boolean;
	setLoadingProjectStatuses: React.Dispatch<React.SetStateAction<boolean>>;

	loadingStatus: LoadingStatus | null;
	setLoadingStatus: React.Dispatch<React.SetStateAction<LoadingStatus | null>>;
};

export const LoadingContext = createContext<LoadingContextType>({} as LoadingContextType);

export const LoadingProvider = ({ children }: ContainerProps) => {
	// Global loading state/status
	const [loading, setLoading] = useState(false);
	const [loadingProjects, setLoadingProjects] = useState(false);
	const [loadingStatus, setLoadingStatus] = useState<LoadingStatus | null>(null);
	const [loadingProjectStatuses, setLoadingProjectStatuses] = useState(false);

	/**
	 * This useEffect ensures that loadingStatus is set back to null whenever loading is false
	 */
	useEffect(() => {
		if (!loading) setLoadingStatus(null);
	}, [loading]);

	return (
		<LoadingContext.Provider
			value={{
				loading,
				setLoading,

				loadingProjects,
				setLoadingProjects,
				loadingProjectStatuses,
				setLoadingProjectStatuses,

				loadingStatus,
				setLoadingStatus,
			}}>
			{children}
		</LoadingContext.Provider>
	);
};
