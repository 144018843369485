import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import Spinner from '../../../../../../reusable-components/Spinner';
import { YellowIconButton } from '../../../../../../styled-components/buttons';
import { CloudUpload } from '@mui/icons-material';
import styled from 'styled-components';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { ProjectContext } from '../../../../../../../context/ProjectProvider';
import { FirebaseFile, Organization } from '../../../../../../../types';
import { getOrganization, uploadProcoreFiles } from '../../../../../../../firebase';
import FileList from './FileList';
import theme from '../../../../../../../styles/theme';
import { AuthContext } from '../../../../../../../context/AuthProvider';

type ProcoreList = {
	procoreProjectId: string;
	procoreOrganizationId: string;
	organizationId: string;
	organizationName: string;
};

interface FirebaseFileFolder extends FirebaseFile {
	folder: string;
}

const UploadExternalFilesButton = () => {
	const { user } = useContext(AuthContext);
	const { project } = useContext(ProjectContext);
	const [loading, setLoading] = useState(false);
	const [showingDialog, setShowingDialog] = useState(false);
	const [files, setFiles] = useState<FirebaseFileFolder[]>([]);
	const [procoreProjects, setProcoreProjects] = useState<ProcoreList[]>([]);
	const [selectedOrgId, setSelectedOrgId] = useState<string>('');
	const [selectedOrg, setSelectedOrg] = useState<ProcoreList | undefined>(undefined);

	const procore = useMemo(() => {
		return project?.procore;
	}, [project?.procore]);

	const handleAddItem = (isAdd: boolean, file: FirebaseFile, folder: string) => {
		if (isAdd) {
			setFiles([...files, { ...file, folder }]);
		} else {
			setFiles(files.filter(f => f.filename !== file.filename));
		}
	};

	const handleCloseDialog = (event: object, reason: string) => {
		if (reason === 'backdropClick') {
			return;
		}
		handleClose();
	};

	const handleClose = () => {
		cleanStates();
		setShowingDialog(false);
	};

	const handleUpload = async () => {
		if (!selectedOrgId) return;

		if (!selectedOrg?.organizationId) return;

		setLoading(true);

		const urls: { url: string; folder: string }[] = [];

		files.forEach(file => {
			urls.push({ url: file.downloadUrl, folder: file.folder });
		});

		try {
			await uploadProcoreFiles({
				procoreProjectId: selectedOrg.procoreProjectId,
				procoreOrganizationId: selectedOrg.procoreOrganizationId,
				organizationId: selectedOrg.organizationId,
				projectId: project?.id,
				files: urls,
			});

			setFiles([]);
			setLoading(false);
		} catch (error) {
			console.error('Error uploading files: ', error);
			setLoading(false);
		}
	};

	const getOrganizationById = async (id: string) => {
		const organizationDoc = await getOrganization({ id: id });
		return organizationDoc.data as Organization;
	};

	const checkIfUserHasPermission = useCallback(
		async (org: Organization) => {
			if (user?.isAdmin) {
				return true;
			} else if (user?.orgOwnerIds?.includes(org.id)) {
				return true;
			} else if (user?.teamIds && user?.teamIds?.length > 0) {
				let havePermission = false;
				const orgazination = await getOrganizationById(org.id);

				orgazination.teamIds?.forEach(async teamId => {
					if (user?.teamIds?.includes(teamId)) {
						havePermission = true;
						return;
					}
				});

				return havePermission;
			} else {
				return false;
			}
		},
		[user]
	);

	const getProjectList = useCallback(async () => {
		procore?.forEach(async procore => {
			const organizationData = await getOrganizationById(procore.organizationId);

			if (!organizationData) return;

			const userHasPermission = await checkIfUserHasPermission(organizationData);

			if (!userHasPermission) {
				return;
			}

			const item = {
				procoreProjectId: procore.procoreProjectId,
				procoreOrganizationId: procore.procoreOrganizationId,
				organizationId: organizationData.id,
				organizationName: organizationData.name,
			};

			setProcoreProjects(prev => {
				const exist = prev.filter(p => p.organizationId === item.organizationId);
				if (exist.length === 0) {
					return [...prev, item];
				}
				return prev;
			});
		});
	}, [checkIfUserHasPermission, procore]);

	useEffect(() => {
		getProjectList();
	}, [getProjectList]);

	const disabledItem = () => {
		return selectedOrgId === '';
	};

	useEffect(() => {
		setFiles([]);
	}, [selectedOrgId]);

	const cleanStates = () => {
		setFiles([]);
		setSelectedOrg(undefined);
		setSelectedOrgId('');
	};

	return (
		<>
			{loading ? (
				<Spinner />
			) : (
				<YellowIconButton
					title="Manage shared users for this project"
					size="large"
					onClick={() => setShowingDialog(true)}>
					<StyledCloudUploadIcon />
				</YellowIconButton>
			)}

			<Dialog open={showingDialog} onClose={handleCloseDialog}>
				<DialogTitle>Upload External Files</DialogTitle>
				<StyledDialogContent>
					<SelectStyled
						onChange={e => {
							setSelectedOrgId(e.target.value);

							const selectedOrg = procoreProjects.find(
								p => p.organizationId === e.target.value
							);

							setSelectedOrg(selectedOrg);
						}}>
						<option value="">Select Organization</option>
						{procoreProjects?.map(project => {
							return (
								<option key={project.organizationId} value={project.organizationId}>
									{project.organizationName}
								</option>
							);
						})}
					</SelectStyled>

					{project?.pdf && project?.pdf.length > 0 && (
						<FileList
							title="PDF"
							files={project?.pdf}
							disabled={disabledItem()}
							handleAddItem={handleAddItem}
						/>
					)}
					{project?.photos && project?.photos.length > 0 && (
						<FileList
							title="Photos"
							files={project?.photos}
							disabled={disabledItem()}
							handleAddItem={handleAddItem}
						/>
					)}
					{project?.existingDocs && project?.existingDocs.length > 0 && (
						<FileList
							title="Raw Data"
							files={project?.existingDocs}
							disabled={disabledItem()}
							handleAddItem={handleAddItem}
						/>
					)}
					{project?.pointCloud && project?.pointCloud.length > 0 && (
						<FileList
							title="Point Cloud"
							files={project?.pointCloud}
							disabled={disabledItem()}
							handleAddItem={handleAddItem}
						/>
					)}
					{project?.autodesk && project?.autodesk.length > 0 && (
						<FileList
							title="Autodesk"
							files={project?.autodesk}
							disabled={disabledItem()}
							handleAddItem={handleAddItem}
						/>
					)}
					{project?.proposal && project?.proposal.length > 0 && (
						<FileList
							title="Matter port"
							files={project?.proposal}
							disabled={disabledItem()}
							handleAddItem={handleAddItem}
						/>
					)}
				</StyledDialogContent>
				<DialogActions>
					{loading ? (
						<Spinner size={30} />
					) : (
						<>
							<Button
								disabled={files.length === 0 || disabledItem()}
								onClick={handleUpload}>
								Upload Selected Files ({files.length})
							</Button>
							<Button onClick={handleClose}>close</Button>
						</>
					)}
				</DialogActions>
			</Dialog>
		</>
	);
};

export default UploadExternalFilesButton;

const StyledCloudUploadIcon = styled(CloudUpload)`
	color: #ffb310;
`;

const StyledDialogContent = styled(DialogContent)`
	display: flex;
	gap: 10px;
	padding: 20px 24px !important;

	@media screen and (max-width: 600px) {
		padding: 5px !important;
	}
`;

const SelectStyled = styled('select')`
	height: 40px;
	width: 100%;
	border: 1px solid #ccc;
	border-radius: 5px;
	padding: 0 10px;
	font-size: 16px;
	color: ${theme.palette.primary.main};
`;
