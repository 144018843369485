import { useParams } from 'react-router-dom';
import UtilityFormRenderer from '../components/screen-components/ProjectUtilityForm/UtilityFormRenderer';
import { formatProjectTimestamps, useNav } from '../utils';
import { useContext, useEffect } from 'react';
import { ProjectContext } from '../context/ProjectProvider';
import { getProject } from '../firebase';
import { FormProvider } from '../components/screen-components/ProjectUtilityForm/FormContext';

export default function ProjectUtilityForm() {
	const { projectId }: any = useParams();

	const { setProject } = useContext(ProjectContext);

	const navigate = useNav();

	// Fetch, format, and set project
	useEffect(() => {
		const getSetProject = async () => {
			try {
				const res = await getProject({ projectId: projectId });
				const resData = res.data as any;
				if (resData.project) {
					setProject(formatProjectTimestamps([resData.project])[0]);
				} else {
					console.error(`No data found for project '${projectId}'`);
					navigate('/404', { replace: true });
				}
			} catch (err) {
				console.error(err);
				navigate('/404', { replace: true });
			}
		};
		getSetProject();
		// Cleanup on unmount
		return () => {
			setProject(null);
		};
	}, [projectId, setProject, navigate]);

	return (
		<FormProvider>
			<UtilityFormRenderer />
		</FormProvider>
	);
}
