import { useContext } from 'react';
import {
	ArcElement,
	BarElement,
	CategoryScale,
	Chart,
	Legend,
	LinearScale,
	Plugin,
	Title,
	Tooltip,
} from 'chart.js';

import { Bar, Doughnut } from 'react-chartjs-2';
import styled from 'styled-components';
import { Lead } from '../../../types';
import { leadStatusColor, leadStatuses } from '../../../utils';
import { AuthContext } from '../../../../../context/AuthProvider';
import { AnyObject } from 'chart.js/dist/types/basic';
import { UserObj } from '../../../../../types';

export default function Graphs({ filteredLeads }: { filteredLeads: Lead[] }) {
	const { projectRoleOptions } = useContext(AuthContext);

	const accountManagers = projectRoleOptions['accountManager'].map(userWithData => {
		const userObj: UserObj = {
			email: userWithData.email,
			name: userWithData.fullName,
			id: userWithData.id,
		};
		return userObj;
	});

	Chart.register(
		CategoryScale,
		LinearScale,
		BarElement,
		Title,
		Tooltip,
		Legend,
		ArcElement
	);

	const barOptions = (title: string) => ({
		indexAxis: 'y' as const,
		elements: {
			bar: {
				borderWidth: 2,
			},
		},
		responsive: true,
		plugins: {
			legend: {
				display: false,
			},
			title: {
				display: true,
				text: title,
			},
		},
	});

	const totalLeadStatusCounts = leadStatuses.map(
		status => filteredLeads.filter(lead => lead.status === status).length
	);

	const dailyAccountManagerCounts = accountManagers.map(
		accountManager =>
			filteredLeads
				.filter(lead => lead.accountManager?.id === accountManager?.id)
				.filter(
					lead =>
						lead.lastActivity &&
						new Date(lead.lastActivity).setHours(0, 0, 0, 0) ===
							new Date().setHours(0, 0, 0, 0)
				).length
	);

	const doughnutOptions = (arr: number[], title: string) => ({
		responsive: true,
		plugins: {
			legend: {
				position: 'right' as const,
			},
			title: {
				display: true,
				text: title,
			},
		},
		total: arr.reduce((acc, curr) => acc + curr, 0),
	});

	const dailyBarData = {
		labels: accountManagers.map(manager => manager.name),
		datasets: [
			{
				label: `Today's Leads`,
				data: dailyAccountManagerCounts,
				backgroundColor: 'rgba(75,192,192,0.5)',
				borderColor: 'rgba(75,192,192,1)',
			},
		],
	};

	const dailyDoughnutData = {
		labels: accountManagers.map(manager => manager.name),
		datasets: [
			{
				label: `Today's Leads`,
				data: dailyAccountManagerCounts,
				backgroundColor: accountManagers.map(
					(accountManager, i) => `hsl(${(360 / accountManagers.length) * i}, 100%, 50%)`
				),
				borderColor: 'black',
			},
		],
	};

	const totalBarData = {
		labels: accountManagers.map(manager => manager.name),
		datasets: [
			{
				label: 'Total Leads',
				data: accountManagers.map(accountManager => {
					return filteredLeads.filter(
						lead => lead.accountManager?.id === accountManager?.id
					).length;
				}),
				backgroundColor: 'rgba(75,192,192,0.5)',
				borderColor: 'rgba(75,192,192,1)',
			},
		],
	};

	const totalDoughnutData = {
		labels: leadStatuses,
		datasets: [
			{
				label: 'Leads',
				data: totalLeadStatusCounts,
				backgroundColor: leadStatuses.map(status => leadStatusColor(status)),
				borderColor: ['black'],
			},
		],
	};

	const centerDoughnutPlugin: Plugin<'doughnut', AnyObject> = {
		id: 'doughnutCenterText',
		beforeDraw: (
			chart: Chart<'doughnut', number[], unknown>,
			args: { cancelable: true },
			options: AnyObject
		) => {
			const width = chart.width;
			const height = chart.height;
			const ctx = chart.ctx;
			const legendWidth = chart.legend?.width || 0;
			const doughnutWidth = width - legendWidth;
			chart.ctx.restore();
			let fontSize = height / 114;
			ctx.font = fontSize.toFixed(2) + 'em Epilogue';

			let text = '-';
			const chartOptions = chart.config.options as any;
			if (chartOptions && chartOptions.total) {
				const total = chartOptions.total as number;
				text = total.toString();
			}

			let textMetrics = ctx.measureText(text);
			// Makes fontSize smaller until it fits within doughnut center
			// (the diameter of the center is 50% of the doughnut's width by default)
			while (textMetrics.width >= doughnutWidth / 2 - 10) {
				fontSize -= 0.3;
				ctx.font = fontSize.toFixed(2) + 'em Epilogue';
				textMetrics = ctx.measureText(text);
			}

			const textX = doughnutWidth / 2 - textMetrics.width / 2;
			const textY =
				height / 1.87 +
				(textMetrics.actualBoundingBoxAscent + textMetrics.actualBoundingBoxDescent) / 2;

			ctx.fillText(text, textX, textY);
			ctx.fillStyle = '#ffb310';
			ctx.save();
		},
	};

	return (
		<GraphWrapper>
			<ChartCard>
				<Bar options={barOptions(`Today's Outreach`)} data={dailyBarData} />
			</ChartCard>
			<ChartCard>
				<Doughnut
					options={doughnutOptions(
						dailyAccountManagerCounts,
						`Today's Leads per Account Manager`
					)}
					data={dailyDoughnutData}
					plugins={[centerDoughnutPlugin]}
				/>
			</ChartCard>

			<ChartCard>
				<Bar options={barOptions('Total Outreach')} data={totalBarData} />
			</ChartCard>
			<ChartCard>
				<Doughnut
					options={doughnutOptions(totalLeadStatusCounts, 'Total Leads per Status')}
					data={totalDoughnutData}
					plugins={[centerDoughnutPlugin]}
				/>
			</ChartCard>
		</GraphWrapper>
	);
}

const GraphWrapper = styled.div`
	display: flex;
	width: 100%;
	flex-wrap: wrap;
	align-items: center;
	justify-content: center;
`;

const ChartCard = styled.div`
	width: 45%;
	height: calc((100vh - 150px) / 2);
	min-height: 200px;

	@media (max-width: 1023px) {
		width: 100%;
	}

	display: flex;
	position: relative;
	align-items: center;
	justify-content: center;

	border: #ffb310 solid 1px;
	background-color: black;

	& > canvas {
		max-height: 100%;
		max-width: 100%;
	}
`;
