import { useCallback, useContext, useEffect } from 'react';
import { V2FormGalleryContext } from '../V2FormGalleryProvider';
import ErrorScreen from '../../../components/reusable-components/ErrorScreen';
import LoadingScreen from '../../../components/reusable-components/LoadingScreen';
import V2FormGallery from './V2FormGallery';
import { useParams } from 'react-router-dom';
import { getProjectAddress } from '../../../firebase';

type Props = {
	setProjectAddress: (address: string) => void;
};

export default function V2FormGalleryRenderer({ setProjectAddress }: Props) {
	const { errorMessage, loadingMessage, formRes, fetchV2Form } =
		useContext(V2FormGalleryContext);

	const { formId } = useParams();

	useEffect(() => {
		fetchV2Form(formId || '');
	}, [fetchV2Form, formId]);

	const getProjectFromFormId = useCallback(async () => {
		const projectId = formRes?.form.projectId;
		if (!projectId) return;

		const response = await getProjectAddress(projectId);

		const address = response.data as string;

		setProjectAddress(address);
	}, [formRes?.form.projectId, setProjectAddress]);

	useEffect(() => {
		getProjectFromFormId();
	}, [formRes, getProjectFromFormId]);

	return errorMessage ? (
		<ErrorScreen message={errorMessage} />
	) : loadingMessage ? (
		<LoadingScreen message={loadingMessage} />
	) : formRes ? (
		<V2FormGallery />
	) : null;
}
