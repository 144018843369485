import { FC } from 'react';
import styled from 'styled-components';

const ListContainer: FC<React.PropsWithChildren<unknown>> = ({ children }) => {
	return <Container>{children}</Container>;
};

const Container = styled.div`
	display: flex;
	flex-direction: column;
	gap: 10px;

	background-color: #212124;
	border-radius: 20px;

	width: 100%;
	overflow: hidden;
	padding: 10px 30px 30px 30px;

	box-shadow: inset -5px -5px 8px -5px rgba(89, 89, 89, 0.5),
		inset 5px 5px 8px -5px rgba(89, 89, 89, 0.5);

	@media screen and (min-width: 769px) {
		width: 720px;
		margin: 10px auto;
	}
	@media screen and (max-width: 768px) {
		width: 100%;
		min-height: 100%;
	}
`;

export default ListContainer;
