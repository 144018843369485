import { useCallback, useEffect, useMemo, useState } from 'react';
import { FormNode } from '../../../ProjectUtilityFormV2/utils/types';
import { collection, doc, onSnapshot, query, updateDoc, where } from 'firebase/firestore';
import { db } from '../../../../../firebase';
import { FlattenedItem } from './types';

const useFormNodes = (formId: string, projectId: string) => {
	const [allFormNodes, setAllFormNodes] = useState<FormNode[]>([]);

	const loadNodes = useCallback(() => {
		if (!formId || !projectId) return;

		const nodesQuery = query(
			collection(db, 'utility_forms_v2_items'),
			where('formId', '==', formId),
			where('projectId', '==', projectId)
		);

		const unsubNodes = onSnapshot(nodesQuery, async snapshot => {
			const formNodes = snapshot.docs.map(
				doc => ({ ...doc.data(), id: doc.id } as FormNode)
			);
			setAllFormNodes(formNodes);
		});

		return unsubNodes;
	}, [formId, projectId]);

	useEffect(() => {
		const unsub = loadNodes();
		return () => {
			if (unsub) unsub();
		};
	}, [loadNodes]);

	const saveNodes = async (nodes: FormNode[]) => {
		console.log('saveNodes', nodes);
		const nodesPromises = nodes.map(node => {
			const nodeRef = doc(db, 'utility_forms_v2_items', node.id);
			return updateDoc(nodeRef, node);
		});

		await Promise.all(nodesPromises);
	};

	const headerPlaceholder: FormNode = useMemo(
		() => ({
			id: 'header',
			displayTitle: '',
			formId: '',
			parentId: '',
			type: 'placeholder',
			projectId: '',
		}),
		[]
	);

	const footerPlaceholder: FormNode = useMemo(
		() => ({
			id: 'footer',
			displayTitle: '',
			formId: '',
			parentId: '',
			type: 'placeholder',
			projectId: '',
		}),
		[]
	);

	const flattenedHeaderPlaceholder: FlattenedItem = useMemo(
		() => ({
			id: 'header',
			children: [],
			collapsed: false,
			node: headerPlaceholder,
			parentId: null,
			depth: 0,
			index: 0,
		}),
		[headerPlaceholder]
	);

	const flattenedFooterPlaceholder: FlattenedItem = useMemo(
		() => ({
			id: 'footer',
			children: [],
			collapsed: false,
			node: footerPlaceholder,
			parentId: null,
			depth: 0,
			index: 0,
		}),
		[footerPlaceholder]
	);

	return {
		allFormNodes,
		flattenedHeaderPlaceholder,
		flattenedFooterPlaceholder,
		saveNodes,
	};
};

export default useFormNodes;
