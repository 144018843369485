import { Grid, Box } from '@mui/material';
import Button from '@mui/material/Button';
import { Link } from 'react-router-dom';
import LogoContainer from '../components/reusable-components/LogoContainer';
import { useContext, useEffect } from 'react';
import { LoadingContext } from '../context/LoadingProvider';
import Spinner from '../components/reusable-components/Spinner';

import EmailIcon from '@mui/icons-material/Email';

export default function Welcome() {
	const { loading } = useContext(LoadingContext);

	useEffect(() => {
		document.title = '404 Page Not Found | Robotic Imaging';
	}, []);

	return (
		<Box
			style={{
				width: '100vw',
				height: '100vh',
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center',
				background:
					'radial-gradient(circle at 90%, #000, #000 50%, #ffb310 74%, #000 75%)',
			}}>
			{!loading ? (
				<LogoContainer>
					<Grid
						container
						component="form"
						direction="column"
						justifyContent="center"
						alignItems="center"
						spacing={2}
						style={{ padding: '50px' }}>
						<Grid item style={{ padding: '1em' }}>
							<p
								style={{
									fontSize: '100px',
									color: '#ffb310',
									margin: '0px',
									marginBottom: '-50px',
								}}>
								Whoops!
							</p>
						</Grid>
						<Grid item>
							<p
								style={{
									fontSize: '40px',
									color: '#ffb310',
									marginBottom: '-25px',
								}}>
								Page Not Found
							</p>
						</Grid>
						<Grid item>
							<p
								style={{
									fontSize: '25px',
									color: '#ffb310',
									textAlign: 'center',
									marginBottom: '-15px',
								}}>
								It looks like the page you were searching doesn't exist. <br></br>We are
								sorry for the inconvience!
							</p>
						</Grid>
						<Grid item>
							<p
								style={{
									fontSize: '20px',
									color: '#ffb310',
									textAlign: 'center',
								}}>
								Error: 404
							</p>
						</Grid>
						<Grid item>
							<Link to={'/'} style={{ textDecoration: 'unset' }}>
								<Button
									variant="outlined"
									color="primary"
									size="large"
									startIcon={<EmailIcon />}>
									Back to Home
								</Button>
							</Link>
						</Grid>
					</Grid>
				</LogoContainer>
			) : (
				<Spinner />
			)}
		</Box>
	);
}
