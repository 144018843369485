import { ChangeEvent, useContext } from 'react';
import { Checkbox } from '@mui/material';
import { ProjectData } from '../../../../../../../types';
import { ProjectContext } from '../../../../../../../context/ProjectProvider';
import theme from '../../../../../../../styles/theme';

export default function CheckboxCell({ project }: { project: ProjectData }) {
	const { projectsSelected, setProjectsSelected, canSelectProjects } =
		useContext(ProjectContext);

	const updateSelected = (e: ChangeEvent<HTMLInputElement>) => {
		e.stopPropagation();
		if (e.target.checked) {
			setProjectsSelected((prev: ProjectData[]) => [...prev, project]);
		} else {
			setProjectsSelected(prev => prev.filter(proj => proj.id !== project.id));
		}
	};

	return canSelectProjects ? (
		<Checkbox
			checked={projectsSelected.some(proj => proj.id === project.id)}
			onChange={updateSelected}
			onClick={e => e.stopPropagation()}
			sx={{
				color: '#f6f6f6',
				'&.Mui-checked': {
					color: theme.palette.primary.main,
				},
			}}
		/>
	) : null;
}
