import { useContext, useEffect } from 'react';
import { FormV2Context } from '../context/FormV2Context.types';
import { useNavigate } from 'react-router-dom';

const useArchivedListener = () => {
	const { utilityForm } = useContext(FormV2Context);
	const navigate = useNavigate();

	useEffect(() => {
		if (utilityForm && utilityForm.status === 'archived') {
			navigate(-1);
			alert('Current form was archived.');
		}
	}, [navigate, utilityForm]);
};

export default useArchivedListener;
