import { useContext } from 'react';
import useDownloadZippedFiles from '../../../../../../../utils/useDownloadZippedFiles/useDownloadZippedFiles';
import { LoadingContext } from '../../../../../../../context/LoadingProvider';

const useDownloadPhotos = () => {
	const { buildPhotosListFromForm, downloadZippedFiles, safeFileName } =
		useDownloadZippedFiles();
	const { setLoadingStatus } = useContext(LoadingContext);

	const downloadPhotos = async (formId: string, projectId: string, zipName: string) => {
		setLoadingStatus({ title: 'Downloading photos', detail: 'Please wait...' });
		const fileList = await buildPhotosListFromForm(formId, projectId);
		await downloadZippedFiles(fileList, `${zipName}.zip`, message =>
			setLoadingStatus({
				title: 'Downloading photos',
				detail: `${message}`,
			})
		);
		setLoadingStatus(null);
	};

	return { downloadPhotos, safeFileName };
};

export default useDownloadPhotos;
