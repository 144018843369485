import { useContext, useEffect, useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';

import { onAuthStateChanged, signInAnonymously } from 'firebase/auth';
import { auth, getProject } from '../firebase';

import DeliverableFiles from '../components/reusable-components/DeliverableFiles';
import DeliverableFilesHeader from '../components/reusable-components/DeliverableFiles/DeliverableFilesHeader';
import { ProjectContext } from '../context/ProjectProvider';

import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { AnonymousBackground, AnonymousLoadingContainer } from './UploadDeliverables';
import Spinner from '../components/reusable-components/Spinner';
import { formatProjectTimestamps } from '../utils';

export default function DownloadDeliverables() {
	const [loading, setLoading] = useState(true);
	const [loadingStatus, setLoadingStatus] = useState('');

	const { project, setProject } = useContext(ProjectContext);
	const { projectId } = useParams();

	const disableLoading = useCallback(() => {
		setLoadingStatus('');
	}, [setLoadingStatus]);

	const handleError = useCallback((e: any, status: string) => {
		console.log(e);
		setLoading(false);
		setLoadingStatus(status);
	}, []);

	useEffect(() => {
		const unsub = onAuthStateChanged(auth, async user => {
			setLoadingStatus('Loading project details...');

			if (user) {
				try {
					const res = await getProject({
						projectId: projectId,
						anonymous: { download: true },
					});
					const resData = res.data as any;
					setProject(formatProjectTimestamps([resData.project])[0]);
					disableLoading();
				} catch (err) {
					handleError(err, "Error: Can't access project data...");
				}
			} else {
				signInAnonymously(auth).catch(e =>
					handleError(e, "Error: Can't connect to backend")
				);
			}
		});

		return () => {
			setProject(null);
			unsub();
		};
	}, [disableLoading, handleError, projectId, setProject]);

	useEffect(() => {
		if (project) {
			document.title = `${
				project.address || project.name || 'Unnamed Project'
			} | Download Page`;
		}
	}, [project]);

	return (
		<AnonymousBackground>
			{project ? (
				<>
					<DeliverableFilesHeader project={project} />
					<DeliverableFiles downloadOnly />
				</>
			) : (
				<AnonymousLoadingContainer>
					<span>{loadingStatus}</span>
					{loading ? (
						<Spinner size={200} />
					) : (
						<ErrorOutlineIcon color="primary" fontSize="large" />
					)}
				</AnonymousLoadingContainer>
			)}
		</AnonymousBackground>
	);
}
