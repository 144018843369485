import { useRef, useState, useContext } from 'react';
import { Dialog, DialogContent, DialogTitle } from '@mui/material';
import UploadIcon from '@mui/icons-material/Upload';
import { read, utils } from 'xlsx';
import { uploadLeads } from '../../../firebase';
import { SnackContext } from '../../../context/SnackProvider';
import { YellowIconButton } from '../../../components/styled-components/buttons';
import { DuplicatedLead } from '../types';
import { LeadsContext } from './LeadsProvider';
import LoadingScreen from '../../../components/reusable-components/LoadingScreen';
import { AuthContext } from '../../../context/AuthProvider';

export default function LeadsImportButton() {
	const { setSnackbarProps } = useContext(SnackContext);
	const { fetchSetLeads } = useContext(LeadsContext);
	const { user, firebaseAuthData } = useContext(AuthContext);

	const [loadingText, setLoadingText] = useState('');
	const inputRef = useRef<any>(null);

	const formatString = (str: string) => {
		if (!str || typeof str !== 'string') return '';
		return str.trim();
	};

	const formatPhoneNumber = (input: string | number) => {
		if (typeof input === 'string') return formatString(input);
		else if (typeof input === 'number') {
			const str = input.toString();
			if (str.length !== 10) return str;

			let res = '(';
			for (let i = 0; i < str.length; i++) {
				if (i === 3) res += ') ';
				else if (i === 6) res += '-';
				res += str[i];
			}
			return res;
		} else return '';
	};

	const nameFilter = (name: string) => {
		if (!name || name.includes('[') || typeof name !== 'string') return '';
		else return formatString(name);
	};

	const dateConversion = (date: string) => {
		if (typeof date === 'string' && date) {
			const dateObj = new Date(date);
			const millis = dateObj.valueOf();
			if (Number.isNaN(millis)) return null;
			else return millis;
		} else return null;
	};

	const getAccountManager = () => {
		if (user?.userTags?.accountManager) {
			return {
				id: firebaseAuthData?.uid,
				name: user?.fullName,
				email: user?.email,
			};
		}
		return null;
	};

	return (
		<YellowIconButton size="large" onClick={() => inputRef.current?.click()}>
			<UploadIcon />

			<input
				style={{ display: 'none' }}
				ref={inputRef}
				type="file"
				accept=".xlsx"
				onChange={async e => {
					if (e.target.files) {
						const file = e.target.files[0];

						if (file.name.endsWith('.xlsx')) {
							setLoadingText('Reading file...');

							const data = await file.arrayBuffer();
							const workbook = read(data);
							const leads = utils
								.sheet_to_json<any>(workbook.Sheets[workbook.SheetNames[0]])
								.map(lead => {
									return {
										company: formatString(lead['Company']),
										contactTitle: formatString(lead['Title']),
										email: formatString(lead['Email']),
										firstName: nameFilter(lead['First Name']),
										lastName: nameFilter(lead['Last Name']),
										lastActivity: dateConversion(lead['Last Activity']),
										mobileNumber: formatPhoneNumber(lead['Mobile Number']),
										hqNumber: formatPhoneNumber(lead['Direct Number']),
										accountManager: getAccountManager(),
										state: formatString(lead['State']),
										status: formatString(lead['Lead Status']),
									};
								});

							setLoadingText(`Importing leads...`);
							const response: any = await uploadLeads({
								leads: leads,
							});
							const duplicateLeads = response?.data?.duplicateLeads as DuplicatedLead[];

							inputRef.current.value = null;
							setLoadingText('');

							if (!duplicateLeads.length) {
								fetchSetLeads();
								setSnackbarProps({
									open: true,
									severity: 'success',
									message: `${leads.length} leads successfully imported!`,
								});
							} else if (duplicateLeads.length === leads.length) {
								setSnackbarProps({
									open: true,
									message: 'All imported leads were duplicates.',
									severity: 'error',
								});
							} else {
								const numGood = leads.length - duplicateLeads.length;
								const numBad = duplicateLeads.length;

								console.log(duplicateLeads);
								fetchSetLeads();
								setSnackbarProps({
									open: true,
									severity: 'warning',
									message: `${numGood} lead${
										numGood > 1 ? 's' : ''
									} successfully imported.
									${numBad} duplicate lead${numBad > 1 ? 's' : ''} not imported.`,
								});
							}
						} else {
							setSnackbarProps({
								open: true,
								message: 'Invalid file type. Please upload only .xlsx files.',
								severity: 'error',
							});
						}
					}
				}}
			/>

			<Dialog open={!!loadingText} PaperProps={{ style: { width: '300px' } }}>
				<DialogTitle>Lead Import</DialogTitle>
				<DialogContent>
					<LoadingScreen message={loadingText} textColor="black" />
				</DialogContent>
			</Dialog>
		</YellowIconButton>
	);
}
