import { useEffect, useContext } from 'react';
import styled from 'styled-components';
import { ProjectContext } from '../../../context/ProjectProvider';

import ProposalBox from './boxes/ProposalBox';
import MatterportBox from './boxes/MatterportBox';
import PointCloudBox from './boxes/PointCloudBox';
import PhotosBox from './boxes/PhotosBox';
import PdfBox from './boxes/PdfBox';
import AutodeskBox from './boxes/AutodeskBox';
import ExistingBox from './boxes/ExistingBox';

export default function DeliverableFiles({
	downloadOnly = false,
	anonUpload = false,
}: {
	downloadOnly?: boolean;
	anonUpload?: boolean;
}) {
	const { project } = useContext(ProjectContext);

	useEffect(() => {
		const prevent = (e: DragEvent) => {
			e.preventDefault();
		};
		window.addEventListener('dragover', prevent, false);
		window.addEventListener('drop', prevent, false);

		return () => {
			window.removeEventListener('dragover', prevent);
			window.removeEventListener('drop', prevent);
		};
	}, []);

	return (
		<>
			{downloadOnly && project?.projectStatus === 'Proposal Sent' ? (
				<ProposalBox />
			) : (
				<DeliverableFileListContainer>
					<ExistingBox downloadOnly={downloadOnly} anonUpload={anonUpload} />
					<PhotosBox downloadOnly={downloadOnly} anonUpload={anonUpload} />
					<MatterportBox downloadOnly={downloadOnly} />
					<PointCloudBox downloadOnly={downloadOnly} anonUpload={anonUpload} />
					<PdfBox downloadOnly={downloadOnly} anonUpload={anonUpload} />
					<AutodeskBox downloadOnly={downloadOnly} anonUpload={anonUpload} />
				</DeliverableFileListContainer>
			)}
		</>
	);
}

const DeliverableFileListContainer = styled.div`
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	height: 100%;
	justify-content: center;
`;
