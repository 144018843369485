import { useContext, useState } from 'react';
import { FormNode } from '../../../../../utils/types';
import dayjs, { Dayjs } from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { FormV2Context } from '../../../../../context/FormV2Context.types';
import { InputFieldContainer } from '../FormFillV2.styles';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

dayjs.extend(utc);
dayjs.extend(timezone);

interface DateTimeFieldProps {
	node: FormNode;
}

const DateTimeField: React.FC<React.PropsWithChildren<DateTimeFieldProps>> = ({
	node,
}) => {
	const [open, setOpen] = useState(false);
	const { updateValue } = useContext(FormV2Context);
	const [value, setValue] = useState<Dayjs | null>(
		node.value && typeof node.value === 'object' && 'toDate' in node.value
			? dayjs(node.value.toDate())
			: null
	);

	const handleDateTimeChange = async (newValue: Dayjs | null) => {
		setOpen(false);
		setValue(newValue);
		if (newValue && node.id) {
			// Convert to users timezone
			const dateForFirestore = newValue.utc().toDate();
			await updateValue(node.id, dateForFirestore);
		}
	};

	return (
		<InputFieldContainer id={node.id}>
			<LocalizationProvider dateAdapter={AdapterDayjs}>
				<DateTimePicker
					label="Select Date & Time"
					open={open}
					value={value}
					onChange={handleDateTimeChange}
					onClose={() => setOpen(false)}
					slotProps={{
						textField: {
							InputProps: {
								readOnly: true,
							},
							onClick: () => setOpen(true),
						},
					}}
				/>
			</LocalizationProvider>
		</InputFieldContainer>
	);
};

export default DateTimeField;
