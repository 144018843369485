import { OrganizationsContext } from '../../../../../../../context/OrganizationsProvider';
import { useContext, useState } from 'react';
import { Organization } from '../../../../../../../types';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import OrgTeamSelector from '../../../../../../reusable-components/OrgTeamSelector';
import useOrgTeams from '../hooks/useOrgTeams';

type TeamSharingDialogProps = {
	open: boolean;
	setOpen: React.Dispatch<React.SetStateAction<boolean>>;
	teamArr: string[];
	setTeamArr: React.Dispatch<React.SetStateAction<string[]>>;
};

export default function TeamSharingDialog({
	open,
	setOpen,
	teamArr,
	setTeamArr,
}: TeamSharingDialogProps) {
	const { organizations } = useContext(OrganizationsContext);

	const [selectedOrg, setSelectedOrg] = useState<Organization | null>(null);
	const [selectedTeams, addTeam, removeTeam] = useOrgTeams(teamArr);

	const handleClose = () => {
		setOpen(false);
	};

	const handleAddTeam = (teamId: string) => {
		addTeam(teamId);
		setTeamArr(prev => [...prev, teamId]);
	};

	const handleRemoveTeam = (teamId: string) => {
		removeTeam(teamId);
		setTeamArr(prev => prev.filter(tId => tId !== teamId));
	};

	return (
		<Dialog open={open} onClose={handleClose} maxWidth={'sm'} fullWidth>
			<DialogTitle>Team(s)</DialogTitle>

			<DialogContent>
				<OrgTeamSelector
					organizations={organizations}
					selectedOrg={selectedOrg}
					setSelectedOrg={setSelectedOrg}
					selectedTeams={selectedTeams}
					addTeam={handleAddTeam}
					removeTeam={handleRemoveTeam}
				/>
			</DialogContent>

			<DialogActions>
				<Button onClick={handleClose}>Close</Button>
			</DialogActions>
		</Dialog>
	);
}
