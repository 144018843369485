import { useCallback, useContext, useEffect, useState } from 'react';
import Loading from '../../screen-components/ProjectUtilityFormV2/components/Loading/Loading';
import { ReportData } from '../../../types/reports';
import ListItem from '../ListItem';
import SectionHeader from '../../../screens/ProjectsList/components/ClientProjects/components/SectionHeader';
import { Center, ContentList, Scroller } from '../TeamTables';
import { UserHomeContext } from '../../../context/UserHomeProvider';
import { useNavigate } from 'react-router-dom';
import { IconButton } from '@mui/material';
import { Add } from '@mui/icons-material';
import TeamSelectionDialog from './TeamSelectionDialog';
import EmptyStateMessage from '../EmptyStateMessage';
import { ContainerBorder } from '../../../screens/ProjectsList/components/ClientProjects/styles';

const UserReports = () => {
	const navigate = useNavigate();
	const { loadingReports, refreshReports, reports, teams, selectedTeam } =
		useContext(UserHomeContext);
	const [filteredReports, setFilteredReports] = useState<ReportData[] | undefined>(
		reports
	);
	const [teamSelectionDialogOpen, setTeamSelectionDialogOpen] = useState(false);
	const [isOpen, setIsOpen] = useState<boolean>(true);

	const filterReports = useCallback(
		(query: string) => {
			if (!reports) return;

			let localReports = reports;

			if (selectedTeam) {
				localReports = reports.filter(report => report.teamId === selectedTeam.id);
			}

			if (!query) {
				setFilteredReports(localReports);
				return;
			}

			setFilteredReports(
				localReports.filter(report => {
					const date = report.createdAt?.toDate().toLocaleString() ?? '';
					return (report.name || date).toLowerCase().includes(query.toLowerCase());
				})
			);
		},
		[reports, selectedTeam]
	);

	const selectReport = (report: ReportData) => {
		const organizationId = teams?.find(team => team.id === report.teamId)?.organizationId;
		navigate(`/analytics/${organizationId}/${report.teamId}?reportId=${report.id}`);
	};

	const onRefreshHandler = (value: string) => {
		refreshReports();
		filterReports(value);
	};

	useEffect(() => {
		filterReports('');
	}, [filterReports, selectedTeam]);

	return (
		<ContainerBorder style={{ flexGrow: isOpen ? 1 : 'initial' }}>
			<ContentList>
				<SectionHeader
					headerStyle={{ padding: '0 20px' }}
					showDropdown={true}
					dropdownValue={setIsOpen}
					onSearch={filterReports}
					onRefresh={onRefreshHandler}
					headerComponents={
						<IconButton
							size="small"
							onClick={() => setTeamSelectionDialogOpen(!teamSelectionDialogOpen)}>
							<Add color="primary" />
						</IconButton>
					}>
					Analytics
				</SectionHeader>
				<Scroller>
					{loadingReports && (
						<Center>
							<Loading />
						</Center>
					)}
					{isOpen && (
						<>
							{!loadingReports && filteredReports?.length ? (
								filteredReports.map(report => (
									<ListItem
										tooltip={`Created by ${report.createdBy?.fullName}`}
										showIcon={false}
										key={report.id}
										onClick={() => selectReport(report)}
										title={
											report.name
												? `${report.name} (${report.createdAt
														?.toDate()
														.toLocaleString()})`
												: report.createdAt?.toDate().toLocaleString() || ''
										}
										detail={teams?.find(team => team.id === report.teamId)?.name || ''}
										id={report.id!}
									/>
								))
							) : (
								<EmptyStateMessage text={'No displayable analytics'} />
							)}
						</>
					)}
				</Scroller>
			</ContentList>
			<TeamSelectionDialog
				open={teamSelectionDialogOpen}
				setOpen={setTeamSelectionDialogOpen}
			/>
		</ContainerBorder>
	);
};

export default UserReports;
