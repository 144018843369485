import { FieldLabel } from '../../../../../screens/ProjectInfo';
import { ProjectData } from '../../../../../types';
import { dateToDateString } from '../../../../../utils';
import styled from 'styled-components';
import { useContext } from 'react';
import { AuthContext } from '../../../../../context/AuthProvider';
import { ProjectInfoTextField } from '../../styled-components';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { ProjectContext } from '../../../../../context/ProjectProvider';

dayjs.extend(utc);
dayjs.extend(timezone);

interface TimestampFieldProps {
	field: 'modelingStartTimestamp' | 'modelingDueTimestamp' | 'deliveryTimestamp';
	isActive: boolean;
	setPartialFormState: React.Dispatch<React.SetStateAction<Partial<ProjectData>>>;
}

export default function TimestampField({
	field,
	isActive,
	setPartialFormState,
}: TimestampFieldProps) {
	const { user } = useContext(AuthContext);
	const { project } = useContext(ProjectContext);

	const date = project?.[field];

	const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const dateStr = e.target.value;
		const dateObj = dayjs(dateStr).toDate();
		let updatedDate: Date | undefined = undefined;

		if (dateStr && dateObj.toString() !== 'Invalid Date') {
			updatedDate = dayjs(dateObj).hour(12).minute(0).second(0).utc().toDate();
		}

		setPartialFormState(prev => {
			return {
				...prev,
				[field]: updatedDate,
			};
		});
	};

	return (
		<div className="infoRow">
			<FieldLabel label={timestampDisplayNames[field]} />

			{isActive && user?.isAdmin ? (
				<DateTextField
					type="date"
					defaultValue={date ? dateToDateString(date) : ''}
					onChange={handleChange}
					fullWidth
				/>
			) : (
				<div className="fieldInfo">{date ? date.toLocaleDateString() : ''}</div>
			)}
		</div>
	);
}

const timestampDisplayNames = {
	modelingStartTimestamp: 'Modeling Start Date',
	modelingDueTimestamp: 'Modeling Due Date',
	deliveryTimestamp: 'Delivery Date',
};

const DateTextField = styled(ProjectInfoTextField)`
	input {
		cursor: text;

		&::-webkit-calendar-picker-indicator {
			cursor: pointer;
			filter: invert(73%) sepia(94%) saturate(1834%) hue-rotate(349deg) brightness(104%)
				contrast(101%);

			&:hover {
				filter: invert(1);
			}
		}
	}
`;
