import { Dialog, DialogContent } from '@mui/material';
import styled from 'styled-components';
import theme from '../../../styles/theme';

export const StyledDialog = styled(Dialog)<{ $fullheight: boolean; $wide: boolean }>`
	${({ $wide }) =>
		$wide &&
		`
			& .MuiDialog-paper {
				max-width: 90vw;
				width: 675px;
			}
		`}

	${({ $fullheight }) =>
		$fullheight &&
		`
			& .MuiDialog-paper {
				max-width: 90vw;
				height: 800px;
			}
		`}
`;

export const StyledDialogTitleContainer = styled('div')`
	min-width: 500px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	padding: 5px 20px;
	background-color: ${theme.palette.secondary.light};
	color: ${theme.palette.primary.main};
`;

export const StyledBottomActionsContainer = styled('div')`
	display: flex;
	align-items: center;
	flex-direction: row;
	justify-content: flex-end;
	width: 100%;
	padding: 5px 20px;
	background-color: ${theme.palette.secondary.light};
	color: ${theme.palette.primary.main};
`;

export const StyledDialogLogoContainer = styled('div')`
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 10px;
`;

export const StyledDialogLogo = styled('div')`
	height: 24px;
`;

export const StyledDialogTitle = styled('div')`
	font-weight: bold;
	padding-top: 3px;
`;

export const StyledContent = styled(DialogContent)`
	background-color: ${theme.palette.secondary.main};
	color: ${theme.palette.newText.primary};
	align-items: center;
	width: 100%;
	color: white;
`;

export const GalleryImage = styled.img`
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	max-width: 99%;
	max-height: 99%;
	object-fit: contain;
`;
