import { useContext, useState } from 'react';
import Layout from '../../../components/reusable-components/Layout';
import LeadsTable from './LeadsTable';
import { AuthContext } from '../../../context/AuthProvider';
import LeadsImportButton from './LeadsImportButton';
import { LeadsContext } from './LeadsProvider';
import styled from 'styled-components';
// import { checkPermissions } from '../../../utils';
// import CreateProjectButton from '../../../components/reusable-components/CreateProjectButton';
import SkeletonTable from '../../../components/reusable-components/SkeletonTable';
import { useSearchParams } from 'react-router-dom';
import LeadsActions from './LeadsActions';
import { UserObj } from '../../../types';
import { Row } from 'react-table';
import { Lead, LeadStatus } from '../types';

export default function LeadsScreen() {
	const { user } = useContext(AuthContext);
	const { fetchingLeads } = useContext(LeadsContext);

	const [searchParams] = useSearchParams();

	const [showGraphs, setShowGraphs] = useState(searchParams.get('graphs') === 'true');
	const [query, setQuery] = useState('');
	const [accountManagerFilter, setAccountManagerFilter] = useState<UserObj | undefined>();
	const [leadStatusFilter, setLeadStatusFilter] = useState<LeadStatus | ''>('Follow Up');
	const [peopleFilter, setPeopleFilter] = useState<string[]>([]);
	const [selectedRows, setSelectedRows] = useState<Row<Lead>[]>([]);
	const [deselectAllRows, setDeselectAllRows] = useState(false);

	return (
		<Layout
			title="Sales"
			LeftNavComponent={
				<LeadsActions
					query={query}
					setQuery={setQuery}
					setAccountManagerFilter={setAccountManagerFilter}
					leadStatusFilter={leadStatusFilter}
					setLeadStatusFilter={setLeadStatusFilter}
					setPeopleFilter={setPeopleFilter}
					selectedRows={selectedRows}
					showGraphs={showGraphs}
					setShowGraphs={setShowGraphs}
					setDeselectAllRows={setDeselectAllRows}
				/>
			}
			RightNavComponent={user?.isAdmin ? <LeadsImportButton /> : null}>
			<Container>
				{!fetchingLeads ? (
					<LeadsTable
						query={query}
						showGraphs={showGraphs}
						accountManagerFilter={accountManagerFilter}
						leadStatusFilter={leadStatusFilter}
						peopleFilter={peopleFilter}
						setSelectedRows={setSelectedRows}
						deselectAllRows={deselectAllRows}
						setDeselectAllRows={setDeselectAllRows}
					/>
				) : (
					<SkeletonTable rows={10} />
				)}
			</Container>
		</Layout>
	);
}

const Container = styled.div`
	display: flex;
	flex-direction: column;
	gap: 10px;
	align-items: center;

	width: 100%;
	height: 100%;
	padding: 10px;
`;
