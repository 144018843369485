import { AuthContext } from '../../../context/AuthProvider';
import { IconButton } from '@mui/material';
import { FC, useContext } from 'react';
import {
	FormQuestion,
	OptionText,
	OptionValue,
	OptionsRow,
	QuestionOption,
	QuestionParent,
	QuestionText,
	VerticalDivider,
} from './StyledComponents';
import { ReportQuestion, ReportQuestionOption } from '../../../types/reports';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

type ReportListItemProps = {
	onClickAvailable: () => void;
	onClickMissing: () => void;
	onClickNoValue: () => void;
	onClickOption: (option: ReportQuestionOption) => void;
	question: ReportQuestion;
	totalForms: number;
	hiddenQuestions: string[];
	toggleVisibility: (question: string) => void;
};

const QuestionsItem: FC<ReportListItemProps> = ({
	onClickAvailable,
	onClickMissing,
	onClickNoValue,
	onClickOption,
	question,
	totalForms,
	hiddenQuestions,
	toggleVisibility,
}) => {
	// Check whether question is hidden (included in hiddenQuestions array)
	const isHidden = hiddenQuestions.includes(question.question);
	const { user } = useContext(AuthContext);

	// If the user is not an admin and the question is hidden, do not render the component
	if (!user?.isAdmin && isHidden) {
		return null;
	}

	return (
		// Render the question with half opacity if its hidden (for Admin)
		<FormQuestion
			key={Math.random()}
			style={{ opacity: isHidden ? '0.5' : '1', display: 'flex' }}>
			{/* Render visibility toggle if user is an admin */}
			<div>
				{user?.isAdmin && (
					<IconButton
						onClick={() => toggleVisibility(question.question)}
						style={{ marginRight: '8px' }}>
						{isHidden ? (
							<VisibilityOffIcon style={{ color: 'white' }} />
						) : (
							<VisibilityIcon style={{ color: 'white' }} />
						)}
					</IconButton>
				)}
			</div>
			<div style={{ flex: 1 }}>
				<QuestionParent>{question.parent}</QuestionParent>
				<QuestionText onClick={onClickAvailable}>
					{question.question.split(' > ').pop()}
				</QuestionText>
				<OptionsRow>
					{question.options.map(option => (
						<QuestionOption key={option.value}>
							<OptionText
								clickable={option.count > 0}
								onClick={() => onClickOption(option)}>
								{option.value}
							</OptionText>
							<OptionValue>
								{option.count}/{totalForms}
							</OptionValue>
						</QuestionOption>
					))}
					<VerticalDivider />
					<QuestionOption key="not-filled">
						<OptionText
							clickable={question.noValue > 0}
							onClick={() => {
								if (question.noValue > 0) onClickNoValue();
							}}>
							No value
						</OptionText>
						<OptionValue>
							{question.noValue}/{totalForms}
						</OptionValue>
					</QuestionOption>
					<QuestionOption key="total">
						<OptionText
							clickable={question.missing > 0}
							onClick={() => {
								if (question.missing > 0) onClickMissing();
							}}>
							Missing question
						</OptionText>
						<OptionValue>
							{question.missing}/{totalForms}
						</OptionValue>
					</QuestionOption>
				</OptionsRow>
			</div>
		</FormQuestion>
	);
};

export default QuestionsItem;
