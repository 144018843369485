import { debounce } from 'lodash';
import React, { useState, useMemo, useContext } from 'react';
import { TextField } from '@mui/material';
import { Row } from 'react-table';
import { SnackContext } from '../../../../../../context/SnackProvider';
import { updateLead } from '../../../../../../firebase';
import { EditableSpan } from '../../../../../../components/styled-components/styledReactTable';
import { Lead } from '../../../../types';
import { LeadsContext } from '../../../LeadsProvider';

export default function LeadTextCell({
	row,
	field,
	children,
}: {
	row: Row<Lead>;
	field:
		| 'company'
		| 'firstName'
		| 'lastName'
		| 'contactTitle'
		| 'mobileNumber'
		| 'hqNumber'
		| 'email'
		| 'state';
	children?: React.ReactNode;
}) {
	const { setSnackbarProps } = useContext(SnackContext);
	const { setLeads } = useContext(LeadsContext);

	const leadId = row.original.id as string;
	const defaultValue = row.original[field];

	const [focused, setFocused] = useState(false);
	const [text, setText] = useState(defaultValue);

	const updateField = useMemo(
		() =>
			debounce(async (newValue: string) => {
				if (newValue !== defaultValue) {
					setSnackbarProps({
						open: true,
						message: 'Submitting changes...',
						severity: 'warning',
						hideDuration: null,
					});

					setLeads(prev => {
						return prev.map(lead => {
							if (lead.id !== leadId) return lead;
							else
								return {
									...lead,
									[field]: newValue,
									lastActivity: new Date(),
								};
						});
					});
					await updateLead({
						id: leadId,
						updatedFields: {
							[field]: newValue,
						},
					});

					setSnackbarProps({
						open: true,
						message: 'Changes submitted!',
						severity: 'success',
					});
				}
			}, 3000),

		[defaultValue, field, leadId, setLeads, setSnackbarProps]
	);

	return focused ? (
		<TextField
			value={text}
			variant="standard"
			autoFocus
			fullWidth
			onBlur={() => {
				setFocused(false);
			}}
			onChange={e => {
				setText(e.target.value);
				updateField(e.target.value);
			}}
		/>
	) : (
		<>
			<EditableSpan
				onClick={() => {
					setFocused(true);
				}}
				onKeyUp={e => {
					if (e.key === 'Enter') setFocused(true);
				}}
				tabIndex={0}>
				{text || '-'}
			</EditableSpan>

			{children}
		</>
	);
}
