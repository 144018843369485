import { Action, ZoomAction } from './actions';

export interface PanZoomState {
	translateX: number;
	translateY: number;
	prevMouseX: number;
	prevMouseY: number;
	scale: number;
}

export const initialState: PanZoomState = {
	translateX: 0,
	translateY: 0,
	prevMouseX: 0,
	prevMouseY: 0,
	scale: 1,
};

export const reducer = (state: PanZoomState, action: Action | ZoomAction) => {
	switch (action.type) {
		case 'PAN_START':
			return {
				...state,
				prevMouseX: action.clientX,
				prevMouseY: action.clientY,
			};
		case 'PAN':
			const deltaMouseX = action.clientX - state.prevMouseX;
			const deltaMouseY = action.clientY - state.prevMouseY;
			return {
				...state,
				translateX: state.translateX + deltaMouseX,
				translateY: state.translateY + deltaMouseY,
				prevMouseX: action.clientX,
				prevMouseY: action.clientY,
			};
		case 'ZOOM':
			const zoomAction = action as ZoomAction;
			const scaledTranslate = getScaledTranslate(state, zoomAction.zoomFactor);
			const mousePositionOnScreen = { x: zoomAction.clientX, y: zoomAction.clientY };
			const zoomOffset = getZoomOffset(
				zoomAction.containerRect,
				mousePositionOnScreen,
				zoomAction.zoomFactor
			);
			return {
				...state,
				scale: state.scale * (zoomAction.zoomFactor || 0),
				translateX: scaledTranslate.x + zoomOffset.x,
				translateY: scaledTranslate.y + zoomOffset.y,
			};
		case 'RESET':
			return initialState;
		default:
			return state;
	}
};

const getZoomOffset = (
	containerRect: DOMRect,
	mousePositionOnScreen: {
		x: number;
		y: number;
	},
	zoomFactor: number
) => {
	const zoomOrigin = {
		x: mousePositionOnScreen.x - containerRect.left,
		y: mousePositionOnScreen.y - containerRect.top,
	};
	const currentDistanceToCenter = {
		x: containerRect.width / 2 - zoomOrigin.x,
		y: containerRect.height / 2 - zoomOrigin.y,
	};
	const scaledDistanceToCenter = {
		x: currentDistanceToCenter.x * zoomFactor,
		y: currentDistanceToCenter.y * zoomFactor,
	};
	const zoomOffset = {
		x: currentDistanceToCenter.x - scaledDistanceToCenter.x,
		y: currentDistanceToCenter.y - scaledDistanceToCenter.y,
	};
	return zoomOffset;
};

const getScaledTranslate = (state: PanZoomState, zoomFactor: number) => ({
	x: state.translateX * zoomFactor,
	y: state.translateY * zoomFactor,
});
