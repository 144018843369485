import {
	// Children, isValidElement, cloneElement,
	useContext,
} from 'react';
import {
	// useParams,
	useLocation,
	Navigate,
	Outlet,
} from 'react-router-dom';
import { AuthContext } from '../context/AuthProvider';
import { RouteProps } from '../types';

export default function AuthRoute({ children, redirectPath = '/projects' }: RouteProps) {
	const { user } = useContext(AuthContext);
	const { state } = useLocation();
	// const params = useParams();
	// const childrenWithProps = Children.map(children, child => {
	// 	// checking isValidElement is the safe way and avoids a typescript error too
	// 	if (isValidElement(child) && params.shareCode) {
	// 		return cloneElement(child, { shareCode: params.shareCode });
	// 	}
	// 	return child;
	// });

	if (user) {
		const searchParams = state?.searchParams || '';
		return <Navigate to={(state?.redirectPath || redirectPath) + searchParams} replace />;
	} else {
		if (children) return <>{children}</>;
		else return <Outlet />;
	}
}
