import { writeFileXLSX, utils } from 'xlsx';
import { FormNodeWithChildren, PhotoV2 } from '../utils/types';
import { Timestamp } from 'firebase/firestore';

const useXLSX = () => {
	const downloadXLSX = (
		storeNumber: string | undefined,
		formName: string,
		address: string | undefined,
		nodes: FormNodeWithChildren[]
	) => {
		const workbook = utils.book_new();
		const rows = flattenNodes(nodes);
		const worksheet = utils.json_to_sheet(rows);

		let letterFilename = '';
		if (storeNumber) letterFilename += `${storeNumber} - `;
		if (formName) {
			letterFilename += `${formName}`;
		} else {
			letterFilename += 'Inspection Report';
		}
		if (address) letterFilename += ` - ${address}`;
		letterFilename += '.xlsx';

		worksheet['!cols'] = [{ wch: 30 }, { wch: 30 }];
		worksheet['!rows'] = [{ hpx: 30 }];

		utils.book_append_sheet(workbook, worksheet, 'Form');
		writeFileXLSX(workbook, `${letterFilename}`);
	};

	const flattenNodes = (nodes: FormNodeWithChildren[]) => {
		const flattenedNodes: { Name: string; Value: any }[] = [];

		const buildNodes = (nodes: FormNodeWithChildren[]) => {
			nodes.forEach((node, index) => {
				if (index > 0 && node.parentId === '')
					flattenedNodes.push({ Name: '', Value: '' });

				const row: any = {
					Name: {
						v: `${'--|'.repeat(node.level || 0)}${node.level ? ' ' : ''}${
							node.type === 'node' ? '[' : ''
						}${node.displayTitle}${node.type === 'node' ? ']' : ''}`,
						t: 's',
					},
				};

				if (node.type === 'photos' && node.value) {
					const photos = node.value as PhotoV2[];
					row.Value = `${photos.length} photos`;
					photos.forEach(
						(photo, index) =>
							(row[`Photo ${index + 1}`] = {
								l: { Target: photo.url },
								v: photo.originalName,
							})
					);
				} else if (node.type === 'node') {
					row.Value = '--------------------';
				} else if (node.type === 'switch') {
					row.Value = node.value ? 'Yes' : 'No';
				} else if (node.type === 'date') {
					row.Value = node.value
						? (node.value as Timestamp).toDate().toLocaleDateString()
						: '';
				} else if (node.type === 'datetime') {
					row.Value = node.value
						? (node.value as Timestamp).toDate().toLocaleString()
						: '';
				} else {
					row.Value = node.value;
				}

				flattenedNodes.push(row);

				if (node.children) buildNodes(node.children);
			});
		};

		buildNodes(nodes);

		return flattenedNodes;
	};

	return downloadXLSX;
};

export default useXLSX;
