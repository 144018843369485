import { useEffect } from 'react';
import { MapProvider } from '../../context/MapProvider';
import MapScreen from './MapScreen';
import { useLocation } from 'react-router-dom';

export default function Map() {
	const location = useLocation();
	const queryParams = new URLSearchParams(location.search);
	const lat = queryParams.get('lat') ? parseFloat(queryParams.get('lat')!) : undefined;
	const lng = queryParams.get('lng') ? parseFloat(queryParams.get('lng')!) : undefined;

	useEffect(() => {
		document.title = 'Map Screen | Robotic Imaging';
	}, []);

	return (
		<MapProvider>
			<MapScreen lat={lat} lng={lng} />
		</MapProvider>
	);
}
