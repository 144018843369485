import styled from 'styled-components';
import CompletionIconButton from '../CompletionIconButton';
import { ReactComponent as GoogleMapsIcon } from './components/GoogleMapsIcon.svg';

export default function GoogleMapsButton({
	concatAddress,
}: {
	concatAddress: string | undefined;
}) {
	return (
		<a
			href={`https://google.com/maps/search/${concatAddress}`}
			target="_blank"
			rel="noopener noreferrer"
			title="Open Google Maps view in new tab">
			<CompletionIconButton isComplete={false} icon={<GoogMapsIcon />} />
		</a>
	);
}

const GoogMapsIcon = styled(GoogleMapsIcon)`
	width: 25px;
	height: 25px;
`;
