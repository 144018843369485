import { TextField } from '@mui/material';
import theme from '../../../styles/theme';
import styled from 'styled-components';

export const StyledTextField = styled(TextField)`
	transition: 0.3s;
	width: 100%;

	${theme.breakpoints.up('lg')} {
		min-width: 400px;
		width: auto;
	}

	input {
		color: ${theme.palette.newText.primary};

		&::placeholder {
			line-height: 1.6;
		}
	}

	fieldset {
		border-color: ${theme.palette.grey[800]};
	}

	label {
		color: ${theme.palette.grey[300]};
		line-height: 1.6;
	}

	&:hover {
		> :not(.Mui-focused) {
			fieldset {
				border-color: ${theme.palette.common.white};
			}
		}
	}

	.MuiFormHelperText-root:not(.Mui-error) {
		color: ${theme.palette.grey[300]};
	}
`;

export const inputStyles = {
	'&.name': {
		'.MuiInputBase-input': {
			fontSize: '1rem',
		},
		'&:not(.active)': {
			'.MuiInputBase-input': {
				color: theme.palette.primary.main,
			},
		},
	},
	'.MuiInputBase-root': {
		transition: '0.3s',
	},
	'.MuiInputBase-input': {
		color: theme.palette.newText.primary,
		fontSize: '14px',
		transition: '0.3s',
		lineHeight: '1rem',
	},
	fieldset: {
		transition: '0.3s',
	},
	label: {
		transition: 'left 0.3s, opacity 0.3s',
	},
	'&': {
		'.MuiInputBase-root': {
			transition: '0.3s',
		},
		'.MuiInput-underline': {
			marginTop: '12px',
		},
	},
	'&:not(.active)': {
		fieldset: {
			border: 'none',
		},
		label: {
			opacity: '0',
		},
		'.MuiInputBase-root, .MuiInputBase-input': {
			paddingLeft: '0',
			paddingRight: '0',
			cursor: 'pointer',
		},
	},

	'.MuiFormHelperText-root': {
		position: 'absolute',
		bottom: '-3px',
	},
};
