import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import styled from 'styled-components';

export default function ItemSelectField({
	field,
	setState,
	label,
	type,
	value,
	options,
}: {
	field: string;
	setState: React.Dispatch<React.SetStateAction<any>>;
	label: string;
	type?: string;
	value: string;
	options: string[];
}) {
	return (
		<Container>
			<FormControl sx={{ width: '100%' }}>
				<InputLabel id={label}>{label}</InputLabel>

				<Select
					labelId={label}
					id={label}
					value={value}
					label={label}
					onChange={e => {
						setState((prev: any) => ({
							...prev,
							metadata: {
								...prev.metadata,
								[field]: e.target.value,
							},
						}));
					}}>
					{options.map((option, idx) => (
						<MenuItem key={idx} value={option}>
							{option}
						</MenuItem>
					))}
				</Select>
			</FormControl>
		</Container>
	);
}

const Container = styled.div`
	min-width: 219px;
	@media screen and (max-width: 768px) {
		width: 100%;
	}
`;
