import { Box, Button, Chip, Dialog, DialogActions } from '@mui/material';
import styled from 'styled-components';

export const StyledDialog = styled(Dialog)`
	& .MuiDialog-paper {
		width: 80%;
		max-width: 600px;
	}
`;

export const StyledDialogActions = styled(DialogActions)`
	& .MuiButton-text {
		text-transform: none;
	}
`;

export const TemplateChip = styled(Chip)`
	margin: 4px;
`;

export const ChipContent = styled(Box)`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	padding: 4px;
`;

export const TemplateTitle = styled(Box)`
	font-size: 12px;
`;

export const TemplateType = styled(Box)`
	font-size: 10px;
	color: grey;
`;

export const OptionHeader = styled.h3`
	text-align: center;
`;

export const PasteButton = styled(Button)`
	text-transform: none;
	align-self: center;
`;

export const PastedNodeBox = styled.div`
	padding: 10px;
	border: 1px solid grey;
	margin-bottom: 20px;
	border-radius: 10px;
`;

export const PasteContainer = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	margin-bottom: 20px;
	overflow-wrap: auto;
`;

export const TypeText = styled.span`
	color: grey;
	margin-left: 5px;
`;

export const NodeText = styled.span`
	color: black;
	font-weight: 600;
	letter-spacing: 0.5px;
`;

export const ChildText = styled.span`
	color: black;
	font-weight: 400;
	letter-spacing: 0.5px;
	word-break: break-all;
`;
