import SitePhotoBucket from './SitePhotoBucket/SitePhotoBucket';
import SiteMapBucket from './SiteMapBucket/SiteMapBucket';
import SiteAerialBucket from './SiteAerialBucket/SiteAerialBucket';
import ClientLogoBucket from './ClientLogoBucket/ClientLogoBucket';
import {
	BucketsWrapper,
	// Caret,
	// DropdownHeader,
	// DropdownText,
	// ToggleBar,
} from './ContentBuckets.style';
// import { useState } from 'react';

const ContentBuckets = () => {
	// const [isOpen, setIsOpen] = useState(true);

	// const toggleDropdown = () => setIsOpen(!isOpen);

	return (
		<>
			{/* <ToggleBar onClick={toggleDropdown}>
				<DropdownHeader>
					<DropdownText>Add Coverpage Content</DropdownText>
					<Caret isOpen={isOpen} />
				</DropdownHeader>
				{isOpen && ( */}
			<BucketsWrapper>
				<SitePhotoBucket />
				<SiteMapBucket />
				<SiteAerialBucket />
				<ClientLogoBucket />
			</BucketsWrapper>
			{/* // )} */}
			{/* </ToggleBar> */}
		</>
	);
};

export default ContentBuckets;
