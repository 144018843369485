import React, { useContext, useEffect, useMemo, useState } from 'react';
import { FieldLabel } from '../../../../../screens/ProjectInfo';
import { ProjectData } from '../../../../../types';
import MultiAutoCompleteInput from '../../../../reusable-components/MultiAutoCompleteInput';
import { AuthContext } from '../../../../../context/AuthProvider';
import { ProjectContext } from '../../../../../context/ProjectProvider';
import { cpuOptions } from '../../../../../utils';
import { isEqual } from 'lodash';

interface MultiStringFieldProps {
	field: 'accountsPayable' | 'modelers' | 'designSoftware' | 'cpu' | 'invoiceNums';
	isActive: boolean;
	setPartialFormState: React.Dispatch<React.SetStateAction<Partial<ProjectData>>>;
	adminOnly?: boolean;
	freeSolo?: boolean;
}

export default function MultiStringField({
	field,
	isActive,
	setPartialFormState,
	adminOnly = false,
	freeSolo = true,
}: MultiStringFieldProps) {
	const { user } = useContext(AuthContext);
	const { project, cachedModelers, cachedDesignSoftware, loadingAutofillData } =
		useContext(ProjectContext);

	const [editing, setEditing] = useState(false);

	const projStrArr = useMemo(() => project?.[field] || [], [field, project]);
	const [strArr, setStrArr] = useState(projStrArr);

	useEffect(() => {
		if (!isEqual(projStrArr, strArr)) {
			setPartialFormState(prev => ({
				...prev,
				[field]: strArr,
			}));
		}
	}, [field, projStrArr, setPartialFormState, strArr]);

	useEffect(() => {
		if (isActive) setStrArr(projStrArr);
	}, [isActive, projStrArr]);

	const canDisplay = adminOnly ? !!user?.isAdmin : true;

	const handleChange = (
		e: React.SyntheticEvent<Element, Event>,
		newValue: readonly string[]
	) => {
		setStrArr(Array.from(newValue));
	};

	const handleBlur = (inputValue: string) => {
		if (freeSolo) {
			setStrArr(prev => [...prev, inputValue]);
		}
	};

	const getOptions = () => {
		switch (field) {
			case 'modelers':
				return cachedModelers;
			case 'designSoftware':
				return cachedDesignSoftware;
			case 'cpu':
				return cpuOptions;
			default:
				return [];
		}
	};

	return canDisplay ? (
		<div className="infoRow">
			<FieldLabel label={fieldLabelMap[field]} />

			{editing && isActive ? (
				<MultiAutoCompleteInput
					name={field}
					value={strArr}
					onChange={handleChange}
					onBlur={handleBlur}
					options={getOptions() || []}
					setShow={setEditing}
					loading={getOptions() === null && loadingAutofillData}
					loadingText="Loading options..."
					freeSolo={freeSolo}
				/>
			) : (
				<div
					className={`fieldInfo ${user?.isAdmin && isActive && 'fieldInfo-edit'}`}
					title={user?.isAdmin ? `Click to edit ${fieldLabelMap[field]}` : ''}
					onClick={() => (user?.isAdmin && isActive ? setEditing(true) : null)}
					tabIndex={user?.isAdmin && isActive ? 0 : -1}>
					{isActive ? strArr?.join(', ') || 'N/A' : projStrArr.join(', ') || ''}
				</div>
			)}
		</div>
	) : null;
}

const fieldLabelMap = {
	accountsPayable: 'AP',
	modelers: 'BIM Team',
	designSoftware: 'Design Software',
	cpu: 'CPU',
	invoiceNums: 'Invoice No.(s)',
};
