import { Autocomplete } from '@mui/material';
import { AutocompleteRenderOptionState } from '@mui/material/Autocomplete';
import React, { useState } from 'react';
import { ProjectInfoTextField } from '../screen-components/ProjectInfo/styled-components';

/**
 * Multiple input component with autocomplete suggestions using MUI's Autocomplete.
 */
export default function MultiAutoCompleteInput({
	// Required params
	value,
	onChange,
	options,
	onBlur,

	// Optional params
	setShow,
	name,
	label,
	inputClassName,
	autoFocus = true,
	variant,
	renderOption,
	freeSolo = true,
	fullWidth = true,
	required = false,

	disabled = false,
	disableCloseOnSelect = true,
	loading = undefined,
	loadingText = undefined,
}: {
	// Required params
	value: string[];
	onChange: (
		e: React.SyntheticEvent<Element, Event>,
		newValue: readonly string[]
	) => void;
	options: string[];
	onBlur: (inputValue: string) => void;

	// Optional params
	setShow?: (value: React.SetStateAction<boolean>) => void;
	name?: string;
	label?: string;
	inputClassName?: string;
	autoFocus?: boolean;
	variant?: 'standard' | 'filled' | 'outlined';
	renderOption?: (
		props: React.HTMLAttributes<HTMLLIElement>,
		option: string,
		state: AutocompleteRenderOptionState
	) => React.ReactNode;
	freeSolo?: boolean;
	fullWidth?: boolean;
	required?: boolean;
	disabled?: boolean;
	disableCloseOnSelect?: boolean;

	loading?: boolean;
	loadingText?: string;
}) {
	const [inputValue, setInputValue] = useState('');

	return (
		<Autocomplete
			value={value}
			onChange={onChange}
			options={options}
			onBlur={() => {
				// Add inputValue to form on blur if not already in form
				const trimmedInput = inputValue.trim();
				if (trimmedInput && !value.includes(trimmedInput)) {
					onBlur(trimmedInput);
					setInputValue('');
				}
				// Hide component on blur
				if (setShow !== undefined) setShow(false);
			}}
			renderInput={params => (
				<ProjectInfoTextField
					{...params}
					name={name}
					label={label}
					className={inputClassName}
					autoFocus={autoFocus}
					variant={variant}
					required={required}
					InputProps={{
						...params.InputProps,
						required: required ? value.length === 0 : undefined,
					}}
				/>
			)}
			renderOption={renderOption}
			freeSolo={freeSolo}
			fullWidth={fullWidth}
			disabled={disabled}
			disableCloseOnSelect={disableCloseOnSelect}
			multiple
			inputValue={inputValue}
			onInputChange={(e, value) => setInputValue(value)}
			// componentsProps={{ clearIndicator: { style: { color: '#ffb310' } } }}
			style={{
				marginTop:
					label && (variant === undefined || variant === 'outlined') ? '9px' : undefined,
			}}
			loading={loading}
			loadingText={loadingText}
		/>
	);
}
