import { Row } from 'react-table';
import { SnackContext } from '../../../../../../../context/SnackProvider';
import { StatusTableContext } from '../../../../StatusTableProvider';
import { OrganizationsContext } from '../../../../../../../context/OrganizationsProvider';
import { useCallback, useContext, useEffect, useState } from 'react';
import { updateSingleProjectField } from '../../../../../../../firebase/firestore/projects';
import { Organization, ProjectData } from '../../../../../../../types';
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	IconButton,
} from '@mui/material';
import OrgTeamSelector from '../../../../../OrgTeamSelector';
import useOrgTeams from '../../../../../../screen-components/ProjectInfo/components/field-components/SharedTeams/hooks/useOrgTeams';
import { AuthContext } from '../../../../../../../context/AuthProvider';
import { SortBy } from '../..';
import styled from 'styled-components';
import theme from '../../../../../../../styles/theme';
import { Remove } from '@mui/icons-material';

export default function OrganizationsCell({
	row,
	rowEditing,
	sortBy,
}: {
	row: Row<ProjectData>;
	rowEditing: boolean;
	sortBy: SortBy | null;
}) {
	const { organizations } = useContext(OrganizationsContext);
	const { setSnackbarProps } = useContext(SnackContext);
	const { setStatusProjects } = useContext(StatusTableContext);
	const { user } = useContext(AuthContext);

	const [isDialogOpen, setIsDialogOpen] = useState(false);
	const [selectedOrg, setSelectedOrg] = useState<Organization | null>(null);

	const projectId = row.original.id as string;
	const [selectedTeams, addTeam, removeTeam] = useOrgTeams(
		user?.isAdmin ? row.original.sharedTeams || [] : []
	);
	const [projectOrgs, setProjectOrgs] = useState<string>(
		row.original?.orgs?.length ? row.original.orgs.map(org => org.name).join(', ') : '–'
	);

	const handleOpen = (e: React.MouseEvent<HTMLSpanElement> | undefined) => {
		e?.stopPropagation();
		setIsDialogOpen(true);
	};

	const handleDialogClose = () => {
		setIsDialogOpen(false);
	};

	const handleSortBy = useCallback(() => {
		if (sortBy && sortBy.field === 'orgs') {
			if (sortBy.order === 'asc') {
				const list = row.original?.orgs
					?.sort((a, b) => {
						if (a.name < b.name) {
							return -1;
						}
						if (a.name > b.name) {
							return 1;
						}
						return 0;
					})
					.map(org => org.name)
					.join(', ');
				setProjectOrgs(list || '-');
			} else {
				const list = row.original?.orgs
					?.sort((a, b) => {
						if (b.name < a.name) {
							return -1;
						}
						if (b.name > a.name) {
							return 1;
						}
						return 0;
					})
					.map(org => org.name)
					.join(', ');
				setProjectOrgs(list || '-');
			}
		}
	}, [row.original?.orgs, sortBy]);

	useEffect(() => {
		handleSortBy();
	}, [handleSortBy, row, sortBy]);

	const handleSave = async () => {
		const teamIds = selectedTeams.map(team => team.id);

		setSnackbarProps({
			open: true,
			severity: 'warning',
			message: 'Saving changes...',
		});

		await updateSingleProjectField(projectId, teamIds, 'sharedTeams');

		const uniqueOrgs = selectedTeams
			.map(team => {
				const org = organizations.find(org => org.id === team.organizationId);
				return org ? { id: org.id, name: org.name } : { id: '', name: 'Unknown' };
			})
			.filter((org, index, self) => index === self.findIndex(o => o.id === org.id));

		setStatusProjects(prev => {
			if (prev) {
				return prev.map(proj => {
					if (proj.id === projectId) {
						proj.orgs = uniqueOrgs;
						return {
							...proj,
							sharedTeams: teamIds,
						};
					} else return proj;
				});
			} else return prev;
		});

		setSnackbarProps({
			open: true,
			severity: 'success',
			message: 'Changes saved!',
		});

		setProjectOrgs(uniqueOrgs.map(org => org.name).join(', '));
		handleDialogClose();
	};

	return !rowEditing ? (
		<span>{projectOrgs}</span>
	) : (
		<>
			<StyledSpan onClick={handleOpen} title="Click to edit Orgs/Teams">
				{projectOrgs === '–' ? (
					<IconButton color="inherit">
						<Remove />
					</IconButton>
				) : (
					projectOrgs
				)}
			</StyledSpan>

			<Dialog
				open={isDialogOpen}
				onClose={handleDialogClose}
				onClick={event => event.stopPropagation()}
				maxWidth={'sm'}
				fullWidth>
				<DialogTitle>Edit Organization(s) and Team(s)</DialogTitle>
				<DialogContent>
					<OrgTeamSelector
						organizations={organizations}
						selectedOrg={selectedOrg}
						setSelectedOrg={setSelectedOrg}
						selectedTeams={selectedTeams}
						addTeam={addTeam}
						removeTeam={removeTeam}
					/>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleDialogClose} color="primary">
						Cancel
					</Button>
					<Button onClick={handleSave} color="primary">
						Save
					</Button>
				</DialogActions>
			</Dialog>
		</>
	);
}

const StyledSpan = styled.span`
	transition: 0.2s;
	color: #fff;
	cursor: pointer;

	&:hover,
	:focus-visible {
		color: ${theme.palette.primary.main};
		text-decoration: underline;
	}
`;
