import React, { useContext, useEffect, useState } from 'react';
import {
	FormNode,
	NodeType,
} from '../../screen-components/ProjectUtilityFormV2/utils/types';
import { FormV2Context } from '../../screen-components/ProjectUtilityFormV2/context/FormV2Context.types';
import { SnackContext } from '../../../context/SnackProvider';
import { copyAndPasteNode } from '../../../firebase';
import { ElementDialog } from './ElementDialog';
import { TemplateContext } from '../../../context/TemplateProvider';

const AddElementDialog: React.FC<{
	open: boolean;
	node?: FormNode;
	handleClose: () => void;
	excludedTypes: NodeType[];
	defaultType?: NodeType;
}> = ({ open, node, excludedTypes, handleClose, defaultType }) => {
	const { addNewNode, copiedNodeData, formId, nodeStack } = useContext(FormV2Context);
	const { isTemplate, addTemplateNode } = useContext(TemplateContext);
	const [newNodeId, setNewNodeId] = useState<string | null>(null);
	const { setSnackbarProps } = useContext(SnackContext);

	const handleAddItem = async (
		displayTitle: string,
		type: NodeType,
		options?: string[]
	) => {
		const id = isTemplate
			? await addTemplateNode(displayTitle, type, options, node?.id, formId, nodeStack)
			: await addNewNode(displayTitle, type, options, node?.id);
		setNewNodeId(id ?? null);
		handleClose();
	};

	const handlePasteItem = async () => {
		if (!copiedNodeData) {
			console.error('No node to paste!');
			return;
		}
		try {
			const result = await copyAndPasteNode({
				operation: 'paste',
				itemId: copiedNodeData.id,
				targetParentId: node?.id,
				formId: formId,
			});
			setSnackbarProps({
				open: true,
				message: `Success! Item pasted to ${node?.displayTitle || 'root'}`,
				severity: 'success',
			});
			console.log('Node pasted successfully', result);
			handleClose();
		} catch (error) {
			console.error('error pasting node', error);
			setSnackbarProps({
				open: true,
				message: `Error, Item could not be pasted in ${
					node?.displayTitle || 'root'
				}. Please try again.`,
				severity: 'error',
			});
		}
	};

	useEffect(() => {
		if (newNodeId) {
			const element = document.getElementById(newNodeId);
			element?.scrollIntoView();
		}
	}, [newNodeId]);

	return (
		<>
			<ElementDialog
				isOpen={open}
				onClose={handleClose}
				onAddItem={handleAddItem}
				enableTypeSelection
				excludeTypes={excludedTypes}
				showPasteOption={true}
				onPasteItem={() => handlePasteItem()}
				defaultType={defaultType}
			/>
		</>
	);
};

export default AddElementDialog;
