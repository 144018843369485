import styled from 'styled-components';

export const GalleryImage = styled.img<{ degree?: number }>`
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%) rotate(${({ degree }) => degree}deg);
	object-fit: contain;
	width: 100%;
	height: 100%;
`;
