import { useContext, useState } from 'react';
import Button from '@mui/material/Button';
import {
	Dialog,
	DialogActions,
	DialogContentText,
	DialogContent,
	DialogTitle,
	TextField,
} from '@mui/material';

import { AuthContext } from '../../../context/AuthProvider';
import { LoadingContext } from '../../../context/LoadingProvider';
import LoadingScreen from '../../reusable-components/LoadingScreen';

export default function FormDialog({
	showingDialog,
	setShowingDialog,
}: {
	showingDialog: boolean;
	setShowingDialog: React.Dispatch<React.SetStateAction<boolean>>;
}) {
	const { loading } = useContext(LoadingContext);
	const [formEmail, setFormEmail] = useState('');
	const { resetPassword } = useContext(AuthContext);

	const handleClose = () => {
		setFormEmail('');
		setShowingDialog(false);
	};

	const handleClick = () => {
		resetPassword(formEmail);
		setShowingDialog(false);
	};

	const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
		setFormEmail(e.target.value);
	};
	return (
		<Dialog open={showingDialog} onClose={handleClose}>
			<DialogTitle>Password Reset</DialogTitle>
			{!loading ? (
				<>
					<DialogContent>
						<DialogContentText>
							Enter the email for your account. You will receive an email to reset your
							password.
						</DialogContentText>
						<TextField
							variant="standard"
							label="Email"
							autoFocus
							type="email"
							fullWidth
							value={formEmail}
							onChange={handleChange}
							onKeyPress={e => {
								if (e.key === 'Enter') handleClick();
							}}
						/>
					</DialogContent>
					<DialogActions>
						<Button onClick={handleClose} color="primary">
							Cancel
						</Button>
						<Button onClick={handleClick} color="primary">
							Send
						</Button>
					</DialogActions>
				</>
			) : (
				<DialogContent>
					<LoadingScreen message="Sending password reset email..." textColor="black" />
				</DialogContent>
			)}
		</Dialog>
	);
}
