import { useCallback, useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ProjectContext } from '../../../../../../../../context/ProjectProvider';
import UploadCoverpageContent from '../../UploadCoverpageContent/UploadCoverpageContent';
import { useDropzone } from 'react-dropzone';
import {
	Bucket,
	BucketText,
	ImageOverlay,
	UploadedContainer,
	UploadedImage,
} from '../ContentBuckets.style';
import { FormV2Context } from '../../../../../context/FormV2Context.types';
import UploadConfirmationDialog from '../../../../Dialogs/UploadConfirmationDialog/UploadConfirmationDialog';
import { deleteObject, ref } from 'firebase/storage';
import { db, storage } from '../../../../../../../../firebase';
import { deleteField, doc, updateDoc } from 'firebase/firestore';
import ImageOptionsDialog from '../../../../Dialogs/ImageOptionsDialog/ImageOptionsDialog';

const SitePhotoBucket = () => {
	const { formId }: any = useParams();
	const { project } = useContext(ProjectContext);
	const { utilityForm } = useContext(FormV2Context);
	const projectId = project?.id || '';
	const [uploaded, setUploaded] = useState(false);
	const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] = useState(false);
	const [selectedFile, setSelectedFile] = useState<File | null>(null);
	const [uploadedImageURL, setUploadedImageURL] = useState('');
	const [imageOptionsDialogOpen, setImageOptionsDialogOpen] = useState(false);
	const [uploadProgress, setUploadProgress] = useState(0);
	const [isUploading, setIsUploading] = useState(false);

	const filepath = `utility_forms_v2/${formId}/cover/site_photo`;

	useEffect(() => {
		if (utilityForm && utilityForm.sitePhoto) {
			setUploadedImageURL(utilityForm.sitePhoto);
			setUploaded(true);
		}
	}, [utilityForm]);

	const uploadFile = useCallback(
		(file: File) => {
			setIsUploading(true);

			UploadCoverpageContent(
				file,
				file.name,
				'sitePhoto',
				formId,
				projectId,
				filepath,
				(url: string) => {
					setUploaded(true);
					setUploadedImageURL(url);
					setIsUploading(false);
				},
				(progress: number) => {
					setUploadProgress(progress);
				}
			);
		},
		[formId, projectId, filepath]
	);

	const onDrop = useCallback(
		(acceptedFiles: any) => {
			const file = acceptedFiles[0];
			if (!file) return;

			if (uploaded) {
				setSelectedFile(file);
				setIsConfirmationDialogOpen(true);
			} else {
				uploadFile(file);
			}
		},
		[uploaded, uploadFile]
	);

	const { getRootProps, getInputProps } = useDropzone({ onDrop, noClick: true });

	const handleDeleteImage = async () => {
		const imageRef = ref(storage, uploadedImageURL);
		await deleteObject(imageRef);

		const formRef = doc(db, `utility_forms_v2`, formId);
		await updateDoc(formRef, { sitePhoto: deleteField() });

		setUploadedImageURL('');
		setUploaded(false);
		setImageOptionsDialogOpen(false);
	};

	const handleBucketClick = (event: React.MouseEvent<HTMLDivElement>) => {
		event.stopPropagation();
		setImageOptionsDialogOpen(true);
	};

	return (
		<>
			<Bucket {...getRootProps({ className: 'dropzone' })} disableHover={!uploaded}>
				<input {...getInputProps()} />
				{isUploading ? (
					<UploadedContainer>
						<BucketText>Uploading: {uploadProgress.toFixed(2)}%</BucketText>
					</UploadedContainer>
				) : uploaded ? (
					<UploadedContainer onClick={handleBucketClick}>
						<UploadedImage
							src={uploadedImageURL}
							alt="Site Photo"
							style={{ width: '100%', height: 'auto', borderRadius: '30px' }}
						/>
						<ImageOverlay>Site Photo</ImageOverlay>
					</UploadedContainer>
				) : (
					<BucketText>Drop Site Photo here!</BucketText>
				)}
			</Bucket>
			<UploadConfirmationDialog
				open={isConfirmationDialogOpen}
				onClose={() => setIsConfirmationDialogOpen(false)}
				onConfirm={() => {
					setIsConfirmationDialogOpen(false);
					if (selectedFile) {
						uploadFile(selectedFile); // Use temporarily stored file for upload
					}
					setSelectedFile(null); // Reset temporary file storage
				}}
				title="Replace Site Photo"
				message="This will replace the Site Photo you already uploaded, are you sure you want to continue?"
			/>
			<ImageOptionsDialog
				open={imageOptionsDialogOpen}
				onClose={() => setImageOptionsDialogOpen(false)}
				onDelete={handleDeleteImage}
				onUpload={() => setIsConfirmationDialogOpen(true)}
				onReplace={() => setIsConfirmationDialogOpen(true)}
				imageURL={uploadedImageURL}
				uploaded={uploaded}
				dialogTitle="Site Photo"
			/>
		</>
	);
};

export default SitePhotoBucket;
