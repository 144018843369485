import {
	createContext,
	useCallback,
	useContext,
	useEffect,
	useState,
	useMemo,
	useRef,
} from 'react';
import { useParams } from 'react-router-dom';

import { Category, UtilityForm, UtilityFormStatus } from './types';
import { debounce } from 'lodash';
import { getUtilityFormById, updateFormName, updateUtilityForm } from '../../../firebase';
import { UploadContext } from './UploadContext';
import { usePrompt } from './utils/confirmNavigationHelper';
import { SnackContext } from '../../../context/SnackProvider';
import { FirebaseError } from 'firebase/app';

/**
 * Context to hold all form data.
 */
export type FormContextType = {
	formState: Category[];
	setFormState: React.Dispatch<React.SetStateAction<Category[]>>;
	formStatus: UtilityFormStatus | null;
	setFormStatus: React.Dispatch<React.SetStateAction<UtilityFormStatus | null>>;
	formUrl: string;
	setFormUrl: React.Dispatch<React.SetStateAction<string>>;
	formError: FirebaseError | null;
	setFormError: React.Dispatch<React.SetStateAction<FirebaseError | null>>;

	submittingUtilityForm: boolean;
	setSubmittingUtilityForm: React.Dispatch<React.SetStateAction<boolean>>;
	wrappedDebounce: (status?: UtilityFormStatus, cachedFormState?: Category[]) => void;
	debouncePending: boolean;

	formTitle: {
		formName: string;
	} | null;
	setFormTitle: React.Dispatch<
		React.SetStateAction<{
			formName: string;
		} | null>
	>;

	fetchUtilityForm: () => Promise<void>;

	fetchingUtilityForm: boolean;
	setFetchingUtilityForm: React.Dispatch<React.SetStateAction<boolean>>;
};
export const FormContext = createContext<FormContextType>({} as FormContextType);

export const FormProvider = ({ children }: { children?: React.ReactNode }) => {
	const { setSnackbarProps } = useContext(SnackContext);
	const { mountedFormId, setGlobalCachedForm, formSubmitState } =
		useContext(UploadContext);

	const { formId } = useParams() as { [key: string]: string };

	const [formState, setFormState] = useState<Category[]>([]);
	const [formStatus, setFormStatus] = useState<UtilityFormStatus | null>(null);
	const [formUrl, setFormUrl] = useState('');
	const [formError, setFormError] = useState<FirebaseError | null>(null);

	const [submittingUtilityForm, setSubmittingUtilityForm] = useState(false);
	const [debouncePending, setDebouncePending] = useState(false);
	const [formTitle, setFormTitle] = useState<{ formName: string } | null>(null);

	const isFormContextMounted = useRef(false);
	const [fetchingUtilityForm, setFetchingUtilityForm] = useState(true);

	useEffect(() => {
		isFormContextMounted.current = true;
		return () => {
			isFormContextMounted.current = false;
		};
	}, []);

	useEffect(() => {
		if (formId) mountedFormId.current = formId;
		return () => {
			mountedFormId.current = null;
		};
	}, [formId, mountedFormId]);

	useEffect(() => {
		document.title = formTitle?.formName || 'Loading Form...';
		if (formTitle && formId) {
			updateFormName({ id: formId, formName: formTitle.formName });
		}
	}, [formTitle, formId]);

	usePrompt('Leave page? Changes may not be saved.', debouncePending);

	/**
	 * Call firebase function to check if a form exists for the current user and project id.
	 * If form exists then render the existing form, else create a record of the form in the database before rendering the UI.
	 */
	const fetchUtilityForm = useCallback(async () => {
		setFetchingUtilityForm(true);
		setFormUrl('');
		const data = { formId: formId };
		try {
			const response = await getUtilityFormById(data);
			const responseData = response.data as UtilityForm;
			if (responseData.formData) {
				if (responseData.status === 'completed' && responseData.downloadUrl) {
					setFormUrl(responseData.downloadUrl);
				}
				setFormState(responseData.formData);
				setFormStatus(responseData.status);
			}
			setFormTitle({ formName: responseData.formName });
		} catch (e) {
			const err = e as FirebaseError;
			console.error(err.code, err.message);
			setFormError(err as FirebaseError);
		}
		setFetchingUtilityForm(false);
	}, [formId]);

	// useEffect to get form data
	useEffect(() => {
		if (formId in formSubmitState) {
			mountedFormId.current = null;
			setSubmittingUtilityForm(true);
			setFetchingUtilityForm(false);
		} else {
			mountedFormId.current = formId;
			setSubmittingUtilityForm(false);
			fetchUtilityForm();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [fetchUtilityForm]);

	const debounceUpdate = useMemo(
		() =>
			debounce(
				async (
					formId: string,
					formData: Category[],
					status?: UtilityFormStatus,
					cachedFormState?: Category[]
				) => {
					try {
						if (isFormContextMounted.current) {
							setSnackbarProps({
								open: true,
								severity: 'warning',
								message: 'Saving changes...',
								hideDuration: null,
							});
						}

						await updateUtilityForm({
							id: formId,
							formData: cachedFormState || formData,
							status: status,
						});

						if (isFormContextMounted.current) {
							setSnackbarProps({
								open: true,
								severity: 'success',
								message: 'Changes Saved!',
							});
							if (status === 'uploaded') {
								// TODO: Change this block so that we attach a listener to the form doc
								// and then fetch when it's completed
								await fetchUtilityForm();
								setSubmittingUtilityForm(false);
							}
						}
					} catch (err) {
						console.log(err);
					}

					setGlobalCachedForm(null);
					if (isFormContextMounted.current) setDebouncePending(false);
				},
				5000
			),
		[fetchUtilityForm, setGlobalCachedForm, setSnackbarProps]
	);

	const wrappedDebounce = useCallback(
		(status?: UtilityFormStatus, cachedFormState?: Category[]) => {
			if (isFormContextMounted.current) {
				setDebouncePending(true);
			}
			debounceUpdate(formId, formState, status, cachedFormState);
		},
		[debounceUpdate, formId, formState]
	);

	return (
		<FormContext.Provider
			value={{
				formState,
				setFormState,
				formStatus,
				setFormStatus,
				formUrl,
				setFormUrl,
				formError,
				setFormError,

				submittingUtilityForm,
				setSubmittingUtilityForm,
				wrappedDebounce,
				debouncePending,

				formTitle,
				setFormTitle,

				fetchUtilityForm,

				fetchingUtilityForm,
				setFetchingUtilityForm,
			}}>
			{children}
		</FormContext.Provider>
	);
};
