import { Button, Dialog, DialogActions, DialogContent } from '@mui/material';
import { useContext, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { DocusignEnvelopeStatus } from '../types';
import { AuthContext } from '../../../../../../context/AuthProvider';
import { createRecipientView, createSenderView } from '../../../../../../firebase';
import LoadingScreen from '../../../../../reusable-components/LoadingScreen';
import { SnackContext } from '../../../../../../context/SnackProvider';

type DocusignIframeDialogProps = {
	selectedEnvelope: {
		envelopeId: string;
		envelopeStatus: DocusignEnvelopeStatus;
		envelopeName: string;
	} | null;
	handleClose: () => void;
};

export default function DocusignIframeDialog({
	selectedEnvelope,
	handleClose,
}: DocusignIframeDialogProps) {
	const { setSnackbarProps } = useContext(SnackContext);
	const { user } = useContext(AuthContext);

	const [loadingUrl, setLoadingUrl] = useState(false);
	const [docuSignUrl, setDocuSignUrl] = useState('');

	/* 
        See the 'createSenderView' and 'createRecipientView' firebase functions to see that the iframe
        urls are created with a return url of 'https://www.google.com', which the iframe will redirect to
        once the Docusign flow is over. Google does not allow their page to be embedded and the failed
        redirect will still trigger the iframe's 'onLoad' event, but the iframe will have the same src
        as before since the redirect failed.

        By keeping track of the iframe's previous src and updating it after every 'onLoad' event, we can
        look for that specfic failed redirect to google and run any completion callbacks (like rendering
        a snack bar or closing the iframe dialog).
    */
	const prevSrc = useRef('');
	const checkIframe = (target: HTMLIFrameElement) => {
		if (target.src !== prevSrc.current) {
			// Different src's so, no 'refused to connect' error.
			prevSrc.current = target.src;
		} else {
			// Same src's so iframe attempted to redirect to https://google.com, so Docusign flow is over.
			prevSrc.current = '';
			if (selectedEnvelope) {
				if (selectedEnvelope?.envelopeStatus !== 'completed') {
					setSnackbarProps({
						open: true,
						severity: 'success',
						message: user?.isAdmin
							? senderFinishedMessage(selectedEnvelope?.envelopeStatus)
							: recipientFinishedMessage,
						hideDuration: 10000,
					});
				}
				handleClose();
			}
		}
	};

	const callCreateSenderView = async (envelope: {
		envelopeId: string;
		envelopeStatus?: DocusignEnvelopeStatus;
	}) => {
		try {
			const response = await createSenderView(envelope);
			let dsUrl = (response.data as any).url;
			dsUrl = dsUrl.replace('send=1', 'send=0');
			setDocuSignUrl(dsUrl);
		} catch (err) {
			console.error(err);
		} finally {
			setLoadingUrl(false);
		}
	};

	const callCreateRecipientView = async (envelope: { envelopeId: string }) => {
		try {
			const response = await createRecipientView(envelope);
			const dsUrl = (response.data as any).url;
			setDocuSignUrl(dsUrl);
		} catch (err) {
			console.error(err);
		} finally {
			setLoadingUrl(false);
		}
	};

	useEffect(() => {
		if (selectedEnvelope) {
			setLoadingUrl(true);
			if (user?.isAdmin) callCreateSenderView(selectedEnvelope);
			else callCreateRecipientView(selectedEnvelope);
		}
	}, [selectedEnvelope, user?.isAdmin]);

	return (
		<IframeDialog open={!!selectedEnvelope} onClose={handleClose}>
			<IframeContainer>
				{!loadingUrl && docuSignUrl ? (
					<iframe
						src={docuSignUrl}
						height="100%"
						width="100%"
						title="docusign-iframe"
						onLoad={e => checkIframe(e.target as HTMLIFrameElement)}
					/>
				) : (
					<LoadingScreen message="Loading Docusign viewer..." textColor="black" />
				)}
			</IframeContainer>

			<DialogActions>
				<Button onClick={handleClose}>Close</Button>
			</DialogActions>
		</IframeDialog>
	);
}

const IframeDialog = styled(Dialog)`
	.MuiPaper-root {
		width: 90vw;
		max-width: 90vw;
		height: 100%;
	}
`;

const IframeContainer = styled(DialogContent)`
	display: flex;
	flex-direction: column;
	position: relative;

	width: 100%;
	height: 100%;
	padding: 10px;
`;

const senderFinishedMessage = (status: DocusignEnvelopeStatus) => {
	switch (status) {
		case 'created':
			return 'Envelope has been sent out to recipients! Please wait about 20 seconds, then refresh the Docusign envelopes to review the sent envelope!';
		default:
			return 'Envelope has been edited! Please wait about 20 seconds, then refresh the Docusign envelopes to review the edited envelope!';
	}
};
const recipientFinishedMessage = `Thanks for signing! Please wait about 20 seconds, then refresh the Docusign envelopes to review the completed envelope!`;
