import React, { useCallback, useContext, useEffect, useState } from 'react';
import { DndContext, DragEndEvent, closestCenter } from '@dnd-kit/core';
import { SortableContext, arrayMove } from '@dnd-kit/sortable';
import {
	CollapseButton,
	CompactIconButton,
	GalleryContainer,
	PhotoDropzone,
} from '../FormFillV2.styles';
import SortablePhoto from './SortablePhoto';
import { PhotoV2 } from '../../../screen-components/ProjectUtilityFormV2/utils/types';
import { FormV2Context } from '../../../screen-components/ProjectUtilityFormV2/context/FormV2Context.types';
import { Typography, CircularProgress } from '@mui/material';
import { Add, FilePresent, ImageOutlined, KeyboardArrowUp } from '@mui/icons-material';
import theme from '../../../../styles/theme';
import { useDropzone } from 'react-dropzone';
import { SnackContext } from '../../../../context/SnackProvider';
import { TemplateContext } from '../../../../context/TemplateProvider';

interface DraggableGalleryProps {
	disabled?: boolean;
	photos: PhotoV2[];
	openGallery: (index: number) => void;
	nodeId: string;
	onUpload: (newPhotos: File[]) => Promise<void>;
	isUploading: boolean;
	setOpenUploadManager: (open: boolean) => void;
}

const DraggableGallery: React.FC<React.PropsWithChildren<DraggableGalleryProps>> = ({
	disabled,
	photos,
	openGallery,
	nodeId,
	onUpload,
	isUploading,
	setOpenUploadManager,
}) => {
	const { updateValue, isViewMode } = useContext(FormV2Context);
	const { isTemplate } = useContext(TemplateContext);
	const { setSnackbarProps } = useContext(SnackContext);
	const [localPhotos, setLocalPhotos] = useState<PhotoV2[]>(photos);
	const [isExpanded, setIsExpanded] = useState(false);

	const PHOTOS_PER_ROW = 8;
	const visiblePhotos =
		isViewMode && !isExpanded ? localPhotos.slice(0, PHOTOS_PER_ROW) : localPhotos;
	const remainingPhotos =
		localPhotos.length - (isViewMode ? PHOTOS_PER_ROW : localPhotos.length);

	useEffect(() => {
		setLocalPhotos(photos);
	}, [photos]);

	const handleDragEnd = useCallback(
		(event: DragEndEvent) => {
			const { active, over } = event;

			if (over && active.id !== over.id) {
				const oldIndex = localPhotos.findIndex(item => item.name === active.id);
				const newIndex = localPhotos.findIndex(item => item.name === over.id);
				const newPhotos = arrayMove(localPhotos, oldIndex, newIndex);

				setLocalPhotos(newPhotos);
				updateValue(nodeId, newPhotos).catch(console.error);
			}
		},
		[localPhotos, updateValue, nodeId]
	);

	const onDrop = useCallback(
		async (acceptedFiles: File[]) => {
			await onUpload(acceptedFiles);
		},
		[onUpload]
	);

	const onDropRejected = useCallback(
		(fileRejections: any[]) => {
			fileRejections.forEach(fileRejection => {
				fileRejection.errors.forEach((error: { code: string; message: string }) => {
					if (error.code === 'file-too-large') {
						setSnackbarProps({
							open: true,
							message: `File is too large.`,
							severity: 'warning',
						});
					} else if (error.code === 'file-invalid-type') {
						setSnackbarProps({
							open: true,
							message: `File type not accepted.`,
							severity: 'warning',
						});
					}
				});
			});
		},
		[setSnackbarProps]
	);

	const { isDragActive, getRootProps, getInputProps, isDragReject } = useDropzone({
		onDrop,
		onDropRejected,
		accept: ['image/png', 'image/jpeg'],
		minSize: 0,
		disabled: isUploading || isTemplate,
	});
	useEffect(() => {
		setLocalPhotos(photos);
	}, [photos]);

	return (
		<DndContext onDragEnd={handleDragEnd} collisionDetection={closestCenter}>
			<SortableContext items={localPhotos.map(photo => photo.name)} disabled={disabled}>
				<GalleryContainer isviewmode={String(isViewMode)} isExpanded={isExpanded}>
					{visiblePhotos.map((photo, index) => (
						<SortablePhoto
							key={index}
							photo={photo}
							index={index}
							openGallery={openGallery}
							isViewMode={isViewMode}
						/>
					))}

					{isViewMode ? (
						<>
							{!isExpanded && remainingPhotos > 0 && (
								<CompactIconButton onClick={() => setIsExpanded(true)}>
									+{remainingPhotos}
								</CompactIconButton>
							)}
							<CompactIconButton
								onClick={() => !isTemplate && setOpenUploadManager(true)}>
								<FilePresent fontSize="small" />
							</CompactIconButton>
							<CompactIconButton {...getRootProps()}>
								<input {...getInputProps()} disabled={isTemplate || isUploading} />
								{isUploading ? <CircularProgress size={16} /> : <Add fontSize="small" />}
							</CompactIconButton>
						</>
					) : (
						<PhotoDropzone {...getRootProps()} small={1}>
							<input {...getInputProps()} disabled={isTemplate || isUploading} />
							{isUploading ? (
								<CircularProgress size={24} />
							) : (
								<ImageOutlined
									sx={{
										fontSize: 24,
										marginBottom: 1,
									}}
								/>
							)}
							<Typography
								fontWeight={700}
								variant="caption"
								sx={{
									textAlign: 'center',
									[theme.breakpoints.down('sm')]: {
										fontSize: 12,
									},
								}}>
								{isUploading && 'Uploading...'}
								{!isUploading && !isDragActive && 'Add photo'}
								{!isUploading && isDragActive && !isDragReject && 'Drop it like its hot!'}
							</Typography>
						</PhotoDropzone>
					)}
				</GalleryContainer>

				{isViewMode && isExpanded && (
					<CollapseButton onClick={() => setIsExpanded(false)}>
						<KeyboardArrowUp fontSize="small" />
					</CollapseButton>
				)}
			</SortableContext>
		</DndContext>
	);
};

export default DraggableGallery;
