import React from 'react';

import { StyledIconButton } from './components';
import { PictureAsPdf } from '@mui/icons-material';

export const PDF: React.FC<{ link: string }> = ({ link }) => {
	const handleClick = (event: React.MouseEvent<HTMLElement>) => {
		window.open(link, '_blank');
	};

	return (
		<StyledIconButton onClick={handleClick} title="Open generated PDF">
			<PictureAsPdf color="primary" />
		</StyledIconButton>
	);
};
