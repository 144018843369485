import {
	Box,
	Button,
	CircularProgress,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Grid,
	TextField,
	Typography,
} from '@mui/material';
import { CropOriginal } from '@mui/icons-material';
import { useCallback, useEffect, useState } from 'react';
import { PhotoUploader } from '../../screen-components/ProjectUtilityFormV2/components/FormContainer/components/FormFill/FormFillV2.styles';
import { useDropzone } from 'react-dropzone';
import {
	getFileExtension,
	getFileName,
} from '../../screen-components/ProjectUtilityFormV2/utils/utils';
import styled from 'styled-components';
import { CoverImage } from '../../screen-components/ProjectUtilityFormV2/utils/types';
import theme from '../../../styles/theme';

type Props = {
	openDialog: boolean;
	image?: CoverImage;
	dialogTitle: string;
	handleClose: () => void;
	uploadImageFunc: (value: UploadImageDialogData) => Promise<void>;
	deleteImageFunc: (value: UploadImageDialogData) => Promise<void>;
};

type DisabledButton = {
	upload: boolean;
	all: boolean;
};

export type UploadImageDialogData = {
	oldURL: string;
	name: string;
	onProgress: (progress: number) => void;
	newFile: any;
};

const UploadImageDialog: React.FC<Props> = ({
	openDialog,
	image,
	dialogTitle,
	handleClose,
	uploadImageFunc,
	deleteImageFunc,
}) => {
	const [extension, setExtension] = useState<string>('');
	const [file, setFile] = useState<any>([]);
	const [imageTitle, setImageTitle] = useState<string>('');
	const [imagePreview, setImagePreview] = useState<string>('');
	const [uploadProgress, setUploadProgress] = useState<number | null>(null);
	const [uploadError, setUploadError] = useState<boolean>(false);
	const [disableButtons, setDisableButtons] = useState<DisabledButton>({
		upload: false,
		all: false,
	});

	const handleImageTitle = (title: string) => {
		setImageTitle(title);
		setDisableButtons({
			upload: !!title.length,
			all: false,
		});
		setUploadError(false);
	};

	const { getRootProps, getInputProps, open } = useDropzone({
		accept: ['image/png', 'image/jpeg'],
		minSize: 0,
		maxFiles: 1,
		multiple: false,
		onDrop: acceptedFiles => {
			setDisableButtons({
				upload: true,
				all: false,
			});
			setExtension(getFileExtension(acceptedFiles[0].name));
			setFile(
				acceptedFiles.map(file =>
					Object.assign(file, {
						preview: URL.createObjectURL(file),
					})
				)
			);
			setImagePreview(URL.createObjectURL(acceptedFiles[0]));
		},
	});

	const handleUploadImage = async () => {
		const uploadData = {
			oldURL: image?.downloadURL ?? '',
			name: `${imageTitle}${extension}`,
			onProgress: (progress: number) => {
				setUploadProgress(progress);
			},
			newFile: file[0],
		};

		setDisableButtons({
			upload: true,
			all: true,
		});

		setUploadProgress(0);
		await uploadImageFunc(uploadData);
		handleCancel();
	};

	const handleDeleteImage = async () => {
		if (!image?.downloadURL) return;

		const deleteData: UploadImageDialogData = {
			oldURL: image?.downloadURL,
			name: image?.name ?? '',
			newFile: null,
			onProgress: () => {},
		};
		setDisableButtons({
			upload: true,
			all: true,
		});

		await deleteImageFunc(deleteData);
		handleCancel();
	};

	const handleCancel = useCallback(() => {
		setFile([]);
		setImageTitle('');
		setImagePreview('');
		setUploadProgress(null);
		setDisableButtons({
			upload: true,
			all: false,
		});
		setUploadError(false);
		handleClose();
	}, [handleClose]);

	useEffect(() => {
		if (uploadProgress === 100) {
			handleCancel();
		}
	}, [handleCancel, uploadProgress]);

	useEffect(() => {
		if (image && image.downloadURL.length && openDialog) {
			setImagePreview(image.downloadURL);
			setImageTitle(getFileName(image.name));
		}
	}, [image, openDialog]);

	useEffect(() => {
		setDisableButtons({
			upload: !(
				(!!file.length || imageTitle !== getFileName(image?.name ?? '')) &&
				!!imageTitle.length
			),
			all: false,
		});
	}, [file, imageTitle, image?.name]);

	return (
		<Dialog
			fullScreen={false}
			open={openDialog}
			onClose={() =>
				(uploadProgress === null || uploadProgress === 100) &&
				!disableButtons.all &&
				handleCancel()
			}
			aria-labelledby="responsive-dialog-title"
			maxWidth="md"
			PaperProps={{
				sx: {
					maxHeight: 'unset',
				},
			}}>
			<StyledDialogTitle id="responsive-dialog-title" variant="h4">
				{dialogTitle}
			</StyledDialogTitle>
			<StyledDialogContent
				sx={{ p: '32px', backgroundColor: theme.palette.common.black }}>
				<PhotoUploader {...getRootProps()} hidden={!!imagePreview.length}>
					<input {...getInputProps()} />
					<CropOriginal color="primary" fontSize="small" />
					<Box display={'flex'} gap={0.5}>
						<Typography variant="body2" fontWeight={700} color="newText.primary">
							Drop image here or
						</Typography>
						<Typography
							variant="body2"
							fontWeight={700}
							color="primary.main"
							sx={{ textDecoration: 'underline' }}>
							click to upload
						</Typography>
					</Box>
				</PhotoUploader>
				{!!imagePreview.length && (
					<Grid container spacing={2}>
						<Grid item xs={6}>
							<img
								src={imagePreview}
								alt={imageTitle}
								style={{ width: '100%', borderRadius: '6px' }}
							/>
						</Grid>
						<Grid item xs={6}>
							{image?.downloadURL ? (
								<ImageTitle>Image title: {imageTitle}</ImageTitle>
							) : (
								<StyledTextField
									label="Image title"
									disabled={!!image?.downloadURL}
									value={imageTitle}
									placeholder="Enter image title"
									InputLabelProps={{ shrink: true }}
									onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
										handleImageTitle(event.target.value);
									}}
									error={uploadError}
									helperText={
										uploadError ? 'An image with this name already exists.' : ''
									}
								/>
							)}
							<StyledButtonContained
								onClick={open}
								variant="outlined"
								disabled={disableButtons.all || !!image?.downloadURL}>
								Replace Image
							</StyledButtonContained>
						</Grid>
					</Grid>
				)}
			</StyledDialogContent>
			<StyledDialogActions>
				<StyledButtonText onClick={handleCancel} disabled={disableButtons.all}>
					Cancel
				</StyledButtonText>
				<Box sx={{ position: 'relative' }}>
					<StyledButtonContained
						onClick={handleUploadImage}
						variant="contained"
						size="medium"
						disabled={disableButtons.upload || !!image?.downloadURL}>
						Save and upload
					</StyledButtonContained>
					{uploadProgress !== null && disableButtons.upload && (
						<StyledCircularProgress
							size={24}
							variant="indeterminate"
							value={uploadProgress}
						/>
					)}
				</Box>
				{!!image?.name.length && (
					<Box sx={{ position: 'relative' }}>
						<StyledButtonError
							onClick={handleDeleteImage}
							variant="contained"
							color="error"
							size="medium"
							disabled={disableButtons.all}>
							Delete
						</StyledButtonError>
						{disableButtons.all && uploadProgress === null && (
							<StyledCircularProgress size={24} variant="indeterminate" />
						)}
					</Box>
				)}
			</StyledDialogActions>
		</Dialog>
	);
};

export default UploadImageDialog;

const StyledButtonContained = styled(Button)`
	font-size: 14px;
	text-transform: none;
	padding: 8px 24px;

	background-color:  ${theme.palette.secondary.main}

	&:hover {
		border-color: transparent;
		background-color:  ${theme.palette.secondary.main}
		opacity: 0.8;
	}

	&:disabled {
		color: rgb(251 251 251 / 30%);
		background-color: rgba(230, 224, 233, 0.12);
	}
`;

const StyledButtonError = styled(Button)`
	font-size: 14px;
	text-transform: none;
	padding: 8px 24px;

	&:disabled {
		color: ${theme.palette.newText.primary};
		background-color: ${theme.palette.error.main};
		opacity: 0.7;
	}
`;

const StyledButtonText = styled(Button)`
	color: ${theme.palette.primary.main};
	font-size: 14px;
	text-transform: none;
	padding: 8px 12px;

	&:hover {
		background-color: ${theme.palette.secondary.main};
	}

	&:disabled {
		color: ${theme.palette.newText.primary};
		opacity: 0.7;
	}
`;

const StyledCircularProgress = styled(CircularProgress)`
	position: absolute;
	top: 50%;
	left: 50%;
	margin-top: -12px;
	margin-left: -12px;
`;

const StyledDialogActions = styled(DialogActions)`
	background: unset;
	background-color: ${theme.palette.common.black};
	padding: 24px 32px;
`;

const StyledDialogContent = styled(DialogContent)`
	padding: 0px;
	background-color: black;

	> * {
		margin: 0;
	}
`;

const StyledDialogTitle = styled(DialogTitle)`
	background: unset;
	background-color: ${theme.palette.common.black};
	border-bottom: 1px solid ${theme.palette.divider};
	padding: 24px 32px;
`;

const StyledTextField = styled(TextField)`
	width: 400px;
	max-width: 100%;
	margin: 16px 0;

	input,
	textarea {
		color: #fbfbfb;

		&::placeholder {
			line-height: 1.6;
		}
	}

	fieldset {
		border-color: #242424;
	}

	label {
		color: #838383;
		line-height: 1.6;
	}

	&:hover {
		> :not(.Mui-focused) {
			fieldset {
				border-color: #fff;
			}
		}
	}
	.MuiFormHelperText-root {
		color: ${theme.palette.error.main};
	}
`;

const ImageTitle = styled.span`
	font-size: 16px;
	font-weight: 700;
	color: ${theme.palette.newText.primary};
`;
