import { useState, useContext } from 'react';
import AddProjectForm from '../AddProjectForm';
import { AuthContext } from '../../../context/AuthProvider';
import { YellowIconButton } from '../../styled-components/buttons';
import { Add } from '@mui/icons-material';

export default function CreateProjectButton({
	orgRequired = true,
}: {
	orgRequired?: boolean;
}) {
	const [showingAddProject, setShowingAddProject] = useState<boolean>(false);
	const { user } = useContext(AuthContext);

	return (
		<>
			<AddProjectForm
				showingDialog={showingAddProject}
				setShowingDialog={setShowingAddProject}
				isClient={user?.isContractor && !user?.isAdmin}
				orgRequired={orgRequired}
			/>

			<YellowIconButton
				title="Create a new project"
				onClick={() => {
					setShowingAddProject(true);
				}}
				size="large">
				<Add
					style={{
						border: '1.5px solid #ffb310',
						borderRadius: '5px',
						fontSize: '25px',
					}}
				/>
			</YellowIconButton>
		</>
	);
}
