import { Column, Row } from 'react-table';
import { formatDate } from '../../../../utils';
import LeadButtons from './components/LeadButtons';
import LeadTextCell from './components/cells/LeadTextCell';
import LeadPhoneCell from './components/cells/LeadPhoneCell';
import LeadSelectCell from './components/cells/LeadSelectCell';
import LeadDateCell from './components/cells/LeadDateCell';
import CommentsTextField from './components/cells/LeadCommentsCell';
import { Lead } from '../../types';
import LeadEmailCell from './components/cells/LeadEmailCell';

export function getLeadColumns(): Column<Lead>[] {
	return [
		{
			id: 'indexNumber',
			Cell: (cellProps: any) => {
				const rowIndex = cellProps.rowIdx + 1 + cellProps.pageIndex * cellProps.pageSize;
				return <span>{rowIndex}</span>;
			},
			width: 45,
		},
		{
			id: 'checkbox',
			Cell: ({ row }: { row: Row<Lead> }) => {
				return (
					<input
						type="checkbox"
						style={{ cursor: 'pointer' }}
						checked={row.isSelected}
						onChange={e => {
							row.toggleRowSelected(e.target.checked);
						}}
					/>
				);
			},
			width: 30,
		},
		{
			id: 'buttonColumn',
			Cell: ({ row }: { row: Row<Lead> }) => {
				return <LeadButtons row={row} />;
			},
			width: 90,
		},
		{
			id: 'company',
			Header: 'Company',
			Cell: ({ row }: { row: Row<Lead> }) => {
				return <LeadTextCell row={row} field="company" />;
			},
			accessor: (leadData: Lead) => leadData?.company || '-',
		},
		{
			id: 'firstName',
			Header: 'First Name',
			Cell: ({ row }: { row: Row<Lead> }) => {
				return <LeadTextCell row={row} field="firstName" />;
			},
			accessor: (leadData: Lead) => leadData?.firstName || '-',
			width: 100,
		},
		{
			id: 'lastName',
			Header: 'Last Name',
			Cell: ({ row }: { row: Row<Lead> }) => {
				return <LeadTextCell row={row} field="lastName" />;
			},
			accessor: (leadData: Lead) => leadData?.lastName || '-',
			width: 100,
		},
		{
			id: 'contactTitle',
			Header: 'Title',
			Cell: ({ row }: { row: Row<Lead> }) => {
				return <LeadTextCell row={row} field="contactTitle" />;
			},
			accessor: (leadData: Lead) => leadData?.contactTitle || '-',
			width: 150,
		},
		{
			id: 'mobileNumber',
			Header: 'Mobile Number',
			Cell: ({ row }: { row: Row<Lead> }) => {
				return <LeadPhoneCell row={row} field="mobileNumber" />;
			},
			accessor: (leadData: Lead) => leadData?.mobileNumber || '-',
			width: 145,
		},
		{
			id: 'hqNumber',
			Header: 'Direct Number',
			Cell: ({ row }: { row: Row<Lead> }) => {
				return <LeadPhoneCell row={row} field="hqNumber" />;
			},
			accessor: (leadData: Lead) => leadData?.hqNumber || '-',
			width: 145,
		},
		{
			id: 'email',
			Header: 'Email',
			Cell: ({ row }: { row: Row<Lead> }) => {
				return <LeadEmailCell row={row} />;
			},
			accessor: (leadData: Lead) => leadData?.email || '-',
			width: 220,
		},
		{
			id: 'status',
			Header: 'Lead Status',
			Cell: ({ row }: { row: Row<Lead> }) => {
				return <LeadSelectCell row={row} field="status" />;
			},
			accessor: (leadData: Lead) => leadData?.status || '-',
			width: 110,
		},
		{
			id: 'accountManager',
			Header: 'Account Manager',
			Cell: ({ row }: { row: Row<Lead> }) => {
				return <LeadSelectCell row={row} field="accountManager" />;
			},
			accessor: (leadData: Lead) => leadData?.accountManager || '-',
			width: 110,
		},
		{
			id: 'nextActivity',
			Header: 'Next Activity',
			Cell: ({ row }: { row: Row<Lead> }) => {
				return <LeadDateCell row={row} field="nextActivity" />;
			},
			accessor: (leadData: Lead) => leadData?.nextActivity?.valueOf() || '-',
			width: 130,
		},
		{
			id: 'lastActivity',
			Header: 'Last Activity',
			Cell: ({ row: { original: leadData } }: any) => {
				return <span>{formatDate(leadData?.lastActivity) || '-'}</span>;
			},
			accessor: (leadData: Lead) => leadData?.lastActivity?.valueOf() || '-',
			width: 100,
		},
		{
			id: 'state',
			Header: 'State',
			Cell: ({ row }: { row: Row<Lead> }) => {
				return <LeadTextCell row={row} field="state" />;
			},
			accessor: (leadData: Lead) => leadData?.state || '-',
			width: 100,
		},
		{
			id: 'lastComment',
			Header: 'Comments',
			Cell: ({ row: { original: leadData } }: any) => {
				return (
					<CommentsTextField docId={leadData?.id} lastComment={leadData?.lastComment} />
				);
			},
			accessor: (leadData: Lead) => leadData?.lastComment || '-',
			disableSortBy: true,
		},
	];
}
