import { useContext, useEffect } from 'react';

import ProjectsListStatusTables from './components/ProjectsListStatusTables';
import { AuthContext } from '../../context/AuthProvider';

import { checkPermissions } from '../../utils';
import { ProjectContext } from '../../context/ProjectProvider';
import ClientProjects from './components/ClientProjects';

/**
 * Functional component that displays all projects accessible by the user; as a list for and admin and as cards for users
 * @returns
 */
export default function ProjectsList() {
	const { user } = useContext(AuthContext);
	const { setProjectsSelected } = useContext(ProjectContext);

	useEffect(() => {
		document.title = 'Projects List | Robotic Imaging';

		return () => {
			// Clear selected projects when user navigates away from this page.
			setProjectsSelected([]);
		};
	}, [setProjectsSelected]);

	return (
		<>
			{checkPermissions(user, 'contractor') ? (
				// Projects view for contractors and admins.
				<ProjectsListStatusTables />
			) : (
				// Projects view for clients.
				<ClientProjects />
			)}
		</>
	);
}
