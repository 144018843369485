import { useContext, useEffect, useState } from 'react';
import { Divider, IconButton, Stack, Tooltip } from '@mui/material';
import { AddCircleOutline, RemoveCircleOutline } from '@mui/icons-material';
import {
	FormNode,
	itemTypesWithSection,
	NodeType,
} from '../../screen-components/ProjectUtilityFormV2/utils/types';
import useSearchItemTemplates from '../../screen-components/ProjectUtilityFormV2/hooks/useSearchItemTempaltes';
import { FormV2Context } from '../../screen-components/ProjectUtilityFormV2/context/FormV2Context.types';
import {
	ChildText,
	ChipContent,
	NodeText,
	OptionHeader,
	PasteButton,
	PasteContainer,
	PastedNodeBox,
	TemplateChip,
	TemplateTitle,
	TemplateType,
	TypeText,
} from '../../screen-components/ProjectUtilityFormV2/components/Dialogs/NewItemDialog/NewItemDialog.styles';
import {
	StyledButtonContained,
	StyledButtonText,
	StyledTextField,
	StyledDialogContent,
} from '../CoverImage/CoverImageStyles';
import { StyledMenuItem } from '../SelectField/Select.styles';
import { selectStyles, textFieldStyles } from './ElementDialog.styles';
import { Dialog } from '../Dialog';

type NewItemDialogProps = {
	isOpen: boolean;
	onClose: () => void;
	onAddItem: (
		itemName: string,
		itemType: NodeType,
		options?: string[],
		parentId?: string
	) => void;
	enableTypeSelection: boolean;
	excludeTypes?: NodeType[];
	dialogTitle?: string;
	inputLabel?: string;
	onPasteItem?: () => void;
	showPasteOption?: boolean;
	defaultType?: NodeType;
};

export const ElementDialog: React.FC<React.PropsWithChildren<NewItemDialogProps>> = ({
	isOpen,
	onClose,
	onAddItem,
	enableTypeSelection = true,
	excludeTypes = [],
	dialogTitle = 'Add New Item',
	inputLabel = 'Enter Item Name or Search for Template',
	onPasteItem,
	showPasteOption,
	defaultType,
}) => {
	const [itemName, setItemName] = useState('');
	const [itemType, setItemType] = useState<NodeType | ''>('');
	const [nodeOptions, setNodeOptions] = useState<string[]>([]);
	const [newOption, setNewOption] = useState('');
	const [templates, setTemplates] = useState<FormNode[]>([]);
	const { searchItems } = useSearchItemTemplates();
	const { copiedNodeData } = useContext(FormV2Context);

	useEffect(() => {
		if (defaultType) {
			setItemType(defaultType);
		}
	}, [defaultType, isOpen]);

	const filteredTypes = itemTypesWithSection.filter(
		type => !excludeTypes.includes(type.value as NodeType)
	);

	const handleTemplateClick = (template: FormNode) => {
		onAddItem(template.displayTitle, template.type, template.options);
		handleClose();
	};

	const handleAddClick = () => {
		if (itemType === 'select' && nodeOptions.length > 0) {
			onAddItem(itemName, itemType, nodeOptions);
		} else if (itemType) {
			onAddItem(itemName, itemType);
		}
		handleClose();
	};

	const handleKeyDown = (event: React.KeyboardEvent) => {
		if (event.key === 'Enter') {
			event.preventDefault(); // Prevent form submission on 'enter'
			handleAddOption();
		}
	};

	const handleRemoveOption = (optionToRemove: string) => {
		setNodeOptions(nodeOptions.filter(option => option !== optionToRemove));
	};

	const handleAddOption = () => {
		if (newOption.trim() !== '') {
			setNodeOptions(prev => [...prev, newOption.trim()]);
			setNewOption('');
		}
	};

	const handleNewOptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setNewOption(event.target.value);
	};

	const onChangeName = async (e: React.ChangeEvent<HTMLInputElement>) => {
		setItemName(e.target.value);
		const result = await searchItems(e.target.value as string);
		const filteredResult = result.filter(
			template => !excludeTypes.includes(template.type as NodeType)
		);
		setTemplates(filteredResult);
	};

	const handleClose = () => {
		setItemName('');
		setItemType(enableTypeSelection ? '' : 'node');
		setNodeOptions([]);
		setNewOption('');
		setTemplates([]);
		onClose();
	};

	return (
		<Dialog
			open={isOpen}
			onClose={handleClose}
			title={dialogTitle}
			bottomActions={
				<>
					<StyledButtonText size="medium" onClick={handleClose}>
						Cancel
					</StyledButtonText>
					<StyledButtonContained
						colortype="primary"
						variant="contained"
						size="medium"
						onClick={handleAddClick}>
						Add
					</StyledButtonContained>
				</>
			}>
			<StyledDialogContent>
				{showPasteOption && copiedNodeData && (
					<>
						<PasteContainer>
							<OptionHeader>Paste your copied item</OptionHeader>
							<PastedNodeBox>
								<NodeText>{copiedNodeData.displayTitle}</NodeText>{' '}
								<TypeText>{copiedNodeData.type}</TypeText>
								{copiedNodeData.children?.map((child, index) => (
									<div key={index}>
										– <ChildText>{child.displayTitle} </ChildText>
										<TypeText>{child.type}</TypeText>
									</div>
								))}
							</PastedNodeBox>
							<PasteButton onClick={onPasteItem} variant="contained" color="primary">
								Paste {copiedNodeData.displayTitle}
							</PasteButton>
						</PasteContainer>
						<Divider>Or</Divider>
						<OptionHeader>Create a new item</OptionHeader>
					</>
				)}
				<StyledTextField
					autoFocus
					margin="dense"
					id="item-name"
					label={inputLabel}
					type="text"
					fullWidth
					variant="outlined"
					value={itemName}
					onChange={onChangeName}
					sx={{ ...textFieldStyles }}
				/>
				{enableTypeSelection && (
					<StyledTextField
						select
						label="Select the Type"
						fullWidth
						margin="normal"
						variant="outlined"
						value={itemType}
						onChange={e => {
							setItemType(e.target.value as NodeType);
							setNodeOptions([]);
						}}
						inputProps={{
							'aria-label': 'Select Option',
							MenuProps: {
								MenuListProps: {
									sx: {
										backgroundColor: '#121212',
									},
								},
							},
						}}
						sx={{
							...selectStyles,
							...textFieldStyles,
						}}>
						{filteredTypes.map(option => (
							<StyledMenuItem key={option.key} value={option.value}>
								{option.label}
							</StyledMenuItem>
						))}
					</StyledTextField>
				)}
				{itemType === 'select' && (
					<>
						<Stack direction="row" spacing={1} alignItems="center">
							<StyledTextField
								fullWidth
								margin="dense"
								id="new-option"
								label="Enter option for the Picker"
								type="text"
								variant="outlined"
								value={newOption}
								onChange={handleNewOptionChange}
								onKeyDown={handleKeyDown}
								sx={{ ...textFieldStyles }}
							/>
							<IconButton onClick={handleAddOption} aria-label="add option">
								<AddCircleOutline style={{ color: '#fff' }} />
							</IconButton>
						</Stack>
						{nodeOptions.map((option, index) => (
							<Stack direction="row" spacing={1} alignItems="center" key={index}>
								<StyledTextField
									fullWidth
									margin="dense"
									variant="outlined"
									value={option}
									InputProps={{
										readOnly: true,
										disableUnderline: true,
									}}
									sx={{ ...textFieldStyles }}
								/>
								<IconButton
									onClick={() => handleRemoveOption(option)}
									aria-label="remove option">
									<RemoveCircleOutline />
								</IconButton>
							</Stack>
						))}
					</>
				)}
				{templates.length > 0 && <h5>Or select a template:</h5>}
				<div>
					{templates.map((template, index) => (
						<TemplateChip
							label={
								<ChipContent>
									<TemplateTitle>
										{template.displayTitle.length > 30 ? (
											<Tooltip title={template.displayTitle} placement="top">
												<span>{template.displayTitle.slice(0, 30) + '...'}</span>
											</Tooltip>
										) : (
											<>{template.displayTitle}</>
										)}
									</TemplateTitle>
									<TemplateType>{template.type}</TemplateType>
								</ChipContent>
							}
							sx={{ height: 'auto' }}
							key={index}
							color="primary"
							onClick={() => handleTemplateClick(template)}
						/>
					))}
				</div>
			</StyledDialogContent>
		</Dialog>
	);
};
