import { Button, IconButton, TextField } from '@mui/material';
import { FC, PropsWithChildren, useState } from 'react';
import { AddCircleOutline, RemoveCircleOutline } from '@mui/icons-material';
import { FormNode } from '../../../../screen-components/ProjectUtilityFormV2/utils/types';
import { Dialog } from '../../../Dialog';
import { StyledStack } from './EditNodeOptionsDialog.styles';
import theme from '../../../../../styles/theme';
import { StyledTextField } from '../../../StyledTextField';

interface EditNodeOptionsDialogProps {
	isOpen: boolean;
	onClose: () => void;
	onSaveItem: (options: string[]) => void;
	node: FormNode;
}

const EditNodeOptionsDialog: FC<PropsWithChildren<EditNodeOptionsDialogProps>> = ({
	isOpen,
	node,
	onClose,
	onSaveItem,
}) => {
	const [nodeOptions, setNodeOptions] = useState<string[]>(node.options || []);
	const [newOption, setNewOption] = useState('');

	const handleSaveClick = () => {
		onSaveItem(nodeOptions);
		setNewOption('');
		onClose();
	};

	const handleKeyDown = (event: React.KeyboardEvent) => {
		if (event.key === 'Enter') {
			event.preventDefault(); // Prevent form submission on 'enter'
			handleAddOption();
		}
	};

	const handleRemoveOption = (optionToRemove: string) => {
		setNodeOptions(nodeOptions.filter(option => option !== optionToRemove));
	};

	const handleAddOption = () => {
		if (newOption.trim() !== '') {
			setNodeOptions(prev => [...prev, newOption.trim()]);
			setNewOption('');
		}
	};

	const handleNewOptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setNewOption(event.target.value);
	};

	const handleClose = () => {
		setNewOption('');
		onClose();
	};

	return (
		<Dialog
			open={isOpen}
			onClose={handleClose}
			title="Edit picker options"
			bottomActions={
				<>
					<Button onClick={handleClose}>Cancel</Button>
					<Button onClick={handleSaveClick}>Save</Button>
				</>
			}>
			<StyledStack gap="16px">
				<StyledTextField
					onChange={handleNewOptionChange}
					onKeyDown={handleKeyDown}
					value={newOption}
					label="Enter option for the Picker"
					fullWidth
				/>
				<IconButton onClick={handleAddOption} aria-label="add option">
					<AddCircleOutline color="primary" />
				</IconButton>
			</StyledStack>
			{nodeOptions.map((option, index) => (
				<StyledStack key={index} paddingLeft={2}>
					<TextField
						fullWidth
						margin="dense"
						value={option}
						InputProps={{
							readOnly: true,
							disableUnderline: true,
							style: { color: theme.palette.newText.primary },
						}}
						variant="standard"
					/>
					<IconButton
						onClick={() => handleRemoveOption(option)}
						aria-label="remove option">
						<RemoveCircleOutline color="primary" />
					</IconButton>
				</StyledStack>
			))}
		</Dialog>
	);
};

export default EditNodeOptionsDialog;
