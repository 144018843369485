import { useMemo } from 'react';
import BasicIconButton from '../../reusable-components/BasicIconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import { Organization } from '../../../types';
import { TableCellLink } from '../../styled-components/styledReactTable';
import { CustomColumn } from '../../reusable-components/DataTable/CustomColumn';

type useOrganizationPageColumnsProps = {
	onDeleteOrganization: (organization: Organization) => void;
	isAdmin: boolean;
};

export const useOrganizationsColumns = (props: useOrganizationPageColumnsProps) => {
	const { onDeleteOrganization, isAdmin } = props;

	const organizationsColumns: Array<CustomColumn<Organization>> = useMemo(() => {
		const columns: Array<CustomColumn<Organization>> = [
			{
				Header: 'Organization',
				id: 'organization',
				Cell: ({ row: { original: organization } }: any) => {
					return (
						<TableCellLink to={`/organizations/${organization.id}`}>
							<span style={{ justifyContent: 'left' }}>{organization.name}</span>
						</TableCellLink>
					);
				},
				accessor: (row: any) => row.organizations,
				width: '40%',
			},
			{
				Header: 'Number of Teams',
				Cell: ({ row: { original: organization } }: any) => {
					return <div>{organization.teamIds?.length || 0}</div>;
				},
				sortType: 'number',
				width: '40%',
				canSort: true,
				align: 'center',
			},
		];

		if (isAdmin) {
			columns.push({
				Header: 'Actions',
				Cell: ({ row: { original: organization } }: any) => {
					if (organization.teamIds?.length > 0) return null;

					return (
						<BasicIconButton
							title="Delete organization"
							onClick={() => onDeleteOrganization(organization)}>
							<DeleteIcon />
						</BasicIconButton>
					);
				},
				width: '20%',
				align: 'center',
			});
		}
		return columns;
	}, [onDeleteOrganization, isAdmin]);

	return {
		organizationsColumns,
	};
};
