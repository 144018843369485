import { IconButton, InputBase } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import React, { CSSProperties } from 'react';
import styled from 'styled-components';
import theme from '../../../styles/theme';

export default function ProjectsSearchbar({
	query,
	handleInputChange,
	clearQuery,
	style,
}: {
	query: string;
	handleInputChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
	clearQuery: () => void;
	style?: CSSProperties;
}) {
	return (
		<StyledSearch>
			<InputBase
				placeholder="Search..."
				onChange={handleInputChange}
				value={query}
				className="searchbar-input"
				inputProps={{
					id: 'searchbar-input',
					style: { color: theme.palette.primary.main },
				}}
				style={{ flex: 1 }}
			/>
			{query ? (
				<IconButton
					sx={{
						color: theme.palette.primary.main,
						'&:hover': {
							color: 'white',
						},
					}}
					onClick={() => clearQuery()}>
					<ClearIcon />
				</IconButton>
			) : null}
		</StyledSearch>
	);
}

export const StyledSearch = styled.div`
	display: flex;
	gap: 5px;
	width: 100px;
	min-width: 230px;
	border-radius: 15px;
	border: 1px solid #ffb310;
	padding: 10px;
	height: 44px;
	align-items: center;
`;
