import React, { useCallback, useContext, useEffect, useState } from 'react';
import { PhotoV2 } from '../../../../../../utils/types';
import { DndContext, DragEndEvent, closestCenter } from '@dnd-kit/core';
import { SortableContext, arrayMove } from '@dnd-kit/sortable';
import { GalleryContainer } from '../../FormFillV2.styles';
import { FormV2Context } from '../../../../../../context/FormV2Context.types';
import SortablePhoto from './SortablePhoto';

interface DraggableGalleryProps {
	photos: PhotoV2[];
	openGallery: (index: number) => void;
	nodeId: string;
}

const DraggableGallery: React.FC<React.PropsWithChildren<DraggableGalleryProps>> = ({
	photos,
	openGallery,
	nodeId,
}) => {
	const { updateValue } = useContext(FormV2Context);
	const [localPhotos, setLocalPhotos] = useState<PhotoV2[]>(photos);

	useEffect(() => {
		setLocalPhotos(photos);
	}, [photos]);

	const handleDragEnd = useCallback(
		(event: DragEndEvent) => {
			const { active, over } = event;

			if (over && active.id !== over.id) {
				const oldIndex = localPhotos.findIndex(item => item.name === active.id);
				const newIndex = localPhotos.findIndex(item => item.name === over.id);
				const newPhotos = arrayMove(localPhotos, oldIndex, newIndex);

				setLocalPhotos(newPhotos);
				updateValue(nodeId, newPhotos).catch(console.error);
			}
		},
		[localPhotos, updateValue, nodeId]
	);

	return (
		<DndContext onDragEnd={handleDragEnd} collisionDetection={closestCenter}>
			<SortableContext items={localPhotos.map(photo => photo.name)}>
				<GalleryContainer>
					{localPhotos.map((photo, index) => (
						<SortablePhoto
							key={photo.name + index}
							photo={photo}
							index={index}
							openGallery={openGallery}
						/>
					))}
				</GalleryContainer>
			</SortableContext>
		</DndContext>
	);
};

export default DraggableGallery;
