import { UserRow } from './styled-components';
import { SharedTeam } from '../../types';
import { Avatar, ListItemText } from '@mui/material';
import styled from 'styled-components';
import PersonIcon from '@mui/icons-material/Person';
import theme from '../../../../../../../styles/theme';
import { Fragment } from 'react/jsx-runtime';

interface SharedTeamsProps {
	sharedTeams: SharedTeam[];
}

export default function SharedTeams({ sharedTeams }: SharedTeamsProps) {
	return (
		<Container>
			{sharedTeams.length < 1 ? (
				<h2>No shared teams yet...</h2>
			) : (
				sharedTeams.map((sharedTeam, teamIdx) => {
					const teamMembers = sharedTeam.users;
					return (
						<Fragment key={teamIdx}>
							<TeamHeading
								primary={sharedTeam.name}
								secondary={sharedTeam.orgName}
								key={sharedTeam.id + teamIdx}
							/>
							<TeamMemberContainer key={sharedTeam.id + teamIdx + 'members'}>
								{teamMembers.length < 1 ? (
									<h2>No team members...</h2>
								) : (
									teamMembers.map((teamMember, memberIdx) => (
										<UserRow key={teamMember.id + memberIdx}>
											<Avatar>
												<PersonIcon />
											</Avatar>

											<ListItemText
												primary={teamMember.email}
												secondary={teamMember.userType}
											/>
										</UserRow>
									))
								)}
							</TeamMemberContainer>
						</Fragment>
					);
				})
			)}
		</Container>
	);
}

const Container = styled.div`
	display: flex;
	flex-direction: column;
	background-color: white;
`;

const TeamHeading = styled(ListItemText)`
	margin: 0px;
	padding: 10px;
	border-top: 1px solid ${theme.palette.divider};
	border-bottom: 1px solid ${theme.palette.divider};

	&:first-child {
		border-top: none;
	}
`;

const TeamMemberContainer = styled.div`
	display: flex;
	flex-direction: column;
	padding: 10px;
`;
