import { Link } from 'react-router-dom';
import styled from 'styled-components';

type AppLinkProps = {
	text: string;
	to: string;
	style?: React.CSSProperties;
};

export default function AppLink({ text, to, style = {} }: AppLinkProps) {
	return (
		<StyledLink
			to={to}
			style={{
				...style,
			}}>
			{text}
		</StyledLink>
	);
}

const StyledLink = styled(Link)`
	color: #ffb310;
	text-decoration: none;

	&:hover {
		text-decoration: underline;
	}
`;
