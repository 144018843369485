import { useState } from 'react';

const useAlert = () => {
	const [alertOpen, setAlertOpen] = useState(false);
	const [alertMessage, setAlertMessage] = useState('');

	const showAlert = (message: string) => {
		setAlertMessage(message);
		setAlertOpen(true);
	};

	const hideAlert = () => {
		setAlertOpen(false);
	};

	return { alertOpen, alertMessage, showAlert, hideAlert };
};

export default useAlert;
