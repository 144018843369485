import styled from 'styled-components';
import { Flag, NodeType } from '../../screen-components/ProjectUtilityFormV2/utils/types';
import { getFlagColor } from '../FormNodeWrapper/buttons/FlagButton';
import theme from '../../../styles/theme';

export const WrapperContainer = styled.div<{
	nodeType: NodeType;
	level?: number;
	flag: Flag;
	isSelected: boolean;
}>`
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	padding: ${({ nodeType, level }) =>
		nodeType === 'node' || (level && level > 0) ? '0  0' : '0px'};
	width: 100%;
	border-radius: 10px;
	align-items: flex-start;
	justify-content: flex-start;

	background-color: ${({ nodeType }) =>
		nodeType !== 'placeholder' ? theme.palette.common.black : 'transparent'};

	border: ${({ nodeType, level, flag }) =>
		`1px solid ${getFlagColor(
			flag,
			nodeType === 'node' || level === 0 ? 'grey' : 'transparent'
		)}`};

	box-shadow: ${({ isSelected }) =>
		isSelected ? `${theme.palette.primary.main} 0px 0px 5px 2.5px` : ''};

	@media (max-width: 768px) {
		flex-direction: column;
		align-items: flex-start;
		gap: 10px;
		padding: 10px 0;
	}
`;

export const WrapperTitle = styled.div`
	font-weight: bold;
	color: ${theme.palette.common.white};
	font-size: 14px;
	line-height: 20px;
`;

export const WrapperInfo = styled.div`
	color: ${theme.palette.grey[300]};
	font-size: 12px;
	line-height: 13px;
`;

export const WrapperNode = styled.div`
	flex: 1;
	min-width: 100px;
	@media (max-width: 768px) {
		width: 90%;
	}
`;

export const WrapperIconButton = styled.div`
	width: 40px;
	flex-shrink: 0;
`;

export const FlagWrapper = styled.div`
	display: flex;
	align-items: flex-start;
	min-width: 30px;
	padding: 0;
	flex-shrink: 0;
`;
export const OrderAndTitle = styled.div`
	display: flex;
	align-items: flex-start;
	gap: 10px;
	max-width: 60%;
	flex: 0 1 auto;
	min-width: 0;
	padding-right: 5px;

	@media (max-width: 768px) {
		max-width: 100%;
		width: 100%;
	}
`;

export const ActionButtonsContainer = styled.div`
	display: flex;
	gap: 5px;

	@media (max-width: 768px) {
		width: 100%;
		justify-content: flex-end;
	}
`;
