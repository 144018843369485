import { Row } from 'react-table';
import { ProjectData } from '../../../../../../../types';
import { numberStrToMoney } from '../../../../../../../utils';
import { YellowTextField } from '../../../../../../styled-components/styledReactTable';
import styled from 'styled-components';
import { debounce } from 'lodash';
import { useContext } from 'react';
import { deleteField, doc, updateDoc } from 'firebase/firestore';
import { db } from '../../../../../../../firebase';
import { SnackContext } from '../../../../../../../context/SnackProvider';
import { StatusTableContext } from '../../../../StatusTableProvider';

export default function CostCell({
	row,
	rowEditing,
	costType,
}: {
	row: Row<ProjectData>;
	rowEditing: boolean;
	costType: 'scanningCost' | 'registrationCost' | 'modelingCost';
}) {
	const { setSnackbarProps } = useContext(SnackContext);
	const { setStatusProjects } = useContext(StatusTableContext);

	const projectId = row.original.id as string;
	const cost = row.original[costType];
	const projDocRef = doc(db, `projects/${projectId}`);

	const handleChange = debounce(async (e: React.ChangeEvent<HTMLInputElement>) => {
		const numberString = e.target.value;

		if (numberString) {
			const roundedCost = Number(numberString).toFixed(2);
			await updateDoc(projDocRef, {
				[costType]: Number(roundedCost),
			});
		} else {
			await updateDoc(projDocRef, {
				[costType]: deleteField(),
			});
		}
		setStatusProjects(prev => {
			return prev.map(proj => {
				if (proj.id === projectId) {
					return {
						...proj,
						[costType]: numberString
							? Number(Number(numberString).toFixed(2))
							: undefined,
					};
				} else return proj;
			});
		});

		setSnackbarProps({
			open: true,
			severity: 'success',
			message: 'Changes saved!',
		});
	}, 1000);

	return !rowEditing ? (
		<span>{cost ? numberStrToMoney(cost.toFixed(2)) : '-'}</span>
	) : (
		<CostInput
			onClick={e => e.stopPropagation()}
			type="number"
			defaultValue={cost}
			onChange={handleChange}
			isProjectPage={true}
		/>
	);
}

const CostInput = styled(YellowTextField)`
	input {
		::-webkit-outer-spin-button,
		::-webkit-inner-spin-button {
			display: none;
		}
		-moz-appearance: textfield;
	}
`;
