import { Outlet } from 'react-router-dom';
import { TemplatesProvider } from '../context/TemplatesProvider';

export default function TemplatesRoute() {
	return (
		<TemplatesProvider>
			<Outlet />
		</TemplatesProvider>
	);
}
