export interface FormIndexesState {
	categoryIdx: number;
	itemIdx: number;
	photoIdx: number;
}

export interface FormIndexesInput extends Partial<FormIndexesState> {}

export const initialState: FormIndexesState = {
	categoryIdx: 0,
	itemIdx: 0,
	photoIdx: 0,
};

export const reducer = (currentState: FormIndexesState, newState: FormIndexesInput) => {
	const updatedState: FormIndexesState = {
		...currentState,
		...newState,
	};
	return updatedState;
};
