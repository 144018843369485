import { Box } from '@mui/material';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { FC } from 'react';
import { ListItemMenu } from '../ListItemMenu';
import { ListItemMenuPropsItem } from '../ListItemMenu/ListItemMenu';
import Loading from '../../../screen-components/ProjectUtilityFormV2/components/Loading/Loading';
import RequiredProgress from '../../RequiredProgress';

type ListItemProps = {
	title: string;
	content?: {
		key: string;
		value: string;
	}[];
	url?: string;
	menuItems?: ListItemMenuPropsItem[];
	loading?: boolean;
	disabled?: boolean;
	status?: string;
	child?: React.ReactNode;
	requiredFields?: string[];
	completedFields?: string[];
};

const ListItem: FC<React.PropsWithChildren<ListItemProps>> = ({
	child,
	content,
	disabled,
	loading,
	menuItems,
	status,
	title,
	url,
	requiredFields,
	completedFields,
}) => {
	const navigate = useNavigate();

	const handleClick = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
		e.preventDefault();
		if (url) navigate(url);
	};

	const contentComponent = () => (
		<Container aria-disabled={disabled}>
			<Header>
				<Title
					style={{
						color: status === 'completed' ? '#ffb310' : 'white',
					}}>
					{title}
				</Title>

				{!disabled && !loading && menuItems && menuItems.length > 0 && (
					<ListItemMenu items={menuItems} />
				)}
			</Header>

			<Content>
				{requiredFields && requiredFields.length > 0 && completedFields && (
					<RequiredProgress
						completedFields={completedFields.length}
						requiredFields={requiredFields.length}
					/>
				)}
				<ContentList>
					{content?.map((item, index) => (
						<Detail key={index}>
							<strong>{`${item.key}: `}</strong>
							{item.value}
						</Detail>
					))}
				</ContentList>
			</Content>

			{child}
			{loading && (
				<LoadingContainer>
					<Loading size={20} />
				</LoadingContainer>
			)}
		</Container>
	);

	if (disabled) return <Disabled>{contentComponent()}</Disabled>;

	return (
		<ItemLink href={url} onClick={handleClick}>
			{contentComponent()}
		</ItemLink>
	);
};

const ItemLink = styled.a`
	text-decoration: none;
`;

const Disabled = styled(Box)`
	opacity: 0.5;
`;

const Container = styled(Box)`
	position: relative;
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
	text-transform: none;
	background-color: #17171b;
	border-radius: 20px;
	color: white;
	width: 100%;
	padding: 20px;
	flex-direction: column;

	box-shadow: 1px 3px 4px rgba(89, 89, 89, 0.2), inset 1px 2px 10px rgba(0, 0, 0, 0.2);

	&:hover {
		background-color: ${props => (props['aria-disabled'] ? '#17171b' : '#ffb31050')};
	}

	@media screen and (max-width: 424px) {
		flex-direction: column;
		align-items: flex-start;
	}
`;

const Header = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
`;

const Title = styled.h3`
	margin: 0;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	font-weight: 400;
`;

const Detail = styled.span`
	font-size: 12px;
	text-align: start;
	font-weight: 400;
`;

const LoadingContainer = styled(Box)`
	position: absolute;
	top: 20px;
	right: 20px;
	width: 24px;
	height: 24px;
`;

const Content = styled.div`
	display: flex;
	gap: 15px;
	align-items: center;
	width: 100%;
	padding-left: 20px;
	margin-top: 2px;
`;

const ContentList = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
`;

export default ListItem;
