import { useContext, useEffect, useMemo, useState } from 'react';
import { ProjectData } from '../../../../../types';
import { ProjectRole, projectRoleTitleMap } from '../../../../../types/project-roles';
import { AuthContext } from '../../../../../context/AuthProvider';
import { FieldLabel } from '../../../../../screens/ProjectInfo';
import ProjectRoleAutocomplete from '../../../../reusable-components/ProjectRoleAutocomplete';
import { ProjectContext } from '../../../../../context/ProjectProvider';
import { isEqual } from 'lodash';

interface UserFieldProps {
	field: ProjectRole;
	isActive: boolean;
	setPartialFormState: React.Dispatch<React.SetStateAction<Partial<ProjectData>>>;
}

export default function UserField({
	field,
	isActive,
	setPartialFormState,
}: UserFieldProps) {
	const { user } = useContext(AuthContext);
	const { project } = useContext(ProjectContext);

	const projRoles = useMemo(() => project?.[field], [field, project]);
	const [roles, setRoles] = useState(projRoles);

	useEffect(() => {
		if (!isEqual(projRoles, roles)) {
			setPartialFormState(prev => ({
				...prev,
				[field]: roles,
			}));
		}
	}, [field, projRoles, roles, setPartialFormState]);

	useEffect(() => {
		if (isActive) setRoles(projRoles);
	}, [isActive, projRoles]);

	const handleChange = (newValue: ProjectData[ProjectRole]) => {
		if (field === 'accountManager') {
			setRoles(newValue && !Array.isArray(newValue) ? newValue : undefined);
		} else {
			setRoles(newValue && Array.isArray(newValue) ? newValue : []);
		}
	};

	return user?.isAdmin ? (
		<div className="infoRow">
			<FieldLabel label={projectRoleTitleMap[field]} />

			{project && user?.isAdmin && isActive ? (
				<ProjectRoleAutocomplete
					role={field}
					inputValue={roles}
					handleChange={handleChange}
					project={project}
				/>
			) : (
				<p className="fieldInfoParagraph">
					{Array.isArray(projRoles)
						? projRoles.map(userObj => `${userObj.name} (${userObj.email})`).join('\n') ||
						  ''
						: projRoles
						? `${projRoles.name} (${projRoles.email})`
						: ''}
				</p>
			)}
		</div>
	) : null;
}
