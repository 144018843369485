import { useState, useEffect, useContext } from 'react';
import {
	Autocomplete,
	Button,
	Dialog,
	DialogActions,
	DialogTitle,
	TextField,
} from '@mui/material';
import { PendingUser, SharedUser } from '../types';
import styled from 'styled-components';
import { SnackContext } from '../../../../../../context/SnackProvider';
import { sendProjectEmail } from '../../../../../../firebase';
import { ProjectContext } from '../../../../../../context/ProjectProvider';
import { FullWidthDialogContent } from '../../../../../styled-components/dialog';
import { YellowIconButton } from '../../../../../styled-components/buttons';
import { AuthContext } from '../../../../../../context/AuthProvider';
import InfoIcon from '../../../../../reusable-components/InfoIcon/InfoIcon';
import { ForwardToInboxOutlined } from '@mui/icons-material';

export default function ProjectEmailButton({
	teamMembers,
	pendingUsers,
}: {
	teamMembers: SharedUser[];
	pendingUsers: PendingUser[];
}) {
	const { setSnackbarProps } = useContext(SnackContext);
	const { user } = useContext(AuthContext);
	const { project, cachedEmails, projEmailOptions } = useContext(ProjectContext);

	const projectName = project?.address || project?.name || 'Unnamed Project';
	const defaultSubject = `Robotic Imaging Message - ${projectName}`;

	const [open, setOpen] = useState(false);
	const [emailOptions, setEmailOptions] = useState<string[]>([]);
	const [sendingEmail, setSendingEmail] = useState(false);

	const [recipients, setRecipients] = useState<readonly string[]>([]);
	const [recipientsInput, setRecipientsInput] = useState('');

	const [cc, setCC] = useState<readonly string[]>([]);
	const [ccInput, setCCInput] = useState('');

	const [bcc, setBCC] = useState<readonly string[]>([]);
	const [bccInput, setBCCInput] = useState('');

	const [subject, setSubject] = useState<string>(defaultSubject);
	const [message, setMessage] = useState<string>('');

	const [recipientsError, setRecipientsError] = useState('');
	const [ccError, setCCError] = useState('');
	const [bccError, setBCCError] = useState('');

	useEffect(() => {
		setEmailOptions(() => {
			let options = [
				...projEmailOptions,
				...teamMembers.map(teamMember => teamMember.email),
				...pendingUsers.map(pendingUser => pendingUser.email),
			];
			if (user?.isAdmin) options = [...options, ...cachedEmails];
			return Array.from(new Set(options));
		});
	}, [user?.isAdmin, teamMembers, pendingUsers, projEmailOptions, cachedEmails]);

	// Reset all useStates when opening dialog
	const handleOpen = (event: any) => {
		event.stopPropagation();
		setOpen(true);

		setRecipients([]);
		setRecipientsInput('');

		setCC([]);
		setCCInput('');

		setBCC([]);
		setBCCInput('');

		setSubject(defaultSubject);
		setMessage('');
	};

	// Disable dialog close while email is being sent
	const handleClose = () => {
		if (!sendingEmail) {
			setOpen(false);
		}
	};

	const handleSend = async () => {
		if (recipientsError || ccError || bccError) {
			setSnackbarProps({
				open: true,
				severity: 'error',
				message: 'Cannot send message with invalid emails',
			});
		} else {
			setSendingEmail(true);
			setSnackbarProps({
				open: true,
				severity: 'warning',
				message: 'Sending email...',
				hideDuration: null,
			});

			await sendProjectEmail({
				projectId: project?.id,
				recipients: recipients,
				cc: cc,
				bcc: bcc,
				subject: subject || defaultSubject,
				text: message,
				html: `<p>${message.replace(/\n/g, '<br/>')}</p>`,
			});

			setSendingEmail(false);
			setOpen(false);
			setSnackbarProps({
				open: true,
				severity: 'success',
				message: 'Email successfully sent!',
			});
		}
	};

	// useEffects for field errors
	useEffect(() => {
		if (recipients.length) {
			const idx = recipients.findIndex(email => !email.includes('@'));
			if (idx > -1) setRecipientsError(`${recipients[idx]} is not a valid email`);
		} else setRecipientsError('');
	}, [recipients]);
	useEffect(() => {
		if (cc.length) {
			const idx = cc.findIndex(email => !email.includes('@'));
			if (idx > -1) setCCError(`${cc[idx]} is not a valid email`);
		} else setCCError('');
	}, [cc]);
	useEffect(() => {
		if (bcc.length) {
			const idx = bcc.findIndex(email => !email.includes('@'));
			if (idx > -1) setBCCError(`${bcc[idx]} is not a valid email`);
		} else setBCCError('');
	}, [bcc]);

	return (
		<>
			<NavBarButton size="small" onClick={handleOpen} title="Send project email">
				<ForwardToInboxOutlined />
			</NavBarButton>

			<Dialog
				open={open}
				onClose={handleClose}
				onClick={event => event.stopPropagation()}>
				<StyledDialogTitle>
					<span>Send Project Email</span>

					<InfoIcon
						title={'What is a project email?'}
						message={
							"Project emails function just like normal emails, except users shared on the same project and Robotic Imaging emails are provided as options to speed up email creation. \n \nProject emails will also append the current project's link and public link at the end of the message, allowing recipients to quickly navigate to the project being discussed."
						}
					/>
				</StyledDialogTitle>

				<FullWidthDialogContent>
					<Autocomplete
						multiple
						freeSolo
						value={Array.from(recipients)}
						onChange={(e, value) => setRecipients(value)}
						inputValue={recipientsInput}
						onInputChange={(e, value) => setRecipientsInput(value)}
						onBlur={() => {
							if (recipientsInput) {
								setRecipients(prev => [...prev, recipientsInput]);
								setRecipientsInput('');
							}
						}}
						options={emailOptions}
						disabled={sendingEmail}
						renderInput={props => (
							<TextField
								{...props}
								fullWidth
								label="Recipients"
								error={!!recipientsError}
								helperText={recipientsError}
							/>
						)}
					/>

					<Autocomplete
						multiple
						freeSolo
						value={Array.from(cc)}
						onChange={(e, value) => setCC(value)}
						inputValue={ccInput}
						onInputChange={(e, value) => setCCInput(value)}
						onBlur={() => {
							if (ccInput) {
								setCC(prev => [...prev, ccInput]);
								setCCInput('');
							}
						}}
						options={emailOptions}
						disabled={sendingEmail}
						renderInput={props => (
							<TextField
								{...props}
								fullWidth
								label="Cc"
								error={!!ccError}
								helperText={ccError}
							/>
						)}
					/>

					<Autocomplete
						multiple
						freeSolo
						value={Array.from(bcc)}
						onChange={(e, value) => setBCC(value)}
						inputValue={bccInput}
						onInputChange={(e, value) => setBCCInput(value)}
						onBlur={() => {
							if (bccInput) {
								setBCC(prev => [...prev, bccInput]);
								setBCCInput('');
							}
						}}
						options={emailOptions}
						disabled={sendingEmail}
						renderInput={props => (
							<TextField
								{...props}
								fullWidth
								label="Bcc"
								error={!!bccError}
								helperText={bccError}
							/>
						)}
					/>

					<TextField
						value={subject}
						onChange={e => setSubject(e.target.value)}
						disabled={sendingEmail}
						label="Subject"
					/>

					<TextField
						multiline
						minRows={2}
						maxRows={6}
						value={message}
						onChange={e => setMessage(e.target.value)}
						disabled={sendingEmail}
						label="Message"
					/>
				</FullWidthDialogContent>

				<DialogActions>
					{!sendingEmail ? (
						<>
							<Button onClick={handleClose}>Cancel</Button>
							{recipients.length && message ? (
								<Button onClick={handleSend}>Send Email</Button>
							) : null}
						</>
					) : (
						<DialogActionSpacer />
					)}
				</DialogActions>
			</Dialog>
		</>
	);
}

const NavBarButton = styled(YellowIconButton)`
	padding: 8px;
	margin-top: -8px;
	margin-bottom: -8px;
`;

const StyledDialogTitle = styled(DialogTitle)`
	display: flex;
	align-items: center;
	justify-content: space-between;
`;

const DialogActionSpacer = styled.div`
	height: 36.5px;
`;
