import { IconButton, Menu, MenuItem } from '@mui/material';
import React, { useContext, useState } from 'react';
import { FormV2Context } from '../../../../context/FormV2Context.types';
import { DropdownContainer, ToolsButton } from './ToolsDropdown.styles';
import CreateTemplateDialog from '../../../Dialogs/CreateTemplateDialog/CreateTemplateDialog';
import { Link, useNavigate } from 'react-router-dom';
import useXLSX from '../../../../hooks/useXLSX';
import UnarchiveDialog from '../../../Dialogs/UnarchiveDialog/UnarchiveDialog';
import UnarchivePhotosDialog from '../../../Dialogs/UnarchivePhotosDialog/UnarchivePhotosDialog';
import { ProjectContext } from '../../../../../../../context/ProjectProvider';
import useDownloadPhotos from './useDownloadPhotos';

/**
 * @returns a dropdown menu of Form Tools:
 *
 * • Change Form Name
 * • Save Form as Template
 * • Submit Form
 * • Reorder form items
 * • Download xlsx file
 * • Unarchive items
 *
 * This menu is rendered in the FormV2Header component
 */
const ToolDropdown = () => {
	const navigate = useNavigate();
	const { setIsSubmitting, submitForm, submitLetterForm, utilityForm, fetchNodesAsTree } =
		useContext(FormV2Context);
	const { project } = useContext(ProjectContext);
	const downloadXLSX = useXLSX();
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const [isCreateTemplateDialogOpen, setIsCreateTemplateDialogOpen] = useState(false);
	const [isUnarchiveDialogOpen, setIsUnarchiveDialogOpen] = useState(false);
	const [isUnarchivePhotosDialogOpen, setIsUnarchivePhotosDialogOpen] = useState(false);
	const { downloadPhotos, safeFileName } = useDownloadPhotos();

	const open = Boolean(anchorEl);
	const handleClick = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = (action: () => void) => {
		setAnchorEl(null);
		action();
	};

	const actions = {
		saveFormAsTemplate: () => {
			setIsCreateTemplateDialogOpen(true);
		},
		submit: () => {
			setIsSubmitting(true);
			submitForm();
		},
		submitLetter: () => {
			setIsSubmitting(true);
			submitLetterForm();
		},
		reorderFormItems: () => {
			if (utilityForm)
				navigate(
					`/projects/${utilityForm.projectId}/capture-form-v2/${utilityForm.id}/reorder`
				);
		},
		downloadXLSX: async () => {
			if (utilityForm) {
				const nodes = await fetchNodesAsTree();
				if (!nodes) return;
				downloadXLSX(project?.storeNumber, utilityForm.formName, project?.address, nodes);
			}
		},
		unarchiveItems: async () => {
			if (utilityForm) {
				setIsUnarchiveDialogOpen(true);
			}
		},
		unarchivePhotos: async () => {
			if (utilityForm) {
				setIsUnarchivePhotosDialogOpen(true);
			}
		},
		downloadPhotos: async () => {
			if (utilityForm) {
				downloadPhotos(
					utilityForm.id,
					utilityForm.projectId,
					safeFileName(utilityForm.formName)
				);
			}
		},
	};

	const options = [
		{ title: 'Save Form as Template', action: actions.saveFormAsTemplate },
		{ title: 'Submit Arch D Form', action: actions.submit },
		{ title: 'Submit Letter Form', action: actions.submitLetter },
		{ title: 'Reorder form items', action: actions.reorderFormItems },
		{ title: 'Download xlsx file', action: actions.downloadXLSX },
		{ title: 'Unarchive items', action: actions.unarchiveItems },
		{ title: 'Unarchive photos', action: actions.unarchivePhotos },
		{ title: 'Download all form photos', action: actions.downloadPhotos },
	];

	return (
		<DropdownContainer>
			<IconButton
				id="toolsButton"
				aria-controls={open ? 'menu' : undefined}
				aria-haspopup="true"
				aria-expanded={open ? 'true' : undefined}
				onClick={handleClick}>
				<ToolsButton open={open} />
			</IconButton>
			<Menu
				id="menu"
				aria-labelledby="toolsButton"
				anchorEl={anchorEl}
				open={open}
				onClose={() => handleClose(() => {})}>
				{options.map((option, index) => (
					<div key={index}>
						{option.title === 'Reorder form items' ? (
							<Link
								style={{ textDecoration: 'none', color: 'black' }}
								to={`/projects/${utilityForm?.projectId}/capture-form-v2/${utilityForm?.id}/reorder`}>
								<MenuItem key={index}>{option.title}</MenuItem>
							</Link>
						) : (
							<MenuItem
								onClick={() => handleClose(option.action)}
								disabled={!!utilityForm?.locked && [1, 2].includes(index)}>
								{option.title}
							</MenuItem>
						)}
					</div>
				))}
			</Menu>
			<CreateTemplateDialog
				open={isCreateTemplateDialogOpen}
				onClose={() => setIsCreateTemplateDialogOpen(false)}
			/>
			<UnarchiveDialog
				open={isUnarchiveDialogOpen}
				onClose={() => setIsUnarchiveDialogOpen(false)}
				formId={utilityForm?.id || ''}
				projectId={utilityForm?.projectId || ''}
			/>
			<UnarchivePhotosDialog
				open={isUnarchivePhotosDialogOpen}
				onClose={() => setIsUnarchivePhotosDialogOpen(false)}
				formId={utilityForm?.id || ''}
				projectId={utilityForm?.projectId || ''}
			/>
		</DropdownContainer>
	);
};

export default ToolDropdown;
