import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const LayoutLink = styled(Link)`
	text-decoration: none;
	color: white;
	transition: 0.2s;
	max-width: 50%;
	line-height: normal;

	> * {
		margin: 0;
		padding: 0;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}

	:hover,
	:focus-visible {
		color: #ffb310;
	}
`;
