import { useContext } from 'react';
import { Link } from 'react-router-dom';
import { YellowIconButton } from '../../../../components/styled-components/buttons';
import MapIcon from './MapIcon.svg';
import { ProjectContext } from '../../../../context/ProjectProvider';

type Props = {
	lat?: number;
	lng?: number;
	newTab?: boolean;
};

export default function MapButton({ lat, lng, newTab = false }: Props) {
	const { setProjectsSelected } = useContext(ProjectContext);

	const linkProps = {
		to: lat && lng ? `/map?lat=${lat}&lng=${lng}` : '/map',
		...(newTab ? { target: '_blank', rel: 'noopener noreferrer' } : {}),
	};

	return (
		<Link {...linkProps}>
			<YellowIconButton
				title="Navigate to map screen"
				sx={{ padding: '8px' }}
				onClick={() => {
					setProjectsSelected([]);
				}}>
				<img src={MapIcon} alt="" width={35} />
			</YellowIconButton>
		</Link>
	);
}
