import { FC } from 'react';
import { MapProvider } from '../../../../../context/MapProvider';
import ProjectMap from './ProjectMap';
import AddContractorForm from './AddContractorForm';

type Props = {
	lat: number;
	lng: number;
};

const ProjectInfoMap: FC<Props> = ({ lat, lng }: Props) => {
	return (
		<MapProvider>
			<ProjectMap lat={lat} lng={lng} />
			<AddContractorForm />
		</MapProvider>
	);
};

export default ProjectInfoMap;
