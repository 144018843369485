import { Button, Chip } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { UploadContext } from './UploadContext';

import PendingIcon from '@mui/icons-material/Pending';
import { UtilityFormDetails } from '../../../screens/ProjectUtilityFormList';
import { useNavigate } from 'react-router-dom';
import { SnackContext } from '../../../context/SnackProvider';
import Spinner from '../../reusable-components/Spinner';

export default function UtilityFormListItem({
	form,
	projectId,
}: {
	form: UtilityFormDetails;
	projectId: string;
}) {
	const { setSnackbarProps } = useContext(SnackContext);
	const { uploadQueueState } = useContext(UploadContext);

	const [formStatus, setFormStatus] = useState(form.status);

	useEffect(() => {
		setFormStatus(prev => {
			if (form.status && prev !== form.status) return form.status;
			else return prev;
		});
	}, [form.status]);

	const navigate = useNavigate();
	const formUrl = `/projects/${projectId}/capture-form/${form.id}`;

	const handleClick = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
		e.preventDefault();
		if (form.editor && !form.canView) {
			setSnackbarProps({
				open: true,
				message: 'Form is currently being edited and can only be viewed by the editor.',
				severity: 'warning',
			});
		} else {
			navigate(formUrl);
		}
	};

	const capitalize = (str: string) => {
		const strArr = str.split(' ');
		strArr.forEach((word, i) => {
			strArr[i] = word.charAt(0).toUpperCase() + word.slice(1);
		});
		return strArr.join(' ');
	};

	return (
		<UtilityFormItemLink href={formUrl} onClick={handleClick}>
			<Container>
				<DetailsContainer>
					<Title>{form.formName || 'Unamed Form'}</Title>
					<Detail>
						<strong>{'Status: '}</strong>
						{capitalize(formStatus)}
					</Detail>

					{form.editor ? (
						<Detail>
							<strong>{'Current Editor: '}</strong>
							{form.editor}
						</Detail>
					) : null}

					<Detail>
						<strong>{'Creator: '}</strong>
						{`${form.creator}`}
					</Detail>

					{form.uploadedDate &&
					form.uploadedDate?.toLocaleDateString() ===
						form.lastUpdated.toLocaleDateString() ? (
						<Detail>
							<strong>{'Uploaded: '}</strong>
							{form.uploadedDate?.toLocaleDateString()},
							{form.uploadedDate?.toLocaleTimeString()}
						</Detail>
					) : form.createdDate &&
					  form.createdDate?.toLocaleDateString() ===
							form.lastUpdated.toLocaleDateString() ? (
						<Detail>
							<strong>{'Created: '}</strong>
							{form.createdDate.toLocaleDateString()},
							{form.createdDate.toLocaleTimeString()}
						</Detail>
					) : (
						<Detail>
							<strong>{'Last Updated: '}</strong>
							{form.lastUpdated?.toLocaleDateString()},
							{form.lastUpdated?.toLocaleTimeString()}
						</Detail>
					)}
				</DetailsContainer>

				{/* Chip renderer */}
				{form.id in uploadQueueState ? (
					Object.keys(uploadQueueState)[0] === form.id ? (
						// Form has file(s) being uploaded
						<StyledChip
							label="Uploading..."
							icon={<Spinner relativeHeight="70%" />}
							color="secondary"
							variant="outlined"
						/>
					) : (
						// Form has file(s) queued for upload
						<StyledChip
							label={`Queued for upload`}
							icon={<PendingIcon />}
							color="secondary"
							variant="outlined"
						/>
					)
				) : formStatus === 'processing' ? (
					<StyledChip
						label="Processing PDF..."
						icon={<Spinner relativeHeight="70%" />}
						color="secondary"
						variant="outlined"
					/>
				) : null}
			</Container>
		</UtilityFormItemLink>
	);
}

const UtilityFormItemLink = styled.a`
	text-decoration: none;
`;

const Container = styled(Button)`
	position: relative;
	display: flex;
	align-items: center;
	justify-content: space-between;

	text-transform: none;
	color: black;
	background-color: #d7d7d7;

	width: 100%;
	padding: 10px;

	@media screen and (max-width: 424px) {
		flex-direction: column;
		align-items: start;

		> .MuiChip-root {
			align-self: end;

			:before {
				content: ' ';
				background-color: black;
				width: calc(100% - 20px);
				height: 1px;
				display: block;
				position: absolute;
				bottom: 47px;
				left: 10px;
			}
		}
	}
`;

const Title = styled.h3`
	margin: 0;
	text-wrap: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
`;

const DetailsContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
`;

const Detail = styled.span`
	padding-left: 20px;
	font-size: 12px;
	text-align: start;
`;

const StyledChip = styled(Chip)`
	padding: 0 10px;
	gap: 10px;

	> .MuiChip-label {
		padding: 0;
	}
`;
