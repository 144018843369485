import { Button, IconButton, InputBase, Menu, MenuItem } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import React, { CSSProperties, useRef, useState } from 'react';
import styled from 'styled-components';
import SearchIcon from '@mui/icons-material/Search';
import AddIcon from '@mui/icons-material/Add';
import { FilterList } from '@mui/icons-material';

export default function TableSearchbar({
	query,
	handleInputChange,
	clearQuery,
	style,
	onAdd,
	name,
	showButton,
	placeholder,
	showFilterButton,
	onFilterSelect,
	toolbar,
}: {
	query: string;
	handleInputChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
	clearQuery: () => void;
	style?: CSSProperties;
	onAdd?: () => void;
	name: string;
	showButton?: boolean;
	placeholder?: string;
	showFilterButton?: boolean; //to show the 'Filter by...' button
	onFilterSelect?: (filterType: string) => void; //behavior when a filter selection is made
	toolbar?: React.ReactNode;
}) {
	const inputRef = useRef<HTMLInputElement>();
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const open = Boolean(anchorEl);

	const handleMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	return (
		<Row>
			<StyledSearch style={style}>
				<IconButton
					sx={{
						color: '#ffb310',
					}}>
					<SearchIcon />
				</IconButton>
				<StyledInput
					placeholder={placeholder ? placeholder : 'Search...'}
					onChange={handleInputChange}
					value={query}
					inputRef={inputRef}
				/>
				{query ? (
					<StyledButton
						onClick={() => {
							clearQuery();
							inputRef.current?.focus();
						}}>
						<ClearIcon />
					</StyledButton>
				) : null}
				{showButton && name && onAdd ? (
					<YellowButton onClick={onAdd}>
						<AddIcon />
						Add {name}
					</YellowButton>
				) : null}
				{showFilterButton && (
					<>
						<StyledButton onClick={handleMenu}>
							<FilterList />
						</StyledButton>
						<Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
							<MenuItem
								onClick={() => {
									onFilterSelect && onFilterSelect('userTags');
									handleClose();
								}}>
								Filter by User Tags
							</MenuItem>
						</Menu>
					</>
				)}
			</StyledSearch>
			{toolbar}
		</Row>
	);
}

const StyledSearch = styled.div`
	display: flex;
	gap: 5px;
	min-width: 230px;
	border-radius: 50px;
	border: 1px solid #ffb310;
	padding-left: 10px;
	padding-right: 2px;
	height: 50px;
	align-items: center;
	justify-content: space-between;
	flex: 1;
	background: linear-gradient(
		180deg,
		rgb(16.52, 4.6, 4.6) 0%,
		rgb(10.53, 10.15, 10.15) 0.01%,
		rgb(11.39, 10.99, 10.99) 0.02%,
		rgb(38.71, 36.47, 36.47) 100%
	);
`;

const YellowButton = styled(Button)`
	background-color: #ffb310;
	color: black;
	flex-shrink: 0;
	border-radius: 50px;
	align-self: right;
	align-items: center;
	height: 46px;
	text-transform: none;

	&:hover {
		background-color: #ffb310;
	}
`;

const StyledInput = styled(InputBase)`
	color: #ffb310;
	flex-grow: 1;
`;

const StyledButton = styled(IconButton)`
	color: #ffb310;
	transition: 0.2s;

	&:hover {
		color: white;
	}
`;

const Row = styled('div')`
	display: flex;
	flex-direction: row;
	align-items: center;
	width: 100%;
	padding: 0 6px;
`;
