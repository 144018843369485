import { useState, useContext, useEffect } from 'react';
import DeliverableDropzone from '../components/DeliverableDropzone';
import { ProjectContext } from '../../../../context/ProjectProvider';
import styled from 'styled-components';
import Carousel from '../../Carousel';
import DeliverableButtonsOverlay from '../components/DeliverableButtonsOverlay';
import { useWindowSize } from '../../../../screens/Welcome';

export default function ProposalBox() {
	const { project } = useContext(ProjectContext);

	const [showProposalFilesDialog, setShowProposalFilesDialog] = useState(false);
	const [validProposalLinks, setValidProposalLinks] = useState<string[]>([]);

	const { width } = useWindowSize();
	const showCarousel = width >= 1024;

	const filesPath = `projects/${project?.id}/`;
	useEffect(() => {
		async function getValidProposalLinks() {
			if (project?.proposal?.length) {
				const urlArr: string[] = [];
				for (const file of project.proposal) {
					if (file.filepath.endsWith('.pdf')) {
						urlArr.push(file.downloadUrl);
					}
				}
				setValidProposalLinks(urlArr);
			}
		}
		getValidProposalLinks();
	}, [filesPath, project?.proposal]);

	return (
		<DeliverableDropzone
			deliverableKey="proposal"
			showingDialog={showProposalFilesDialog}
			setShowingDialog={setShowProposalFilesDialog}
			downloadOnly
			style={{
				margin: '10px auto',
				width: 'calc(90% - 20px)',
				height: 'calc(90% - 20px)',
				maxWidth: '1200px',
				maxHeight: '800px',
			}}>
			{project?.proposal?.length && validProposalLinks.length && showCarousel ? (
				<ProposalContainer id="deliverable-proposal">
					<Carousel lazy>
						{validProposalLinks.map((link: string, i: number) => (
							<ProposalPDFEmbed data={link} type="application/pdf" key={i}>
								<ProposalEmbedError>
									<span>Could not load PDF Viewer:</span>
									<a href={link}>Click to view PDF</a>
								</ProposalEmbedError>
							</ProposalPDFEmbed>
						))}
					</Carousel>
					<DeliverableButtonsOverlay
						deliverableKey="proposal"
						downloadOnly
						setShowingDialog={setShowProposalFilesDialog}
					/>
				</ProposalContainer>
			) : (
				<span>No Proposal PDF's to Display</span>
			)}
		</DeliverableDropzone>
	);
}

const ProposalContainer = styled.div`
	width: 100%;
	height: 100%;
`;

const ProposalPDFEmbed = styled.object`
	width: 100%;
	height: 100%;
`;

const ProposalEmbedError = styled.div`
	display: flex;
	gap: 5px;
	margin: auto;
	width: fit-content;
`;
