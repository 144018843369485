import { TextField, TextFieldProps } from '@mui/material';
import theme from '../../styles/theme';
import { useFormikContext } from 'formik';
import styled from 'styled-components';

export default function FormikTextField(props: TextFieldProps) {
	const { setFieldValue, values } = useFormikContext();
	const handleTextChange = (
		e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
	) => {
		setFieldValue(props.name || '', e.target.value);
	};

	return (
		<StyledFormikTextField
			{...props}
			name={props.name}
			label={props.label}
			onChange={handleTextChange}
			value={(values as any)[props.name || '']}
			variant="outlined"
			color="primary"
			type={props.type}
		/>
	);
}

export const StyledFormikTextField = styled(TextField)`
	.MuiOutlinedInput-root {
		fieldset {
			border-color: ${theme.palette.primary.main};
		}
		&:hover fieldset {
			border-width: 2px;
			border-color: ${theme.palette.primary.main};
		}

		input {
			color: ${theme.palette.primary.main};
		}
	}

	.MuiInputLabel-root {
		color: ${theme.palette.primary.main};
	}

	.MuiFormHelperText-root {
		color: red;
	}

	.MuiOutlinedInput-root.Mui-disabled {
		fieldset {
			color: ${theme.palette.primary.dark};
		}
		input {
			color: ${theme.palette.primary.dark};
			-webkit-text-fill-color: ${theme.palette.primary.dark};
		}
	}

	.MuiInputLabel-root.Mui-disabled {
		color: ${theme.palette.primary.dark};
	}
`;
