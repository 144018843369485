import styled from 'styled-components';
import {
	FormNode,
	PhotoV2,
} from '../../../components/screen-components/ProjectUtilityFormV2/utils/types';
import PanAndZoom from '../../../components/reusable-components/PanAndZoom';
import ModelNumberPreview from './ModelNumberPreview';
import FieldsPreview from './FieldsPreview';

export default function GalleryImg({
	photo,
	photoIndex,
	currentIndex,
	nodeId,
	fields,
}: {
	photo: PhotoV2;
	photoIndex: number;
	currentIndex: number;
	nodeId: string;
	fields: FormNode[];
}) {
	return (
		<Container>
			<PanAndZoom src={photo.url} reset={currentIndex !== photoIndex} />
			<ModelNumberPreview nodeId={nodeId} />
			<FieldsPreview fields={fields} />
		</Container>
	);
}

const Container = styled.div`
	height: 100%;
	width: 100%;
	background-color: #161616;
`;
