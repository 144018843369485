import { ProjectStatus } from '../../../../types';

// Different Phases of Project Tracker
export const steps = [
	{ step: 1, label: 'Proposal Pending' },
	{ step: 2, label: 'On-Site Scheduled' },
	{ step: 3, label: 'Capture Complete' },
	{ step: 4, label: 'Reality Modeling' },
	{ step: 5, label: 'Delivered' },
];

// Labels for each project status
export const statusLabels: { [key in ProjectStatus]: string } = {
	'Proposal Pending': 'Your project has a bid pending',
	'Proposal Sent': 'Your bid has been sent',
	'To Be Scheduled': 'Your on-site scan is to be scheduled',
	Scheduled: 'Your on-site scan is scheduled',
	Capturing: 'Your on-site scan is in progress',
	'On-Site Capture Complete': 'Your on-site scan has been completed',
	Registration: 'Your project is being registered',
	'Ready For Modeling': 'Your project is ready for modeling',
	'Modeling In Progress': 'Your project is being modeled',
	'QA/QC': 'Your project is undergoing QA/QC',
	'Pending Payment / Delivered': 'Your project has been delivered',
	Delivered: 'Your project has been delivered',
	'Pending Payment': 'Your project is pending payment',
	Sample: 'Tracking is not available for your project',
	Template: 'Tracking is not available for your project',
	Archive: 'Tracking is not available for your project',
};
