export const getFileExtension = (filename: string): string => {
	const split = filename.split('.');
	if (split.length > 1) {
		return `.${split.pop()}`;
	} else {
		return '';
	}
};
export const getFileName = (filename: string): string => {
	const split = filename.split('.');
	if (split.length) {
		return split[0];
	} else {
		return '';
	}
};

export const getFlagStyle = (flag?: string): React.CSSProperties => {
	switch (flag) {
		case 'completed':
			return { border: '4px solid mediumseagreen' };
		case 'progress':
			return { border: '4px solid lightskyblue' };
		case 'review':
			return { border: '4px solid orangered' };
		default:
			return { border: 'none' };
	}
};

export const scrollToAndHighlight = (itemId: string) => {
	let item = document.getElementById(itemId);
	item?.scrollIntoView();

	item?.animate(
		[
			// Keyframes
			{ backgroundColor: '#ffb310' },
			{ backgroundColor: 'initial' },
		],
		{
			duration: 2000,
			easing: 'ease-out',
		}
	);

	item = document.getElementById(itemId);
	item?.scrollIntoView();
};
