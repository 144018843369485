import { useContext, useState, useEffect } from 'react';
import { getGeocode, getLatLng } from 'use-places-autocomplete';

import { db, storage } from '../../../../../firebase';
import {
	deleteDoc,
	doc,
	updateDoc,
	getDoc,
	collection,
	setDoc,
} from 'firebase/firestore';
import {
	deleteObject,
	getDownloadURL,
	getMetadata,
	listAll,
	ref,
	uploadBytesResumable,
} from 'firebase/storage';

import { AuthContext } from '../../../../../context/AuthProvider';
import { ProjectContext } from '../../../../../context/ProjectProvider';
import { SnackContext } from '../../../../../context/SnackProvider';

import ProgressBar from '../../../../../components/reusable-components/ProgressBar';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';

import DeleteIcon from '@mui/icons-material/Delete';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import PostAddIcon from '@mui/icons-material/PostAdd';
import EditIcon from '@mui/icons-material/Edit';
import EditOffIcon from '@mui/icons-material/EditOff';
import PublishIcon from '@mui/icons-material/Publish';
import ArchiveIcon from '@mui/icons-material/Archive';

// import './ContractorCard.css';
import { checkPermissions } from '../../../../../utils';
import Spinner from '../../../../../components/reusable-components/Spinner';
import styled from 'styled-components';
import PlacesAutocompleteWrapper from '../../../../../components/reusable-components/PlacesAutocomplete/PlacesAutocompleteWrapper';
import CameraChips, {
	CameraRow,
	ChipContainer,
} from '../../../../../screens/Map/components/CameraChips';
import { Link } from 'react-router-dom';
import { Box } from '@mui/material';
import theme from '../../../../../styles/theme';

const imageWidth = 130;

/**
 * Functional component for displaying an info card when a contractor pin on the Map component is clicked
 * @param catpure Object containing contractor data
 * @param setCMarkers useState updater for cMarkers (contractor markers useState)
 */
export default function ContractorCard({ capture, setCMarkers }: any) {
	const { setContractors } = useContext(ProjectContext);
	const { user } = useContext(AuthContext);
	const { setSnackbarProps } = useContext(SnackContext);

	const [fileLinks, setFileLinks] = useState(capture?.fileLinks);
	const [progress, setProgress] = useState<number | null>(null);
	const [edit, setEdit] = useState(false);

	const concatAddress = capture.Address.replace(/\s+/g, '+');
	const imageApiUrl = `https://maps.googleapis.com/maps/api/staticmap?&markers=color:blue|${concatAddress}|size:&center=${concatAddress}&zoom=18&size=800x800&maptype=satellite&key=${process.env.REACT_APP_firebase_apiKey}`;
	const [imageSrc, setImageSrc] = useState<string | undefined>(undefined);

	useEffect(() => {
		// Adds a delay to fetch static map image so that we don't flood Google's API with requests when we hover over many map markers
		const fetchImg = setTimeout(() => setImageSrc(imageApiUrl), 1000);
		// Cancels timeout if component unmounts
		return () => clearTimeout(fetchImg);
	}, [imageApiUrl]);

	const filesPath = `contractors/${capture.id}/`;

	const reRenderFileList = (files: string[], tempLinks: any[] | null) => {
		// Use setFileLinks to re-render file list
		let links: any[] = [];
		if (tempLinks) {
			setFileLinks(tempLinks);
			links = tempLinks;
		} else {
			// tempLinks not provided, so fetch new links from storage
			files.forEach((file, i) => {
				(async () => {
					const fileRef = ref(storage, `${filesPath}${file}`);
					const metaData = await getMetadata(fileRef);
					const url = await getDownloadURL(fileRef);
					links.push({
						...metaData,
						url,
						path: file,
						idx: i,
					});
					if (links.length === files.length) setFileLinks(links);
				})();
			});
		}
		// Update fileLinks useState so file list re-renders
		updateDoc(doc(db, 'matterport_directory', capture.id), {
			files: files,
		});
		// Update cMarkers
		const marker = {
			...capture,
			files: files,
			fileLinks: links,
		};
		setCMarkers((prev: any[]) => {
			const idx = prev.findIndex(obj => obj.id === capture.id);
			prev.splice(idx, 1, marker);
			// console.log(marker);
			return prev;
		});
	};

	const handleFileUploadChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const uFiles = Array.from(e.target.files || []);
		let filesCompleted = 0;
		setProgress(0);
		// setSnackbarProps({
		//   open: true,
		//   hideDuration: null,
		//   progress: progress,
		// })

		uFiles.forEach(uFile => {
			// Upload file to firebase storage
			const storageRef = ref(storage, filesPath + uFile.name);
			const uploadTask = uploadBytesResumable(storageRef, uFile);
			uploadTask.on(
				'state_changed',
				// Upload progress/status
				snapshot => {
					setProgress(
						(snapshot.bytesTransferred / snapshot.totalBytes) * (100 / uFiles.length)
					);
				},
				// Upload Error
				error => console.log(error),
				// Upload Completion Callback
				() => {
					++filesCompleted;
					// All files uploaded
					if (filesCompleted === uFiles.length) {
						// Get names of all files in storage
						const listRef = ref(storage, filesPath);
						listAll(listRef)
							.then(res => {
								// Re-render file list with uploaded files and reset progress bar
								const files: string[] = [];
								res.items.forEach(item => files.push(item.name));
								reRenderFileList(files, null);
								setProgress(null);
								// Reset input file upload so onChange triggers even if you upload the same files again
								e.target.value = '';
							})
							.catch(error => console.log(error));
					}
				}
			);
		});
	};

	const deleteAllContractorFiles = () => {
		if (
			window.confirm(`Are you sure you want to delete all files for ${capture.Name}?`)
		) {
			(async () => {
				const listRef = ref(storage, filesPath);
				const files = await listAll(listRef);
				files.items.forEach(item => {
					deleteObject(item);
				});
				reRenderFileList([], []);
			})();
		}
	};

	const deleteContractorFile = (i: number) => {
		if (window.confirm(`Are you sure you want to delete ${fileLinks[i].path}?`)) {
			// Clone fileLinks and delete selected file
			const temp = fileLinks.slice();
			temp.splice(i, 1);
			// Delete from storage
			const fileRef = ref(storage, filesPath + fileLinks[i].path);
			deleteObject(fileRef)
				.then(() => {
					const files = temp.map((file: any) => {
						return file.path;
					});
					reRenderFileList(files, temp);
				})
				.catch(error => {
					console.log(error);
				});
		}
	};
	const archiveContractor = async () => {
		const docRef = doc(db, 'matterport_directory', capture.id);
		const docSnap = await getDoc(docRef);

		// Check if contractor doc in db
		if (docSnap.exists()) {
			const archiveRef = doc(collection(db, 'contractor_archive'), capture.id);
			// Copy contractor doc to archive collection
			await setDoc(archiveRef, docSnap.data());

			const archiveDocSnap = await getDoc(archiveRef);
			// Check if archive doc created successfully
			if (archiveDocSnap.exists()) {
				// Delete contractor doc from contractor collection
				deleteDoc(docRef);

				// Update UI
				setContractors(prev => {
					if (prev) {
						const idx = prev.findIndex((obj: any) => obj.id === capture.id);
						const temp = [...prev];
						temp.splice(idx, 1);
						return temp;
					} else return prev;
				});

				setSnackbarProps({
					open: true,
					message: `Successfully archived ${capture.Name}`,
					severity: 'success',
				});
			} else {
				setSnackbarProps({
					open: true,
					message: `Couldn't archive contractor. Please refresh and try again.`,
					severity: 'warning',
				});
			}
		} else {
			setSnackbarProps({
				open: true,
				message: `Couldn't access contractor data. Please refresh the page!`,
				severity: 'warning',
			});
		}
	};

	const openLink = () => {
		try {
			const url = new URL(capture.Website);
			window.open(url);
		} catch {
			window.open('http://' + capture.Website);
		}
	};

	const defaultFields = {
		Name: capture.Name || '',
		Email: capture.Email || '',
		Website: capture.Website || '',
		Address: capture.Address || '',
		Phone: capture.Phone || '',
		Cameras: capture.Cameras || [],
		Notes: capture.Notes || '',
	};
	const [formEdit, setFormEdit] = useState(defaultFields);

	const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
		const value = e.target.value;
		setFormEdit({
			...formEdit,
			[e.target.name]: value,
		});
	};
	const handleClose = () => {
		setFormEdit(defaultFields);
	};
	const handleSubmit = async () => {
		let updatedContractor: any = { ...formEdit };

		if (updatedContractor.Address !== capture.Address) {
			const results = await getGeocode({ address: updatedContractor.Address });
			if (results.length) {
				const { lat, lng } = await getLatLng(results[0]);
				updatedContractor = { ...updatedContractor, lat: lat, lng: lng };
			} else {
				console.error(`No geocode results for ${updatedContractor.Address}`);
			}
		}
		await updateDoc(doc(db, 'matterport_directory', capture.id), updatedContractor);

		setEdit(false);
		setContractors(prev => {
			if (prev) {
				const cList: any[] = [];
				prev.forEach(c => {
					if (c.id !== capture.id) cList.push(c);
					else cList.push({ ...c, ...updatedContractor });
				});
				return cList;
			} else return prev;
		});
	};

	return (
		<StyledCard>
			<Details>
				<StyledCardContent>
					{!edit ? (
						<>
							<Typography component="h5" variant="body1" color="white">
								{capture.Name}
							</Typography>

							<Typography variant="caption" color="newText.primary">
								{capture.Email}
							</Typography>

							<Typography
								variant="caption"
								color="newText.primary"
								onClick={openLink}
								sx={{ cursor: 'pointer', textDecoration: 'underline' }}>
								{capture.Website}
							</Typography>

							<Typography variant="caption" color="newText.primary">
								{capture.Address}
							</Typography>

							<Typography variant="caption" color="newText.primary">
								{capture.Phone}
							</Typography>

							{fileLinks?.length ? (
								<ul style={{ padding: '0px', listStyleType: 'none' }}>
									{fileLinks.map((file: any, i: number) => (
										<li key={i}>
											<DeleteIcon
												tabIndex={0}
												onClick={() => deleteContractorFile(i)}
												sx={{
													...iconStyles,
												}}
											/>
											<Link
												to={file.url}
												target="_blank"
												rel="noopener noreferrer"
												style={{ textDecoration: 'unset' }}>
												<Typography
													variant="caption"
													color="newText.primary"
													sx={{
														textDecoration: 'underline',
														position: 'relative',
														top: '-8px',
													}}>
													{file.path}
												</Typography>
											</Link>
										</li>
									))}
								</ul>
							) : null}

							<CameraChipsContainer>
								<CameraRow>
									<Typography variant="caption" color="newText.primary">
										Cameras:
									</Typography>
									<ChipContainer>
										{capture?.['Cameras']?.length ? (
											capture['Cameras'].map((camera: string) => (
												<div className="chip-viewonly" key={camera}>
													{camera}
												</div>
											))
										) : (
											<Typography variant="caption" color="newText.primary">
												None
											</Typography>
										)}
									</ChipContainer>
								</CameraRow>
							</CameraChipsContainer>

							<Typography
								variant="caption"
								color="newText.primary"
								className="infoCardNotes">
								{capture.Notes}
							</Typography>
						</>
					) : (
						<div className="contractor-edit-container">
							<TextField
								label="Name"
								name="Name"
								defaultValue={capture.Name}
								onChange={handleChange}
								variant="standard"
								sx={{ ...textFieldStyles }}
							/>
							<TextField
								label="Email"
								name="Email"
								defaultValue={capture.Email}
								onChange={handleChange}
								variant="standard"
								sx={{ ...textFieldStyles }}
							/>
							<TextField
								label="Website"
								name="Website"
								defaultValue={capture.Website}
								onChange={handleChange}
								variant="standard"
								sx={{ ...textFieldStyles }}
							/>
							<Box
								sx={{
									...textFieldStyles,
								}}>
								<PlacesAutocompleteWrapper
									formState={formEdit}
									setFormState={
										setFormEdit as React.Dispatch<
											React.SetStateAction<{ [key: string]: any }>
										>
									}
									addressField="Address"
								/>
							</Box>
							<TextField
								label="Phone Number"
								name="Phone"
								defaultValue={capture.Phone}
								onChange={handleChange}
								variant="standard"
								sx={{ ...textFieldStyles }}
							/>
							<CameraChipsContainerEdit>
								<CameraChips
									form={formEdit}
									cameraField="Cameras"
									setForm={setFormEdit}
								/>
							</CameraChipsContainerEdit>
							<TextField
								label="Notes"
								name="Notes"
								defaultValue={capture.Notes}
								multiline
								maxRows={5}
								onChange={handleChange}
								variant="standard"
								sx={{ ...textFieldStyles }}
							/>
						</div>
					)}
				</StyledCardContent>

				<Controls>
					{checkPermissions(user, 'admin') ? (
						<>
							{!edit ? (
								<>
									<IconButton
										aria-label="archive-contractor"
										title="Archive contractor"
										onClick={() => {
											if (
												window.confirm(
													`Are you sure you want to archive ${capture.Name}?`
												)
											)
												archiveContractor();
										}}
										size="large">
										<ArchiveIcon sx={{ ...iconStyles }} />
									</IconButton>
									<IconButton
										aria-label="add-document"
										title="Add file"
										component="label"
										size="large">
										<PostAddIcon sx={{ ...iconStyles }} />
										<input
											type="file"
											id={`${capture.id}-file-upload`}
											onChange={handleFileUploadChange}
											multiple
											hidden
										/>
									</IconButton>
									<IconButton
										aria-label="delete-all-contractor-files"
										title="Delete all contractor files"
										onClick={() => deleteAllContractorFiles()}
										size="large">
										<DeleteForeverIcon sx={{ ...iconStyles }} />
									</IconButton>
								</>
							) : null}
							{edit ? (
								<IconButton
									title="Submit contractor information changes"
									onClick={handleSubmit}
									size="large">
									<PublishIcon sx={{ ...iconStyles }} />
								</IconButton>
							) : null}
							<IconButton
								title={!edit ? 'Edit contractor information' : 'Cancel edit'}
								onClick={() =>
									setEdit(prev => {
										if (prev) handleClose();
										return !prev;
									})
								}
								size="large">
								{!edit ? (
									<EditIcon sx={{ ...iconStyles }} />
								) : (
									<EditOffIcon sx={{ ...iconStyles }} />
								)}
							</IconButton>
							<ProgressBar progress={progress} />
						</>
					) : null}
				</Controls>
			</Details>

			{!edit ? (
				<CardImage image={imageSrc} title="Google Street View image">
					{!imageSrc ? <Spinner relativeWidth="70%" /> : null}
				</CardImage>
			) : null}
		</StyledCard>
	);
}

const Details = styled.div`
	display: flex;
	flex-direction: column;
`;

const CardImage = styled(CardMedia)`
	display: flex;
	align-items: center;
	justify-content: center;
	width: ${imageWidth}px;
`;

const Controls = styled.div`
	display: flex;
	align-items: center;
	margin-left: -12px;
`;

const StyledCard = styled(Card)`
	display: flex;
	background-color: #121212;
	margin-left: -14px;
	margin-right: 2px;
	padding-left: 16px;
	padding-right: 8px;
	padding-bottom: 20px;
	max-height: 210px;
	overflow-y: auto;
	overflow-x: hidden;
`;

const StyledCardContent = styled(CardContent)`
	flex: 1 0 auto;
	padding: 0;
	display: flex;
	flex-direction: column;
	padding-right: 5px;
`;

const textFieldStyles = {
	'.MuiInputBase-root': {
		color: theme.palette.newText.primary,
		fontSize: '0.875rem',
		'&:not(.Mui-focused)': {
			'&:before': {
				borderBottom: `1px solid ${theme.palette.divider}`,
			},
			'&:hover:before': {
				borderBottom: `2px solid ${theme.palette.divider}`,
			},
		},
	},
	'.MuiFormLabel-root:not(.Mui-focused)': {
		color: theme.palette.divider,
		fontSize: '0.875rem',
	},
};
const CameraChipsContainer = styled(Box)`
	.chip-viewonly {
		background-color: transparent;
		color: ${theme.palette.newText.primary};
		border: 1px solid #909090;
		cursor: pointer;
		padding: 5px;
		font-size: 0.875rem;
		transition: 0.3s;
	}

	> div {
		border-bottom: 1px solid #909090;
		margin-bottom: 10px;
	}
`;
const CameraChipsContainerEdit = styled(Box)`
	button {
		background-color: transparent;
		color: ${theme.palette.newText.primary};
		border: 1px solid #909090;
		cursor: pointer;
		padding: 5px;
		font-size: 0.875rem;
		transition: 0.3s;
		&:hover {
			box-shadow: unset;
			opacity: 0.5;
		}

		&:active {
			background-color: transparent;
			color: #909090;
			border: 1px solid #909090;
			cursor: pointer;
			padding: 5px;
			font-size: 0.875rem;
			opacity: 0.5;
		}
	}
	div {
		> div:first-of-type {
			border-bottom: 1px solid #909090;
			span {
				color: ${theme.palette.newText.primary};
			}
			div {
				border-bottom: unset;
				background: transparent;
				padding-left: 0;
				padding-right: 0;
			}
		}
		> div:last-of-type {
			margin-bottom: 10px;
		}
	}
`;

const iconStyles = {
	color: theme.palette.common.black,
	filter: 'invert(100%)',
	cursor: 'pointer',
};
