import { useContext } from 'react';
import { Row } from 'react-table';
import styled from 'styled-components';
import { Lead } from '../../../types';
import { LeadsContext } from '../../LeadsProvider';
import { getEmailDomain } from '../../../../../utils';

export default function LeadsTableRow({
	row,
	rowIdx,
	pageIndex,
	pageSize,
}: {
	row: Row<Lead>;
	rowIdx: number;
	pageIndex: number;
	pageSize: number;
}) {
	const { leadFlags } = useContext(LeadsContext);

	const leadId = row.original.id;
	const company = row.original.company.toLowerCase();
	const emailDomain = getEmailDomain(row.original.email.toLowerCase());

	const isFlagged = leadFlags.some(flag => {
		if (
			(flag.leadId && leadId === flag.leadId) ||
			(flag.company && company === flag.company) ||
			(flag.emailDomain && emailDomain === flag.emailDomain)
		)
			return true;
		else return false;
	});

	return (
		<BodyRow {...row.getRowProps()} $isSelected={row.isSelected} $isFlagged={isFlagged}>
			{row.cells.map((cell, i) => {
				return (
					<BodyCell {...cell.getCellProps()} key={`${i} ${cell.value}`}>
						{cell.render('Cell', {
							rowIdx,
							pageIndex,
							pageSize,
						})}
					</BodyCell>
				);
			})}
		</BodyRow>
	);
}

const BodyRow = styled.div<{ $isSelected: boolean; $isFlagged: boolean }>`
	border-bottom: 1px solid #323639;
	min-width: fit-content !important;
	height: 60px;
	color: #f6f6f6;
	transition: 0.2s;

	background-color: ${({ $isSelected, $isFlagged }) =>
		$isSelected ? '#686764' : $isFlagged ? '#380000' : ''};

	&:hover {
		background-color: ${({ $isSelected, $isFlagged }) =>
			$isFlagged && !$isSelected ? '#200000' : '#0e1013'};
	}
`;

const BodyCell = styled.div`
	display: flex;
	gap: 5px;
	align-items: center;
	justify-content: space-between;
	overflow: hidden;
	padding: 5px;

	& > * {
		max-height: 100%;
		max-width: 100%;
		overflow: auto;
		overflow-wrap: break-word;
	}

	input.MuiInputBase-input {
		color: white;
		font-size: 0.875rem;
	}
`;
