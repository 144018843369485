import { useCallback, useEffect, useState } from 'react';
import { FormNode } from '../../../ProjectUtilityFormV2/utils/types';
import {
	collection,
	doc,
	documentId,
	onSnapshot,
	query,
	updateDoc,
	where,
} from 'firebase/firestore';
import { db } from '../../../../../firebase';

const useFormTemplateNodes = (formId: string) => {
	const [allFormTemplateNodes, setAllFormTemplateNodes] = useState<FormNode[]>([]);

	const loadNodes = useCallback(() => {
		if (!formId) return;

		const nodesQuery = query(
			collection(db, 'utility_forms_v2_templates'),
			where(documentId(), '==', formId)
		);

		const unsubNodes = onSnapshot(nodesQuery, async snapshot => {
			const formNodes = snapshot.docs.map(doc => {
				return { ...doc.data(), id: doc.id } as any;
			});
			setAllFormTemplateNodes(formNodes[0]?.nodes);
		});

		return unsubNodes;
	}, [formId]);

	useEffect(() => {
		const unsub = loadNodes();
		return () => {
			if (unsub) unsub();
		};
	}, [loadNodes]);

	const saveTemplateNodes = async (nodes: FormNode[]) => {
		const nodesPromises = nodes.map(node => {
			const nodeRef = doc(db, 'utility_forms_v2_templates', node.id);
			return updateDoc(nodeRef, node);
		});

		await Promise.all(nodesPromises);
	};

	return {
		allFormTemplateNodes,
		saveTemplateNodes,
	};
};

export default useFormTemplateNodes;
