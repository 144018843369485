import { FC, useContext, useState } from 'react';
import { BlockMenuButton } from '../BlockMenuButton';
import { AddElementDialog } from '../AddElementDialog';
import { FormV2Context } from '../../screen-components/ProjectUtilityFormV2/context/FormV2Context.types';

const FormFooter: FC = () => {
	const [showDialog, setShowDialog] = useState<boolean>(false);
	const { isViewMode } = useContext(FormV2Context);
	const handleClose = () => setShowDialog(false);

	if (isViewMode) return null;

	return (
		<>
			<BlockMenuButton onChange={() => setShowDialog(true)} isSection={true} />
			<AddElementDialog
				open={showDialog}
				handleClose={handleClose}
				defaultType="node"
				excludedTypes={[
					'input',
					'area',
					'select',
					'switch',
					'date',
					'datetime',
					'photos',
				]}
			/>
		</>
	);
};

export default FormFooter;
