import { DialogContent, DialogTitle, Button } from '@mui/material';
import { StyledDialog, StyledDialogActions } from './ImageOptionsDialog.styles';

const ImageOptionsDialog = ({
	open,
	onClose,
	onDelete,
	onUpload,
	onReplace,
	imageURL,
	uploaded, // determines which buttons to display
	dialogTitle,
}: {
	open: boolean;
	onClose: () => void;
	onDelete?: () => void;
	onUpload?: () => void;
	onReplace?: () => void;
	imageURL: string;
	uploaded: boolean;
	dialogTitle?: string;
}) => {
	return (
		<StyledDialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
			<DialogTitle>{dialogTitle}</DialogTitle>
			<DialogContent>
				{uploaded ? (
					<img src={imageURL} alt="Upload" style={{ width: '100%', height: 'auto' }} />
				) : (
					<div
						style={{
							width: '100%',
							height: 'auto',
							textAlign: 'center',
							padding: '20px',
						}}>
						No image uploaded
					</div>
				)}
			</DialogContent>
			<StyledDialogActions>
				{uploaded ? (
					<>
						<Button onClick={onDelete}>Delete Image</Button>
						<Button onClick={onReplace}>Replace Image</Button>
					</>
				) : (
					<Button onClick={onUpload}>Upload Image</Button>
				)}
				<Button onClick={onClose}>Close</Button>
			</StyledDialogActions>
		</StyledDialog>
	);
};

export default ImageOptionsDialog;
