import { Autocomplete, TextField } from '@mui/material';
import { ProjectRole } from '../../../types/project-roles';
import { useContext } from 'react';
import { AuthContext } from '../../../context/AuthProvider';
import { ProjectData, UserObj } from '../../../types';
import { ProjectInfoTextField } from '../../screen-components/ProjectInfo/styled-components';
import ProjectRoleOption from './ProjectRoleOption';

type ProjectRoleAutocompleteProps = {
	role: ProjectRole;
	inputValue: ProjectData[ProjectRole];
	handleChange: (newValue: ProjectData[ProjectRole]) => void;
	project: ProjectData;
	darkMode?: boolean;
};

export default function ProjectRoleAutocomplete({
	role,
	inputValue,
	handleChange,
	project,
	darkMode = true,
}: ProjectRoleAutocompleteProps) {
	const { fetchingUsers, projectRoleOptions } = useContext(AuthContext);

	const isMultiUserRole = role !== 'accountManager';
	const options: UserObj[] = projectRoleOptions[role].map(userWithData => ({
		email: userWithData.email,
		name: userWithData.fullName,
		id: userWithData.id,
	}));

	return (
		<Autocomplete
			multiple={isMultiUserRole}
			disableCloseOnSelect={isMultiUserRole}
			fullWidth
			loading={fetchingUsers}
			loadingText="Loading users..."
			noOptionsText="No users found..."
			options={options}
			getOptionLabel={option => option.name}
			isOptionEqualToValue={(option, value) => option.id === value.id}
			renderOption={(props, option) => (
				<ProjectRoleOption
					props={props}
					userObj={option}
					role={role}
					project={project}
					key={option.id}
				/>
			)}
			value={isMultiUserRole ? inputValue || [] : inputValue || null}
			onChange={(e, newValue) => handleChange(newValue ?? undefined)}
			renderInput={params =>
				darkMode ? (
					<ProjectInfoTextField {...params} fullWidth />
				) : (
					<TextField {...params} fullWidth />
				)
			}
			componentsProps={{ popper: { style: { width: 'fit-content' } } }}
		/>
	);
}
