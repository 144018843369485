import { SetStateAction, useContext, useRef, useState } from 'react';
import { Equipment } from './types';
import {
	Button,
	Chip,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	TextField,
} from '@mui/material';
import { FirebaseFile } from '../../../../types';
import styled from 'styled-components';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import Spinner from '../../../reusable-components/Spinner';
import {
	deleteObject,
	getDownloadURL,
	getMetadata,
	ref,
	uploadBytes,
} from 'firebase/storage';
import { db, storage } from '../../../../firebase';
import { arrayRemove, arrayUnion, doc, writeBatch } from 'firebase/firestore';
import { SnackContext } from '../../../../context/SnackProvider';
import { v4 as uuidv4 } from 'uuid';
import { splitFilename } from '../../../../utils';

interface EditEquipmentDialogProps {
	selectedEquipment?: Equipment;
	setSelectedEquipment: React.Dispatch<SetStateAction<Equipment | undefined>>;
	setEquipment: React.Dispatch<React.SetStateAction<Equipment[]>>;
}
export default function EditEquipmentDialog({
	selectedEquipment,
	setSelectedEquipment,
	setEquipment,
}: EditEquipmentDialogProps) {
	const { setSnackbarProps } = useContext(SnackContext);

	const [partialData, setPartialData] = useState<Partial<Equipment>>({});
	const [filesRemoved, setFilesRemoved] = useState<FirebaseFile[]>([]);
	const [filesAdded, setFilesAdded] = useState<File[]>([]);
	const [uploading, setUploading] = useState(false);

	const inputRef = useRef<HTMLInputElement>(null);

	const showSubmit =
		Object.keys(partialData).length || filesRemoved.length || filesAdded.length;

	const filepathsRemoved = filesRemoved.map(file => file.filepath);
	const filteredFiles =
		selectedEquipment?.files.filter(file => !filepathsRemoved.includes(file.filepath)) ||
		[];

	const handleClose = () => {
		if (!uploading) {
			setSelectedEquipment(undefined);
			setPartialData({});
			setFilesRemoved([]);
			setFilesAdded([]);
		}
	};

	const removeUploadedFile = (filepath: string) => {
		if (selectedEquipment) {
			const fileToRemove = selectedEquipment.files.find(
				file => file.filepath === filepath
			);
			if (fileToRemove) {
				setFilesRemoved(prev => [...prev, fileToRemove]);
			}
		}
	};

	const addRemovedFile = (filepath: string) => {
		setFilesRemoved(prev => prev.filter(file => file.filepath !== filepath));
	};

	const removeNewFile = (idx: number) => {
		setFilesAdded(prev => {
			const tmp = [...prev];
			tmp.splice(idx, 1);
			return tmp;
		});
	};

	const handleInputClick = () => {
		if (inputRef.current) {
			inputRef.current.click();
		}
	};

	const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const files = e.target.files;
		if (files) {
			setFilesAdded(prev => [...prev, ...Array.from(files)]);
		}
	};

	const handleSubmit = async () => {
		if (selectedEquipment) {
			setUploading(true);
			setSnackbarProps({
				open: true,
				severity: 'warning',
				message: `Saving changes...`,
			});

			try {
				const docRef = doc(db, `equipment/${selectedEquipment.id}`);

				// Remove uploaded files.
				const fileRemoval = filesRemoved.map(async file => {
					const storageRef = ref(storage, file.filepath);
					await deleteObject(storageRef);
				});
				await Promise.all(fileRemoval);

				// Upload new files.
				const newFiles: FirebaseFile[] = [];
				const fileUpload = filesAdded.map(async file => {
					const uuid = uuidv4();
					const [, extension] = splitFilename(file.name);
					const uniqueFilename = `${uuid}.${extension}`;
					const filepath = `equipment_files/${uniqueFilename}`;
					const storageRef = ref(storage, filepath);

					const metadata = {
						contentDisposition: `inline; filename="${file.name}"`,
						customMetadata: {
							originalFilename: file.name,
							equipmentId: docRef.id,
						},
					};

					await uploadBytes(storageRef, file, metadata);
					const downloadUrl = await getDownloadURL(storageRef);
					const fetchedMetadata = await getMetadata(storageRef);
					const uploadDate = new Date(fetchedMetadata.timeCreated);

					newFiles.push({
						filename: file.name,
						downloadUrl,
						filepath,
						uploadDate,
						lastUpdated: uploadDate,
					});
				});
				await Promise.all(fileUpload);

				// Update equipment doc.
				const batch = writeBatch(db);
				if (filesRemoved.length) {
					batch.update(docRef, {
						files: arrayRemove(...filesRemoved),
					});
				}
				if (newFiles.length) {
					batch.update(docRef, {
						files: arrayUnion(...newFiles),
					});
				}
				if (Object.keys(partialData)) {
					batch.update(docRef, {
						...partialData,
					});
				}
				await batch.commit();

				// Update equipment state.
				setEquipment(prev =>
					prev.map(equip => {
						if (equip.id !== selectedEquipment.id) return equip;
						else {
							return {
								...equip,
								...partialData,
								files: [
									...equip.files.filter(
										file => !filepathsRemoved.includes(file.filepath)
									),
									...newFiles,
								],
							};
						}
					})
				);
			} catch (err) {
				console.error('Could not save changes to equipment');
				console.log(err);
				setSnackbarProps({
					open: true,
					severity: 'error',
					message: `Could not save changes to equipment...`,
				});
			} finally {
				setSnackbarProps({
					open: true,
					severity: 'success',
					message: `Changes saved!`,
				});
				handleClose();
			}

			setUploading(false);
		}
	};

	return selectedEquipment ? (
		<Dialog open={true} onClose={handleClose}>
			<DialogTitle>Editing Equipment</DialogTitle>
			<DialogContent>
				<TextField
					defaultValue={selectedEquipment.name}
					onChange={e =>
						setPartialData(prev => ({
							...prev,
							name: e.target.value,
						}))
					}
					placeholder="Name"
				/>
				<TextField
					defaultValue={selectedEquipment.serialNumber}
					onChange={e =>
						setPartialData(prev => ({
							...prev,
							serialNumber: e.target.value,
						}))
					}
					placeholder="Serial Number"
				/>
				<TextField
					defaultValue={selectedEquipment.description}
					onChange={e =>
						setPartialData(prev => ({
							...prev,
							description: e.target.value,
						}))
					}
					placeholder="Description"
				/>

				<FileContainer onClick={handleInputClick}>
					<FileSection>
						<span>Uploaded Files:</span>
						<FileChipContainer>
							{filteredFiles.length ? (
								filteredFiles.map(file => (
									<Chip
										label={file.filename}
										key={file.filepath}
										onDelete={() => removeUploadedFile(file.filepath)}
										onClick={() => window.open(file.downloadUrl)}
									/>
								))
							) : (
								<span>No files to display...</span>
							)}
						</FileChipContainer>
					</FileSection>

					{filesRemoved.length ? (
						<FileSection>
							<span>Removed Files:</span>
							<FileChipContainer>
								{filesRemoved.map(file => (
									<Chip
										label={file.filename}
										key={file.filepath}
										deleteIcon={<AddCircleIcon />}
										onDelete={() => addRemovedFile(file.filepath)}
										onClick={() => window.open(file.downloadUrl)}
									/>
								))}
							</FileChipContainer>
						</FileSection>
					) : null}

					{filesAdded.length ? (
						<FileSection>
							<span>New Files:</span>
							<FileChipContainer>
								{filesAdded.map((file, idx) => (
									<Chip label={file.name} key={idx} onDelete={() => removeNewFile(idx)} />
								))}
							</FileChipContainer>
						</FileSection>
					) : null}

					<input type="file" multiple ref={inputRef} onChange={handleInputChange} />
				</FileContainer>
			</DialogContent>
			<DialogActions>
				{!uploading ? (
					<>
						<Button onClick={handleClose}>Close</Button>
						{showSubmit ? <Button onClick={handleSubmit}>Save Changes</Button> : null}
					</>
				) : (
					<Spinner size={40} />
				)}
			</DialogActions>
		</Dialog>
	) : null;
}

const FileContainer = styled.div`
	display: flex;
	flex-direction: column;

	padding: 10px;

	transition: 200ms;
	cursor: pointer;
	background-color: gray;
	&:hover {
		background-color: #aeaeae;
	}

	> input {
		display: none;
	}
`;

const FileSection = styled.div`
	display: flex;
	flex-direction: column;
	> span {
		color: white;
	}

	padding: 10px;

	&:not(:first-child) {
		border-top: 2px solid black;
	}
`;

const FileChipContainer = styled.div`
	display: flex;
	justify-content: center;
	flex-wrap: wrap;
	gap: 10px;
`;
