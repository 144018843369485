import { useContext } from 'react';
import { ProjectData } from '../../../../../types';
import { AuthContext } from '../../../../../context/AuthProvider';
import { FieldLabel } from '../../../../../screens/ProjectInfo';
import { numberStrToMoney } from '../../../../../utils';
import styled from 'styled-components';
import { ProjectInfoTextField } from '../../styled-components';
import { ProjectContext } from '../../../../../context/ProjectProvider';

interface CostsProps {
	isActive: boolean;
	setPartialFormState: React.Dispatch<React.SetStateAction<Partial<ProjectData>>>;
	costType: 'scanningCost' | 'registrationCost' | 'modelingCost';
}

export default function Costs({ isActive, setPartialFormState, costType }: CostsProps) {
	const { user } = useContext(AuthContext);
	const { project } = useContext(ProjectContext);

	const cost = project?.[costType];
	const costStr = typeof cost === 'number' ? numberStrToMoney(cost.toString()) : '';

	const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
		setPartialFormState(prev => {
			let newValue: Number | undefined;
			if (e.target.value === '' || e.target.value === undefined) newValue = undefined;
			else newValue = Number(Number(e.target.value).toFixed(2));

			return {
				...prev,
				[costType]: newValue,
			};
		});
	};

	return user?.isAdmin ? (
		<div className="infoRow">
			<FieldLabel label={costsMap[costType]} />

			{isActive ? (
				<NumberField
					type="number"
					defaultValue={cost}
					onChange={handleChange}
					fullWidth
				/>
			) : (
				<div className="fieldInfo">{costStr}</div>
			)}
		</div>
	) : null;
}

const costsMap = {
	scanningCost: 'Scanning Cost',
	registrationCost: 'Data Processing Cost',
	modelingCost: 'BIM Cost',
};

const NumberField = styled(ProjectInfoTextField)`
	input {
		::-webkit-outer-spin-button,
		::-webkit-inner-spin-button {
			display: none;
		}
		-moz-appearance: textfield;
	}
`;
