import { Dispatch, SetStateAction } from 'react';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';
import { FileType } from '../types';
import styled from 'styled-components';
import { Icon, IconButton } from '@mui/material';
import NewFolderButton from '../icons/newFolderButton.svg';

const FolderIcon = () => (
	<Icon>
		<img src={NewFolderButton} height={20} width={20} alt="" />
	</Icon>
);

export default function DeliverableButtonsOverlay({
	deliverableKey,
	downloadOnly,
	setShowingDialog,
}: {
	deliverableKey: FileType;
	downloadOnly: boolean;
	setShowingDialog: Dispatch<SetStateAction<boolean>>;
}) {
	return (
		<ButtonContainer>
			{!document.fullscreenElement ? (
				<>
					<OverlayButton
						title={!downloadOnly ? 'Edit/View uploaded files' : 'View uploaded files'}
						onClick={() => {
							setShowingDialog(true);
						}}
						size="large">
						{<FolderIcon />}
					</OverlayButton>

					<OverlayButton
						title="View fullscreen"
						onClick={() => {
							console.log(deliverableKey);
							document
								.getElementById(`deliverable-${deliverableKey}`)
								?.requestFullscreen();
						}}
						size="large">
						<FullscreenIcon />
					</OverlayButton>
				</>
			) : (
				<OverlayButton
					title="Exit fullscreen"
					onClick={() => {
						document.exitFullscreen();
					}}
					size="large">
					<FullscreenExitIcon />
				</OverlayButton>
			)}
		</ButtonContainer>
	);
}

const ButtonContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 5px;

	position: absolute;
	bottom: 20px;
	right: 20px;
`;

const OverlayButton = styled(IconButton)`
	background-color: rgba(255, 179, 16, 0.5);
	box-shadow: 0px 0px 5px black;
	z-index: 10;

	&:hover,
	:focus-visible {
		filter: brightness(1);
		background-color: #ffb310;
		box-shadow: 0px 0px 10px grey;
	}

	@media screen and (max-width: 1300px) {
		height: 30px;
		width: 30px;
	}
`;
