import { useContext, useState } from 'react';
import { FormV2Context } from '../../../../context/FormV2Context.types';
import { createTemplate, db } from '../../../../../../../firebase';
import { SnackContext } from '../../../../../../../context/SnackProvider';
import { collection, getDocs, query, where } from 'firebase/firestore';

type CreateTemplateResult = {
	data?: {
		success: boolean;
		[key: string]: any;
	};
};

export const useTemplateContext = (formId?: string) => {
	const [isCreating, setIsCreating] = useState(false);
	const { utilityForm } = useContext(FormV2Context);
	const { setSnackbarProps } = useContext(SnackContext);

	const addTemplate = async ({
		name,
		templateId,
		isPartial,
	}: {
		name?: string;
		templateId?: string;
		isPartial?: boolean;
	}) => {
		if (!utilityForm) return;

		setIsCreating(true);

		try {
			const result = (await createTemplate({
				formId: utilityForm.id,
				name,
				templateId,
				isPartial,
			})) as CreateTemplateResult;

			if (!result.data?.success) {
				templateError();
				setIsCreating(false);
			} else {
				templateError();
			}
		} catch (e) {
			console.log(e);
			templateError();
		} finally {
			setIsCreating(false);
		}
	};

	const templateError = () =>
		setSnackbarProps({
			open: true,
			message: `Unable to create the template. Please try again.`,
			severity: 'error',
		});

	const checkTemplateNameExists = async (name: string): Promise<boolean> => {
		const templatesRef = query(
			collection(db, 'utility_forms_v2_templates'),
			where('name', '==', name.trim())
		);
		const querySnapshot = await getDocs(templatesRef);
		return !querySnapshot.empty; // returns true if the template name exists
	};

	return {
		addTemplate,
		isCreating,
		checkTemplateNameExists,
	};
};
