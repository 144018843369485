import styled from 'styled-components';
import Spinner from './Spinner';

export default function ProgressSpinner({
	value,
	containerStyles,
	size = 35,
	relativeWidth,
	relativeHeight,
}: {
	value: number;
	containerStyles?: React.CSSProperties;
	size?: number;
	relativeWidth?: string;
	relativeHeight?: string;
}) {
	return (
		<Container style={containerStyles}>
			<Spinner
				variant="determinate"
				value={value}
				size={size}
				relativeWidth={relativeWidth}
				relativeHeight={relativeHeight}
			/>
			<FloatingText>{`${value}%`}</FloatingText>
		</Container>
	);
}
const Container = styled.div`
	position: relative;
`;

const FloatingText = styled.span`
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
`;
