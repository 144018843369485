import { useRef, useEffect, useCallback } from 'react';
import { NavigateOptions, useNavigate } from 'react-router-dom';
import { ProjectStatus, ProjectData } from '../types';
import { FullMetadata } from 'firebase/storage';
import { startsWith } from 'lodash';
import { FileType } from '../components/reusable-components/DeliverableFiles/types';
import { getCaptureTimestampString } from './timestamps';

export const projectStatuses: ProjectStatus[] = [
	'Proposal Pending',
	'Proposal Sent',
	'To Be Scheduled',
	'Scheduled',
	'Capturing',
	'On-Site Capture Complete',
	'Registration',
	'Ready For Modeling',
	'Modeling In Progress',
	'QA/QC',
	'Pending Payment / Delivered',
	'Delivered',
	'Pending Payment',
	'Sample',
	'Template',
	'Archive',
];

export const contractorStatuses: ProjectStatus[] = [
	'Ready For Modeling',
	'Modeling In Progress',
	'QA/QC',
];

export type VisibleProjectStatus = {
	status: (typeof projectStatuses)[number];
	visible: boolean;
};

export const projectStatusText = (status: ProjectStatus) => {
	switch (status) {
		case 'Proposal Pending':
			return 'Bid Pending';
		case 'Proposal Sent':
			return 'Bid Sent';
		case 'To Be Scheduled':
			return 'Capture Pending';
		case 'On-Site Capture Complete':
			return 'Capture Complete';
		case 'Pending Payment / Delivered':
			return 'Delivered';
		case 'Delivered':
			return 'Delivery Complete';
		default:
			return status;
	}
};

export const getStatusIndex = (status: ProjectStatus) => {
	const idx = projectStatuses.findIndex(s => s === status);
	if (idx > -1) return idx;
	else return null;
};

export const cameraList = [
	'RTC360 – #2980083',
	'BLK360 – #3502210',
	'BLK360 – #3500747',
	'BLK360 – #3502946',
	'BLK360 2 – #2061262',
	'BLK360 2 – #2061483',
	// 'P50',
	// 'P40',
	// 'P30',
	// 'Faro70',
	// 'Faro150',
	// 'Faro350 – #LLS082119998',
	// 'Trimble X7',
	// 'BLK2GO',
	// 'BLK2FLY',
	'Matterport Pro 2 – #M027ZKBG',
	'Matterport Pro 2 – #M04LX43D',
	'Matterport Pro 2 – #R320',
	'Matterport Pro 2 – #M025HJ7C',
	'Matterport Pro 3 – #Q33140398',
	'Matterport Pro 3 – #Q33030014',
	'Mavic 2 Pro – #163CHATROA56VS',
	'Mavic 3 Pro – #1581F45TB21AK1AED0WK',
	'Phantom 4 Pro – #11U3J7Q-00SU046',
	// 'Navvis VLX',
	'Survey Coordinates',
];

export const formatProjectTimestamps = (projects: ProjectData[]) => {
	return projects.map(proj => ({
		...proj,

		captureTimestamps: proj.captureTimestamps
			? // @ts-expect-error Timestamp not formatted as Date yet.
			  proj.captureTimestamps.map(ts => new Date(ts._seconds * 1000))
			: undefined,

		modelingDueTimestamp: proj.modelingDueTimestamp
			? // @ts-expect-error Timestamp not formatted as Date yet.
			  new Date(proj.modelingDueTimestamp._seconds * 1000)
			: undefined,
		modelingStartTimestamp: proj.modelingStartTimestamp
			? // @ts-expect-error Timestamp not formatted as Date yet.
			  new Date(proj.modelingStartTimestamp._seconds * 1000)
			: undefined,

		deliveryTimestamp: proj.deliveryTimestamp
			? // @ts-expect-error Timestamp not formatted as Date yet.
			  new Date(proj.deliveryTimestamp._seconds * 1000)
			: undefined,

		// @ts-expect-error Timestamp not formatted as Date yet.
		createdAt: proj.createdAt ? new Date(proj.createdAt._seconds * 1000) : undefined,
		lastStatusChange: proj.lastStatusChange
			? // @ts-expect-error Timestamp not formatted as Date yet.
			  new Date(proj.lastStatusChange._seconds * 1000)
			: undefined,
	})) as ProjectData[];
};

export function formatProjectsForExport(projList: ProjectData[]) {
	const formatted: Object[][] = [];
	projectStatuses.forEach(() => {
		formatted.push([]);
	});
	projList.forEach(proj => {
		let idx: number = getStatusIndex(proj.projectStatus) ?? 0;

		formatted[idx].push({
			Status: projectStatusText(proj.projectStatus),
			'Organization(s)': proj.orgs?.map(org => org.name).join(', ') ?? '',
			Address: proj.address ?? '',

			'Sub-Total': proj.subTotal ?? '',
			'Scanning Cost': proj.scanningCost || '',
			'Data Processing Cost': proj.registrationCost || '',
			'BIM Cost': proj.modelingCost || '',

			'Project ID': proj.name ?? '',
			'Scanning Technician(s)': proj.scanningTechnicians?.join(', ') ?? '',
			Equipment: proj.equipment?.join(', ') ?? '',

			'Site Visit Date(s)': proj.captureTimestamps
				? proj.captureTimestamps.map(ts => getCaptureTimestampString(ts)).join(', ')
				: '',
			'Modeling Start Date': proj.modelingStartTimestamp
				? proj.modelingStartTimestamp.toLocaleDateString()
				: '',
			'Modeling Due Date': proj.modelingDueTimestamp
				? proj.modelingDueTimestamp.toLocaleDateString()
				: '',
			'Delivery Date': proj.deliveryTimestamp
				? proj.deliveryTimestamp.toLocaleDateString()
				: '',

			'Design Software': proj.designSoftware?.join(', ') ?? '',
			'Modeler(s)': proj.modelers?.join(', ') ?? '',
			'Access/Scan Notes': proj.description ?? '',
			VR: proj.matterport?.length ? 'X' : '',
			'3D / 2D Files': proj.autodesk?.length ? 'X' : '',
			'2D Links': proj.twoDLinks?.length ? 'X' : '',
			'3D Links': proj.threeDLinks?.length ? 'X' : '',
			'Point Cloud': proj.pointCloud?.length ? 'X' : '',
			Photos: proj.photos?.length ? 'X' : '',
			PDF: proj.pdf?.length ? 'X' : '',
		});
	});
	return formatted.flat();
}

/**
 * Object holding deliverableKeys as keys with their respective names as values
 */
export const deliverableNames: { [deliverableKey in FileType]: string } = {
	pdf: 'PDF',
	autodesk: '3D / 2D',
	pointCloud: 'Point Cloud',
	existingDocs: 'Existing Documents / Raw Data',
	photos: 'Photos',
	proposal: 'Proposal Documents',
};

export function getAnonDownloadLink(projectData: ProjectData) {
	if (projectData?.id) {
		const host = window.location.host;
		const protocol = window.location.protocol;
		return `${protocol}//${host}/deliverables/${projectData.id}/download`;
	} else return null;
}

export function getAnonUploadLink(projectData: ProjectData) {
	if (projectData?.id) {
		const host = window.location.host;
		const protocol = window.location.protocol;
		return `${protocol}//${host}/deliverables/${projectData.id}/upload`;
	} else return null;
}

export function copyToClipboard(str: string | null) {
	if (str) navigator.clipboard.writeText(str);
}

export function useNav() {
	const navigate = useNavigate();
	const navigateRef = useRef({ navigate });
	useEffect(() => {
		navigateRef.current.navigate = navigate;
	}, [navigate]);
	return useCallback((location: string, options?: NavigateOptions) => {
		navigateRef.current.navigate(location, options);
	}, []);
}

/**
 * Re-orders date string from yyyy-mm-dd format to mm-dd-yyyy format.
 * If invalid date provided, '-' is returned.
 */
export function reorderDate(date?: string | null) {
	if (!date) return '-';
	const arr = date?.split('-');
	if (arr?.length === 3 && arr[0]?.length === 4) {
		return [arr[1], arr[2], arr[0]].join('-');
	} else return '-';
}

/**
 * Converts a Date API object to a string with the format 'yyyy-mm-dd'.
 * @param {Date} date Date API object.
 * @returns
 */
export function dateToDateString(date: Date) {
	// Check if 'date' is an instance of Date and it's valid
	if (!(date instanceof Date) || isNaN(date.getTime())) {
		// Attempt to create a Date object if 'date' is not an instance of Date
		date = new Date(date);
		// Check again if the newly created Date object is valid
		if (isNaN(date.getTime())) {
			console.error('Invalid date:', date);
			return ''; // Return an empty string
		}
	}

	const year = date.getFullYear().toString();
	let month = (date.getMonth() + 1).toString(); // Need to add 1 to month value because Date API's month value is zero-indexed.
	let day = date.getDate().toString();

	if (month.length === 1) month = '0' + month;
	if (day.length === 1) day = '0' + day;

	const dateStr = year + '-' + month + '-' + day;
	return dateStr;
}

export function numberStrToMoney(str: string) {
	let ans = `${str}`;
	if (!ans.startsWith('$')) ans = '$' + ans;
	const split = ans.split('.');
	if (split?.length === 2 && split[1].length === 1) {
		ans = ans + '0';
	}
	return ans.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

export function parseNumFromStr(str: string) {
	if (!str || str.includes('-')) return 0;
	const res = Number(str.replace(/[^0-9.]/g, ''));
	if (isNaN(res)) return 0;
	else return res;
}

export function addCommasToNumber(num: Number) {
	return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

export function isBrowserViewableFile(metadata: FullMetadata) {
	const contentType = metadata.contentType;
	if (contentType?.includes('image/')) return true;
	else if (contentType?.includes('video/')) return true;
	else if (contentType === 'application/pdf') return true;
	else return false;
}

/**
 * Returns the current date as a string in yyyy-mm-dd format
 */
type DateFormat = 'yyyy-mm-dd' | 'mm-dd-yyyy';
export const getDate = (format: DateFormat = 'yyyy-mm-dd') => {
	const today = new Date();
	const dd = String(today.getDate()).padStart(2, '0');
	const mm = String(today.getMonth() + 1).padStart(2, '0');
	const yyyy = String(today.getFullYear());

	if (format === 'mm-dd-yyyy') return `${mm}-${dd}-${yyyy}`;
	else return `${yyyy}-${mm}-${dd}`;
};

/**
 * Takes a Date object and returns a string in mm-dd-yyyy format
 * @param date
 */
export const formatDate = (date?: Date | null) => {
	if (!date) return '-';

	const dd = String(date.getDate()).padStart(2, '0');
	const mm = String(date.getMonth() + 1).padStart(2, '0');
	const yyyy = String(date.getFullYear());

	return `${mm}-${dd}-${yyyy}`;
};

const permissionTypes = ['admin', 'contractor'] as const;
type PermissionType = (typeof permissionTypes)[number];

export const checkPermissions = (user: any, permission: PermissionType) => {
	if (!permissionTypes.includes(permission))
		throw new Error('Invalid permission argument.');

	switch (permission) {
		case 'contractor':
			return !!user?.isAdmin || !!user?.isContractor;
		default: // "admin" case
			return !!user?.isAdmin;
	}
};

export const capitalize = (str: string) => {
	if (str.length) {
		return str.charAt(0).toUpperCase() + str.slice(1);
	} else {
		return str;
	}
};

const linkPrefixes = ['https://', 'http://'];
export const isValidLink = (str: string) => {
	for (const prefix of linkPrefixes) {
		if (startsWith(str.toLowerCase(), prefix)) return true;
	}
	return false;
};

/**
 * Splits a filename and returns the prefix and extension (period not included).
 * The prefix is the first element of the return array and the extension is the second.
 */
export const splitFilename = (filename: string) => {
	const split = filename.split('.');
	if (split.length > 1) {
		const extension = split.pop();
		const prefix = split.join('.');
		return [prefix, extension];
	} else if (split.length === 1) {
		return [filename, ''];
	} else {
		throw new Error(`Couldn't split filename: ${filename}`);
	}
};

export const getEmailDomain = (email: string) => {
	const split = email.split('@');
	if (split.length < 2) return '';
	else return split[split.length - 1];
};

export const cpuOptions = ['Blastoise', 'Warturtle', 'Pikachu (BLK)', 'Charizard'];

export const strArrToStr = (arr: string[], beforeLastItem = 'and') => {
	return arr.reduce((acc, str, idx) => {
		if (idx > 0) {
			if (idx === arr.length - 1) {
				if (arr.length > 2) acc += ',';
				acc += ` ${beforeLastItem} `;
			} else {
				acc += ', ';
			}
		}
		return acc + str;
	}, '');
};
