import { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { SnackContext } from '../../../context/SnackProvider';
import { copyToClipboard } from '../../../utils';
import { ProjectData, ProjectStatus } from '../../../types';
import { IconButton, List, ListItem, ListItemText } from '@mui/material';
import Drawer from '@mui/material/Drawer';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import { appBarHeight } from '../Layout';
import LogoButton from '../LogoButton';
import { statusLabels } from '../ProjectTracker/utils/utils';
import theme from '../../../styles/theme';
import NewTracker from '../ProjectTracker/NewTracker';
import GoogleMapsButton from '../../screen-components/ProjectInfo/components/GoogleMapsButton';
import { getCaptureTimestampString } from '../../../utils/timestamps';

const drawerWidth = 160;
const navBarBorder = '2px dotted #ffb310';

type ProjectDetailProps = {
	title: string;
	detail: string | string[];
	isLink?: boolean;
	multiline?: boolean;
};

const ProjectDetail = ({
	title,
	detail,
	isLink = false,
	multiline = false,
}: ProjectDetailProps) => {
	const { setSnackbarProps } = useContext(SnackContext);

	if (Array.isArray(detail) ? detail.length : detail)
		return (
			<div key={title} style={{ display: 'flex', flexDirection: 'column' }}>
				<ProjectDetailTitle>{title}</ProjectDetailTitle>
				<div
					style={{
						display: 'flex',
						gap: '10px',
						flexWrap: 'wrap',
						maxWidth: 'calc(100vw - 15px)',
					}}>
					{Array.isArray(detail) ? (
						detail.map((foo, i) => {
							return isLink ? (
								<LinkContainer key={i}>
									<ProjectDetailLink href={foo} target="_blank" rel="noreferrer" key={i}>
										{foo}
									</ProjectDetailLink>
									<ContentCopyIcon
										onClick={() => {
											copyToClipboard(foo);
											setSnackbarProps({
												open: true,
												message: 'Link copied!',
												severity: 'success',
											});
										}}
										className="CopyLinkButton"
										titleAccess="Copy link"
										tabIndex={0}
									/>
								</LinkContainer>
							) : (
								<ProjectDetailValue key={i}>{foo}</ProjectDetailValue>
							);
						})
					) : isLink ? (
						<LinkContainer>
							<ProjectDetailLink href={detail} target="_blank" rel="noreferrer">
								{detail}
							</ProjectDetailLink>
							<ContentCopyIcon
								onClick={() => {
									copyToClipboard(detail);
									setSnackbarProps({
										open: true,
										message: 'Link copied!',
										severity: 'success',
									});
								}}
								className="CopyLinkButton"
								titleAccess="Copy link"
								tabIndex={0}
							/>
						</LinkContainer>
					) : (
						<ProjectDetailValue multiline={multiline}>{detail}</ProjectDetailValue>
					)}
				</div>
			</div>
		);
	else return null;
};

export type Props = {
	project: ProjectData;
	upload?: boolean;
};

export default function DeliverableFilesHeader({ project, upload = false }: Props) {
	const [openMenu, setOpenMenu] = useState(false);
	// const [showSchedule, setShowSchedule] = useState(false);
	const concatAddress = project?.address.replace(/\s+/g, '+');

	return (
		<Header>
			<AnonNavBar $drawerOpen={openMenu}>
				<div
					style={{
						display: 'flex',
						flexDirection: 'row',
						justifyContent: 'flex-start',
						alignItems: 'center',
					}}>
					<LogoButton path={`/projects/${project?.id ?? ''}`} />

					<ProjectTitle>
						{project?.address || project?.name || 'Unnamed Project'}
					</ProjectTitle>

					<NewTracker
						projectStatus={project?.projectStatus}
						nav={true}
						style={{ marginRight: '10px' }}
					/>
				</div>
				<div
					style={{
						display: 'flex',
						flexDirection: 'row',
						justifyContent: 'flex-end',
						alignItems: 'center',
						marginLeft: 'auto',
					}}>
					{concatAddress ? <GoogleMapsButton concatAddress={concatAddress} /> : null}

					<AnonNavBarDrawerButton
						$drawerOpen={openMenu}
						title="Open menu"
						size="large"
						onClick={() => setOpenMenu(true)}>
						<MenuIcon />
					</AnonNavBarDrawerButton>
				</div>
			</AnonNavBar>

			<AnonNavBarDrawer variant="persistent" anchor="right" open={openMenu}>
				<AnonNavBarDrawerHeader>
					<AnonNavBarButton
						title="Close menu"
						size="large"
						onClick={() => setOpenMenu(false)}
						style={{ marginLeft: 'auto' }}>
						<CloseIcon />
					</AnonNavBarButton>
				</AnonNavBarDrawerHeader>

				<List>
					<ListItem
						button
						component={Link}
						to="/login"
						state={{ redirectPath: `/projects/${project.id}` }}
						title="Navigate to Login screen">
						<ListItemText primary="Login" />
					</ListItem>
					<ListItem
						button
						component="a"
						href="https://www.roboticimaging.com/request-a-scan"
						title="Navigate to Request Quote page on website">
						<ListItemText primary="Request Quote" />
					</ListItem>
					<ListItem
						button
						component="a"
						href="https://www.roboticimaging.com/scheduling"
						title="Navigate to Scheduling page on website">
						<ListItemText primary="Schedule" />
					</ListItem>
					<ListItem
						button
						component="a"
						href="https://roboticimaging.ai/deliverables/e6QCykLflAJu0JEGIkEC/upload"
						title="Navigate to Transfer screen">
						<ListItemText primary="Transfer" />
					</ListItem>
				</List>
			</AnonNavBarDrawer>

			<DetailsContainer hasInternalNotes={upload && !!project.internalNotes}>
				<InlineDetailsContainer>
					{!upload &&
					(project?.projectStatus === 'Pending Payment' ||
						project?.projectStatus === 'Pending Payment / Delivered') ? (
						<ProjectDetail
							title="Invoice Link(s)"
							detail={project?.invoiceLinks}
							isLink
						/>
					) : null}

					{upload ? <ProjectDetail title="Site ID" detail={project?.name} /> : null}

					<ProjectDetail
						title="Site Number:"
						detail={project.storeNumber ? project.storeNumber : ''}
					/>

					<ProjectDetail
						title="Project Status:"
						detail={statusLabels[project?.projectStatus as ProjectStatus]}
					/>

					<ProjectDetail
						title="Site Visit Date"
						detail={
							project.captureTimestamps
								? project.captureTimestamps
										.map(ts => getCaptureTimestampString(ts))
										.join(', ')
								: ''
						}
					/>

					{/* <ProjectDetail
						title="Modeling Start Date"
						detail={
							project.modelingStartTimestamp
								? project.modelingStartTimestamp.toLocaleDateString()
								: ''
						}
					/> */}

					{/* <ProjectDetail
						title="Modeling Due Date"
						detail={
							project.modelingDueTimestamp
								? project.modelingDueTimestamp.toLocaleDateString()
								: ''
						}
					/> */}

					<ProjectDetail
						title="Delivery Date"
						detail={
							project.deliveryTimestamp
								? project.deliveryTimestamp.toLocaleDateString()
								: ''
						}
					/>

					{/* <ProjectDetail title="Design Software" detail={project?.designSoftware} /> */}

					<ProjectDetail title="VR Links" detail={project?.matterport} isLink />
				</InlineDetailsContainer>

				{upload ? (
					<ProjectDetail
						title="Internal Notes"
						detail={project?.internalNotes}
						multiline
					/>
				) : null}
			</DetailsContainer>
		</Header>
	);
}

const Header = styled.header`
	display: flex;
`;

export const AnonNavBar = styled.nav<{ $drawerOpen: boolean }>`
	position: fixed;
	display: flex;
	align-items: center;
	gap: 5px;

	margin-right: ${({ $drawerOpen }) => (!$drawerOpen ? undefined : `${drawerWidth}px`)};
	width: ${({ $drawerOpen }) =>
		!$drawerOpen ? '100%' : `calc(100% - ${drawerWidth}px)`};
	height: ${appBarHeight};

	overflow-x: auto;
	overflow-y: hidden;

	background-color: black;
	border-bottom: ${navBarBorder};

	z-index: 100;
	transition: ${theme.transitions.create(['margin', 'width'], {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.leavingScreen,
	})};
`;

export const AnonNavBarButton = styled(IconButton)`
	color: #ffb310;
	&:hover {
		background-color: rgba(255, 179, 16, 0.2);
	}
`;

export const AnonNavBarDrawerButton = styled(IconButton)<{ $drawerOpen: boolean }>`
	display: ${({ $drawerOpen }) => ($drawerOpen ? 'none' : 'flex')};
	color: #ffb310;
	&:hover {
		background-color: rgba(255, 179, 16, 0.2);
	}
`;

export const AnonNavBarDrawer = styled(Drawer)`
	.MuiPaper-root {
		width: ${drawerWidth}px;
		background-color: rgba(0, 0, 0, 0.8);
		color: #ffb310;
	}
`;

export const AnonNavBarDrawerHeader = styled.div`
	display: flex;
	align-items: center;

	height: ${appBarHeight};
	border-bottom: ${navBarBorder};
	background-color: black;
`;

const ProjectTitle = styled.span`
	flex-shrink: 0;
	font-size: 1.75rem;
	font-weight: bold;
	max-width: 100%;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
`;

export const ProjectDetailTitle = styled.div`
	// font-family: Roboto Mono;
	font-weight: bold;
	color: #ffb310;
	display: flex;
	margin: 0 auto;
	font-size: 18px;
	width: 100%;
`;

const LinkContainer = styled.div`
	display: flex;
	align-items: center;
	gap: 5px;
	max-width: 100%;
	overflow: hidden;
`;

const DetailsContainer = styled.div<{ hasInternalNotes: boolean }>`
	// font-family: Roboto Mono;
	display: flex;
	margin-top: ${appBarHeight};
	column-gap: 20px;
	row-gap: 5px;
	padding: 10px;
	font-size: large;
	width: 100%;

	& > div {
		max-width: ${p => (p.hasInternalNotes ? '50%' : '')};
	}

	@media screen and (max-width: 1023px) {
		flex-direction: column;

		& > div {
			max-width: 100%;
		}
	}
`;

const InlineDetailsContainer = styled.div`
	display: flex;
	column-gap: 20px;
	row-gap: 5px;
	flex-wrap: wrap;
	width: 100%;
`;

const ProjectDetailValue = styled.span<{ multiline?: boolean }>`
	color: white;
	white-space: ${p => (p.multiline ? 'pre-wrap' : 'nowrap')};
	text-overflow: ${p => (p.multiline ? '' : 'ellipsis')};
	overflow: ${p => (p.multiline ? 'auto' : 'hidden')};
	max-width: ${p => (p.multiline ? '' : '500px')};
	max-height: 135px;
`;

const ProjectDetailLink = styled.a`
	color: #ffb310;
	text-decoration: none;
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
	max-width: 500px;

	&:hover,
	&:focus-visible {
		text-decoration: underline;
		color: white;
	}
`;
