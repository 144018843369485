import { useContext, useState, useEffect } from 'react';
import { SnackContext } from '../../context/SnackProvider';

import Snackbar, { SnackbarCloseReason } from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import styled from 'styled-components';

export default function AppSnackbar() {
	const { setSnackbarProps, snackbarProps } = useContext(SnackContext);
	const { open, severity, message, hideDuration, onClick, onButtonClose } = snackbarProps;

	const defaultHideDuration = 5000;

	const handleClose = (
		e?: React.SyntheticEvent | Event,
		reason?: SnackbarCloseReason
	) => {
		if (reason === 'clickaway') return;
		setSnackbarProps(prev => {
			return { ...prev, open: false };
		});
		onButtonClose?.();
		e?.stopPropagation();
	};

	const [key, setKey] = useState<number | undefined>(undefined);
	useEffect(() => {
		if (open && severity && message) {
			setKey(new Date().getTime());
		}
	}, [open, severity, message]);

	const handleExited = () => {
		setKey(undefined);
	};

	return (
		<Snackbar
			open={open}
			onClose={handleClose}
			onClick={onClick}
			autoHideDuration={
				hideDuration === 0
					? undefined
					: hideDuration === undefined
					? defaultHideDuration
					: hideDuration
			}
			key={key}
			style={{ cursor: onClick ? 'pointer' : 'default' }}
			TransitionProps={{ onExited: handleExited }}>
			<StyledAlert
				elevation={6}
				variant="filled"
				severity={severity}
				onClose={handleClose}>
				{message ?? ''}
			</StyledAlert>
		</Snackbar>
	);
}

const StyledAlert = styled(Alert)`
	align-items: center;
	gap: 10px;

	.MuiAlert-icon,
	.MuiAlert-action {
		margin: 0px;
		padding: 0px;
	}

	.MuiAlert-message {
		white-space: pre-line;
	}
`;
