import { DialogContent, DialogTitle, Button, Box } from '@mui/material';
import {
	Center,
	Checkbox,
	NodeRow,
	StyledDialog,
	StyledDialogActions,
	StyledSpan,
} from './UnarchivePhotosDialog.styles';
import useArchivedNodes from './useArchivedPhotos';
import { useEffect, useState } from 'react';
import {
	ArchivedPhotoV2,
	FormNode,
	// FormNodeWithChildren,
	// itemTypes,
} from '../../../utils/types';
import Loading from '../../Loading/Loading';
import { Stop } from '@mui/icons-material';

const UnarchivePhotosDialog = ({
	formId,
	onClose,
	open,
	projectId,
}: {
	formId: string;
	onClose: () => void;
	open: boolean;
	projectId: string;
}) => {
	const {
		activePhotoNodes,
		archivedPhotos,
		getActivePhotoNodes,
		getArchivedPhotos,
		getSelectedPhotos,
		loading,
		restorePhotos,
		togglePhoto,
	} = useArchivedNodes(formId, projectId);

	const [destinationDialogOpen, setDestinationDialogOpen] = useState(false);
	const closeDestinationDialog = () => setDestinationDialogOpen(false);

	const displayPhoto = (photo: ArchivedPhotoV2, level = 0) => (
		<Box key={photo.name}>
			<NodeRow style={{ marginLeft: level * 30, marginBottom: 10 }}>
				<Checkbox
					onChange={value => togglePhoto(photo, value.target.checked)}
					checked={photo.selected || false}
					color="primary"
				/>
				<img
					src={photo.url}
					alt={photo.name}
					style={{ width: 32, height: 32, marginRight: 10 }}
				/>
				{photo.name}
			</NodeRow>
		</Box>
	);

	const displayDestinationNode = (node: FormNode, level = 0) => (
		<Box key={node.id}>
			<NodeRow>
				<Stop fontSize="small" color="primary" />
				<StyledSpan
					onClick={async () => {
						await restorePhotos(node);
						closeDestinationDialog();
						onClose();
					}}>
					{node.parentsTitle && node.parentsTitle + ' > '}
					{node.displayTitle}
				</StyledSpan>
			</NodeRow>
		</Box>
	);

	const restore = async () => {
		if (!activePhotoNodes.length) await getActivePhotoNodes();
		setDestinationDialogOpen(true);
	};

	useEffect(() => {
		if (open) getArchivedPhotos();
	}, [getArchivedPhotos, open]);

	return (
		<>
			<StyledDialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
				<DialogTitle>Unarchive deleted photos</DialogTitle>
				{loading && (
					<DialogContent>
						<Center>
							<Loading />
						</Center>
					</DialogContent>
				)}
				{!loading && (
					<DialogContent>
						{archivedPhotos.length === 0 && 'No archived items'}
						{archivedPhotos.map(node => displayPhoto(node, 0))}
					</DialogContent>
				)}
				<StyledDialogActions>
					<Button onClick={restore} disabled={getSelectedPhotos().length === 0}>
						Restore
					</Button>
					<Button onClick={onClose}>Close</Button>
				</StyledDialogActions>
			</StyledDialog>
			<StyledDialog
				open={destinationDialogOpen}
				onClose={closeDestinationDialog}
				maxWidth="sm"
				fullWidth>
				<DialogTitle>Pick the destination container</DialogTitle>
				{loading && (
					<DialogContent>
						<Center>
							<Loading />
						</Center>
					</DialogContent>
				)}
				{!loading && (
					<DialogContent>
						{activePhotoNodes.map(node => displayDestinationNode(node, 0))}
					</DialogContent>
				)}
				<StyledDialogActions>
					<Button onClick={closeDestinationDialog}>Close</Button>
				</StyledDialogActions>
			</StyledDialog>
		</>
	);
};

export default UnarchivePhotosDialog;
