import Dropdown from './components/Dropdown';
import { ProjectStatus } from '../../../types';
import { projectStatusText, projectStatuses } from '../../../utils';
import styled from 'styled-components';
import { StatusIcon, statusColor } from '../../styled-components/styledReactTable';
import { Button } from '@mui/material';
import { useContext } from 'react';
import { LoadingContext } from '../../../context/LoadingProvider';

export type SelectedStatus = ProjectStatus | string | null;

export default function ProjectStatusSidebar({
	selectedStatus,
	setSelectedStatus,

	titleElement,
	options = [],
	statuses = projectStatuses,
	projectStatusCount,

	loading,
}: {
	selectedStatus: SelectedStatus;
	setSelectedStatus: React.Dispatch<React.SetStateAction<SelectedStatus>>;

	titleElement?: JSX.Element;
	options?: { icon: JSX.Element; text: string; status: string }[];
	statuses?: ProjectStatus[];
	projectStatusCount?: Partial<Record<ProjectStatus, number | null>>;

	loading?: boolean;
}) {
	const { loadingProjectStatuses } = useContext(LoadingContext);

	const handleClick = (status: SelectedStatus) => {
		setSelectedStatus(status);
	};

	return (
		<>
			<SidebarList>
				{titleElement}
				{options.map(option => {
					return (
						<OptionButton
							$status={option.status}
							$selectedStatus={selectedStatus}
							onClick={() => handleClick(option.status)}
							key={option.status}>
							{option.icon}
							<span>{option.text}</span>
						</OptionButton>
					);
				})}
				{statuses.map(status => {
					const statusCount = projectStatusCount?.[status] || 0;
					return (
						<ProjectStatusButton
							$selectedStatus={selectedStatus}
							$status={status}
							onClick={() => handleClick(status)}
							key={status}>
							<StatusIcon status={status} />
							<span>{`${projectStatusText(status)}: ${
								!loadingProjectStatuses ? statusCount : ''
							}`}</span>
						</ProjectStatusButton>
					);
				})}
			</SidebarList>

			<Dropdown
				selectedStatus={selectedStatus}
				setSelectedStatus={setSelectedStatus}
				options={options}
				statuses={statuses}
			/>
		</>
	);
}
const SidebarList = styled.div`
	display: flex;
	flex-direction: column;
	gap: 20px;
	flex-shrink: 0;
	flex-grow: 0;

	height: 100%;
	padding: 20px;

	overflow: auto;
	&::-webkit-scrollbar-track {
		background: #0d0d0c;
	}
	&:not(:hover) {
		&::-webkit-scrollbar-thumb {
			background: transparent;
		}
	}

	@media (max-width: 1023px) {
		display: none;
	}
`;

const SidebarButton = styled(Button)`
	display: flex;
	align-items: center;
	line-height: normal;
	justify-content: left;
	gap: 10px;
	font-size: 18px;
	height: 50px;
	border-radius: 20px;
	text-transform: none;
`;

const OptionButton = styled(SidebarButton)<{
	$status: string;
	$selectedStatus: SelectedStatus;
}>`
	color: ${({ $selectedStatus, $status }) =>
		$selectedStatus === $status ? `black` : `white`};
	background-color: ${({ $status, $selectedStatus }) =>
		$status === $selectedStatus ? 'white' : 'transparent'};
	border: 1px solid white;

	:hover {
		opacity: 2;
		background-color: ${({ $status, $selectedStatus }) =>
			$status === $selectedStatus ? 'white' : 'transparent'};
	}
`;

const ProjectStatusButton = styled(SidebarButton)<{
	$status: ProjectStatus;
	$selectedStatus: SelectedStatus;
}>`
	color: ${({ $selectedStatus, $status }) =>
		$selectedStatus === $status ? `black` : `${statusColor($status)}`};
	border: ${({ $status }) => `2px solid ${statusColor($status)}`};
	background-color: ${({ $selectedStatus, $status }) =>
		$selectedStatus === $status ? `${statusColor($status)}` : `transparent`};
	:hover {
		opacity: 2;
		background-color: ${({ $selectedStatus, $status }) =>
			$selectedStatus === $status ? `${statusColor($status)}` : `transparent`};
	}
`;
