import { ClickAwayListener, TextField } from '@mui/material';
import { StaticTitle, Value } from './FormFillV2.styles';
import { FormNode } from '../../../../utils/types';
import { useContext, useEffect, useRef, useState } from 'react';
import NodeField from './FieldTypes/NodeField';
import { FormV2Context } from '../../../../context/FormV2Context.types';
import NodeActionButton from '../../../buttons/NodeActionButton/NodeActionButton';
import CommentsButton from '../../../buttons/CommentsButton/CommentsButton';
import InfoButton from '../../../buttons/InfoButton/InfoButton';
import ItemWrapper from '../ItemWrapper';
import InputField from './FieldTypes/InputField';
import MultilineField from './FieldTypes/MultilineField';
import SwitchField from './FieldTypes/SwitchField';
import PhotosField from './FieldTypes/PhotosField';
import DateField from './FieldTypes/DateField';
import DateTimeField from './FieldTypes/DateTimeField';
import SelectField from './FieldTypes/SelectField';

interface ItemFieldProps {
	node: FormNode;
}

const ItemField: React.FC<React.PropsWithChildren<ItemFieldProps>> = ({ node }) => {
	const { updateDisplayTitle } = useContext(FormV2Context);

	const [editTitle, setEditTitle] = useState(false);
	const [title, setTitle] = useState(node.displayTitle);

	const _isMounted = useRef(true);

	const handleTitleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setTitle(event.target.value);
	};

	const handleBlur = async () => {
		setEditTitle(false);
		if (node.id) {
			try {
				await updateDisplayTitle(node.id, title);
			} catch (error) {
				console.error('Error updating display title: ', error);
			}
		}
	};

	useEffect(() => {
		_isMounted.current = true;
		return () => {
			_isMounted.current = false;
		};
	}, []);

	const isContainerOrRootPhotos =
		node.type === 'node' || (node.type === 'photos' && node.level === 0);

	return (
		<ItemWrapper itemNode={node}>
			{!isContainerOrRootPhotos ? (
				editTitle ? (
					<ClickAwayListener onClickAway={handleBlur}>
						<TextField
							value={title}
							onChange={handleTitleChange}
							onBlur={handleBlur}
							autoFocus
						/>
					</ClickAwayListener>
				) : (
					<StaticTitle onClick={() => setEditTitle(true)}>
						{node.displayTitle}:
					</StaticTitle>
				)
			) : null}

			<Value>
				{node.type === 'input' ? (
					<InputField node={node} />
				) : node.type === 'area' ? (
					<MultilineField node={node} />
				) : node.type === 'switch' ? (
					<SwitchField itemNode={node} />
				) : node.type === 'photos' ? (
					<PhotosField itemNode={node} />
				) : node.type === 'date' ? (
					<DateField node={node} />
				) : node.type === 'datetime' ? (
					<DateTimeField node={node} />
				) : node.type === 'select' ? (
					<SelectField node={node} />
				) : (
					<NodeField node={node} />
				)}
			</Value>

			{!isContainerOrRootPhotos ? (
				<>
					<NodeActionButton node={node} />
					<CommentsButton node={node} />
					<InfoButton node={node} />
				</>
			) : null}
		</ItemWrapper>
	);
};

export default ItemField;
