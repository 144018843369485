import { IconButton, Menu as BaseMenu, MenuItem, styled, Box } from '@mui/material';
import MoreHoriz from '@mui/icons-material/MoreHoriz';
import { FC, useState } from 'react';

type ListItemMenuProps = {
	items: ListItemMenuPropsItem[];
};

export type ListItemMenuPropsItem = {
	title: string;
	onClick: React.MouseEventHandler<HTMLLIElement>;
	icon?: JSX.Element;
};

const ListItemMenu: FC<React.PropsWithChildren<ListItemMenuProps>> = ({ items }) => {
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const open = Boolean(anchorEl);

	const handleClose = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
		e.preventDefault();
		e.stopPropagation();
		setAnchorEl?.(null);
	};

	const handleOpen = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
		e.preventDefault();
		e.stopPropagation();
		setAnchorEl?.(e.currentTarget);
	};

	const handleMenuItemClick = (
		e: React.MouseEvent<HTMLLIElement>,
		item: ListItemMenuPropsItem
	) => {
		e.stopPropagation();
		setAnchorEl?.(null);
		item.onClick(e);
	};

	return (
		<>
			<IconButton onClick={handleOpen}>
				<MoreHoriz color="primary" />
			</IconButton>

			<Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
				{items?.map((item, index) => (
					<MenuItem key={index} onClick={e => handleMenuItemClick(e, item)}>
						{item.icon}
						{item.icon && <Box width={12} />}
						{item.title}
					</MenuItem>
				))}
			</Menu>
		</>
	);
};

const Menu = styled(BaseMenu)(
	({ theme }) => `
	.MuiPaper-root {
		background-color: ${theme.palette.secondary.light};
		color: ${theme.palette.primary.main};
	}
`
);

export default ListItemMenu;
