/**
 * Identical to Object.keys(), except the returned array is typed correctly.
 */
export const getKeys = Object.keys as <T extends object>(obj: T) => Array<keyof T>;

export type Entry<T> = [keyof T, T[keyof T]];

export function getEntries<T extends object>(obj: T): Entry<T>[] {
	return getKeys(obj).map(key => [key, obj[key]]);
}
