import { FC } from 'react';
import styled from 'styled-components';
import theme from '../../../styles/theme';

type Props = {
	completedFields: number;
	requiredFields: number;
	fontSize?: string;
};

const RequiredProgressNumber: FC<Props> = ({
	completedFields,
	requiredFields,
	fontSize = '12px',
}: Props) => {
	return completedFields === 0 && requiredFields === 0 ? (
		<></>
	) : (
		<StatusCompleted fontSize={fontSize}>
			{completedFields}{' '}
			<StatusCompletedSeparator isCompleted={completedFields === requiredFields}>
				/
			</StatusCompletedSeparator>{' '}
			{requiredFields}
		</StatusCompleted>
	);
};

export default RequiredProgressNumber;

const StatusCompleted = styled.span<{ fontSize: string }>`
	font-size: ${props => props.fontSize};
	font-weight: 700;
	white-space: nowrap;
	margin-left: 8px;
`;

const StatusCompletedSeparator = styled.span.attrs<{ isCompleted: boolean }>(props => ({
	isCompleted: props.isCompleted,
}))<{ isCompleted: boolean }>`
	color: ${props => (props.isCompleted ? '#3DB310' : theme.palette.primary.main)};
`;
