import { useContext, useRef, useState } from 'react';
import Button from '@mui/material/Button';
import { Box, DialogContent, Typography } from '@mui/material';
import { ProjectContext } from '../../../../../context/ProjectProvider';
import { LoadingContext } from '../../../../../context/LoadingProvider';
import { SnackContext } from '../../../../../context/SnackProvider';
import { db, storage } from '../../../../../firebase';
import { addDoc, collection } from 'firebase/firestore';

import { ref, uploadBytesResumable } from 'firebase/storage';
import LoadingScreen from '../../../../../components/reusable-components/LoadingScreen';
import styled from 'styled-components';
import PlacesAutocompleteWrapper from '../../../../../components/reusable-components/PlacesAutocomplete/PlacesAutocompleteWrapper';
import { getEntries } from '../../../../../utils/typescript-utils';
import CameraChips from '../../../../../screens/Map/components/CameraChips';
import { Dialog, StyledTextField } from '../../../../form-components';
import theme from '../../../../../styles/theme';

export default function AddContractorForm() {
	const { loading, setLoading } = useContext(LoadingContext);
	const { setContractors, showContractorDialog, setShowContractorDialog } =
		useContext(ProjectContext);
	const filesRef = useRef<File[] | null>(null);

	const emptyFields = {
		Name: '',
		Email: '',
		Address: '',
		Phone: '',
		Website: '',
		Cameras: [] as string[],
		files: [] as string[],
		Notes: '',
	};

	const [formFolderName, setFormFolderName] = useState(emptyFields);
	const { setSnackbarProps } = useContext(SnackContext);

	const handleClose = () => {
		setFormFolderName(emptyFields);
		filesRef.current = null;
		setShowContractorDialog(false);
	};
	const handleAddContractor = (formValues: { [key: string]: any }) => {
		return new Promise((resolve, reject) => {
			addDoc(collection(db, 'matterport_directory'), formValues)
				.then(newContractor => {
					resolve(newContractor);
				})
				.catch(e => {
					console.log(e);
					reject(e);
				});
		});
	};
	const [progress, setProgress] = useState(0);
	const handleUpload = (contractorId: string) => {
		return new Promise<void>((resolve, reject) => {
			if (!filesRef.current || !filesRef.current.length) reject();

			const files = [...(filesRef.current || [])];
			const filesPath = `contractors/${contractorId}/`;
			setLoading(true);
			let filesUploaded = 0;
			files.forEach(file => {
				const storageRef = ref(storage, filesPath + file.name);
				const uploadTask = uploadBytesResumable(storageRef, file);
				let prevProgress = 0;
				uploadTask.on(
					'state_changed',
					// Upload progress/status
					snapshot => {
						const progress =
							(snapshot.bytesTransferred / snapshot.totalBytes) * (100 / files.length) -
							prevProgress;
						prevProgress += progress;
						setProgress(prev => {
							return prev + progress;
						});
					},
					// Upload error
					error => {
						console.log(error);
						reject(error);
					},
					// Upload completion callback
					() => {
						++filesUploaded;
						if (filesUploaded === files.length) {
							setLoading(false);
							setProgress(0);
							resolve();
						}
					}
				);
			});
		});
	};
	const handleUpdateUI = (
		contractorData: { [key: string]: any },
		contractorId: string
	) => {
		setContractors(prev => [
			...(prev || []),
			{
				...contractorData,
				id: contractorId,
			},
		]);
	};

	const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();

		for (const [key, value] of getEntries(formFolderName)) {
			if (typeof value === 'string') {
				setFormFolderName(prev => ({
					...prev,
					[key]: value.trim(),
				}));
			}
		}
		const { Name } = formFolderName;
		const contractorData = { ...formFolderName };

		const contractor = (await handleAddContractor(contractorData)) as any;
		if (contractorData.files.length) await handleUpload(contractor.id);
		handleUpdateUI(contractorData, contractor.id);

		handleClose();
		setSnackbarProps({
			open: true,
			message: `${Name} has been added to contractor directory`,
			severity: 'success',
		});
	};

	const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
		const value = e.target.value;
		setFormFolderName({
			...formFolderName,
			[e.target.name]: value,
		});
	};

	return (
		<Dialog
			open={showContractorDialog}
			onClose={handleClose}
			title="Add A New Contractor"
			bottomActions={
				<>
					<Button onClick={handleClose} color="primary">
						Cancel
					</Button>
					<Button type="submit" color="primary">
						Add Contractor
					</Button>
				</>
			}>
			{!loading ? (
				<form
					onSubmit={handleSubmit}
					style={{
						...formStyles,
					}}>
					<StyledTextField
						label="Name"
						id="Name"
						name="Name"
						value={formFolderName.Name}
						onChange={handleChange}
						required
						fullWidth
						variant="standard"
						sx={{ ...textFieldStyles }}
					/>
					<StyledTextField
						label="Email"
						id="Email"
						name="Email"
						value={formFolderName.Email}
						onChange={handleChange}
						fullWidth
						variant="standard"
						sx={{ ...textFieldStyles }}
					/>
					<Box
						sx={{
							...textFieldStyles,
						}}>
						<PlacesAutocompleteWrapper
							formState={formFolderName}
							setFormState={
								setFormFolderName as React.Dispatch<
									React.SetStateAction<{ [key: string]: any }>
								>
							}
							addressField="Address"
							required
						/>
					</Box>
					<StyledTextField
						label="Phone"
						id="Phone"
						name="Phone"
						value={formFolderName.Phone}
						onChange={handleChange}
						fullWidth
						variant="standard"
						sx={{ ...textFieldStyles }}
					/>
					<StyledTextField
						label="Website"
						id="Website"
						name="Website"
						value={formFolderName.Website}
						onChange={handleChange}
						fullWidth
						variant="standard"
						sx={{ ...textFieldStyles }}
					/>
					<StyledTextField
						label="Contractor Notes"
						name="Notes"
						value={formFolderName.Notes}
						onChange={handleChange}
						multiline
						fullWidth
						maxRows={5}
						variant="standard"
						sx={{ ...textFieldStyles }}
					/>
					<CameraChipsContainer>
						<CameraChips
							form={formFolderName}
							cameraField="Cameras"
							setForm={setFormFolderName}
						/>
					</CameraChipsContainer>
					<AttachmentRow>
						<Typography variant="body2" sx={{ color: theme.palette.divider }}>
							Attachments:
						</Typography>
						<StyledInputContainer>
							<input
								type="file"
								id="contractorAttachment"
								multiple
								onChange={e => {
									const fileList = [...(e.target.files || [])];
									const files = [...fileList].map(file => file.name);
									setFormFolderName({
										...formFolderName,
										files: files,
									});
									filesRef.current = fileList;
								}}
							/>
						</StyledInputContainer>
					</AttachmentRow>
				</form>
			) : (
				<DialogContent>
					<LoadingScreen message={`${progress.toFixed(2)}% uploaded...`} />
				</DialogContent>
			)}
		</Dialog>
	);
}

const AttachmentRow = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
`;

const formStyles = {
	overflow: 'auto',
	padding: '0 3em 2em',
	margin: '-2em -3em',
	display: 'grid',
	gap: '5px',
};

const textFieldStyles = {
	'.MuiInputBase-root': {
		color: theme.palette.newText.primary,
		fontSize: '0.875rem',
		'&:not(.Mui-focused)': {
			'&:before': {
				borderBottom: `1px solid ${theme.palette.divider}`,
			},
			'&:hover:before': {
				borderBottom: `2px solid ${theme.palette.divider}`,
			},
		},
	},
	'.MuiFormLabel-root:not(.Mui-focused)': {
		color: theme.palette.divider,
		fontSize: '0.875rem',
	},
};

const CameraChipsContainer = styled(Box)`
	margin: 15px 0 65px;

	div {
		button {
			background-color: transparent;
			color: #909090;
			border: 1px solid #909090;
			cursor: pointer;
			padding: 10px;
			font-size: 0.875rem;
			transition: 0.3s;

			&:hover {
				box-shadow: unset;
				opacity: 0.5;
			}

			&:active {
				background-color: transparent;
				color: #909090;
				border: 1px solid #909090;
				cursor: pointer;
				padding: 10px;
				font-size: 0.875rem;
				opacity: 0.5;
			}
		}

		div {
			border-bottom: unset;
			span {
				color: ${theme.palette.divider};
			}
			div {
				background: transparent;
				padding-left: 0;
				padding-right: 0;
			}
		}
	}
`;

const StyledInputContainer = styled(Box)`
	color: ${theme.palette.divider};
	width: 180px;

	input[type='file'] {
		width: 100%;
	}

	input[type='file']::file-selector-button {
		background-color: transparent;
		border: 1px solid #909090;
		cursor: pointer;
		transition: 0.3s;
		color: #909090;
		font-size: 0.875rem;
		height: 25px;
	}
`;
