import {
	Dialog,
	DialogTitle,
	DialogContent,
	TextField,
	DialogActions,
	Button,
} from '@mui/material';
import { FC, useState } from 'react';

type InputDialogProps = {
	open: boolean;
	handleClose: () => void;
	handleSave: (value: string) => void;
	initialValue?: string;
	title: string;
	label?: string;
};

const InputDialog: FC<React.PropsWithChildren<InputDialogProps>> = ({
	handleClose,
	handleSave,
	initialValue,
	label,
	open,
	title,
}) => {
	const [value, setValue] = useState(initialValue || '');

	return (
		<Dialog open={open} onClose={handleClose}>
			<DialogTitle>{title}</DialogTitle>
			<DialogContent>
				<TextField
					margin="dense"
					id="value"
					label={label || 'Value'}
					fullWidth
					variant="standard"
					value={value}
					onChange={e => setValue(e.target.value)}
				/>
			</DialogContent>
			<DialogActions>
				<Button onClick={handleClose}>Cancel</Button>
				<Button onClick={() => handleSave(value)}>Ok</Button>
			</DialogActions>
		</Dialog>
	);
};

export default InputDialog;
