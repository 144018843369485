import { useContext, useState } from 'react';
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	TextField,
} from '@mui/material';
import { SnackContext } from '../../../context/SnackProvider';
import LoadingScreen from '../../reusable-components/LoadingScreen';
import { Organization } from '../../../types';
import { OrganizationsContext } from '../../../context/OrganizationsProvider';
import { useNavigate } from 'react-router-dom';

const defaultOrganization: Organization = {
	id: '',
	name: '',
	projectIds: [],
	adminNotes: '',
	orgNotes: '',
	logo: [],
};

export default function AddOrganizationDialog({
	open,
	setOpen,
}: {
	open: boolean;
	setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) {
	const { setSnackbarProps } = useContext(SnackContext);
	const { addingOrganization, addOrganization } = useContext(OrganizationsContext);
	const [form, setForm] = useState(defaultOrganization);
	const navigate = useNavigate();

	const onChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
		const field = e.target.name;
		setForm(prev => ({
			...prev,
			[field]: e.target.value,
		}));
	};

	const handleClose = () => setOpen(false);

	const handleSubmit = async () => {
		const newOrgId = await addOrganization(form.name);
        if (newOrgId) {
			setSnackbarProps({
				open: true,
				severity: 'success',
				message: `Successfully submitted new organization!`,
			});
			handleClose();
			setForm(defaultOrganization);
			navigate(`/organizations/${newOrgId}`);
		} else {
			setSnackbarProps({
				open: true,
				severity: 'error',
				message: `Failed to submit new organization!`,
			});
		}
	};

	return (
		<Dialog open={open} onClose={handleClose} maxWidth="xs" fullWidth>
			<DialogTitle>Create A New Organization</DialogTitle>

			{!addingOrganization ? (
				<DialogContent>
					<TextField
						variant="standard"
						label="Name"
						name="name"
						value={form.name}
						onChange={onChange}
					/>
				</DialogContent>
			) : (
				<DialogContent>
					<LoadingScreen message="Creating organization..." textColor="black" />
				</DialogContent>
			)}

			<DialogActions>
				{!addingOrganization ? (
					<>
						<Button onClick={handleClose} sx={{ textTransform: 'none' }}>
							Cancel
						</Button>
						<Button onClick={handleSubmit} sx={{ textTransform: 'none' }}>
							Create Organization
						</Button>
					</>
				) : null}
			</DialogActions>
		</Dialog>
	);
}
