import { TreeItems } from 'dnd-kit-sortable-tree';
import Layout from '../../components/reusable-components/Layout';
import useReorder, {
	ReorderableItem,
} from '../../components/screen-components/NodesReorder/hooks/useReorder';
import LoadingScreen from '../../components/reusable-components/LoadingScreen';
import { createContext, useContext, useEffect } from 'react';
import { FormNode } from '../../components/screen-components/ProjectUtilityFormV2/utils/types';
import { Alert, AlertTitle, Button, Dialog, DialogActions } from '@mui/material';
import useAlert from '../../components/screen-components/NodesReorder/hooks/useAlert';
import { useParams } from 'react-router-dom';
import { MinimalTreeItemData } from '../../components/screen-components/NodesReorder/components/TreeComponent/TreeComponent.types';
import Form from '../../components/screen-components/Form';
import { TemplateContext } from '../../context/TemplateProvider';

export const NodesReorderContext = createContext({
	reorderableItem: {} as ReorderableItem,
	nodes: [] as TreeItems<MinimalTreeItemData>,
	nodesArray: [] as FormNode[],
});

const NodesReorder = () => {
	const { templateId } = useParams();
	const { loading, nodes, reorderableItem } = useReorder();
	const { alertOpen, alertMessage, hideAlert } = useAlert();
	const { setTempId, isPartial } = useContext(TemplateContext);

	useEffect(() => {
		document.title = `${reorderableItem?.name} | Robotic Imaging`;
	}, [reorderableItem?.name]);

	useEffect(() => {
		setTempId(templateId || '');
		return () => {
			setTempId('');
		};
	}, [setTempId, templateId]);

	if (loading || !nodes || !reorderableItem)
		return (
			<LoadingScreen message={templateId ? 'Loading template...' : 'Loading form...'} />
		);

	const title = isPartial
		? `${reorderableItem.name} (Partial Template)`
		: `${reorderableItem.name}`;

	return (
		<Layout LeftNavComponent={<h1>{title}</h1>}>
			<Form formId={templateId ?? ''} isTemplate projectId="" />
			<Dialog open={alertOpen}>
				<Alert severity="error">
					<AlertTitle>Forbidden</AlertTitle>
					{alertMessage}
				</Alert>
				<DialogActions>
					<Button onClick={hideAlert}>Ok</Button>
				</DialogActions>
			</Dialog>
		</Layout>
	);
};

export default NodesReorder;
