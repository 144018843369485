import { Row } from 'react-table';
import { ProjectData } from '../../../../../../../types';
import { useEffect, useState } from 'react';
import { collection, getDocs, orderBy, query, where } from 'firebase/firestore';
import { db } from '../../../../../../../firebase';
import { UtilityFormV2 } from '../../../../../../screen-components/ProjectUtilityFormV2/utils/types';
import { Check, Remove } from '@mui/icons-material';

export default function PhotoReportCell({ row }: { row: Row<ProjectData> }) {
	const projectId = row.original.id || '';
	const [forms, setForms] = useState<UtilityFormV2[]>([]);

	useEffect(() => {
		if (projectId) {
			const reportQuery = query(
				collection(db, 'utility_forms_v2'),
				where('projectId', '==', projectId),
				orderBy('lastUpdated', 'desc')
			);
			getDocs(reportQuery)
				.then(res => {
					if (res.docs.length) {
						setForms(
							res.docs.map(reportDoc => ({
								...(reportDoc.data() as UtilityFormV2),
								id: reportDoc.id,
							}))
						);
					}
				})
				.catch(err => {
					console.error(`Could not get reports for project with id "${projectId}"`);
					console.log(err);
				});
		}
	}, [projectId]);

	return (
		<span onClick={e => e.stopPropagation()}>
			{forms.some(form => Boolean(form.downloadUrlVirtual)) ? <Check /> : <Remove />}
		</span>
	);
}
