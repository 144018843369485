import { useCallback, useContext, useEffect, useState } from 'react';
import { PendingShare, UserObj } from '../../../../types';
import { SnackContext } from '../../../../context/SnackProvider';
import { db, getOrgOwners } from '../../../../firebase';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import SkeletonTable from '../../../reusable-components/SkeletonTable';
import DataTable from '../../../reusable-components/DataTable';
import AddOrgOwnerDialog from './AddOrgOwnerDialog';
import { PendingUserObj } from '../../../../screens/TeamPage';
import { collection, onSnapshot, query, where } from 'firebase/firestore';
import { AuthContext } from '../../../../context/AuthProvider';
import { checkPermissions } from '../../../../utils';
import { useTeamPageColumns } from '../../TeamPage/TeamPageColumns';
import RemoveOwnerDialog from '../RemoveOwnerDialog';

export default function OwnerTab() {
	const { user } = useContext(AuthContext);
	const { setSnackbarProps } = useContext(SnackContext);

	const [usersToBeRemoved, setUsersToBeRemoved] = useState<(UserObj | PendingUserObj)[]>(
		[]
	);
	const { usersColumns } = useTeamPageColumns(false);
	const isAdmin = checkPermissions(user, 'admin');

	const [orgOwners, setOrgOwners] = useState<UserObj[]>([]);
	const [loadingOrgOwners, setLoadingOrgOwners] = useState(false);
	const [pendingOwners, setPendingOwners] = useState<PendingUserObj[]>([]);
	const [openAddOrgOwner, setOpenAddOrgOwner] = useState(false);

	const { organizationId }: any = useParams();
	const showButton = isAdmin || user?.orgOwnerIds?.includes(organizationId);

	const handleOnDeleteUsers = (ids: string[]) => {
		const pendingUsersToDelete: PendingUserObj[] = [];
		const usersToDelete: UserObj[] = [];

		for (const id of ids) {
			const userToDelete = orgOwners.find(userObj => userObj.id === id);
			if (userToDelete) {
				usersToDelete.push(userToDelete);
				continue;
			}
			const pendingUserToDelete = pendingOwners.find(
				pendingUserObj => pendingUserObj.id === id
			);
			if (pendingUserToDelete) {
				pendingUsersToDelete.push(pendingUserToDelete);
			}
		}

		setUsersToBeRemoved([...usersToDelete, ...pendingUsersToDelete]);
	};

	const getSetOrgOwners = useCallback(
		(orgId: string) => {
			setLoadingOrgOwners(true);

			getOrgOwners({ orgId: orgId })
				.then(res => {
					const owners =
						(res.data as UserObj[]).map(owner => ({
							...owner,
							id: owner.id,
						})) || [];
					setOrgOwners(owners);
				})
				.catch(err => {
					console.error(err);
					setSnackbarProps({
						open: true,
						severity: 'error',
						message: 'Could not fetch organization owners',
					});
				})
				.finally(() => {
					setLoadingOrgOwners(false);
				});
		},
		[setSnackbarProps]
	);

	useEffect(() => {
		let unsubPendingOwners = () => {};

		if (user?.isAdmin || user?.orgOwnerIds?.includes(organizationId as string)) {
			if (organizationId) {
				getSetOrgOwners(organizationId);

				const coll = collection(db, 'pending_project_shares');
				const pendingOwnerQuery = query(
					coll,
					where('orgOwnerIds', 'array-contains', organizationId)
				);

				unsubPendingOwners = onSnapshot(pendingOwnerQuery, snap => {
					const fetchedOwners: PendingUserObj[] = snap.docs.map(pendingShareDoc => {
						const pendingShare = pendingShareDoc.data() as PendingShare;
						return {
							id: pendingShareDoc.id,
							email: pendingShare.email,
							lastShared: new Date((pendingShare as any).lastUpdated.seconds * 1000),
							orgOwnerIds: pendingShare.orgOwnerIds || [],
						};
					});
					setPendingOwners(fetchedOwners);
				});
			}
		}

		return () => {
			unsubPendingOwners();
		};
	}, [getSetOrgOwners, organizationId, user?.isAdmin, user?.orgOwnerIds]);

	return (
		<Container>
			{loadingOrgOwners ? (
				<SkeletonTable rows={5} />
			) : (
				<DataTable
					data={[...pendingOwners, ...orgOwners]}
					columns={usersColumns}
					name="user"
					onAdd={() => setOpenAddOrgOwner(true)}
					textEmptyResult="Add an owner to this organization to get started!"
					textNoSearchQueryResult="No Displayable Owners"
					onDelete={handleOnDeleteUsers}
					showButton={showButton}
					queryFields={['fullName', 'email']}
				/>
			)}

			<AddOrgOwnerDialog
				open={openAddOrgOwner}
				setOpen={setOpenAddOrgOwner}
				orgId={organizationId || ''}
			/>

			<RemoveOwnerDialog
				setUsersToBeRemoved={setUsersToBeRemoved}
				usersToBeRemoved={usersToBeRemoved}
				organizationId={organizationId}
				setOrgOwners={setOrgOwners}
				setPendingOwners={setPendingOwners}
			/>
		</Container>
	);
}

const Container = styled.div`
	display: flex;
	flex-direction: column;
	padding: 0 20px;
	width: 100%;
`;
