import { FC } from 'react';
import { Box, Tooltip, styled } from '@mui/material';
import { Info } from '@mui/icons-material';

type ListItemProps = {
	onClick: (id: string) => void;
	title: string;
	detail: string;
	id: string;
	tooltip?: string;
	link?: string;
	showIcon?: boolean;
};

const ListItem: FC<ListItemProps> = ({
	onClick,
	detail,
	title,
	id,
	tooltip,
	link,
	showIcon = true,
}) => {
	return (
		<Item onClick={() => onClick(id)}>
			<Row style={{ maxWidth: '60%' }}>
				{showIcon && (
					<Tooltip title={tooltip}>
						<Box marginRight={1} marginTop={1}>
							<Info fontSize="small" color="primary" />
						</Box>
					</Tooltip>
				)}
				<Tooltip title={tooltip}>
					<span>{title}</span>
				</Tooltip>
			</Row>
			<span style={{ maxWidth: '60%' }}>{detail}</span>
		</Item>
	);
};

export default ListItem;

const Row = styled('div')`
	display: flex;
	flex-direction: row;
	align-items: center;
`;

const Item = styled('div')`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	padding: 4px 10px;
	width: 100%;
	align-items: center;
	border-bottom: 1px solid rgba(89, 89, 89, 0.4);
	color: white;
	cursor: pointer;
	background-color: #0c0c0c;

	&:hover {
		background-color: #1e1e23;
	}
`;
