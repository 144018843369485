import { FlattenedItem } from '../screens/NodesReorder/components/DndKitSortableTreeItem/types';

export const addOrderToItems = (items: FlattenedItem[], isForm?: boolean) => {
	const filteredItems: FlattenedItem[] = isForm
		? items.filter(item => item.node.type !== 'placeholder')
		: items;
	const unsuitableItems: FlattenedItem[] = isForm
		? items.filter(item => item.node.type === 'placeholder')
		: [];
	const orderTracker: Record<number, number> = {};
	let currentLevel: number | undefined;
	let previousLevel: number;

	const processedItems = filteredItems.map((item: any) => {
		const elem = isForm ? item.node : item;

		if (elem.level === undefined) return item;

		if (currentLevel !== elem.level) {
			currentLevel = elem.level;
			orderTracker[elem.level] =
				elem.level === 0 || previousLevel > elem.level
					? orderTracker[elem.level] || 0
					: 0;
		}

		orderTracker[elem.level]++;

		let displayOrder;
		switch (elem.level) {
			case 0:
				displayOrder = toRoman(orderTracker[elem.level]);
				break;
			case 1:
				displayOrder = toCapitalLetter(orderTracker[elem.level]);
				break;
			case 2:
				displayOrder = orderTracker[elem.level].toString();
				break;
			case 3:
				displayOrder = toLowercaseLetter(orderTracker[elem.level]);
				break;
			case 4:
				displayOrder = toLowercaseRoman(orderTracker[elem.level]);
				break;
			default:
				displayOrder = orderTracker[elem.level].toString();
		}
		displayOrder += '.';
		previousLevel = elem.level;
		return isForm
			? { ...item, node: { ...elem, displayOrder } }
			: { ...elem, displayOrder };
	});

	return [...unsuitableItems, ...processedItems];
};

const toRoman = (num: number): string => {
	if (num < 1 || num > 3999) return num.toString();

	const romanNumerals: { [key: number]: string } = {
		1000: 'M',
		900: 'CM',
		500: 'D',
		400: 'CD',
		100: 'C',
		90: 'XC',
		50: 'L',
		40: 'XL',
		10: 'X',
		9: 'IX',
		5: 'V',
		4: 'IV',
		1: 'I',
	};

	let result = '';
	const values = Object.keys(romanNumerals)
		.map(Number)
		.sort((a, b) => b - a);

	for (const value of values) {
		while (num >= value) {
			result += romanNumerals[value];
			num -= value;
		}
	}
	return result;
};

const toCapitalLetter = (num: number): string => {
	const adjustedNum = ((num - 1) % 26) + 1;
	const amount = Math.floor((num - 1) / 26);
	const letter = String.fromCharCode(adjustedNum + 64);
	return letter.repeat(amount + 1);
};

const toLowercaseLetter = (num: number): string => {
	return toCapitalLetter(num).toLowerCase();
};

const toLowercaseRoman = (num: number): string => {
	return toRoman(num).toLowerCase();
};
