import { useContext } from 'react';
import { Row } from 'react-table';
import { Lead } from '../../../../types';
import { SnackContext } from '../../../../../../context/SnackProvider';

import EmailIcon from '@mui/icons-material/Email';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

import LeadTextCell from './LeadTextCell';
import BasicIconButton from '../../../../../../components/reusable-components/BasicIconButton';
import { copyToClipboard } from '../../../../../../utils';
import styled from 'styled-components';

export default function LeadEmailCell({ row }: { row: Row<Lead> }) {
	const { setSnackbarProps } = useContext(SnackContext);

	const email = row.original.email;

	return (
		<LeadTextCell row={row} field="email">
			{email ? (
				<>
					<CopyButton
						onClick={() => {
							copyToClipboard(email);
							setSnackbarProps({
								open: true,
								message: `${email} copied to clipboard!`,
								severity: 'success',
							});
						}}
						titleAccess="Copy email"
						tabIndex={0}
					/>
					<BasicIconButton href={`mailto:${email}`} title={`Email ${email}`}>
						<EmailIcon />
					</BasicIconButton>
				</>
			) : null}
		</LeadTextCell>
	);
}

const CopyButton = styled(ContentCopyIcon)`
	display: block;
	cursor: pointer;
	transition: 0.2s;
	color: white;

	&:hover,
	:focus-visible {
		color: #ffb310;
	}

	&:active {
		color: white;
		transform: scale(0.9);
	}
`;
