import { useEffect, useState } from 'react';
import Layout from '../components/reusable-components/Layout';
import styled from 'styled-components';
import Users from '../components/screen-components/UserManagement/Users';
import { Tab, Tabs } from '@mui/material';
import theme from '../styles/theme';
import Organizations from '../components/screen-components/UserManagement/Organizations';
import EquipmentTab from '../components/screen-components/UserManagement/EquipmentTab';

const tabs: { label: string; tab: JSX.Element }[] = [
	{ label: 'Users', tab: <Users /> },
	{ label: 'Organizations', tab: <Organizations /> },
	{ label: 'Equipment', tab: <EquipmentTab /> },
];

export default function UserManagement() {
	useEffect(() => {
		document.title = 'Neural Net | Robotic Imaging';
	}, []);

	const [tabIndex, setTabIndex] = useState(0);
	const changeTab = (e: React.SyntheticEvent, value: any) => {
		if (typeof value === 'number') setTabIndex(value);
	};

	return (
		<Layout title="Neural Net">
			<StyledTabs value={tabIndex} onChange={changeTab}>
				{tabs.map((tab, idx) => (
					<Tab label={tab.label} value={idx} key={idx} />
				))}
			</StyledTabs>

			<TabPanel>{tabs[tabIndex].tab}</TabPanel>
		</Layout>
	);
}

const StyledTabs = styled(Tabs)`
	width: fit-content;
	margin: 0 auto;
	height: 48px;

	.MuiTab-root {
		color: ${theme.palette.primary.main};
	}
`;

const TabPanel = styled.div`
	flex-grow: 1;
	flex-shrink: 1;
	max-height: calc(100% - 48px);
	overflow: auto;
`;
