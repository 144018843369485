import styled from 'styled-components';

export const UserRowContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 15px;
	background-color: white;
	border-radius: 0 0 8px 8px;
	padding: 10px;
`;

export const UserRow = styled.div`
	display: flex;
	gap: 10px;
	align-items: center;

	span.MuiListItemText-primary {
		overflow: hidden;
		text-overflow: ellipsis;
	}
`;
