import { TextField } from '@mui/material';
import styled from 'styled-components';

export default function ItemStringField({
	field,
	setState,
	label,
	type,
	value,
	InputProps,
}: {
	field: string;
	setState: React.Dispatch<React.SetStateAction<any>>;
	label: string;
	type?: string;
	value?: string | boolean;
	InputProps?: any;
}) {
	return (
		<TextFieldContainer>
			<TextField
				sx={{ width: '100%' }}
				label={label}
				value={value}
				InputProps={InputProps}
				InputLabelProps={
					type === 'custom' ? { className: 'customTextFieldLabel' } : undefined
				}
				onChange={e => {
					if (type === 'metadata') {
						setState((prev: any) => ({
							...prev,
							metadata: {
								...prev.metadata,
								[field]: e.target.value,
							},
						}));
					} else if (type === 'custom') {
						setState((prev: any) => ({
							...prev,
							customFields: {
								...prev.customFields,
								[field]: e.target.value,
							},
						}));
					} else {
						setState((prev: any) => ({
							...prev,
							[field]: e.target.value,
						}));
					}
				}}
			/>
		</TextFieldContainer>
	);
}

const TextFieldContainer = styled.div`
	min-width: 219px;
	@media screen and (max-width: 768px) {
		width: 100%;
	}
`;
