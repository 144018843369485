import { useContext, useState } from 'react';
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	TextField,
} from '@mui/material';
import LoadingScreen from '../../reusable-components/LoadingScreen';
import { UserObj } from '../../../types';
import { OrganizationsContext } from '../../../context/OrganizationsProvider';

const defaultUser: UserObj = {
	id: '',
	email: '',
	name: '',
};

export default function AddUserDialog({
	open,
	teamId,
	setOpen,
	organizationId,
	onSave,
}: {
	open: boolean;
	teamId: string;
	organizationId: string;
	setOpen: React.Dispatch<React.SetStateAction<boolean>>;
	onSave?: () => void;
}) {
	const { addingUserToTeam, addUserToTeam } = useContext(OrganizationsContext);

	const [form, setForm] = useState(defaultUser);

	const onChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
		const field = e.target.name;
		setForm(prev => ({
			...prev,
			[field]: e.target.value,
		}));
	};

	const handleClose = () => setOpen(false);

	const handleSubmit = async () => {
		if (!form.email) return;

		try {
			await addUserToTeam(form.email, teamId, organizationId);
			onSave?.();
		} catch (err) {
			console.error(err);
		} finally {
			handleClose();
			setForm(defaultUser);
		}
	};

	return (
		<Dialog open={open} onClose={handleClose} maxWidth="xs" fullWidth>
			<DialogTitle>Add user to team</DialogTitle>

			{!addingUserToTeam ? (
				<DialogContent>
					<TextField
						variant="standard"
						label="User email"
						name="email"
						value={form.email}
						onChange={onChange}
					/>
				</DialogContent>
			) : (
				<DialogContent>
					<LoadingScreen message="Adding user..." textColor="black" />
				</DialogContent>
			)}

			<DialogActions>
				{!addingUserToTeam ? (
					<>
						<Button onClick={handleClose}>Cancel</Button>
						<Button onClick={handleSubmit}>Add user</Button>
					</>
				) : null}
			</DialogActions>
		</Dialog>
	);
}
