import {
	Button,
	Chip,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	TextField,
} from '@mui/material';
import { SetStateAction, useContext, useRef, useState } from 'react';
import { Equipment } from './types';
import { collection, doc, setDoc } from 'firebase/firestore';
import { FirebaseFile } from '../../../../types';
import { v4 as uuidv4 } from 'uuid';
import { db, storage } from '../../../../firebase';
import { splitFilename } from '../../../../utils';
import { getDownloadURL, getMetadata, ref, uploadBytes } from 'firebase/storage';
import { SnackContext } from '../../../../context/SnackProvider';
import Spinner from '../../../reusable-components/Spinner';
import styled from 'styled-components';

interface AddEquipmentDialogProps {
	open: boolean;
	setOpen: React.Dispatch<SetStateAction<boolean>>;
	setEquipment: React.Dispatch<SetStateAction<Equipment[]>>;
}

type NewEquipment = Omit<Equipment, 'id' | 'createdAt' | 'files'>;
const emptyEquip: NewEquipment = {
	name: '',
	description: '',
	serialNumber: '',
};

export default function AddEquipmentDialog({
	open,
	setOpen,
	setEquipment,
}: AddEquipmentDialogProps) {
	const { setSnackbarProps } = useContext(SnackContext);

	const [addingEquipment, setAddingEquipment] = useState(false);
	const [equipEdit, setEquipEdit] = useState(emptyEquip);
	const [filesEdit, setFilesEdit] = useState<File[]>([]);

	const inputRef = useRef<HTMLInputElement>(null);

	const handleClose = () => {
		if (!addingEquipment) {
			setOpen(false);
			setEquipEdit(emptyEquip);
			setFilesEdit([]);
		}
	};

	const addEquipment = async (newEquipment: NewEquipment, files: File[]) => {
		setSnackbarProps({
			open: true,
			severity: 'warning',
			message: `Adding ${newEquipment.name}...`,
		});

		try {
			const newDoc = doc(collection(db, 'equipment'));

			const firebaseFiles: FirebaseFile[] = [];
			const fileUpload = files.map(async file => {
				const uuid = uuidv4();
				const [, extension] = splitFilename(file.name);
				const uniqueFilename = `${uuid}.${extension}`;
				const filepath = `equipment_files/${uniqueFilename}`;
				const storageRef = ref(storage, filepath);

				const metadata = {
					contentDisposition: `inline; filename="${file.name}"`,
					customMetadata: {
						originalFilename: file.name,
						equipmentId: newDoc.id,
					},
				};

				await uploadBytes(storageRef, file, metadata);
				const downloadUrl = await getDownloadURL(storageRef);
				const fetchedMetadata = await getMetadata(storageRef);
				const uploadDate = new Date(fetchedMetadata.timeCreated);

				firebaseFiles.push({
					filename: file.name,
					downloadUrl,
					filepath,
					uploadDate,
					lastUpdated: uploadDate,
				});
			});
			await Promise.all(fileUpload);

			const newEquipData: Equipment = {
				...newEquipment,
				id: newDoc.id,
				createdAt: new Date(),
				files: firebaseFiles,
			};

			await setDoc(newDoc, newEquipData);
			setEquipment(prev => [...prev, newEquipData]);

			setSnackbarProps({
				open: true,
				severity: 'success',
				message: `Added ${newEquipData.name}!`,
			});
		} catch (err) {
			console.error('Could not add equipment');
			console.log(err);
			setSnackbarProps({
				open: true,
				severity: 'error',
				message: 'Error adding equipment...',
			});
		}
	};

	const handleSubmit = async () => {
		if (!equipEdit.name) {
			setSnackbarProps({
				open: true,
				severity: 'warning',
				message: 'New equipment must have a name!',
			});
			return;
		}
		setAddingEquipment(true);
		await addEquipment(equipEdit, filesEdit);
		setAddingEquipment(false);
		handleClose();
	};

	const handleInputClick = () => {
		if (inputRef.current) {
			inputRef.current.click();
		}
	};

	const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const files = e.target.files;
		if (files) {
			setFilesEdit(prev => [...prev, ...Array.from(files)]);
		}
	};

	const handleRemoveFile = (idx: number) => {
		setFilesEdit(prev => {
			const tmp = [...prev];
			tmp.splice(idx, 1);
			return tmp;
		});
	};

	return (
		<Dialog open={open} onClose={handleClose}>
			<DialogTitle>Add Equipment</DialogTitle>
			<DialogContent>
				<TextField
					value={equipEdit.name}
					onChange={e =>
						setEquipEdit(prev => ({
							...prev,
							name: e.target.value,
						}))
					}
					placeholder="Name"
				/>
				<TextField
					value={equipEdit.serialNumber}
					onChange={e =>
						setEquipEdit(prev => ({
							...prev,
							serialNumber: e.target.value,
						}))
					}
					placeholder="Serial Number"
				/>
				<TextField
					value={equipEdit.description}
					onChange={e =>
						setEquipEdit(prev => ({
							...prev,
							description: e.target.value,
						}))
					}
					placeholder="Description"
				/>

				<FileChipContainer onClick={handleInputClick}>
					{filesEdit.length ? (
						filesEdit.map((file, idx) => (
							<Chip label={file.name} key={idx} onDelete={() => handleRemoveFile(idx)} />
						))
					) : (
						<span>No files selected...</span>
					)}
					<input type="file" multiple ref={inputRef} onChange={handleInputChange} />
				</FileChipContainer>
			</DialogContent>
			<DialogActions>
				{!addingEquipment ? (
					<>
						<Button onClick={handleClose}>Close</Button>
						<Button onClick={handleSubmit}>Submit</Button>
					</>
				) : (
					<Spinner size={40} />
				)}
			</DialogActions>
		</Dialog>
	);
}

const FileChipContainer = styled.div`
	display: flex;
	justify-content: center;
	flex-wrap: wrap;
	gap: 10px;

	padding: 10px;

	transition: 200ms;
	cursor: pointer;
	background-color: gray;
	&:hover {
		background-color: #aeaeae;
	}

	> input {
		display: none;
	}
`;
