import { Button, Chip, DialogContent } from '@mui/material';
import styled from 'styled-components';

export const ListItemContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
`;

export const EmptyContent = styled(DialogContent)`
	align-items: center;
	justify-content: center;
	display: flex;
`;

export const RemoveButton = styled(Button)`
	color: black;
	text-transform: none;
	margin-left: 5px;
`;

export const StyledChip = styled(Chip)`
	color: black;
	background-color: #f6f6f6;
	border: 2px solid #ffb310;
	.MuiChip-icon {
		color: black;
	}
`;
