import { FieldLabel } from '../../../../../screens/ProjectInfo';
import React, { useContext, useState } from 'react';
import { AuthContext } from '../../../../../context/AuthProvider';
import MultiLinkField from './MultiLinkField';
import { ProjectData } from '../../../../../types';

// Props for Point Cloud Links
interface PointCloudLinksProps {
	isActive: boolean;
	setPartialFormState: React.Dispatch<React.SetStateAction<Partial<ProjectData>>>;
}

// Point Cloud Links Information
export default function PointCloudLinks({
	isActive,
	setPartialFormState,
}: PointCloudLinksProps) {
	const { user } = useContext(AuthContext);

	const [editPointCloudLink, setEditPointCloudLink] = useState(false);

	return (
		<div className="infoRow">
			<FieldLabel label={'Point Cloud Link(s)'} />
			<MultiLinkField
				field={'pointCloudLinks'}
				title={'Point Cloud'}
				setState={setPartialFormState}
				isActive={Boolean(user?.isAdmin && isActive)}
				isFocused={editPointCloudLink}
				setFocused={setEditPointCloudLink}
			/>
		</div>
	);
}
