import { useContext, useState } from 'react';
import { FormNode } from '../../../../../utils/types';
import { FormV2Context } from '../../../../../context/FormV2Context.types';
import { Menu, MenuItem } from '@mui/material';
import { InputFieldContainer, PickerButton } from '../FormFillV2.styles';
import { ArrowDropDown, ArrowDropUp } from '@mui/icons-material';

interface SelectFieldProps {
	node: FormNode;
}

const SelectField: React.FC<React.PropsWithChildren<SelectFieldProps>> = ({ node }) => {
	const { updateValue } = useContext(FormV2Context);
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const open = Boolean(anchorEl);

	const handleClick = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = async (option?: string) => {
		setAnchorEl(null);
		if (option) {
			await updateValue(node.id, option);
		}
	};

	const options: string[] = node.options || [];

	return (
		<InputFieldContainer id={node.id}>
			<PickerButton aria-controls="menu" aria-haspopup="true" onClick={handleClick}>
				<>
					{node.value || 'Select Option'}
					{open ? <ArrowDropUp /> : <ArrowDropDown />}
				</>
			</PickerButton>
			<Menu
				id="menu"
				anchorEl={anchorEl}
				keepMounted
				open={open}
				onClose={() => handleClose()}>
				{options.map((option, index) => (
					<MenuItem key={index} onClick={() => handleClose(option)}>
						{option}
					</MenuItem>
				))}
			</Menu>
		</InputFieldContainer>
	);
};

export default SelectField;
