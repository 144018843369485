import { ContentContainer, RightColumn, LayoutContainer } from './styles';
import { AuthContext } from '../../../../context/AuthProvider';
import { UserHomeContext } from '../../../../context/UserHomeProvider';
import { OrganizationsContext } from '../../../../context/OrganizationsProvider';
import { useContext, useEffect, useState } from 'react';
import UserProjects from '../../../../components/reusable-components/UserProjects';
import UserReports from '../../../../components/reusable-components/UserReports';
import Layout from '../../../../components/reusable-components/Layout';
import UserMap from '../../../../components/reusable-components/UserMap';
import { MapProvider } from '../../../../context/MapProvider';

export default function ClientProjects() {
	const { setSelectedOrganizationId, organizations } = useContext(OrganizationsContext);
	const {
		loadData,
		templates,
		reports,
		setProjectsFiltered,
		setSelectedTeam,
		selectedTeam,
	} = useContext(UserHomeContext);
	const { user } = useContext(AuthContext);
	const [orgLogo, setOrgLogo] = useState('');
	const [orgName, setOrgName] = useState('');

	useEffect(() => {
		if (user?.id) {
			if (!templates && !reports) loadData(user.id);
		}
	}, [loadData, reports, templates, user?.id]);

	useEffect(() => {
		setSelectedOrganizationId(null);
	}, [setSelectedOrganizationId]);

	const userHasTeams =
		(user?.teamIds && user.teamIds?.length > 0) ||
		(user?.orgOwnerIds && user.orgOwnerIds.length);

	// Clear the projectsFiltered and selectedTeam when the component unmounts
	useEffect(() => {
		return () => {
			setProjectsFiltered([]);
			setSelectedTeam(null);
		};
	}, [setProjectsFiltered, setSelectedTeam]);

	useEffect(() => {
		if (selectedTeam) {
			const organization = organizations.find(
				org => org.id === selectedTeam.organizationId
			);
			if (organization) {
				setOrgName(organization.name);
				if (organization.logo && organization.logo.length > 0)
					setOrgLogo(organization.logo[0].downloadUrl);
			}
		} else {
			setOrgLogo('');
			setOrgName('');
		}
	}, [organizations, selectedTeam]);

	return (
		<Layout title={orgName.length ? orgName : 'Home'} orgLogo={orgLogo}>
			<LayoutContainer style={{ padding: '20px' }}>
				<ContentContainer>
					<UserProjects />

					{userHasTeams && (
						<RightColumn>
							<MapProvider>
								<UserMap />
							</MapProvider>
							<UserReports />
						</RightColumn>
					)}
				</ContentContainer>
			</LayoutContainer>
		</Layout>
	);
}
