import { Tooltip } from '@mui/material';
import { CoverImageItemProps } from './CoverImage.types';
import { StyledCard, StyledCardMedia } from './CoverImageStyles';
import { FC, memo, useState } from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

const CoverImageItem: FC<CoverImageItemProps> = memo(({ image, index, handleClick }) => {
	const [isDragging, setIsDragging] = useState(false);
	const { attributes, listeners, setNodeRef, transform, transition } = useSortable({
		id: image.downloadURL,
		transition: {
			duration: 0,
			easing: 'cubic-bezier(0.25, 1, 0.5, 1)',
		},
	});

	const handleMouseDown = () => {
		setIsDragging(false); // Assume dragging starts as false
	};

	const handleMouseUp = () => {
		if (!isDragging) {
			handleClick(); //If not dragging, on click, open gallery dialog.
		}
	};

	const handleDragStart = () => {
		setIsDragging(true); // Set dragging to true when drag starts
	};

	const style = {
		transform: CSS.Translate.toString(transform),
		transition,
	};

	const combinedListeners = {
		...listeners,
		onClick: () => handleClick(),
	};

	return (
		<div
			key={index}
			ref={setNodeRef}
			style={style}
			{...attributes}
			onMouseMove={handleDragStart}
			onMouseDown={handleMouseDown}
			onMouseUp={handleMouseUp}
			{...combinedListeners}>
			<StyledCard>
				<Tooltip title={image.name}>
					<StyledCardMedia image={image.downloadURL} />
				</Tooltip>
			</StyledCard>
		</div>
	);
});

export default CoverImageItem;
