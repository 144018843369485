import { Photo } from '../types';
import styled from 'styled-components';
import { useContext } from 'react';
import { UploadContext } from '../UploadContext';

import HourglassTopIcon from '@mui/icons-material/HourglassTop';

import { Button, Chip, DialogContent, ListItem } from '@mui/material';
import ProgressSpinner from '../../../reusable-components/ProgressSpinner';

export default function UploadManager({
	photoFiles,
	removePhoto,
}: {
	photoFiles: Photo[];
	removePhoto: (idx: number, photo: Photo) => void;
}) {
	const { currentUploadTask, currentUploadProgress } = useContext(UploadContext);

	return photoFiles.length ? (
		<DialogList>
			{photoFiles.map((photo, idx) => {
				return (
					<ListItemContainer key={idx}>
						<ListItem
							sx={{
								whiteSpace: 'noWrap',
								textOverflow: 'ellipsis',
								display: 'block',
								width: '100%',
								overflow: 'hidden',
							}}>
							{photo.name}
						</ListItem>

						{photo.url ? null : photo.hash === currentUploadTask?.hash ? (
							<ProgressSpinner value={Math.round(currentUploadProgress)} />
						) : (
							<Chip
								label="Queued for upload"
								icon={<HourglassTopIcon />}
								color="secondary"
								variant="outlined"
							/>
						)}
						<Button onClick={() => removePhoto(idx, photo)}>X</Button>
					</ListItemContainer>
				);
			})}
		</DialogList>
	) : (
		<EmptyContent>
			<h3>No files to display...</h3>
		</EmptyContent>
	);
}
const DialogList = styled(DialogContent)`
	display: flex;
`;
const ListItemContainer = styled.div`
	display: flex;
`;

const EmptyContent = styled(DialogContent)`
	align-items: center;
	justify-content: center;
`;
