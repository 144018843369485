import { InlineWidget } from 'react-calendly';
import Layout from '../components/reusable-components/Layout';

const Schedule = () => {
	return (
		<Layout title="Schedule">
			<InlineWidget url="https://calendly.com/roboticimaging" />
		</Layout>
	);
};

export default Schedule;
