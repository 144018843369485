import { useState } from 'react';

const useAddScratchForm = () => {
	const [addDialogOpen, setAddDialogOpen] = useState(false);

	const closeAddDialog = () => setAddDialogOpen(false);

	const openAddDialog = () => setAddDialogOpen(true);

	return {
		addDialogOpen,
		closeAddDialog,
		openAddDialog,
	};
};

export default useAddScratchForm;
