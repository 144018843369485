import { FC } from 'react';
import styled from 'styled-components';
import LoadingScreen from '../../LoadingScreen';

type ListContentProps = {
	loading: boolean;
	loadingAlternative?: boolean;
	list: any;
	renderItem: (item: any) => JSX.Element;
	loadingMessage?: string;
	loadingMessageAlternative?: string;
	emptyMessage?: string;
};

const ListContent: FC<React.PropsWithChildren<ListContentProps>> = ({
	list,
	loading,
	loadingAlternative,
	renderItem,
	emptyMessage,
	loadingMessage,
	loadingMessageAlternative,
}) => {
	return (
		<Container>
			{loading ? (
				<LoadingScreen message={loadingMessage} textColor="white" size={32} />
			) : list?.length ? (
				list.map((item: any) => renderItem(item))
			) : (
				<h3 style={{ margin: 'auto' }}>{emptyMessage}</h3>
			)}
			{!loading && loadingAlternative && (
				<LoadingScreen message={loadingMessageAlternative} textColor="white" size={32} />
			)}
		</Container>
	);
};

const Container = styled.div`
	display: flex;
	flex-direction: column;
	gap: 10px;
`;

export default ListContent;
