import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { TextField } from '@mui/material';

interface InputProps {
	field: string;
	value: string;
	setState: React.Dispatch<React.SetStateAction<any>>;
	styles?: {
		colorTheme: 'primary' | 'secondary';
		style: { fontSize: string; color: string };
	};
}

/**
 *
 * @param styles : Apply different style based on type of header (Item or Category)
 * @returns Editable text field for headers of Items and Custom Category.
 */

export default function EditableHeaderField({
	field,
	value = '',
	setState,
	styles,
}: InputProps) {
	const [focused, setFocused] = useState(false);
	const [text, setText] = useState(value);

	useEffect(() => {
		setText(value);
	}, [value]);

	if (focused) {
		return (
			<TextField
				value={text}
				variant="standard"
				color={styles?.colorTheme}
				autoFocus
				// inputProps={{ style: { fontSize: '0.875rem', color: fontColor } }}
				inputProps={{ style: styles?.style }}
				onBlur={() => {
					setFocused(false);
					setState((prev: any) => ({
						...prev,
						[field]: text,
					}));
				}}
				onKeyDown={e => {
					if (e.code === 'Enter') {
						setFocused(false);
						setState((prev: any) => ({
							...prev,
							[field]: text,
						}));
					}
				}}
				onChange={e => {
					setText(e.target.value);
				}}
			/>
		);
	} else {
		return (
			<EditableSpan
				onClick={() => setFocused(true)}
				onKeyUp={e => {
					if (e.code === 'Enter') {
						setFocused(true);
					}
				}}
				tabIndex={0}>
				{text || '-'}
			</EditableSpan>
		);
	}
}

const EditableSpan = styled.span`
	transition: 0.2s;
	cursor: pointer;
	min-width: 50px;
	padding-left: 10px;

	&:hover,
	&:focus-visible {
		color: #777777;
	}
`;
