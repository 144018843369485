import {
	collection,
	getFirestore,
	where,
	limit,
	getDocs,
	documentId,
	query,
	orderBy,
} from 'firebase/firestore';
import { useState, useCallback, useEffect, useContext } from 'react';
import { FormTemplate } from '../ProjectUtilityFormV2/utils/types';
import { AuthContext } from '../../../context/AuthProvider';

const useTemplates = (open: boolean, isPartial: boolean) => {
	const [templates, setTemplates] = useState<FormTemplate[] | undefined>();
	const [history, setHistory] = useState<FormTemplate[]>([]);
	const { user } = useContext(AuthContext);

	const fetchTemplates = useCallback(
		async (search: string) => {
			if (user?.isAdmin) {
				let baseQuery = query(
					collection(getFirestore(), 'utility_forms_v2_templates'),
					orderBy('name'),
					limit(5)
				);

				if (search.length > 2) {
					baseQuery = query(
						baseQuery,
						where('searchableName', 'array-contains', search.toLowerCase())
					);
				}

				if (isPartial) {
					baseQuery = query(baseQuery, where('isPartial', '==', true));
				} else {
					baseQuery = query(
						baseQuery,
						where('userId', '==', ''),
						where('isPartial', '==', false)
					);
				}

				const templatesCollection = await getDocs(baseQuery);
				const templatesData = templatesCollection.docs.map(doc => ({
					...(doc.data() as FormTemplate),
					id: doc.id,
				}));

				return templatesData;
			} else {
				const queryByTeamIds = query(
					collection(getFirestore(), 'utility_forms_v2_templates'),
					where('teamIds', 'array-contains-any', user?.teamIds),
					where('isPartial', '==', isPartial),
					orderBy('name')
				);

				let queryByUserId = query(
					collection(getFirestore(), 'utility_forms_v2_templates'),
					orderBy('name')
				);

				if (isPartial) {
					queryByUserId = query(
						collection(getFirestore(), 'utility_forms_v2_templates'),
						where('isPartial', '==', isPartial),
						orderBy('name')
					);
				} else {
					queryByUserId = query(
						collection(getFirestore(), 'utility_forms_v2_templates'),
						where('userId', '==', user?.id),
						where('isPartial', '==', false),
						orderBy('name')
					);
				}

				const [resultByTeamIds, resultByUserId] = await Promise.all([
					getDocs(queryByTeamIds),
					getDocs(queryByUserId),
				]);

				const templatesDataByTeamIds = resultByTeamIds.docs.map(doc => ({
					...(doc.data() as FormTemplate),
					id: doc.id,
				}));

				const templatesDataByUserId = resultByUserId.docs.map(doc => ({
					...(doc.data() as FormTemplate),
					id: doc.id,
				}));

				const list: FormTemplate[] = [];

				for (const template of templatesDataByTeamIds) {
					if (!list.some(t => t.id === template.id)) list.push(template);
				}

				for (const template of templatesDataByUserId) {
					if (!list.some(t => t.id === template.id)) list.push(template);
				}

				return list
					.filter(template => template.name.toLowerCase().includes(search.toLowerCase()))
					.slice(0, 5);
			}
		},
		[isPartial, user?.id, user?.isAdmin, user?.teamIds]
	);

	const onChangeQuery = async (event: React.ChangeEvent<HTMLInputElement>) => {
		const searchQuery = event.target.value;

		const templatesData = await fetchTemplates(searchQuery);

		setTemplates(templatesData);
	};

	const loadLatestTemplates = useCallback(async () => {
		const templatesData = await fetchTemplates('');
		setTemplates(templatesData);
	}, [fetchTemplates]);

	useEffect(() => {
		setTemplates(undefined);
		if (open) loadLatestTemplates();
	}, [loadLatestTemplates, open, setTemplates]);

	const addToHistory = async (template: FormTemplate) => {
		const json = localStorage.getItem('templateHistory');
		const history = json ? JSON.parse(json) : [];
		const newHistory = [...history, template.id];
		if (newHistory.length > 10) newHistory.shift();
		localStorage.setItem('templateHistory', JSON.stringify(newHistory));
		loadHistory(newHistory);
	};

	const loadHistory = useCallback(
		async (ids: string[]) => {
			if (ids.length === 0) return;
			let baseQuery;
			if (user?.isAdmin) {
				baseQuery = query(
					collection(getFirestore(), 'utility_forms_v2_templates'),
					where(documentId(), 'in', ids),
					limit(3)
				);
			} else
				baseQuery = query(
					collection(getFirestore(), 'utility_forms_v2_templates'),
					where(documentId(), 'in', ids),
					where('teamIds', 'array-contains-any', user?.teamIds),
					limit(3)
				);

			const historyQuery = await getDocs(baseQuery);

			setHistory(
				historyQuery.docs.map(item => ({ ...(item.data() as FormTemplate), id: item.id }))
			);
		},
		[user?.isAdmin, user?.teamIds]
	);

	useEffect(() => {
		const json = localStorage.getItem('templateHistory');
		if (!json) return;
		const history = JSON.parse(json);
		loadHistory(history);
	}, [loadHistory]);

	return {
		templates,
		history,
		onChangeQuery,
		addToHistory,
	};
};

export default useTemplates;
