import { useContext, useState } from 'react';
import PersonSearchIcon from '@mui/icons-material/PersonSearch';
import { YellowIconButton } from '../../../components/styled-components/buttons';
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	MenuItem,
	Select,
} from '@mui/material';
import styled from 'styled-components';
import { ProjectContext } from '../../../context/ProjectProvider';
import { AuthContext } from '../../../context/AuthProvider';
import { UserObj } from '../../../types';

export default function AccountManagerFilterButton() {
	const { projectRoleOptions } = useContext(AuthContext);
	const { accountManagerFilter, setAccountManagerFilter } = useContext(ProjectContext);

	const [showDialog, setShowDialog] = useState(false);

	const handleClose = () => {
		setShowDialog(false);
	};

	return (
		<>
			<YellowIconButton
				title="Filter projects by account manager"
				size="large"
				onClick={() => setShowDialog(true)}>
				<PersonSearchIcon />
			</YellowIconButton>

			<Dialog open={showDialog} onClose={handleClose}>
				<DialogTitle>Account Manager Filter</DialogTitle>

				<StyledDialogContent>
					<span>Filter projects by the selected account manager:</span>
					<Select
						displayEmpty
						value={accountManagerFilter?.id}
						onChange={e => {
							const selectedId = e.target.value;
							if (selectedId) {
								const selectedManager = projectRoleOptions['accountManager'].find(
									userWithData => userWithData.id === selectedId
								);
								if (selectedManager) {
									const userObj: UserObj = {
										email: selectedManager.email,
										name: selectedManager.fullName,
										id: selectedManager.id,
									};
									setAccountManagerFilter(userObj);
								}
							} else {
								setAccountManagerFilter(null);
							}
						}}>
						<MenuItem value={''}>None</MenuItem>
						{projectRoleOptions['accountManager'].map(userWithData => (
							<MenuItem value={userWithData.id} key={userWithData.id}>
								{userWithData.fullName}
							</MenuItem>
						))}
					</Select>
				</StyledDialogContent>

				<DialogActions>
					<Button onClick={handleClose}>Close</Button>
				</DialogActions>
			</Dialog>
		</>
	);
}

const StyledDialogContent = styled(DialogContent)`
	align-items: center;
`;
