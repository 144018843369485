import { auth } from './index';
import {
	signInAnonymously,
	sendPasswordResetEmail,
	signInWithEmailAndPassword,
} from 'firebase/auth';
import axios from 'axios';

/**
 * Signs into Firebase Authentication with email and password
 * @param {*} email
 * @param {*} password
 * @returns Promise that calls getCustomToken
 */
export const loginWithEmail = (email, password) => {
	return new Promise(async (resolve, reject) => {
		await signInWithEmailAndPassword(auth, email, password).catch(e =>
			reject({
				status: 'error',
				message: e.message,
			})
		);
		resolve();
	});
};

/**
 * Signs in anonymously
 * @returns Promise containing user credential
 */
export const loginAnonymously = () => {
	return new Promise((resolve, reject) => {
		signInAnonymously(auth)
			.then(credential => {
				resolve(credential.user);
			})
			.catch(e => {
				reject(e);
			});
	});
};

/**
 * Sends password reset to given email
 * @param {*} email Email String
 * @returns
 */
export const passwordReset = email => sendPasswordResetEmail(auth, email);

export const bccMailingList = [
	'mike@roboticimaging.com',
	'nick@roboticimaging.com',
	'joe@roboticimaging.com',
	'will@roboticimaging.com',
	'matt@roboticimaging.com',
	'austin@roboticimaging.com',
	'denton@roboticimaging.com',
];

/**
 * Calls sendMail Firebase Function
 */
export const sendEmail = ({ destination, subject, text, html, cc, bcc }) => {
	return new Promise((resolve, reject) => {
		axios
			.post(`${process.env.REACT_APP_firebase_function_domain}/smtpMailers-sendMail`, {
				destination,
				cc,
				bcc,
				subject,
				text: text.trim().replace(/\t/g, ''),
				html: html ? html.trim().replace(/\t/g, '') : '',
			})
			.then(response => resolve(response))
			.catch(err => reject(err));
	});
};
