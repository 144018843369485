import { Checkbox } from '@mui/material';
import {
	ProjectScopeArea,
	ProjectScopingData,
} from '../../../../../ProjectScoping/types';
import styled from 'styled-components';
import theme from '../../../../../../../styles/theme';

export default function ProjectScopingCell({
	projectScoping,
	scopingArea,
}: {
	projectScoping: ProjectScopingData;
	scopingArea: ProjectScopeArea | 'Drone';
}) {
	const hasScopingArea =
		scopingArea !== 'Drone'
			? Boolean(
					projectScoping?.scopes.some(projectScope =>
						projectScope.areas.some(
							area => area.name === scopingArea && Boolean(area.value)
						)
					)
			  )
			: Boolean(
					projectScoping?.scopes.some(
						projectScope =>
							projectScope.name === 'Drone' &&
							projectScope.areas.some(area => Boolean(area.value))
					)
			  );

	return (
		<StyledCheckbox
			disabled
			checked={hasScopingArea}
			onClick={e => {
				e.stopPropagation();
			}}
			sx={{
				color: 'white !important',
			}}
		/>
	);
}

const StyledCheckbox = styled(Checkbox)`
	z-index: 99999;
	cursor: pointer;

	:hover {
		color: ${theme.palette.primary.main};
	}
`;
