import { Button } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { useContext } from 'react';
import { FormContext } from '../FormContext';
import { UploadContext } from '../UploadContext';
import { useParams } from 'react-router-dom';

export default function DeleteItem({
	categoryIndex,
	itemIndex,
}: {
	categoryIndex: number;
	itemIndex: number;
}) {
	const { formId } = useParams() as { [key: string]: string };

	const { setFormState, formState } = useContext(FormContext);
	const { deleteTask, deleteFromStorage } = useContext(UploadContext);

	const deleteItem = (index: number) => {
		const itemId = formState[categoryIndex].items[itemIndex].itemId;
		const categoryId = formState[categoryIndex].categoryId;

		//Delete item from upload queue
		deleteTask(formId, categoryId, itemId);

		//Delete files that have finished uploading from storage
		formState[categoryIndex].items[itemIndex].photos.forEach(photo => {
			if (photo.url !== undefined) {
				const filePath = `utility_forms/${formId}/${categoryId}/${itemId}/photos/${photo.name}`;
				deleteFromStorage(filePath);
			}
		});

		//Delete from formstate
		setFormState(prev => {
			const oldCategory = prev[categoryIndex];
			let oldItems = prev[categoryIndex].items;

			oldItems.splice(index, 1);

			const updatedCategory = {
				...oldCategory,
				items: oldItems,
			};

			prev.splice(categoryIndex, 1, updatedCategory);
			return [...prev];
		});
	};

	return (
		<Button
			size="small"
			color="secondary"
			title="Delete Item"
			onClick={() => deleteItem(itemIndex)}>
			<DeleteIcon fontSize="small" />
		</Button>
	);
}
