import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';

import { ProjectContext } from '../../../../../context/ProjectProvider';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';

import LaunchIcon from '@mui/icons-material/Launch';
import { projectStatusText } from '../../../../../utils';
import styled from 'styled-components';
import theme from '../../../../../styles/theme';

/**
 * Functional component for displaying an info card when a project pin on the Map component is clicked
 * @param project Object containing project data
 */
export default function ProjectCard({ project }: any) {
	const { setProjectsSelected } = useContext(ProjectContext);
	const navigate = useNavigate();

	const navigateTo = (proj: any) => {
		setProjectsSelected([]);
		navigate(`/projects/${proj.id}`);
	};
	return (
		<StyledCard>
			<CardDetails>
				<Controls>
					<IconButton
						aria-label="navigate-to-project"
						title="Navigate to project"
						onClick={() => navigateTo(project)}
						sx={{ ...iconStyles }}>
						<LaunchIcon />
					</IconButton>
				</Controls>
			</CardDetails>

			<StyledCardContent>
				<Typography variant="caption" color="white">
					{project.address}
				</Typography>

				<Typography variant="caption" color="grey">
					{`Status: ${projectStatusText(project.projectStatus)}`}
				</Typography>
			</StyledCardContent>
		</StyledCard>
	);
}

const StyledCard = styled(Card)`
	display: flex;
	background-color: ${theme.palette.secondary.main};
	max-height: 210px;
	max-width: 250px;
	padding-top: 12px;
	margin-bottom: -12px;
`;

const CardDetails = styled.div`
	display: flex;
	flex-direction: column;
`;

const StyledCardContent = styled(CardContent)`
	flex: 14;
	padding: 0;
	display: flex;
	flex-direction: column;
	padding-right: 10px;
`;

const Controls = styled.div`
	display: flex;
	align-items: center;
`;

const iconStyles = {
	color: theme.palette.common.black,
	filter: 'invert(100%)',
	ml: '-12px',
};
