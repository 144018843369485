import { Box, Divider, IconButton } from '@mui/material';
import { ModelDataProps } from './ModelData.types';
import { FC, useState } from 'react';
import { Link } from 'react-router-dom';
import theme from '../../../../../styles/theme';
import { styled } from '@mui/system';
import { ExpandLess, ExpandMore } from '@mui/icons-material';

const ModelData: FC<ModelDataProps> = ({ model }) => {
	const [visible, setVisible] = useState(false);

	return (
		<Box
			display="flex"
			flexDirection="column"
			justifyContent="space-between"
			width="100%"
			paddingLeft="16px">
			{model && model.data?.length > 0 && (
				<>
					<Box key="Manufacturer">
						<span style={{ color: 'white' }}>
							<b style={{ color: 'gray' }}>Manufacturer</b>: {model.manufacturer?.name}
						</span>
					</Box>
					<Box key="Equipment">
						<span style={{ color: 'white' }}>
							<b style={{ color: 'gray' }}>Equipment:</b> {model.equipment?.name}
						</span>
					</Box>
					<Box key="Pdf">
						<span style={{ color: 'white' }}>
							<b style={{ color: 'gray' }}>PDF:</b>{' '}
							<Link
								to={model?.pdfUrl}
								target="_blank"
								rel="noreferrer"
								style={{ textDecoration: 'unset', color: theme.palette.primary.main }}>
								Click here to open
							</Link>
						</span>
					</Box>
					<Divider style={{ margin: '4px 0' }} />
					<Box display="flex" justifyContent="space-between" alignItems="center">
						<ModelDataTitle>Model data</ModelDataTitle>
						<IconButton onClick={() => setVisible(!visible)}>
							{visible ? <ExpandLess color="primary" /> : <ExpandMore color="primary" />}
						</IconButton>
					</Box>
					{visible &&
						model.data?.map((item: any) => (
							<Box key={item.id}>
								<span style={{ color: 'white' }}>
									<b style={{ color: 'gray' }}>{item.code}</b>: {item.description}
								</span>
							</Box>
						))}
				</>
			)}
		</Box>
	);
};

export default ModelData;

const ModelDataTitle = styled(Box)`
	font-weight: bold;
	color: ${theme.palette.primary.main};
`;
