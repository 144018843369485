import { useContext, useMemo } from 'react';
import { ProjectDataProcore } from '../../../../../../types';
import { ProjectContext } from '../../../../../../context/ProjectProvider';
import ProcoreInput from './ProcoreInput';

type Props = {
	isActive: boolean;
	onChange: (data: ProjectDataProcore) => void;
};

const SelectProcoreProject = ({ isActive, onChange }: Props) => {
	const { project } = useContext(ProjectContext);

	const orgs = useMemo(() => project?.orgs, [project?.orgs]);

	return (
		<>
			{orgs?.map(org => (
				<ProcoreInput
					key={org.id}
					orgId={org.id}
					orgName={org.name}
					isActive={isActive}
					onChange={onChange}
				/>
			))}
		</>
	);
};

export default SelectProcoreProject;
