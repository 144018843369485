import { useContext, useEffect, useState } from 'react';
import AddNewItem from '../utils/AddNewItem';
import {
	newCustomItem,
	disconnectMetadataMap,
	electricMeterMetadataMap,
	servicePanelMetadataMap,
	hvacMetadataMap,
	fireAlarmControlPanelMetadataMap,
	waterHeaterMetadataMap,
	waterMeterMetadataMap,
} from '../utils/BaseItems';

import { FormContext } from '../FormContext';
import { Category, Item } from '../types';
import CreateItem from '../items/CreateItem';
import { Button } from '@mui/material';
import EditableHeaderField from '../utils/EditableHeaderField';

import DeleteIcon from '@mui/icons-material/Delete';
import styled from 'styled-components';
import { UploadContext } from '../UploadContext';
import { useParams } from 'react-router-dom';

export default function CreateCategory({
	category,
	categoryIndex,
	categoryItems,
}: {
	category: Category;
	categoryIndex: number;
	categoryItems: Item[];
}) {
	const { formId } = useParams() as { [key: string]: string };

	const { setFormState, formState, wrappedDebounce } = useContext(FormContext);
	const { deleteTask, deleteFromStorage } = useContext(UploadContext);

	const [categoryState, setCategoryState] = useState(formState[categoryIndex]);

	// Keeps category component up to date with global formState
	useEffect(() => {
		setCategoryState(formState[categoryIndex]);
	}, [formState, categoryIndex]);

	// Keeps global formState up to date with category data
	useEffect(() => {
		setFormState(prev => {
			prev.splice(categoryIndex, 1, categoryState);
			return prev;
		});
	}, [categoryState, categoryIndex, setFormState]);

	const deleteCategory = (categoryIndex: number) => {
		//Delete category from upload queue
		deleteTask(formId, category.categoryId);

		//Delete files that have finished uploading from storage
		const categoryId = formState[categoryIndex].categoryId;
		formState[categoryIndex].items.forEach(item => {
			const itemId = item.itemId;
			item.photos.forEach(photo => {
				if (photo.url !== undefined) {
					const filePath = `utility_forms/${formId}/${categoryId}/${itemId}/photos/${photo.name}`;
					deleteFromStorage(filePath);
				}
			});
		});

		setFormState(prev => {
			prev.splice(categoryIndex, 1);
			return [...prev];
		});

		wrappedDebounce();
	};

	const itemReturn = (item: Item, idx: number, itemId: string) => {
		switch (item.type) {
			case 'SERVICE_PANEL':
				return (
					<CreateItem
						key={itemId}
						categoryId={category.categoryId}
						categoryIndex={categoryIndex}
						itemIndex={idx}
						metadataMap={servicePanelMetadataMap}
					/>
				);
			case 'DISCONNECT':
				return (
					<CreateItem
						key={itemId}
						categoryId={category.categoryId}
						categoryIndex={categoryIndex}
						itemIndex={idx}
						metadataMap={disconnectMetadataMap}
					/>
				);
			case 'ELECTRIC_METER':
				return (
					<CreateItem
						key={itemId}
						categoryId={category.categoryId}
						categoryIndex={categoryIndex}
						itemIndex={idx}
						metadataMap={electricMeterMetadataMap}
					/>
				);

			case 'HVAC':
				return (
					<CreateItem
						key={itemId}
						categoryId={category.categoryId}
						categoryIndex={categoryIndex}
						itemIndex={idx}
						metadataMap={hvacMetadataMap}
					/>
				);

			case 'WATER_HEATER':
				return (
					<CreateItem
						key={itemId}
						categoryId={category.categoryId}
						categoryIndex={categoryIndex}
						itemIndex={idx}
						metadataMap={waterHeaterMetadataMap}
					/>
				);
			case 'WATER_METER':
				return (
					<CreateItem
						key={itemId}
						categoryId={category.categoryId}
						categoryIndex={categoryIndex}
						itemIndex={idx}
						metadataMap={waterMeterMetadataMap}
					/>
				);
			case 'FIRE_ALARM_CONTROL_PANEL':
				return (
					<CreateItem
						key={itemId}
						categoryId={category.categoryId}
						categoryIndex={categoryIndex}
						itemIndex={idx}
						metadataMap={fireAlarmControlPanelMetadataMap}
					/>
				);

			case 'CUSTOM':
				return (
					<CreateItem
						key={itemId}
						categoryId={category.categoryId}
						categoryIndex={categoryIndex}
						itemIndex={idx}
					/>
				);
			default:
				return null;
		}
	};

	return (
		<div>
			{category.type === 'CUSTOM' ? (
				<HeaderContainer>
					<CategoryHeader>
						<EditableHeaderField
							field="displayTitle"
							value={formState[categoryIndex].displayTitle || ''}
							setState={setCategoryState}
							styles={{
								colorTheme: 'primary',
								style: { fontSize: '0.975rem', color: '#ffb310' },
							}}
						/>
					</CategoryHeader>

					<AddNewItem categoryItems={[newCustomItem()]} categoryIndex={categoryIndex} />
					<CategoryButton
						onClick={() => deleteCategory(categoryIndex)}
						title="Delete Category">
						<DeleteIcon />
					</CategoryButton>
				</HeaderContainer>
			) : (
				<HeaderContainer>
					<CategoryHeader>{formState[categoryIndex].displayTitle}</CategoryHeader>
					<AddNewItem categoryItems={categoryItems} categoryIndex={categoryIndex} />
				</HeaderContainer>
			)}

			{formState[categoryIndex].items.map((item, i) => itemReturn(item, i, item.itemId))}
		</div>
	);
}

const HeaderContainer = styled.div`
	background-color: black;
	color: #ffb310;
	height: 60px;
	line-height: normal;

	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	gap: 10px;
`;

const CategoryHeader = styled.h2`
	margin: 0;
`;

export const CategoryButton = styled(Button)`
	padding: 0;
	min-width: 40px;
	min-height: 40px;
`;
