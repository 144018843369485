// import { CircularProgress } from '@mui/material';
import styled from 'styled-components';
import GlowLoader from './GlowLoader/GlowLoader';

export default function Spinner({
	size,
	style,
	relativeWidth,
	relativeHeight,
	variant = 'indeterminate',
	value,
}: {
	size?: number;
	style?: React.CSSProperties;
	relativeWidth?: string;
	relativeHeight?: string;
	variant?: 'determinate' | 'indeterminate';
	value?: number;
}) {
	if (relativeHeight && relativeWidth) {
		throw new Error('Cannot provide both relative width and relative height!');
	}

	const styling: React.CSSProperties = {
		display: 'flex',
		aspectRatio: '1',
	};
	if (relativeWidth) {
		styling.width = relativeWidth;
		styling.height = undefined;
	}
	if (relativeHeight) {
		styling.height = relativeHeight;
		styling.width = undefined;
	}

	return (
		// <CircularProgress
		// 	color="primary"
		// 	variant={variant}
		// 	value={value}
		// 	size={size}
		// 	style={{ ...styling, ...style }}
		// />
		<GlowLoader size={size} />
	);
}

export const SpinnerContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 5px;
	align-items: center;
	justify-content: center;
`;
