import FormWrapper from './components/FormWrapper';
import styled from 'styled-components';
import { FormNavigationMenu } from '../../form-components';
import theme from '../../../styles/theme';
import { useMediaQuery } from '@mui/system';
import { createContext, useContext, useEffect, useRef, useState } from 'react';
import { FormNode } from '../ProjectUtilityFormV2/utils/types';
import { useSearchParams } from 'react-router-dom';
import { FormV2Context } from '../ProjectUtilityFormV2/context/FormV2Context.types';

interface FormProps {
	formId: string;
	projectId: string;
	isTemplate?: boolean;
}

interface MenuReorderContextType {
	expandedList: string[];
	toggleItem: (id: string) => void;
	toggleAllItems: (id: string[]) => void;
	setSelectedItem: React.Dispatch<React.SetStateAction<FormNode | undefined>>;
	selectedItem: FormNode | undefined;
	setSelectedSection?: React.Dispatch<React.SetStateAction<FormNode | undefined>>;
	selectedSection?: FormNode | undefined;
	scrollMenuRef: React.RefObject<HTMLDivElement>;
	handleScrollMenu: (scroll: React.UIEvent<HTMLDivElement, UIEvent>) => void;
	setMasterScroll: React.Dispatch<React.SetStateAction<'left' | 'right' | undefined>>;
	handleHighlight: (id: string, add: boolean) => void;
}
export const MenuReorderContext = createContext({} as MenuReorderContextType);

export default function Form({ formId, projectId }: FormProps) {
	const matches = useMediaQuery('(max-width:1024px)');
	const matchesSM = useMediaQuery(theme.breakpoints.down('sm'));
	const [selectedItem, setSelectedItem] = useState<FormNode | undefined>();
	const [selectedSection, setSelectedSection] = useState<FormNode | undefined>();

	const [expandedList, setExpandedList] = useState<string[]>([]);
	const { currentNode } = useContext(FormV2Context);

	const [searchParams] = useSearchParams();
	const itemId = searchParams.get('itemId');

	const [masterScroll, setMasterScroll] = useState<'left' | 'right' | undefined>();

	const scrollMenuRef = useRef<HTMLDivElement>(null);
	const scrollFormRef = useRef<HTMLDivElement>(null);

	const handleScrollMenu = (scroll: React.UIEvent<HTMLDivElement, UIEvent>) => {
		const target = scroll.target as HTMLDivElement;

		if (scrollFormRef.current && masterScroll === 'left') {
			const menuScrollRatio =
				target.scrollTop / (target.scrollHeight - target.clientHeight);
			scrollFormRef.current.scrollTop = Math.floor(
				menuScrollRatio *
					(scrollFormRef.current.scrollHeight - scrollFormRef.current.clientHeight)
			);
		}
	};

	const handleScrollForm = (scroll: React.UIEvent<HTMLDivElement, UIEvent>) => {
		const target = scroll.target as HTMLDivElement;

		if (scrollMenuRef.current && masterScroll === 'right') {
			const formScrollRatio =
				target.scrollTop / (target.scrollHeight - target.clientHeight);
			scrollMenuRef.current.scrollTop = Math.floor(
				formScrollRatio *
					(scrollMenuRef.current.scrollHeight - scrollMenuRef.current.clientHeight)
			);
		}
	};

	useEffect(() => {
		if (itemId) {
			setTimeout(() => {
				const item = document.getElementById(`${itemId}-scroll`);
				item?.scrollIntoView();
			}, 1800);
			setTimeout(() => {
				const dialog = document.getElementById(`${itemId}-comments`);
				dialog?.click();
			}, 2500);
		}
	}, [itemId, currentNode]);

	const toggleItem = (id: string) => {
		if (expandedList.includes(id)) setExpandedList(expandedList.filter(e => e !== id));
		else setExpandedList([...expandedList, id]);
	};

	const toggleAllItems = (id: string[]) => {
		setExpandedList(id);
	};

	const scrollToAndHighlight = (itemId: string) => {
		let item = document.getElementById(itemId);
		item?.scrollIntoView();

		item?.animate(
			[
				// Keyframes
				{ backgroundColor: theme.palette.grey[500] },
				{ backgroundColor: 'initial' },
			],
			{
				duration: 1500,
				easing: 'ease-out',
			}
		);

		item = document.getElementById(itemId);
		item?.scrollIntoView();
	};

	const handleHighlight = (id: string, add: boolean) => {
		let item = document.getElementById(id);

		if (add) {
			item?.animate([{ borderBottom: `2px solid ${theme.palette.primary.main}` }], {
				duration: 500,
				easing: 'ease-out',
				fill: 'forwards',
			});
		} else {
			item?.animate([{ borderBottom: '2px solid transparent' }], {
				duration: 500,
				easing: 'ease-out',
				fill: 'forwards',
			});
		}
	};

	useEffect(() => {
		if (selectedItem) scrollToAndHighlight(selectedItem.id);
	}, [selectedItem]);

	useEffect(() => {
		if (selectedSection) scrollToAndHighlight(`section-${selectedSection.id}`);
	}, [selectedSection]);

	return (
		<MenuReorderContext.Provider
			value={{
				expandedList,
				toggleItem,
				toggleAllItems,
				setSelectedItem,
				selectedItem,
				setSelectedSection,
				selectedSection,
				scrollMenuRef,
				handleScrollMenu,
				setMasterScroll,
				handleHighlight,
			}}>
			<Container>
				{!matches && (
					<MenuContainer>
						<FormNavigationMenu formId={formId} projectId={projectId} />
					</MenuContainer>
				)}
				<FormContainer
					onScroll={handleScrollForm}
					ref={scrollFormRef}
					onMouseEnter={() => setMasterScroll('right')}
					onMouseLeave={() => setMasterScroll(undefined)}>
					<FormWrapper
						formId={formId}
						projectId={projectId}
						indentationWidth={matchesSM ? 25 : 50}
					/>
				</FormContainer>
			</Container>
		</MenuReorderContext.Provider>
	);
}

const FormContainer = styled.div`
	display: flex;
	flex-direction: column;
	flex-grow: 1;

	/* color: #ffb310; */
	background-color: ${theme.palette.secondary.main};
	height: 100%;
	overflow-y: auto;
	padding: 0 5px;
`;

const MenuContainer = styled.div`
	flex-shrink: 0;
`;

const Container = styled.div`
	display: flex;
	flex-direction: row;
	height: 100%;
	background-color: ${theme.palette.secondary.main};
`;
