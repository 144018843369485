import styled from 'styled-components';

export const OverviewHeaderContainer = styled.div`
	position: relative;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	width: 100%;
`;


export const OverviewText = styled.span`
	font-family: 'Epilogue';
	color: black;
	font-weight: 600;
	font-size: 1.5rem;
	text-align: center;
`;
