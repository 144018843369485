import { useContext } from 'react';
import { AuthContext } from '../../../../context/AuthProvider';
import { FieldLabel } from '../../../../screens/ProjectInfo';
import { ProjectInfoTextField } from '../../ProjectInfo/styled-components';

interface PaymentTermsProps {
	isActive: boolean;
	handleChange: (value: number | undefined) => void;
	paymentTerm: number | undefined;
}

export default function PaymentTerms({
	isActive,
	handleChange,
	paymentTerm,
}: PaymentTermsProps) {
	const { user } = useContext(AuthContext);

	const displayStr = typeof paymentTerm === 'number' ? `Net - ${paymentTerm}` : '';

	const onChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
		let newValue: number | undefined;
		if (e.target.value === '' || e.target.value === undefined) newValue = undefined;
		else newValue = Number(Number(e.target.value).toFixed(2));
		handleChange(newValue);
	};

	return user?.isAdmin ? (
		<div className="infoRow">
			<FieldLabel label="Payment Terms" />
			{isActive ? (
				<ProjectInfoTextField
					type="number"
					defaultValue={paymentTerm}
					onChange={onChange}
					fullWidth
				/>
			) : (
				<span className="fieldInfo">{displayStr}</span>
			)}
		</div>
	) : null;
}
