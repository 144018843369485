import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import styled from 'styled-components';

export const Info = styled(Dialog)`
	& .MuiDialog-paper {
		min-width: 40vw;
		height: 600px;
	}

	& .MuiTypography-root-MuiDialogTitle-root + div.MuiDialogContent-root {
		padding: 0px;
	}
`;

export const InfoTitle = styled(DialogTitle)`
	display: flex;
	flex-direction: column;
	padding: 15px;
`;

export const InfoText = styled.span`
	font-size: 1.4rem;
	font-weight: 300;
	line-height: normal;
`;

export const ItemText = styled.span`
	font-size: 1rem;
	line-height: normal;
	font-weight: 300;
	color: #ffb310;
`;

export const InfoContent = styled(DialogContent)`
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	height: 100%;
	padding: 0px 15px;
	overflow-y: scroll;
	& .MuiDialogContent-root {
		justify-content: flex-end;
		align-items: flex-start;
		padding: 0px;
	}
`;

export const InfoDetails = styled.div`
	display: flex;
	flex-direction: column;
	position: relative;
	margin-top: 8px;
	font-size: 0.75rem;
	color: #656;
`;

export const StyledActions = styled(DialogActions)``;

export const StyledButton = styled(Button)`
	text-transform: none;
`;
