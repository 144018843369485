import { useEffect, useState } from 'react';
import { Row } from 'react-table';
import { isRowComplete } from '../utils';
import { ProjectData, ProjectStatus } from '../../../../../../types';
import { BodyCell, BodyRow } from '../../../../../styled-components/styledReactTable';
import { useNavigate } from 'react-router-dom';
import { SortBy } from '..';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../../../../../../firebase';
import { ProjectScopingData } from '../../../../ProjectScoping/types';

export default function StatusTableRow({
	row,
	sortBy,
	status,
	style,
}: {
	row: Row<ProjectData>;
	sortBy: SortBy | null;
	status: ProjectStatus;
	style?: React.CSSProperties;
}) {
	const [editing, setEditing] = useState(false);
	const navigate = useNavigate();

	const projectId = row.original.id || '';
	const [projectScoping, setProjectScoping] = useState<ProjectScopingData | undefined>();

	useEffect(() => {
		if (status === 'To Be Scheduled') {
			const projectScopingDoc = doc(db, `projects/${projectId}/private/projectScoping`);
			getDoc(projectScopingDoc)
				.then(res => {
					setProjectScoping(res.data() as ProjectScopingData);
				})
				.catch(err => {
					console.error(
						`Could not get project scoping for project with id "${projectId}"`
					);
					console.log(err);
				});
		}
	}, [projectId, status]);

	const handleClick = () => {
		navigate(`/projects/${projectId}`);
	};

	return (
		<BodyRow
			{...row.getRowProps()}
			key={row.original.id}
			completed={isRowComplete(row)}
			onClick={handleClick}
			style={style}>
			{row.cells.map((cell, i) => {
				return (
					<BodyCell
						{...cell.getCellProps()}
						style={{ width: cell.column.width }}
						key={`${i} ${cell.value}`}>
						{cell.render('Cell', {
							rowEditing: editing,
							setRowEditing: setEditing,
							sortBy,
							projectScoping,
						})}
					</BodyCell>
				);
			})}
		</BodyRow>
	);
}
