import React, { useContext, useState } from 'react';
import { FormNode } from '../../../utils/types';
import { IconButton } from '@mui/material';
import { Info as InfoIcon } from '@mui/icons-material';
import { SnackContext } from '../../../../../../context/SnackProvider';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../../../../../../firebase';
import InfoDialog from '../../Dialogs/InfoDialog/InfoDialog';

type CommentsButtonProps = {
	node: FormNode;
};

export default function InfoButton({ node }: CommentsButtonProps) {
	const [dialogOpen, setDialogOpen] = useState(false);
	const { setSnackbarProps } = useContext(SnackContext);

	const handleClick = async (_event: React.MouseEvent<HTMLButtonElement>) => {
		const itemRef = doc(db, `utility_forms_v2_items/${node.id}`);
		const docSnap = await getDoc(itemRef);

		if (!docSnap.exists()) {
			setSnackbarProps({
				open: true,
				message: 'Error, another user deleted this item',
				severity: 'error',
				hideDuration: 6000,
			});
		} else {
			setDialogOpen(true);
		}
	};

	const handleClose = async () => {
		setDialogOpen(false);
	};

	if (node.type === 'node') return null;

	return (
		<>
			<IconButton onClick={handleClick}>
				<InfoIcon color={node.info ? 'primary' : undefined} />
			</IconButton>
			{dialogOpen && <InfoDialog open={dialogOpen} onClose={handleClose} node={node} />}
		</>
	);
}
