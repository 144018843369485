import { Box, Typography } from '@mui/material';
import styled from 'styled-components';

const EmptyStateMessage: React.FC<{ text: string }> = ({ text }) => {
	return (
		<StyledBox>
			<Typography variant="h6" color="common.white">
				{text}
			</Typography>
		</StyledBox>
	);
};

const StyledBox = styled(Box)`
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 32px;
	height: 100%;
	width: 100%;
`;

export default EmptyStateMessage;
