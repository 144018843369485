import { useState } from 'react';
import { doc, serverTimestamp, updateDoc } from 'firebase/firestore';
import { UtilityFormV2 } from '../ProjectUtilityFormV2/utils/types';
import { db } from '../../../firebase';

const useEditForm = (form: UtilityFormV2) => {
	const [editDialogOpen, setEditDialogOpen] = useState(false);
	const [isEditing, setIsEditing] = useState(false);

	const editForm = (e: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
		e.stopPropagation();
		setEditDialogOpen(true);
	};

	const closeEditDialog = () => setEditDialogOpen(false);

	const handleEditForm = async () => {
		setIsEditing(true);
		closeEditDialog();
		const formRef = doc(db, 'utility_forms_v2', form.id);
		await updateDoc(formRef, {
			status: 'draft',
			lastUpdated: serverTimestamp(),
		});
		setIsEditing(false);
	};

	return {
		closeEditDialog,
		editDialogOpen,
		handleEditForm,
		isEditing,
		editForm,
	};
};

export default useEditForm;
