import {
	collection,
	getCountFromServer,
	getFirestore,
	query,
	where,
} from 'firebase/firestore';
import { ProjectStatus, UserObj } from '../../../types';
import {
	ProjectRole,
	UserTagTitleMap,
	projectRoleToUserTagMap,
} from '../../../types/project-roles';
import { projectStatusText, strArrToStr } from '../../../utils';

const roleExceptions: Partial<Record<ProjectRole, ProjectStatus[]>> = {
	bimTechnicians: [
		'On-Site Capture Complete',
		'Registration',
		'Ready For Modeling',
		'Modeling In Progress',
	],
	qaQcManagers: ['Modeling In Progress', 'QA/QC'],
};

export const getCount = async (
	role: ProjectRole,
	userObj: UserObj,
	projectStatus: ProjectStatus
) => {
	const isMultiUserRole = role !== 'accountManager';
	let q = query(collection(getFirestore(), 'projects'));

	// Adjust query for multi UserObj roles vs. sing UserObj roles.
	if (isMultiUserRole) q = query(q, where(role, 'array-contains', userObj));
	else q = query(q, where(role, '==', userObj));

	// Get statuses that need to be queried individually, if any.
	const exceptionStatuses = roleExceptions[role];
	if (!exceptionStatuses) {
		// No 'exceptionStatuses', so just query on the given 'projectStatus' and return the count.
		q = query(q, where('projectStatus', '==', projectStatus));
		const res = await getCountFromServer(q);
		return res.data().count;
	} else {
		// There are 'exceptionStatuses', so query each ProjectStatus associated with
		// the given 'role' then return the count sum.
		//
		// Each status needs to be queried separately because one 'array-contains' condition
		// is already used for the UserObj.
		const counts = await Promise.all(
			exceptionStatuses.map(status => {
				const statusQ = query(q, where('projectStatus', '==', status));
				return getCountFromServer(statusQ);
			})
		);
		return counts.reduce((acc, res) => acc + res.data().count, 0);
	}
};

const vowels = ['a', 'e', 'i', 'o', 'u'];
export const getToolTip = (
	name: string,
	role: ProjectRole,
	count: number | undefined,
	projectStatus: ProjectStatus
) => {
	if (typeof count !== 'number') return undefined;

	let str = `${name}`;
	const userTag = projectRoleToUserTagMap[role];
	const userTagTitle = UserTagTitleMap[userTag];
	const indefiniteArticle = vowels.includes(userTagTitle.toLowerCase()[0]) ? 'an' : 'a';

	if (count === 0) {
		str += ` has not been assigned as ${indefiniteArticle} ${userTagTitle} in any`;
	} else {
		str += ` has been assigned as ${indefiniteArticle} ${userTagTitle} in ${count}`;
	}

	const exceptionStatuses = roleExceptions[role];
	if (!exceptionStatuses) {
		str += ` ${projectStatusText(projectStatus)} project${count !== 1 ? 's' : ''}`;
	} else {
		const statuses = strArrToStr(exceptionStatuses, 'or');
		str += ` ${statuses} project${count !== 1 ? 's' : ''}`;
	}

	return str;
};
