import { Button, Chip, TextField } from '@mui/material';
import { useContext, useState } from 'react';
import styled from 'styled-components';
import { SnackContext } from '../../../context/SnackProvider';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { getCaptureTimestampString } from '../../../utils/timestamps';

dayjs.extend(utc);
dayjs.extend(timezone);

export default function MultiDateTimeInput({
	dates,
	setDates,
}: {
	dates: Date[];
	setDates: React.Dispatch<React.SetStateAction<Date[]>>;
}) {
	const { setSnackbarProps } = useContext(SnackContext);

	const [dateStr, setDateStr] = useState('');

	const handleDelete = (idx: number) => {
		setDates(prev => {
			const tmp = [...prev];
			tmp.splice(idx, 1);
			return tmp;
		});
	};

	const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
		const newValue = e.target.value;
		setDateStr(newValue);
	};

	const handleAddDate = () => {
		if (dateStr) {
			const newDate = dayjs(dateStr).hour(12).minute(0).second(0).toDate();
			setDates(prev => [...prev, newDate]);
		} else {
			setSnackbarProps({
				open: true,
				severity: 'warning',
				message: 'Invalid date selected!',
			});
		}
	};

	const handleSchedulingNeeded = () => {
		setDates(prev => [...prev, new Date(0)]);
	};

	return (
		<Container>
			<DateContainer>
				{dates.length ? (
					dates.map((date, idx) => {
						return (
							<Chip
								key={idx}
								label={getCaptureTimestampString(date)}
								onDelete={() => handleDelete(idx)}
							/>
						);
					})
				) : (
					<span>No dates selected...</span>
				)}
			</DateContainer>

			<InputContainer>
				<TextField
					type="date"
					value={dateStr}
					onChange={handleChange}
					label="New date"
					InputLabelProps={{ shrink: true }}
				/>

				<Button onClick={handleAddDate}>Add</Button>
			</InputContainer>

			<Button onClick={handleSchedulingNeeded}>Scheduling Needed</Button>
		</Container>
	);
}

const Container = styled.div`
	display: flex;
	flex-direction: column;
	gap: 20px;
`;

const DateContainer = styled.div`
	display: flex;
	justify-content: center;
	gap: 10px;
	flex-wrap: wrap;

	padding: 10px;
	background-color: gray;
	max-height: calc(100vh / 4);
	overflow-y: auto;

	> span {
		color: white;
	}
`;

const InputContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 10px;
`;
