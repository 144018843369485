import { ProjectData, UserObj } from '.';

export type UserTag =
	| 'scanningTechnician'
	| 'registrationManager'
	| 'qaQcManager'
	| 'accountManager'
	| 'bimTechnician';

export const UserTagTitleMap: Record<UserTag, string> = {
	scanningTechnician: 'Scanning Technician',
	registrationManager: 'Registration Technician',
	qaQcManager: 'QA/QC Technician',
	accountManager: 'Account Manager',
	bimTechnician: 'BIM Technician',
};

export type ProjectRole = {
	[K in keyof Required<ProjectData>]: Required<ProjectData>[K] extends UserObj | UserObj[]
		? K
		: never;
}[keyof ProjectData];

export const projectRoleToUserTagMap: Record<ProjectRole, UserTag> = {
	registrationManagers: 'registrationManager',
	qaQcManagers: 'qaQcManager',
	scanningTechnicians: 'scanningTechnician',
	bimTechnicians: 'bimTechnician',
	accountManager: 'accountManager',
};

export const projectRoleTitleMap: Record<ProjectRole, string> = {
	scanningTechnicians: 'Scan Tech(s)',
	registrationManagers: 'Registration Tech(s)',
	qaQcManagers: 'QA/QC Tech(s)',
	bimTechnicians: 'BIM Tech(s)',
	accountManager: 'Account Manager',
};

export type MultiUserProjectRole = {
	[K in keyof Required<ProjectData>]: Required<ProjectData>[K] extends UserObj[]
		? K
		: never;
}[ProjectRole];
