import {
	Alert,
	AlertTitle,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	TextField,
} from '@mui/material';
import { useState } from 'react';
import AddIcon from '@mui/icons-material/Add';

export default function AddCustomField({
	itemState,
	setItemState,
}: {
	itemState: any;
	setItemState: React.Dispatch<React.SetStateAction<any>>;
}) {
	const [customField, setCustomField] = useState({
		fieldName: '',
		fieldValue: '',
	});

	const [open, setOpen] = useState(false);
	const [alert, setAlert] = useState(false);
	const [continueSubmit, setContinueSubmit] = useState(false);
	const addCustomField = () => {
		if (customField.fieldName in itemState.customFields && !continueSubmit) {
			setAlert(true);
			setContinueSubmit(true);
		} else {
			setItemState((prev: any) => ({
				...prev,
				customFields: {
					...prev.customFields,
					[customField.fieldName]: customField.fieldValue,
				},
			}));
			handleClose();
		}
	};

	const handleClose = () => {
		setOpen(false);
		setAlert(false);
		setContinueSubmit(false);
		setCustomField({ fieldName: '', fieldValue: '' });
	};

	return (
		<>
			<Button
				color="secondary"
				size="small"
				title="Add New Field"
				onClick={() => setOpen(true)}>
				<AddIcon fontSize="small" />
			</Button>
			<Dialog open={open} onClose={handleClose}>
				{alert && (
					<Alert severity="warning">
						<AlertTitle>Warning! Custom field already exists.</AlertTitle>
						Continuing to add field will replace the existing field.
					</Alert>
				)}
				<DialogTitle>Custom Field</DialogTitle>
				<DialogContent>
					<TextField
						autoFocus
						margin="dense"
						id="field"
						label="Field"
						fullWidth
						variant="standard"
						onChange={e => {
							setCustomField(prev => ({
								...prev,
								fieldName: e.target.value,
							}));
						}}
					/>
					<TextField
						// autoFocus
						margin="dense"
						id="value"
						label="Value"
						fullWidth
						variant="standard"
						onChange={e => {
							setCustomField(prev => ({
								...prev,
								fieldValue: e.target.value,
							}));
						}}
					/>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleClose}>Cancel</Button>
					<Button onClick={addCustomField}>Add Field</Button>
				</DialogActions>
			</Dialog>
		</>
	);
}
