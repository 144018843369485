import { Chip, DialogContent } from '@mui/material';
import styled from 'styled-components';
import theme from '../../../../styles/theme';

export const ListItemContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	color: ${theme.palette.common.white};
	gap: 20px;
`;

export const EmptyContent = styled(DialogContent)`
	align-items: center;
	justify-content: center;
	display: flex;
	color: ${theme.palette.common.white};
	background-color: transparent;
`;

export const StyledChip = styled(Chip)`
	color: ${theme.palette.common.white};
	background-color: ${theme.palette.common.black};
	border: 1px solid ${theme.palette.primary.main};
	.MuiChip-icon {
		color: black;
	}
`;

export const IconContainer = styled.div`
	height: 32px;
	width: 32px;
	display: flex;
	align-items: center;
	justify-content: center;
`;

export const IconsContainer = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 5px;
`;
