import {
	DesktopFooter,
	FooterRow,
	WhiteFooterLink,
	FooterGreyText,
	FooterGreyLink,
	FooterYellowLink,
} from './style';

const Footer = ({ isRegister = false }: { isRegister?: boolean }) => {
	return (
		<DesktopFooter $isRegister={isRegister}>
			<FooterRow>
				<WhiteFooterLink href="https://www.roboticimaging.com/blog" target="_blank">
					Blog
				</WhiteFooterLink>
				<WhiteFooterLink href="https://www.roboticimaging.com/careers" target="_blank">
					Careers
				</WhiteFooterLink>
				<WhiteFooterLink href="https://www.roboticimaging.com/contact" target="_blank">
					Contact
				</WhiteFooterLink>
				<WhiteFooterLink href="https://www.roboticimaging.com/faq" target="_blank">
					FAQ
				</WhiteFooterLink>

				<WhiteFooterLink
					href="https://www.roboticimaging.com/testimonials"
					target="_blank">
					Testimonials
				</WhiteFooterLink>
				<WhiteFooterLink href="https://www.roboticimaging.com/contact" target="_blank">
					Partnership Requests
				</WhiteFooterLink>
				<WhiteFooterLink href="https://roboticimaging.ai/register" target="_blank">
					Join Network
				</WhiteFooterLink>
			</FooterRow>
			<FooterRow>
				<FooterGreyText>{`© 2024 All Rights Reserved`}</FooterGreyText>
				<FooterGreyLink
					href="https://www.roboticimaging.com/privacy-policy"
					target="_blank">
					Privacy Policy
				</FooterGreyLink>
				<FooterGreyLink
					href="https://www.roboticimaging.com/terms-and-conditions"
					target="_blank">
					Terms & Conditions
				</FooterGreyLink>

				<FooterYellowLink href="mailto:info@roboticimaging.com">
					info@roboticimaging.com
				</FooterYellowLink>

				<FooterYellowLink href="tel:12679691153">+1 267-969-1153</FooterYellowLink>
			</FooterRow>
		</DesktopFooter>
	);
};

export default Footer;
