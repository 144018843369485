import { useParams } from 'react-router-dom';
import Layout from '../components/reusable-components/Layout';
import { useContext, useEffect } from 'react';
import { OrganizationsContext } from '../context/OrganizationsProvider';
import Reports from '../components/screen-components/TeamPage/Reports';
import { BackButton } from '../components/reusable-components/BackButton';

export default function Analytics() {
	const { organizationId, teamId } = useParams();

	const { loadTeam, organizations, team } = useContext(OrganizationsContext);

	useEffect(() => {
		if (teamId) {
			loadTeam(teamId).then(() => {});
		}
	}, [loadTeam, teamId]);

	useEffect(() => {
		document.title = 'Analytics | Robotic Imaging';
	}, []);

	return (
		<Layout
			title={team?.name ? `Analytics | ${team.name}` : 'Loading Team...'}
			orgLogo={
				team?.organizationId
					? organizations.find(org => org.id === team.organizationId)?.logo?.[0]
							?.downloadUrl
					: undefined
			}>
			<BackButton />
			{typeof teamId === 'string' && typeof organizationId === 'string' ? (
				<Reports teamId={teamId} organizationId={organizationId} />
			) : null}
		</Layout>
	);
}
