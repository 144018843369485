import { useContext } from 'react';
import { Row } from 'react-table';
import { ProjectData } from '../../../../../../../types';
import { YellowTextField } from '../../../../../../styled-components/styledReactTable';
import { updateSingleProjectField } from '../../../../../../../firebase/firestore/projects';
import { SnackContext } from '../../../../../../../context/SnackProvider';
import { StatusTableContext } from '../../../../StatusTableProvider';
import { dateToDateString } from '../../../../../../../utils';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(timezone);

export default function DateTimeCell({
	row,
	rowEditing,
	field,
}: {
	row: Row<ProjectData>;
	rowEditing: boolean;
	field: 'modelingStartTimestamp' | 'modelingDueTimestamp' | 'deliveryTimestamp';
}) {
	const { setSnackbarProps } = useContext(SnackContext);
	const { setStatusProjects } = useContext(StatusTableContext);

	const projectId = row.original.id as string;
	const date = row.original[field];

	const updateField = async (dateStr: string) => {
		let updatedDate: Date | undefined = undefined;

		if (dateStr) {
			updatedDate = dayjs(dateStr).hour(12).minute(0).second(0).utc().toDate();
		}

		setSnackbarProps({
			open: true,
			severity: 'warning',
			message: 'Saving changes...',
			hideDuration: null,
		});

		// Update firestore document.
		await updateSingleProjectField(projectId, updatedDate, field);

		// Update state.
		setStatusProjects(prev => {
			return prev.map(proj => {
				if (proj.id !== projectId) return proj;
				else {
					return {
						...proj,
						[field]: updatedDate,
					};
				}
			});
		});

		setSnackbarProps({
			open: true,
			severity: 'success',
			message: 'Changes saved!',
		});
	};

	return !rowEditing ? (
		<span>{date ? dayjs(date).tz(dayjs.tz.guess()).format('MM/DD/YYYY') : '-'}</span>
	) : (
		<YellowTextField
			fullWidth
			type="date"
			defaultValue={date ? dateToDateString(date) : ''}
			onChange={e => updateField(e.target.value)}
			onClick={e => e.stopPropagation()}
			isProjectPage={true}
		/>
	);
}
