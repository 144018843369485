import { FC, useCallback, useContext, useEffect, useState } from 'react';
import { StyledTextField } from '../StyledTextField';
import { FormEditTextFieldProps } from './FormTextEditField.types';
import { debounce, isString } from 'lodash';
import { FormV2Context } from '../../screen-components/ProjectUtilityFormV2/context/FormV2Context.types';
import { Box } from '@mui/system';
import { StyledInfo, additionalStyles } from './FormTextEditField.styles';
import { Typography } from '@mui/material';
import { TemplateContext } from '../../../context/TemplateProvider';
import theme from '../../../styles/theme';
import { AuthContext } from '../../../context/AuthProvider';

const debouncedSave = debounce(async (save: () => void) => save(), 300);

export const FormTextEditField: FC<FormEditTextFieldProps> = ({
	node,
	placeholder = 'Enter section name',
	label,
	isName,
	nodeField,
	helperText = '',
	showInfo,
	isFormTitle = false,
}) => {
	const { updateDisplayTitle, updateValue, isViewMode } = useContext(FormV2Context);
	const { updateTemplateDisplayTitle, isTemplate, template } =
		useContext(TemplateContext);
	const { user } = useContext(AuthContext);
	const [isFieldHovered, setIsFieldHovered] = useState<boolean>(false);
	const [isFieldFocused, setIsFieldFocused] = useState<boolean>(false);
	const [nodeValue, setNodeValue] = useState<string>('');
	const [fieldValue, setFieldValue] = useState<string>('');
	const [fieldText, setFieldText] = useState<string>(helperText);
	const [error, setError] = useState<boolean>(false);
	const [disableButton, setDisableButton] = useState(false);
	const titleClass = isName ? 'name' : '';

	const onFieldChangeHandler = (value: string) => {
		setError(false);
		setFieldValue(value);

		if (nodeField === 'displayTitle' && !value.length) {
			setFieldText('Field is required');
			setError(true);
			return;
		}
		setFieldText(helperText);
	};

	const debounceSave = useCallback(
		(value: string) => {
			debouncedSave.cancel();
			debouncedSave(() => {
				switch (nodeField) {
					case 'displayTitle':
						isTemplate
							? updateTemplateDisplayTitle(node.id, value)
							: updateDisplayTitle(node.id, value);
						break;
					case 'value':
						!isTemplate && updateValue(node.id, value);
						break;
				}
			});
		},
		[node.id, nodeField, updateDisplayTitle, updateValue]
	);

	// This useEffect sends any changes to the fieldValue to the debounced update function.
	useEffect(() => {
		if (fieldValue !== nodeValue) {
			debounceSave(fieldValue);
		}
	}, [debounceSave, fieldValue, nodeValue]);

	/**
	 * This useEffect keeps the nodeValue current with any changes in the database to
	 * node.displayTitle or node.value. It also updated the fieldValue only if the field is
	 * not focused in order to prevent Firestore changes from overwriting the text that the
	 * user is typing.
	 */
	useEffect(() => {
		let value = '';
		switch (nodeField) {
			case 'displayTitle':
				value = node.displayTitle || '';
				if (value === nodeValue) break;
				// console.log('value', value);
				setNodeValue(value);
				if (!isFieldFocused && isString(value)) setFieldValue(value);
				break;
			case 'value':
				value = (node.value as string) || '';
				if (value === nodeValue) break;
				setNodeValue(value);
				if (!isFieldFocused) setFieldValue(value);
				break;
		}
	}, [isFieldFocused, node.displayTitle, node.value, nodeField, nodeValue]);

	const handleDisableButton = useCallback(() => {
		if (isTemplate && user?.id !== template?.userId && !user?.isAdmin) {
			setDisableButton(true);
		}
	}, [isTemplate, template?.userId, user?.id, user?.isAdmin]);

	useEffect(() => {
		handleDisableButton();
	}, [handleDisableButton]);

	return (
		<Box width={'100%'}>
			<Box width={'100%'} sx={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
				{nodeField === 'displayTitle' && (
					<Typography
						color={isName ? 'primary.main' : 'newText.primary'}
						fontWeight={isViewMode ? '600' : node.level === 0 || isName ? '400' : '600'}
						variant={node.level === 0 || isName ? 'body1' : 'body2'}>
						{node.displayOrder + (!isViewMode && node.required ? '*' : '')}
					</Typography>
				)}
				{!isViewMode ? (
					<StyledTextField
						placeholder={isViewMode ? '' : error ? fieldText : placeholder}
						label={isViewMode ? '' : label || 'Name'}
						sx={{
							...additionalStyles({
								isFormTitle,
								helperText,
								error,
								isEmpty: !fieldValue && !isFieldHovered,
							}),
							...(nodeField === 'displayTitle' && {
								margin: '2px 0',
							}),
						}}
						className={
							isFieldHovered || isFieldFocused ? `active ${titleClass}` : titleClass
						}
						onMouseEnter={() => setIsFieldHovered(true)}
						multiline={nodeField === 'displayTitle'}
						onMouseLeave={() => !isFieldFocused && setIsFieldHovered(false)}
						onFocus={() => setIsFieldFocused(true)}
						onBlur={() => [setIsFieldFocused(false), setIsFieldHovered(false)]}
						value={fieldValue}
						onChange={e => onFieldChangeHandler(e.target.value)}
						fullWidth
						helperText={(isFieldHovered || isFieldFocused) && helperText}
						error={error}
						autoComplete="off"
						size="small"
						isviewmode={String(isViewMode)}
						InputProps={{
							readOnly: isViewMode,
							disableUnderline: isViewMode,
							tabIndex: -1,
						}}
						disabled={disableButton}
					/>
				) : (
					<Typography color={theme.palette.primary.main} fontWeight={'600'}>
						{fieldValue}
						{nodeField === 'displayTitle' && node.required && '*'}
					</Typography>
				)}
			</Box>
			{showInfo && node.info && !isViewMode && (
				<StyledInfo variant="label" color="grey.300" tabIndex={-1}>
					{node.info}
				</StyledInfo>
			)}
		</Box>
	);
};
