import { doc, getDoc } from 'firebase/firestore';
import { SharedUser } from '../../../../../screen-components/ProjectInfo/components/RightNavButtons/types';
import { db } from '../../../../../../firebase';

interface FirestoreUser {
	email?: string;
	fullName?: string;
	id?: string;
	userType?: 'Admin' | 'Contractor' | 'Client' | null;
}

const fetchStaticUsers = async (): Promise<SharedUser[]> => {
	try {
		const docRef = doc(db, 'cached_data', 'adminFormCommentList');
		const docSnap = await getDoc(docRef);

		if (docSnap.exists() && docSnap.data().users) {
			const usersData = docSnap.data().users as Record<string, FirestoreUser>;
			const users = Object.values(usersData);
			return users.map(user => {
				const formattedUser: SharedUser = {
					email: user.email ?? '',
					fullName: user.fullName ?? 'Unknown',
					id: user.id ?? '',
					userType: user.userType
						? ['Admin', 'Contractor', 'Client'].includes(user.userType)
							? user.userType
							: null
						: null,
				};
				return formattedUser;
			});
		} else {
			console.log('No static user data found.');
			return [];
		}
	} catch (error) {
		console.error('Error fetching static users:', error);
		return [];
	}
};

export default fetchStaticUsers;
