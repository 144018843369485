import { IconButton, InputBase } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import React, { CSSProperties } from 'react';
import styled from 'styled-components';

export default function ModelersSearchbar({
	query,
	handleInputChange,
	clearQuery,
	style,
}: {
	query: string;
	handleInputChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
	clearQuery: () => void;
	style?: CSSProperties;
}) {
	return (
		<StyledSearch>
			<InputBase
				placeholder="Search..."
				onChange={handleInputChange}
				value={query}
				className="searchbar-input"
				inputProps={{ id: 'searchbar-input', style: { color: '#ffb310' } }}
				style={{ flex: 1 }}
			/>
			{query ? (
				<IconButton
					sx={{
						color: '#ffb310',
						'&:hover': {
							color: 'white',
						},
					}}
					onClick={() => clearQuery()}>
					<ClearIcon />
				</IconButton>
			) : null}
		</StyledSearch>
	);
}

export const StyledSearch = styled.div`
	display: flex;
	align-items: center;
	gap: 5px;
	width: 400px;
	min-width: 230px;
	border-radius: 15px;
	border: 1px solid #ffb310;
	padding: 10px;
	height: 44px;

	@media (max-width: 1023px) {
		width: 350px;
	}
`;
