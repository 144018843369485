import React, { forwardRef, HTMLAttributes, useContext } from 'react';
import { Action, Handle, Remove } from './components';
import styles from './TreeItem.module.css';
import clsx from 'clsx';
import { FormNode } from '../../../../../screen-components/ProjectUtilityFormV2/utils/types';
import theme from '../../../../../../styles/theme';
import { MenuReorderContext } from '../../../../../screen-components/Form/Form';
import RequiredProgressNumber from '../../../../../reusable-components/RequiredProgressNumber';
import styled from 'styled-components';

export interface Props extends Omit<HTMLAttributes<HTMLLIElement>, 'id'> {
	childCount?: number;
	clone?: boolean;
	collapsed?: boolean;
	depth: number;
	disableInteraction?: boolean;
	disableSelection?: boolean;
	ghost?: boolean;
	handleProps?: any;
	indicator?: boolean;
	indentationWidth: number;
	value: string;
	onCollapse?(): void;
	onRemove?(): void;
	wrapperRef?(node: HTMLLIElement): void;
	node: FormNode;
	hideDragButton?: boolean;
}

export const TreeItem = forwardRef<HTMLDivElement, Props>(
	(
		{
			childCount,
			clone,
			depth,
			disableSelection,
			disableInteraction,
			ghost,
			handleProps,
			indentationWidth,
			indicator,
			collapsed,
			onCollapse,
			onRemove,
			style,
			value,
			wrapperRef,
			node,
			hideDragButton,
			...props
		},
		ref
	) => {
		const { selectedItem } = useContext(MenuReorderContext);

		return (
			<li
				className={clsx(
					styles.Wrapper,
					clone && styles.clone,
					ghost && styles.ghost,
					indicator && styles.indicator,
					disableSelection && styles.disableSelection,
					disableInteraction && styles.disableInteraction
				)}
				ref={wrapperRef}
				style={
					{
						'--spacing': `${indentationWidth * depth}px`,
						'--text-color':
							node.id === selectedItem?.id
								? theme.palette.primary.main
								: theme.palette.newText.primary,
						'--bordertop':
							depth === 0 && (node.order || 0) > 0 && !clone ? '1px solid #444' : 'none',
					} as React.CSSProperties
				}
				{...props}>
				<div
					className={styles.TreeItem}
					ref={ref}
					style={style}
					id={`section-${node.id}`}>
					{!hideDragButton && <Handle {...handleProps} />}
					<TextWrapper>
						<StyledText>{value}</StyledText>
						{node.required && <RequiredIndicator>*</RequiredIndicator>}
					</TextWrapper>
					{onCollapse && (
						<>
							{node.requiredFields && node.completedFields && (
								<RequiredProgressNumber
									completedFields={node.completedFields.length}
									requiredFields={node.requiredFields.length}
								/>
							)}
							<Action
								onClick={e => [onCollapse(), e.stopPropagation()]}
								className={clsx(styles.Collapse, collapsed && styles.collapsed)}>
								{collapseIcon}
							</Action>
						</>
					)}
					{!clone && onRemove && <Remove onClick={onRemove} />}
					{clone && childCount && childCount > 1 ? (
						<span className={styles.Count}>{childCount}</span>
					) : null}
				</div>
			</li>
		);
	}
);

const TextWrapper = styled.div`
	display: flex;
	align-items: center;
	min-width: 0;
	flex: 1;
`;

const StyledText = styled.span`
	display: block;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	min-width: 0;
`;

const RequiredIndicator = styled.span`
	color: inherit;
	margin-left: 2px;
	flex-shrink: 0;
`;

const collapseIcon = (
	<svg width="10" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 70 41">
		<path d="M30.76 39.2402C31.885 40.3638 33.41 40.995 35 40.995C36.59 40.995 38.115 40.3638 39.24 39.2402L68.24 10.2402C69.2998 9.10284 69.8768 7.59846 69.8494 6.04406C69.822 4.48965 69.1923 3.00657 68.093 1.90726C66.9937 0.807959 65.5106 0.178263 63.9562 0.150837C62.4018 0.123411 60.8974 0.700397 59.76 1.76024L35 26.5102L10.24 1.76024C9.10259 0.700397 7.59822 0.123411 6.04381 0.150837C4.4894 0.178263 3.00632 0.807959 1.90702 1.90726C0.807714 3.00657 0.178019 4.48965 0.150593 6.04406C0.123167 7.59846 0.700153 9.10284 1.75999 10.2402L30.76 39.2402Z" />
	</svg>
);
