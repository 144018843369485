import { isEmpty } from 'lodash';
import {
	FilteredNodesByParentId,
	FormNodesByParentId,
	GalleryNode,
	GalleryPhoto,
} from './types';

/**
 * A recursive function that returns the filtered form nodes separated by parentId, an array of
 * all filtered form nodes, and an array of all photos.
 *
 * Recusively filters nodes starting from the root level nodes.
 */
export const filterNodes = (
	itemsObj: FormNodesByParentId,
	photos: GalleryPhoto[] = [],
	parentNode?: GalleryNode
) => {
	/**
	 * An object with the 'parentNode's id as keys and GalleryNode[] as values.
	 *
	 * Each GalleryNode also contains a reference to its parent node, so that the 'filteredNodesObj' object
	 * can act as both a tree and a map holding GalleryNode[] by their parent ids.
	 */
	let filteredNodesObj: FilteredNodesByParentId = {};

	const parentId = parentNode?.id || 'root';
	const children = itemsObj?.[parentId] || [];
	const filteredChildren: GalleryNode[] = [];

	// Iterate through the child nodes of the given 'parentId'.
	for (const child of children) {
		if (child.type === 'photos' && Array.isArray(child.value) && child.value.length) {
			// Child node is a non-empty photo node, so convert the node to a GalleryNode and
			// add the child node to 'filteredChildren'.
			const galleryNode: GalleryNode = {
				...child,
				galleryIndex: photos.length,
				parentNode,
			};
			filteredChildren.push(galleryNode);

			// Convert the child node's photos into GalleryPhotos and push it to the 'photos' array.
			const galleryPhotos: GalleryPhoto[] = child.value.map((photo, idx) => ({
				...photo,
				node: galleryNode,
				galleryIndex: photos.length + idx,
			}));
			photos.push(...galleryPhotos);
		} else if (child.type === 'node') {
			// Child node is a container, so first convert the node to a GalleryNode.
			const galleryNode: GalleryNode = {
				...child,
				galleryIndex: photos.length,
				parentNode,
			};

			/**
			 * Recursively call the 'filterNodes' using the child node's id as the 'parentId', then
			 * assign the returned 'filteredNodesObj' to 'childObj'.
			 *
			 * This recusive call is essentially DFS, using the 'itemsObj' object as a tree.
			 */
			const { filteredNodesObj: childObj } = filterNodes(itemsObj, photos, galleryNode);

			/**
			 * If 'childObj' is empty, it means that the current child container node does not contain
			 * any valid photo/container nodes.
			 *
			 * If 'childObj' is not empty, push the current node to 'filteredChildren', then add the contents
			 * of 'childObj' to 'filteredNodesObj' because the current node contains at least one valid
			 * photo/container node.
			 */
			if (!isEmpty(childObj)) {
				filteredChildren.push(galleryNode);
				filteredNodesObj = {
					...filteredNodesObj,
					...childObj,
				};
			}
		}
	}

	// If there are valid child nodes for the given 'parentId', add them to 'filteredNodesObj' before returning
	// the return values.
	if (filteredChildren.length) filteredNodesObj[parentId] = filteredChildren;
	return { filteredNodesObj, photos };
};

export const getParentSelectorTitle = (node: GalleryNode) => {
	switch (node.level) {
		case 0:
			return 'Category';
		case 1:
			return 'Item';
		case 2:
			return 'Photos Field';
		default:
			return '';
	}
};
