import { useState, useContext, useRef } from 'react';
import { Row } from 'react-table';
import styled from 'styled-components';

import {
	MenuItem,
	TextField,
	Button,
	FormControlLabel,
	Switch,
	IconButton,
} from '@mui/material';

import AddIcon from '@mui/icons-material/Add';
import ClearIcon from '@mui/icons-material/Clear';

import { SnackContext } from '../../../../context/SnackProvider';
import { LeadsContext } from '../LeadsProvider';

import { Lead, LeadStatus } from '../../types';
import { updateLeads } from '../../../../firebase';
import AddLeadDialog from './components/AddLeadDialog';
import { leadStatuses } from '../../utils';
import MassComments from './components/MassComments';
import LeadsExportButton from './components/LeadsExportButton';
import { useSearchParams } from 'react-router-dom';
import { AuthContext } from '../../../../context/AuthProvider';
import { UserObj } from '../../../../types';

type LeadsActionsProps = {
	query: string;
	setQuery: React.Dispatch<React.SetStateAction<string>>;

	setAccountManagerFilter: React.Dispatch<React.SetStateAction<UserObj | undefined>>;
	leadStatusFilter: LeadStatus | '';
	setLeadStatusFilter: React.Dispatch<React.SetStateAction<'' | LeadStatus>>;
	setPeopleFilter: React.Dispatch<React.SetStateAction<string[]>>;

	selectedRows: Row<Lead>[];

	showGraphs: boolean;
	setShowGraphs: React.Dispatch<React.SetStateAction<boolean>>;

	setDeselectAllRows: React.Dispatch<React.SetStateAction<boolean>>;
};

export default function LeadsActions({
	query,
	setQuery,

	setAccountManagerFilter,
	leadStatusFilter,
	setLeadStatusFilter,
	selectedRows,

	showGraphs,
	setShowGraphs,

	setPeopleFilter,
	setDeselectAllRows,
}: LeadsActionsProps) {
	const [, setSearchParams] = useSearchParams();

	const { setSnackbarProps } = useContext(SnackContext);
	const { projectRoleOptions } = useContext(AuthContext);
	const { setLeads } = useContext(LeadsContext);

	const clearQuery = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
		setQuery('');
		inputRef.current?.focus();
	};

	const [openLeadDialog, setOpenLeadDialog] = useState(false);

	const massChange = async (field: string, value: any) => {
		setSnackbarProps({
			open: true,
			message: 'Submitting changes...',
			severity: 'warning',
			hideDuration: null,
		});

		const selectedLead = selectedRows.map(row => row.original);
		const selectedLeadIds: string[] = [];
		for (const lead of selectedLead) {
			if (lead.id) selectedLeadIds.push(lead.id);
		}
		setLeads(prev => {
			const tmp = [...prev];
			let idx = 0;
			const newDate = new Date();

			for (const id of selectedLeadIds) {
				idx = tmp.findIndex(lead => lead.id === id);
				const updatedLead: Lead = {
					...tmp[idx],
					[field]: value,
					lastActivity: newDate,
				};
				tmp.splice(idx, 1, updatedLead);
			}
			return tmp;
		});
		await updateLeads({
			ids: selectedLeadIds,
			updatedFields: {
				[field]: value,
			},
		});

		setSnackbarProps({
			open: true,
			message: 'Changes submitted!',
			severity: 'success',
		});
	};

	const inputRef = useRef<HTMLInputElement>();

	return (
		<LeadsActionsContainer>
			<Searchbar
				placeholder="Search leads..."
				variant="standard"
				value={query}
				onChange={e => {
					setQuery(e.target.value);
				}}
				InputProps={{
					endAdornment: query ? (
						<ClearFilterButton onClick={clearQuery}>
							<ClearIcon />
						</ClearFilterButton>
					) : null,
				}}
				inputRef={inputRef}
			/>

			<YellowButton onClick={() => setOpenLeadDialog(true)}>
				<AddIcon />
				Add Lead
			</YellowButton>

			<GraphToggleContainer>
				<FormControlLabel
					control={
						<Switch
							checked={showGraphs}
							onChange={e => {
								setShowGraphs(e.target.checked);
								setSearchParams(prev => ({
									...prev,
									graphs: `${e.target.checked}`,
								}));
							}}
						/>
					}
					label="Graph"
				/>
			</GraphToggleContainer>

			<AddLeadDialog open={openLeadDialog} setOpen={setOpenLeadDialog} />

			<SpecialDropdown>
				<TextField
					select
					SelectProps={{ displayEmpty: true }}
					defaultValue={undefined}
					onChange={e => {
						const selectedId = e.target.value;
						if (typeof selectedId === 'string') {
							const selectedManager = projectRoleOptions['accountManager'].find(
								userWithData => userWithData.id === selectedId
							);
							if (selectedManager) {
								const userObj: UserObj = {
									email: selectedManager.email,
									name: selectedManager.fullName,
									id: selectedManager.id,
								};
								setAccountManagerFilter(userObj);
							} else {
								setAccountManagerFilter({ email: '', name: '', id: '' });
							}
						} else {
							setAccountManagerFilter(undefined);
						}
					}}
					variant="standard">
					<MenuItem value={undefined}>All Managers</MenuItem>
					<MenuItem value={''}>Unassigned</MenuItem>
					{projectRoleOptions['accountManager'].map(userWithData => (
						<MenuItem value={userWithData.id} key={userWithData.id}>
							{userWithData.fullName}
						</MenuItem>
					))}
				</TextField>
			</SpecialDropdown>

			<SpecialDropdown>
				<TextField
					select
					SelectProps={{ displayEmpty: true }}
					value={leadStatusFilter}
					onChange={e => {
						setLeadStatusFilter(e.target.value as LeadStatus | '');
					}}
					variant="standard">
					<MenuItem value={''}>All Statuses</MenuItem>
					{leadStatuses.map((leadStatus, i) => (
						<MenuItem value={leadStatus} key={i}>
							{leadStatus}
						</MenuItem>
					))}
				</TextField>
			</SpecialDropdown>

			{selectedRows.length ? (
				<>
					<LeadsSelected>
						<span>
							{selectedRows.length === 1
								? `${selectedRows.length} lead selected`
								: `${selectedRows.length} leads selected`}
						</span>
						<YellowButton onClick={() => setDeselectAllRows(true)}>
							De-select all
						</YellowButton>
					</LeadsSelected>

					<MassChangeContainer>
						<SelectDropdown>
							<MassChangeSelect
								label="Mass Change Status"
								select
								size="small"
								defaultValue=""
								onChange={e => massChange('status', e.target.value)}
								variant="outlined">
								{leadStatuses.map((leadStatus, i) => (
									<MenuItem value={leadStatus} key={i}>
										{leadStatus}
									</MenuItem>
								))}
							</MassChangeSelect>
						</SelectDropdown>

						<SelectDropdown>
							<MassChangeSelect
								label="Mass Change Account Manager"
								select
								size="small"
								defaultValue=""
								onChange={e => {
									const selectedId = e.target.value;
									if (selectedId) {
										const selectedManager = projectRoleOptions['accountManager'].find(
											userWithData => userWithData.id === selectedId
										);
										if (selectedManager) {
											const userObj: UserObj = {
												email: selectedManager.email,
												name: selectedManager.fullName,
												id: selectedManager.id,
											};
											massChange('accountManager', userObj);
										}
									} else {
										massChange('accountManager', null);
									}
								}}
								variant="outlined">
								{projectRoleOptions['accountManager'].map(userWithData => (
									<MenuItem value={userWithData.id} key={userWithData.id}>
										{userWithData.fullName}
									</MenuItem>
								))}
							</MassChangeSelect>
						</SelectDropdown>

						<MassComments selectedRows={selectedRows} setLeads={setLeads} />

						<LeadsExportButton selectedRows={selectedRows} />
					</MassChangeContainer>
				</>
			) : null}
		</LeadsActionsContainer>
	);
}

const LeadsActionsContainer = styled.div`
	display: flex;
	flex-shrink: 0;
	flex-grow: 1;
	align-items: center;
	gap: 20px;
	color: black;

	> div {
		background-color: white;
		border-radius: 5px;
	}
`;

const Searchbar = styled(TextField)`
	padding: 5px;

	.MuiInputBase-root {
		min-width: 140px;
	}
`;

const ClearFilterButton = styled(IconButton)`
	height: 24px;
	width: 24px;
`;

const YellowButton = styled(Button)`
	background-color: #ffb310;
	color: black;
	flex-shrink: 0;
	border-radius: 10px;
	line-height: normal;

	&:hover {
		background-color: #ffb310;
	}
`;

const SpecialDropdown = styled.div`
	display: flex;
	align-items: center;
	min-height: 40px;
	gap: 10px;
	padding: 5px;

	& > div {
		display: flex;
		flex-direction: column;
		align-items: center;
		/* min-width: 100px; */
		margin-top: auto; //aligns the text to the bottom of the div
	}
`;

const SelectDropdown = styled.div`
	display: flex;
	flex-shrink: 0;
	gap: 10px;
	align-items: center;
`;

const GraphToggleContainer = styled.div`
	& > label {
		margin: 0px;
		padding-right: 5px;
		color: black;
	}
`;

const LeadsSelected = styled.div`
	display: flex;
	gap: 10px;
	align-items: center;
	padding: 5px;
`;

const MassChangeContainer = styled.div`
	display: flex;
	gap: 10px;
	align-items: center;
	padding-left: 10px;
`;

const MassChangeSelect = styled(TextField)`
	min-width: 190px;

	.MuiFormLabel-root {
		max-width: calc(100% - 38px);
	}
`;
