import styled from 'styled-components';
import splash from '../../assets/splash.mp4';

const StyledVideo = styled.video`
	position: fixed;
	width: 100%;
	height: 100%;
	object-fit: cover;
	z-index: -1;
`;

export default function BackgroundVideo() {
	return (
		<StyledVideo autoPlay loop={true} muted playsInline>
			<source src={splash} type="video/mp4"></source>
		</StyledVideo>
	);
}
