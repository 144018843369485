import { Construction } from '@mui/icons-material';
import styled from 'styled-components';

export const DropdownContainer = styled.div`
	z-index: 10;
`;

interface ToolsButtonProps {
	open?: boolean;
}

export const ToolsButton = styled(Construction)<ToolsButtonProps>`
	color: #ffb310;
	font-size: 1.2em;
	animation: ${({ open }) => (open ? 'spin .2s linear' : null)};

	@keyframes spin {
		0% {
			transform: rotate(360deg);
		}
		100% {
			transform: rotate(0deg);
		}
	}
`;
