import { FC } from 'react';
import styled from 'styled-components';

const ListIconContainer: FC<React.PropsWithChildren<unknown>> = ({ children }) => {
	return <Title>{children}</Title>;
};

const Title = styled.h1`
	margin: 0;
	color: white;
	font-weight: 400;
`;

export default ListIconContainer;
