import { collection, getDocs, limit, orderBy, query, where } from 'firebase/firestore';
import { db } from '../../../../firebase';
import { FormNode } from '../utils/types';

const useSearchItemTemplates = () => {
	const searchItems = async (searchTerm: string) => {
		const templatesRef = query(
			collection(db, 'utility_forms_v2_item_templates'),
			where('searchableDisplayTitle', 'array-contains', searchTerm.toLowerCase()),
			orderBy('searchableDisplayTitle'),
			limit(10)
		);

		const items = await getDocs(templatesRef);

		return items.docs.map(item => ({
			...(item.data() as FormNode),
			id: item.id,
		}));
	};

	return {
		searchItems,
	};
};

export default useSearchItemTemplates;
