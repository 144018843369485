import { useReducer } from 'react';
import { initialState, reducer } from './reducer';

export default function useProjectStatusCount() {
	const [projectStatusCount, setProjectStatusCount] = useReducer(reducer, initialState);

	return {
		projectStatusCount,
		setProjectStatusCount,
	};
}
