import { MenuItem, Select } from '@mui/material';
import { ProjectScopeArea } from '../types';
import styled from 'styled-components';
import theme from '../../../../styles/theme';
import { useContext } from 'react';
import { AuthContext } from '../../../../context/AuthProvider';

interface ScopeSelectProps {
	value: string;
	onChange: (newValue: boolean | string) => Promise<void>;
	area: ProjectScopeArea;
}

export default function ScopeSelect({ value, onChange, area }: ScopeSelectProps) {
	const { user } = useContext(AuthContext);

	const options = area === 'BIM LOD Standards' ? ['LOD 200', 'LOD 300', 'LOD 350'] : [];

	return user?.isAdmin ? (
		<StyledSelect
			value={value || ''}
			onChange={e => onChange(e.target.value as string)}
			displayEmpty>
			<MenuItem value="" key={'None_Value'}>
				None
			</MenuItem>
			{options.map((option, idx) => {
				return (
					<MenuItem value={option} key={value + idx}>
						{option}
					</MenuItem>
				);
			})}
		</StyledSelect>
	) : (
		<span>{value || 'None'}</span>
	);
}

const StyledSelect = styled(Select)`
	background-color: black;
	color: ${theme.palette.primary.main};
	> svg {
		fill: ${theme.palette.primary.main};
	}
`;
