import { FC, useContext } from 'react';
import { ListItem } from '../../reusable-components/List';
import { FormTemplate } from '../ProjectUtilityFormV2/utils/types';

import InputDialog from '../../reusable-components/InputDialog';
import useDeleteTemplate from './useDeleteTemplate';
import useRenameTemplate from './useRenameTemplate';
import useMenuItems from './useMenuItems';
import ConfirmDialog from './ConfirmDialog';
import useDuplicateTemplate from './useDuplicateTemplate';
import { AuthContext } from '../../../context/AuthProvider';
import styled from 'styled-components';
import { Box } from '@mui/system';
import theme from '../../../styles/theme';

type TemplateListItemProps = {
	template: FormTemplate;
	isPartial: boolean;
};

const TemplateListItem: FC<React.PropsWithChildren<TemplateListItemProps>> = ({
	template,
	isPartial,
}) => {
	const { user } = useContext(AuthContext);
	const {
		closeRenameDialog,
		handleRenameTemplate,
		isSaving,
		renameDialogOpen,
		renameTemplate,
	} = useRenameTemplate(template);

	const {
		closeDeleteDialog,
		deleteDialogOpen,
		deleteTemplate,
		handleDeleteTemplate,
		isDeleting,
	} = useDeleteTemplate(template);

	const {
		closeDuplicateDialog,
		duplicateDialogOpen,
		duplicateTemplate,
		handleDuplicateTemplate,
		isDuplicating,
	} = useDuplicateTemplate(template);

	const { created, filterMenuItems, updated } = useMenuItems({
		template,
		renameTemplate,
		duplicateTemplate,
		deleteTemplate,
		user: user!,
	});

	const url = isPartial
		? `/partial-templates/${template.id}`
		: `/templates/${template.id}`;

	return (
		<>
			<ListItem
				title={template.name || 'Unnamed Template'}
				content={[
					{ key: 'Creator', value: template.createdBy?.name || 'Unknown' },
					{
						key: 'Created',
						value: created,
					},
					{
						key: 'Updated',
						value: updated,
					},
				]}
				url={url}
				menuItems={filterMenuItems()}
				loading={isSaving || isDeleting || isDuplicating}
				child={
					template.isPartial ? (
						<Badge>Partial</Badge>
					) : template.userId === user?.id ? (
						<Badge>My template</Badge>
					) : null
				}
			/>
			<InputDialog
				handleClose={closeRenameDialog}
				handleSave={handleRenameTemplate}
				initialValue={template.name}
				open={renameDialogOpen}
				label="New template name"
				title="Rename Template"
			/>
			<ConfirmDialog
				handleClose={closeDeleteDialog}
				handleConfirm={handleDeleteTemplate}
				open={deleteDialogOpen}
				title="Delete Template"
				label={`Are you sure you want to delete ${template.name} template?`}
			/>
			<ConfirmDialog
				handleClose={closeDuplicateDialog}
				handleConfirm={handleDuplicateTemplate}
				open={duplicateDialogOpen}
				title="Duplicate Template"
				label={`Are you sure you want to duplicate ${template.name} template?`}
			/>
		</>
	);
};

export default TemplateListItem;

const Badge = styled(Box)`
	position: absolute;
	bottom: 0;
	right: 0;
	background-color: ${theme.palette.primary.main};
	color: black;
	padding: 2px 16px;
	border-radius: 20px 0 20px 0;
	font-size: 12px;
`;
