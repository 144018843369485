import { FC, useContext, useEffect, useState } from 'react';
import { StarOutline, StarRate } from '@mui/icons-material';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import { OrganizationsContext } from '../../../context/OrganizationsProvider';
import { updateUserTeamAdmin } from '../../../firebase';
import { UserObj } from '../../../types';
import { AuthContext } from '../../../context/AuthProvider';
import Spinner from '../../reusable-components/Spinner';

type Props = {
	user: UserObj;
};

const ButtonTeamAdmin: FC<Props> = ({ user }: Props) => {
	const { organizationId, teamId } = useParams();

	const { user: userLogged } = useContext(AuthContext);
	const { selectedTeam } = useContext(OrganizationsContext);

	const [isTeamAdmin, setIsTeamAdmin] = useState(false);
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		if (!selectedTeam && !teamId) return;
		const exist =
			(selectedTeam && user.teamAdminIds?.includes(selectedTeam)) ||
			(teamId && user.teamAdminIds?.includes(teamId)) ||
			false;
		setIsTeamAdmin(exist);
	}, [selectedTeam, teamId, user]);

	const handleUserTeamAdmin = async (userId: string, isAdd: boolean) => {
		try {
			setLoading(true);
			await updateUserTeamAdmin({
				isAdd: isAdd,
				userId: userId,
				teamId: selectedTeam || teamId,
				orgId: organizationId,
			});
			setIsTeamAdmin(isAdd);
		} catch (error) {
			console.error('Error updating user team admin', error);
		} finally {
			setLoading(false);
		}
	};

	const [userLoggedHasPermission, setUserLoggedHasPermission] = useState(false);

	useEffect(() => {
		const userLoggedHasPermission =
			userLogged?.teamAdminIds?.includes(selectedTeam || teamId || '') ||
			(organizationId && userLogged?.orgOwnerIds?.includes(organizationId)) ||
			userLogged?.isAdmin ||
			false;
		setUserLoggedHasPermission(userLoggedHasPermission);
	}, [selectedTeam, organizationId, userLogged, teamId]);

	return !loading ? (
		userLoggedHasPermission ? (
			<Button
				disabled={userLogged?.id === user.id}
				onClick={() => handleUserTeamAdmin(user.id || '', !isTeamAdmin)}>
				{isTeamAdmin ? <StarRate /> : <StarOutline />}
			</Button>
		) : isTeamAdmin ? (
			<StarRate />
		) : null
	) : (
		<Spinner />
	);
};

export default ButtonTeamAdmin;

const Button = styled.button`
	background: none;
	border: none;
	color: #fff;
	cursor: pointer;

	&:disabled {
		cursor: not-allowed;
	}
`;
