import { useState, useEffect, useCallback, useContext, useMemo } from 'react';
import Layout from '../components/reusable-components/Layout';
import { getModelers } from '../firebase';
import { Modeler, ProjectStatus } from '../types';
import ModelerTable from '../components/screen-components/Modelers/ModelerTable';
import ModelersSearchbar from '../components/screen-components/Modelers/ModelersSearchbar';
import ModelersTableFilters from '../components/screen-components/Modelers/ModelersTableFilters';
import { ModelerContext } from '../context/ModelerProvider';
import styled from 'styled-components';
import LoadingScreen from '../components/reusable-components/LoadingScreen';

export default function ModelersList() {
	const { modelersQuery, setModelersQuery } = useContext(ModelerContext);

	const [modelers, setModelers] = useState<Modeler[] | null>(null);
	const [loadingModelers, setLoadingModelers] = useState(false);
	const [filteredModelers, setFilteredModelers] = useState<Modeler[] | null>(null);
	const [filters, setFilters] = useState<{ availability?: string; status?: string }>({});

	const statuses: ProjectStatus[] = useMemo(
		() => ['Ready For Modeling', 'Modeling In Progress', 'QA/QC'],
		[]
	);

	// Fetches Modelers from Firebase
	const getSetModelers = useCallback(async () => {
		setLoadingModelers(true);
		setModelers(null);
		const response = (await getModelers()).data as { [key: string]: Modeler };
		const modelersArr = [];
		for (const modeler in response) {
			modelersArr.push(response[modeler]);
		}
		setModelers(modelersArr);
		setFilteredModelers(modelersArr);
		setLoadingModelers(false);
	}, [setLoadingModelers]);

	useEffect(() => {
		getSetModelers();
	}, [getSetModelers]);

	useEffect(() => {
		document.title = 'Modelers | Robotic Imaging';
	}, []);

	const onFilterChange = useCallback(
		(newFilters: { availability?: string; status?: string }) => {
			setFilters(prev => ({ ...prev, ...newFilters }));
		},
		[]
	);

	const filterModelers = useCallback(
		(query?: string) => {
			if (Array.isArray(modelers)) {
				let filtered = modelers;

				// filters search bar for name
				if (query) {
					filtered = filtered.filter(modeler =>
						modeler.name.toLowerCase().includes(query.toLowerCase())
					);
				}
				// filters by availability
				if (filters.availability) {
					if (filters.availability === 'All') {
						filtered = modelers;
					} else {
						filtered = filtered.filter(modeler => {
							const isBusy = Object.keys(modeler.projects).some(status =>
								['Ready For Modeling', 'Modeling In Progress'].includes(status)
							);
							// Still show as available if only QA/QC and no other project statuses
							const onlyQAQC =
								Object.keys(modeler.projects).length === 1 && modeler.projects['QA/QC'];
							return filters.availability === 'Busy' ? isBusy : !isBusy || onlyQAQC;
						});
					}
				}
				// filters by status
				if (
					filters.status &&
					typeof filters.status === 'string' &&
					statuses.includes(filters.status as ProjectStatus)
				) {
					filtered = filtered.filter(modeler => {
						const projIdArr = modeler.projects[filters.status as ProjectStatus];
						if (!projIdArr) return false;
						else {
							return projIdArr.length;
						}
					});
				}
				setFilteredModelers(filtered);
			}
		},
		[modelers, filters.availability, filters.status, statuses]
	);

	// Handles input change for search bar
	const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const value = e.currentTarget.value;
		setModelersQuery(value);
	};

	// Clears search bar queries
	const clearQuery = () => {
		setModelersQuery('');
		filterModelers('');
		document.getElementById('searchbar-input')?.focus();
	};

	useEffect(() => {
		filterModelers(modelersQuery);
	}, [filterModelers, filters, modelersQuery]);

	return (
		<Layout title="Modelers">
			{!loadingModelers ? (
				<TableContainer>
					<ActionBar>
						<ModelersSearchbar
							query={modelersQuery}
							style={{ marginLeft: '20px' }}
							handleInputChange={handleInputChange}
							clearQuery={clearQuery}
						/>
						<ModelersTableFilters onFilterChange={onFilterChange} />
					</ActionBar>
					<ModelerTable modelers={filteredModelers || []} />
				</TableContainer>
			) : (
				<LoadingScreen message="Loading modelers..." />
			)}
		</Layout>
	);
}

const TableContainer = styled.div`
	display: flex;
	flex-direction: column;
	margin-top: 60px;

	@media (max-width: 1023px) {
		margin-top: 0;
	}
`;

const ActionBar = styled.div`
	height: 50px;
	display: flex;
	flex-direction: row;
	background-color: black;
	gap: 12px;
	margin: 0 auto;
	justify-content: center;

	@media (max-width: 1023px) {
		min-height: 150px;
		display: flex;
		flex-direction: column;
		padding: 0px;
		margin: 0 auto;
		margin-top: 20px;
	}
`;
