import { FormNode } from '../../../../utils/types';
import { ChildChipTitle, ChildNodeChip } from '../../Sidebar.styles';
import { memo } from 'react';

const ChildChip = memo(
	({ rootNode, onClick }: { rootNode: FormNode; onClick: () => void }) => {
		return (
			<ChildNodeChip onClick={onClick}>
				<ChildChipTitle>{rootNode.displayTitle}</ChildChipTitle>
			</ChildNodeChip>
		);
	}
);

export default ChildChip;
