import { OAuthProvider } from 'firebase/auth';
import { useContext } from 'react';
import { AuthContext } from '../../context/AuthProvider';

//The Azure Secret expires on 09/01/2026
const provider = new OAuthProvider('microsoft.com');

const useMicrosoft = () => {
	const { handleSocialLogin } = useContext(AuthContext);

	const login = async () => {
		await handleSocialLogin(provider);
	};

	return { login };
};

export default useMicrosoft;
