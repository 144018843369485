import { Dialog, DialogActions } from '@mui/material';
import styled from 'styled-components';

export const StyledDialog = styled(Dialog)`
	& .MuiDialog-paper {
		width: 80%;
		max-width: 600px;
	}
`;

export const StyledDialogActions = styled(DialogActions)`
	& .MuiButton-text {
		text-transform: none;
	}
`;
