import { Button, InputAdornment } from '@mui/material';
import ItemStringField from './ItemStringField';
import styled from 'styled-components';
import { cloneDeep } from 'lodash';

/**
 * Component to render custom fields for each Item.
 */

export default function CustomField({
	field,
	setItemState,
	value,
}: {
	field: string;
	setItemState: React.Dispatch<React.SetStateAction<any>>;
	value: string;
}) {
	const deleteCustomField = (fieldName: string) => {
		setItemState((prev: any) => {
			const clone = cloneDeep(prev);
			delete clone.customFields[fieldName];
			return { ...clone };
		});
	};

	return (
		<div>
			<CustomFieldContainer>
				<ItemStringField
					label={field}
					field={field}
					setState={setItemState}
					value={value}
					type="custom"
					InputProps={{
						endAdornment: (
							<InputAdornment position="end">
								<Button
									sx={{
										fontSize: '20px',
										minWidth: 0,
										width: '40px',
										height: '40px',
										padding: 0,
										margin: 0,
									}}
									size="small"
									onClick={() => deleteCustomField(field)}
									title={`Delete ${field} field`}>
									X
								</Button>
							</InputAdornment>
						),
					}}
				/>
			</CustomFieldContainer>
		</div>
	);
}

const CustomFieldContainer = styled.div`
	display: flex;
	gap: 10px 10px;
	max-width: 205px;
	justify-content: space-between;
`;
