import { useReducer } from 'react';
import { FormIndexesInput, initialState, reducer } from './reducer';

export default function useFormIndexes(indexes?: FormIndexesInput) {
	const [formIndexes, setFormIndexes] = useReducer(reducer, {
		...initialState,
		...indexes,
	});
	return {
		formIndexes,
		setFormIndexes,
	};
}
