import { isEqual } from 'lodash';
import { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';

export const usePreviousUrl = () => {
	const location = useLocation();
	const currentLocationRef = useRef<any>(null);
	const previousLocationRef = useRef<any>(null);

	useEffect(() => {
		if (!isEqual(currentLocationRef.current, location)) {
			previousLocationRef.current = currentLocationRef.current;
			currentLocationRef.current = location;
		}
	}, [location]);

	return previousLocationRef.current;
};
