import { CarouselItem } from './CarouselItem';
import { CarouselProps } from './types';
import { Indicators } from './Indicators';
import { sanitizeProps } from './util';
import {
	StyledButtonWrapper,
	StyledIconButton,
	StyledItemWrapper,
	StyledRoot,
} from './Styled';
import { useEffect, useState, Children, useCallback } from 'react';

export default function Carousel(props: CarouselProps) {
	const sanitizedProps = sanitizeProps(props);
	const {
		children,
		sx,
		className,

		height,

		// stopAutoPlayOnHover,
		animation,
		duration,
		swipe,

		navButtonsAlwaysInvisible,
		navButtonsAlwaysVisible,
		cycleNavigation,
		fullHeightHover,
		navButtonsProps,
		navButtonsWrapperProps,
		NavButton,

		NextIcon,
		PrevIcon,

		indicators,
		indicatorContainerProps,
		indicatorIconButtonProps,
		activeIndicatorIconButtonProps,
		IndicatorIcon,

		lazy,
		lazyPreload,

		controlledIndex,
		setControlledIndex,

		showNextButton,
		showPrevButton,

		captureWindowKeypresses,
	} = sanitizedProps;

	const [state, setState] = useState({
		active: 0,
		prevActive: 0,
		next: true,
	});

	/** useState hooks for keeping track of which index is the current slide and how many slides are already rendered. */
	const [currentSlideIndex, setCurrentSlideIndex] = useState(0);
	const [renderedSlides, setRenderedSlides] = useState(lazyPreload);

	/** Used to set carousel's height. It is being set by the CarouselItems */
	const [childrenHeight, setChildrenHeight] = useState<number>();
	// const [paused, setPaused] = useState<boolean>(false);

	// useInterval(() => {
	// 	const { autoPlay } = sanitizedProps;

	// 	if (autoPlay && !paused) {
	// 		next(undefined);
	// 	}
	// }, sanitizedProps.interval);

	const changeIndex = useCallback(
		(index: number, isNext: boolean, runCallbacks: boolean = true) => {
			const { onChange, underflow, overflow } = sanitizedProps;

			if (runCallbacks) {
				if (isNext !== undefined)
					isNext
						? sanitizedProps.next(index, state.active)
						: sanitizedProps.prev(index, state.active);

				onChange(index, state.active);
			}

			if (setControlledIndex !== undefined) {
				if (index === state.active) {
					if (isNext) overflow();
					else underflow();
				} else {
					setControlledIndex(index);
				}
			} else {
				setCurrentSlideIndex(index);
			}
		},
		[sanitizedProps, setControlledIndex, state.active]
	);

	const next = useCallback(
		(event: any) => {
			const { children, cycleNavigation } = sanitizedProps;

			const lastIdx = Array.isArray(children) ? children.length - 1 : 0;
			let newIdx =
				state.active + 1 > lastIdx ? (cycleNavigation ? 0 : lastIdx) : state.active + 1;

			changeIndex(newIdx, true);

			if (event) event.stopPropagation();
		},
		[changeIndex, sanitizedProps, state.active]
	);

	const prev = useCallback(
		(event: any) => {
			const { children, cycleNavigation } = sanitizedProps;

			const lastIdx = Array.isArray(children) ? children.length - 1 : 0;
			let newIdx =
				state.active - 1 < 0 ? (cycleNavigation ? lastIdx : 0) : state.active - 1;

			changeIndex(newIdx, false);

			if (event) event.stopPropagation();
		},
		[changeIndex, sanitizedProps, state.active]
	);

	// Changes state hook based on the current slide index
	useEffect(() => {
		if (controlledIndex !== undefined) {
			setState(prev => ({
				active: controlledIndex,
				prevActive: prev.active,
				next: controlledIndex > prev.active,
			}));
		} else {
			setState(prev => ({
				active: currentSlideIndex,
				prevActive: prev.active,
				next: currentSlideIndex > prev.active,
			}));
		}
	}, [controlledIndex, currentSlideIndex]);

	const showButton = (next = true) => {
		if (cycleNavigation) return true;

		const last = Array.isArray(children) ? children.length - 1 : 0;

		if (next && state.active === last) return false;
		if (!next && state.active === 0) return false;

		return true;
	};

	const renderChildren = () => {
		const carouselChildren = Children.toArray(children);
		if (carouselChildren.length > 1) {
			// let res;
			let elementArray: React.ReactNode[] = [];
			if (lazy) {
				const currIdx = controlledIndex || currentSlideIndex;
				for (const [i, child] of carouselChildren.entries()) {
					if (Math.abs(currIdx - i) <= lazyPreload) {
						elementArray.push(child);
					} else {
						elementArray.push(null);
					}
				}
				// const visibleItemsCount = renderedSlides + 1;
				// const notVisibleItemsCount = carouselChildren.length - visibleItemsCount;

				// const visibleComponents = carouselChildren.slice(0, visibleItemsCount);
				// const virtualizedItems = Array(
				// 	notVisibleItemsCount > 0 ? notVisibleItemsCount : 0
				// ).fill(null);

				// if (notVisibleItemsCount) res = visibleComponents.concat(virtualizedItems);
				// else res = visibleComponents;
			} else elementArray = carouselChildren;

			return elementArray.map((child, index) => (
				<CarouselItem
					key={`carousel-item${index}`}
					state={state}
					index={index}
					maxIndex={elementArray.length - 1}
					child={child}
					animation={animation}
					duration={duration}
					swipe={swipe}
					next={next}
					prev={prev}
					height={height}
					setHeight={setChildrenHeight}
				/>
			));
		} else if (carouselChildren.length === 1) {
			return (
				<CarouselItem
					key={`carousel-item0`}
					state={state}
					index={0}
					maxIndex={0}
					child={children}
					animation={animation}
					duration={duration}
					height={height}
					setHeight={setChildrenHeight}
				/>
			);
		} else return null;
	};

	/** Loads another slide when a new slide that hasn't been viewed yet is viewed */
	useEffect(() => {
		if (controlledIndex !== undefined && setControlledIndex !== undefined) {
			if (controlledIndex + lazyPreload > renderedSlides) {
				setRenderedSlides(controlledIndex + lazyPreload);
			}
		} else if (currentSlideIndex + lazyPreload > renderedSlides) {
			setRenderedSlides(currentSlideIndex + lazyPreload);
		}
	}, [
		currentSlideIndex,
		controlledIndex,
		setControlledIndex,
		lazyPreload,
		renderedSlides,
	]);

	useEffect(() => {
		const keyPressHandler = (e: KeyboardEvent) => {
			if (e.key === 'ArrowLeft') prev(e);
			else if (e.key === 'ArrowRight') next(e);
		};

		if (captureWindowKeypresses)
			window.document.addEventListener('keyup', keyPressHandler);
		return () => {
			if (captureWindowKeypresses)
				window.document.removeEventListener('keyup', keyPressHandler);
		};
	}, [captureWindowKeypresses, next, prev]);

	return (
		<StyledRoot
			style={sx}
			className={className}
			// onMouseOver={() => {
			// 	if (stopAutoPlayOnHover) setPaused(true);
			// }}
			// onMouseOut={() => {
			// 	if (stopAutoPlayOnHover) setPaused(false);
			// }}
			// onFocus={() => {
			// 	if (stopAutoPlayOnHover) setPaused(true);
			// }}
			// onBlur={() => {
			// 	if (stopAutoPlayOnHover) setPaused(false);
			// }}
			tabIndex={0}
			onKeyUp={
				!captureWindowKeypresses
					? e => {
							if (e.key === 'ArrowLeft') prev(e);
							else if (e.key === 'ArrowRight') next(e);
					  }
					: undefined
			}
			// style={{height: height}} // <-- number | undefined
		>
			<StyledItemWrapper style={{ height: height ? height : childrenHeight }}>
				{renderChildren()}
			</StyledItemWrapper>

			{!navButtonsAlwaysInvisible && (showButton(true) || showNextButton) ? (
				<StyledButtonWrapper
					$next
					$prev={false}
					$fullHeightHover={fullHeightHover}
					{...navButtonsWrapperProps}
					tabIndex={-1}>
					{NavButton !== undefined ? (
						NavButton({ onClick: next, next: true, prev: false, ...navButtonsProps })
					) : (
						<StyledIconButton
							$alwaysVisible={navButtonsAlwaysVisible}
							$fullHeightHover={fullHeightHover}
							onClick={next}
							aria-label="Next"
							{...navButtonsProps}
							tabIndex={-1}>
							{NextIcon}
						</StyledIconButton>
					)}
				</StyledButtonWrapper>
			) : null}

			{!navButtonsAlwaysInvisible && (showButton(false) || showPrevButton) ? (
				<StyledButtonWrapper
					$next={false}
					$prev
					$fullHeightHover={fullHeightHover}
					{...navButtonsWrapperProps}
					tabIndex={-1}>
					{NavButton !== undefined ? (
						NavButton({ onClick: prev, next: false, prev: true, ...navButtonsProps })
					) : (
						<StyledIconButton
							$alwaysVisible={navButtonsAlwaysVisible}
							$fullHeightHover={fullHeightHover}
							onClick={prev}
							aria-label="Previous"
							{...navButtonsProps}
							tabIndex={-1}>
							{PrevIcon}
						</StyledIconButton>
					)}
				</StyledButtonWrapper>
			) : null}

			{indicators ? (
				<Indicators
					length={Array.isArray(children) ? children.length : 0}
					active={state.active}
					press={changeIndex}
					indicatorContainerProps={indicatorContainerProps}
					indicatorIconButtonProps={indicatorIconButtonProps}
					activeIndicatorIconButtonProps={activeIndicatorIconButtonProps}
					IndicatorIcon={IndicatorIcon}
				/>
			) : null}
		</StyledRoot>
	);
}
