import { useContext } from 'react';
import { AuthContext } from '../../../../../../../context/AuthProvider';
import { ProjectData, UserObj } from '../../../../../../../types';
import { MenuItem } from '@mui/material';
import { updateSingleProjectField } from '../../../../../../../firebase/firestore/projects';
import { WhiteTextField } from '../../../../../../styled-components/styledReactTable';
import { StatusTableContext } from '../../../../StatusTableProvider';

export default function AccountManagerCell({
	projectData,
}: {
	projectData: ProjectData;
}) {
	const { user, projectRoleOptions } = useContext(AuthContext);
	const { setStatusProjects } = useContext(StatusTableContext);

	const handleChange = (
		projectData: ProjectData,
		value: ProjectData['accountManager']
	) => {
		updateSingleProjectField(projectData.id as string, value, 'accountManager').catch(e =>
			console.error(e)
		);
		setStatusProjects(prev => {
			if (prev) {
				return prev.map(proj => {
					if (proj.id === projectData.id) {
						return {
							...proj,
							accountManager: value,
						};
					} else return proj;
				});
			} else return prev;
		});
	};

	return user?.isAdmin ? (
		<WhiteTextField
			select
			variant="standard"
			fullWidth
			value={projectData.accountManager?.id || ''}
			onChange={e => {
				const selectedId = e.target.value;
				if (selectedId) {
					const selectedManager = projectRoleOptions['accountManager'].find(
						userWithData => userWithData.id === selectedId
					);
					if (selectedManager) {
						const userObj: UserObj = {
							email: selectedManager.email,
							name: selectedManager.fullName,
							id: selectedManager.id,
						};
						handleChange(projectData, userObj);
					}
				} else {
					handleChange(projectData, undefined);
				}
			}}
			onClick={event => event.stopPropagation()}>
			<MenuItem value={''}>None</MenuItem>
			{projectRoleOptions['accountManager'].map(userWithData => (
				<MenuItem value={userWithData.id} key={userWithData.id}>
					{userWithData.fullName}
				</MenuItem>
			))}
		</WhiteTextField>
	) : (
		<span>{projectData.accountManager?.name ?? 'No Account Manager'}</span>
	);
}
