import { FileBucket, FileType } from './types';

export const getDeliverableTitle = (deliverableKey: FileType) => {
	switch (deliverableKey) {
		case 'proposal':
			return 'Proposal';
		case 'autodesk':
			return '3D / 2D';
		case 'existingDocs':
			return 'Existing Documents / Raw Data';
		case 'pointCloud':
			return 'Point Cloud';
		case 'photos':
			return 'Photos';
		case 'pdf':
			return 'PDF';
		default:
			return 'Unknown';
	}
};

export const getDeliverableDialogBucketTitle = (
	bucket: FileBucket,
	deliverableKey: FileType
) => {
	switch (bucket) {
		case 'inProgress':
			return `In-Progress ${getDeliverableTitle(deliverableKey)} Files`;
		case 'archive':
			return `Archived ${getDeliverableTitle(deliverableKey)} Files`;
		default:
			return `Uploaded ${getDeliverableTitle(deliverableKey)} Files`;
	}
};
