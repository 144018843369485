import { cameraList } from '../../../utils';
import { useEffect, useRef } from 'react';
import styled from 'styled-components';

export function useClickOutside(ref, callback = () => console.log('Clicked Outside')) {
	useEffect(() => {
		function handleClickOutside(event) {
			if (ref.current && !ref.current.contains(event.target)) {
				callback();
			}
		}

		document.addEventListener('mousedown', handleClickOutside);

		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [ref, callback]);
}

/**
 * Functional component for selecting camera types, used in forms
 * @param form Object useState containing all form fields/values
 * @param cameraField string denoting the field name in the form that holds an array of camera types
 * @param setForm useState updater for form
 * @param style style Object for the component container
 * @param label bool to display "Cameras" label. true by default
 */
export default function CameraChips({
	form,
	cameraField,
	setForm,
	style = {},
	label = true,
	onBlur = () => {},
}) {
	const ref = useRef(null);
	useClickOutside(ref, onBlur);

	return (
		<CameraContainer style={style} ref={ref}>
			<CameraRow>
				{label ? <span>Cameras:</span> : ''}
				<SelectedChipContainer>
					{form[cameraField]?.length ? (
						form[cameraField].map(camera => (
							<Chip
								type="button"
								key={camera}
								onClick={() => {
									setForm({
										...form,
										[cameraField]: form[cameraField].filter(cam => cam !== camera),
									});
								}}
								tabIndex="0">
								{camera}
							</Chip>
						))
					) : (
						<span>None</span>
					)}
				</SelectedChipContainer>
			</CameraRow>
			<ChipContainer>
				{cameraList.map(cam => {
					if (!form[cameraField]?.includes(cam)) {
						return (
							<Chip
								type="button"
								key={cam}
								onClick={() => {
									setForm({
										...form,
										[cameraField]: [...form[cameraField], cam],
									});
								}}
								tabIndex="0">
								{cam}
							</Chip>
						);
					}
					return '';
				})}
			</ChipContainer>
		</CameraContainer>
	);
}

const CameraContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 10px;
`;

export const CameraRow = styled.div`
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	align-items: center;
	gap: 10px;

	border-bottom: dotted 2px #ffb310;
	padding-bottom: 5px;
`;

export const ChipContainer = styled.div`
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	align-items: center;
	gap: 5px;
`;

const SelectedChipContainer = styled(ChipContainer)`
	background-color: darkgray;
	padding: 5px;
	border-radius: 5px;
`;

export const Chip = styled.button`
	border: solid 1px grey;
	background-color: white;
	padding: 2px 5px;
	cursor: pointer;
	transition: 0.1s;

	&:hover {
		box-shadow: 0px 0px 5px grey;
	}
	&:active {
		background-color: rgb(245, 245, 245);
	}
`;
