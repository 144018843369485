import { Button, DialogContent, DialogTitle, TextField } from '@mui/material';
import { StyledDialog, StyledDialogActions } from './CreateTemplateDialog.styles';
import { FC, useContext, useEffect, useState } from 'react';
import { FormV2Context } from '../../../context/FormV2Context.types';
import { useTemplateContext } from './utils/useTemplateContext';
import { SnackContext } from '../../../../../../context/SnackProvider';
import LoadingScreen from '../../../../../reusable-components/LoadingScreen';
import { createTemplateFromNode } from '../../../../../../firebase';

type Props = {
	open: boolean;
	onClose: () => void;
	isPartial?: boolean;
	nodeId?: string;
};

const CreateTemplateDialog: FC<Props> = ({
	open,
	onClose,
	isPartial = false,
	nodeId,
}) => {
	const [templateName, setTemplateName] = useState('');
	const [isLoading, setIsLoading] = useState(false);
	const { utilityForm } = useContext(FormV2Context);
	const { addTemplate, isCreating } = useTemplateContext(utilityForm?.id);
	const { setSnackbarProps } = useContext(SnackContext);

	useEffect(() => {
		if (open) {
			setTemplateName('');
		}
	}, [open]);

	const title = isPartial ? 'Partial Template' : 'Template';

	const isNode = nodeId ? true : false;

	const handleAddTemplate = async () => {
		if (!templateName.trim()) return;

		if (isPartial && nodeId) {
			setIsLoading(true);
			await createTemplateFromNode({
				name: templateName.trim(),
				isPartial,
				nodeId: nodeId,
			});
		} else {
			await addTemplate({ name: templateName.trim(), isPartial });
		}

		setSnackbarProps({
			open: true,
			message: `Success! ${title} '${templateName.trim()}' has been created.`,
			severity: 'success',
		});
		setIsLoading(false);
		onClose();
	};

	return (
		<StyledDialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
			<DialogTitle>
				Create {title} from {isNode ? 'Section' : 'Form'}
			</DialogTitle>
			<DialogContent>
				{isCreating || isLoading ? (
					<LoadingScreen size={20} message={'Creating Template'} textColor="black" />
				) : (
					<TextField
						autoFocus
						margin="dense"
						id="template-name"
						label={`${title} Name`}
						placeholder={'Enter new name'}
						type="text"
						fullWidth
						variant="standard"
						value={templateName}
						onChange={e => setTemplateName(e.target.value)}
						disabled={isCreating}
					/>
				)}
			</DialogContent>
			<StyledDialogActions>
				<Button disabled={isCreating} onClick={onClose}>
					Cancel
				</Button>
				<Button
					onClick={handleAddTemplate}
					disabled={!templateName.trim() || isLoading || isCreating}>
					Add Template
				</Button>
			</StyledDialogActions>
		</StyledDialog>
	);
};

export default CreateTemplateDialog;
