import Dropzone, { DropEvent, FileRejection } from 'react-dropzone';
import { Item, Photo } from '../types';
import styled from 'styled-components';
import { useContext, useEffect, useState } from 'react';
import { Button, Dialog, DialogActions, DialogTitle } from '@mui/material';
import { UploadContext, UploadQueueItem } from '../UploadContext';
import { FormContext } from '../FormContext';
import UploadManager from './UploadManager';
import { useParams } from 'react-router-dom';
import { splitFilename } from '../../../../utils';

var CryptoJS = require('crypto-js');

export default function ItemPhotosField({
	categoryIndex,
	itemIndex,
	category,
	item,
	photoFiles,
	setItemState,
	categoryId,
	itemId,
}: {
	categoryIndex: number;
	itemIndex: number;
	category: string;
	item: string;
	categoryId: string;
	itemId: string;
	photoFiles: Photo[];
	setItemState: React.Dispatch<React.SetStateAction<Item>>;
}) {
	const { formId } = useParams() as { [key: string]: string };

	const {
		uploadQueueState,
		setUploadQueueState,
		currentUploadTask,
		queueStateCleanup,
		deleteFromStorage,
	} = useContext(UploadContext);
	const { formState, wrappedDebounce } = useContext(FormContext);

	const [dropzoneInactive, setDropzoneInactive] = useState(false);
	const [openDialog, setOpenDialog] = useState(false);

	/**
	 * Function to generate unique upload task id
	 * @param {File = file}
	 * @returns a hashed string based on the category, item and file details(name, size and lastModified)
	 */

	const generateUploadTaskId = (file: File, name: string) => {
		const size = file.size;
		// const name = file.name;
		const lastModified = file.lastModified;

		var hash = CryptoJS.SHA256(
			categoryId + name + itemId + size + lastModified
		).toString();
		return hash;
	};

	const onDrop = (
		acceptedFiles: File[],
		fileRejections: FileRejection[],
		event: DropEvent
	) => {
		event.stopPropagation();
		// const rejects = fileRejections.map(x => x.file.name);
		if (fileRejections.length) {
			// setSnackbarProps({
			// 	openDialog: true,
			// 	message: `${rejects.join(', ')} rejected`,
			// 	severity: 'error',
			// });
		} else {
			const uniqueFiles: Photo[] = [];
			const uniqueQueueItems: UploadQueueItem[] = [];
			uploadQueueState[formId] = uploadQueueState[formId] || [];

			const existingItemsIds = [
				...uploadQueueState[formId].map(item => {
					return {
						hash: item.photo.hash,
						name: item.photo.name,
						categoryId: item.categoryId,
						itemId: item.itemId,
					};
				}),
				...formState[categoryIndex].items[itemIndex].photos.map(photo => {
					return {
						hash: photo.hash,
						name: photo.name,
						categoryId: formState[categoryIndex].categoryId,
						itemId: formState[categoryIndex].items[itemIndex].itemId,
					};
				}),
			];

			acceptedFiles.forEach(file => {
				//If file has the same name as another file in the same category and item, append unique identifier to the name of the file
				let count = 1;
				let uniqueFileName = file.name;
				while (
					existingItemsIds.some(
						// eslint-disable-next-line no-loop-func
						item =>
							item.name === uniqueFileName &&
							item.itemId === itemId &&
							item.categoryId === categoryId
					)
				) {
					count += 1;
					const [prefix, extension] = splitFilename(file.name);
					uniqueFileName = `${prefix} (${count}).${extension}`;
				}

				const photoHash = generateUploadTaskId(file, uniqueFileName);
				const uniquePhoto = { hash: photoHash, name: uniqueFileName, file: file };

				let queueItem: UploadQueueItem = {
					photo: uniquePhoto,
					category: category,
					item: item,
					categoryId: categoryId,
					itemId: itemId,
					formId: formId,
					setItemState: setItemState,
					categoryIndex: categoryIndex,
					itemIndex: itemIndex,
					cachedFormState: formState,
					wrappedDebounce: wrappedDebounce,
				};

				uniqueQueueItems.push(queueItem);
				uniqueFiles.push(uniquePhoto);
			});

			if (uniqueQueueItems.length) {
				setUploadQueueState(prev => {
					prev[formId] = [...prev[formId], ...uniqueQueueItems];
					return { ...prev };
				});

				setItemState((prev: Item) => {
					return {
						...prev,
						photos: [...prev.photos, ...uniqueFiles],
					};
				});
			}
		}
	};

	const removePhoto = (idx: number, photo: Photo) => {
		//If file has not completed uploaded yet, remove file from context
		if (photo.url === undefined) {
			// If file is currently being uploaded then cancel upload task.
			if (photo.hash === currentUploadTask?.hash) {
				currentUploadTask?.task.cancel();
				queueStateCleanup(photo.hash, formId);
			}
			//If file is not currently being uploaded, remove it from the queue
			else {
				setUploadQueueState(prev => {
					prev[formId] = prev[formId].filter(item => item.photo.hash !== photo.hash);
					return prev;
				});
			}
		}
		//If file has already been uploaded, delete file from storage.
		else {
			const filePath = `utility_forms/${formId}/${categoryId}/${itemId}/photos/${photo.name}`;
			deleteFromStorage(filePath);
		}

		//Remove file from form context

		// photoFiles.splice(idx, 1);
		photoFiles = photoFiles.filter((photo, index) => index !== idx);

		//Update form data
		setItemState((prev: Item) => {
			return { ...prev, photos: photoFiles };
		});
	};

	useEffect(() => {
		if (photoFiles.length > 0) {
			setDropzoneInactive(true);
		} else {
			setDropzoneInactive(false);
		}
	}, [setItemState, photoFiles, openDialog]);
	return (
		<Dropzone noClick={dropzoneInactive} onDrop={onDrop}>
			{({ getRootProps, getInputProps, isDragActive, open }) => {
				return (
					<>
						<DropZoneContainer
							{...getRootProps()}
							onClick={() => (dropzoneInactive ? setOpenDialog(true) : open())}>
							<input {...getInputProps()} />
							<FilenameContainer>
								{photoFiles.length ? (
									<>
										<span>{photoFiles[0].name}</span>

										{photoFiles.length > 1 ? (
											<span>{` and ${
												photoFiles.length - 1
											} other photo(s) uploaded`}</span>
										) : null}
									</>
								) : (
									<span>Click or drag and drop your photos here...</span>
								)}
							</FilenameContainer>
						</DropZoneContainer>

						<Dialog open={openDialog} fullWidth onClose={() => setOpenDialog(false)}>
							<DialogTitle>{`Selected Photos (${photoFiles.length} items)`}</DialogTitle>
							<UploadManager photoFiles={photoFiles} removePhoto={removePhoto} />
							<DialogActions>
								<Button onClick={() => open()}>Add Photos</Button>
								<Button onClick={() => setOpenDialog(false)}>Done</Button>
							</DialogActions>
						</Dialog>
					</>
				);
			}}
		</Dropzone>
	);
}

const DropZoneContainer = styled.div`
	display: flex;
	border: 2px darkgray dashed;
	width: 25%;
	height: 150px;
	padding: 10px;
	background-color: rgb(20, 20, 20);
	cursor: pointer;
	transition: 0.2s;
	color: white;
	align-items: center;
	justify-content: center;

	// &:hover {
	// 	border-color: #ffb310;
	// 	color: #ffb310;
	// 	background-color: rgb(50, 50, 50);
	// }
	@media screen and (max-width: 768px) {
		width: 100%;
	}
`;

const FilenameContainer = styled.div`
	overflow: hidden;
	text-overflow: ellipsis;
	text-align: center;
`;
