import { IconButton, ListItemIcon, Menu, MenuItem } from '@mui/material';
import {
	ContentCopy,
	Delete,
	Edit,
	Flag,
	MoreVert,
	RemoveCircle,
} from '@mui/icons-material';
import { useContext, useEffect, useRef, useState } from 'react';
import { deleteDoc, doc, updateDoc } from 'firebase/firestore';
import { copyAndPasteNode, db } from '../../../../../../firebase';
import { FormNode, FormNodeWithChildren } from '../../../utils/types';
import { EditNodeOptionsDialog } from '../../../../../reusable-components/EditNodeOptionsDialog/EditNodeOptionsDialog';
import ConfirmDialog from '../../../../TemplateList/ConfirmDialog';
import { FormV2Context } from '../../../context/FormV2Context.types';
import { SnackContext } from '../../../../../../context/SnackProvider';

type NodeActionButtonProps = {
	node: FormNode;
};

interface CopyNodeResponse {
	copiedNodeData: FormNodeWithChildren;
}

export default function NodeActionButton({ node }: NodeActionButtonProps) {
	const [editOptionsVisible, setEditOptionsVisible] = useState(false);
	const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
	const { formId, setCopiedNodeData } = useContext(FormV2Context);
	const [actionAnchorEl, setActionAnchorEl] = useState<null | HTMLElement>(null);
	const actionMenuOpen = Boolean(actionAnchorEl);
	const { setSnackbarProps } = useContext(SnackContext);

	const _isMounted = useRef(true);
	useEffect(() => {
		_isMounted.current = true;
		return () => {
			_isMounted.current = false;
		};
	}, []);

	const handleDelete = async () => {
		setConfirmDialogOpen(false);
		if (node.id) {
			await deleteDoc(doc(db, 'utility_forms_v2_items', node.id));
		}
	};

	const handleClickActionMenu = (
		event: React.MouseEvent<HTMLButtonElement, MouseEvent>
	) => {
		setActionAnchorEl(event.currentTarget as unknown as HTMLElement);
	};

	const handleCloseActionMenu = () => {
		if (_isMounted.current) setActionAnchorEl(null);
	};

	const handleEditOptions = () => {
		handleCloseActionMenu();
		setEditOptionsVisible(true);
	};

	const handleUpdateOptions = async (options: string[]) => {
		if (node.id) {
			await updateDoc(doc(db, 'utility_forms_v2_items', node.id), { options });
		}
	};

	const handleSetFlag = async (flag: string) => {
		handleCloseActionMenu();

		if (node.id) {
			await updateDoc(doc(db, 'utility_forms_v2_items', node.id), {
				flag,
			});
		}
	};

	const handleCopyNode = async () => {
		handleCloseActionMenu();
		try {
			const result = (await copyAndPasteNode({
				operation: 'copy',
				itemId: node.id,
				formId: formId,
			})) as { data: CopyNodeResponse };
			console.log('Node copied:', result.data.copiedNodeData);
			setCopiedNodeData(result.data.copiedNodeData);
			setSnackbarProps({
				open: true,
				message: 'Success! Item copied!',
				severity: 'success',
			});
		} catch (error) {
			console.error('error copying node: ', error);
			setSnackbarProps({
				open: true,
				message: 'Error copying item. Please try again',
				severity: 'error',
			});
		}
	};

	return (
		<>
			<IconButton onClick={handleClickActionMenu}>
				<MoreVert />
			</IconButton>

			{/* Actions Menu */}
			<Menu
				anchorEl={actionAnchorEl}
				open={actionMenuOpen}
				onClose={handleCloseActionMenu}>
				<MenuItem
					onClick={() => {
						setConfirmDialogOpen(true);
						handleCloseActionMenu();
					}}
					style={{ alignItems: 'center' }}>
					<ListItemIcon>
						<Delete style={{ color: 'grey' }} />
					</ListItemIcon>
					Delete Item
				</MenuItem>

				{node.type === 'select' ? (
					<MenuItem onClick={handleEditOptions}>
						<ListItemIcon>
							<Edit style={{ color: 'grey' }} />
						</ListItemIcon>
						Edit options
					</MenuItem>
				) : null}

				{node.flag && (
					<MenuItem onClick={() => handleSetFlag('')}>
						<ListItemIcon>
							<RemoveCircle style={{ color: 'grey' }} />
						</ListItemIcon>
						Remove flag
					</MenuItem>
				)}
				{node.flag !== 'progress' && (
					<MenuItem onClick={() => handleSetFlag('progress')}>
						<ListItemIcon>
							<Flag style={{ color: 'lightskyblue' }} />
						</ListItemIcon>
						Mark as in progress
					</MenuItem>
				)}
				{node.flag !== 'review' && (
					<MenuItem onClick={() => handleSetFlag('review')}>
						<ListItemIcon>
							<Flag style={{ color: 'orangered' }} />
						</ListItemIcon>
						Mark as needs review
					</MenuItem>
				)}
				{node.flag !== 'completed' && (
					<MenuItem onClick={() => handleSetFlag('completed')}>
						<ListItemIcon>
							<Flag style={{ color: 'mediumseagreen' }} />
						</ListItemIcon>
						Mark as completed
					</MenuItem>
				)}
				<MenuItem onClick={() => handleCopyNode()}>
					<ListItemIcon>
						<ContentCopy style={{ color: 'grey' }} />
					</ListItemIcon>
					Copy Item
				</MenuItem>
			</Menu>

			<EditNodeOptionsDialog
				isOpen={editOptionsVisible}
				node={node}
				onClose={() => setEditOptionsVisible(false)}
				onSaveItem={handleUpdateOptions}
			/>

			<ConfirmDialog
				handleClose={() => setConfirmDialogOpen(false)}
				handleConfirm={handleDelete}
				open={confirmDialogOpen}
				title="Delete Item"
				label={`Are you sure you want to delete the item ${node.displayTitle}?`}
			/>
		</>
	);
}
