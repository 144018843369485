import { FC } from 'react';
import styled from 'styled-components';

const ListButtonsContainer: FC<React.PropsWithChildren<unknown>> = ({ children }) => {
	return <Container>{children}</Container>;
};

const Container = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 10px;
`;

export default ListButtonsContainer;
