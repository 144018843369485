import styled from 'styled-components';

export const CaptureFormHeaderContainer = styled.div`
	display: flex;
	flex-direction: column;
	background-color: #fefefe;
	width: 95%;
	border-radius: 30px;
height: 8%;	
min-height: 70px;
	margin: 0 auto;
	align-items: center;
	justify-content: center;
	position: relative;
	box-shadow: 0px 2px 5px 1px rgba(0, 0, 0, 0.15);
`;
