import { FC } from 'react';
import styled from 'styled-components';

const ListTitleContainer: FC<React.PropsWithChildren<unknown>> = ({ children }) => {
	return <Container>{children}</Container>;
};

const Container = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	gap: 10px;
	margin: 0;
	padding: 10px;
`;

export default ListTitleContainer;
