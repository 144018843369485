import React, { useContext, useState } from 'react';
import styled from 'styled-components';

import AddIcon from '@mui/icons-material/Add';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import LockIcon from '@mui/icons-material/Lock';

import { AuthContext } from '../../../../context/AuthProvider';
import { SnackContext } from '../../../../context/SnackProvider';
import { checkPermissions } from '../../../../utils';

const getBorderColor = (props: any) => {
	if (props.dragCounter !== 0 && !props.downloadOnly) return '#00e676';
	return 'black';
};

const getBorderStyle = (props: any) => {
	if (props.dragCounter !== 0 && !props.downloadOnly) return 'dashed';
	return 'solid';
};

const getBackgroundColor = (props: any) => {
	if (props.projectStatus === 'Pending Payment' && (props.downloadOnly || props.isClient))
		return 'red';
	if (props.dragCounter !== 0) return 'antiquewhite';
	if (props.hasFiles) return 'forestgreen';
	return '#ffb310';
};

const getOpacity = (props: any) => {
	if (props.dragCounter !== 0) return '0.8';
	return 1;
};

export default function DeliverableIconBox({
	hasFiles,
	downloadOnly,
	projectStatus,
	promptUpload,
	downloadFunction,
}: {
	hasFiles: boolean;
	downloadOnly: boolean;
	projectStatus: string;
	promptUpload: () => void;
	downloadFunction: () => Promise<void>;
}) {
	const { user } = useContext(AuthContext);
	const { setSnackbarProps } = useContext(SnackContext);

	const [mouseHover, setMouseHover] = useState<boolean>(false);
	const [dragCounter, setDragCounter] = useState<number>(0);

	return (
		<IconBoxContainer
			hasFiles={hasFiles}
			downloadOnly={downloadOnly}
			isClient={!checkPermissions(user, 'contractor')}
			dragCounter={dragCounter}
			projectStatus={projectStatus}
			onClick={e => {
				e.stopPropagation();
				if (
					projectStatus === 'Pending Payment' &&
					(downloadOnly || !checkPermissions(user, 'contractor'))
				) {
					setSnackbarProps({
						open: true,
						message: `Cannot download files until payment is processed. Please contact a member of the Robotic Imaging team.`,
						severity: 'error',
					});
				} else {
					if (hasFiles) downloadFunction();
					else if (!downloadOnly) promptUpload();
				}
			}}
			onKeyUp={e => {
				if (e.key === 'Enter') {
					e.stopPropagation();
					if (
						projectStatus === 'Pending Payment' &&
						(downloadOnly || !checkPermissions(user, 'contractor'))
					) {
					} else {
						if (hasFiles) downloadFunction();
						else if (!downloadOnly) promptUpload();
					}
				}
			}}
			tabIndex={0}
			onDragEnter={() => setDragCounter(prev => (prev += 1))}
			onDragLeave={() => setDragCounter(prev => (prev -= 1))}
			onMouseEnter={() => setMouseHover(true)}
			onMouseLeave={() => {
				setDragCounter(0);
				setMouseHover(false);
			}}
			title={
				hasFiles
					? 'Download files'
					: !downloadOnly
					? 'Drag and drop or click to upload files'
					: 'No files uploaded'
			}>
			{projectStatus === 'Pending Payment' &&
			(downloadOnly || !checkPermissions(user, 'contractor')) ? (
				<LockIcon />
			) : mouseHover ? (
				hasFiles ? (
					<CloudDownloadIcon />
				) : !downloadOnly ? (
					<CloudUploadIcon />
				) : (
					<CloseIcon />
				)
			) : hasFiles ? (
				<StyledCheckIcon />
			) : !downloadOnly ? (
				<StyledAddIcon />
			) : (
				<CloseIcon />
			)}
		</IconBoxContainer>
	);
}

const IconBoxContainer = styled.div<{
	hasFiles: boolean;
	downloadOnly: boolean;
	isClient: boolean;
	dragCounter: number;
	projectStatus: string;
}>`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	border-radius: 20px;
	color: black;
	font-weight: bolder;
	transition: 0.3s;
	height: 10vh;
	width: 10vh;
	border-color: ${(props: any) => getBorderColor(props)};
	border-style: ${(props: any) => getBorderStyle(props)};
	background-color: ${(props: any) => getBackgroundColor(props)};
	opacity: ${(props: any) => getOpacity(props)};
	cursor: ${(props: any) => {
		if (props.hasFiles || !props.downloadOnly) return 'pointer';
		else return '';
	}};
`;

const StyledAddIcon = styled(AddIcon)`
	color: white;
	margin: auto;
`;

const StyledCheckIcon = styled(CheckIcon)``;
