import { useContext, useState } from 'react';
import SectionHeader from '../../../screens/ProjectsList/components/ClientProjects/components/SectionHeader';
import { ContentList, Scroller } from '../TeamTables';
import { ContainerBorder } from '../../../screens/ProjectsList/components/ClientProjects/styles';
import { MapContext } from '../../../context/MapProvider';
import ProjectMap from '../../screen-components/ProjectInfo/components/ProjectInfoMap/ProjectMap';

const UserMap = () => {
	const [isOpen, setIsOpen] = useState<boolean>(true);
	const { setQuery } = useContext(MapContext);

	const searchMap = (query: string) => {
		setQuery(query);
	};

	return (
		<ContainerBorder style={{ flexGrow: isOpen ? 1 : 'initial' }}>
			<ContentList>
				<SectionHeader
					headerStyle={{ padding: '0 20px' }}
					showDropdown={true}
					showSearch={true}
					onSearch={searchMap}
					dropdownValue={setIsOpen}>
					Map
				</SectionHeader>
				<Scroller>
					{isOpen && (
						<ProjectMap
							lat={38.95155} // Missouri, United States
							lng={-92.32859} // Missouri, United States
							initialZoom={3}
							initialHeight="100%"
						/>
					)}
				</Scroller>
			</ContentList>
		</ContainerBorder>
	);
};

export default UserMap;
