import { FC, useCallback, useEffect, useState } from 'react';
import { FirebaseFile, ProcoreUpload } from '../../../../../../../types';
import styled from 'styled-components';
import { Checkbox } from '@mui/material';
import FileSize from '../../../../../../reusable-components/DeliverableFiles/components/ProjectFilesDialog.tsx/components/FileSize';
import { collection, getDocs, onSnapshot, query, where } from 'firebase/firestore';
import { db } from '../../../../../../../firebase';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import CloudDoneIcon from '@mui/icons-material/CloudDone';

type Props = {
	title: string;
	files: FirebaseFile[];
	disabled?: boolean;
	handleAddItem: (isAdd: boolean, file: FirebaseFile, folder: string) => void;
};

type FileUploaded = FirebaseFile & { status: string };

const FileList: FC<Props> = ({ title, files, disabled, handleAddItem }: Props) => {
	const [fileSizeInMegabytes, setFileSizeInMegabytes] = useState(0);
	const [procoreUploaded, setProcoreUpload] = useState<FileUploaded[]>([]);

	const fileSizeLimitInMegabytes = 5000; // 5GB

	const formatUploadDate = (file: any) => {
		if (file.uploadDate._seconds) {
			return new Date(file.uploadDate._seconds * 1000);
		}
	};

	const filterUploadFile = async (file: FirebaseFile) => {
		const collectionRef = collection(db, 'procore_upload');
		const q = query(collectionRef, where('url', '==', file.downloadUrl));
		const snap = await getDocs(q);

		if (snap.empty) {
			return null;
		}

		return snap.docs[0].data() as ProcoreUpload;
	};

	const getFileData = useCallback(() => {
		files.forEach(async file => {
			const procoreUpload = await filterUploadFile(file);

			setProcoreUpload(prev => {
				if (prev.filter(f => f.downloadUrl === file.downloadUrl).length > 0) {
					return prev;
				} else {
					return [...prev, { ...file, status: procoreUpload?.status || '' }];
				}
			});
		});
	}, [files]);

	useEffect(() => {
		getFileData();
	}, [getFileData]);

	useEffect(() => {
		const unsubscribe = onSnapshot(collection(db, 'procore_upload'), snapshot => {
			const data: ProcoreUpload[] = [];
			snapshot.forEach(doc => {
				data.push(doc.data() as ProcoreUpload);
			});

			setProcoreUpload(prev =>
				prev.map(file => {
					const procoreFile = data.find(f => f.url === file.downloadUrl);
					if (procoreFile) {
						return { ...file, status: procoreFile.status };
					} else {
						return file;
					}
				})
			);
		});

		return () => {
			unsubscribe();
		};
	}, []);

	return (
		<>
			<FileListTitle>{title}</FileListTitle>
			{procoreUploaded?.map(file => (
				<FileContent key={file.filename}>
					{file.status === 'completed' ? (
						<IconCompleted />
					) : file.status === 'pending' ? (
						<IconPeding />
					) : (
						<Checkbox
							disabled={
								fileSizeInMegabytes > fileSizeLimitInMegabytes ||
								disabled ||
								file.status !== ''
							}
							onChange={e => {
								handleAddItem(e.target.checked, file, title.toLowerCase());
							}}
						/>
					)}

					<FilenameSpan>{file.filename}</FilenameSpan>

					<Timestamp>
						<span>{formatUploadDate(file)?.toLocaleDateString()}</span>
						<span>{formatUploadDate(file)?.toLocaleTimeString()}</span>
					</Timestamp>

					<FileSize
						file={file}
						fileSizeInMegabytes={(size: number) => {
							setFileSizeInMegabytes(size);
							return size;
						}}
					/>
				</FileContent>
			))}
		</>
	);
};

export default FileList;

const FileListTitle = styled.h3`
	margin: 0;
`;

const FileContent = styled.div`
	display: flex;
	align-items: center;
	gap: 10px;

	line-height: normal;
	transition: 0.2s;
	padding: 1px;

	&:hover {
		background-color: rgba(0, 0, 0, 0.1);
	}
`;

const FilenameSpan = styled.span`
	overflow: hidden;
	overflow-wrap: break-word;
`;

const Timestamp = styled('span')`
	display: flex;
	flex-direction: column;
	align-items: end;
	gap: 0;

	margin-left: auto;
	padding: 0;

	color: black;
	line-height: normal;
	text-align: end;
	font-size: 12px;
	font-weight: 700;

	@media screen and (min-width: 768px) {
		flex-shrink: 0;
	}
`;

const IconPeding = styled(CloudUploadIcon)`
	color: #ffb310;
	font-size: 25px;
	margin: 8px;
`;

const IconCompleted = styled(CloudDoneIcon)`
	color: green;
	font-size: 25px;
	margin: 8px;
`;
