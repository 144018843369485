import Sidebar from '../../../Sidebar/Sidebar';
import FormContainer from '../../FormContainer';
import { Page } from './FormFillV2.styles';

export default function FormFillV2() {
	return (
		<Page>
			<Sidebar />
			<FormContainer />
		</Page>
	);
}
