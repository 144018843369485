import { useState } from 'react';

import styled from 'styled-components';
import { Button, Collapse, IconButton } from '@mui/material';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { DocusignEnvelope, DocusignEnvelopeStatus } from '../types';
import { capitalize } from 'lodash';

const docusignEnvelopeStatusFormat = (status: DocusignEnvelopeStatus) => {
	switch (status) {
		case 'created':
			return 'Draft';
		case 'correct':
			return 'Editing';
		default:
			return capitalize(status);
	}
};

export default function Envelope({
	envelope,
	selectEnvelope,
}: {
	envelope: DocusignEnvelope;
	selectEnvelope: (
		envelopeId: string,
		envelopeStatus: DocusignEnvelopeStatus,
		envelopeName: string
	) => void;
}) {
	const [showDetails, setShowDetails] = useState(true);
	return (
		<EnvelopeContainer
			key={envelope.envelopeId}
			{...{ component: 'div' }} // Change Button root component to div due to interactive element nesting.
			onClick={() =>
				selectEnvelope(
					envelope.envelopeId,
					envelope.status,
					envelope.emailSubject || 'Unnamed Envelope'
				)
			}
			title={`View ${envelope.emailSubject}`}>
			<EnvelopeTitleContainer>
				<EnvelopeTitle
					className={'docusign-envelope-title'}
					// If the EnvelopeTitle overflows its container, display its innerText as a title tooltip on hover
					onMouseEnter={e => {
						const target = e.target as HTMLHeadingElement;
						const overflowed = target.scrollWidth > target.clientWidth;
						if (overflowed) {
							target.title = target.innerText;
						}
					}}>
					{envelope.emailSubject}
				</EnvelopeTitle>

				<IconButton
					onClick={e => {
						setShowDetails(prev => !prev);
						e.stopPropagation();
					}}
					title={`${showDetails ? 'Hide' : 'Show'} details for ${envelope.emailSubject}`}>
					{showDetails ? <ExpandLess /> : <ExpandMore />}
				</IconButton>
			</EnvelopeTitleContainer>

			<Collapse in={showDetails} timeout="auto" unmountOnExit>
				<EnvelopeDetailsContainer>
					<EnvelopeDetail>
						<strong>{'Status: '}</strong>

						{docusignEnvelopeStatusFormat(envelope.status)}
					</EnvelopeDetail>

					<EnvelopeDetail>
						<strong>{'Last Modified: '}</strong>
						{envelope.lastModifiedDateTime.toLocaleDateString()}
						<span>{' | '}</span>
						{envelope.lastModifiedDateTime.toLocaleTimeString()}
					</EnvelopeDetail>

					<EnvelopeDetail>
						<strong>{'Signers: '}</strong>

						{envelope.recipients.signers.length
							? envelope.recipients.signers.map(signer => signer.email).join(', ')
							: 'None'}
					</EnvelopeDetail>

					<EnvelopeDetail>
						<strong>{'Documents: '}</strong>

						{envelope.documents.length
							? envelope.documents.map(document => document.name).join(', ')
							: 'None'}
					</EnvelopeDetail>
				</EnvelopeDetailsContainer>
			</Collapse>
		</EnvelopeContainer>
	);
}

const EnvelopeContainer = styled(Button)`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: 0;

	text-transform: none;
	color: black;
	background-color: #d7d7d7;

	width: 100%;
	padding: 10px;
`;

const EnvelopeTitleContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
`;

const EnvelopeTitle = styled.h3`
	margin: 0;
	overflow: hidden;
	text-overflow: ellipsis;
`;

const EnvelopeDetailsContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
`;

const EnvelopeDetail = styled.span`
	padding-left: 20px;
	font-size: 12px;
	text-align: start;
`;
