import { useState } from 'react';
import { ReportQuestion, ReportQuestionOption } from '../../../types/reports';

const useQuestions = () => {
	const [formListDialogOpen, setFormListDialogOpen] = useState(false);
	const [option, seOption] = useState<ReportQuestionOption | undefined>();
	const [question, setQuestion] = useState<ReportQuestion>();

	const onClickOption = (option: ReportQuestionOption, question: ReportQuestion) => {
		if (option.count === 0) return;

		setQuestion(question);
		seOption(option);
		setFormListDialogOpen(true);
	};

	const onClickMissing = (question: ReportQuestion) => {
		setQuestion(question);
		seOption({ value: 'Missing', count: -1 });
		setFormListDialogOpen(true);
	};

	const onClickAvailable = (question: ReportQuestion) => {
		setQuestion(question);
		seOption({ value: 'Available', count: -1 });
		setFormListDialogOpen(true);
	};

	const onClickNoValue = (question: ReportQuestion) => {
		setQuestion(question);
		seOption({ value: '', count: -1 });
		setFormListDialogOpen(true);
	};

	return {
		formListDialogOpen,
		onClickAvailable,
		onClickMissing,
		onClickNoValue,
		onClickOption,
		option,
		question,
		setFormListDialogOpen,
	};
};

export default useQuestions;
