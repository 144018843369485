import { useContext } from 'react';
import { OrganizationsContext } from '../../../../context/OrganizationsProvider';
import { ProjectInfoTextField } from '../../ProjectInfo/styled-components';

export default function TextInputField({
	field,
	id,
	type = 'text',
	className,
	onBlur,
	onChange,
	isActive,
	inactiveDisplay = ' ',
	multiline = false,
	rows = 1,
}: {
	field: string;
	id?: string;
	type?: string;
	className?: string;
	onBlur?: React.FocusEventHandler<HTMLInputElement>;
	onChange?: React.ChangeEventHandler<HTMLInputElement>;
	isActive: boolean;
	inactiveDisplay?: string;
	multiline?: boolean;
	rows?: number;
}) {
	const { organization }: any = useContext(OrganizationsContext);
	return isActive ? (
		<ProjectInfoTextField
			name={field}
			id={id}
			type={type}
			className={className}
			defaultValue={organization?.[field] || ''}
			onBlur={onBlur}
			onChange={onChange}
			fullWidth
			multiline={multiline}
			rows={multiline ? rows : 1}
			disabled={!isActive}
		/>
	) : (
		<div className="fieldInfo" style={{ minHeight: '0' }}>
			{organization?.[field] || inactiveDisplay}
		</div>
	);
}
