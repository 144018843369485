import { Dispatch, SetStateAction, useContext } from 'react';
import styled from 'styled-components';

import DeliverableIconBox from './DeliverableIconBox';
import { FileType } from '../types';
import { AuthContext } from '../../../../context/AuthProvider';
import { ProjectContext } from '../../../../context/ProjectProvider';
import { checkPermissions } from '../../../../utils';
import { getDeliverableTitle } from '../utils';

export default function DeliverableContent({
	deliverableKey,
	downloadOnly,
	setShowingDialog,
	promptUpload,
	downloadFunction,
	anonUpload = false,
}: {
	deliverableKey: FileType;
	downloadOnly: boolean;
	setShowingDialog: Dispatch<SetStateAction<boolean>>;
	promptUpload: () => void;
	downloadFunction: () => Promise<void>;
	anonUpload?: boolean;
}) {
	const { user } = useContext(AuthContext);
	const { project } = useContext(ProjectContext);

	const handleClick = () => {
		setShowingDialog(true);
	};

	let totalFiles = null;
	// Admin in project info
	if (checkPermissions(user, 'admin') && !anonUpload && !downloadOnly) {
		totalFiles =
			(project?.[deliverableKey]?.length ?? 0) +
			(project?.[`${deliverableKey}InProgress`]?.length ?? 0) +
			(project?.[`${deliverableKey}Archive`]?.length ?? 0);
	}
	// Contractor admin in project info
	else if (checkPermissions(user, 'contractor') && !anonUpload && !downloadOnly) {
		totalFiles =
			(project?.[deliverableKey]?.length ?? 0) +
			(project?.[`${deliverableKey}InProgress`]?.length ?? 0);
	}
	// Client in project info
	else if (!anonUpload && !downloadOnly) {
		totalFiles = project?.[deliverableKey]?.length ?? 0;
	}
	// Anon upload
	else if (anonUpload) {
		totalFiles =
			(project?.[deliverableKey]?.length ?? 0) +
			(project?.[`${deliverableKey}InProgress`]?.length ?? 0);
	}
	// Anon download
	else if (downloadOnly) {
		totalFiles = totalFiles = project?.[deliverableKey]?.length ?? 0;
	}

	return (
		<>
			<DeliverableIconBox
				hasFiles={!!project?.[deliverableKey]?.length}
				downloadOnly={downloadOnly}
				projectStatus={project?.projectStatus as string}
				promptUpload={promptUpload}
				downloadFunction={downloadFunction}
			/>

			<DropZoneTitle>{getDeliverableTitle(deliverableKey)}</DropZoneTitle>

			{totalFiles ? (
				<DeliverableFilesLink
					title="View uploaded files"
					tabIndex={0}
					onClick={() => handleClick()}
					onKeyUp={e => {
						if (e.key === 'Enter') handleClick();
					}}>
					{`${totalFiles} file(s) uploaded`}
				</DeliverableFilesLink>
			) : (
				<div>&nbsp;</div>
			)}
		</>
	);
}

const DropZoneTitle = styled.div`
	display: flex;
	justify-content: center;
`;

const DeliverableFilesLink = styled.span`
	color: #ffb310;
	cursor: pointer;
	&:hover,
	&:focus,
	&:active {
		color: white;
	}
	margin: 0 10px;
	display: inline-block;
	max-width: calc(100% - 44px);
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
`;
