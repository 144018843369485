import { useCallback, useEffect, useState } from 'react';
import { Row } from 'react-table';
// import { SnackContext } from '../../../../../context/SnackProvider';
import LeadTextCell from './LeadTextCell';
import PhoneInTalkIcon from '@mui/icons-material/PhoneInTalk';
import BasicIconButton from '../../../../../../components/reusable-components/BasicIconButton';
import { Lead } from '../../../../types';

export default function LeadPhoneCell({
	row,
	field,
}: {
	row: Row<Lead>;
	field: 'mobileNumber' | 'hqNumber';
}) {
	const [phoneString, setPhoneString] = useState('');

	const getPhoneString = useCallback((str: string) => {
		if (typeof str !== 'string') {
			console.log(str);
			return null;
		}
		if (str.startsWith('+')) {
			// International numbers
			return `+${str.replace(/\D/g, '')}`;
		} else {
			// American numbers
			const numbers = str.replace(/\D/g, '');
			if (numbers.length < 10) {
				return null;
			} else {
				return `1${numbers.slice(0, 10)}`;
			}
		}
	}, []);

	useEffect(() => {
		if (row?.original[field]) {
			const str = getPhoneString(row.original[field]);
			setPhoneString(str ?? '');
		}
	}, [field, getPhoneString, row.original]);

	return (
		<>
			<LeadTextCell row={row} field={field}>
				{phoneString ? (
					<BasicIconButton href={`tel:${phoneString}`} title={`Call ${phoneString}`}>
						<PhoneInTalkIcon />
					</BasicIconButton>
				) : null}
			</LeadTextCell>
		</>
	);
}
