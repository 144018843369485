import { useContext, useState, useRef } from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';

import styled from 'styled-components';
import axios from 'axios';
import { Buffer } from 'buffer';
import { v4 as uuidv4 } from 'uuid';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { ProjectContext } from '../../../../../../context/ProjectProvider';
import { SnackContext } from '../../../../../../context/SnackProvider';
import { getDate } from '../../../../../../utils';
import { DocusignAuthToken } from '../types';
import LoadingScreen from '../../../../../reusable-components/LoadingScreen';
import { getJWT } from '../../../../../../firebase';

export default function CreateEnvelopeDialog({
	showing,
	setShowing,
}: {
	showing: boolean;
	setShowing: React.Dispatch<React.SetStateAction<boolean>>;
}) {
	const { project } = useContext(ProjectContext);
	const { setSnackbarProps } = useContext(SnackContext);

	const [loadingText, setLoadingText] = useState('');

	const projectName = project?.address || project?.name || 'Unnamed Project';
	const defaultEnvelopeSubject = `${projectName} (${getDate('mm-dd-yyyy')})`;
	const [envelopeSubject, setEnvelopeSubject] = useState(defaultEnvelopeSubject);
	const [recipientName, setRecipientName] = useState('');
	const [recipientEmail, setRecipientEmail] = useState('');
	const [files, setFiles] = useState<File[]>([]);
	const fileInputRef = useRef<HTMLInputElement | null>(null);

	const resetFields = () => {
		setEnvelopeSubject(defaultEnvelopeSubject);
		setRecipientName('');
		setRecipientEmail('');
		setFiles([]);
		if (fileInputRef.current) fileInputRef.current.value = '';
	};

	const onFilesChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		if (e.target.files) {
			const fileArr = Array.from(e.target.files);
			const totalBytes = fileArr.reduce((acc, curr) => acc + curr.size, 0);
			const byteLimit = 30 * 1024 * 1024;

			if (totalBytes <= byteLimit) setFiles(fileArr);
			else {
				setSnackbarProps({
					open: true,
					severity: 'warning',
					message: 'Inital upload limit is 30MB. You can upload more documents later.',
				});
				e.target.value = '';
			}
		} else {
			setFiles([]);
		}
	};

	const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		setLoadingText('Buffering attachments...');

		const multipartBoundary = 'a00c1456-1e69-4bd8-84c7-6de8b8ae008b';
		const CRLF = '\r\n';
		const hyphens = '--';

		const body = {
			emailSubject: envelopeSubject,
			recipients: {
				signers: [
					// Specify 'clientUserId' and 'embeddedRecipientStartURL' for hybrid signing
					// See here: https://www.docusign.com/blog/developers/deep-dive-the-embedded-signing-recipient-view
					{
						name: recipientName,
						email: recipientEmail,
						clientUserId: uuidv4(),

						deliveryMethod: 'email',
						embeddedRecipientStartURL: 'SIGN_AT_DOCUSIGN',

						recipientId: '1',
						routingOrder: '1',
					},
				],
			},
			status: 'created',
			documents: files.map((file, i) => {
				return {
					name: file.name,
					fileExtension: 'pdf',
					documentId: i + 1,
				};
			}),
			customFields: {
				textCustomFields: [
					{
						name: 'projectId',
						show: 'true',
						value: project?.id,
					},
				],
			},
		};

		const documents = await Promise.all(
			files.map(async (file, i) => {
				return {
					mime: file.type,
					filename: file.name,
					documentId: i + 1,
					bytes: await file.arrayBuffer(),
				};
			})
		);

		let reqBody = Buffer.from(
			[
				hyphens,
				multipartBoundary,
				CRLF,
				'Content-Type: application/json',
				CRLF,
				'Content-Disposition: form-data',
				CRLF,
				CRLF,
				JSON.stringify(body, null, '    '),
			].join('')
		);
		documents.forEach(d => {
			reqBody = Buffer.concat([
				reqBody,
				Buffer.from(
					[
						CRLF,
						hyphens,
						multipartBoundary,
						CRLF,
						`Content-Type: ${d.mime}`,
						CRLF,
						`Content-Disposition: file; filename="${d.filename}"; documentId=${d.documentId}`,
						CRLF,
						CRLF,
					].join('')
				),
				Buffer.from(d.bytes),
			]);
		});
		reqBody = Buffer.concat([
			reqBody,
			Buffer.from([CRLF, hyphens, multipartBoundary, hyphens, CRLF].join('')),
		]);

		try {
			setLoadingText('Getting Docusign Auth Token...');
			const jwt = (await getJWT()).data as DocusignAuthToken;
			setLoadingText('Creating Docusign Envelope...');
			await axios.post(
				`${process.env.REACT_APP_DOCUSIGN_BASE_PATH}/v2.1/accounts/${process.env.REACT_APP_DOCUSIGN_API_ACCOUNT_ID}/envelopes`,
				reqBody,
				{
					headers: {
						Accept: 'application/json',
						Authorization: `Bearer ${jwt.access_token}`,
						'Content-Type': `multipart/form-data; boundary=${multipartBoundary}`,
					},
				}
			);
		} catch (err) {
			console.error(err);
		}

		resetFields();
		setLoadingText('');
		handleClose();
		setSnackbarProps({
			open: true,
			severity: 'success',
			message:
				'Docusign envelope successfully created! Please wait about 30 seconds,  then refresh the list to view the new envelope.',
		});
	};

	const handleClose = () => {
		if (!loadingText) {
			setShowing(false);
			resetFields();
		} else {
			setSnackbarProps({
				open: true,
				severity: 'info',
				message: 'Cannot close form while Docusign envelope is being created!',
			});
		}
	};

	return (
		<Dialog open={showing} onClose={handleClose}>
			<form onSubmit={onSubmit}>
				<DialogTitle>Create A New Docusign Envelope</DialogTitle>

				<DialogContent>
					{!loadingText ? (
						<ContentContainer>
							<TextField
								variant="standard"
								sx={{ minWidth: '200px' }}
								label="Envelope Subject"
								value={envelopeSubject}
								onChange={e => setEnvelopeSubject(e.target.value)}
								required
							/>
							<TextField
								variant="standard"
								sx={{ minWidth: '200px' }}
								label="Recipient Name"
								value={recipientName}
								onChange={e => setRecipientName(e.target.value)}
								required
							/>
							<TextField
								variant="standard"
								sx={{ minWidth: '200px' }}
								label="Recipient Email"
								value={recipientEmail}
								onChange={e => setRecipientEmail(e.target.value)}
								required
							/>
							<input
								type="file"
								accept=".pdf"
								ref={fileInputRef}
								onChange={onFilesChange}
								multiple
								required
							/>
						</ContentContainer>
					) : (
						<LoadingScreen message={loadingText} textColor="black" />
					)}
				</DialogContent>

				<DialogActions>
					<Button type="button" onClick={handleClose}>
						Close
					</Button>
					<Button type="submit" disabled={!!loadingText}>
						Submit
					</Button>
				</DialogActions>
			</form>
		</Dialog>
	);
}

const ContentContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 10px;
	width: 100%;
`;
