import { useCallback, useContext, useState, Fragment } from 'react';
import {
	Backdrop,
	Button,
	FormControl,
	Input,
	MenuItem,
	Select,
	SelectChangeEvent,
	styled,
} from '@mui/material';
import { ScrollableContent } from '../../../../../screens/ProjectsList/components/ClientProjects/styles';
import { ProjectContext } from '../../../../../context/ProjectProvider';
import { uploadFile } from '../../../../../utils/project-file-upload';
import { FileType } from '../../types';
import { SnackContext } from '../../../../../context/SnackProvider';
import { AuthContext } from '../../../../../context/AuthProvider';
import Spinner from '../../../Spinner';

const ClientUploadBoxContent: React.FC<{
	anonUpload?: boolean;
}> = ({ anonUpload = false }) => {
	const [fileType, setFileType] = useState<FileType | null>(null);
	const [files, setFiles] = useState<File[]>([]);
	const { project, setProject } = useContext(ProjectContext);
	const [loadingDetails, setLoadingDetails] = useState('line1\nline2');
	const { setSnackbarProps } = useContext(SnackContext);
	const [uploading, setUploading] = useState(false);
	const { user } = useContext(AuthContext);
	const isContractor = !!user?.isContractor;

	const uploadFiles = useCallback(async () => {
		if (files.length === 0 || !fileType) {
			setSnackbarProps({
				open: true,
				message: `Please select file type and file(s)`,
				severity: 'warning',
			});
			return;
		}
		setUploading(true);
		for (const [index, file] of files.entries()) {
			setLoadingDetails(`Uploading ${index + 1} of ${files.length} ${fileType} files...`);
			setSnackbarProps({
				open: true,
				message: `Uploading ${file.name}...`,
				severity: 'warning',
			});

			try {
				// Call uploadFile and handle the promise
				await uploadFile(
					file,
					project?.id as string,
					fileType,
					() => {},
					setLoadingDetails,
					setProject,
					undefined,
					anonUpload || isContractor ? 'InProgress' : ''
				);
				setSnackbarProps({
					open: true,
					message: `Success! ${file.name} uploaded.`,
					severity: 'success',
				});
			} catch (error: any) {
				console.error('Error uploading file:', error);
				setSnackbarProps({
					open: true,
					message: `Upload failed: ${error.message}`,
					severity: 'error',
				});
			}
		}
		setFileType(null);
		setFiles([]);
		setUploading(false);
		setLoadingDetails('');
	}, [
		files,
		fileType,
		setSnackbarProps,
		project?.id,
		setProject,
		anonUpload,
		isContractor,
	]);

	const handleFileTypeChange = (event: SelectChangeEvent<string | unknown>) => {
		const newFileType = event.target.value as FileType;
		setFileType(newFileType);
	};

	const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const files = event.target.files ? Array.from(event.target.files) : [];
		setFiles(files);
	};

	const handleUpload = async () => {
		if (!fileType || files.length === 0) {
			setSnackbarProps({
				open: true,
				message: 'Please select a file type and at least one file.',
				severity: 'warning',
			});
			return;
		}
		await uploadFiles();
	};

	const typeList = [
		{
			value: '',
			label: <em>Select File Type</em>,
		},
		{
			value: 'existingDocs',
			label: 'Existing Documents / Raw Data',
		},
		{
			value: 'photos',
			label: 'Photos',
		},
		{
			value: 'pdf',
			label: 'PDF Documents',
		},
		{
			value: 'pointCloud',
			label: 'Point Cloud',
		},
		{
			value: 'autodesk',
			label: 'Autodesk Files',
		},
	];

	return (
		<Fragment>
			<Backdrop
				open={uploading}
				style={{
					display: 'flex',
					flexDirection: 'column',
					position: 'absolute',
					zIndex: 100,
					color: '#fff',
				}}>
				{uploading && <Spinner size={100} />}
				{loadingDetails && <div style={{ marginTop: 20 }}>{loadingDetails}</div>}
			</Backdrop>

			<ScrollableContent
				style={{
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'center',
					justifyContent: 'center',
				}}>
				<StyledFormControl fullWidth>
					<StyledSelect
						value={fileType || ''}
						onChange={event => handleFileTypeChange(event)}
						displayEmpty
						fullWidth
						renderValue={(selected: any) =>
							!!selected ? selected : <em>Select File Type</em>
						}>
						{typeList.map(item => (
							<MenuItem key={item.value} value={item.value}>
								{item.label}
							</MenuItem>
						))}
					</StyledSelect>
				</StyledFormControl>
				<StyledInput
					type="file"
					inputProps={{ multiple: true }}
					onChange={handleFileChange}
					fullWidth
				/>
				<Button
					onClick={handleUpload}
					variant="contained"
					color="primary"
					style={{ textTransform: 'none' }}>
					Upload
				</Button>
			</ScrollableContent>
		</Fragment>
	);
};

const StyledFormControl = styled(FormControl)`
	margin-top: 20px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
`;

const StyledSelect = styled(Select)`
	margin-top: 20px;
	background-color: ${props => props.theme.palette.common.white};
	color: ${props => props.theme.palette.common.black};
	width: 90%;
	height: 40px;
`;

const StyledInput = styled(Input)`
	color: ${props => props.theme.palette.common.white};
	margin: 20px;
	border: 1px solid ${props => props.theme.palette.primary.main};
	width: 90%;
	border-radius: 10px;
`;

export default ClientUploadBoxContent;
