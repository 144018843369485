import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	List,
	ListItemButton,
	ListItemText,
	TextField,
	styled,
} from '@mui/material';
import { SnackContext } from '../../../context/SnackProvider';
import LoadingScreen from '../../reusable-components/LoadingScreen';
import { OrganizationsContext } from '../../../context/OrganizationsProvider';
import { queryProjects } from '../../../firebase';
import { ProjectData } from '../../../types';
import { ProjectQueryResult, ProjectQueryCursor } from '../../../types';

export default function AddProjectDialog({
	open,
	teamId,
	setOpen,
}: {
	open: boolean;
	teamId: string;
	setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) {
	const { addingProjectToTeam, addProjectsToTeam, team } =
		useContext(OrganizationsContext);
	const { setSnackbarProps } = useContext(SnackContext);
	const [searchQuery, setSearchQuery] = useState('');
	const [projects, setProjects] = useState<ProjectData[]>([]);
	const [selectedProjects, setSelectedProjects] = useState<ProjectData[]>([]);
	const [isSearching, setIsSearching] = useState(false);
	const debounceTimer = useRef<NodeJS.Timeout | null>(null);
	const debouncedSearchQuery = useRef<string>('');
	const cursor = useRef<ProjectQueryCursor | null>(null);

	const cachedQuery = useRef<string | null>(null);

	const fetchQueriedProjects = useCallback(async () => {
		setIsSearching(true);
		try {
			const res = await queryProjects({
				query: debouncedSearchQuery.current,
				searchMode: 'general',
				limit: 20,
				cursor: cursor.current,
			});
			const fetchedProjects = res.data as ProjectQueryResult;
			cursor.current = fetchedProjects.cursor || null;

			// Filter out projects that are already added to the team
			const existingProjectIds = team?.projectIds || [];
			const newProjects = fetchedProjects.projects.filter(
				project => project.id && !existingProjectIds.includes(project.id)
			);

			if (newProjects.length) {
				// If there are any new projects to add, update the hook.
				if (searchQuery !== cachedQuery.current) {
					// If it's a new query, replace projects
					setProjects(newProjects);
					cachedQuery.current = searchQuery;
				} else {
					// if it's the same query, append to projects
					setProjects(prevProjects => [...prevProjects, ...newProjects]);
				}
			} else if (fetchedProjects.projects.length === 20) {
				// If there are no new projects to add, but the query result hit the limit of 20, there
				// might be more projects to show, so just query again with the updated cursor.
				fetchQueriedProjects();
			}
		} catch (err) {
			console.error(err);
		} finally {
			setIsSearching(false);
		}
	}, [searchQuery, team?.projectIds]);

	useEffect(() => {
		if (debouncedSearchQuery.current !== searchQuery) {
			if (debounceTimer.current) {
				clearTimeout(debounceTimer.current);
			}
			debounceTimer.current = setTimeout(() => {
				debouncedSearchQuery.current = searchQuery;
				cursor.current = null;
				fetchQueriedProjects();
			}, 500); // 500 milliseconds debounce time
		}
	}, [searchQuery, fetchQueriedProjects]);

	const handleSearchChange = (
		e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
	) => {
		setSearchQuery(e.target.value);
	};

	const handleProjectSelection = (project: ProjectData) => {
		setSelectedProjects(prev => {
			const newSelectedProjects = prev.find(p => p.id === project.id)
				? prev.filter(p => p.id !== project.id)
				: [...prev, project];

			return newSelectedProjects;
		});
	};

	const handleClose = () => {
		setOpen(false);
		setSearchQuery('');
		setProjects([]);
		setSelectedProjects([]);
	};

	const handleScroll = (event: React.UIEvent<HTMLElement>) => {
		const { scrollTop, scrollHeight, clientHeight } = event.currentTarget;
		const isNearBottom = scrollHeight - scrollTop <= clientHeight * 1.05;

		if (isNearBottom && !isSearching && cursor.current) {
			console.log('Fetching more projects...');
			fetchQueriedProjects();
		}
	};

	useEffect(() => {
		return () => {
			// Clear timer when component unmounts
			if (debounceTimer.current) {
				clearTimeout(debounceTimer.current);
			}
		};
	}, []);

	const handleSubmit = async () => {
		if (selectedProjects.length === 0 || !team) return;

		const projectIds = selectedProjects
			.map(p => p.id)
			.filter(id => id !== undefined) as string[];
		try {
			const success = await addProjectsToTeam(projectIds, teamId, team?.organizationId);
			handleClose();

			if (success) {
				setSnackbarProps({
					open: true,
					severity: 'success',
					message: `Successfully added projects to team!`,
				});
			} else {
				setSnackbarProps({
					open: true,
					severity: 'error',
					message: `Failed to add projects to team!`,
				});
			}
		} catch (err) {
			console.error(err);
			handleClose();
		}
	};

	return (
		<StyledDialog open={open} onClose={handleClose} maxWidth="xs" fullWidth>
			<DialogTitle>Add Project(s) to Team</DialogTitle>

			{!addingProjectToTeam ? (
				<DialogContent onScroll={handleScroll}>
					<TextField
						variant="standard"
						label="Search by address or client..."
						value={searchQuery}
						onChange={handleSearchChange}
					/>
					{projects.length > 0 ? (
						<List>
							{projects.map((project, index) => (
								<ListItemButton
									key={index}
									onClick={() => handleProjectSelection(project)}
									selected={selectedProjects.some(p => p.id === project.id)}
									sx={{
										backgroundColor: selectedProjects.some(p => p.id === project.id)
											? 'rgba(255, 179, 16, 0.1)'
											: '#f6f6f6',
										color: 'black',
										'&:hover': {
											backgroundColor: 'rgba(0, 0, 0, 0.04)',
										},
									}}>
									<ListItemText
										primary={project.address || 'Unnamed Project'}
										secondary={project.clients || 'n/a'}
									/>
								</ListItemButton>
							))}
						</List>
					) : searchQuery ? (
						<span>no projects found</span>
					) : null}
				</DialogContent>
			) : (
				<DialogContent>
					<LoadingScreen message="Adding project..." textColor="black" />
				</DialogContent>
			)}

			<DialogActions>
				{!addingProjectToTeam ? (
					<>
						<Button onClick={handleClose} style={{ textTransform: 'none' }}>
							Cancel
						</Button>
						<Button
							onClick={handleSubmit}
							style={{ textTransform: 'none' }}
							disabled={selectedProjects.length === 0}>
							Add project(s)
						</Button>
					</>
				) : null}
			</DialogActions>
		</StyledDialog>
	);
}

const StyledDialog = styled(Dialog)(({ theme }) => ({
	'& .MuiDialog-paper': {
		maxWidth: '800px',
	},

	'& .MuiDialogContent-root': {
		maxHeight: '60vh',
		overflowY: 'auto',
	},
}));
