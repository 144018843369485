import { useContext, useEffect, useState } from 'react';
import { FormContext } from '../FormContext';
import ItemStringField from '../utils/ItemStringField';
import { MetaData, Photo } from '../types';
import AddCustomField from '../utils/AddCustomField';
import CustomField from '../utils/CustomField';
import DeleteItem from '../utils/DeleteItem';
import EditableHeaderField from '../utils/EditableHeaderField';
import ItemCheckBoxField from '../utils/ItemCheckBoxField';
import ItemSelectField from '../utils/ItemSelectField';
import styled from 'styled-components';
import ItemPhotosField from '../utils/ItemPhotosField';
import { UploadContext } from '../UploadContext';
import { isEqual } from 'lodash';
import { useParams } from 'react-router-dom';

export default function CreateItem({
	categoryIndex,
	categoryId,
	itemIndex,
	metadataMap,
}: {
	categoryIndex: number;
	categoryId: string;
	itemIndex: number;
	metadataMap?: MetaData;
}) {
	const { formId } = useParams() as { [key: string]: string };

	const { formState, setFormState, wrappedDebounce } = useContext(FormContext);
	const { setUploadQueueState, globalCachedForm } = useContext(UploadContext);

	const [itemState, setItemState] = useState(formState[categoryIndex].items[itemIndex]);

	// Keeps Item component up to date with global formState
	useEffect(() => {
		setItemState(prev => {
			const formStateItem = formState[categoryIndex].items[itemIndex];
			//Get photo ids of the photos in the formState
			const existingPhotoHashes = formStateItem.photos.map(photo => photo.hash);

			//Get all photos in the uploadQueueState exclusive of the photos in the the formState
			const uploadQueuePhotos: Photo[] = [];
			setUploadQueueState(uploadQueue => {
				uploadQueue[formId]?.forEach((queueItem, idx) => {
					if (
						// Filter photos in the uploadQueueState by item id
						// Exclude any photos that are already a part of the formState to avoid duplication
						queueItem.itemId === formStateItem.itemId &&
						!existingPhotoHashes.includes(queueItem.photo.hash)
					) {
						// Since we are getting photos from the upload queue, the setItemState functions
						// in the upload queue are outdated because they refer to componenets that were already unmounted.
						// Thus, we update the upload queue items with new setItemState setters that refer to the currently mounted
						// components.
						const newQueueItem = queueItem;
						newQueueItem.setItemState = setItemState;
						uploadQueue[formId].splice(idx, 1, newQueueItem);
						uploadQueuePhotos.push(newQueueItem.photo);
					}
				});
				return uploadQueue;
			});
			const uploadQueuePhotosHashes = uploadQueuePhotos.map(photo => photo.hash);

			// Get photos from globalCachedForm
			const cachedPhotos: Photo[] = [];
			if (
				globalCachedForm &&
				globalCachedForm.length >= categoryIndex + 1 &&
				globalCachedForm[categoryIndex].categoryId === categoryId &&
				globalCachedForm[categoryIndex].items.length >= itemIndex + 1 &&
				globalCachedForm[categoryIndex].items[itemIndex].itemId === formStateItem.itemId
			) {
				globalCachedForm[categoryIndex].items[itemIndex].photos.forEach(photo => {
					if (
						!existingPhotoHashes.includes(photo.hash) &&
						!uploadQueuePhotosHashes.includes(photo.hash)
					) {
						cachedPhotos.push(photo);
					}
				});
			}

			// Add the photos from the uploadQueueState to the formState
			if (cachedPhotos || uploadQueuePhotos) {
				prev.photos = [...prev.photos, ...cachedPhotos, ...uploadQueuePhotos];
			}

			return prev;
		});
	}, [
		formState,
		itemIndex,
		categoryIndex,
		setUploadQueueState,
		globalCachedForm,
		categoryId,
		formId,
	]);

	// Keeps global formState up to date with itemState
	useEffect(() => {
		setFormState(prev => {
			if (
				!isEqual(itemState, prev[categoryIndex].items[itemIndex]) &&
				prev[categoryIndex]
			) {
				const itemsArray = prev[categoryIndex].items;
				itemsArray.splice(itemIndex, 1, itemState);

				const oldParent = prev[categoryIndex];
				const updatedParent = {
					...oldParent,
					items: itemsArray,
				};
				prev.splice(categoryIndex, 1, updatedParent);

				wrappedDebounce(undefined, prev);
			}
			return prev;
		});
	}, [itemState, setFormState, categoryIndex, itemIndex, wrappedDebounce]);

	return (
		<div>
			<HeaderContainer>
				<h3>
					<EditableHeaderField
						field="displayTitle"
						value={itemState.displayTitle}
						setState={setItemState}
						styles={{
							colorTheme: 'secondary',
							style: { fontSize: '0.875rem', color: '#000000' },
						}}
					/>
				</h3>
				<AddCustomField itemState={itemState} setItemState={setItemState} />
				<DeleteItem categoryIndex={categoryIndex} itemIndex={itemIndex} />
			</HeaderContainer>

			<MainContainer>
				<ItemBaseFieldContainer>
					<ItemStringField
						value={itemState.location}
						label="Location"
						field="location"
						setState={setItemState}
					/>

					{metadataMap &&
						Object.keys(itemState.metadata as {}).map(field => {
							const metadata = itemState.metadata as MetaData;
							if (field === 'read') {
								return (
									<ItemSelectField
										key={field}
										label={metadataMap[field]}
										field={field}
										setState={setItemState}
										value={metadata[field]}
										options={['OFF', 'ON']}
									/>
								);
							} else if (typeof metadata[field] === 'boolean') {
								return (
									<ItemCheckBoxField
										key={field}
										label={metadataMap[field]}
										field={field}
										setState={setItemState}
										value={metadata[field]}
									/>
								);
							} else {
								return (
									<ItemStringField
										key={field}
										label={metadataMap[field]}
										field={field}
										setState={setItemState}
										type="metadata"
										value={metadata[field]}
									/>
								);
							}
						})}

					<ItemStringField
						value={itemState.notes}
						label="Notes"
						field="notes"
						setState={setItemState}
					/>

					{Object.keys(itemState.customFields as {}).map(field => (
						<CustomField
							key={field}
							field={field}
							setItemState={setItemState}
							value={itemState.customFields?.[field]}
						/>
					))}
				</ItemBaseFieldContainer>

				<ItemPhotosField
					categoryId={categoryId}
					itemId={itemState.itemId}
					categoryIndex={categoryIndex}
					itemIndex={itemIndex}
					category={formState[categoryIndex].displayTitle || ''}
					item={itemState.displayTitle}
					photoFiles={itemState.photos}
					setItemState={setItemState}
				/>
			</MainContainer>
		</div>
	);
}

const HeaderContainer = styled.div`
	background-color: #ffb310;
	display: flex;
	flex-direction: row;
	gap: 5px 10px;
	margin-bottom: 10px;
	margin-top: 5px;
	@media screen and (max-width: 768px) {
		width: 100%;
	}
`;

const ItemBaseFieldContainer = styled.div`
	display: flex;
	gap: 10px 10px;
	flex-wrap: wrap;
	padding: 5px;
	width: 75%;
	@media screen and (max-width: 768px) {
		width: 100%;
	}
`;

const MainContainer = styled.div`
	display: flex;
	flex-wrap: wrap;
	width: 100%;
`;
