import { Box, LinearProgress, LinearProgressProps, Typography } from '@mui/material';
import { useContext } from 'react';
import { UploadContext } from '../UploadContext';
import { UtilityFormLoadingContainer } from '../UtilityFormRenderer';
import Spinner from '../../../reusable-components/Spinner';

export default function SubmittingForm({ formId }: { formId: string }) {
	const { uploadQueueState, currentUploadProgress } = useContext(UploadContext);

	const uploadQueueArr = Object.keys(uploadQueueState);

	return (
		<UtilityFormLoadingContainer>
			<Spinner size={140} />
			<h2>Submitting...</h2>

			{uploadQueueArr[0] === formId ? (
				<>
					<h3>{`Currently Uploading: ${uploadQueueState[formId][0].photo.name} `}</h3>
					<Box sx={{ width: '100%' }}>
						<LinearProgressWithLabel value={currentUploadProgress} />
					</Box>
					{uploadQueueState[formId].length - 1 ? (
						<h4>{`${uploadQueueState[formId].length - 1} file(s) queued for upload`}</h4>
					) : null}
				</>
			) : (
				<>
					<h3>{`Currently submitting form - ${uploadQueueArr[0]}`}</h3>
					<h4>{`${uploadQueueState[formId].length} file(s) queued for upload`}</h4>
				</>
			)}
		</UtilityFormLoadingContainer>
	);
}

function LinearProgressWithLabel(props: LinearProgressProps & { value: number }) {
	return (
		<Box sx={{ display: 'flex', alignItems: 'center' }}>
			<Box sx={{ width: '100%', mr: 1 }}>
				<LinearProgress variant="determinate" {...props} />
			</Box>
			<Box sx={{ minWidth: 35 }}>
				<Typography variant="body2" color="text.secondary">{`${Math.round(
					props.value
				)}%`}</Typography>
			</Box>
		</Box>
	);
}
