import { useContext, useState } from 'react';
import { AuthContext } from '../../../../context/AuthProvider';
import { UserWithData } from '../../../../types';
import { UserTag } from '../../../../types/project-roles';
import SkeletonTable from '../../../reusable-components/SkeletonTable';
import DataTable from '../../../reusable-components/DataTable';
import getUserColumns from './columns';
import { FilterActions } from './FilterActions';
import UserPermsDialog from './UserPermsDialog';
import UserTagDialog from './UserTagsDialog';
import styled from 'styled-components';

export default function Users() {
	const { usersWithData, fetchingUsers } = useContext(AuthContext);
	const [userPermsEdit, setUserPermsEdit] = useState<UserWithData | null>(null);
	const [userTagsEdit, setUserTagsEdit] = useState<UserWithData | null>(null);
	const [showFilterBar, setShowFilterBar] = useState(false);
	const [selectedTags, setSelectedTags] = useState<UserTag[]>([]);

	const userColumns = getUserColumns(setUserPermsEdit, setUserTagsEdit);

	const handlePermsClose = () => {
		setUserPermsEdit(null);
	};

	const handleTagsClose = () => {
		setUserTagsEdit(null);
	};

	const resetTags = () => {
		setSelectedTags([]);
	};

	const toggleFilterBar = () => setShowFilterBar(prev => !prev);

	const handleTagFilterChange = (tag: UserTag) => {
		setSelectedTags(prev => {
			if (prev.includes(tag)) {
				return prev.filter(t => t !== tag);
			} else {
				return [...prev, tag];
			}
		});
	};

	return fetchingUsers ? (
		<SkeletonContainer>
			<SkeletonTable />
		</SkeletonContainer>
	) : (
		<>
			<TableWrapper>
				<DataTable
					data={usersWithData}
					columns={userColumns}
					queryFields={['fullName', 'email']}
					name={''}
					showButton
					showFilterButton
					onFilterSelect={toggleFilterBar}
					additionalActions={
						<FilterActions
							showFilterBar={showFilterBar}
							toggleFilterBar={toggleFilterBar}
							selectedTags={selectedTags}
							onTagFilterChange={handleTagFilterChange}
							resetTags={resetTags}
						/>
					}
					selectedTags={selectedTags}
					onTagFilterChange={handleTagFilterChange}
					disabledField="disabled"
				/>
			</TableWrapper>

			<UserPermsDialog
				open={!!userPermsEdit}
				onClose={handlePermsClose}
				setUserPermsEdit={setUserPermsEdit}
				userPermsEdit={userPermsEdit}
			/>

			<UserTagDialog
				open={!!userTagsEdit}
				onClose={handleTagsClose}
				setUserTagsEdit={setUserTagsEdit}
				userTagsEdit={userTagsEdit}
			/>
		</>
	);
}

const SkeletonContainer = styled.div`
	max-width: 640px;
	width: 100%;
	margin: auto;
`;

const TableWrapper = styled.div`
	display: block;
	border-radius: 20px;
	margin: 0 auto;

	width: 100%;
	height: 100%;
`;
