import { useMediaQuery } from '@mui/system';
import { FC, useCallback, useContext, useEffect, useState } from 'react';
import { CoverImage } from '../CoverImage';
import { useParams } from 'react-router-dom';
import { Tooltip } from '@mui/material';
import { Group, Lock, UnfoldLess, UnfoldMore } from '@mui/icons-material';
import { FormV2Context } from '../../screen-components/ProjectUtilityFormV2/context/FormV2Context.types';
import { BackButton } from '../../reusable-components/BackButton';
import Tools from './buttons/Tools';
import { YellowButton } from '../../styled-components/styledReactTable';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import { StyledIconButton } from './buttons/components';
import MenuItemContent from '../MenuItem/MenuItem';
import { FormDialog } from '../../../screens/NodesReorder/components/FormDialog';
import { AssignDialog } from '../../../screens/NodesReorder/components/FormDialog/AssignDialog';
import { TemplateContext } from '../../../context/TemplateProvider';
import {
	Container,
	CustomMenu,
	CustomMenuItem,
	Description,
	HeaderTitle,
	HeaderTitleContainer,
	HeaderTitleContent,
	HeaderTitleFlex,
	HeaderTitleFlexItem,
	IconsContainer,
	ImagesContainer,
	Title,
	ViewModeToggle,
} from './FormHeader.styles';
import { MenuReorderContext } from '../../screen-components/Form/Form';
import useFormNodes from '../../screen-components/Form/components/FormWrapper/useFormNodes';
import useFormTemplateNodes from '../../screen-components/Form/components/FormWrapper/useFormTemplateNodes';
import { AuthContext } from '../../../context/AuthProvider';

const FormHeader: FC = () => {
	const matches = useMediaQuery('(max-width:1024px)');
	const { projectId }: any = useParams();
	const { utilityForm, isViewMode, formId } = useContext(FormV2Context);
	const { isTemplate, templateName, template, isPartial } = useContext(TemplateContext);
	const { toggleAllItems, expandedList } = useContext(MenuReorderContext);
	const { user } = useContext(AuthContext);
	const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
	const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
	const [isAssignDialogOpen, setIsAssignDialogOpen] = useState<boolean>(false);
	const [assignType, setAssignType] = useState<'project' | 'team'>('team');
	const [isAllExpanded, setIsAllExpanded] = useState(false);
	const [hideButton, setHideButton] = useState(false);

	const { allFormNodes } = useFormNodes(
		utilityForm?.id ?? '',
		utilityForm?.projectId ?? ''
	);
	const { allFormTemplateNodes } = useFormTemplateNodes(utilityForm?.id ?? '');

	const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const handleDialog = () => {
		setIsDialogOpen(!isDialogOpen);
	};

	const handleAssignDialog = () => {
		setIsAssignDialogOpen(!isAssignDialogOpen);
	};

	const getRootSections = useCallback(() => {
		const nodes = isTemplate ? allFormTemplateNodes : allFormNodes;
		return nodes
			.filter(node => !node.parentId && node.type === 'node')
			.map(node => node.id);
	}, [allFormNodes, allFormTemplateNodes, isTemplate]);

	const open = Boolean(anchorEl);
	const id = open ? 'simple-popover' : undefined;

	const handleExpandCollapseAll = () => {
		const rootSectionIds = getRootSections();

		if (isAllExpanded) {
			toggleAllItems(expandedList.filter(id => !rootSectionIds.includes(id)));
		} else {
			const newExpandedList = [...new Set([...expandedList, ...rootSectionIds])];
			toggleAllItems(newExpandedList);
		}
		setIsAllExpanded(!isAllExpanded);
	};

	useEffect(() => {
		const rootSectionIds = getRootSections();
		const allExpanded =
			rootSectionIds.length > 0 && rootSectionIds.every(id => expandedList.includes(id));
		setIsAllExpanded(allExpanded);
	}, [expandedList, allFormNodes, allFormTemplateNodes, getRootSections]);

	const handleDisableButton = useCallback(() => {
		if (isTemplate && user?.id !== template?.userId && !user?.isAdmin) {
			setHideButton(true);
		}
	}, [isTemplate, template?.userId, user?.id, user?.isAdmin]);

	useEffect(() => {
		handleDisableButton();
	}, [handleDisableButton]);

	return (
		<Container>
			{matches && (
				<BackButton
					url={`/projects/${projectId}`}
					buttonText="Back to Project"
					noMargin
				/>
			)}
			{isTemplate ? (
				<>
					<HeaderTitleContainer>
						<HeaderTitle>{templateName}</HeaderTitle>
						<IconsContainer>
							{!hideButton && !isPartial && (
								<YellowButton
									aria-describedby={id}
									variant="contained"
									onClick={handleClick}>
									Assign Template
								</YellowButton>
							)}
							<CustomMenu anchorEl={anchorEl} open={open} onClose={handleClose}>
								<CustomMenuItem
									onClick={() => {
										setAssignType('team');
										handleAssignDialog();
										handleClose();
									}}>
									<MenuItemContent
										title="Assign to Team(s)"
										startIcon={<Group color="primary" />}
									/>
								</CustomMenuItem>
							</CustomMenu>
							{!isPartial && (
								<StyledIconButton onClick={handleDialog}>
									<NoteAddIcon color="primary" />
								</StyledIconButton>
							)}
							<FormDialog open={isDialogOpen} handleClose={handleDialog} />
							<AssignDialog
								open={isAssignDialogOpen}
								type={assignType}
								handleClose={handleAssignDialog}
							/>
						</IconsContainer>
					</HeaderTitleContainer>
					<Title>Report Content</Title>
					<Description>
						Add blocks to create sections and inputs for this report.
					</Description>
				</>
			) : (
				<>
					<HeaderTitleContainer>
						<div>
							<HeaderTitleFlex>
								<HeaderTitle>{utilityForm?.formName}</HeaderTitle>
								<HeaderTitleFlexItem>
									<Tools />
								</HeaderTitleFlexItem>
							</HeaderTitleFlex>
							{!isViewMode && (
								<HeaderTitleContent>
									<div>
										<div>
											<Title>Cover images</Title>
											<Description>
												Add images to be displayed on the cover page of the analytics
												generated from this report.
											</Description>
										</div>
										<ImagesContainer>
											<CoverImage formId={formId} hasLogo />
										</ImagesContainer>
									</div>
								</HeaderTitleContent>
							)}
						</div>
						<IconsContainer>
							{utilityForm?.locked && (
								<Tooltip title="The form cannot be submitted while uploading photos">
									<Lock color="primary" />
								</Tooltip>
							)}
							{isViewMode && (
								<ViewModeToggle
									variant="contained"
									color="primary"
									onClick={handleExpandCollapseAll}
									startIcon={isAllExpanded ? <UnfoldLess /> : <UnfoldMore />}>
									{isAllExpanded ? 'Collapse All' : 'Expand All'}
								</ViewModeToggle>
							)}
						</IconsContainer>
					</HeaderTitleContainer>
				</>
			)}
		</Container>
	);
};

export default FormHeader;
