import styled from 'styled-components';
import {
	BodyCell,
	BodyRow,
	HeaderCell,
	HeaderRow,
	TableWrapper,
} from '../styled-components/styledReactTable';
import { Skeleton } from '@mui/material';

export default function SkeletonTable({ rows = 7 }: { rows?: number }) {
	const numRows = new Array(rows).fill({});
	const columns = [
		{
			Header: <HeaderSkeleton variant="rounded" animation="wave" />,
			Cell: <BodySkeleton variant="rectangular" animation="wave" />,
			width: 100,
		},
		{
			Header: <HeaderSkeleton variant="rounded" animation="wave" />,
			Cell: <BodySkeleton variant="rectangular" animation="wave" />,
			width: 200,
		},
		{
			Header: <HeaderSkeleton variant="rounded" animation="wave" />,
			Cell: <BodySkeleton variant="rectangular" animation="wave" />,
			width: 300,
		},
	];

	return (
		<Container>
			<StyledHeaderRow>
				{columns.map((column, idx) => (
					<HeaderCell style={{ width: column.width }} key={idx}>
						{column.Header}
					</HeaderCell>
				))}
			</StyledHeaderRow>

			<TableBody>
				{numRows.map((_, idx) => (
					<StyledBodyRow key={idx}>
						{columns.map((column, idx) => (
							<StyledBodyCell $width={column.width} key={idx}>
								{column.Cell}
							</StyledBodyCell>
						))}
					</StyledBodyRow>
				))}
			</TableBody>
		</Container>
	);
}

const Container = styled(TableWrapper)`
	width: 100%;
	border-radius: 4px;
	border: none;
`;

const StyledHeaderRow = styled(HeaderRow)`
	width: 100%;
	padding-left: 10px;
`;

const TableBody = styled.div`
	display: flex;
	flex-direction: column;
`;

const StyledBodyRow = styled(BodyRow)`
	padding-left: 10px;
	align-items: center;
`;

const StyledBodyCell = styled(BodyCell)<{ $width: number }>`
	width: ${({ $width }) => `${$width}px`};

	.MuiSkeleton-root {
		overflow: hidden;
	}

	&:last-child {
		flex-grow: 1;
	}
`;

const HeaderSkeleton = styled(Skeleton)`
	background-color: #555555;
	height: 40px;
	width: 70%;
`;

const BodySkeleton = styled(Skeleton)`
	background-color: #3d3d3d;
	height: 50%;
	width: 100%;
`;
