import { SetStateAction, useCallback, useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { ProjectData, ProjectStatus } from '../types';
import Layout from '../components/reusable-components/Layout';
import { getModelerProjects } from '../firebase';
import IndividualModelerTable from '../components/screen-components/ModelerPage';
import ModelerProjectsSearchBar from '../components/screen-components/ModelerPage/ModelerProjectsSearchBar';
import styled from 'styled-components';
import { ArrowBack } from '@mui/icons-material';
import { formatProjectTimestamps, projectStatuses } from '../utils';
import PersonIcon from '@mui/icons-material/Person';
import ModelerInfoContainer from '../components/screen-components/ModelerPage/ModelerInfo';
import LoadingScreen from '../components/reusable-components/LoadingScreen';
import ProjectStatusSidebar, {
	SelectedStatus,
} from '../components/reusable-components/ProjectStatusSidebar';

// Only statuses to be displayed on this page
export const modelerProjectStatuses: ProjectStatus[] = [
	'Ready For Modeling',
	'Modeling In Progress',
	'QA/QC',
	'On-Site Capture Complete',
	'Registration',
	'Pending Payment / Delivered',
	'Delivered',
	'Pending Payment',
];

export default function ModelerPage() {
	const { modelerId } = useParams();

	const [loadingModeler, setLoadingModeler] = useState(false);
	const [modelerProjects, setModelerProjects] = useState<ProjectData[] | null>(null);
	const [query, updateQuery] = useState('');
	const [filteredProjects, setFilteredProjects] = useState<ProjectData[] | null>(null);
	const [selectedStatus, setSelectedStatus] =
		useState<SelectedStatus>('Modeler Information');

	const projectStatusCount: Partial<Record<ProjectStatus, number>> =
		filteredProjects?.reduce((obj, proj) => {
			const status = proj.projectStatus;
			return {
				...obj,
				[status]: (obj?.[status] || 0) + 1,
			};
		}, {} as Partial<Record<ProjectStatus, number>>) || {};

	// firebase function to fetch modeler's project data
	useEffect(() => {
		async function fetchModelerProjects() {
			try {
				setLoadingModeler(true);
				const result = await getModelerProjects({ modelerId });
				const projects = result.data as ProjectData[];
				setModelerProjects(formatProjectTimestamps(projects));
			} catch (error) {
				console.error("Error fetching modeler's projects:", error);
			} finally {
				setLoadingModeler(false);
			}
		}

		fetchModelerProjects();
	}, [modelerId, setLoadingModeler]);

	// Filters modeler's projects based on search bar query
	const filterModelerProjects = useCallback(
		(query: any) => {
			if (Array.isArray(modelerProjects)) {
				if (query === undefined || query === null || query === '') {
					setFilteredProjects(modelerProjects);
				} else {
					const filtered: SetStateAction<ProjectData[] | null> = [];
					modelerProjects.forEach(item => {
						if (
							item.address.toLowerCase().includes(query.toLowerCase()) ||
							item.clients?.some(client =>
								client.toLowerCase().includes(query.toLowerCase())
							)
						)
							filtered.push(item);
					});
					setFilteredProjects(filtered);
				}
			}
		},
		[modelerProjects]
	);

	// Handles input change for search bar
	const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const value = e.currentTarget.value;
		updateQuery(value);
	};

	// Clears search bar queries
	const clearQuery = () => {
		updateQuery('');
		setFilteredProjects(modelerProjects);
	};

	useEffect(() => {
		if (modelerProjects?.length) {
			filterModelerProjects(query);
		}
	}, [filterModelerProjects, query, modelerProjects]);

	useEffect(() => {
		document.title = `${modelerId} | Robotic Imaging`;
	}, [modelerId]);

	return (
		<Layout title={modelerId}>
			{!loadingModeler ? (
				<PageContent>
					<ProjectStatusSidebar
						selectedStatus={selectedStatus}
						setSelectedStatus={setSelectedStatus}
						titleElement={
							<BackToModelers to={'/modelers'}>
								<ArrowBack />
								<span>Back to Modelers</span>
							</BackToModelers>
						}
						options={[
							{
								icon: <PersonIcon />,
								text: 'Modeler Information',
								status: 'Modeler Information',
							},
						]}
						statuses={modelerProjectStatuses}
						projectStatusCount={projectStatusCount}
					/>

					<MainWrapper>
						{/* ActionBar holds the search bar + any filters to be implemented  */}
						<ActionBar>
							<ModelerProjectsSearchBar
								query={query}
								handleInputChange={handleInputChange}
								clearQuery={clearQuery}
							/>
						</ActionBar>

						{projectStatuses.includes(selectedStatus as ProjectStatus) ? (
							<IndividualModelerTable
								status={selectedStatus as ProjectStatus}
								filteredProjects={
									filteredProjects?.filter(
										proj => proj.projectStatus === selectedStatus
									) || []
								}
								setModelerProjects={setModelerProjects}
							/>
						) : (
							<ModelerInfoContainer
								modelerId={modelerId as string}
								filteredProjects={filteredProjects}
							/>
						)}
					</MainWrapper>
				</PageContent>
			) : (
				<LoadingScreen message="Loading modeler..." />
			)}
		</Layout>
	);
}

const PageContent = styled.div`
	display: flex;
	overflow: hidden;
	height: 100%;
	width: 100%;

	@media (max-width: 1023px) {
		flex-direction: column;
	}
`;

const BackToModelers = styled(Link)`
	display: flex;
	align-items: center;
	text-decoration: none;
	transition: all 0.2s;

	> svg {
		color: #ffb310;
	}

	> span {
		padding-left: 5px;
		color: white;
		font-size: 20px;
	}

	&:hover {
		background-color: rgba(255, 179, 16, 0.04);
		> span {
			color: #ffb310;
			text-decoration: underline;
		}
	}
`;

const ActionBar = styled.div``;

const MainWrapper = styled.div`
	display: flex;
	flex-direction: column;
	gap: 10px;

	min-width: 0px;
	flex-grow: 1;
	flex-shrink: 1;

	padding: 40px;

	overflow-y: auto;

	@media (max-width: 1023px) {
		padding: 10px;
	}
`;
