import { useEffect } from 'react';
import styled from 'styled-components';
import usePanAndZoom from '../../hooks/usePanAndZoom';

export default function PanAndZoom({
	src,
	style,
	imgProps,
	reset,
}: {
	src?: string;
	style?: React.CSSProperties;
	imgProps?: React.DetailedHTMLProps<
		React.ImgHTMLAttributes<HTMLImageElement>,
		HTMLImageElement
	>;
	reset?: boolean;
}) {
	const {
		containerRef,
		imgContainerRef,
		onMouseDown,
		onWheel,
		translateX,
		translateY,
		scale,
		resetPanZoom,
	} = usePanAndZoom();

	useEffect(() => {
		if (reset) resetPanZoom();
	}, [reset, resetPanZoom]);

	return (
		<Container
			ref={containerRef}
			onMouseDown={onMouseDown}
			onWheel={onWheel}
			style={style}>
			<div
				ref={imgContainerRef}
				style={{
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',

					height: '100%',
					width: '100%',
					overflow: 'hidden',
					transform: `translate(${translateX}px, ${translateY}px) scale(${scale})`,
				}}>
				<StyledImg src={src} alt="" {...(imgProps as any)} />
			</div>
		</Container>
	);
}

const Container = styled.div`
	height: 100%;
	width: 100%;
	overflow: hidden;
`;

const StyledImg = styled.img`
	object-fit: contain;
	max-height: 100%;
	max-width: 100%;

	cursor: grab;
	&:active {
		cursor: grabbing;
	}
`;
