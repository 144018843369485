import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	TextField,
} from '@mui/material';
import { useState } from 'react';
import { addOrgOwner } from '../../../../firebase';

interface AddOrgOwnerDialogProps {
	open: boolean;
	setOpen: React.Dispatch<React.SetStateAction<boolean>>;
	orgId: string;
	onFinishCallback?: (orgId?: string) => void;
}

export default function AddOrgOwnerDialog({
	open,
	setOpen,
	orgId,
	onFinishCallback,
}: AddOrgOwnerDialogProps) {
	const [email, setEmail] = useState('');
	const [addingOrgOwner, setAddingOrgOwner] = useState(false);

	const handleClose = () => {
		setEmail('');
		setOpen(false);
	};

	const onChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
		setEmail(e.target.value);
	};

	const handleSubmit = async () => {
		if (!email || !orgId) return;
		try {
			setAddingOrgOwner(true);
			await addOrgOwner({ email, orgId });
		} catch (err) {
			console.error(err);
		} finally {
			handleClose();
			setAddingOrgOwner(false);
			if (onFinishCallback) {
				onFinishCallback(orgId);
			}
		}
	};

	return (
		<Dialog
			open={open}
			onClose={() => {
				if (!addingOrgOwner) handleClose();
			}}>
			<DialogTitle>Add New Organization Owner</DialogTitle>

			<DialogContent>
				<TextField
					variant="standard"
					label="Email"
					value={email}
					onChange={onChange}
					disabled={addingOrgOwner}
				/>
			</DialogContent>

			<DialogActions>
				<Button onClick={handleClose} disabled={addingOrgOwner}>
					Cancel
				</Button>
				{email ? (
					<Button onClick={handleSubmit} disabled={addingOrgOwner}>
						Add user
					</Button>
				) : null}
			</DialogActions>
		</Dialog>
	);
}
