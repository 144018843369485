import { LeadStatus } from './types';

export const leadStatuses: LeadStatus[] = ['New', 'Follow Up', 'Client', 'Archive'];

// Status Colors for Leads Table
export const leadStatusColor = (status: LeadStatus) => {
	switch (status) {
		case 'New':
			return '#E6E6E6';
		case 'Follow Up':
			return '#ff9b29';
		case 'Client':
			return '#FFB310';
		case 'Archive':
			return '#0D99FF';
		default:
			return 'white';
	}
};

// Text color for Leads Table – Account manager and Status columns only
export const leadStatusTextColor = (status: LeadStatus) => {
	switch (status) {
		case 'Archive':
			return 'white';
		default:
			return 'black';
	}
};
