import { useState, useEffect, useContext } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { createUser, getPendingProjectShare } from '../firebase';
import splashVideo from '../assets/splash.mp4';
import styled from 'styled-components';
import { Formik } from 'formik';
import * as yup from 'yup';
import AppText from '../components/reusable-components/AppText';
import FormikTextField from '../components/reusable-components/FormikTextField';

import { Button } from '@mui/material';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { AuthContext } from '../context/AuthProvider';
import Spinner from '../components/reusable-components/Spinner';
import theme from '../styles/theme';
import { TermsAndPrivacy } from '../components/screen-components/Login/TermsAndPrivacy';
import { PendingShare, ResolvedShare } from '../types';
import { FooterYellowLink } from './auth/style';
import { Link } from 'react-router-dom';

const validationsForm = {
	fullName: yup.string().required('Full name required'),
	password: yup
		.string()
		.min(6, 'Password must contain at least 6 characters')
		.required('Password required'),
	passwordRepeat: yup
		.string()
		.oneOf([yup.ref('password')], 'Passwords must match')
		.required('Confirm your password'),
};

export default function PendingProjectShare() {
	const { handleOnLogin, handleOnLogout } = useContext(AuthContext);
	const navigate = useNavigate();

	const [shareData, setShareData] = useState<PendingShare | ResolvedShare | null>(null);
	const [loadingError, setLoadingError] = useState('');
	const [timeLeft, setTimeLeft] = useState(30);

	// Search params for pending project share id
	const [searchParams] = useSearchParams();
	const pendingShareDocId = searchParams.get('id');

	// Gets pending project share document based on search params on load
	useEffect(() => {
		const getShareData = async () => {
			try {
				const res = await getPendingProjectShare({ id: pendingShareDocId });
				setShareData(res.data as PendingShare);
			} catch (err) {
				console.error(err);
				setLoadingError('Could not load project invite...');
			}
		};
		getShareData();
	}, [pendingShareDocId]);

	useEffect(() => {
		if (timeLeft > 0 && shareData && 'resolvedAt' in shareData) {
			setTimeout(() => {
				setTimeLeft(prev => prev - 1);
			}, 1000);
		}
	}, [shareData, timeLeft]);

	useEffect(() => {
		if (timeLeft === 0) {
			navigate('/login');
		}
	}, [navigate, timeLeft]);

	// Set page title.
	useEffect(() => {
		document.title = 'Sign Up | Robotic Imaging';
	}, []);

	return (
		<Container>
			<BackgroundVideo autoPlay loop muted playsInline>
				<source src={splashVideo} type="video/mp4" />
			</BackgroundVideo>

			{!!shareData ? (
				!('resolvedAt' in shareData) ? (
					<>
						<RealityBackground style={{ width: 685 }}>
							<div className="reality">Reality Capture At Scale</div>
						</RealityBackground>

						<AuthContainer>
							<Formik
								validateOnBlur
								validationSchema={yup.object().shape(validationsForm)}
								initialValues={{
									email: shareData.email,
									fullName: '',
									password: '',
									passwordRepeat: '',
								}}
								onSubmit={async (values, { setSubmitting }) => {
									try {
										// Call Firebase Function to create new user with pending projects shared
										await createUser({
											...values,
											pendingShareId: shareData.id,
										});
										// Logout any user currently logged in
										await handleOnLogout();
										// Login to platform
										await handleOnLogin({
											email: values.email,
											password: values.password,
										});
										// Goto projects page
										navigate('/projects');
									} catch (err) {
										console.error(err);
										setShareData(null);
										setLoadingError(
											'Could not create account. Please contact the Robotic Imaging team.'
										);
									}
									setSubmitting(false);
								}}>
								{({ errors, touched, handleSubmit, isSubmitting }) => (
									<FormContainer onSubmit={handleSubmit}>
										<AppText variant={'h5'} text="Finish Signing Up" />
										<FormikTextField
											style={{ marginTop: '10px' }}
											label="Email Address"
											name="email"
											type="email"
											fullWidth
											disabled
										/>
										<FormikTextField
											label="Full Name"
											name="fullName"
											type="text"
											helperText={touched.fullName ? errors.fullName : undefined}
											error={touched.fullName && Boolean(errors.fullName)}
											fullWidth
										/>
										<FormikTextField
											label="Password"
											name="password"
											type="password"
											helperText={touched.password ? errors.password : undefined}
											error={touched.password && Boolean(errors.password)}
											fullWidth
										/>
										<FormikTextField
											label="Retype Password"
											name="passwordRepeat"
											type="password"
											helperText={
												touched.passwordRepeat ? errors.passwordRepeat : undefined
											}
											error={touched.passwordRepeat && Boolean(errors.passwordRepeat)}
											fullWidth
										/>
										{!isSubmitting ? (
											<Button type="submit" color="primary" variant="contained">
												Login
											</Button>
										) : (
											<Spinner />
										)}
									</FormContainer>
								)}
							</Formik>
						</AuthContainer>
						<TermsAndPrivacy style={{ width: 685 }} />
					</>
				) : (
					<MessageContainer>
						<span>This invitation has already been used to create an account.</span>
						<br />
						<span>
							Please login with your email and the password you entered during account
							creation.
						</span>
						<span>
							If you have forgotten your password, you can reset it on the{' '}
							<Link to="/login">
								<FooterYellowLink>login screen</FooterYellowLink>
							</Link>
							.
						</span>
						<br />
						<span>{`Redirecting to login screen in ${timeLeft} seconds...`}</span>
					</MessageContainer>
				)
			) : !loadingError ? (
				<>
					<Spinner size={200} />
					<span>Loading project invite...</span>
				</>
			) : (
				<>
					<WarningAmberIcon sx={{ height: '100px', width: '100px' }} />
					<span>{loadingError}</span>
				</>
			)}
		</Container>
	);
}

const Container = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	width: 100%;
	height: 100%;
	color: #ffb310;
	font-size: 1rem;
`;

const BackgroundVideo = styled.video`
	position: fixed;
	object-fit: cover;
	z-index: -1;

	width: 100%;
	height: 100%;
`;

const RealityBackground = styled.div`
	background-color: ${theme.palette.secondary.main};
	padding: 15px 25px;
	border-radius: 10px;
	text-align: center;
`;

const AuthContainer = styled.div`
	display: flex;
	place-content: center;
	background-color: #121212;
	width: 685px;
	border-radius: 10px;
	margin: 25px 0;
`;

const FormContainer = styled.form`
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 10px;

	max-width: 300px;
	padding: 30px;
	border-radius: 20px;
	font-size: 14px;
`;

const MessageContainer = styled.div`
	display: flex;
	flex-direction: column;
	padding: 20px;
	color: white;
	background-color: ${theme.palette.secondary.main};
`;
