import { GoogleAuthProvider } from 'firebase/auth';
import { AuthContext } from '../../context/AuthProvider';
import { useContext } from 'react';

const provider = new GoogleAuthProvider();

const useGoogle = () => {
	const { handleSocialLogin } = useContext(AuthContext);

	const login = async () => {
		await handleSocialLogin(provider);
	};

	return { login };
};

export default useGoogle;
