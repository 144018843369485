import { useContext, useEffect, useRef, useState } from 'react';
import { FormNode } from '../../../../../utils/types';
import { FormV2Context } from '../../../../../context/FormV2Context.types';
import { NodeCenteredHeader } from '../FormFillV2.styles';

export default function NodeTitle({ node }: { node: FormNode }) {
	const [inputValue, setInputValue] = useState(node.displayTitle);
	const inputRef = useRef<HTMLInputElement>(null);
	const { updateDisplayTitle } = useContext(FormV2Context);

	const handleInputChange = async (
		e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
	) => {
		const newValue = e.target.value;
		setInputValue(newValue);
		console.log(`updating value for node id: ${node.id}`);
		await updateDisplayTitle(node.id, newValue);
	};

	useEffect(() => {
		setInputValue(node.displayTitle);
	}, [node]);

	const handleClick = () => {
		if (inputRef.current) {
			inputRef.current.focus();
		}
	};

	return (
		<NodeCenteredHeader
			defaultValue={inputValue}
			onChange={handleInputChange}
			onClick={handleClick}
			ref={inputRef}
			fullWidth
		/>
	);
}
