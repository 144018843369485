import { useContext, useEffect, useState } from 'react';
import { FormNode, NodeType } from '../../../utils/types';
import { FormV2Context } from '../../../context/FormV2Context.types';
import { IconButton } from '@mui/material';
import { AddCircle } from '@mui/icons-material';
import { NewItemDialog } from '../../Dialogs/NewItemDialog/NewItemDialog';
import { copyAndPasteNode } from '../../../../../../firebase';
import { SnackContext } from '../../../../../../context/SnackProvider';

type NodeAddChildButtonProps = {
	node: FormNode;
	excludeTypes?: NodeType[];
};

export default function NodeAddChildButton({
	node,
	excludeTypes,
}: NodeAddChildButtonProps) {
	const { addNewNode, copiedNodeData, formId } = useContext(FormV2Context);
	const [newNodeId, setNewNodeId] = useState<string | null>(null);
	const { setSnackbarProps } = useContext(SnackContext);

	const [showDialog, setShowDialog] = useState(false);

	const handleClick = () => {
		setShowDialog(true);
	};
	const handleClose = () => {
		setShowDialog(false);
	};

	const handleAddItem = async (
		displayTitle: string,
		type: NodeType,
		options?: string[]
	) => {
		const id = await addNewNode(displayTitle, type, options, node.id);
		setNewNodeId(id ?? null);
		handleClose();
	};

	const handlePasteItem = async () => {
		if (!copiedNodeData) {
			console.error('No node to paste!');
			return;
		}
		try {
			const result = await copyAndPasteNode({
				operation: 'paste',
				itemId: copiedNodeData.id,
				targetParentId: node.id,
				formId: formId,
			});
			setSnackbarProps({
				open: true,
				message: `Success! Item pasted to ${node.displayTitle}`,
				severity: 'success',
			});
			console.log('Node pasted successfully', result);
			handleClose();
		} catch (error) {
			console.error('error pasting node', error);
			setSnackbarProps({
				open: true,
				message: `Error, Item could not be pasted in ${node.displayTitle}. Please try again.`,
				severity: 'error',
			});
		}
	};

	useEffect(() => {
		if (newNodeId) {
			const element = document.getElementById(newNodeId);
			element?.scrollIntoView();
		}
	}, [newNodeId]);

	return node.type === 'node' ? (
		<>
			<IconButton
				onClick={handleClick}
				title={`Create new child item for ${node.displayTitle}`}>
				<AddCircle />
			</IconButton>

			<NewItemDialog
				isOpen={showDialog}
				onClose={handleClose}
				onAddItem={handleAddItem}
				enableTypeSelection
				excludeTypes={excludeTypes}
				showPasteOption={true}
				onPasteItem={() => handlePasteItem()}
			/>
		</>
	) : null;
}
