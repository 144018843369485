import { Box, styled } from '@mui/system';
import React from 'react';

export const LogoTeam: React.FC<{ src: string; alt: string }> = ({ src, alt }) => {
	return (
		<StyledBox>
			<img src={src} alt={alt} />
		</StyledBox>
	);
};

const StyledBox = styled(Box)`
	height: 44px;
	width: auto;
	padding: 0 10px;

	img {
		height: 100%;
		width: auto;
	}
`;
