import type { FlattenedItem } from './types';
import useFormNodes from './useFormNodes';
import useDragging from './useDragging';
import { CellMeasurerCache } from 'react-virtualized';
import { addOrderToItems } from '../../../../../utils/addFormOrder';
import FormNodeWrapper from '../../../../form-components/FormNodeWrapper';
import {
	DateField,
	DateTimeField,
	InputField,
	MultilineField,
	PhotosField,
	Section,
	SelectField,
	SwitchField,
} from '../../../../form-components';
import { FormPlaceholder } from '../../../../form-components/FormPlaceholder';
import styled from 'styled-components';
import { useContext } from 'react';
import { MenuReorderContext } from '../../Form';
import useFormTemplateNodes from './useFormTemplateNodes';
import { TemplateContext } from '../../../../../context/TemplateProvider';
import { FormV2Context } from '../../../ProjectUtilityFormV2/context/FormV2Context.types';
import ViewModeNodeWrapper from '../../../../form-components/ViewModeNodeWrapper/ViewModeNodeWrapper';

interface FormWrapperProps {
	collapsible?: boolean;
	indentationWidth?: number;
	indicator?: boolean;
	removable?: boolean;
	formId: string;
	projectId: string;
}

const rowHeights = new CellMeasurerCache({
	defaultHeight: 80,
	fixedWidth: true,
});

export default function FormWrapper({
	indicator = false,
	indentationWidth = 50,
	formId,
	projectId,
}: FormWrapperProps) {
	const { expandedList, toggleItem } = useContext(MenuReorderContext);
	const { isTemplate } = useContext(TemplateContext);
	const {
		allFormNodes,
		flattenedFooterPlaceholder,
		flattenedHeaderPlaceholder,
		saveNodes,
	} = useFormNodes(formId, projectId);
	const { allFormTemplateNodes, saveTemplateNodes } = useFormTemplateNodes(formId ?? '');
	const { isViewMode } = useContext(FormV2Context);
	const nodes = isTemplate ? allFormTemplateNodes : allFormNodes;
	const safeFunc = isTemplate ? saveTemplateNodes : saveNodes;

	const { flattenedItems } = useDragging({
		allFormNodes: nodes,
		flattenedFooterPlaceholder,
		flattenedHeaderPlaceholder,
		indentationWidth,
		indicator,
		rowHeights,
		saveNodes: safeFunc,
	});

	const orderedItems = addOrderToItems(flattenedItems, true) as FlattenedItem[];

	const itemsByParentId: Record<string, FlattenedItem[]> = {};
	orderedItems.forEach(item => {
		const parentId = item.parentId ?? 'root';
		itemsByParentId[parentId] = [...(itemsByParentId[parentId] || []), item];
	});

	const renderInput = (item: FlattenedItem) => (
		<>
			{item.node.type === 'input' ? (
				<InputField node={item.node} key={item.node.id} disabled={isTemplate} />
			) : item.node.type === 'area' ? (
				<MultilineField node={item.node} key={item.node.id} disabled={isTemplate} />
			) : item.node.type === 'switch' ? (
				<SwitchField itemNode={item.node} key={item.node.id} />
			) : item.node.type === 'photos' ? (
				<PhotosField
					orderedItems={orderedItems}
					itemNode={item.node}
					key={item.node.id}
				/>
			) : item.node.type === 'date' ? (
				<DateField node={item.node} key={item.node.id} />
			) : item.node.type === 'datetime' ? (
				<DateTimeField node={item.node} key={item.node.id} />
			) : item.node.type === 'select' ? (
				<SelectField node={item.node} key={item.node.id} />
			) : item.node.type === 'placeholder' ? (
				<FormPlaceholder node={item.node} key={item.node.id} />
			) : (
				<Section
					node={item.node}
					key={item.node.id}
					level={item.depth + 1}
					itemsByParentId={itemsByParentId}
					expandedList={expandedList}
					toggleCollapse={toggleItem}
					orderedItems={orderedItems}
				/>
			)}
		</>
	);

	return (
		<Container isviewmode={String(isViewMode)}>
			{isViewMode
				? itemsByParentId['root']?.map(item => {
						return (
							<ViewModeNodeWrapper node={item.node} key={item.node.id}>
								{renderInput(item)}
							</ViewModeNodeWrapper>
						);
				  })
				: itemsByParentId['root']?.map(item => {
						return (
							<FormNodeWrapper node={item.node} key={item.node.id}>
								{renderInput(item)}
							</FormNodeWrapper>
						);
				  })}
		</Container>
	);
}

const Container = styled.div<{ isviewmode?: string }>`
	display: flex;
	flex-direction: column;
	gap: 0;
`;
