import { useContext, FC } from 'react';
import { FormV2Context } from '../../context/FormV2Context.types';
import { CaptureFormHeaderContainer } from './FormV2Header.styles';
import Loading from '../Loading/Loading';
import Lock from '@mui/icons-material/Lock';
import ToolDropdown from './components/ToolsDropdown/ToolDropdown';
import FormName from './components/FormName/FormName';
import styled from 'styled-components';
import { useLocation, useNavigate } from 'react-router-dom';
import { IconButton } from '@mui/material';
import { Loop } from '@mui/icons-material';

/**
 * @returns Form Header
 *
 *	Contains the following components:
 * • FormName
 * • ToolsDropdown
 */
const FormV2Header: FC<React.PropsWithChildren<unknown>> = () => {
	const { isUploading, utilityForm, isSubmitting } = useContext(FormV2Context);
	const navigate = useNavigate();
	const location = useLocation();

	const switchForm = () => {
		navigate(location.pathname.replace('capture-form-v2', 'capture-form-v3'));
	};

	return (
		<CaptureFormHeaderContainer>
			<FormName />
			{isUploading && <Loading size={20} />}
			{utilityForm?.locked && <Lock />}
			{isSubmitting && <Loading loadingText={'Submitting form...'} />}
			<FloatRight>
				<IconButton onClick={switchForm}>
					<Loop color="primary" />
				</IconButton>{' '}
				<ToolDropdown />
			</FloatRight>
		</CaptureFormHeaderContainer>
	);
};

export default FormV2Header;

const FloatRight = styled.div`
	position: absolute;
	right: 10px;
	display: flex;
	top: 50%;
	transform: translateY(-50%);
`;
