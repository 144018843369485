import { FieldLabel } from '../../../../screens/ProjectInfo';
import React from 'react';
import TextInputField from './TextInputField';
import styled from 'styled-components';

// Props for Admin Notes
interface OrgNotesProps {
	isActive: boolean;
	handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

// Notes Info
export default function OrgNotes({ isActive, handleChange }: OrgNotesProps) {
	return (
		<Row>
			<FieldLabel label={'Org Notes'} />
			<TextInputField
				field={'orgNotes'}
				onBlur={handleChange}
				isActive={isActive}
				multiline={true}
				rows={4}
			/>
		</Row>
	);
}

const Row = styled.div`
	display: flex;
	align-items: center;
	gap: 5px;
	width: 100%;
	padding: 5px;
	font-size: 1.1rem;
	margin-bottom: 10px;

	&:last-child {
		min-width: 300px;
	}
	@media (max-width: 768px) {
		flex-wrap: wrap;
		font-size: 0.9rem;
	}
`;
