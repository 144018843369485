import { LinearProgress } from '@mui/material';

export default function ProgressBar({
	progress,
	style = {},
}: {
	progress: number | null;
	style?: any;
}) {
	if (progress === null) return null;
	else {
		return (
			<div style={{ backgroundColor: 'white', padding: '10px', ...style }}>
				{progress >= 0 ? (
					<div style={{ display: 'flex', alignItems: 'center' }}>
						<div style={{ marginRight: '10px' }}>
							<h3 style={{ margin: '0px', marginBottom: '5px' }}>
								Upload In Progress...
							</h3>
							<LinearProgress value={progress} variant="determinate" />
						</div>
						<h3>{`${Math.round(progress)}%`}</h3>
					</div>
				) : null}
			</div>
		);
	}
}
