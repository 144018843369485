import { useEffect, useState, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { getProjectAddress, getUtilityForm } from '../firebase';
import Layout from '../components/reusable-components/Layout';
import LoadingScreen from '../components/reusable-components/LoadingScreen';

import { UtilityForm } from '../components/screen-components/ProjectUtilityForm/types';
import FormGallery from '../components/screen-components/CaptureFormGallery';
import { Link } from 'react-router-dom';
import { AuthContext } from '../context/AuthProvider';
import { YellowIconButton } from '../components/styled-components/buttons';
import { LayoutLink } from '../components/styled-components/links';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';

export default function CaptureFormGallery() {
	const { formId } = useParams();
	const { user } = useContext(AuthContext);

	const [loadingMessage, setLoadingMessage] = useState('');
	const [form, setForm] = useState<UtilityForm | null>(null);
	const [address, setAddress] = useState<string | null>(null);

	useEffect(() => {
		const fetchSetForm = async () => {
			setLoadingMessage('Fetching form data...');

			try {
				const res = await getUtilityForm(formId);
				const formData = res.data as UtilityForm;
				if (formData) {
					setForm(formData);

					const res2 = await getProjectAddress(formData.projectId);
					setAddress(res2.data as string);
				}
			} catch (error) {
				console.error(error);
			}

			setLoadingMessage('');
		};
		fetchSetForm();
	}, [formId]);
	return (
		<Layout
			LeftNavComponent={
				<>
					{address ? (
						<LayoutLink
							to={`/projects/${form?.projectId}`}
							title="Navigate to project details page">
							<h1>{address}</h1>
						</LayoutLink>
					) : null}

					{user?.isAdmin && form ? (
						<Link
							to={`/projects/${form?.projectId}/capture-form/${form?.id}`}
							title="Navigate to this gallery's form"
							tabIndex={-1}>
							<YellowIconButton size="large">
								<PictureAsPdfIcon />
							</YellowIconButton>
						</Link>
					) : null}
				</>
			}>
			{loadingMessage || !form ? (
				<LoadingScreen message="Loading form data..." />
			) : (
				<FormGallery form={form} />
			)}
		</Layout>
	);
}
