import { createContext, useState, useEffect, useContext, useRef } from 'react';
import { ProjectData } from '../types';
import { queryProjects } from '../firebase';
import { getGeocode } from 'use-places-autocomplete';
import { SnackContext } from './SnackProvider';
import { ProjectContext } from './ProjectProvider';

type MapContextType = {
	mapProjects: ProjectData[] | null;
	fetchingMapProjects: boolean;
	googleMap: React.MutableRefObject<google.maps.Map | null>;
	query: string;
	setQuery: React.Dispatch<React.SetStateAction<string>>;
	filter: MapFilter;
	setFilter: React.Dispatch<React.SetStateAction<MapFilter>>;
};

export const MapContext = createContext({} as MapContextType);

export const MapProvider = ({ children }: { children?: React.ReactNode }) => {
	const { setSnackbarProps } = useContext(SnackContext);
	const { contractors } = useContext(ProjectContext);

	const rawProjects = useRef<ProjectData[]>([]);
	const [mapProjects, setMapProjects] = useState<ProjectData[] | null>(null);
	const [fetchingMapProjects, setFetchingMapProjects] = useState(false);
	const [query, setQuery] = useState('');
	const [filter, setFilter] = useState(mapFilters[0].value as MapFilter);

	const googleMap = useRef<null | google.maps.Map>(null);

	// Fetch projects for map
	useEffect(() => {
		if (!mapProjects) {
			setFetchingMapProjects(true);
			queryProjects({
				searchMode: 'general',
				query: query,
				limit: 6000,
			}).then(res => {
				const projects = (res.data as any).projects as ProjectData[];
				rawProjects.current = projects;
				setMapProjects(projects);
				setFetchingMapProjects(false);
			});
		}
	}, [mapProjects, query]);

	// Filter projects when filter is set to 'client'.
	useEffect(() => {
		if (filter === 'client' && !fetchingMapProjects) {
			setMapProjects(
				rawProjects.current.filter(proj =>
					proj?.clients?.some(client =>
						client.toLowerCase().includes(query.toLowerCase())
					)
				)
			);
		}
	}, [filter, fetchingMapProjects, query]);

	// Pan map when filter is set to 'address' or contractor'.
	useEffect(() => {
		if (filter !== 'client' && !fetchingMapProjects) {
			if (filter === 'address' && query.trim()) {
				// Pan map to address.
				getGeocode({ address: query })
					.then(res => {
						googleMap.current?.fitBounds(res[0].geometry.viewport);
						setSnackbarProps({ open: false });
					})
					.catch(() => {});
			} else if (query.trim()) {
				// Pan map to contractor.
				const filteredContractors = contractors.filter(contractor =>
					contractor.Name.toLowerCase().includes(query.toLowerCase())
				);
				if (filteredContractors.length) {
					const pos = {
						lat: filteredContractors[0].lat,
						lng: filteredContractors[0].lng,
					};
					if (pos.lat && pos.lng) {
						googleMap.current?.panTo(pos);
					} else {
						googleMap.current?.panTo({ lat: 0, lng: 0 });
					}
					googleMap.current?.setZoom(18);
					setSnackbarProps({ open: false });
				} else {
					setSnackbarProps({
						open: true,
						message: 'No contractors found...',
						severity: 'error',
					});
				}
			}
		}
	}, [filter, fetchingMapProjects, query, setSnackbarProps, contractors]);

	return (
		<MapContext.Provider
			value={{
				mapProjects,
				fetchingMapProjects,

				googleMap,

				query,
				setQuery,
				filter,
				setFilter,
			}}>
			{children}
		</MapContext.Provider>
	);
};

type MapFilter = 'address' | 'client' | 'contractor';

export const mapFilters = [
	{ value: 'address', name: 'Address' },
	{ value: 'client', name: 'Client' },
	{ value: 'contractor', name: 'Contractor' },
];
