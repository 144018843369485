import { getFileExtension } from '../../../../utils/utils';
import { db, storage } from '../../../../../../../firebase';
import {
	deleteObject,
	getDownloadURL,
	ref,
	uploadBytesResumable,
} from 'firebase/storage';
import { doc, getDoc, updateDoc } from 'firebase/firestore';

type OnSuccessCallback = (url: string) => void;
type onProgressCallback = (progress: number) => void;

const UploadCoverpageContent = async (
	file: Blob | Uint8Array | ArrayBuffer,
	name: string,
	bucketType: string,
	formId: string,
	projectId: string,
	storageFilepath: string,
	onSuccess: OnSuccessCallback,
	onProgress: onProgressCallback
) => {
	const formRef = doc(db, `utility_forms_v2`, formId);
	const formSnapshot = await getDoc(formRef);
	if (formSnapshot.exists()) {
		const existingPhotoURL = formSnapshot.data()[bucketType];
		if (existingPhotoURL) {
			const existingPhotoRef = ref(storage, existingPhotoURL);
			await deleteObject(existingPhotoRef);
		}
	}

	const extension = getFileExtension(name);
	const filePath = `${storageFilepath}${extension}`;
	const storageRef = ref(storage, filePath);
	const metadata = {
		customMetadata: {
			originalFilename: name,
			projectId: projectId,
		},
	};
	const uploadTask = uploadBytesResumable(storageRef, file, metadata);
	uploadTask.on(
		'state_changed',
		snapshot => {
			const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
			onProgress(progress);
		},
		error => {
			console.log(error);
		},
		() => {
			getDownloadURL(uploadTask.snapshot.ref).then(downloadURL => {
				const formRef = doc(db, 'utility_forms_v2', formId);
				const updateObject = { [bucketType]: downloadURL };
				updateDoc(formRef, updateObject)
					.then(() => {
						console.log(`${bucketType} URL updated in Firestore`);
						onSuccess(downloadURL);
					})
					.catch(error => {
						console.error('Error updating document: ', error);
					});
			});
		}
	);
};

export default UploadCoverpageContent;
