import { Fragment, useContext, useState } from 'react';
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Typography,
	Checkbox,
	Link as MUILink,
	Box,
} from '@mui/material';
import { Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import { SnackContext } from '../../../context/SnackProvider';
import { ProjectDataWithForms } from '../../../types';
import theme from '../../../styles/theme';
import { Link } from 'react-router-dom';
import { ExpandMoreIcon } from '../../../screens/ProjectsList/components/ClientProjects/styles';
import { Center } from '../ProjectUtilityFormV2/components/Dialogs/UnarchiveDialog/UnarchiveDialog.styles';
import { StyledDialogLogo } from '../../form-components/Dialog/Dialog.styles';
import logoSrc from '../../../assets/circleLogo.png';
import ProjectsSearchbar from './ProjectsSearchBar';
import { YellowButton } from '../../styled-components/styledReactTable';
import { UserHomeContext } from '../../../context/UserHomeProvider';

type Props = {
	createReport: () => Promise<void>;
	open: boolean;
	reportName: string;
	projects: ProjectDataWithForms[] | undefined;
	setOpen: React.Dispatch<React.SetStateAction<boolean>>;
	toggleForm: (formId: string, value: boolean) => Promise<void>;
	totalSelectedForms: number;
};

export default function CreateReportSecondDialog({
	open,
	createReport,
	reportName,
	projects,
	setOpen,
	toggleForm,
	totalSelectedForms,
}: Props) {
	const { refreshReports } = useContext(UserHomeContext);
	const { setSnackbarProps } = useContext(SnackContext);
	const [searchQuery, setSearchQuery] = useState('');
	const filteredProjects = projects?.filter(
		project =>
			project.forms.some(form =>
				form.formName.toLowerCase().includes(searchQuery.toLowerCase())
			) || project.address.toLowerCase().includes(searchQuery.toLowerCase())
	);

	const selectAll = (value: boolean) => {
		filteredProjects?.forEach(project =>
			project.forms.map(async form => {
				if (['completed', 'draft'].includes(form.status)) {
					await toggleForm(form.id, value);
				}
			})
		);
	};

	const selectCompleted = () => {
		filteredProjects?.forEach(project =>
			project.forms.map(async form => {
				if (form.status === 'completed') {
					await toggleForm(form.id, true);
				}
			})
		);
	};

	const selectDraft = () => {
		filteredProjects?.forEach(project =>
			project.forms.map(async form => {
				if (form.status === 'draft') {
					await toggleForm(form.id, true);
				}
			})
		);
	};

	const selectChanged = () => {
		filteredProjects?.forEach(async project => {
			const forms = [...project.forms];
			const form = forms.sort(
				(a, b) => (b.lastUpdated?.toMillis() || 0) - (a.lastUpdated?.toMillis() || 0)
			)[0];

			if (form && form.id) await toggleForm(form.id, true);
		});
	};

	const handleCreateReport = async () => {
		setOpen(false);
		await createReport();
		refreshReports();
		setSnackbarProps({
			open: true,
			message: 'Analytics creation started.',
			severity: 'success',
		});
	};

	return (
		<Dialog
			open={open}
			onClose={() => setOpen(false)}
			maxWidth={false}
			PaperProps={{
				sx: {
					minWidth: '640px',
					minHeight: '400px',
				},
			}}>
			<DialogTitle
				sx={{
					background: theme.palette.grey[800],
					color: theme.palette.newText.primary,
				}}>
				<StyledDialogLogo>
					<img src={logoSrc} alt="" style={{ height: 24 }} />
				</StyledDialogLogo>{' '}
				<Box sx={{ marginRight: 'auto' }}>
					{reportName}{' '}
					<span style={{ color: theme.palette.primary.main, fontSize: '16px' }}>
						({totalSelectedForms} form{totalSelectedForms > 1 ? 's' : ''})
					</span>
				</Box>
				<ProjectsSearchbar
					query={searchQuery}
					handleInputChange={e => setSearchQuery(e.target.value)}
					clearQuery={() => setSearchQuery('')}
				/>
			</DialogTitle>
			<DialogContent sx={{ background: theme.palette.secondary.main }}>
				<Center>
					<Typography sx={{ color: theme.palette.common.white }}>
						Select which forms you would like to generate an analytics for.
					</Typography>
				</Center>
				<Center style={{ gap: 16 }}>
					<YellowButton onClick={() => selectAll(true)}>All</YellowButton>
					<YellowButton onClick={selectChanged}>Last changed</YellowButton>
					<YellowButton onClick={selectCompleted}>Completed</YellowButton>
					<YellowButton onClick={selectDraft}>Draft</YellowButton>
					<YellowButton onClick={() => selectAll(false)}>None</YellowButton>
				</Center>
				{filteredProjects?.map((project, index) => (
					<Fragment key={project.id}>
						{project.forms.length > 0 && (
							<Accordion
								key={project.id}
								sx={{
									borderRadius:
										index === 0
											? '5px 5px 0 0'
											: index === project.forms.length - 1
											? '0 0 5px 5px'
											: '0',
									background: theme.palette.grey[500],
									'&:before': {
										display: 'none',
									},
								}}>
								<AccordionSummary
									expandIcon={<ExpandMoreIcon />}
									aria-controls={`panel${index}-content`}
									id={`panel${index}-header`}
									sx={{
										display: 'flex',
										alignItems: 'center',
									}}>
									<Typography
										color={
											project.forms.some(form => form.report)
												? theme.palette.primary.main
												: theme.palette.newText.primary
										}
										style={{ transition: 'color 0.3s' }}
										paddingRight={4}>
										({project.forms.filter(form => form.report).length}){' '}
										{project.address.slice(0, 50)}
										{project.address.length > 50 && '...'}
									</Typography>
									<Typography
										variant="subtitle2"
										sx={{
											color: theme.palette.grey[800],
											fontSize: '12px',
											fontWeight: '700',
											alignSelf: 'center',
										}}>
										{project.projectStatus}
									</Typography>
								</AccordionSummary>

								<AccordionDetails>
									{project.forms.map((form, index) => (
										<div
											key={form.id}
											style={{
												marginBottom: '8px',
												display: 'flex',
												alignItems: 'center',
												gap: '10px',
											}}>
											<span>
												<Checkbox
													onClick={e =>
														toggleForm(form.id, (e.target as HTMLInputElement).checked)
													}
													checked={
														form.report && ['completed', 'draft'].includes(form.status)
													}
													disabled={!['completed', 'draft'].includes(form.status)}
													sx={{
														color:
															form.status === 'draft'
																? 'gray'
																: form.status === 'completed'
																? theme.palette.newText.primary
																: 'default',
														'&.Mui-checked': {
															color:
																form.status === 'completed'
																	? theme.palette.primary.main
																	: 'default',
														},
													}}
												/>
											</span>

											<MUILink
												component={Link}
												to={`/projects/${project.id}/capture-form-v3/${form.id}`}
												target="_blank"
												sx={{
													color: theme.palette.newText.primary,
													fontFamily: 'Epilogue',
													fontWeight: '500',
													textDecoration: 'none',
													'&:hover': {
														textDecoration: 'underline',
														color: theme.palette.primary.main,
													},
												}}>
												{form.formName}
											</MUILink>

											<Typography
												variant="body2"
												sx={{
													color: theme.palette.grey[800],
													fontSize: '12px',
													fontWeight: '400',
													marginLeft: '10px',
												}}>
												{form.status}
											</Typography>
										</div>
									))}
								</AccordionDetails>
							</Accordion>
						)}
					</Fragment>
				))}
			</DialogContent>
			<DialogActions sx={{ background: theme.palette.grey[800] }}>
				<Button onClick={() => setOpen(false)}>Cancel</Button>
				<Button onClick={handleCreateReport} autoFocus>
					Create Report
				</Button>
			</DialogActions>
		</Dialog>
	);
}
