import styled from 'styled-components';

interface RootNodeChipProps {
	isExpanded: boolean;
	isOverflowing?: boolean;
}

export const RootNodeChip = styled.div<RootNodeChipProps>`
	display: flex;
	flex-direction: column;
	background-color: #fefefe;
	box-shadow: 0px 5px 5px 2px rgba(0, 0, 0, 0.25);
	align-self: center;
	width: 90%;
	border-radius: 30px;
	justify-content: center;
	align-items: center;
	text-transform: none;
	gap: 10px;
	transition: transform 0.3s ease, opacity 0.3s ease;
	padding: 10px;
	cursor: pointer;
	pointer-events: auto;
	&:hover {
		opacity: ${props => (props.isExpanded ? '1' : '0.8')};
	}
`;

export const TitleContainer = styled.div`
	display: flex;
	padding: 5px;
	flex-wrap: wrap;
`;

export const ChipTitle = styled.p`
	font-family: 'Epilogue';
	color: black;
	font-weight: 400;
	font-size: 1.2rem;
	white-space: normal;
	flex: 1 1 auto;
	width: 90%;
	text-align: center;
	margin: 5px;
	overflow-wrap: anywhere;
	hyphens: auto;

	@media screen and (max-width: 1700px) {
		font-size: 1rem;
	}
`;
