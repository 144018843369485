import { useContext } from 'react';
import { LoadingContext } from '../../context/LoadingProvider';
import styled from 'styled-components';
import Spinner from './Spinner';

export default function LoadingLayer({ showOverlay }: { showOverlay: boolean }) {
	const { loadingStatus } = useContext(LoadingContext);

	return showOverlay ? (
		<LoadingPositionClass>
			<LoadingBackground />
			<Content>
				<h2>{loadingStatus?.title}</h2>
				<Spinner size={300} />
				<h3>{loadingStatus?.detail}</h3>
			</Content>
		</LoadingPositionClass>
	) : null;
}

const LoadingPositionClass = styled.div`
	left: 0;
	right: 0;
	height: 100%;
	width: 100%;
	position: fixed;
	width: 100%;
	z-index: 11;
	text-align: center;
	color: white;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
`;

const LoadingBackground = styled.div`
	position: fixed;
	width: 100%;
	height: 100%;
	background-color: black;
	opacity: 75%;
`;

const Content = styled.div`
	position: relative;
	z-index: 10;
`;
