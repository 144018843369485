import React, { FC, PropsWithChildren, ReactNode } from 'react';
import {
	StyledBottomActionsContainer,
	StyledContent,
	StyledDialog,
	StyledDialogLogo,
	StyledDialogLogoContainer,
	StyledDialogTitle,
	StyledDialogTitleContainer,
} from './Dialog.styles';
import logoSrc from '../../../assets/circleLogo.png';
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import theme from '../../../styles/theme';

type DialogProps = {
	actions?: ReactNode;
	bottomActions?: ReactNode;
	fullHeight?: boolean;
	fullWidth?: boolean;
	open: boolean;
	onClose?: () => void;
	title?: string;
	wide?: boolean;
};

const Dialog: FC<PropsWithChildren<DialogProps>> = ({
	actions,
	bottomActions,
	children,
	fullHeight = false,
	fullWidth = false,
	wide = false,
	open,
	onClose,
	title,
}) => {
	return (
		<StyledDialog
			open={open}
			onClose={onClose}
			fullWidth={fullWidth}
			$fullheight={fullHeight}
			$wide={wide}>
			<StyledDialogTitleContainer>
				{title ? (
					<StyledDialogLogoContainer>
						<StyledDialogLogo>
							<img src={logoSrc} alt="" style={{ height: 24 }} />
						</StyledDialogLogo>
						<StyledDialogTitle>{title}</StyledDialogTitle>
					</StyledDialogLogoContainer>
				) : (
					<div />
				)}
				<div>
					{actions}
					{onClose && (
						<IconButton onClick={onClose} style={{ color: theme.palette.primary.main }}>
							<CloseIcon fontSize="medium" />
						</IconButton>
					)}
				</div>
			</StyledDialogTitleContainer>
			<StyledContent>{children}</StyledContent>
			{bottomActions && (
				<StyledBottomActionsContainer>{bottomActions}</StyledBottomActionsContainer>
			)}
		</StyledDialog>
	);
};

export default Dialog;
