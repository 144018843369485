import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@mui/material';
import LoadingScreen from '../../reusable-components/LoadingScreen';
import { useContext } from 'react';
import { Organization } from '../../../types';
import { SnackContext } from '../../../context/SnackProvider';
import { OrganizationsContext } from '../../../context/OrganizationsProvider';

export default function DeleteOrganizationDialog({
	organizationToBeDeleted,
	setOrganizationToBeDeleted,
}: {
	organizationToBeDeleted: Organization | undefined;
	setOrganizationToBeDeleted: React.Dispatch<
		React.SetStateAction<Organization | undefined>
	>;
}) {
	const { setSnackbarProps } = useContext(SnackContext);
	const { removeOrganization, removingOrganization } = useContext(OrganizationsContext);

	const handleClose = () => {
		setOrganizationToBeDeleted(undefined);
	};

	const handleDelete = async () => {
		if (organizationToBeDeleted?.id) {
			if (await removeOrganization(organizationToBeDeleted?.id)) {
				setSnackbarProps({
					open: true,
					severity: 'success',
					message: `Successfully deleted the organization!`,
				});
			} else {
				setSnackbarProps({
					open: true,
					severity: 'error',
					message: `Failed to delete the organization!`,
				});
			}
		}
		handleClose();
	};

	return (
		<Dialog open={!!organizationToBeDeleted} onClose={handleClose}>
			<DialogTitle>{organizationToBeDeleted?.name}</DialogTitle>
			{removingOrganization ? (
				<DialogContent>
					<LoadingScreen message="Deleting organization..." textColor="black" />
				</DialogContent>
			) : (
				<DialogContent>
					<span>Are you sure you want to delete this organization?</span>
				</DialogContent>
			)}
			{!removingOrganization && (
				<DialogActions>
					<Button autoFocus onClick={handleClose}>
						Cancel
					</Button>
					<Button onClick={handleDelete}>Ok</Button>
				</DialogActions>
			)}
		</Dialog>
	);
}
