import { useContext, useState } from 'react';
import { AuthContext } from '../../../../../../context/AuthProvider';
import styled from 'styled-components';

import StarIcon from '@mui/icons-material/Star';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import DownloadIcon from '@mui/icons-material/Download';
import MoreVertIcon from '@mui/icons-material/MoreVert';

import { FirebaseFile, ProjectData } from '../../../../../../types';
import { FileBucket } from '../../../types';
import { checkPermissions } from '../../../../../../utils';
import { Menu, MenuItem } from '@mui/material';
import { downloadFileWithBlob } from '../../../../../../utils/project-file-download';
import { SnackContext } from '../../../../../../context/SnackProvider';

export default function FileButtons({
	projectData,
	file,
	fileIndex,
	selectedBucket,
	changeFirstPreview,
	moveFile,
	deleteFile,
	editingOrder,
	isAnonymous,
	setRenaming,
}: {
	projectData: ProjectData;
	file: FirebaseFile;
	fileIndex: number;
	selectedBucket: FileBucket;
	changeFirstPreview: (i: number) => void;
	moveFile: (i: number, destination: FileBucket) => Promise<void>;
	deleteFile: (i: number) => void;
	editingOrder: boolean;
	isAnonymous: boolean;
	setRenaming: React.Dispatch<React.SetStateAction<boolean>>;
}) {
	const { setSnackbarProps } = useContext(SnackContext);
	const { user } = useContext(AuthContext);

	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

	const open = Boolean(anchorEl);
	const handleClickMenu = (e: React.MouseEvent<HTMLDivElement>) => {
		setAnchorEl(e.currentTarget);
	};
	const handleCloseMenu = () => {
		setAnchorEl(null);
	};

	const [progress, setProgress] = useState<string | null>(null);
	const handleDownload = async (file: FirebaseFile) => {
		await downloadFileWithBlob(
			file,
			() => {
				setSnackbarProps({
					open: true,
					message: 'Getting file data...',
					severity: 'warning',
					hideDuration: null,
				});
			},
			() => {
				setSnackbarProps({
					open: true,
					message: 'File download started...',
					severity: 'success',
				});
			},
			setProgress
		);
	};

	if (editingOrder) {
		return fileIndex === 0 ? (
			<StarIcon />
		) : (
			<FileButton
				title={`Change first preview file`}
				onClick={() => changeFirstPreview(fileIndex)}>
				<StarBorderIcon />
			</FileButton>
		);
	}

	return (
		<>
			{/* Download file button */}
			{selectedBucket !== 'archive' &&
			!(
				projectData?.projectStatus === 'Pending Payment' &&
				!checkPermissions(user, 'contractor')
			) ? (
				progress === null ? (
					<FileButton
						title={`Download ${file.filename}`}
						tabIndex={0}
						onClick={() => handleDownload(file)}
						onKeyUp={e => {
							if (e.key === 'Enter') handleDownload(file);
						}}>
						<DownloadIcon />
					</FileButton>
				) : (
					<ProgressText>{`${progress}%`}</ProgressText>
				)
			) : null}

			{/* More options button */}
			{user?.isAdmin && !isAnonymous ? (
				<>
					<FileButton title={`View more options`} tabIndex={0} onClick={handleClickMenu}>
						<MoreVertIcon />
					</FileButton>

					<Menu anchorEl={anchorEl} open={open} onClose={handleCloseMenu}>
						<MenuItem
							onClick={() => {
								setRenaming(true);
								handleCloseMenu();
							}}>
							Rename file
						</MenuItem>

						{selectedBucket !== 'uploaded' ? (
							<MenuItem
								onClick={() => {
									moveFile(fileIndex, 'uploaded');
									handleCloseMenu();
								}}>
								Move to uploaded
							</MenuItem>
						) : null}

						{selectedBucket !== 'inProgress' ? (
							<MenuItem
								onClick={() => {
									moveFile(fileIndex, 'inProgress');
									handleCloseMenu();
								}}>
								Move to in-progress
							</MenuItem>
						) : null}

						{selectedBucket !== 'archive' ? (
							<MenuItem
								onClick={() => {
									moveFile(fileIndex, 'archive');
									handleCloseMenu();
								}}>
								Move to archive
							</MenuItem>
						) : null}

						{selectedBucket === 'archive' ? (
							<MenuItem
								onClick={() => {
									deleteFile(fileIndex);
									handleCloseMenu();
								}}>
								Delete file
							</MenuItem>
						) : null}
					</Menu>
				</>
			) : null}
		</>
	);
}

const FileButton = styled.div`
	cursor: pointer;
	border-radius: 0.2em;

	&:hover,
	&:focus {
		box-shadow: 0 0 5px;
	}

	> * {
		display: block;
	}
`;

const ProgressText = styled.span`
	width: 30px;
`;
