import { Button } from '@mui/material';
import { ReactNode, useState } from 'react';
import styled from 'styled-components';
import theme from '../../../../../../../styles/theme';
import { ArrowDropDown, ArrowDropUp } from '@mui/icons-material';

interface SharingCategoryProps {
	title: string;
	children: ReactNode;
}

export default function SharingCategory({ title, children }: SharingCategoryProps) {
	const [open, setOpen] = useState(true);

	return (
		<>
			<CategoryDropdown onClick={() => setOpen(prev => !prev)}>
				<span>{title}</span>
				{open ? <ArrowDropUp /> : <ArrowDropDown />}
			</CategoryDropdown>
			{open ? children : null}
		</>
	);
}

const CategoryDropdown = styled(Button)`
	display: flex;
	align-items: center;
	justify-content: space-between;

	background-color: ${theme.palette.primary.main};
	&:hover {
		background-color: ${theme.palette.primary.main};
	}
	color: black;

	width: 100%;
`;
