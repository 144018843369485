import { FC } from 'react';
import { LoadingProps } from './Loading.types';
import Spinner from '../../../../reusable-components/Spinner';
import { LoadingContainer, LoadingText } from './Loading.styles';

const Loading: FC<React.PropsWithChildren<LoadingProps>> = ({
	loadingText,
	size = 40,
}) => {
	return (
		<LoadingContainer height={size}>
			<Spinner size={size} />
			{!!loadingText && <LoadingText>{loadingText}</LoadingText>}
		</LoadingContainer>
	);
};

export default Loading;
