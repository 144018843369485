import React from 'react';
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
} from '@mui/material';

interface CommentDeletionConfirmationProps {
	open: boolean;
	onClose: () => void;
	onDeleteConfirm: () => void;
}

const CommentDeletionConfirmation: React.FC<React.PropsWithChildren<CommentDeletionConfirmationProps>> = ({
	open,
	onClose,
	onDeleteConfirm,
}) => {
	return (
		<Dialog
			open={open}
			onClose={onClose}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description">
			<DialogTitle id="alert-dialog-title">{'Are you sure?'}</DialogTitle>
			<DialogContent>
				<DialogContentText id="alert-dialog-description">
					Are you sure you want to delete your comment? It will be permanently deleted.
				</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button onClick={onClose}>No</Button>
				<Button onClick={onDeleteConfirm} autoFocus>
					Yes
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default CommentDeletionConfirmation;
