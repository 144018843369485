import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
} from '@mui/material';

const UploadConfirmationDialog = ({
	open,
	onClose,
	onConfirm,
	title,
	message,
}: {
	open: boolean;
	onClose: () => void;
	onConfirm: () => void;
	title: string;
	message: string;
}) => {
	return (
		<Dialog open={open} onClose={onClose}>
			<DialogTitle>{title}</DialogTitle>
			<DialogContent>
				<DialogContentText>{message}</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button onClick={onClose}>No</Button>
				<Button onClick={onConfirm} autoFocus>
					Yes!
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default UploadConfirmationDialog;
