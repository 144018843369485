import { useRef, useContext } from 'react';
import { Upload } from '@mui/icons-material';
import styled from 'styled-components';
import { OrganizationsContext } from '../../../context/OrganizationsProvider';
import { AuthContext } from '../../../context/AuthProvider';

type LogoContainerProps = {
	handleLogoUpload?: (files: File[]) => Promise<void>;
	disableHover?: boolean;
};

const LogoContainer = ({
	handleLogoUpload,
	disableHover = false,
}: LogoContainerProps) => {
	const { user } = useContext(AuthContext);
	const { logoUrl, logoLoading } = useContext(OrganizationsContext);

	const fileInputRef = useRef<HTMLInputElement>(null);
	const canUpload = Boolean(user?.isAdmin);

	const triggerFileSelect = () => {
		if (canUpload && fileInputRef.current) {
			fileInputRef.current.click();
		}
	};

	const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
		if (event.target.files && event.target.files[0] && handleLogoUpload) {
			await handleLogoUpload([event.target.files[0]]);
		}
	};

	return (
		<LogoUploadContainer hasLogo={!!logoUrl} canUpload={canUpload}>
			{logoLoading ? (
				<>
					<div></div>
				</>
			) : logoUrl ? (
				<>
					<LogoImage src={logoUrl} alt="Org Logo" />
					{canUpload && !disableHover && (
						<LogoUploadOverlay onClick={triggerFileSelect}>
							<UploadIcon />
							<span>Upload new logo</span>
						</LogoUploadOverlay>
					)}
				</>
			) : canUpload ? (
				<UploadButton onClick={triggerFileSelect}>
					<UploadIcon />
					<span>Upload your organization's logo</span>
				</UploadButton>
			) : null}
			<input
				type="file"
				accept="image/*"
				ref={fileInputRef}
				onChange={handleFileChange}
				style={{ display: 'none' }}
			/>
		</LogoUploadContainer>
	);
};

export default LogoContainer;

const LogoUploadOverlay = styled.div`
	display: none; // Initially hidden
	position: absolute;
	align-items: center;
	justify-content: center;
	background-color: rgba(0, 0, 0, 0.5);
	color: white;
	border: 2px solid white;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	cursor: pointer;
	text-align: center;
	&:hover {
		display: flex;
	}

	@media (max-width: 768px) {
		max-height: 100px;
		width: 80%;
		justify-content: center;
		margin: 0 auto;
	}
`;

const LogoUploadContainer = styled.div<{ hasLogo: boolean; canUpload: boolean }>`
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	height: 150px;
	max-width: 250px;
	margin-top: auto;
	border: ${({ hasLogo, canUpload }) =>
		hasLogo || !canUpload ? 'none' : '2px solid #ffb310'};

	color: white;
	border-radius: 50px;

	&:hover ${LogoUploadOverlay} {
		display: ${({ hasLogo }) => (hasLogo ? 'flex' : 'none')};
	}

	@media (max-width: 768px) {
		max-height: 100px;
		width: 80%;
		justify-content: center;
		margin: 0 auto;
	}
`;

const UploadButton = styled.div`
	cursor: pointer;
	display: flex;
	text-align: center;
	align-items: center;
	&:hover {
		opacity: 0.8;
	}
`;

const UploadIcon = styled(Upload)`
	color: #ffb310;
	font-size: 2rem;
	display: flex;
`;

const LogoImage = styled.img`
	max-width: 100%;
	max-height: 100%;
	object-fit: contain;

	@media (max-width: 768px) {
		max-height: 60px;
	}
`;
