import { Link } from 'react-router-dom';

import { IconButton } from '@mui/material';
import logoSrc from '../../assets/circleLogo.png';
import styled from 'styled-components';

/**
 * Logo button component that navigates to the projects list page
 * @param {string} path Optional string param that changes the navigation target to a specific page
 */
export default function LogoButton({
	path = '',
	disabled = false,
}: {
	path?: string;
	disabled?: boolean;
}) {
	return (
		<Link
			to={path ? path : '/projects'}
			tabIndex={-1}
			title="Navigate to projects list page"
			style={disabled ? { cursor: 'default', pointerEvents: 'none' } : {}}>
			<LogoHomeButton>
				<img src={logoSrc} alt="" style={{ height: '44px' }} />
			</LogoHomeButton>
		</Link>
	);
}

const LogoHomeButton = styled(IconButton)`
	transition: 0.2s;
	padding: 10px;

	> img {
		transition: inherit;
	}

	&:hover,
	&:focus-visible {
		background-color: rgba(255, 179, 16, 0.2);

		> img {
			transform: scale(1.1);
		}
	}
`;
