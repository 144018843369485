import styled from 'styled-components';
import { UserTag, UserTagTitleMap } from '../../../../types/project-roles';
import { getEntries } from '../../../../utils/typescript-utils';

export const FilterActions = ({
	showFilterBar,
	toggleFilterBar,
	selectedTags,
	onTagFilterChange,
	resetTags,
}: {
	showFilterBar: boolean;
	toggleFilterBar: () => void;
	selectedTags: UserTag[];
	onTagFilterChange: (tag: UserTag) => void;
	resetTags: () => void;
}) => {
	const handleCancel = () => {
		resetTags();
		toggleFilterBar();
	};

	return showFilterBar ? (
		<FilterContainer>
			<TagsContainer>
				{getEntries(UserTagTitleMap).map(([key, value]) => (
					<TagButton
						key={key}
						onClick={() => onTagFilterChange(key)}
						selected={selectedTags.includes(key)}>
						{value}
					</TagButton>
				))}
			</TagsContainer>
			<CancelButton onClick={handleCancel}>Cancel</CancelButton>
		</FilterContainer>
	) : null;
};

const FilterContainer = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	gap: 10px;
`;

const TagsContainer = styled.div`
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	gap: 10px;
`;

interface TagButtonProps {
	selected: boolean;
}

const TagButton = styled.button<TagButtonProps>`
	background-color: ${props => (props.selected ? '#ffb310' : 'grey')};
	color: #0e0e0f;
	border: none;
	border-radius: 20px;
	padding: 8px 16px;
	cursor: pointer;
	font-weight: bold;
	transition: background-color 0.3s;
	font-family: 'Epilogue';

	&:hover {
		background-color: ${props => (props.selected ? '#ffb310' : '#f6f6f6')};
		color: #0e0e0f;
	}
`;

const CancelButton = styled.button`
	background-color: red;
	font-family: 'Epilogue';
	color: #fff;
	border: none;
	border-radius: 20px;
	padding: 8px 16px;
	cursor: pointer;
	font-weight: bold;

	&:hover {
		background-color: #555;
	}
`;
