import { Box, Typography } from '@mui/material';
import { FC, PropsWithChildren, useContext } from 'react';
import { OCRModelNumberProps } from './OCRModelNumber.types';
import { AuthContext } from '../../../context/AuthProvider';
import theme from '../../../styles/theme';
import { StyledTextField } from '../StyledTextField';
import { TemplateContext } from '../../../context/TemplateProvider';
import { YellowButton } from '../../styled-components/styledReactTable';
import useModelSearch from './useModelSearch';
import useTextFields from './useTextFields';
import ModelData from './components/ModelData/ModelData';
import PreviewImage from '../../reusable-components/PreviewImage';

const OCRModelNumber: FC<PropsWithChildren<OCRModelNumberProps>> = ({ node }) => {
	const {
		handleManufactureDateInputChange,
		handleManufacturerInputChange,
		handleModelInputChange,
		handleSerialInputChange,
		onManufactureDateBlur,
		onManufactureDateFocus,
		onManufacturerBlur,
		onManufacturerFocus,
		onModelBlur,
		onModelFocus,
		onSerialBlur,
		onSerialFocus,
		setValue,
		value,
	} = useTextFields(node);
	const { isProcessing, model, proccessImage, searchModel, searching } = useModelSearch({
		value,
		setValue,
	});
	const { firebaseAuthData } = useContext(AuthContext);
	const { isTemplate } = useContext(TemplateContext);

	const editable = !node.focusedBy || node.focusedBy.id === firebaseAuthData?.uid;
	const processing = isProcessing || searching;

	return (
		<Box display="flex" flexDirection="row" alignItems="start">
			<PreviewImage
				imageUrl={value.photo.url || ''}
				imageName={node.displayTitle}
				imageWidth={132}
				imageHeight={132}
			/>
			<Box
				display="flex"
				flexDirection="column"
				justifyContent="space-between"
				gap={1}
				paddingLeft="16px">
				<StyledTextField
					placeholder="Enter text"
					label="Model Number"
					value={value.modelNumber}
					onChange={handleModelInputChange}
					onBlur={onModelBlur}
					onFocus={onModelFocus}
					disabled={!editable || isTemplate}
					variant={'outlined'}
					size={'small'}
					id={`${node.id}modelNumber`}
				/>
				<StyledTextField
					placeholder="Enter text"
					label="Serial Number"
					value={value.serialNumber}
					onChange={handleSerialInputChange}
					onBlur={onSerialBlur}
					onFocus={onSerialFocus}
					disabled={!editable || isTemplate}
					variant={'outlined'}
					size={'small'}
					id={`${node.id}serialNumber`}
				/>
				<StyledTextField
					placeholder="Enter text"
					label="Manufacturer"
					value={value.manufacturer}
					onChange={handleManufacturerInputChange}
					onBlur={onManufacturerBlur}
					onFocus={onManufacturerFocus}
					disabled={!editable || isTemplate}
					variant={'outlined'}
					size={'small'}
					id={`${node.id}manufacturer`}
				/>
				<StyledTextField
					placeholder="Enter text"
					label="Manufacture Date"
					value={value.manufactureDate}
					onChange={handleManufactureDateInputChange}
					onBlur={onManufactureDateBlur}
					onFocus={onManufactureDateFocus}
					disabled={!editable || isTemplate}
					variant={'outlined'}
					size={'small'}
					id={`${node.id}manufactureDate`}
				/>
				<div style={{ display: 'flex', gap: '8px', width: '100%' }}>
					<YellowButton
						disabled={processing || (!!value.modelNumber && !!value.serialNumber)}
						onClick={() => proccessImage(node)}
						style={{ flex: 1 }}>
						{isProcessing ? 'Processing...' : 'Process photo'}
					</YellowButton>
					<YellowButton
						disabled={processing || !value.modelNumber}
						onClick={() => searchModel(node)}
						style={{ flex: 1 }}>
						{searching ? 'Searching...' : 'Search database'}
					</YellowButton>
				</div>
				{node.focusedBy && node.focusedBy.id !== firebaseAuthData?.uid ? (
					<Typography sx={{ color: theme.palette.newText.primary }}>
						{node.focusedBy.name} is typing...
					</Typography>
				) : (
					<span />
				)}
			</Box>
			{model && <ModelData model={model} />}
		</Box>
	);
};

export default OCRModelNumber;
