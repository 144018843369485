import { Input } from '@mui/material';
import styled from 'styled-components';

export const MainContainer = styled.div`
	background-color: #eef0f5;
	width: 85%;
	display: flex;
	flex-direction: column;
`;

export const BackgroundContainer = styled.div`
	background-color: #fefefe;
	width: 95%;
	border-radius: 40px;
	margin: 0 auto;
	height: 95%;
	overflow: hidden;
	box-shadow: 0px 2px 5px 1px rgba(0, 0, 0, 0.15);
`;

export const BackgroundContent = styled.div`
	display: flex;
	flex-direction: column;
	gap: 20px;

	overflow-y: scroll;
	height: 100%;
	padding: 20px;

	&::-webkit-scrollbar-track {
		margin-top: 40px;
		margin-bottom: 40px;
	}
`;

export const CategoryHeaderText = styled(Input)`
	font-family: 'Epilogue';
	color: black;
	font-weight: 600;
	font-size: 1.5rem;
	text-align: center;
`;

export const ToolsContainer = styled.div`
	display: flex;
	flex-direction: column;
	margin-left: 350px;
	position: absolute;
`;
