import React, { Fragment, useState, FC, useContext, useEffect, useCallback } from 'react';
import { Dialog, StyledTextField } from '../../../../components/form-components';
import { Box, InputAdornment, inputLabelClasses, Typography } from '@mui/material';
import {
	StyledButtonText,
	StyledCircularProgress,
} from '../../../../components/form-components/CoverImage/CoverImageStyles';
import { createForm, queryProjects } from '../../../../firebase';
import { ProjectData, ProjectQueryResult } from '../../../../types';
import { ProjectTable } from './ProjectTable';
import { Close, Search } from '@mui/icons-material';
import { SnackContext } from '../../../../context/SnackProvider';
import { TemplateContext } from '../../../../context/TemplateProvider';
import theme from '../../../../styles/theme';
import useFormName from '../../../V2FormsList/useFormName';

export const FormDialog: FC<{ open: boolean; handleClose: () => void }> = ({
	open,
	handleClose,
}) => {
	const { tempId } = useContext(TemplateContext);
	const { setSnackbarProps } = useContext(SnackContext);
	const { handleAddName } = useFormName();

	const [formName, setFormName] = useState<string>('');
	const [searchQuery, setSearchQuery] = useState<string>('');
	const [step, setStep] = useState<number>(0);
	const [projects, setProjects] = useState<ProjectData[]>([]);
	const [selectedProject, setSelectedProject] = useState<string[]>([]);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [disableButton, setDisableButton] = useState<boolean>(true);

	const handleCancel = () => {
		setStep(0);
		setFormName('');
		setSelectedProject([]);
		setSearchQuery('');
		setDisableButton(false);
		handleClose();
		setIsLoading(false);
	};

	const handleNext = () => {
		setStep(step + 1);
	};

	const handleSelectProject = (id: string) => {
		setSelectedProject(prev => {
			if (prev.includes(id)) {
				return prev.filter(project => project !== id);
			}
			return [...prev, id];
		});
	};

	const addFormToProjects = async () => {
		try {
			setIsLoading(true);
			setDisableButton(true);

			if (selectedProject.length > 1) {
				const promises = selectedProject.map(async projectId => {
					return createForm({
						name: formName,
						projectId: projectId,
						templateId: tempId,
					});
				});

				await Promise.all(promises);
			} else {
				await handleAddName(formName, 'template', selectedProject[0], tempId, true);
			}

			setSnackbarProps({
				open: true,
				message: `Success! Report added to the projects.`,
				severity: 'success',
			});
			handleCancel();
		} catch (error) {
			setSnackbarProps({
				open: true,
				message: `Error adding report to the projects.`,
				severity: 'error',
			});
			setIsLoading(false);
		}
	};

	const displayNextButton = () => {
		if (step === 0) {
			return (
				<StyledButtonText onClick={handleNext} disabled={!formName.length}>
					Next
				</StyledButtonText>
			);
		} else if (step === 1) {
			return (
				<StyledButtonText
					onClick={addFormToProjects}
					disabled={selectedProject.length === 0 && disableButton}>
					Assign Report
				</StyledButtonText>
			);
		}
	};

	const fetchProjects = useCallback(async (search: string) => {
		setIsLoading(true);
		const response = await queryProjects({
			query: search,
			searchMode: 'general',
			limit: 200,
			cursor: 0,
		});

		const result = response.data as ProjectQueryResult;
		setProjects(result.projects);
		setIsLoading(false);
	}, []);

	useEffect(() => {
		if (open) fetchProjects('');
	}, [fetchProjects, open]);

	const clearSearchHandler = () => {
		setSearchQuery('');
		setSelectedProject([]);
	};

	const searchHandler = async (event: React.ChangeEvent<HTMLInputElement>) => {
		const query = event.target.value.toLocaleLowerCase();
		setSearchQuery(query);
		if (query.length > 3) {
			await fetchProjects(query);
		} else if (query.length === 0) {
			await fetchProjects('');
		}
	};

	return (
		<Dialog
			open={open}
			wide
			title={step === 0 ? 'Please enter a name for this report:' : formName}
			onClose={handleCancel}
			bottomActions={
				<>
					<StyledButtonText onClick={handleCancel}>Cancel</StyledButtonText>
					{displayNextButton()}
				</>
			}>
			{!isLoading && step === 0 && (
				<Fragment>
					<Typography color="newText.primary" variant="body2" sx={{ mb: '24px' }}>
						Please enter a name for this report:
					</Typography>
					<StyledTextField
						label="Report Name"
						value={formName}
						size="small"
						onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
							setFormName(event.target.value)
						}
						InputLabelProps={{
							sx: {
								color: theme.palette.grey[100],
								[`&.${inputLabelClasses.shrink}`]: {
									color: theme.palette.primary.main,
								},
							},
						}}
					/>
				</Fragment>
			)}
			{!isLoading && step === 1 && (
				<Fragment>
					<Typography color="newText.primary" variant="body2" sx={{ mb: '24px' }}>
						Select which project this report should be created for.
					</Typography>
					<StyledTextField
						label="Search"
						value={searchQuery}
						size="small"
						onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
							searchHandler(event)
						}
						sx={{
							mb: '16px',
							width: '520px',
							maxWidth: '100%',
						}}
						InputProps={{
							startAdornment: (
								<InputAdornment position="start">
									<Search color="primary" />
								</InputAdornment>
							),
							endAdornment: !!searchQuery.length && (
								<InputAdornment position="end" onClick={() => clearSearchHandler()}>
									<Close color="primary" sx={{ cursor: 'pointer	' }} />
								</InputAdornment>
							),
						}}
						InputLabelProps={{
							sx: {
								color: theme.palette.newText.primary,
								[`&.${inputLabelClasses.shrink}`]: {
									color: theme.palette.primary.main,
								},
							},
						}}
					/>
					<ProjectTable
						rows={projects}
						selectedProject={selectedProject}
						selectProject={handleSelectProject}
					/>
				</Fragment>
			)}

			{isLoading && (
				<Box sx={{ mb: '30px' }}>
					<StyledCircularProgress size={24} variant="indeterminate" />
				</Box>
			)}
		</Dialog>
	);
};
