import styled from 'styled-components';
import { sendProjectCaptureEmail } from '../../../../../../../firebase';
import { ProjectData } from '../../../../../../../types';
import RocketLaunchIcon from '@mui/icons-material/RocketLaunch';
import theme from '../../../../../../../styles/theme';
import { YellowIconButton } from '../../../../../../styled-components/buttons';
import { useContext, useState } from 'react';
import { SnackContext } from '../../../../../../../context/SnackProvider';
import Spinner from '../../../../../Spinner';
import { StatusTableContext } from '../../../../StatusTableProvider';

export default function SendProjectCaptureEmailButton({
	projectData,
}: {
	projectData: ProjectData;
}) {
	const { setSnackbarProps } = useContext(SnackContext);
	const { setStatusProjects } = useContext(StatusTableContext);

	const [sendingEmail, setSendingEmail] = useState(false);

	const scanningTechs = projectData.scanningTechnicians || [];
	const sentEmail = Boolean(projectData.sentCaptureEmail);
	const titleText = sentEmail
		? 'Capture email already sent. Click to send another one!'
		: 'Send capture email';

	const handleClick = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
		e.stopPropagation();
		if (scanningTechs.length) {
			try {
				setSendingEmail(true);
				const projectId = projectData.id || '';
				await sendProjectCaptureEmail({ projectId });
				setStatusProjects(prev => {
					if (prev) {
						return prev.map(proj => {
							if (proj.id === projectId) {
								return {
									...proj,
									sentCaptureEmail: true,
								};
							} else return proj;
						});
					} else return prev;
				});

				setSnackbarProps({
					open: true,
					severity: 'success',
					message: `Sent capture email for ${projectData.address || 'Unknown Project'}!`,
				});
			} catch (err) {
				console.log(err);
				setSnackbarProps({
					open: true,
					severity: 'error',
					message: 'Error sending capture email',
				});
			} finally {
				setSendingEmail(false);
			}
		} else {
			setSnackbarProps({
				open: true,
				severity: 'warning',
				message: 'No scanning technicians added; cannot send capture email!',
			});
		}
	};

	return !sendingEmail ? (
		<YellowIconButton onClick={handleClick} title={titleText}>
			<RocketIcon $sentEmail={sentEmail} />
		</YellowIconButton>
	) : (
		<Spinner />
	);
}

const RocketIcon = styled(RocketLaunchIcon)<{ $sentEmail: boolean }>`
	fill: ${({ $sentEmail }) => ($sentEmail ? 'green' : theme.palette.primary.main)};
	transition: 200ms;
	&:hover {
		fill: white;
	}
`;
