import { Category } from '../types';
import {
	newServicePanel,
	newDisconnect,
	newElectricMeter,
	newHvac,
	newWaterHeater,
	newWaterMeter,
	newFireAlarmControlPanel,
	newCustomItem,
} from './BaseItems';
import { v4 as uuidv4 } from 'uuid';

/**
 *
 * Base Category for each category type.
 */

export const newElectrical = () => {
	const baseElectrical: Category = {
		categoryId: uuidv4(),
		type: 'ELECTRICAL',
		displayTitle: 'Electrical',
		items: [newServicePanel(), newDisconnect(), newElectricMeter()],
	};
	return baseElectrical;
};

export const newMechanical = () => {
	const baseMechanical: Category = {
		categoryId: uuidv4(),
		type: 'MECHANICAL',
		displayTitle: 'Mechanical',
		items: [newHvac()],
	};
	return baseMechanical;
};

export const newPlumbing = () => {
	const basePlumbing: Category = {
		categoryId: uuidv4(),
		type: 'PLUMBING',
		displayTitle: 'Plumbing',
		items: [newWaterHeater(), newWaterMeter()],
	};
	return basePlumbing;
};

export const newFireProtection = () => {
	const baseFireProtection: Category = {
		categoryId: uuidv4(),
		type: 'FIRE_PROTECTION',
		displayTitle: 'Fire Protection',
		items: [newFireAlarmControlPanel()],
	};
	return baseFireProtection;
};

export const newCustomCategory = () => {
	const baseCustomCategory: Category = {
		categoryId: uuidv4(),
		type: 'CUSTOM',
		displayTitle: 'Custom Category',
		items: [newCustomItem()],
	};
	return baseCustomCategory;
};
