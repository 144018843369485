import React, { FC } from 'react';
import { Dialog } from '../../Dialog';
import { Button } from '@mui/material';

type ConfirmDialogProps = {
	open: boolean;
	handleClose: () => void;
	handleConfirm: () => void;
	title: string;
	label?: string;
};

const ConfirmDialog: FC<React.PropsWithChildren<ConfirmDialogProps>> = ({
	handleClose,
	handleConfirm,
	label,
	open,
	title,
}) => {
	return (
		<Dialog
			open={open}
			onClose={handleClose}
			title={title}
			bottomActions={
				<>
					<Button onClick={handleClose}>Cancel</Button>
					<Button onClick={handleConfirm}>Ok</Button>
				</>
			}>
			{label}
		</Dialog>
	);
};

export default ConfirmDialog;
