import { ReactNode, useEffect, useRef, CSSProperties } from 'react';
import NavigateBefore from '@mui/icons-material/NavigateBefore';
import NavigateNext from '@mui/icons-material/NavigateNext';
import { CarouselNavProps, CarouselProps } from './types';

export interface SanitizedCarouselProps extends CarouselProps {
	sx: CSSProperties;
	className: string;
	children: ReactNode;

	height: number | string | undefined;

	strictIndexing: boolean;

	autoPlay: boolean;
	stopAutoPlayOnHover: boolean;
	interval: number;

	animation: 'fade' | 'slide';
	duration: number;

	swipe: boolean;

	navButtonsAlwaysInvisible: boolean;
	navButtonsAlwaysVisible: boolean;
	cycleNavigation: boolean;
	fullHeightHover: boolean;
	navButtonsWrapperProps: SanitizedCarouselNavProps;
	navButtonsProps: SanitizedCarouselNavProps;
	NavButton:
		| (({
				onClick,
				next,
				className,
				style,
				prev,
		  }: {
				onClick: Function;
				className: string;
				style: CSSProperties;
				next: boolean;
				prev: boolean;
		  }) => ReactNode)
		| undefined;

	NextIcon: ReactNode;
	PrevIcon: ReactNode;

	indicators: boolean;
	indicatorContainerProps: SanitizedCarouselNavProps;
	indicatorIconButtonProps: SanitizedCarouselNavProps;
	activeIndicatorIconButtonProps: SanitizedCarouselNavProps;
	IndicatorIcon: ReactNode;

	onChange: (now?: number, previous?: number) => any;
	changeOnFirstRender: boolean;
	next: (now?: number, previous?: number) => any;
	prev: (now?: number, previous?: number) => any;

	lazy: boolean;
	lazyPreload: number;
	thumbnails: boolean;

	overflow: () => void;
	underflow: () => void;

	captureWindowKeypresses: boolean;
}

export interface SanitizedCarouselNavProps extends CarouselNavProps {
	style: CSSProperties;
	className: string;
}

export const sanitizeNavProps = (
	props: CarouselNavProps | undefined
): SanitizedCarouselNavProps => {
	const { className, style, ...rest } = props || {};

	return props !== undefined
		? {
				style: props.style !== undefined ? props.style : {},
				className: props.className !== undefined ? props.className : '',
				...rest,
		  }
		: { style: {}, className: '', ...rest };
};

export const sanitizeProps = (props: CarouselProps): SanitizedCarouselProps => {
	const animation = props.animation ?? 'fade';
	const duration = props.duration ?? animation === 'fade' ? 500 : 200;

	return {
		sx: props.sx ?? { height: '100%', width: '100%' },
		className: props.className ?? '',
		children: props.children ?? [],

		height: props.height ?? '100%',

		strictIndexing: props.strictIndexing ?? true,

		autoPlay: props.autoPlay ?? false,
		stopAutoPlayOnHover: props.stopAutoPlayOnHover ?? true,
		interval: props.interval ?? 4000,

		animation: animation,
		duration: duration,

		swipe: props.swipe ?? true,

		navButtonsAlwaysInvisible: props.navButtonsAlwaysInvisible ?? false,
		navButtonsAlwaysVisible: props.navButtonsAlwaysVisible ?? true,
		cycleNavigation: props.cycleNavigation ?? false,
		fullHeightHover: props.fullHeightHover ?? false,
		navButtonsWrapperProps: sanitizeNavProps(props.navButtonsWrapperProps),
		navButtonsProps: sanitizeNavProps(props.navButtonsProps),
		NavButton: props.NavButton,

		NextIcon: props.NextIcon ?? <NavigateNext />,
		PrevIcon: props.PrevIcon ?? <NavigateBefore />,

		indicators: props.indicators ?? false,
		indicatorContainerProps: sanitizeNavProps(props.indicatorContainerProps),
		indicatorIconButtonProps: sanitizeNavProps(props.indicatorIconButtonProps),
		activeIndicatorIconButtonProps: sanitizeNavProps(
			props.activeIndicatorIconButtonProps
		),
		IndicatorIcon: props.IndicatorIcon,

		onChange: props.onChange !== undefined ? props.onChange : () => {},
		changeOnFirstRender: props.changeOnFirstRender ?? false,
		next: props.next !== undefined ? props.next : () => {},
		prev: props.prev !== undefined ? props.prev : () => {},

		lazy: props.lazy ?? false,
		lazyPreload: props.lazyPreload ?? 0,
		thumbnails: props.thumbnails ?? false,

		controlledIndex: props.controlledIndex,
		setControlledIndex: props.setControlledIndex,

		showNextButton: props.showNextButton,
		showPrevButton: props.showPrevButton,

		overflow: props.overflow ? props.overflow : () => {},
		underflow: props.underflow ? props.underflow : () => {},

		captureWindowKeypresses: props.captureWindowKeypresses ?? false,
	};
};

export const useInterval = (callback: Function, delay: number) => {
	const savedCallback = useRef<Function>(() => {});

	// Remember the latest callback.
	useEffect(() => {
		savedCallback.current = callback;
	}, [callback]);

	// Set up the interval.
	useEffect(() => {
		function tick() {
			savedCallback.current();
		}
		if (delay !== null) {
			let id = setInterval(tick, delay);
			return () => clearInterval(id);
		}

		return () => {};
	}, [delay]);
};
