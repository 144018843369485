import {
	Button,
	Card,
	CardContent,
	CardMedia,
	CircularProgress,
	DialogActions,
	DialogContent,
	DialogTitle,
	TextField,
} from '@mui/material';
import { Box, styled } from '@mui/system';
import theme from '../../../styles/theme';

export const StyledButtonText = styled(Button)`
	color: ${({ theme }) => theme.palette.primary.main};
	font-size: 14px;
	text-transform: none;
	padding: 8px 12px;

	&:hover {
		background-color: ${({ theme }) => theme.palette.secondary.main};
	}

	&:disabled {
		color: ${({ theme }) => theme.palette.newText.primary};
		opacity: 0.7;
	}
`;

export const StyledButtonContained = styled(Button)<{ colortype: string }>`
	color: ${({ theme }) => theme.palette.text.primary};
	font-size: 14px;
	text-transform: none;
	padding: 8px 24px;

	${props =>
		props.colortype === 'dark' &&
		`color: ${props.theme.palette.primary.main};
		 background-color:  ${props.theme.palette.secondary.main}
		 border-color: transparent;

		 &:hover {
		  border-color: transparent;
		  background-color:  ${props.theme.palette.secondary.main}
			opacity: 0.8;
		 }
	`}

	&:disabled {
		color: rgb(251 251 251 / 30%);
		background-color: rgba(230, 224, 233, 0.12);
	}
`;
export const StyledButtonError = styled(Button)`
	font-size: 14px;
	text-transform: none;
	padding: 8px 24px;

	&:disabled {
		color: ${({ theme }) => theme.palette.newText.primary};
		background-color: ${({ theme }) => theme.palette.error.main};
		opacity: 0.7;
	}
`;

export const StyledDialogActions = styled(DialogActions)`
	background: unset;
	background-color: ${({ theme }) => theme.palette.common.black};
	padding: 24px 32px;
`;

export const StyledDialogTitle = styled(DialogTitle)`
	background: unset;
	background-color: ${({ theme }) => theme.palette.common.black};
	border-bottom: 1px solid ${({ theme }) => theme.palette.divider};
	padding: 24px 32px;
`;

export const StyledDialogContent = styled(DialogContent)`
	padding: 0px;
	background-color: transparent;

	> * {
		margin: 0;
	}
`;

export const StyledTextField = styled(TextField)`
	width: 400px;
	max-width: 100%;
	margin: 16px 0;

	input,
	textarea {
		color: #fbfbfb;

		&::placeholder {
			line-height: 1.6;
		}
	}

	fieldset {
		border-color: #242424;
	}

	label {
		color: #838383;
		line-height: 1.6;
	}

	&:hover {
		> :not(.Mui-focused) {
			fieldset {
				border-color: #fff;
			}
		}
	}
	.MuiFormHelperText-root {
		color: ${({ theme }) => theme.palette.error.main};
	}
`;

export const StyledCircularProgress = styled(CircularProgress)`
	position: absolute;
	top: 50%;
	left: 50%;
	margin-top: -12px;
	margin-left: -12px;
`;

export const StyledCard = styled(Card)`
	background-color: ${({ theme }) => theme.palette.grey[900]};
	border-radius: 10px;
	cursor: pointer;
	transition: 0.3s;
	position: relative;
	overflow: visible;
	min-height: 75px;

	&:hover {
		opacity: 0.8;
	}
`;

export const StyledBoxUpload = styled(Box)`
	background-color: ${({ theme }) => theme.palette.grey[900]};
	border-radius: 10px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	transition: 0.3s;
	gap: 14px;
	min-height: 75px;

	&:hover {
		opacity: 0.7;
	}
`;
export const CoverContainer = styled(Box)<{ haslogo: number; logoonly: number }>`
	display: grid;
	grid-template-columns: ${({ haslogo, logoonly }) => {
		if (logoonly || haslogo) return '1fr 3fr';
		return '3fr 1fr';
	}};
	gap: 24px;
	width: 93%;
	max-width: 1032px;

	@media (max-width: 1024px) {
		grid-template-columns: 1fr;
		width: 100%;
	}
`;
export const CoverLogoContainer = styled(Box)<{ hasborder: number }>`
	width: 100%;

	@media (max-width: 1024px) {
		width: calc(100% / 3 - 8px);
	}

	${({ theme }) => theme.breakpoints.down('md')} {
		width: calc(100% / 2 - 8px);
	}

	${({ hasborder }) =>
		hasborder &&
		`
		&:after {
			content: '';
			position: absolute;
			right: -12px;
			top: 0;
			width: 1px;
			height: 100%;
			background-color: ${theme.palette.secondary.light};

			@media (max-width: 1024px) {
				height: 1px;
				width: 100%;
				right: 0;
				top: unset;
				bottom: -16px;
			}
		}
	`}
`;
export const CoverImageContainer = styled(Box)`
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	gap: 24px;
	width: 100%;

	@media (max-width: 1024px) {
		gap: 8px;
	}

	${({ theme }) => theme.breakpoints.down('md')} {
		grid-template-columns: 1fr 1fr;
	}
`;

export const StyledCardMedia = styled(CardMedia)`
	height: 78px;
	border-radius: 10px;
	overflow: hidden;
`;
export const StyledCardContent = styled(CardContent)`
	padding-bottom: 16px;
	border-radius: 0 0 10px 10px;
	overflow: hidden;
`;
