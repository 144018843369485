import { DialogContent, DialogTitle, Button, Box } from '@mui/material';
import {
	Center,
	Checkbox,
	NodeRow,
	StyledDialog,
	StyledDialogActions,
	StyledSpan,
} from './UnarchiveDialog.styles';
import useArchivedNodes from './useArchivedNodes';
import { useEffect, useState } from 'react';
import { FormNodeWithChildren, itemTypes } from '../../../utils/types';
import Loading from '../../Loading/Loading';
import { Stop } from '@mui/icons-material';

const UnarchiveDialog = ({
	formId,
	onClose,
	open,
	projectId,
}: {
	formId: string;
	onClose: () => void;
	open: boolean;
	projectId: string;
}) => {
	const {
		activeContainers,
		archivedNodes,
		getActiveContainers,
		getArchivedNodes,
		getSelectedNodes,
		loading,
		restoreNodes,
		rootNode,
		toggleNode,
	} = useArchivedNodes(formId, projectId);

	const [destinationDialogOpen, setDestinationDialogOpen] = useState(false);
	const closeDestinationDialog = () => setDestinationDialogOpen(false);

	const displayNode = (node: FormNodeWithChildren, level = 0) => (
		<Box key={node.id}>
			<NodeRow style={{ marginLeft: level * 30 }}>
				<Checkbox
					onChange={value => toggleNode(node, value.target.checked)}
					checked={node.selected || false}
					color="primary"
				/>
				{node.displayTitle} ({itemTypes.find(item => item.value === node.type)?.label})
			</NodeRow>
			{node.children && node.children.map(node => displayNode(node, level + 1))}
		</Box>
	);

	const displayDestinationNode = (node: FormNodeWithChildren, level = 0) => (
		<Box key={node.id}>
			<NodeRow style={{ marginLeft: node.id === '' ? 0 : (level + 1) * 30 }}>
				<Stop fontSize="small" color="primary" />
				<StyledSpan
					onClick={async () => {
						await restoreNodes(node);
						closeDestinationDialog();
						onClose();
					}}>
					{node.displayTitle}
				</StyledSpan>
			</NodeRow>
			{node.children &&
				node.children.map(node => displayDestinationNode(node, level + 1))}
		</Box>
	);

	const restore = async () => {
		if (!activeContainers.length) await getActiveContainers();
		setDestinationDialogOpen(true);
	};

	useEffect(() => {
		if (open) getArchivedNodes();
	}, [getArchivedNodes, open]);

	return (
		<>
			<StyledDialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
				<DialogTitle>Unarchive deleted nodes</DialogTitle>
				{loading && (
					<DialogContent>
						<Center>
							<Loading />
						</Center>
					</DialogContent>
				)}
				{!loading && (
					<DialogContent>
						{archivedNodes.length === 0 && 'No archived items'}
						{archivedNodes.map(node => displayNode(node, 0))}
					</DialogContent>
				)}
				<StyledDialogActions>
					<Button onClick={restore} disabled={getSelectedNodes().length === 0}>
						Restore
					</Button>
					<Button onClick={onClose}>Close</Button>
				</StyledDialogActions>
			</StyledDialog>
			<StyledDialog
				open={destinationDialogOpen}
				onClose={closeDestinationDialog}
				maxWidth="sm"
				fullWidth>
				<DialogTitle>Pick the destination container</DialogTitle>
				{loading && (
					<DialogContent>
						<Center>
							<Loading />
						</Center>
					</DialogContent>
				)}
				{!loading && (
					<DialogContent>
						{displayDestinationNode(rootNode, 0)}
						{activeContainers.map(node => displayDestinationNode(node, 0))}
					</DialogContent>
				)}
				<StyledDialogActions>
					<Button onClick={closeDestinationDialog}>Close</Button>
				</StyledDialogActions>
			</StyledDialog>
		</>
	);
};

export default UnarchiveDialog;
