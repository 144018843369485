import { useContext, useState } from 'react';
import { searchModelNumber } from '../../../firebase';
import {
	FormNode,
	OCRModelNumberData,
	OCRModelNumberType,
} from '../../screen-components/ProjectUtilityFormV2/utils/types';
import { processModelNumberOCR } from '../../../firebase';
import { FormV2Context } from '../../screen-components/ProjectUtilityFormV2/context/FormV2Context.types';
import { UseModelSearchProps } from './OCRModelNumber.types';
import { SnackContext } from '../../../context/SnackProvider';

const useModelSearch = (props: UseModelSearchProps) => {
	const { setValue, value } = props;
	const [searching, setSearching] = useState(false);
	const { updateValue } = useContext(FormV2Context);
	const { setSnackbarProps } = useContext(SnackContext);
	const [isProcessing, setIsProcessing] = useState(false);
	const [model, setModel] = useState<OCRModelNumberData | undefined>(value?.data);

	const proccessImage = async (node: FormNode) => {
		setIsProcessing(true);
		try {
			const imageUrl = `${process.env.REACT_APP_firebase_bucket}/utility_forms_v2/${node.formId}/photos/${value.photo.name}`;
			const result = await processModelNumberOCR({ imageUrl });
			const { modelNumber, serialNumber, manufactureDate, manufacturer } =
				result.data as OCRModelNumberType;
			const newValue = {
				...value,
				modelNumber,
				serialNumber,
				manufactureDate,
				manufacturer,
			};
			setValue(newValue);
			await updateValue(node.id, newValue);
		} catch (error) {
			console.error('Failed to process image:', error);
		}
		setIsProcessing(false);
	};

	const searchModel = async (node: FormNode) => {
		if (value.modelNumber) {
			setSearching(true);
			try {
				const result = await searchDatabase(value.modelNumber);
				if (result) {
					setModel(result);
					const newValue = { ...value, data: result };
					setValue(newValue);
					await updateValue(node.id, newValue);
				}
			} catch (error) {
				console.error('Failed to process image:', error);
			}
			setSearching(false);
		}
	};

	const searchDatabase = async (modelNumber: string): Promise<any> => {
		try {
			setSearching(true);

			const cleanModelNumber = modelNumber.replaceAll('-', '');

			const response = await searchModelNumber({
				modelNumber: cleanModelNumber,
			});
			const data = (await response.data) as any;

			setSearching(false);

			if (data.data.length === 0) {
				setSnackbarProps({
					open: true,
					message: 'Model number not found',
					severity: 'error',
					hideDuration: 6000,
				});
				return {
					equipment: '',
					manufacturer: '',
					pdfUrl: '',
					data: [],
				};
			}

			const result = {
				equipment: data.data[0].equipment,
				manufacturer: data.data[0].manufacturer,
				pdfUrl: data.data[0].pdfUrl,
				data: data.data[0].data,
			};

			return result;
		} catch (e) {
			console.log(e);
			setSearching(false);
			return '';
		}
	};

	return {
		searching,
		searchModel,
		proccessImage,
		isProcessing,
		model,
	};
};

export default useModelSearch;
