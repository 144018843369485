import { useContext } from 'react';
import { FormV2Context } from '../../../../context/FormV2Context.types';
import NodeHeaderText from './NodeHeaderText';
import { OverviewHeaderContainer, OverviewText } from './CategoryHeader.styles';
import NodeActionButton from '../../../buttons/NodeActionButton/NodeActionButton';
import NodeAddChildButton from '../../../buttons/NodeAddChildButton/NodeAddChildButton';
import styled from 'styled-components';
import useNodeListener from '../../../../hooks/useNodeListener';
import CommentsButton from '../../../buttons/CommentsButton/CommentsButton';
import InfoButton from '../../../buttons/InfoButton/InfoButton';

/**
 * Returns the header for the currently selected root-level node, consisting of the
 * category's title and a plus button to add new items to that category
 */
export default function CategoryHeader() {
	const { currentNode } = useContext(FormV2Context);
	const nodeData = useNodeListener(currentNode?.id);

	return (
		<NodeHeaderContainer>
			{currentNode ? (
				<>
					<NodeHeaderText />
					<NodeAddChildButton node={currentNode} />
					<NodeActionButton node={nodeData || currentNode} />
					<CommentsButton node={currentNode} />
					<InfoButton node={currentNode} />
				</>
			) : (
				<OverviewHeaderContainer>
					<OverviewText>Overview</OverviewText>
				</OverviewHeaderContainer>
			)}
		</NodeHeaderContainer>
	);
}

const NodeHeaderContainer = styled.div`
	width: 100%;
	height: fit-content;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
`;
