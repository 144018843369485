import { useContext, useState, useEffect, useRef } from 'react';
import { cameraList } from '../../../utils';
import { ProjectContext } from '../../../context/ProjectProvider';

import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { CameraRow, Chip, ChipContainer } from './CameraChips';
import { YellowIconButton } from '../../../components/styled-components/buttons';

export default function MapCameraFilterButton() {
	const { setContractors } = useContext(ProjectContext);

	const [showCameraDropdown, setCameraDropDown] = useState(false);
	const [cameraFilter, setCameraFilter] = useState<string[]>([]);

	const contractorCache = useRef<any[]>([]);

	useEffect(() => {
		if (cameraFilter?.length)
			setContractors((prev: any) => {
				const filtered: any[] = [];
				const allContractors = [...prev, ...contractorCache.current];
				contractorCache.current = [];

				allContractors.forEach(c => {
					if (c.Cameras?.length > cameraFilter?.length) {
						if (c.Cameras.some((cam: string) => cameraFilter.includes(cam)))
							filtered.push(c);
						else contractorCache.current.push(c);
					} else if (c.Cameras?.length === cameraFilter?.length) {
						if (c.Cameras.every((cam: string) => cameraFilter.includes(cam)))
							filtered.push(c);
						else contractorCache.current.push(c);
					} else contractorCache.current.push(c);
				});
				// console.log('filtered:', filtered);
				// console.log('cache:', contractorCache.current);
				return filtered;
			});
		else if (contractorCache.current?.length) {
			// console.log(contractorCache.current?.length)
			setContractors((prev: any) => {
				return [...prev, ...contractorCache.current];
			});
			contractorCache.current = [];
		}
	}, [cameraFilter, setContractors]);

	return (
		<>
			<YellowIconButton
				size="large"
				title="Toggle contractor camera filters"
				onClick={() => setCameraDropDown(prev => !prev)}>
				<PhotoCameraIcon />
			</YellowIconButton>

			<Dialog open={showCameraDropdown} onClose={() => setCameraDropDown(false)}>
				<DialogTitle>Camera Filter</DialogTitle>
				<DialogContent>
					<CameraRow>
						<span>Cameras:</span>
						<ChipContainer>
							{cameraFilter.length ? (
								cameraFilter.map(camera => (
									<Chip
										type="button"
										key={camera}
										onClick={() => {
											setCameraFilter(prev => prev.filter(c => c !== camera));
										}}>
										{camera}
									</Chip>
								))
							) : (
								<span>None</span>
							)}
						</ChipContainer>
					</CameraRow>
					<ChipContainer>
						{cameraList.map(cam => {
							if (!cameraFilter.includes(cam)) {
								return (
									<Chip
										type="button"
										key={cam}
										onClick={() => {
											setCameraFilter(prev => [...prev, cam]);
										}}>
										{cam}
									</Chip>
								);
							}
							return null;
						})}
					</ChipContainer>
				</DialogContent>
				<DialogActions>
					<Button onClick={() => setCameraDropDown(false)}>Close</Button>
				</DialogActions>
			</Dialog>
		</>
	);
}
