import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	IconButton,
	MenuItem,
} from '@mui/material';
import styled from 'styled-components';
import SendIcon from '@mui/icons-material/Send';
import theme from '../../../../../styles/theme';

export const Comments = styled(Dialog)`
	& .MuiDialog-paper {
		min-width: 40vw;
		height: 600px;
	}

	& .MuiTypography-root-MuiDialogTitle-root + div.MuiDialogContent-root {
		padding: 0px;
	}
`;

export const CommentsTitle = styled(DialogTitle)`
	display: flex;
	flex-direction: column;
	padding: 15px;
`;

export const CommentsText = styled.span`
	font-size: 1.4rem;
	font-weight: 300;
	line-height: normal;
`;
export const ItemText = styled.span`
	font-size: 1rem;
	line-height: normal;
	font-weight: 300;
	color: #ffb310;
`;

export const CommentsContent = styled(DialogContent)`
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	height: 100%;
	overflow-y: scroll;
	& .MuiDialogContent-root {
		justify-content: flex-end;
		align-items: flex-start;
		padding: 0px;
	}
`;

export const MessageContainer = styled.div<{ hasComments: boolean }>`
	display: flex;
	flex-grow: 1;
	flex-direction: column;
	gap: 2px;
	width: 100%;
	justify-content: flex-end;
`;

export const CommentBubble = styled.div<{ isCurrentUser: boolean }>`
	background-color: ${props =>
		props.isCurrentUser ? theme.palette.grey[700] : theme.palette.grey[800]};
	padding: 10px;
	border-radius: 20px;
	min-width: 50%;
	max-width: 80%;
	margin-bottom: 8px;
	box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15), 0 1px 3px rgba(0, 0, 0, 0.2);
	align-self: ${props => (props.isCurrentUser ? 'flex-end' : 'flex-start')};

	&:not(:last-child) {
		margin-bottom: 12px;
	}
`;

export const CommentDetails = styled.div`
	display: flex;
	flex-direction: column;
	position: relative;
	margin-top: 8px;
	font-size: 0.75rem;
	color: ${theme.palette.grey[300]};
`;

export const StyledActions = styled(DialogActions)``;

export const StyledButton = styled(Button)`
	text-transform: none;
`;

export const DeleteIconButton = styled(IconButton)`
	position: absolute;
	right: 0;
	top: 50%;
	transform: translateY(-50%);
	color: rgba(0, 0, 0, 0.54); // Adjust color as needed
`;

export const InputContainer = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
`;

export const SubmitCommentIcon = styled(SendIcon)`
	color: #ffb310;
`;

export const SuggestionBox = styled.div`
	padding: 10px;
	cursor: pointer;
`;

export const UserMenuItem = styled(MenuItem)`
	display: flex;
	flex-direction: column;
	gap: 2px;
	justify-content: flex-start;
	align-items: flex-start;
`;

export const UsersName = styled.span`
	font-size: 16px;
`;

export const UsersEmail = styled.span`
	font-size: 14px;
	color: grey;
`;
