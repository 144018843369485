import { SnackContext } from '../../context/SnackProvider';
import { ProjectContext } from '../../context/ProjectProvider';
import { useContext, useRef, useState } from 'react';
import { DialogContentText, TextField, Autocomplete } from '@mui/material';
import LoadingScreen from './LoadingScreen';
import SendIcon from '@mui/icons-material/Send';
import styled from 'styled-components';
import Button from '@mui/material/Button';
import { ProjectData } from '../../types';

export default function EmailSharingBox({
	disabled,
	setDisabled,
	getSetMembers,
	setShowingDialog,
	handleUpdateList,
	projectData,
}: {
	disabled?: boolean;
	setDisabled?: React.Dispatch<React.SetStateAction<boolean>>;
	getSetMembers?: () => Promise<void>;
	setShowingDialog?: React.Dispatch<React.SetStateAction<boolean>>;
	handleUpdateList?: () => void;
	projectData?: ProjectData;
}) {
	const { handleAddMembers, cachedEmails } = useContext(ProjectContext);
	const { setSnackbarProps } = useContext(SnackContext);
	const inputRef = useRef<HTMLInputElement | null>(null);
	const [submitting, setSubmitting] = useState(false);
	const [emails, setEmails] = useState<string[]>([]);

	const checkValidEmails = (emailArr: string[]) => {
		const badEmails: string[] = [];
		emailArr.forEach(email => {
			if (
				!email.match(
					/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
				)
			) {
				badEmails.push(email);
			}
		});

		if (badEmails.length) {
			setSnackbarProps({
				open: true,
				message:
					badEmails.length > 1
						? `${badEmails.join(', ')} are not valid emails`
						: `${badEmails[0]} is not a valid email`,
				severity: 'warning',
			});
		}
		return !badEmails.length;
	};

	const handleClick = async () => {
		if (setShowingDialog) setShowingDialog(false);

		if (!emails.length && !inputRef.current?.value) {
			setSnackbarProps({
				open: true,
				message: 'No emails entered',
				severity: 'warning',
			});
		} else {
			const emailArr = emails.length
				? emails
				: inputRef.current?.value
				? [inputRef.current.value]
				: [];
			if (checkValidEmails(emailArr)) {
				setSubmitting(true);
				if (setDisabled) setDisabled(true);
				setSnackbarProps({
					open: true,
					severity: 'warning',
					message: 'Sharing user(s) to project(s)...',
				});

				try {
					await handleAddMembers(emailArr, projectData ? [projectData] : undefined);
					if (getSetMembers) await getSetMembers();
					if (handleUpdateList) handleUpdateList();
				} catch (err) {
					console.error(err);
					setSnackbarProps({
						open: true,
						severity: 'error',
						message: 'Error sharing user(s) to project...',
					});
				}

				setSubmitting(false);
				if (setDisabled) setDisabled(false);
				setEmails([]);
			}
		}
	};

	return (
		<Container>
			{!submitting ? (
				<>
					<DialogContentText>
						Enter an email address, then press the 'Enter' button to add members to
						invite:
					</DialogContentText>
					<InputContainer>
						<StyledAutocomplete
							freeSolo
							multiple
							options={cachedEmails}
							value={emails}
							onChange={(e, newValue) => {
								setEmails(newValue as string[]);
							}}
							renderInput={params => (
								<TextField {...params} label="Email Address(es)" inputRef={inputRef} />
							)}
							disabled={disabled}
						/>
						<SubmitButton onClick={handleClick} disabled={disabled}>
							<span>Send Invite(s)</span>
							<SendIcon />
						</SubmitButton>
					</InputContainer>
				</>
			) : (
				<LoadingScreen message="Sharing project..." textColor="black" />
			)}
		</Container>
	);
}

const Container = styled.div`
	background-color: white;
	border-radius: 8px;
	padding: 10px;

	display: flex;
	flex-direction: column;
	gap: 10px;
`;

const InputContainer = styled.div`
	display: flex;
	gap: 5px;
	justify-content: center;
	flex-wrap: wrap;
`;

const StyledAutocomplete = styled(Autocomplete)`
	flex-grow: 1;
`;

const SubmitButton = styled(Button)`
	color: black;
	background-color: #ffb310;
	&:hover {
		background-color: #ffb310;
	}
`;
