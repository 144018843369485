import { FormNode } from '../../../../utils/types';
import { NodeContainer } from '../../Sidebar.styles';
import NodeChip from '../NodeChip/NodeChip';
import { useContext, useEffect, useState } from 'react';
import { FormV2Context } from '../../../../context/FormV2Context.types';

/**
 * Returns a list of root-level nodes, of NodeType 'node' (i.e. Categories) or NodeType 'photos'
 *
 * This list is rendered in the Sidebar component
 */
export default function RootNodeList() {
	const { rootNodes, currentNode } = useContext(FormV2Context);
	const [items, setItems] = useState<FormNode[]>([]);

	useEffect(() => {
		setItems(rootNodes);
	}, [rootNodes]);

	return (
		<NodeContainer>
			{items.map((rootNode, index) => (
				<NodeChip
					rootNode={rootNode}
					key={rootNode.id}
					isExpanded={rootNode.id === currentNode?.id}
				/>
			))}
		</NodeContainer>
	);
}
