import { useContext } from 'react';
import { AuthContext } from '../context/AuthProvider';
import { Navigate, Outlet } from 'react-router-dom';

export default function UserManagementRoute({
	children,
}: {
	children?: React.ReactNode;
}) {
	const { user } = useContext(AuthContext);

	if (user?.isAdmin && user?.userPerms?.userManagement) {
		return children ? <>{children}</> : <Outlet />;
	} else {
		return <Navigate to="/404" replace />;
	}
}
