import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import styled from 'styled-components';
import FileDnd from '../../../../../reusable-components/FileDnd';
import { Refresh, NoteAdd } from '@mui/icons-material';
import { useCallback, useContext, useEffect, useState } from 'react';
import { getEnvelopesByProjectId } from '../../../../../../firebase';
import { DocusignEnvelope, DocusignEnvelopeStatus } from '../types';
import { ProjectContext } from '../../../../../../context/ProjectProvider';
import { AuthContext } from '../../../../../../context/AuthProvider';
import Envelope from './Envelope';
import { SnackContext } from '../../../../../../context/SnackProvider';
import LoadingScreen from '../../../../../reusable-components/LoadingScreen';
import CreateEnvelopeDialog from './CreateEnvelopeDialog';
import DocusignIframeDialog from './DocusignIframeDialog';
import { YellowIconButton } from '../../../../../styled-components/buttons';

type DocusignDialogProps = {
	showDialog: boolean;
	setShowDialog: React.Dispatch<React.SetStateAction<boolean>>;
};

export default function DocusignDialog({
	showDialog,
	setShowDialog,
}: DocusignDialogProps) {
	const { setSnackbarProps } = useContext(SnackContext);
	const { user } = useContext(AuthContext);
	const { project } = useContext(ProjectContext);

	const [envelopes, setEnvelopes] = useState<DocusignEnvelope[]>([]);
	const [selectedEnvelope, setSelectedEnvelope] = useState<{
		envelopeId: string;
		envelopeStatus: DocusignEnvelopeStatus;
		envelopeName: string;
	} | null>(null);

	const [isLoading, setIsLoading] = useState(false);
	const [showingCreateEnvelope, setShowingCreateEnvelope] = useState(false);

	const projectId = project?.id as string;

	const handleClose = () => {
		setShowDialog(false);
	};

	const getEnvelopes = useCallback(async () => {
		setIsLoading(true);
		try {
			const response = await getEnvelopesByProjectId({
				projectId: projectId,
			});
			if (response?.data && Array.isArray(response.data)) {
				const envelopesArr: DocusignEnvelope[] = [];
				response.data.forEach(envelope => {
					envelopesArr.push({
						...envelope,
						createdDateTime: new Date(envelope.createdDateTime._seconds * 1000),
						lastModifiedDateTime: new Date(envelope.lastModifiedDateTime._seconds * 1000),
						statusChangedDateTime: new Date(
							envelope.statusChangedDateTime._seconds * 1000
						),
					} as DocusignEnvelope);
				});
				setEnvelopes(envelopesArr);
			}
			setIsLoading(false);
		} catch (err) {
			console.error(err);
		}
	}, [projectId]);

	useEffect(() => {
		getEnvelopes();
	}, [getEnvelopes]);

	const selectEnvelope = (
		envelopeId: string,
		envelopeStatus: DocusignEnvelopeStatus,
		envelopeName: string
	) => {
		if (!user?.isAdmin && envelopeStatus === 'correct') {
			// If client selects envelope with 'correct' status, do not open iframe
			setSnackbarProps({
				open: true,
				severity: 'info',
				message: `${envelopeName} is being edited right now. Please check again at a later time.`,
			});
		} else {
			setSelectedEnvelope({ envelopeId, envelopeStatus, envelopeName });
			// setShowingIframe(true);
		}
	};

	return (
		<>
			<Dialog open={showDialog} onClose={handleClose}>
				<TitleContainer>
					<span>{'Docusign Envelopes'}</span>

					<TitleButtonContainer>
						<YellowIconButton
							onClick={getEnvelopes}
							title="Refresh Docusign envelopes list">
							<Refresh />
						</YellowIconButton>

						{user?.isAdmin ? (
							<YellowIconButton
								onClick={() => setShowingCreateEnvelope(true)}
								title="Create a new Docusign envelope">
								<NoteAdd />
							</YellowIconButton>
						) : null}
					</TitleButtonContainer>
				</TitleContainer>

				<ContentContainer>
					<ProposalDocuments>
						<span>Proposal Documents:</span>
						<FileDnd field="proposal" darkMode={false} />
					</ProposalDocuments>

					<EnvelopeListContainer>
						{!isLoading ? (
							envelopes.length ? (
								envelopes.map((envelope, i) => (
									<Envelope envelope={envelope} selectEnvelope={selectEnvelope} key={i} />
								))
							) : (
								<h3 style={{ margin: 'auto' }}>No envelopes to display...</h3>
							)
						) : (
							<EnvelopeLoadingScreen message="Loading envelopes..." textColor="black" />
						)}
					</EnvelopeListContainer>
				</ContentContainer>

				<DialogActions>
					<Button onClick={handleClose}>Close</Button>
				</DialogActions>
			</Dialog>

			<CreateEnvelopeDialog
				showing={showingCreateEnvelope}
				setShowing={setShowingCreateEnvelope}
			/>

			<DocusignIframeDialog
				selectedEnvelope={selectedEnvelope}
				handleClose={() => setSelectedEnvelope(null)}
			/>
		</>
	);
}

const TitleContainer = styled(DialogTitle)`
	display: flex;
	align-items: center;
`;

const TitleButtonContainer = styled.div`
	display: flex;
`;

const ContentContainer = styled(DialogContent)`
	display: flex;
	flex-direction: column;
	gap: 10px;

	width: 600px;
	max-width: 100%;
	padding: 10px 20px !important;
`;

const ProposalDocuments = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 10px;
	padding: 10px;

	border-bottom: solid 3px black;
`;

const EnvelopeListContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 10px;
	min-height: 300px;
`;

const EnvelopeLoadingScreen = styled(LoadingScreen)`
	margin: auto;
`;
