import { Menu } from '@mui/material';
import { styled } from '@mui/system';
import theme from '../../../styles/theme';

const StyledMenu = styled(Menu)`
	& .MuiMenu-paper {
		background-color: ${theme.palette.grey[900]};
		color: ${theme.palette.common.white};
	}
`;

export default StyledMenu;
