import { Row } from 'react-table';
import { Button } from '@mui/material';
import { UserPerms, UserPermsMap, UserWithData } from '../../../../types';
import { UserTag, UserTagTitleMap } from '../../../../types/project-roles';
import { CustomColumn } from '../../../reusable-components/DataTable/CustomColumn';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import UserStatusCell from './UserStatusCell';
import UserTypeCell from './UserRoleCell';

export default function getUserColumns(
	setUserPermsEdit: React.Dispatch<React.SetStateAction<UserWithData | null>>,
	setUserTagEdit: React.Dispatch<React.SetStateAction<UserWithData | null>>
) {
	const columns: CustomColumn<UserWithData>[] = [
		{
			Header: 'Account Type',
			Cell: ({ row }: { row: Row<UserWithData> }) => {
				return <UserTypeCell row={row} />;
			},
			accessor: (user: UserWithData) => {
				const types: string[] = [];
				if (user.isAdmin) types.push('Admin');
				if (user.isContractor) types.push('Contractor');
				if (!user.isAdmin && !user.isContractor) types.push('Client');
				return types.join(', ');
			},
			width: '120px',
			align: 'center',
		},
		{
			Header: 'Status',
			Cell: ({ row }: { row: Row<UserWithData> }) => {
				return <UserStatusCell row={row} />;
			},
			accessor: (user: UserWithData) => {
				return user.disabled ? 'disabled' : 'active';
			},
			width: '100px',
			align: 'center',
		},
		{
			Header: 'Name',
			Cell: ({ row }: { row: Row<UserWithData> }) => {
				return (
					<StyledLink
						to={`/neural-net/${row.original.id}`}
						title={`Navigate to ${row.original.fullName}`}>
						{row.original.fullName || 'Unnamed User'}
					</StyledLink>
				);
			},
			accessor: (user: UserWithData) => user.fullName.toLowerCase(),
			width: '200px',
			align: 'center',
		},
		{
			Header: 'Email',
			Cell: ({ row }: { row: Row<UserWithData> }) => {
				return <span>{row.original.email}</span>;
			},
			accessor: (user: UserWithData) => user.email.toLowerCase(),
			width: '300px',
			align: 'center',
		},
		{
			Header: 'Permissions',
			Cell: ({ row }: { row: Row<UserWithData> }) => {
				const perms: UserPerms[] = [];
				for (const perm in row.original.userPerms) {
					if (row.original.userPerms[perm as UserPerms]) {
						perms.push(perm as UserPerms);
					}
				}

				return (
					<PermsButton onClick={() => setUserPermsEdit(row.original)}>
						{perms.length ? (
							// perms.map((perm, i) => <Chip label={UserPermsMap[perm]} key={i} />)
							<span>{perms.map(perm => UserPermsMap[perm]).join(', ')}</span>
						) : (
							<span>None</span>
						)}
					</PermsButton>
				);
			},
			accessor: (user: UserWithData) => {
				const perms: UserPerms[] = [];
				for (const perm in user.userPerms) {
					if (user.userPerms[perm as UserPerms]) {
						perms.push(perm as UserPerms);
					}
				}
				return perms.join(', ');
			},
			width: '300px',

			align: 'center',
		},
		{
			Header: 'User Tags',
			Cell: ({ row }: { row: Row<UserWithData> }) => {
				const tags: UserTag[] = [];
				for (const tag in row.original.userTags) {
					if (row.original.userTags[tag as UserTag]) {
						tags.push(tag as UserTag);
					}
				}

				return (
					<TagsButton onClick={() => setUserTagEdit(row.original)}>
						{tags.length ? (
							<span>{tags.map(tag => UserTagTitleMap[tag]).join(', ')}</span>
						) : (
							<span>None</span>
						)}
					</TagsButton>
				);
			},
			accessor: (user: UserWithData) => {
				const tags: UserTag[] = [];
				for (const tag in user.userTags) {
					if (user.userTags[tag as UserTag]) {
						tags.push(tag as UserTag);
					}
				}
				return tags.join(', ');
			},
			width: '300px',
			align: 'center',
		},
	];
	return columns;
}

const PermsButton = styled(Button)`
	padding: 0;
	margin: 0;
	text-transform: none;
`;

const TagsButton = styled(Button)`
	padding: 0;
	margin: 0;
	text-transform: none;
`;

const StyledLink = styled(Link)`
	text-decoration: none;
	color: white;
	max-height: 100%;
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	transition: 0.2s;

	&:hover {
		text-decoration: underline;
		color: #ffb310;
	}
`;
