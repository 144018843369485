import {
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	Button,
	TextField,
	Box,
} from '@mui/material';
import { FC } from 'react';
import useTemplates from './useTemplates';
import GrayButton from './GrayButton';
import { FormTemplate } from '../ProjectUtilityFormV2/utils/types';

type PickTemplateDialogProps = {
	handleClose: () => void;
	handlePickTemplate: (
		templateId: string,
		templateName: string,
		maxLevelNode: number
	) => void;
	open: boolean;
	isPartial?: boolean;
};

const PickTemplateDialog: FC<React.PropsWithChildren<PickTemplateDialogProps>> = ({
	handleClose,
	handlePickTemplate,
	open,
	isPartial = false,
}) => {
	const { addToHistory, history, onChangeQuery, templates } = useTemplates(
		open,
		isPartial
	);

	const getMaxLevelNode = (template: FormTemplate) => {
		let maxLevelNode = 0;
		template.nodes.forEach(node => {
			if (node.type === 'node' && node.level !== undefined) {
				const nodeLevel = node.level + 1;
				if (nodeLevel > maxLevelNode) {
					maxLevelNode = nodeLevel;
				}
			}
		});
		return maxLevelNode;
	};

	return (
		<Dialog open={open} onClose={handleClose}>
			<DialogTitle>Search for a template (case sensitive)</DialogTitle>
			<DialogContent style={{ alignItems: 'center', paddingBottom: 30 }}>
				<Box
					height={360}
					width="100%"
					alignItems="center"
					display="flex"
					flexDirection="column">
					<TextField
						label="Search for a template"
						name="Template"
						variant="outlined"
						onChange={onChangeQuery}
						style={{ width: 300, margin: 20 }}
					/>
					{templates?.map(template => (
						<GrayButton
							key={template.id}
							color="primary"
							onClick={() => {
								const maxLevelNode = getMaxLevelNode(template);
								handlePickTemplate(template.id, template.name, maxLevelNode);
								if (!isPartial) addToHistory(template); // only set history if not partial
							}}>
							{template.name}
						</GrayButton>
					))}
				</Box>
				{isPartial === false && history?.length > 0 && (
					<Box style={{ marginTop: 30 }}>Last used templates</Box>
				)}
				{isPartial === false &&
					history?.map(template => (
						<GrayButton
							key={template.id}
							color="primary"
							onClick={() => {
								const maxLevelNode = getMaxLevelNode(template);
								handlePickTemplate(template.id, template.name, maxLevelNode);
							}}>
							{template.name}
						</GrayButton>
					))}
			</DialogContent>
			<DialogActions>
				<Button onClick={handleClose}>Cancel</Button>
			</DialogActions>
		</Dialog>
	);
};

export default PickTemplateDialog;
