import { debounce } from 'lodash';
import { useCallback, useContext, useEffect, useState } from 'react';
import {
	FormNode,
	OCRModelNumberType,
} from '../../screen-components/ProjectUtilityFormV2/utils/types';
import { FormV2Context } from '../../screen-components/ProjectUtilityFormV2/context/FormV2Context.types';

const debouncedSave = debounce(async (save: () => void) => save(), 200);

const useTextFields = (node: FormNode) => {
	const nodeValue = node.value as OCRModelNumberType;
	const { updateValue } = useContext(FormV2Context);

	const [isModelFocused, setIsModelFocused] = useState(false);
	const [isSerialFocused, setIsSerialFocused] = useState(false);
	// const [isManufacturerFocused, setIsManufacturerFocused] = useState(false);
	// const [isManufactureDateFocused, setIsManufactureDateFocused] = useState(false);
	const [value, setValue] = useState<OCRModelNumberType>(nodeValue);

	const debounceSave = useCallback(
		(value: OCRModelNumberType) => {
			debouncedSave.cancel();
			debouncedSave(() => updateValue(node.id, value));
		},
		[node.id, updateValue]
	);

	const onModelFocus = () => {
		setIsModelFocused(true);
	};

	const onModelBlur = () => {
		setIsModelFocused(false);
	};

	const onSerialFocus = () => {
		setIsSerialFocused(true);
	};

	const onSerialBlur = () => {
		setIsSerialFocused(false);
	};

	const onManufacturerFocus = () => {
		setIsSerialFocused(true);
	};

	const onManufacturerBlur = () => {
		setIsSerialFocused(false);
	};

	const onManufactureDateFocus = () => {
		setIsSerialFocused(true);
	};

	const onManufactureDateBlur = () => {
		setIsSerialFocused(false);
	};

	const handleModelInputChange = (
		event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
	) => {
		setValue(current => ({ ...current, modelNumber: event.target.value }));
	};

	const handleSerialInputChange = (
		event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
	) => {
		setValue(current => ({ ...current, serialNumber: event.target.value }));
	};

	const handleManufacturerInputChange = (
		event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
	) => {
		setValue(current => ({ ...current, manufacturer: event.target.value }));
	};

	const handleManufactureDateInputChange = (
		event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
	) => {
		setValue(current => ({ ...current, manufactureDate: event.target.value }));
	};

	useEffect(() => {
		if (!isModelFocused && !isSerialFocused && node.value)
			setValue(node.value as OCRModelNumberType);
	}, [node.value, isModelFocused, isSerialFocused]);

	useEffect(() => {
		if (isModelFocused || isSerialFocused) debounceSave(value);
	}, [value, isModelFocused, isSerialFocused, debounceSave]);

	return {
		value,
		handleManufactureDateInputChange,
		handleManufacturerInputChange,
		handleModelInputChange,
		handleSerialInputChange,
		onModelFocus,
		onModelBlur,
		onSerialFocus,
		onSerialBlur,
		onManufacturerFocus,
		onManufacturerBlur,
		onManufactureDateFocus,
		onManufactureDateBlur,
		setValue,
	};
};

export default useTextFields;
