import { useContext, useRef, useState } from 'react';
import Button from '@mui/material/Button';
// import PlacesAutocomplete from '../../../components/reusable-components/PlacesAutocomplete';
import {
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	TextField,
} from '@mui/material';
import { ProjectContext } from '../../../context/ProjectProvider';
import { LoadingContext } from '../../../context/LoadingProvider';
import { SnackContext } from '../../../context/SnackProvider';
import { db, storage } from '../../../firebase';
import { addDoc, collection } from 'firebase/firestore';

import { ref, uploadBytesResumable } from 'firebase/storage';
import CameraChips from './CameraChips';
import LoadingScreen from '../../../components/reusable-components/LoadingScreen';
import styled from 'styled-components';
import PlacesAutocompleteWrapper from '../../../components/reusable-components/PlacesAutocomplete/PlacesAutocompleteWrapper';
import { getEntries } from '../../../utils/typescript-utils';

export default function AddContractorForm({
	showingDialog,
	setShowingDialog,
}: {
	showingDialog: boolean;
	setShowingDialog: React.Dispatch<React.SetStateAction<boolean>>;
}) {
	const { loading, setLoading } = useContext(LoadingContext);
	const { setContractors } = useContext(ProjectContext);
	const filesRef = useRef<File[] | null>(null);

	const emptyFields = {
		Name: '',
		Email: '',
		Address: '',
		Phone: '',
		Website: '',
		Cameras: [] as string[],
		files: [] as string[],
		Notes: '',
	};

	const [formFolderName, setFormFolderName] = useState(emptyFields);
	const { setSnackbarProps } = useContext(SnackContext);

	const handleClose = () => {
		setFormFolderName(emptyFields);
		filesRef.current = null;
		setShowingDialog(false);
	};
	const handleAddContractor = (formValues: { [key: string]: any }) => {
		return new Promise((resolve, reject) => {
			addDoc(collection(db, 'matterport_directory'), formValues)
				.then(newContractor => {
					resolve(newContractor);
				})
				.catch(e => {
					console.log(e);
					reject(e);
				});
		});
	};
	const [progress, setProgress] = useState(0);
	const handleUpload = (contractorId: string) => {
		return new Promise<void>((resolve, reject) => {
			if (!filesRef.current || !filesRef.current.length) reject();

			const files = [...(filesRef.current || [])];
			const filesPath = `contractors/${contractorId}/`;
			setLoading(true);
			let filesUploaded = 0;
			files.forEach(file => {
				const storageRef = ref(storage, filesPath + file.name);
				const uploadTask = uploadBytesResumable(storageRef, file);
				let prevProgress = 0;
				uploadTask.on(
					'state_changed',
					// Upload progress/status
					snapshot => {
						const progress =
							(snapshot.bytesTransferred / snapshot.totalBytes) * (100 / files.length) -
							prevProgress;
						prevProgress += progress;
						setProgress(prev => {
							return prev + progress;
						});
					},
					// Upload error
					error => {
						console.log(error);
						reject(error);
					},
					// Upload completion callback
					() => {
						++filesUploaded;
						if (filesUploaded === files.length) {
							setLoading(false);
							setProgress(0);
							resolve();
						}
					}
				);
			});
		});
	};
	const handleUpdateUI = (
		contractorData: { [key: string]: any },
		contractorId: string
	) => {
		setContractors(prev => [
			...(prev || []),
			{
				...contractorData,
				id: contractorId,
			},
		]);
	};

	const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();

		for (const [key, value] of getEntries(formFolderName)) {
			if (typeof value === 'string') {
				setFormFolderName(prev => ({
					...prev,
					[key]: value.trim(),
				}));
			}
		}
		const { Name } = formFolderName;
		const contractorData = { ...formFolderName };

		const contractor = (await handleAddContractor(contractorData)) as any;
		if (contractorData.files.length) await handleUpload(contractor.id);
		handleUpdateUI(contractorData, contractor.id);

		handleClose();
		setSnackbarProps({
			open: true,
			message: `${Name} has been added to contractor directory`,
			severity: 'success',
		});
	};

	const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
		const value = e.target.value;
		setFormFolderName({
			...formFolderName,
			[e.target.name]: value,
		});
	};

	return (
		<Dialog open={showingDialog} onClose={handleClose}>
			<DialogTitle>Add A New Contractor</DialogTitle>
			{!loading ? (
				<form onSubmit={handleSubmit}>
					<DialogContent>
						<TextField
							label="Name"
							id="Name"
							name="Name"
							value={formFolderName.Name}
							onChange={handleChange}
							required
							fullWidth
							variant="standard"
						/>
						<TextField
							label="Email"
							id="Email"
							name="Email"
							value={formFolderName.Email}
							onChange={handleChange}
							fullWidth
							variant="standard"
						/>
						<PlacesAutocompleteWrapper
							formState={formFolderName}
							setFormState={
								setFormFolderName as React.Dispatch<
									React.SetStateAction<{ [key: string]: any }>
								>
							}
							addressField="Address"
							required
						/>
						<TextField
							label="Phone"
							id="Phone"
							name="Phone"
							value={formFolderName.Phone}
							onChange={handleChange}
							fullWidth
							variant="standard"
						/>
						<TextField
							label="Website"
							id="Website"
							name="Website"
							value={formFolderName.Website}
							onChange={handleChange}
							fullWidth
							variant="standard"
						/>
						<TextField
							label="Contractor Notes"
							name="Notes"
							value={formFolderName.Notes}
							onChange={handleChange}
							multiline
							fullWidth
							maxRows={5}
							variant="standard"
						/>
						<CameraChips
							form={formFolderName}
							cameraField="Cameras"
							setForm={setFormFolderName}
						/>
						<AttachmentRow>
							<label htmlFor="contractorAttachment">Attachments:</label>
							<input
								type="file"
								id="contractorAttachment"
								multiple
								onChange={e => {
									const fileList = [...(e.target.files || [])];
									const files = [...fileList].map(file => file.name);
									setFormFolderName({
										...formFolderName,
										files: files,
									});
									filesRef.current = fileList;
								}}
							/>
						</AttachmentRow>
					</DialogContent>
					<DialogActions>
						<Button onClick={handleClose} color="primary">
							Cancel
						</Button>
						<Button type="submit" color="primary">
							Add Contractor
						</Button>
					</DialogActions>
				</form>
			) : (
				<DialogContent>
					<LoadingScreen message={`${progress.toFixed(2)}% uploaded...`} />
				</DialogContent>
			)}
		</Dialog>
	);
}

const AttachmentRow = styled.div`
	display: flex;
	justify-content: space-between;
`;
