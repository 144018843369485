import styled from 'styled-components';

export const LoadingContainer = styled.div<{ height: number }>`
	align-items: 'center';
	justify-content: 'center';
	display: 'flex';
	height: ${({ height }) => height}px;
`;

export const LoadingText = styled.span`
	color: #ffb310;
	margin-top: 20;
`;
