import { SetStateAction, createContext, useState } from 'react';
import { ContainerProps } from '../types';

export type ModelerContextType = {
	modelersQuery: string;
	setModelersQuery: React.Dispatch<SetStateAction<string>>;
};

export const ModelerContext = createContext<ModelerContextType>({} as ModelerContextType);

export const ModelerProvider = ({ children }: ContainerProps) => {
	const [modelersQuery, setModelersQuery] = useState('');

	return (
		<ModelerContext.Provider value={{ modelersQuery, setModelersQuery }}>
			{children}
		</ModelerContext.Provider>
	);
};
