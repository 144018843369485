import { CSSProperties } from 'react';

export const completedColor = '#ade19b';
export const progressColor = '#fbd988';
export const reviewColor = '#ed5454';

export const getFlagStyle = (
	flag?: string,
	defaultBorder?: CSSProperties
): CSSProperties => {
	switch (flag) {
		case 'completed':
			return { border: `1px solid ${completedColor}` };
		case 'progress':
			return { border: `1px solid ${progressColor}` };
		case 'review':
			return { border: `1px solid ${reviewColor}` };
		default:
			return defaultBorder ?? { border: 'none' };
	}
};

export const getFlagColor = (flag?: string, defaultColor?: string): string => {
	switch (flag) {
		case 'completed':
			return completedColor;
		case 'progress':
			return progressColor;
		case 'review':
			return reviewColor;
		default:
			return defaultColor ?? 'transparent';
	}
};
