import { FC, useContext, useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { SnackContext } from '../../../context/SnackProvider';
import LoadingScreen from '../../reusable-components/LoadingScreen';
import { ReportData } from '../../../types/reports';
import { deleteDoc, doc } from 'firebase/firestore';
import { db } from '../../../firebase';

type DeleteReportDialogProps = {
	open: boolean;
	report: ReportData;
	setOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

const DeleteReportDialog: FC<DeleteReportDialogProps> = ({ open, report, setOpen }) => {
	const { setSnackbarProps } = useContext(SnackContext);
	const [saving, setSaving] = useState(false);

	const handleClose = () => setOpen(false);

	const handleSubmit = async () => {
		if (!report.id) return;

		setSaving(true);
		try {
			await deleteDoc(doc(db, 'reports', report.id));
			setSnackbarProps({
				open: true,
				severity: 'success',
				message: `Successfully deleted analytics.`,
			});
		} catch (err) {
			console.error(err);
			setSnackbarProps({
				open: true,
				severity: 'error',
				message: `Failed to delete analytics.`,
			});
		}
		setSaving(false);
		handleClose();
	};

	return (
		<Dialog open={open} onClose={handleClose} maxWidth="xs" fullWidth>
			<DialogTitle>Delete analytics</DialogTitle>
			{!saving ? (
				<DialogContent>
					<p>
						Are you sure you want to delete{' '}
						{report.name || report.createdAt?.toDate().toLocaleString()}?
					</p>
				</DialogContent>
			) : (
				<DialogContent>
					<LoadingScreen message="Deleting analytics..." textColor="black" size={50} />
				</DialogContent>
			)}

			<DialogActions>
				{!saving ? (
					<>
						<Button onClick={handleClose}>Cancel</Button>
						<Button onClick={handleSubmit}>Delete</Button>
					</>
				) : null}
			</DialogActions>
		</Dialog>
	);
};

export default DeleteReportDialog;
