import { useContext, useState, useEffect, useCallback, useRef } from 'react';
import {
	useFilters,
	useGlobalFilter,
	usePagination,
	useSortBy,
	useTable,
	useRowSelect,
	useFlexLayout,
	Column,
} from 'react-table';
import {
	Table,
	BodyCell,
	BottomRow,
	Pagination,
	PaginationButton,
} from '../../styled-components/styledReactTable';
import { AuthContext } from '../../../context/AuthProvider';
import { CustomColumn } from './CustomColumn';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import styled from 'styled-components';
import TableSearchbar from './TableSearchbar';
import { UserTag } from '../../../types/project-roles';
import { UserWithData } from '../../../types';
import theme from '../../../styles/theme';
import Toolbar from './Toolbar';
import useSelecting from './useSelecting';
import { ColumnCheckbox } from '../../screen-components/TeamPage/TeamPageColumns';
import { useNavigate } from 'react-router-dom';

type DataTableProps = {
	data: any[];
	columns: Column<any>[];
	searchbar?: boolean;
	onAdd?: () => void;
	onDelete?: (selectedIds: string[]) => void;
	name: string;
	queryFields?: string[];
	showButton?: boolean;
	selectedTags?: UserTag[];
	onTagFilterChange?: (tag: UserTag) => void;
	additionalActions?: React.ReactNode;
	showFilterButton?: boolean; //to show the 'Filter by...' button
	onFilterSelect?: (filterType: string) => void; //behavior when a filter selection is made
	disabledField?: string;
	textNoSearchQueryResult?: string;
	textEmptyResult?: string;
};

const DataTable = ({
	data,
	columns,
	onAdd,
	onDelete,
	name,
	queryFields,
	showButton = true,
	selectedTags,
	onTagFilterChange,
	additionalActions,
	showFilterButton,
	onFilterSelect,
	disabledField,
	textNoSearchQueryResult,
	textEmptyResult,
}: DataTableProps) => {
	const { user } = useContext(AuthContext);
	const navigate = useNavigate();
	const [controlledPageIndex, setControlledPageIndex] = useState(0);
	const [searchQuery, setSearchQuery] = useState('');
	const [filteredData, setFilteredData] = useState<any[]>([]);

	const {
		addSelectedId,
		clearSelectedIds,
		isSelecting,
		removeSelectedId,
		selectedIds,
		setIsSelecting,
	} = useSelecting();

	const filterByTags = useCallback(() => {
		return data.filter(
			(userWithData: UserWithData) =>
				selectedTags?.length === 0 ||
				selectedTags?.every(tag => userWithData.userTags && userWithData.userTags[tag])
		);
	}, [data, selectedTags]);

	const filterData = useCallback(
		(query: string) => {
			if (Array.isArray(data)) {
				let filtered = showFilterButton ? filterByTags() : data;
				// Filters data by the given 'queryFields' with the query.
				if (query && queryFields?.length) {
					filtered = filtered.filter(item => {
						for (const field of queryFields) {
							if (
								typeof item[field] === 'string' &&
								item[field].toLowerCase().includes(query.toLowerCase())
							) {
								return true;
							}
						}
						return false;
					});
				}
				setFilteredData(filtered);
			}
		},
		[data, showFilterButton, filterByTags, queryFields]
	);

	useEffect(() => {
		filterData(searchQuery);
	}, [filterData, searchQuery, selectedTags]);

	const clearUsersQuery = () => {
		setSearchQuery('');
	};

	const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const value = e.currentTarget.value;
		setSearchQuery(value);
	};

	const {
		getTableProps,
		// getTableBodyProps,
		headerGroups,
		prepareRow,
		page,
		canPreviousPage,
		canNextPage,
		pageCount,
		nextPage,
		previousPage,
		setPageSize,
		state: { pageIndex, pageSize },
	} = useTable(
		{
			data: filteredData,
			columns,
			initialState: {
				pageSize: 10,
				pageIndex: controlledPageIndex,
				hiddenColumns: user?.isContractor
					? ['Sub-Total', 'Internal Notes', 'Invoice Link', 'Signed Proposal']
					: [],
			},
		},
		useFilters,
		useGlobalFilter,
		useSortBy,
		usePagination,
		useRowSelect,
		useFlexLayout
	);

	const pageMin = () => pageIndex * pageSize + 1;
	const pageMax = () =>
		pageIndex + 1 === pageCount ? filteredData.length : (pageIndex + 1) * pageSize;
	const pageRows = () => filteredData.length;

	const configurePageSize = (size: number) => {
		// Calculates new page index based on top row after resizing page size
		const newPageIndex = Math.floor((pageIndex * pageSize) / size);
		setControlledPageIndex(newPageIndex);
		setPageSize(size);
	};

	const headerRef = useRef<HTMLDivElement | null>(null);
	const bodyRef = useRef<HTMLDivElement | null>(null);

	// Makes header row scroll at same rate as body
	useEffect(() => {
		const scrollFunc = (e: Event) => {
			if (e.target === bodyRef.current && bodyRef.current && headerRef.current) {
				headerRef.current.scrollLeft = bodyRef.current.scrollLeft;
			}
		};
		document.addEventListener('scroll', scrollFunc, true);
		return () => document.removeEventListener('scroll', scrollFunc);
	}, []);

	// Keeps table from displaying a blank page when there are no more projects on that page
	// e.g. when projectStatus is changed
	useEffect(() => {
		// Calculates the index of the top row of the current page + 1
		const topRowIndex = pageIndex * pageSize + 1;
		// Goes to previous page if there is no project to display on the top row and the pageIndex !== 0
		if (topRowIndex > filteredData.length && pageIndex) {
			setControlledPageIndex(prev => prev - 1);
			previousPage();
		}
	}, [filteredData.length, pageCount, pageIndex, pageSize, previousPage]);

	const disabledBodyRow = (data: any) => {
		if (typeof disabledField === 'string' && disabledField in data)
			return data[disabledField];
		return false;
	};

	const selectAll = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
		if (checked) {
			data.forEach((row: any) => {
				addSelectedId(row.id);
			});
		} else {
			clearSelectedIds();
		}
	};

	return (
		<TableContainer>
			<ActionBar>
				<TableSearchbar
					query={searchQuery}
					handleInputChange={handleInputChange}
					clearQuery={clearUsersQuery}
					name={name}
					showFilterButton={showFilterButton}
					onFilterSelect={onFilterSelect}
					toolbar={
						showButton && (
							<Toolbar
								isSelecting={isSelecting}
								setIsSelecting={setIsSelecting}
								selectedIds={selectedIds}
								onAdd={onAdd}
								onDelete={
									onDelete
										? () => {
												onDelete?.([...selectedIds]);
												clearSelectedIds();
												setIsSelecting(false);
										  }
										: undefined
								}
							/>
						)
					}
				/>
			</ActionBar>
			{additionalActions}
			<TableContent>
				{filteredData.length > 0 ? (
					<TableWrapper
						style={{
							border: `2px solid ${theme.palette.divider}`,
						}}>
						{headerGroups.map((headerGroup, idx) => (
							<TableHeaderRow
								{...headerGroup.getHeaderGroupProps({
									style: {
										minWidth: '100%',
										backgroundColor: theme.palette.secondary.dark,
										boxShadow: 'none',
										height: '42px',
									},
								})}
								ref={headerRef}
								key={idx}>
								{headerGroup.headers.map((column, idx) => {
									const customColumn = column as CustomColumn<any>;
									const alignment = customColumn.align || 'flex-start';
									const isCheckbox = column.id === 'checkbox';
									const props = isCheckbox
										? undefined
										: column.getHeaderProps(column.getSortByToggleProps());

									return (
										<HeaderCell
											style={{ width: column.width }}
											align={alignment}
											key={idx}>
											<TableHeaderCellInner
												isSorted={column.isSorted}
												canSort={!isCheckbox && column.canSort}
												{...props}
												key={idx}
												title={
													!isCheckbox && column.canSort
														? `Sort table by ${column.Header}`
														: ''
												}>
												{!isCheckbox && column.render('Header')}
												{!isCheckbox && column.isSorted ? (
													column.isSortedDesc ? (
														<ExpandMoreIcon />
													) : (
														<ExpandLessIcon />
													)
												) : null}
												{isCheckbox && isSelecting && (
													<ColumnCheckbox onChange={selectAll} />
												)}
											</TableHeaderCellInner>
										</HeaderCell>
									);
								})}
							</TableHeaderRow>
						))}
						<TableBodyTable {...getTableProps()} id="table-body" ref={bodyRef}>
							{page.map((row, idx) => {
								prepareRow(row);
								return (
									<BodyRow
										{...row.getRowProps({ key: idx })}
										key={idx}
										disabled={disabledBodyRow(row.original)}
										onClick={() =>
											name === 'project(s)' &&
											navigate(`/projects/${(row.original as { id: string }).id}`)
										}
										project={name === 'project(s)' ? 1 : 0}>
										{row.cells.map((cell: any) => {
											const alignment = cell.column.align || 'flex-start';

											return (
												<TableBodyCell
													{...cell.getCellProps()}
													style={{ width: cell.column.width }}
													key={cell.column.id}
													align={alignment}>
													{cell.render('Cell', {
														addSelectedId,
														isSelecting,
														removeSelectedId,
														selectedIds,
														setIsSelecting,
													})}
												</TableBodyCell>
											);
										})}
									</BodyRow>
								);
							})}
						</TableBodyTable>

						<TableBottomRow>
							<Pagination>
								<div>Rows per page:</div>
								<select onChange={e => configurePageSize(Number(e.target.value))}>
									{[10, 25, 50, 100].map(pageSize => (
										<option key={pageSize} value={pageSize}>
											{pageSize}
										</option>
									))}
								</select>
								<div>
									{page.length > 0 && `${pageMin()}–${pageMax()} of ${pageRows()}`}
								</div>
								<PaginationButton
									disabled={!canPreviousPage}
									onClick={() => {
										if (canPreviousPage) setControlledPageIndex(prev => prev - 1);
										previousPage();
									}}>
									<ChevronLeftIcon />
								</PaginationButton>
								<PaginationButton
									disabled={!canNextPage}
									onClick={() => {
										if (canNextPage) setControlledPageIndex(prev => prev + 1);
										nextPage();
									}}>
									<ChevronRightIcon />
								</PaginationButton>
							</Pagination>
						</TableBottomRow>
					</TableWrapper>
				) : (
					<TextInformation>
						{searchQuery ? textNoSearchQueryResult : textEmptyResult}
					</TextInformation>
				)}
			</TableContent>
		</TableContainer>
	);
};

export default DataTable;

const TableContainer = styled.div`
	max-height: 100%;
	max-width: 100%;
`;

export const TableWrapper = styled.div`
	background-color: #0e0e0f;
	display: block;
	max-width: 100%;
	width: 100%;
	overflow: hidden;
	border-radius: 20px;
	margin: 10px auto;
`;

export const TableContent = styled.div`
	width: 100%;
	padding: 0 24px 0 24px;
	max-width: 100%;
	margin: 0 auto;

	@media (max-width: 768px) {
		-webkit-overflow-scrolling: touch; // Improves scrolling on iOS devices
		width: 90%;
	}
`;

export const TableHeaderCellInner = styled.div<{
	isSorted: boolean;
	canSort: boolean;
}>`
	color: ${({ isSorted }) => (isSorted ? '#ffb310' : '#f6f6f6')};
	font-weight: normal;
	font-size: 16px;
	display: flex;
	align-items: center;
	transition: 0.2s;
	justify-content: flex-start;

	&:hover {
		color: ${({ canSort }) => (canSort ? '#ffb310' : '')};
		text-shadow: ${({ canSort }) => (canSort ? '0 0 1px #ffb310' : '')};
		text-decoration: ${({ canSort }) => (canSort ? 'underline' : '')};
	}
`;

export const TableBodyTable = styled(Table)`
	flex-shrink: 1;
`;

export const BodyRow = styled.div<{
	completed?: boolean;
	disabled?: boolean;
	project?: number;
}>`
	border: 0;
	border-bottom: 1px solid ${theme.palette.divider};
	transition: background-color 0.2s ease-in-out;
	color: white;
	background-color: ${theme.palette.secondary.main};

	padding-left: 32px;
	opacity: ${({ disabled }) => (disabled ? '0.4' : '1')};

	gap: 10px;
	min-width: fit-content !important;
	min-height: 60px;

	${({ project }) =>
		project &&
		`
		cursor: pointer;
	`}

	&:hover {
		background-color: ${theme.palette.grey['A100']};
	}

	@media (max-width: 1023px) {
		padding-left: 40px;
	}
`;

export const TableHeaderRow = styled.div`
	background-color: #17181b;
	border-bottom: 1px solid #323639;
	min-width: 100%;
	gap: 10px;
	position: sticky;
	top: 0px;
	z-index: 1;
	overflow: hidden;
	padding-left: 32px;
	height: 60px;
	box-shadow: inset 5px 5px 10px -5px rgba(89, 89, 89, 0.7),
		inset -5px 0px 10px -5px rgba(89, 89, 89, 0.7);

	@media (max-width: 1023px) {
		padding-left: 40px;
	}
`;

export const TableBottomRow = styled(BottomRow)`
	position: sticky;
	bottom: 0px;
	z-index: 5;
	overflow: hidden;
	height: 42px;
	background-color: ${theme.palette.secondary.dark};
`;

export const TableBodyCell = styled(BodyCell)<{ align?: string }>`
	justify-content: ${({ align }) => align || 'flex-start'};
	flex-shrink: 0;
`;

const ActionBar = styled.div`
	width: 100%;
	height: 50px;
	padding: 20px;
	display: flex;
	flex-direction: row;
	gap: 12px;
	margin: 0 auto;
	justify-content: center;
	margin: 20px 0 10px 0;

	@media (max-width: 768px) {
		-webkit-overflow-scrolling: touch; // Improves scrolling on iOS devices
		width: 90%;
		padding: 20px;
	}
`;

export const HeaderCell = styled.div<{ align?: string }>`
	display: flex;
	flex-shrink: 0;
	justify-content: ${({ align }) => align || 'flex-start'};
	border-radius: 0;
	padding: 5px 0;
	text-align: center;
	background-color: ${theme.palette.secondary.dark};
`;

const TextInformation = styled('p')`
	font-size: 24px;
	color: #595959;
	text-align: center;
	font-weight: 300;
`;
