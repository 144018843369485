import styled from 'styled-components';
import ProjectStatusSidebar from '../ProjectStatusSidebar';
import { useContext, useState } from 'react';
import { ProjectContext } from '../../../context/ProjectProvider';
import StatusTable from './components/StatusTable';
import { ProjectStatus, UserObj } from '../../../types';
import { projectStatuses } from '../../../utils';
import { StatusTableProvider } from './StatusTableProvider';
import theme from '../../../styles/theme';

type ProjectStatusTablesProps = {
	startingStatus?: ProjectStatus;
	statuses?: ProjectStatus[];
	fullHeight?: boolean;
	query?: string;
	padTableContainer?: boolean;
	accountManagerFilter?: UserObj | null;
	globalProjStatus?: ProjectStatus;
	setGlobalProjStatus?: React.Dispatch<React.SetStateAction<ProjectStatus>>;
};

export default function ProjectStatusTables({
	startingStatus,
	statuses = projectStatuses,
	fullHeight = true,
	query,
	padTableContainer = true,
	accountManagerFilter = null,

	globalProjStatus,
	setGlobalProjStatus,
}: ProjectStatusTablesProps) {
	const { projectStatusCount } = useContext(ProjectContext);

	const [currProjStatus, setCurrProjStatus] = useState(startingStatus || statuses[0]);

	return (
		<StatusTableProvider>
			<Container $fullHeight={fullHeight} $padTableContainer={padTableContainer}>
				<ProjectStatusSidebar
					selectedStatus={globalProjStatus || currProjStatus}
					setSelectedStatus={
						(setGlobalProjStatus as React.Dispatch<
							React.SetStateAction<string | null>
						>) ||
						(setCurrProjStatus as React.Dispatch<React.SetStateAction<string | null>>)
					}
					projectStatusCount={projectStatusCount}
					statuses={statuses}
				/>

				<TableContainer $padTableContainer={padTableContainer}>
					<StatusTable
						status={globalProjStatus || currProjStatus}
						query={query || ''}
						accountManagerFilter={accountManagerFilter ?? null}
					/>
				</TableContainer>
			</Container>
		</StatusTableProvider>
	);
}
const Container = styled.div<{ $fullHeight: boolean; $padTableContainer: boolean }>`
	display: flex;
	overflow: hidden;
	width: 100%;
	height: ${({ $fullHeight }) => ($fullHeight ? '100%' : undefined)};

	@media (max-width: 1023px) {
		flex-direction: column;
		gap: ${({ $padTableContainer }) => ($padTableContainer ? '0' : '10px')};

		.status-dropdown {
			margin: ${({ $padTableContainer }) => ($padTableContainer ? '10px 10px 0' : '0')};
		}
	}
`;

const TableContainer = styled.div<{ $padTableContainer: boolean }>`
	flex-grow: 1;
	flex-shrink: 1;
	min-width: 0px;
	background-color: ${theme.palette.secondary.main};
	padding: ${({ $padTableContainer }) => ($padTableContainer ? '20px' : '0 0 20px 20px')};
	@media (max-width: 1023px) {
		padding: ${({ $padTableContainer }) => ($padTableContainer ? '10px' : '0')};
	}
`;
