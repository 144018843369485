import { Row } from 'react-table';
import { ProjectData } from '../../../../../../../types';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	IconButton,
} from '@mui/material';
import ClickableSpan from '../../../../../ClickableSpan';
import MultiDateTimeInput from '../../../../../field-components/MultiDateTimeInput';
import { isEqual } from 'lodash';
import { SnackContext } from '../../../../../../../context/SnackProvider';
import { StatusTableContext } from '../../../../StatusTableProvider';
import { updateSingleProjectField } from '../../../../../../../firebase/firestore/projects';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { SortBy } from '../..';
import {
	getCaptureTimestampString,
	setDateToTwelvePM,
} from '../../../../../../../utils/timestamps';
import styled from 'styled-components';
import theme from '../../../../../../../styles/theme';
import { Remove } from '@mui/icons-material';

dayjs.extend(utc);
dayjs.extend(timezone);

export default function MultiDateTimeCell({
	row,
	rowEditing,
	field,
	title,
	sortBy,
}: {
	row: Row<ProjectData>;
	rowEditing: boolean;
	field: 'captureTimestamps';
	title: string;
	sortBy?: SortBy | null;
}) {
	const { setSnackbarProps } = useContext(SnackContext);
	const { setStatusProjects } = useContext(StatusTableContext);

	const project = row.original;
	const projectId = project.id as string;
	const dates = useMemo(() => project[field] || [], [field, project]);

	const getDateStrings = (dates: Date[]) => {
		return dates.map(date => getCaptureTimestampString(date)).join(', ') || '-';
	};

	const [displayStr, setDisplayStr] = useState(getDateStrings(dates));
	const [datesArr, setDatesArr] = useState(dates);
	const [showDialog, setShowDialog] = useState(false);

	const handleSortBy = useCallback(() => {
		if (sortBy && sortBy.field === 'captureTimestamps') {
			if (sortBy.order === 'asc') {
				const ascDates = [...dates].sort((a, b) => a.getTime() - b.getTime());
				setDisplayStr(getDateStrings(ascDates));
			} else {
				const descDates = [...dates].sort((a, b) => b.getTime() - a.getTime());
				setDisplayStr(getDateStrings(descDates));
			}
		}
	}, [dates, sortBy]);

	useEffect(() => {
		handleSortBy();
	}, [handleSortBy, row, sortBy]);

	const handleOpen = (e: React.MouseEvent<HTMLSpanElement> | undefined) => {
		e?.stopPropagation();
		setShowDialog(true);
		setDatesArr(dates);
	};

	const handleClose = () => {
		setShowDialog(false);
	};

	const handleSubmit = async () => {
		handleClose();
		setSnackbarProps({
			open: true,
			severity: 'warning',
			message: 'Saving changes...',
			hideDuration: null,
		});

		// Update state.
		const newData = datesArr.length
			? datesArr.map(date => setDateToTwelvePM(date))
			: undefined;
		setStatusProjects(prev => {
			return prev.map(proj => {
				if (proj.id !== projectId) return proj;
				else {
					return {
						...proj,
						[field]: newData,
					};
				}
			});
		});

		// Update firestore document.
		await updateSingleProjectField(projectId, newData, field);

		setSnackbarProps({
			open: true,
			severity: 'success',
			message: 'Changes saved!',
		});
	};

	return !rowEditing ? (
		<span>{displayStr}</span>
	) : (
		<>
			<StyledSpan onClick={handleOpen}>
				{displayStr === '-' ? (
					<IconButton color="inherit">
						<Remove />
					</IconButton>
				) : (
					displayStr
				)}
			</StyledSpan>

			<Dialog
				open={showDialog}
				onClose={handleClose}
				onClick={event => event.stopPropagation()}>
				<DialogTitle>{`Editing ${title}`}</DialogTitle>

				<DialogContent>
					<MultiDateTimeInput dates={datesArr} setDates={setDatesArr} />
				</DialogContent>

				<DialogActions>
					<Button onClick={handleClose}>Close</Button>

					{!isEqual(dates, datesArr) ? (
						<Button onClick={handleSubmit}>Submit</Button>
					) : null}
				</DialogActions>
			</Dialog>
		</>
	);
}

const StyledSpan = styled.span`
	transition: 0.2s;
	color: #fff;
	cursor: pointer;

	&:hover,
	:focus-visible {
		color: ${theme.palette.primary.main};
		text-decoration: underline;
	}
`;
