import { Button } from '@mui/material';
import styled from 'styled-components';

export const appBarHeight = '75px';

export const PageContent = styled.div`
	display: flex;
	overflow: hidden;
	height: calc(100vh - ${appBarHeight});
	width: 100%;

	@media (max-width: 1023px) {
		flex-direction: column;
	}
	@media (max-width: 1300px) {
		height: 100%;
		overflow: scroll;
	}
`;

export const Sidebar = styled.div`
	display: flex;
	flex-direction: column;
	padding: 20px 40px 60px 20px;

	@media (max-width: 1023px) {
		padding: 20px 40px 0px;
	}
`;

export const MainWrapper = styled.div`
	display: flex;
	flex-direction: column;
	gap: 10px;
	height: 100%;
	flex-grow: 1;
	flex-shrink: 1;

	padding: 0px 40px 20px 0px;

	@media (max-width: 1023px) {
		padding: 10px;
	}
`;

export const OverviewButton = styled(Button)`
	background-color: #17181b;
	display: flex;
	align-items: center;
	line-height: normal;
	justify-content: center;
	gap: 10px;
	font-size: 18px;
	height: 40px;
	border-radius: 20px;
	text-transform: none;
	color: #f6f6f6;
	border: 1px solid #f6f6f6;

	@media (max-width: 1023px) {
		height: 50px;
		border-radius: 30px;
	}
`;

export const SearchbarWrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	width: 100%;
	gap: 20px;
	flex-shrink: 0;
	flex-grow: 0;
	padding: 20px 0 0px 0px;

	@media (max-width: 1023px) {
		padding: 0px 30px;
	}
`;

export const ButtonWrapper = styled.div`
	display: flex;
	flex-direction: column;
	gap: 20px;
	flex-shrink: 0;
	flex-grow: 0;
	padding: 0px 20px 0px;

	@media (max-width: 1023px) {
		padding: 10px 0px;
	}
`;
