import { useCallback, useContext, useMemo, useState } from 'react';
import { Flag, FormNode } from '../../../../../utils/types';
import { useDropzone } from 'react-dropzone';
import {
	InputFieldContainer,
	ManageUploadsButton,
	PhotoDropzone,
	SubCategoryHeaderContainer,
} from '../FormFillV2.styles';
import { FormV2Context } from '../../../../../context/FormV2Context.types';
import PhotoUploadManager from '../../../../Dialogs/PhotoUploadManager/PhotoUploadManager';
import { SnackContext } from '../../../../../../../../context/SnackProvider';
import PhotoGalleryDialog from '../../../../Dialogs/PhotoGalleryDialog/PhotoGalleryDialog';
import DraggableGallery from './PhotosField/DraggableGallery';
import NodeTitle from './NodeTitle';
import NodeActionButton from '../../../../buttons/NodeActionButton/NodeActionButton';
import CommentsButton from '../../../../buttons/CommentsButton/CommentsButton';
import InfoButton from '../../../../buttons/InfoButton/InfoButton';
import styled from 'styled-components';
import CategoryHeader from '../../CategoryHeader';

interface DropzoneFileRejection {
	file: File;
	errors: DropzoneFileError[];
}

interface DropzoneFileError {
	code: string;
	message: string;
}

export default function PhotosField({ itemNode }: { itemNode: FormNode }) {
	const {
		currentNode,
		addPhotosToDownloadQueue,
		deleteNodePhoto,
		photoUploadQueue,
		updatePhotos,
	} = useContext(FormV2Context);

	const [uploadedPhotoCount, setUploadedPhotoCount] = useState(
		itemNode.value && Array.isArray(itemNode.value) ? itemNode.value.length : 0
	);
	const [openUploadManager, setOpenUploadManager] = useState(false);
	const uploadedPhotos = useMemo(
		() => (itemNode.value && Array.isArray(itemNode.value) ? itemNode.value : []),
		[itemNode.value]
	);

	const { setSnackbarProps } = useContext(SnackContext);
	const [isGalleryOpen, setIsGalleryOpen] = useState(false);
	const [initialImageIndex, setInitialImageIndex] = useState(0);

	const combinedPhotos = useMemo(
		() => [
			...uploadedPhotos,
			...photoUploadQueue.filter(p => p.nodeId === itemNode.id).map(p => p.photo),
		],
		[itemNode.id, photoUploadQueue, uploadedPhotos]
	);

	const onDrop = useCallback(
		(acceptedFiles: File[]) => {
			// convert dropped photos to LocalPhoto[] type, and add to download queue
			const localPhotos = acceptedFiles.map(file => ({
				originalName: file.name,
				uri: URL.createObjectURL(file), //create local URL for file
				name: file.name,
			}));
			addPhotosToDownloadQueue(itemNode.id, localPhotos);
			setUploadedPhotoCount(currentCount => currentCount + acceptedFiles.length);
		},
		[addPhotosToDownloadQueue, itemNode.id]
	);

	const onDropRejected = useCallback(
		(fileRejections: DropzoneFileRejection[]) => {
			fileRejections.forEach(fileRejection => {
				fileRejection.errors.forEach((error: DropzoneFileError) => {
					if (error.code === 'file-too-large') {
						setSnackbarProps({
							open: true,
							message: `File is too large.`,
							severity: 'warning',
						});
					} else if (error.code === 'file-invalid-type') {
						setSnackbarProps({
							open: true,
							message: `File type not accepted.`,
							severity: 'warning',
						});
					}
				});
			});
		},
		[setSnackbarProps]
	);

	const openGallery = (index: number) => {
		console.log('Opening gallery at index:', index);
		setInitialImageIndex(index);
		setIsGalleryOpen(true);
	};

	const closeGallery = () => {
		setIsGalleryOpen(false);
	};

	const deleteImage = async (index: number) => {
		const photoToDelete = uploadedPhotos[index];
		if (!photoToDelete) {
			console.error("Can't find photo.");
			return;
		}
		try {
			deleteNodePhoto(itemNode.id, photoToDelete);
			// Update local state to reflect the photo deletion
			const updatedPhotos = uploadedPhotos.filter((_, i) => i !== index);
			setUploadedPhotoCount(updatedPhotos.length); // Update photo count state

			setSnackbarProps({
				open: true,
				message: 'Photo deleted successfully.',
				severity: 'success',
			});
		} catch (error) {
			console.error('Failed to delete photo:', error);
			setSnackbarProps({
				open: true,
				message: 'Failed to delete photo. Please try again later.',
				severity: 'error',
			});
		}
	};

	const setFlag = (index: number, flag: Flag) => {
		const photos = uploadedPhotos.map((photo, i) =>
			i === index ? { ...photo, flag } : photo
		);
		try {
			updatePhotos(itemNode.id, photos);
		} catch (error) {
			console.error('Failed to update flag:', error);
			setSnackbarProps({
				open: true,
				message: 'Failed to update flag. Please try again later.',
				severity: 'error',
			});
		}
	};

	const { isDragActive, getRootProps, getInputProps, isDragReject } = useDropzone({
		onDrop,
		onDropRejected,
		accept: ['image/png', 'image/jpeg'],
		minSize: 0,
	});

	return (
		<Container>
			{itemNode.level === 0 ? (
				currentNode?.id === itemNode.id ? (
					<CategoryHeader />
				) : (
					<SubCategoryHeaderContainer>
						<NodeTitle node={itemNode} />
						<NodeActionButton node={itemNode} />
						<CommentsButton node={itemNode} />
						<InfoButton node={itemNode} />
					</SubCategoryHeaderContainer>
				)
			) : null}

			<InputFieldContainer>
				<PhotoDropzone {...getRootProps()}>
					<input {...getInputProps()} />
					{!isDragActive && 'Click here or drag a file to upload'}
					{isDragActive && !isDragReject && 'Drop it like its hot!'}
					{uploadedPhotoCount > 0 && <div>{uploadedPhotoCount} photo(s) uploaded.</div>}
				</PhotoDropzone>

				<ManageUploadsButton onClick={() => setOpenUploadManager(true)}>
					Manage uploads
				</ManageUploadsButton>

				<PhotoUploadManager
					open={openUploadManager}
					onClose={() => setOpenUploadManager(false)}
					itemNode={itemNode}
					uploadedPhotos={uploadedPhotos}
				/>

				{combinedPhotos.length > 0 && (
					<DraggableGallery
						photos={combinedPhotos}
						nodeId={itemNode.id}
						openGallery={openGallery}
					/>
				)}

				<PhotoGalleryDialog
					open={isGalleryOpen}
					photos={uploadedPhotos}
					initialIndex={initialImageIndex}
					onClose={closeGallery}
					onDelete={deleteImage}
					setFlag={setFlag}
				/>
			</InputFieldContainer>
		</Container>
	);
}

const Container = styled.div`
	display: flex;
	flex-direction: column;
	gap: 40px;
`;
