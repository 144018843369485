import { useState } from 'react';
import { doc, serverTimestamp, updateDoc } from 'firebase/firestore';
import { UtilityFormV2 } from '../ProjectUtilityFormV2/utils/types';
import { db } from '../../../firebase';

const useArchiveTemplate = (form: UtilityFormV2) => {
	const [archiveDialogOpen, setArchiveDialogOpen] = useState(false);
	const [isArchiving, setIsArchiving] = useState(false);

	const archiveForm = (e: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
		e.stopPropagation();
		setArchiveDialogOpen(true);
	};

	const closeArchiveDialog = () => setArchiveDialogOpen(false);

	const handleArchiveForm = async () => {
		setIsArchiving(true);
		closeArchiveDialog();
		const formRef = doc(db, 'utility_forms_v2', form.id);
		await updateDoc(formRef, {
			status: 'archived',
			lastUpdated: serverTimestamp(),
		});
		setIsArchiving(false);
	};

	return {
		closeArchiveDialog,
		archiveDialogOpen,
		handleArchiveForm,
		isArchiving,
		archiveForm,
	};
};

export default useArchiveTemplate;
