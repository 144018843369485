import { useContext, useEffect, useState } from 'react';
import { FormNode } from '../utils/types';
import {
	collection,
	query,
	where,
	orderBy,
	onSnapshot,
	updateDoc,
	doc,
} from 'firebase/firestore';
import { db } from '../../../../firebase';
import { FormV2Context } from '../context/FormV2Context.types';

export const useItemNodes = (parentNode?: FormNode) => {
	const { formId, utilityForm } = useContext(FormV2Context);

	const [itemNodes, setItemNodes] = useState<FormNode[]>([]);
	const [isLoading, setIsLoading] = useState(true);

	const parentId = parentNode?.id;
	const parentLevel = parentNode?.level;

	useEffect(() => {
		let unsub = () => {};
		setIsLoading(true);

		if (parentId && utilityForm?.projectId) {
			const itemsRef = query(
				collection(db, `utility_forms_v2_items`),
				where('formId', '==', formId),
				where('projectId', '==', utilityForm?.projectId),
				where('parentId', '==', parentId),
				orderBy('order')
			);

			unsub = onSnapshot(itemsRef, querySnap => {
				if (querySnap) {
					const items: FormNode[] = [];
					querySnap.forEach(docSnap => {
						items.push({
							...(docSnap.data() as FormNode),
							id: docSnap.id,
						});
					});
					setItemNodes(items);
				}
				setIsLoading(false);
			});
		} else {
			setIsLoading(false);
		}

		return () => {
			unsub();
		};
	}, [parentId, formId, utilityForm?.projectId]);

	// Makes sure the nodes have the correct level property.
	useEffect(() => {
		if (typeof parentLevel === 'number' && itemNodes.length) {
			for (const node of itemNodes) {
				const expectedLevel = parentLevel + 1;
				if (node.level !== expectedLevel) {
					const nodeDoc = doc(db, `utility_forms_v2_items/${node.id}`);
					updateDoc(nodeDoc, {
						level: expectedLevel,
					});
				}
			}
		}
	}, [itemNodes, parentLevel]);

	return {
		itemNodes,
		isLoading,
	};
};
