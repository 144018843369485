import {
	Dispatch,
	SetStateAction,
	useCallback,
	useContext,
	useEffect,
	useRef,
	useState,
} from 'react';
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	List,
	ListItemButton,
	ListItemText,
	TextField,
	styled,
} from '@mui/material';
import { SnackContext } from '../../../context/SnackProvider';
import LoadingScreen from '../../reusable-components/LoadingScreen';
import { OrganizationsContext } from '../../../context/OrganizationsProvider';
import { FormTemplate } from '../ProjectUtilityFormV2/utils/types';
import Spinner from '../../reusable-components/Spinner';
import useTemplates from './useTemplates';

export default function AddTemplateDialog({
	open,
	teamId,
	setOpen,
	organizationId,
}: {
	open: boolean;
	teamId: string;
	organizationId: string;
	setOpen: Dispatch<SetStateAction<boolean>>;
}) {
	const { loadTeam, team } = useContext(OrganizationsContext);
	const { setSnackbarProps } = useContext(SnackContext);
	const { addTemplatesToTeam, searchTemplates } = useTemplates({
		teamId,
		organizationId,
	});
	const [searchQuery, setSearchQuery] = useState('');
	const [templates, setTemplates] = useState<FormTemplate[]>([]);
	const [selectedTemplates, setSelectedTemplates] = useState<FormTemplate[]>([]);
	const [isSearching, setIsSearching] = useState(false);
	const debounceTimer = useRef<NodeJS.Timeout | null>(null);
	const debouncedSearchQuery = useRef<string>('');
	const [addingTemplateToTeam, setAddingTemplateToTeam] = useState(false);

	const fetchQueriedTemplates = useCallback(async () => {
		setIsSearching(true);
		try {
			const templatesList = await searchTemplates(searchQuery);

			const existingTemplateIds = team?.templateIds || [];

			// Filter out template that are already added to the team
			const newTemplates = templatesList.filter(
				template => template.id && !existingTemplateIds.includes(template.id)
			);

			setTemplates(newTemplates);
		} catch (err) {
			console.error(err);
		} finally {
			setIsSearching(false);
		}
	}, [searchQuery, searchTemplates, team?.templateIds]);

	useEffect(() => {
		if (debouncedSearchQuery.current !== searchQuery) {
			if (debounceTimer.current) {
				clearTimeout(debounceTimer.current);
			}
			debounceTimer.current = setTimeout(() => {
				debouncedSearchQuery.current = searchQuery;
				fetchQueriedTemplates();
			}, 500); // 500 milliseconds debounce time
		}
	}, [searchQuery, fetchQueriedTemplates]);

	const handleSearchChange = (
		e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
	) => setSearchQuery(e.target.value);

	const handleTemplateSelection = (template: FormTemplate) => {
		setSelectedTemplates(prev => {
			return prev.find(p => p.id === template.id)
				? prev.filter(p => p.id !== template.id)
				: [...prev, template];
		});
	};

	const handleClose = () => {
		setOpen(false);
		setSearchQuery('');
		setTemplates([]);
		setSelectedTemplates([]);
	};

	useEffect(() => {
		return () => {
			if (debounceTimer.current) clearTimeout(debounceTimer.current);
		};
	}, []);

	const handleSubmit = async () => {
		if (selectedTemplates.length === 0) return;

		const templateIds = selectedTemplates
			.map(p => p.id)
			.filter(id => id !== undefined) as string[];

		try {
			setAddingTemplateToTeam(true);
			await addTemplatesToTeam(templateIds);
			await loadTeam(teamId);

			handleClose();

			setSnackbarProps({
				open: true,
				severity: 'success',
				message: `Successfully added templates to team!`,
			});
			setAddingTemplateToTeam(false);
		} catch (err) {
			console.error(err);
			setSnackbarProps({
				open: true,
				severity: 'error',
				message: `Failed to add templates to team!`,
			});
			handleClose();
			setAddingTemplateToTeam(false);
		}
	};

	return (
		<StyledDialog open={open} onClose={handleClose} maxWidth="xs" fullWidth>
			<DialogTitle>Add Templates(s) to Team</DialogTitle>

			{!addingTemplateToTeam ? (
				<DialogContent>
					<TextField
						variant="standard"
						label="Search by template name..."
						value={searchQuery}
						onChange={handleSearchChange}
						InputProps={{
							endAdornment: isSearching ? <Spinner size={32} /> : null,
						}}
					/>
					{templates.length > 0 ? (
						<List>
							{templates.map((template, index) => (
								<ListItemButton
									key={index}
									onClick={() => handleTemplateSelection(template)}
									selected={selectedTemplates.some(p => p.id === template.id)}
									sx={{
										backgroundColor: selectedTemplates.some(p => p.id === template.id)
											? 'rgba(255, 179, 16, 0.1)'
											: '#f6f6f6',
										color: 'black',
										'&:hover': {
											backgroundColor: 'rgba(0, 0, 0, 0.04)',
										},
									}}>
									<ListItemText
										primary={template.name}
										secondary={`Updated ${template.updatedAt
											.toDate()
											.toLocaleDateString()} by ${template.updatedBy.name}`}
									/>
								</ListItemButton>
							))}
						</List>
					) : searchQuery ? (
						<span>no templates found</span>
					) : null}
				</DialogContent>
			) : (
				<DialogContent>
					<LoadingScreen message="Adding template..." textColor="black" />
				</DialogContent>
			)}

			<DialogActions>
				{!addingTemplateToTeam ? (
					<>
						<Button onClick={handleClose} style={{ textTransform: 'none' }}>
							Cancel
						</Button>
						<Button
							onClick={handleSubmit}
							style={{ textTransform: 'none' }}
							disabled={selectedTemplates.length === 0}>
							Add template(s)
						</Button>
					</>
				) : null}
			</DialogActions>
		</StyledDialog>
	);
}

const StyledDialog = styled(Dialog)(({ theme }) => ({
	'& .MuiDialog-paper': {
		maxWidth: '800px',
	},

	'& .MuiDialogContent-root': {
		maxHeight: '60vh',
		overflowY: 'auto',
	},
}));
