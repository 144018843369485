import { Button } from '@mui/material';
import { styled } from '@mui/system';

const GrayButton = styled(Button)`
	background-color: #17171b;
	width: 90%;
	max-width: 500px;
	min-width: 250px;
	color: white;
	border-radius: 20px;
	margin: 10px 0 0 0;

	box-shadow: 1px 3px 4px rgba(89, 89, 89, 0.4);

	&:hover {
		color: #ffb310;
		background-color: #17171b;
	}

	@media screen and (max-width: 424px) {
		padding: 20px 20px;
		white-space: normal;
	}
`;

export default GrayButton;
