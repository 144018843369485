import { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../../../../../../../context/AuthProvider';
import {
	StatusWrapper,
	statusColor,
} from '../../../../../../styled-components/styledReactTable';
import { IconButton, MenuItem, Select } from '@mui/material';
import { ProjectData, ProjectFlag, ProjectStatus } from '../../../../../../../types';
import {
	confirmProjectStatusChange,
	updateSingleProjectField,
} from '../../../../../../../firebase/firestore/projects';
import { projectStatusText, projectStatuses } from '../../../../../../../utils';
import styled from 'styled-components';
import { ProjectContext } from '../../../../../../../context/ProjectProvider';
import { StatusTableContext } from '../../../../StatusTableProvider';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import {
	checkPermissions,
	copyToClipboard,
	getAnonDownloadLink,
} from '../../../../../../../utils';
import { SnackContext } from '../../../../../../../context/SnackProvider';
import { Row } from 'react-table';
import InvoicesCell from './InvoicesCell';
import OutlinedFlagIcon from '@mui/icons-material/OutlinedFlag';
import FlagIcon from '@mui/icons-material/Flag';
import Spinner from '../../../../../Spinner';
import { collection, deleteDoc, doc, setDoc } from 'firebase/firestore';
import { db } from '../../../../../../../firebase';

// const lockedStatuses: ProjectStatus[] = [
// 	'Proposal Pending',
// 	'Proposal Sent',
// 	'Archive',
// 	'Sample',
// ];

export default function StatusCell({
	row,
	projectData,
	rowEditing,
	setRowEditing,
}: {
	row: Row<ProjectData>;
	projectData: ProjectData;
	rowEditing: boolean;
	setRowEditing: React.Dispatch<React.SetStateAction<boolean>>;
}) {
	const { user } = useContext(AuthContext);
	const { projectStatusCount, setProjectStatusCount, flaggedProjects } =
		useContext(ProjectContext);
	const { setStatusProjects } = useContext(StatusTableContext);
	const { setSnackbarProps } = useContext(SnackContext);
	const shareableLink = getAnonDownloadLink(row.original);
	const projectName = row.original.address || row.original.name || 'Unnamed Project';

	const handleChange = (projectData: ProjectData, newStatus: ProjectStatus) => {
		if (confirmProjectStatusChange(newStatus)) {
			updateSingleProjectField(
				projectData.id as string,
				newStatus,
				'projectStatus'
			).catch(e => console.error(e));
			setStatusProjects(prev => {
				// Update status count.
				const oldStatus = projectData.projectStatus;
				setProjectStatusCount({
					...projectStatusCount,
					[newStatus]: (projectStatusCount[newStatus] || 0) + 1,
					[oldStatus]: (projectStatusCount[oldStatus] || 0) - 1,
				});

				// Remove project from state after status changes
				return prev.filter(proj => proj.id !== projectData.id);
			});
		}
	};

	const projectFlag = flaggedProjects.find(flag => flag.projectId === row.original.id);
	const [flagging, setFlagging] = useState(false);

	const handleFlagToggle = async (e: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
		e.stopPropagation();
		setFlagging(true);
		try {
			if (projectFlag) {
				const flagDoc = doc(db, `project_flags/${projectFlag.id}`);
				await deleteDoc(flagDoc);
				setSnackbarProps({
					open: true,
					message: `${row.original.address} unflagged!`,
					severity: 'success',
				});
			} else {
				const newFlagDoc = doc(collection(db, 'project_flags'));
				const newFlag: ProjectFlag = {
					id: newFlagDoc.id,
					projectId: row.original.id || '',
					flaggerName: user?.fullName || '',
					flaggerEmail: user?.email || '',
					flaggerId: user?.id || '',
					timestamp: new Date(),
				};
				await setDoc(newFlagDoc, newFlag);
				setSnackbarProps({
					open: true,
					message: `${row.original.address} flagged!`,
					severity: 'success',
				});
			}
		} catch (err) {
			console.error(err);
			setSnackbarProps({
				open: true,
				message: 'Could not flag project',
				severity: 'error',
			});
		} finally {
			setFlagging(false);
		}
	};

	useEffect(() => {
		if (checkPermissions(user, 'admin')) {
			setRowEditing(true);
		}
	}, [setRowEditing, user]);

	return user?.isAdmin ? (
		<>
			<StatusWrapper
				$projectStatus={projectData.projectStatus}
				style={{ position: 'relative', zIndex: '1' }}>
				<StyledSelect
					onChange={e => {
						e.preventDefault();
						e.stopPropagation();
						handleChange(projectData, e.target.value as ProjectStatus);
					}}
					MenuProps={{
						PaperProps: {
							sx: {
								borderRadius: '30px',
							},
						},
					}}
					value={projectData.projectStatus}
					displayEmpty
					onClose={e => e.stopPropagation()}>
					{projectStatuses.map(status => (
						<MenuItem value={status} key={status}>
							{projectStatusText(status)}
						</MenuItem>
					))}
				</StyledSelect>
			</StatusWrapper>

			<InvoicesCell row={row} rowEditing={rowEditing} />

			<ButtonContainer>
				<ContentCopyIcon
					onClick={e => {
						e.stopPropagation();
						copyToClipboard(shareableLink);
						setSnackbarProps({
							open: true,
							message: `${projectName} public shareable link copied!`,
							severity: 'success',
						});
					}}
					className="CopyLinkButton CopyLinkButton-black"
					titleAccess="Copy public shareable link"
					tabIndex={0}
				/>
				{/* {checkPermissions(user, 'admin') ? (
					!rowEditing ? (
						<EditIcon
							onClick={e => [e.stopPropagation(), setRowEditing(true)]}
							className="CopyLinkButton CopyLinkButton-black"
							titleAccess={`Edit ${projectName}`}
							tabIndex={0}
						/>
					) : (
						<CancelIcon
							onClick={e => [e.stopPropagation(), setRowEditing(false)]}
							className="CopyLinkButton CopyLinkButton-black"
							tabIndex={0}
						/>
					)
				) : null} */}

				{user?.isAdmin ? (
					flagging ? (
						<Spinner />
					) : projectFlag ? (
						<FilledFlag
							onClick={handleFlagToggle}
							className="CopyLinkButton CopyLinkButton-black"
							titleAccess="Unflag this project"
							tabIndex={0}
						/>
					) : (
						<EmptyFlag
							onClick={handleFlagToggle}
							className="CopyLinkButton CopyLinkButton-black"
							titleAccess="Flag this project"
							tabIndex={0}
						/>
					)
				) : null}
			</ButtonContainer>
		</>
	) : (
		<NonAdminStatusChip $projectStatus={projectData.projectStatus}>
			{projectData.projectStatus}
		</NonAdminStatusChip>
	);
}

const NonAdminStatusChip = styled.div<{ $projectStatus: ProjectStatus }>`
	color: black;
	background-color: ${({ $projectStatus }) => statusColor($projectStatus)};
	border-radius: 5px;
	padding: 5px;
`;

const StyledSelect = styled(Select)`
	border-radius: 30px;
`;

const ButtonContainer = styled.div`
	display: flex;
	align-items: center;
`;

const EmptyFlag = styled(OutlinedFlagIcon)``;

const FilledFlag = styled(FlagIcon)`
	fill: red;
`;
