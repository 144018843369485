import React, { ReactNode, useState } from 'react';
import styled from 'styled-components';
import ExpandMore from '@mui/icons-material/ExpandMore';

type MobileDropdownProps = {
	children: ReactNode;
	title: string;
};

export const MobileDropdown = ({ children, title }: MobileDropdownProps) => {
	const [isOpen, setIsOpen] = useState(false);

	return (
		<DropdownContainer>
			<DropdownButton onClick={() => setIsOpen(!isOpen)}>
				<DropDownTitle>{title}</DropDownTitle>
				<CaretIcon open={isOpen} />
			</DropdownButton>
			<DropdownContent open={isOpen}>{children}</DropdownContent>
		</DropdownContainer>
	);
};

type DropdownContentProps = {
	open: boolean;
};

const DropdownContainer = styled.div`
	display: none;
	background-color: black;
	height: fit-content;

	@media (max-width: 768px) {
		display: block;
	}
`;

const DropDownTitle = styled.span`
	font-family: 'Epilogue';
	font-size: 1rem;
`;

const DropdownButton = styled.button`
	background: none;
	border: none;
	color: white;
	padding: 10px;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 16px;
	cursor: pointer;

	&:hover {
		background-color: #202225;
	}
	> *:not(:last-child) {
		margin-right: 10px;
	}
`;

const DropdownContent = styled.div<DropdownContentProps>`
	display: ${props => (props.open ? 'block' : 'none')};
	padding: 10px;
`;

const CaretIcon = styled(ExpandMore)<DropdownContentProps>`
	color: #ffb310;
	transform: ${props => (props.open ? 'rotate(180deg)' : 'rotate(0)')};
	transition: transform 0.3s;
`;
