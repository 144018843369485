import { useState, useEffect } from 'react';
import PlacesAutocomplete from '.';

const loadGoogleMapsScript = (apiKey: string) => {
	if (window.google) {
		return Promise.resolve();
	}

	const script = document.createElement('script');
	script.src = `https://maps.googleapis.com/maps/api/js?key=${apiKey}&libraries=places`;
	document.head.appendChild(script);

	return new Promise((resolve, reject) => {
		script.onload = resolve;
		script.onerror = reject;
	});
};

export default function PlacesAutocompleteWrapper(props: any) {
	const [apiLoaded, setApiLoaded] = useState(false);

	useEffect(() => {
		const apiKey = process.env.REACT_APP_google_maps_apiKey || '';
		loadGoogleMapsScript(apiKey)
			.then(() => {
				setApiLoaded(true);
			})
			.catch(error => {
				console.error('Failed to load Google Maps', error);
			});
	}, []);

	if (!apiLoaded) {
		return <div>Loading...</div>;
	}

	return <PlacesAutocomplete {...props} />;
}
