import { FC, useContext, useState } from 'react';
import { ReportItem, ReportRow } from './StyledComponents';
import { ArrowDropDownCircle, Delete, Edit } from '@mui/icons-material';
import { Box, IconButton, Tooltip } from '@mui/material';
import Loading from '../ProjectUtilityFormV2/components/Loading/Loading';
import { ReportData } from '../../../types/reports';
import EditReportNameDialog from './EditReportNameDialog';
import theme from '../../../styles/theme';
import DeleteReportDialog from './DeleteReportDialog';
import { AuthContext } from '../../../context/AuthProvider';

type RepostListItemProps = {
	onClick: () => void;
	report: ReportData;
	selectedReport?: ReportData;
};

const ReportListItem: FC<RepostListItemProps> = ({ onClick, report, selectedReport }) => {
	const [dialogOpen, setDialogOpen] = useState(false);
	const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
	const { user } = useContext(AuthContext);

	return (
		<ReportItem
			style={{
				background: `${
					report.id === selectedReport?.id
						? theme.palette.divider
						: theme.palette.common.black
				}`,
			}}
			key={report.id}
			onClick={onClick}
			selected={report.id === selectedReport?.id}>
			<ReportRow>
				<Tooltip title={report.type}>
					<ArrowDropDownCircle
						style={{ fontSize: 16, marginRight: 10 }}
						color="primary"
					/>
				</Tooltip>
				{report.name || report.createdAt?.toDate().toLocaleString()}
			</ReportRow>
			{report.status === 'processing' ? (
				<Box style={{ marginLeft: 'auto' }}>
					<Loading size={20} />
				</Box>
			) : null}
			{report.status === 'completed' && (
				<div>
					<IconButton
						onClick={e => {
							e.stopPropagation();
							setDialogOpen(true);
						}}
						style={{ marginLeft: 'auto' }}>
						<Edit color="primary" />
					</IconButton>
					{(user?.isAdmin || user?.orgOwnerIds?.includes(report.organizationId)) && (
						<IconButton
							onClick={e => {
								e.stopPropagation();
								setDeleteDialogOpen(true);
							}}
							style={{ marginLeft: 'auto' }}>
							<Delete color="primary" />
						</IconButton>
					)}
				</div>
			)}
			<EditReportNameDialog open={dialogOpen} report={report} setOpen={setDialogOpen} />
			<DeleteReportDialog
				open={deleteDialogOpen}
				report={report}
				setOpen={setDeleteDialogOpen}
			/>
		</ReportItem>
	);
};

export default ReportListItem;
