import { FieldLabel } from '../../../../../screens/ProjectInfo';
import {
	useContext,
	// useState,
	// useEffect
} from 'react';
import StringInput from './StringInput';
import { AuthContext } from '../../../../../context/AuthProvider';
import { numberStrToMoney, parseNumFromStr } from '../../../../../utils';
import { ProjectContext } from '../../../../../context/ProjectProvider';

// Props for Sub-Total
interface SubTotalProps {
	isActive: boolean;
	handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

// Sub-Total Information
export default function SubTotal({ isActive, handleChange }: SubTotalProps) {
	const { user } = useContext(AuthContext);
	const { project } = useContext(ProjectContext);

	// const [margin, setMargin] = useState('');

	// useEffect(() => {
	// 	setMargin(() => {
	// 		let subTotal = parseNumFromStr(
	// 			!isActive ? project.subTotal : partialFormState.subTotal ?? ''
	// 		);
	// 		if (!subTotal) return '';

	// 		let costs = 0;
	// 		if (isActive) {
	// 			costs += partialFormState.scanningCost ?? 0;
	// 			costs += partialFormState.registrationCost ?? 0;
	// 			costs += partialFormState.modelingCost ?? 0;
	// 		} else {
	// 			costs += project.scanningCost ?? 0;
	// 			costs += project.registrationCost ?? 0;
	// 			costs += project.modelingCost ?? 0;
	// 		}
	// 		return ((1 - costs / subTotal) * 100).toFixed(2) + '%';
	// 	});
	// }, [isActive, project, partialFormState]);

	return user?.isAdmin ? (
		<>
			{/* <div className="infoRow">
				<FieldLabel label={'Margin'} />

				<span>{margin}</span>
			</div> */}

			<div className="infoRow">
				<FieldLabel label={'Sub-Total'} />

				<StringInput
					field={'subTotal'}
					onChange={handleChange}
					isActive={Boolean(user?.isAdmin && isActive)}
					inactiveDisplay={
						project?.subTotal
							? numberStrToMoney(`${parseNumFromStr(project?.subTotal || '')}`)
							: ''
					}
				/>
			</div>
		</>
	) : null;
}
