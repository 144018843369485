import { useContext } from 'react';
import styled from 'styled-components';
import { YellowIconButton } from '../../../../../styled-components/buttons';

import InventoryIcon from '@mui/icons-material/Inventory';
import FolderIcon from '@mui/icons-material/Folder';
import StarIcon from '@mui/icons-material/Star';
import ClearIcon from '@mui/icons-material/Clear';
import AutorenewIcon from '@mui/icons-material/Autorenew';

import { AuthContext } from '../../../../../../context/AuthProvider';
import { FileBucket, FileType } from '../../../types';
import { ProjectData } from '../../../../../../types';
import { checkPermissions } from '../../../../../../utils';

export default function TitleButtons({
	projectData,
	deliverableKey,

	anonUpload,
	downloadOnly,

	editingOrder,
	setEditingOrder,
	selectedBucket,
	setSelectedBucket,
}: {
	projectData: ProjectData | undefined;
	deliverableKey: FileType;

	anonUpload: boolean;
	downloadOnly: boolean;

	editingOrder: boolean;
	setEditingOrder: React.Dispatch<React.SetStateAction<boolean>>;
	selectedBucket: FileBucket;
	setSelectedBucket: React.Dispatch<React.SetStateAction<FileBucket>>;
}) {
	const { user } = useContext(AuthContext);

	const isAnonymous = anonUpload || downloadOnly;
	const canEditOrder =
		user?.isAdmin &&
		!isAnonymous &&
		selectedBucket === 'uploaded' &&
		(deliverableKey === 'pdf' || deliverableKey === 'photos');

	return (
		<Container>
			{!editingOrder ? (
				<>
					{/* Edit preview order button */}
					{canEditOrder ? (
						<YellowIconButton
							title={`Change first preview ${deliverableKey === 'pdf' ? 'PDF' : 'photo'}`}
							onClick={() => setEditingOrder(true)}>
							<StarIcon />
						</YellowIconButton>
					) : null}

					{/* View 'uploaded' files button */}
					{selectedBucket !== 'uploaded' ? (
						<YellowIconButton
							title="View uploaded files"
							onClick={() => setSelectedBucket('uploaded')}>
							<FolderIcon />
							<FileCounter>{projectData?.[deliverableKey]?.length || ''}</FileCounter>
						</YellowIconButton>
					) : null}

					{/* View 'inProgress' files button */}
					{selectedBucket !== 'inProgress' &&
					(checkPermissions(user, 'contractor') || anonUpload) &&
					!downloadOnly ? (
						<YellowIconButton
							title="View in-progress files"
							onClick={() => setSelectedBucket('inProgress')}>
							<AutorenewIcon />
							<FileCounter>
								{projectData?.[`${deliverableKey}InProgress`]?.length || ''}
							</FileCounter>
						</YellowIconButton>
					) : null}

					{/* View 'archive' files button */}
					{selectedBucket !== 'archive' && user?.isAdmin && !isAnonymous ? (
						<YellowIconButton
							title="View archived files"
							onClick={() => setSelectedBucket('archive')}>
							<InventoryIcon />
							<FileCounter>
								{projectData?.[`${deliverableKey}Archive`]?.length || ''}
							</FileCounter>
						</YellowIconButton>
					) : null}
				</>
			) : (
				<YellowIconButton title="Finish editing" onClick={() => setEditingOrder(false)}>
					<ClearIcon />
				</YellowIconButton>
			)}
		</Container>
	);
}

const Container = styled.div`
	display: flex;
`;

const FileCounter = styled.span`
	position: absolute;
	left: 28px;
	top: 20px;
	transform: translateX(-50%);

	color: white;
	font-size: 15px;
	text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
`;
