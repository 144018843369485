import styled from 'styled-components';
import { PendingUser, SharedTeam, SharedUser } from '../../types';
import SharedUsers from './SharedUsers';
import SharedTeams from './SharedTeams';
import SharingCategory from './SharingCategory';
import PendingUsers from './PendingUsers';

interface SharedUsersAndTeamsProps {
	sharedUsers: SharedUser[];
	pendingUsers: PendingUser[];
	sharedTeams: SharedTeam[];
	getSetMembers: () => Promise<void>;
}

export default function SharedUsersAndTeams({
	sharedUsers,
	pendingUsers,
	sharedTeams,
	getSetMembers,
}: SharedUsersAndTeamsProps) {
	return (
		<Container>
			{pendingUsers.length ? (
				<SharingCategory title="Pending">
					<PendingUsers pendingUsers={pendingUsers} getSetMembers={getSetMembers} />
				</SharingCategory>
			) : null}

			{sharedUsers.length ? (
				<SharingCategory title="Directly Shared">
					<SharedUsers sharedUsers={sharedUsers} getSetMembers={getSetMembers} />
				</SharingCategory>
			) : null}

			{sharedTeams.length ? (
				<SharingCategory title="Teams">
					<SharedTeams sharedTeams={sharedTeams} />
				</SharingCategory>
			) : null}
		</Container>
	);
}

const Container = styled.div`
	display: flex;
	flex-direction: column;

	min-height: 0px;
	flex-grow: 1;
	flex-shrink: 1;
	overflow-y: auto;
`;
