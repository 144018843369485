import { useState, useEffect, useCallback } from 'react';
import { useParams, Link } from 'react-router-dom';
import Layout from '../components/reusable-components/Layout';
import { ProjectData, ProjectStatus } from '../types';
import { projectStatusText, projectStatuses } from '../utils';
import styled from 'styled-components';
import { statusColor } from '../components/styled-components/styledReactTable';
import { collection, getDocs, query, where } from 'firebase/firestore';
import { db } from '../firebase';

export default function ClientInfo() {
	const { clientName } = useParams();

	const [sortedProjects, setSortedProjects] = useState<ProjectData[] | null>(null);

	const fetchSetProjects = useCallback(async () => {
		const q = query(
			collection(db, 'projects'),
			where('clients', 'array-contains', clientName)
		);
		const projectDocs = await getDocs(q);
		setSortedProjects(projectDocs.docs.map(projDoc => projDoc.data() as ProjectData));
	}, [clientName]);

	useEffect(() => {
		fetchSetProjects();
	}, [fetchSetProjects]);

	const StatusDropDown = ({ status }: { status: ProjectStatus }) => {
		const statusText = projectStatusText(status);
		const filteredProjects = sortedProjects?.filter(
			(proj: any) => proj.projectStatus === status
		);
		const [showProjects, setShowProjects] = useState(false);

		return (
			<>
				<StatusClickable
					onClick={() => setShowProjects(prev => !prev)}
					status={status}
					style={{ display: filteredProjects?.length ? '' : 'none' }}>
					{`${statusText} (${filteredProjects?.length})`}
				</StatusClickable>
				<div style={{ display: showProjects ? 'flex' : 'none', flexDirection: 'column' }}>
					{filteredProjects?.map((proj: any, i: number) => (
						<Link
							to={`/projects/${proj.id}`}
							className="clickable-link"
							key={i}
							style={{ width: 'fit-content' }}>
							{proj.address}
						</Link>
					))}
				</div>
			</>
		);
	};

	useEffect(() => {
		if (clientName) {
			document.title = `${clientName} Client Page`;
		} else {
			document.title = 'Unnamed Client Page';
		}
	}, [clientName]);

	return (
		<Layout title={clientName}>
			<div
				style={{
					color: '#ffb310',
					width: '100%',
					padding: '20px 10px',

					display: 'flex',
					flexDirection: 'column',
				}}>
				{projectStatuses.map((status: ProjectStatus) => {
					return <StatusDropDown status={status} key={status} />;
				})}
			</div>
		</Layout>
	);
}

const StatusClickable = styled.div<{
	status: ProjectStatus;
}>`
	color: ${({ status }) => statusColor(status)};
	cursor: pointer;
	width: fit-content;

	&:hover {
		font-weight: bold;
	}
`;
