import { useCallback, useContext, useEffect, useState } from 'react';
import {
	CardsWrapper,
	ContainerBorder,
	ProjectsContainer,
} from '../../../screens/ProjectsList/components/ClientProjects/styles';
import { Add } from '@mui/icons-material';
import { LoadingContext } from '../../../context/LoadingProvider';
import { ProjectContext } from '../../../context/ProjectProvider';
import { IconButton, MenuItem, Select, SelectChangeEvent, styled } from '@mui/material';
import GroupedStatusTable from '../GroupedStatusTable';
import SectionHeader from '../../../screens/ProjectsList/components/ClientProjects/components/SectionHeader';
import AddProjectForm from '../AddProjectForm';
import { AuthContext } from '../../../context/AuthProvider';
import { collection, getDocs, query, where } from 'firebase/firestore';
import { db } from '../../../firebase';
import theme from '../../../styles/theme';
import { UserHomeContext } from '../../../context/UserHomeProvider';
import { Team } from '../../../types';

// TODO: update the comment here :
/**
 * Component for displaying all projects shared with a user
 * @param showingDialog boolean useState that determines whether or not the dialog is shown
 * @param setShowingDialog useState updater for showingDialog
 */
const UserProjects = () => {
	const { setLoadingProjectStatuses, loadingProjectStatuses } =
		useContext(LoadingContext);
	const { projectsListQuery, setProjectsListQuery } = useContext(ProjectContext);
	const { user } = useContext(AuthContext);
	const { setSelectedTeam } = useContext(UserHomeContext);
	const [showingAddProject, setShowingAddProject] = useState<boolean>(false);
	const [isOpen, setIsOpen] = useState<boolean>(true);
	const [projectsFoundNumber, setProjectsFoundNumber] = useState<number>(0);
	const [userTeams, setUserTeams] = useState<Team[]>([]);
	const [teamSelect, setTeamSelect] = useState<string>('allTeams');

	const fetchAllUserTeams = useCallback(async () => {
		const queryUserTeams = query(
			collection(db, 'teams'),
			where('userIds', 'array-contains', user?.id)
		);
		const teamsUserCollection = await getDocs(queryUserTeams);
		const fetchedTeams = teamsUserCollection.docs.map(
			fetchedDoc =>
				({
					...fetchedDoc.data(),
					id: fetchedDoc.id,
				} as Team)
		);

		if (user?.orgOwnerIds && user.orgOwnerIds.length > 0) {
			const queryOrgTeams = query(
				collection(db, 'teams'),
				where('organizationId', 'in', user?.orgOwnerIds)
			);
			const teamsOrgCollection = await getDocs(queryOrgTeams);

			teamsOrgCollection.docs.forEach(fetchedDoc => {
				if (!fetchedTeams.find(team => team.id === fetchedDoc.id)) {
					fetchedTeams.push({
						...fetchedDoc.data(),
						id: fetchedDoc.id,
					} as Team);
				}
			});
		}

		fetchedTeams.sort((a, b) => a.name.localeCompare(b.name));

		setUserTeams(fetchedTeams);
	}, [user]);

	useEffect(() => {
		fetchAllUserTeams();
	}, [fetchAllUserTeams, user]);

	const handleSelectedTeam = (event: SelectChangeEvent) => {
		setTeamSelect(event.target.value);
		if (event.target.value === 'allTeams') {
			setSelectedTeam(null);
		} else {
			const team = userTeams.find(team => team.id === event.target.value);
			if (team) setSelectedTeam(team);
		}
	};

	return (
		<>
			<ContainerBorder
				style={{
					height: isOpen ? '100%' : 'auto',
					alignSelf: 'flex-start',
				}}>
				<CardsWrapper>
					<SectionHeader
						headerStyle={{ width: '100%', padding: '0px 14px', overflowX: 'auto' }}
						showDropdown={true}
						dropdownValue={setIsOpen}
						onSearch={() => setLoadingProjectStatuses(true)}
						propQuery={projectsListQuery}
						setPropQuery={setProjectsListQuery}
						headerComponents={
							<IconButton
								size="small"
								onClick={() => {
									setShowingAddProject(true);
								}}>
								<Add color="primary" />
							</IconButton>
						}>
						<Container>
							Projects
							<Select
								id="select-team"
								value={teamSelect}
								onChange={handleSelectedTeam}
								sx={{
									padding: 0,
									color: '#fff',
									fontWeight: 400,
									minWidth: '200px',
									'.MuiSelect-select': {
										padding: '5px 16px 2px 10px',
										border: `1px solid ${theme.palette.primary.main}`,
									},
									'.MuiSvgIcon-root ': {
										fill: theme.palette.primary.main,
									},
								}}>
								<MenuItem value={'allTeams'}>All Teams</MenuItem>
								{userTeams?.map((team: Team) => (
									<MenuItem key={team.id} value={team.id}>
										{team.name}
									</MenuItem>
								))}
							</Select>
							<Grid>
								{!loadingProjectStatuses && projectsListQuery.length > 0 && (
									<span>{projectsFoundNumber} search results</span>
								)}
							</Grid>
						</Container>
					</SectionHeader>
					{isOpen && (
						<ProjectsContainer>
							<GroupedStatusTable projectsFoundNumber={setProjectsFoundNumber} />
						</ProjectsContainer>
					)}
				</CardsWrapper>
			</ContainerBorder>
			<AddProjectForm
				showingDialog={showingAddProject}
				setShowingDialog={setShowingAddProject}
				isClient={!user?.isAdmin}
				orgRequired={false}
			/>
		</>
	);
};

const Container = styled('div')`
	display: flex;
	flex-direction: row;
	align-items: center;
	width: 100%;
	gap: 20px;
`;

const Grid = styled('div')`
	display: flex;
	width: 100%;
	place-content: center;
`;

export default UserProjects;
