import { createContext, useCallback, useContext, useEffect, useState } from 'react';
import { SnackContext } from './SnackProvider';
import { AuthContext } from './AuthProvider';
import { db, getQBAuthUri, getQBInvoicePdf, refreshQBAccessToken } from '../firebase';
import { HttpsCallableResult } from 'firebase/functions';
import { QuickbooksAuth } from '../types';
import { findIndex } from 'lodash';
import { doc, onSnapshot } from 'firebase/firestore';

type QuickbooksContextType = {
	downloadQBInvoice: (invoiceNum: string) => Promise<void>;
};

export const QuickbooksContext = createContext<QuickbooksContextType>(
	{} as QuickbooksContextType
);
export const QuickbooksProvider = ({ children }: { children: React.ReactNode }) => {
	const { setSnackbarProps } = useContext(SnackContext);
	const { user } = useContext(AuthContext);

	const [qbAuth, setQbAuth] = useState<QuickbooksAuth | undefined>(undefined);
	const [authorizingQB, setAuthorizingQB] = useState(false);
	const [refreshingAccessToken, setRefreshingAccessToken] = useState(false);
	const [queuedFunctions, setQueuedFunctions] = useState<
		{ name: string; params: string[] }[]
	>([]);

	useEffect(() => {
		if (user?.isAdmin) {
			const qbAuthDoc = doc(db, 'cached_data/qbAuth');
			onSnapshot(
				qbAuthDoc,
				res => {
					const data = res.data() as QuickbooksAuth | undefined;
					setQbAuth(data);
				},
				err => {
					console.error('Could not fetch qbAuth doc');
					console.log(err);
				}
			);
		} else {
			setQbAuth(undefined);
			setQueuedFunctions([]);
		}
	}, [user?.isAdmin]);

	/**
	 * Gets the qb auth uri for the current user and prompts qb auth login in a new tab.
	 * The qbAuth is updated by a cloud function callback after the auth uri is fetched,
	 * so we cannot set authorizingQB to false after getQBAuthUri returns.
	 */
	const authorizeQB = useCallback(async () => {
		setAuthorizingQB(true);
		setSnackbarProps({
			open: true,
			severity: 'warning',
			message: 'Authorizing Quickbooks...',
			hideDuration: null,
		});

		try {
			const res = (await getQBAuthUri()) as HttpsCallableResult<{ authUri: string }>;
			window.open(res.data.authUri, '_blank');
		} catch (err) {
			setSnackbarProps({
				open: true,
				severity: 'error',
				message: 'Could not authorize Quickbooks',
				hideDuration: 3000,
			});
			console.error(err);
		}
	}, [setSnackbarProps]);

	/**
	 *
	 */
	const refreshAccessToken = useCallback(async () => {
		setRefreshingAccessToken(true);
		setSnackbarProps({
			open: true,
			severity: 'warning',
			message: 'Refreshing Quickbooks access token...',
			hideDuration: null,
		});

		try {
			await refreshQBAccessToken({ refreshToken: qbAuth?.refreshToken.token });
			setRefreshingAccessToken(false);
			setSnackbarProps({
				open: true,
				severity: 'success',
				message: 'Quickbooks access token refresh successful!',
			});
		} catch (err) {
			setRefreshingAccessToken(false);
			setSnackbarProps({
				open: true,
				severity: 'error',
				message: 'Could not refresh Quickbooks access token',
				hideDuration: 3000,
			});
			console.error(err);
		}
	}, [qbAuth?.refreshToken.token, setSnackbarProps]);

	/**
	 * Checks the given qbAuth to see if the tokens are valid.
	 */
	const validateTokens = (qbAuth?: QuickbooksAuth) => {
		const invalidAccessToken =
			!qbAuth ||
			Date.now() - qbAuth.accessToken.createdAt >= qbAuth.accessToken.expiresIn * 1000;

		const invalidRefreshToken =
			!qbAuth ||
			Date.now() - qbAuth.refreshToken.createdAt >= qbAuth.refreshToken.expiresIn * 1000;

		return { invalidAccessToken, invalidRefreshToken };
	};

	/**
	 * Monitors the qbAuth.
	 */
	useEffect(() => {
		// Every time the qbAuth changes, check the validity of the tokens.
		validateTokens(qbAuth);

		/**
		 * Always set authorizingQB state to false when qbAuth changes. If authorizingQB was true when qbAuth changed,
		 * that means authorizeQB was called, so set wasAuthorizing to true.
		 */
		let wasAuthorizing = false;
		setAuthorizingQB(prev => {
			wasAuthorizing = prev;
			return false;
		});

		if (wasAuthorizing && qbAuth) {
			// This setState is outside of setAuthorizingQB to prevent the
			// "Cannot update a component while rendering a different component" warning
			setSnackbarProps({
				open: true,
				severity: 'success',
				message: 'Quickbooks authorization successful!',
			});
		}
	}, [setSnackbarProps, qbAuth]);

	const downloadQBInvoice = useCallback(
		async (invoiceNum: string) => {
			const { invalidAccessToken, invalidRefreshToken } = validateTokens(qbAuth);
			if (!invalidAccessToken && !invalidRefreshToken && qbAuth) {
				setSnackbarProps({
					open: true,
					severity: 'warning',
					message: 'Fetching invoice pdf...',
				});

				try {
					const res = await getQBInvoicePdf({
						authToken: qbAuth.accessToken.token,
						realmId: qbAuth.realmId,
						invoiceNum: invoiceNum,
					});
					const data = res.data as any;
					const base64 = data.pdfBase64 as string;
					const customFields = Array.isArray(data.invoiceCustomFields)
						? data.invoiceCustomFields
						: [];

					const poIdx = findIndex(customFields, (customField: any) => {
						if (typeof customField?.Name !== 'string') {
							return false;
						} else {
							const name = (customField.Name as string).toLowerCase().trim();
							return (
								name === 'po #' ||
								name === 'p.o. #' ||
								name === 'po box' ||
								name === 'p.o. box'
							);
						}
					});
					const poNum =
						poIdx > -1 ? data.invoiceCustomFields[poIdx].StringValue?.trim() : '';

					const projectNumIdx = findIndex(customFields, (customField: any) => {
						if (typeof customField?.Name !== 'string') {
							return false;
						} else {
							const name = (customField.Name as string).toLowerCase().trim();
							return (
								name === 'project #' ||
								name === 'project num' ||
								name === 'project number'
							);
						}
					});
					const projectNum =
						projectNumIdx > -1
							? data.invoiceCustomFields[projectNumIdx].StringValue?.trim()
							: '';

					const address = data.invoiceDesc as string; // Invoice address is stored as description for for first Line element in Quickbooks
					const date = data.invoiceDate as string;

					const linkSource = `data:application/pdf;base64,${base64}`;
					const a = document.createElement('a');
					a.href = linkSource;

					let filename = 'Invoice ' + invoiceNum;
					if (poNum) filename += `_PO ${poNum}`;
					if (projectNum) filename += `_${projectNum}`;
					if (address) filename += `_${address.trim()}`;
					if (date) filename += `_${date.replaceAll('-', '.')}`;
					filename += '.pdf';

					a.download = filename;
					a.click();

					setSnackbarProps({
						open: false,
					});
				} catch (err) {
					setSnackbarProps({
						open: true,
						severity: 'error',
						message: 'Could not fetch invoice pdf',
					});
					console.error(err);
				}
			} else {
				// Tokens are invalid or qbAuth is undefined, so queue the download
				// function to be run later.
				setQueuedFunctions(prev => [
					...prev,
					{ name: 'downloadQBInvoice', params: [invoiceNum] },
				]);

				if (!authorizingQB && invalidRefreshToken) {
					authorizeQB();
				} else if (!refreshingAccessToken && invalidAccessToken) {
					refreshAccessToken();
				}
			}
		},
		[
			qbAuth,
			setSnackbarProps,
			authorizingQB,
			refreshingAccessToken,
			authorizeQB,
			refreshAccessToken,
		]
	);

	// Runs the queuedFunctions when the tokens are valid.
	useEffect(() => {
		const { invalidAccessToken, invalidRefreshToken } = validateTokens(qbAuth);
		if (!invalidAccessToken && !invalidRefreshToken && queuedFunctions.length) {
			const funcName = queuedFunctions[0].name;
			switch (funcName) {
				case 'downloadQBInvoice':
					downloadQBInvoice(queuedFunctions[0].params[0]);
					break;
				default:
					break;
			}
			setQueuedFunctions(prev => [...prev].slice(1));
		}
	}, [queuedFunctions, downloadQBInvoice, qbAuth]);

	return (
		<QuickbooksContext.Provider
			value={{
				downloadQBInvoice,
			}}>
			{children}
		</QuickbooksContext.Provider>
	);
};
