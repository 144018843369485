import { ProjectStatus } from '../../types';
import { projectStatuses } from '../../utils';

export type ProjectStatusCountState = Record<ProjectStatus, number | null>;

export const initialState = projectStatuses.reduce((obj, status) => {
	return {
		...obj,
		[status]: null,
	};
}, {} as ProjectStatusCountState);

export const reducer = (
	state: ProjectStatusCountState,
	newState: Partial<ProjectStatusCountState>
) => {
	// Filter the inputted newState to make sure only valid project statuses are
	// passed in the newState object.
	const filteredNewState = Object.keys(newState).reduce((obj, state) => {
		if (projectStatuses.includes(state as ProjectStatus)) {
			return {
				...obj,
				[state]: newState[state as ProjectStatus],
			};
		} else return obj;
	}, {} as Partial<ProjectStatusCountState>);

	return {
		...state,
		...filteredNewState,
	};
};
