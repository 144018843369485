import { useState, useEffect, useCallback, useContext } from 'react';
import {
	TextField,
	Button,
	Dialog,
	DialogTitle,
	DialogActions,
	DialogContent,
} from '@mui/material';
import styled from 'styled-components';
import { addLeadComment, getLeadComments } from '../../../../../../firebase';
import { formatDate } from '../../../../../../utils';
import { EditableSpan } from '../../../../../../components/styled-components/styledReactTable';
import { SnackContext } from '../../../../../../context/SnackProvider';
import { LeadsContext } from '../../../LeadsProvider';
import LoadingScreen from '../../../../../../components/reusable-components/LoadingScreen';

export default function CommentsTextField({
	docId,
	lastComment,
}: {
	docId: string;
	lastComment?: string;
}) {
	const { setSnackbarProps } = useContext(SnackContext);
	const { setLeads } = useContext(LeadsContext);

	const [focused, setFocused] = useState(false);
	const [inputValue, setInputValue] = useState('');

	const [comments, setComments] = useState<any[] | null>(null);

	const getSetComments = useCallback(async (id: string) => {
		const response = await getLeadComments({ id: id });
		const commentsArr = (response.data as any).comments.map((comment: any) => ({
			...comment,
			timestamp: new Date(comment.timestamp._seconds * 1000),
		}));
		setComments(commentsArr);
	}, []);

	useEffect(() => {
		if (focused && !comments) {
			getSetComments(docId);
		}
	}, [focused, comments, getSetComments, docId, lastComment]);

	const handleFocus = () => {
		setFocused(true);
	};
	const handleBlur = () => {
		setFocused(false);
		setInputValue('');
	};

	const handleKeyDown = async (e: React.KeyboardEvent<HTMLDivElement>) => {
		if (e.key === 'Enter') {
			e.preventDefault();
			if (e.shiftKey) {
				setInputValue(prev => prev + '\n');
			} else if (inputValue.trim()) {
				setSnackbarProps({
					open: true,
					message: 'Submitting changes...',
					severity: 'warning',
					hideDuration: null,
				});

				setComments(prev => {
					if (prev) {
						return [
							{
								comment: inputValue,
								timestamp: new Date(),
							},
							...prev,
						];
					} else {
						return [
							{
								comment: inputValue,
								timestamp: new Date(),
							},
						];
					}
				});
				setLeads(prev =>
					prev.map(lead => {
						if (lead.id !== docId) return lead;
						else
							return {
								...lead,
								lastComment: inputValue,
								lastActivity: new Date(),
							};
					})
				);
				setInputValue('');
				handleBlur();
				await addLeadComment({ id: docId, comment: inputValue });

				setSnackbarProps({
					open: true,
					message: 'Changes submitted!',
					severity: 'success',
				});
			}
		}
	};

	return (
		<>
			<EditableSpan
				onClick={() => handleFocus()}
				onKeyPress={e => {
					if (e.code === 'Enter') setFocused(true);
				}}
				tabIndex={0}>
				{lastComment || '-'}
			</EditableSpan>

			<Dialog open={focused} onClose={() => handleBlur()} maxWidth="xs" fullWidth>
				<DialogTitle>Lead Comments</DialogTitle>

				<Chatbox>
					{comments ? (
						<>
							<CommentsContainer hasComments={Boolean(comments?.length)}>
								{comments?.length ? (
									comments.map((comment, i) => (
										<Comment key={i} timestamp={comment.timestamp}>
											{comment.comment}
										</Comment>
									))
								) : (
									<h4 style={{ textAlign: 'center' }}>No comments to display...</h4>
								)}
							</CommentsContainer>
							<TextField
								multiline
								value={inputValue}
								onChange={e => setInputValue(e.target.value)}
								onKeyDown={handleKeyDown}
								variant="standard"
								autoFocus
								inputProps={{ style: { fontSize: '0.875rem' } }}
							/>
						</>
					) : (
						<LoadingScreen message="Loading comments..." textColor="black" />
					)}
				</Chatbox>

				<DialogActions>
					<Button onClick={() => handleBlur()}>Close</Button>
				</DialogActions>
			</Dialog>
		</>
	);
}

const Chatbox = styled(DialogContent)`
	display: flex;
	flex-direction: column;
	padding: 5px;
`;

const CommentsContainer = styled.div<{ hasComments: boolean }>`
	display: flex;
	flex-direction: ${p => (p.hasComments ? 'column-reverse' : 'column')};
	justify-content: ${p => (p.hasComments ? '' : 'column-reverse')};
	gap: 2px;

	min-height: 200px;
	max-height: calc(100vh - 300px);
	overflow-y: auto;
`;

const Comment = styled.span<{ timestamp: Date }>`
	border-radius: 5px;
	background-color: #c3c3c3;
	width: fit-content;
	padding: 3px;
	padding-bottom: 20px;
	position: relative;

	min-width: 80px;
	max-width: 100%;
	word-wrap: break-word;
	white-space: pre-wrap;

	&::before {
		content: '${p => formatDate(p.timestamp)}';
		position: absolute;
		// display: none;
		font-size: 12px;
		color: grey;
		bottom: 3px;
		right: 3px;
	}
`;
