import { FC, PropsWithChildren, useContext, useState } from 'react';
import { Button, FormControl } from '@mui/material';
import { SelectFieldProps } from './SelectField.types';
import { FormV2Context } from '../../screen-components/ProjectUtilityFormV2/context/FormV2Context.types';
import theme from '../../../styles/theme';
import { TemplateContext } from '../../../context/TemplateProvider';
import { StyledMenuItem, StyledMenuPaper, StyledSelect } from './Select.styles';
import { Dialog } from '../Dialog';

const SelectField: FC<PropsWithChildren<SelectFieldProps>> = ({
	node,
	size = 'medium',
}) => {
	const options: string[] = node.options || [];
	const nodeValue = node.value as string;
	const { updateValue, isViewMode } = useContext(FormV2Context);
	const { isTemplate } = useContext(TemplateContext);
	const [value, setValue] = useState<string>(nodeValue ?? '');
	const [open, setOpen] = useState(false);
	const [selectedValue, setSelectedValue] = useState<string>(nodeValue ?? '');

	const handleChange = async (option: any) => {
		setValue(option);
		await updateValue(node.id, option);
	};

	const handleConfirmChange = (option: any) => {
		if (node.conditionalType) {
			setOpen(true);
			setSelectedValue(option);
		} else {
			handleChange(option);
		}
	};

	const onClose = () => {
		setOpen(false);
	};

	const handleSaveClick = () => {
		handleChange(selectedValue);
		setOpen(false);
	};

	return (
		<>
			<FormControl
				id={node.id}
				sx={{
					minWidth: 312,
					[theme.breakpoints.down('md')]: {
						width: '100%',
						minWidth: 'unset',
					},
				}}>
				<StyledSelect
					value={value}
					autoWidth
					placeholder="Select Option"
					onChange={event => handleConfirmChange(event.target.value)}
					displayEmpty
					size={size}
					disabled={isTemplate}
					isviewmode={String(isViewMode)}
					sx={{ color: value?.length > 0 ? '#fbfbfb' : '#838383' }}
					MenuProps={{
						PaperProps: {
							component: StyledMenuPaper,
						},
					}}>
					<StyledMenuItem value="">None</StyledMenuItem>
					{options.map((option, index) => (
						<StyledMenuItem key={index} value={option}>
							{option}
						</StyledMenuItem>
					))}
				</StyledSelect>
			</FormControl>
			<Dialog
				open={open}
				onClose={onClose}
				title="Conditional Templates"
				bottomActions={
					<>
						<Button onClick={onClose}>Cancel</Button>
						<Button onClick={handleSaveClick}>Save</Button>
					</>
				}>
				<div>
					Warning: Changing this answer may modify the report and some data could be lost.
					Do you want to continue?
				</div>
			</Dialog>
		</>
	);
};

export default SelectField;
