import {
	Button,
	DialogContent,
	DialogTitle,
	Divider,
	IconButton,
	MenuItem,
	Stack,
	TextField,
} from '@mui/material';
import { useContext, useState } from 'react';
import { FormNode, NodeType, itemTypes } from '../../../utils/types';
import { AddCircleOutline, RemoveCircleOutline } from '@mui/icons-material';
import {
	ChildText,
	ChipContent,
	NodeText,
	OptionHeader,
	PasteButton,
	PasteContainer,
	PastedNodeBox,
	StyledDialog,
	StyledDialogActions,
	TemplateChip,
	TemplateTitle,
	TemplateType,
	TypeText,
} from './NewItemDialog.styles';
import useSearchItemTemplates from '../../../hooks/useSearchItemTempaltes';
import { FormV2Context } from '../../../context/FormV2Context.types';

interface NewItemDialogProps {
	isOpen: boolean;
	onClose: () => void;
	onAddItem: (
		itemName: string,
		itemType: NodeType,
		options?: string[],
		parentId?: string
	) => void;
	enableTypeSelection: boolean;
	excludeTypes?: NodeType[];
	dialogTitle?: string;
	inputLabel?: string;
	onPasteItem?: () => void;
	showPasteOption?: boolean;
}

export const NewItemDialog: React.FC<React.PropsWithChildren<NewItemDialogProps>> = ({
	isOpen,
	onClose,
	onAddItem,
	enableTypeSelection = true,
	excludeTypes = [],
	dialogTitle = 'Add New Item',
	inputLabel = 'Enter Item Name or Search for Template',
	onPasteItem,
	showPasteOption,
}) => {
	const [itemName, setItemName] = useState('');
	const [itemType, setItemType] = useState<NodeType | ''>('');
	const [nodeOptions, setNodeOptions] = useState<string[]>([]);
	const [newOption, setNewOption] = useState('');
	const [templates, setTemplates] = useState<FormNode[]>([]);
	const { searchItems } = useSearchItemTemplates();
	const { copiedNodeData } = useContext(FormV2Context);

	const filteredTypes = itemTypes.filter(
		type => !excludeTypes.includes(type.value as NodeType)
	);

	const handleTemplateClick = (template: FormNode) => {
		onAddItem(template.displayTitle, template.type, template.options);
		setItemName('');
		setItemType(enableTypeSelection ? '' : 'node');
		setNodeOptions([]);
		setNewOption('');
		setTemplates([]);
		onClose();
	};

	const handleAddClick = () => {
		if (itemType === 'select' && nodeOptions.length > 0) {
			onAddItem(itemName, itemType, nodeOptions);
		} else if (itemType) {
			onAddItem(itemName, itemType);
		}
		setItemName('');
		setItemType(enableTypeSelection ? '' : 'node');
		setNodeOptions([]);
		setNewOption('');
		onClose();
	};

	const handleKeyDown = (event: React.KeyboardEvent) => {
		if (event.key === 'Enter') {
			event.preventDefault(); // Prevent form submission on 'enter'
			handleAddOption();
		}
	};

	const handleRemoveOption = (optionToRemove: string) => {
		setNodeOptions(nodeOptions.filter(option => option !== optionToRemove));
	};

	const handleAddOption = () => {
		if (newOption.trim() !== '') {
			setNodeOptions(prev => [...prev, newOption.trim()]);
			setNewOption('');
		}
	};

	const handleNewOptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setNewOption(event.target.value);
	};

	const onChangeName = async (e: React.ChangeEvent<HTMLInputElement>) => {
		setItemName(e.target.value);
		const result = await searchItems(e.target.value as string);
		const filteredResult = result.filter(
			template => !excludeTypes.includes(template.type as NodeType)
		);
		setTemplates(filteredResult);
	};

	return (
		<StyledDialog open={isOpen} onClose={onClose}>
			<DialogTitle>{dialogTitle}</DialogTitle>
			<DialogContent>
				{showPasteOption && copiedNodeData && (
					<>
						<PasteContainer>
							<OptionHeader>Paste your copied item</OptionHeader>
							<PastedNodeBox>
								<NodeText>{copiedNodeData.displayTitle}</NodeText>{' '}
								<TypeText>{copiedNodeData.type}</TypeText>
								{copiedNodeData.children?.map((child, index) => (
									<div key={index}>
										– <ChildText>{child.displayTitle} </ChildText>
										<TypeText>{child.type}</TypeText>
									</div>
								))}
							</PastedNodeBox>
							<PasteButton onClick={onPasteItem} variant="contained" color="primary">
								Paste {copiedNodeData.displayTitle}
							</PasteButton>
						</PasteContainer>
						<Divider>Or</Divider>
						<OptionHeader>Create a new item</OptionHeader>
					</>
				)}
				<TextField
					autoFocus
					margin="dense"
					id="item-name"
					label={inputLabel}
					type="text"
					fullWidth
					variant="standard"
					value={itemName}
					onChange={onChangeName}
				/>
				{enableTypeSelection && (
					<TextField
						select
						label="Select the Type"
						fullWidth
						margin="normal"
						variant="standard"
						value={itemType}
						onChange={e => {
							setItemType(e.target.value as NodeType);
							setNodeOptions([]);
						}}>
						{filteredTypes.map(option => (
							<MenuItem key={option.key} value={option.value}>
								{option.label}
							</MenuItem>
						))}
					</TextField>
				)}
				{itemType === 'select' && (
					<>
						<Stack direction="row" spacing={1} alignItems="center">
							<TextField
								fullWidth
								margin="dense"
								id="new-option"
								label="Enter option for the Picker"
								type="text"
								variant="standard"
								value={newOption}
								onChange={handleNewOptionChange}
								onKeyDown={handleKeyDown}
							/>
							<IconButton onClick={handleAddOption} aria-label="add option">
								<AddCircleOutline />
							</IconButton>
						</Stack>
						{nodeOptions.map((option, index) => (
							<Stack direction="row" spacing={1} alignItems="center" key={index}>
								<TextField
									fullWidth
									margin="dense"
									variant="standard"
									value={option}
									InputProps={{
										readOnly: true,
										disableUnderline: true,
									}}
								/>
								<IconButton
									onClick={() => handleRemoveOption(option)}
									aria-label="remove option">
									<RemoveCircleOutline />
								</IconButton>
							</Stack>
						))}
					</>
				)}
				{templates.length > 0 && <h5>Or select a template:</h5>}
				<div>
					{templates.map((template, index) => (
						<TemplateChip
							label={
								<ChipContent>
									<TemplateTitle>{template.displayTitle}</TemplateTitle>
									<TemplateType>{template.type}</TemplateType>
								</ChipContent>
							}
							sx={{ height: 'auto' }}
							key={index}
							color="primary"
							onClick={() => handleTemplateClick(template)}
						/>
					))}
				</div>
			</DialogContent>
			<StyledDialogActions>
				<Button onClick={onClose}>Cancel</Button>
				<Button onClick={handleAddClick}>Add</Button>
			</StyledDialogActions>
		</StyledDialog>
	);
};
