import { useContext, useState, useRef, useEffect, useCallback } from 'react';
import styled from 'styled-components';

import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';

import { FirebaseFile, ProjectData } from '../../../../../../types';
import { FileBucket } from '../../../types';
import FileButtons from './FileButtons';
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	IconButton,
	TextField,
} from '@mui/material';
import { splitFilename } from '../../../../../../utils';
import { getProjectFileHistory, renameProjectFile } from '../../../../../../firebase';
import { ProjectContext } from '../../../../../../context/ProjectProvider';
import Spinner from '../../../../Spinner';
import { ProjectFileHistoryItem } from '../types';
import LoadingScreen from '../../../../LoadingScreen';
import FileSize from './FileSize';

export default function FileRow({
	file,
	fileIndex,
	field,
	selectedBucket,
	projectData,
	changeFirstPreview,
	moveFile,
	deleteFile,
	editingOrder,
	isAnonymous,
}: {
	file: FirebaseFile;
	fileIndex: number;
	field: keyof ProjectData;
	selectedBucket: FileBucket;
	projectData: ProjectData;
	changeFirstPreview: (i: number) => void;
	moveFile: (i: number, destination: FileBucket) => Promise<void>;
	deleteFile: (i: number) => void;
	editingOrder: boolean;
	isAnonymous: boolean;
}) {
	const { setProject } = useContext(ProjectContext);

	const [renaming, setRenaming] = useState(false);
	const [submitting, setSubmitting] = useState(false);
	const inputRef = useRef<HTMLInputElement>();

	const confirmRename = async () => {
		const [oldPrefix, extension] = splitFilename(file.filename);
		const newPrefix = inputRef.current?.value;

		if (newPrefix && newPrefix !== oldPrefix) {
			const newName = newPrefix + '.' + extension;

			setSubmitting(true);
			await renameProjectFile({
				projectId: projectData.id,
				projectFileType: field,
				filepath: file.filepath,
				newName,
			});

			const fileArr = [...(projectData[field] as FirebaseFile[])];
			fileArr.splice(fileIndex, 1, {
				...file,
				filename: newName,
				lastUpdated: new Date(),
			});
			const updatedProj = {
				...projectData,
				[field]: fileArr,
			};
			setProject(updatedProj);
		} else {
			setRenaming(false);
		}
	};

	const cancelRename = () => {
		setRenaming(false);
	};

	const [openFileHistory, setOpenFileHistory] = useState(false);
	const [fileHistory, setFileHistory] = useState<ProjectFileHistoryItem[] | null>(null);

	const fetchSetFileHistory = useCallback(async () => {
		const res = await getProjectFileHistory({ filepath: file.filepath });
		const history = (res.data as any[]).map(
			item =>
				({
					...item,
					timestamp: new Date(item.timestamp._seconds * 1000),
				} as ProjectFileHistoryItem)
		);
		setFileHistory(history);
	}, [file.filepath]);

	useEffect(() => {
		if (openFileHistory) {
			setFileHistory(null);
			fetchSetFileHistory();
		}
	}, [fetchSetFileHistory, openFileHistory]);

	const closeFileHistory = () => {
		setOpenFileHistory(false);
	};

	return (
		<>
			<Container>
				{!renaming ? (
					<>
						<FileButtons
							projectData={projectData}
							file={file}
							fileIndex={fileIndex}
							selectedBucket={selectedBucket}
							changeFirstPreview={changeFirstPreview}
							moveFile={moveFile}
							deleteFile={deleteFile}
							editingOrder={editingOrder}
							isAnonymous={isAnonymous}
							setRenaming={setRenaming}
						/>

						<FilenameSpan>{file.filename}</FilenameSpan>

						<Timestamp onClick={() => setOpenFileHistory(true)} title="View file history">
							<span>{file.uploadDate.toLocaleDateString()}</span>
							<span>{file.uploadDate.toLocaleTimeString()}</span>
						</Timestamp>

						<FileSize file={file} />
					</>
				) : (
					<InputContainer>
						<RenameField
							defaultValue={splitFilename(file.filename)[0]}
							onKeyUp={e => {
								if (e.key === 'Enter') confirmRename();
							}}
							inputRef={inputRef}
							disabled={submitting}
							fullWidth
							autoFocus
						/>

						{!submitting ? (
							<>
								<IconButton
									title="Submit file rename"
									size="small"
									onClick={confirmRename}>
									<CheckIcon />
								</IconButton>
								<IconButton
									title="Cancel file rename"
									size="small"
									onClick={cancelRename}>
									<CloseIcon />
								</IconButton>
							</>
						) : (
							<>
								<Spinner size={60} />
								<span>Renaming file...</span>
							</>
						)}
					</InputContainer>
				)}
			</Container>

			<Dialog open={openFileHistory} onClose={closeFileHistory}>
				<DialogTitle>File History</DialogTitle>

				<DialogContent>
					{fileHistory ? (
						fileHistory.length ? (
							fileHistory.map((history, i) => (
								<HistoryRow key={i}>
									<HistoryMessage>{history.message}</HistoryMessage>
									<HistoryTimestamp>
										{history.timestamp.toLocaleString()}
									</HistoryTimestamp>
								</HistoryRow>
							))
						) : (
							<strong>No file history found...</strong>
						)
					) : (
						<LoadingScreen message="Loading file history..." textColor="black" />
					)}
				</DialogContent>

				<DialogActions>
					<Button onClick={closeFileHistory}>Close</Button>
				</DialogActions>
			</Dialog>
		</>
	);
}

const Container = styled.div`
	display: flex;
	align-items: center;
	gap: 5px;

	line-height: normal;
	transition: 0.2s;
	padding: 1px;

	&:hover {
		background-color: rgba(0, 0, 0, 0.1);
	}
`;

const FilenameSpan = styled.span`
	overflow: hidden;
	overflow-wrap: break-word;
`;

const Timestamp = styled(Button)`
	display: flex;
	flex-direction: column;
	align-items: end;
	gap: 0;

	margin-left: auto;
	padding: 0;

	color: black;
	line-height: normal;
	text-align: end;
	font-size: 12px;
	font-weight: 700;

	@media screen and (min-width: 768px) {
		flex-shrink: 0;
	}
`;

const InputContainer = styled.div`
	display: flex;
	align-items: center;
	gap: 5px;
	width: 100%;
`;

const RenameField = styled(TextField)`
	.MuiInputBase-root {
		font-size: 0.875rem;
	}

	input {
		padding: 10px;
	}
`;

const HistoryRow = styled.div`
	display: flex;
	flex-direction: column;
	padding: 5px;
	border-radius: 5px;
	background-color: rgba(0, 0, 0, 0.1);
`;

const HistoryMessage = styled.span`
	word-break: break-all;
`;

const HistoryTimestamp = styled.strong`
	flex-shrink: 0;
	margin-left: auto;
`;
