import * as React from 'react';
import { PieChart } from '@mui/x-charts/PieChart';
import { ProjectStatus } from '../../../types';
import { statusColor } from '../../styled-components/styledReactTable';
import { useDrawingArea } from '@mui/x-charts';
import { Box, Typography, styled, useMediaQuery } from '@mui/material';

export default function PieGraph({
	projectStatuses,
	totalProjectStatusCounts,
}: {
	projectStatuses: ProjectStatus[];
	totalProjectStatusCounts: number[];
}) {
	const total = totalProjectStatusCounts.reduce((acc, curr) => acc + curr, 0);

	const data = projectStatuses.map((status, index) => ({
		id: status,
		value: totalProjectStatusCounts[index],
		label: projectStatuses[index],
		color: statusColor(status),
	}));

	const isSmallScreen = useMediaQuery('(max-width:600px)');
	const isMediumScreen = useMediaQuery('(max-width:960px)');

	const size = {
		width: isSmallScreen ? 250 : isMediumScreen ? 350 : 400,
		height: isSmallScreen ? 250 : isMediumScreen ? 350 : 400,
	};

	function PieCenterLabel({ children }: { children: React.ReactNode }) {
		const { width, height, left, top } = useDrawingArea();
		return (
			<StyledText x={left + width / 2} y={top + height / 2}>
				{children}
			</StyledText>
		);
	}

	return (
		<Container>
			<TitleContainer>
				<Typography style={{ color: '#f6f6f6' }}>Project Overview by Status</Typography>
			</TitleContainer>
			<DataContainer>
				<GraphBox>
					<PieChart
						series={[
							{
								data,
								innerRadius: isSmallScreen ? 30 : isMediumScreen ? 50 : 70,
								outerRadius: isSmallScreen ? 80 : isMediumScreen ? 120 : 150,
								paddingAngle: 0,
								cornerRadius: 2,
							},
						]}
						{...size}
						sx={{
							width: '100%',
							'& .MuiPieArc-root': {
								stroke: 'black',
								strokeWidth: '3',
							},
							'& .MuiChartsLegend-root': {
								display: 'none',
							},
							'& .MuiResponsiveChartContainer': {
								justifyContent: 'center',
							},
						}}>
						<PieCenterLabel>{total}</PieCenterLabel>
					</PieChart>
				</GraphBox>
				<LegendBox>
					{projectStatuses.map((status, index) => (
						<Box key={status} display="flex" alignItems="center" marginBottom={1}>
							<Box
								width={16}
								height={16}
								marginRight={1}
								style={{ backgroundColor: statusColor(status) }}
							/>
							<LegendText>{status}</LegendText>
						</Box>
					))}
				</LegendBox>
			</DataContainer>
		</Container>
	);
}

const StyledText = styled('text')(() => ({
	fill: '#ffb310',
	textAnchor: 'middle',
	dominantBaseline: 'central',
	fontSize: 30,
}));

export const Container = styled('div')`
	display: flex;
	flex-direction: column;
	flex-grow: 1;
	justify-content: flex-start;
	width: 100%;
	height: 100%;
`;

export const TitleContainer = styled('div')`
	display: flex;
	flex-direction: row;
	width: 100%;
	justify-content: center;
	padding: 40px 40px 10px;
`;

export const DataContainer = styled('div')`
	display: flex;
	flex-direction: row;
	flex-grow: 1;
	justify-content: center;
	align-items: center;
	width: 100%;

	@media (max-width: 500px) {
		flex-direction: column;
		gap: 20px;
	}
`;

export const GraphBox = styled('div')`
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 0 20px;
	box-sizing: border-box;
	overflow: visible;
	text-align: center;

	@media (max-width: 500px) {
		margin-left: 100px;
	}
`;

export const LegendBox = styled('div')`
	display: flex;
	flex-direction: column;
	justify-content: left;
	align-items: left;
	padding: 0 20px 0 0;

	@media (max-width: 500px) {
		padding: 0px;
	}
`;

export const LegendText = styled(Typography)`
	color: #f6f6f6;
	font-size: 15px;

	@media (max-width: 1300px) {
		font-size: 10px;
	}
`;
